@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.web_header {
  width: 100%;
  background-color: $WHITE;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  @media screen and (max-width: 1024px) {
    padding: 15px;
    box-shadow: 0 4px 4px 0 rgba(21, 21, 21, 0.18);
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .toggle_menu_icon {
    padding: 8px;
    margin-right: 8px;

    img {
      height: 25px;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .web_header_logo_wrapper {
    width: 135px;

    img {
      width: 100%;
    }

    @media (max-width: 600px) {
      width: 120px;
      min-width: 120px;
    }

    @media (max-width: 400px) {
      width: 90px;
      min-width: 90px;
    }
  }

  .web_header_search_input_wrapper {
    width: 100%;

    @media (max-width: 768px) {
      display: none;
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .web_header_action_icons_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .web_header_icon_btn {
      padding: 6px !important;
    }

    .web_header_fav_fill {
      color: #ff0f0f;
      font-size: 28px;
    }

    .web_header_icon {
      color: #000;
      font-size: 28px;
    }

    @media (max-width: 600px) {
      width: fit-content;
      margin: 0px 5px;
    }
  }

  .navbar_login_btn_wrapper {
    background-color: $GRAY_SHADE2;
    border-radius: 50px;
    padding: 6px 12px;
    min-width: 75px;
    box-shadow: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      box-shadow: none;
    }

    span {
      color: #333;
      text-transform: capitalize;
      font-weight: 600;
    }
  }

  .web_header_select {
    :root {
      margin-right: 20px !important;
    }

    &>button {
      background-color: $GREEN;
      height: 15px;
      width: 15px;
      padding: 8px;
    }

    svg {
      color: $WHITE;
      transition: transform 0.3s;
    }

    .openExpandIcon {
      transform: rotate(180deg);
    }

    .MuiSelect-select.MuiSelect-select {
      padding-right: 0 !important;
      background: #20a258;
      border-radius: 50%;
      width: 30px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: #fff;
    }

    .MuiInput-underline::before,
    .MuiInput-underline::after {
      border-bottom: none !important;
      outline: none !important;
    }
  }

  .web_header_profile {
    display: flex;
    justify-content: flex-end;

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      object-fit: cover;
      margin-left: 10px;

      @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
      }

      @media (max-width: 400px) {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.login_tab_container {
  cursor: pointer;
  height: 100%;

  h6 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.main_login_wrapper {
  padding: 5px 45px !important;

  @media screen and (max-width: 600px) {
    padding: 15px 40px !important;
  }
}

.logo_wrapper_heading {
  padding: 0px 15px;

  img {
    width: 45%;
  }
}

.login_sign_up_dialog {
  border-radius: 35px !important;
  -ms-overflow-style: none !important; // Hide scrollbar for Internet explorer
  scrollbar-width: none !important; // Hide scrollbar for Firefox
}

.login_sign_up_dialog::-webkit-scrollbar {
  display: none !important;
  /* Hide scrollbar for Safari and Chrome */
}

.shopping_cart_badge {
  background-color: $GREEN;
  color: #fff;
  font-size: 11px !important;
  padding: 0px !important;
  height: 18px !important;
  min-width: 18px !important;
  right: 3px !important;
}

.web_header_menu_paper {
  padding: 14px;

  a {
    color: #333;
  }

  li {
    font-family: $MONTSERRAT;
    background-color: $WHITE !important;
    font-size: 16px;
    padding: 4px 16px;
    min-height: auto;

    &:hover {
      color: $GREEN;
    }
  }
}

.search_icon {
  width: 45px;
  height: 45px;
  background-color: #ffac0c;
  margin-left: 10px;
  border-radius: 50%;
  cursor: pointer;

  svg {
    color: #ffffff
  }
}