@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.wcb_img_loader {
  color: $GREEN !important;
}

.wcb_item_details {
  display: flex;
  flex-direction: column;
  gap: 35px;

  h3 {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  p {
    font-family: $MONTSERRAT;
    font-size: 19px;
    font-weight: bold;
    color: $GREEN;
  }
}

.wcc_content_container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  height: 100%;

  .wcc_corse_details {
    h3,
    p {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: $MONTSERRAT;
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      color: #000;
      margin-bottom: 4px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: #5a5a5a;
    }
  }

  & > p {
    font-family: $MONTSERRAT;
    font-size: 19px;
    font-weight: bold;
    color: $GREEN;
  }
}

.wcc_action_container {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 100%;
}

.wcb_actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 15px;
  height: 100%;

  @media screen and (max-width: 599px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .wcb_quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f5f5;
    border-radius: 26px;
    gap: 15px;

    button {
      border: none;
      background-color: transparent;
      height: 34px;
      width: 34px;
      font-size: 18px;
      font-weight: bold;
      color: #264653;
      border-radius: 50%;

      &:disabled {
        cursor: default;
      }
    }

    span {
      font-size: 15px;
      font-weight: bold;
      color: #264653;
    }

    button:last-child {
      height: 35px;
      width: 35px;
      background-color: #ffffff;
      box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
    }
  }

  .wcb_actions_bottom {
    display: flex;
    align-items: center;
    gap: 12px;

    @media screen and (max-width: 599px) {
      flex-direction: column;
      gap: 5px;
    }

    .wcb_fav_icon {
      background-color: #f7f7f7;
      padding: 6px;

      svg {
        font-size: 20px;
        color: #ec5959;
      }
    }

    .wcb_cart_btn {
      padding: 6px 25px;
      border-radius: 24px;
      background-color: $GREEN;
      text-transform: none;

      span {
        color: #fff;
      }
    }
  }
}

.wcb_quantity_container {
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 960px) {
    justify-content: flex-start;
  }

  .wcb_quantity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f5f5;
    border-radius: 26px;
    gap: 15px;
    width: fit-content;

    button {
      border: none;
      background-color: transparent;
      height: 34px;
      width: 34px;
      font-size: 18px;
      font-weight: bold;
      color: #264653;
      border-radius: 50%;

      &:disabled {
        cursor: default;
      }
    }

    span {
      font-size: 15px;
      font-weight: bold;
      color: #264653;
    }

    button:last-child {
      height: 35px;
      width: 35px;
      background-color: #ffffff;
      box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
    }
  }
}

.wcb_fav_icon {
  background-color: #f7f7f7 !important;
  padding: 8px !important;

  svg {
    font-size: 20px;
    color: #ec5959;
  }
}

.wcb_cart_btn {
  padding: 6px 20px !important;
  border-radius: 24px !important;
  background-color: $GREEN !important;
  text-transform: none;
  width: 100%;

  span {
    color: #fff;
  }
}

.wcb_add_cart_loader {
  margin: 2px 15px;
}
