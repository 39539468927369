@import "../../../../../web/src/assets/css/constants/base/colors.scss";

.restaurant_search_map_view_container {
  padding: 0px 0px 0px 20px;
  background-color: $WHITE;
}

.search_restaurant {
  background-color: $GRAY_SHADE2;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-around;

  div {
    width: 80%;
    background-color: $WHITE;
    border: 1px solid $GRAY_SHADE5;
    border-radius: 10px;
  }

  div::before,
  div::after {
    border-bottom: none !important;
  }

  select {
    width: 100%;
    background-color: $WHITE !important;
    border-radius: 10px !important;
    padding: 15px 10px;
  }
}

.restaurant_search_card {
  background: linear-gradient(to bottom,
      transparent,
      rgba(185, 181, 181, 0.3),
      rgba(20, 0, 10, 0.5),
      rgba(0, 0, 0, 0.7)),
    url(../images//restaurantCardimg.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;

  p,
  span {
    color: $WHITE;
  }

  .restaurant_search_card_content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .restaurant_rating {
    display: flex;
    align-items: center;
    background-color: #ea8e77;
    padding: 5px 8px 5px;
    width: fit-content;
    border-radius: 10px;

    p {
      padding: 5px;
    }
  }

  .restaurant_details {

    h5,
    h6 {
      color: $WHITE;
      text-transform: capitalize;
    }

    h6 {
      font-weight: 400;
      padding-bottom: 10px;
    }
  }
}

.restaurant_search_map_container {
  background: url(../images//mapView.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-left: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.restaurant_card_in_map {
  background-color: $WHITE;
  border-radius: 20px;
  width: fit-content;
}

.restaurant_card_img_in_map {
  position: relative;

  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 200px;
  }

  .restaurant_card_header_img_in_map {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $ORANGE;
      border-radius: 5px;
      padding: 5px;
      color: $WHITE;

      p,
      span {
        color: $WHITE;
      }

      p {
        padding: 0px 5px;
      }
    }

    div:nth-child(2) {
      border-radius: 50%;
    }
  }
}

.restaurant_card_details_in_map {
  padding: 20px 20px 30px;
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 20px;
  }

  span {
    color: $GRAY_SHADE5;
    font-weight: 500;
  }

  h6,
  h5 {
    padding: 10px 0px;
  }

  button {
    width: 100%;
    background-color: $GREEN !important;
    border-radius: 15px !important;

    span {
      color: $WHITE;
      text-transform: none;
      padding: 5px;
      font-size: 18px;
    }
  }
}