@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.cdl_loader_container {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: $GREEN;
    }
}

.cdl_main_container {
    padding: 20px 0px;

    .cdl_lod_more_btn {
        padding: 8px 55px;
        height: 50px;
        border-radius: 49px;
        background-image: linear-gradient(103deg, #2dae43 -27%, #147620 151%);
        text-transform: none;
        font-weight: normal !important;

        span {
            color: #fff;
            font-family: $POPPINS;
            font-weight: normal !important;
            font-size: 22px;
        }

        @media screen and (max-width: 450px) {
            padding: 6px 35px;
            height: auto;

            span {
                font-size: 16px;
            }
        }
    }
}

.cdl_empty_list_container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    height: 40vh;

    p {
        opacity: 0.5;
        font-family: $POPPINS;
        font-size: 18px;
        font-weight: 500;
        color: #000;
        text-align: center;
    }
}

.cdts_main_container {
    padding-bottom: 15px;

    .cdts_search_field {
        padding: 6px 10px;
        border-radius: 10px;
        border: solid 1.3px #d3d3d3;
        background-color: #fff;
        font-size: 16px;
        color: #000;

        &:hover, &:focus-within {
            border: solid 1.3px #2ea763;
            .cdts_hashtag {
                border-left: 2px solid #2ea763 !important;
            }
        }

        .MuiInputAdornment-positionStart {
            margin-right: 1px;
        }

        .cdts_filed_icons_wrapper {
            display: flex;
            align-items: center;
            gap: 5px;

            .cdts_hashtag {
                color: #333;
                font-size: 1.2rem;
                border-left: 2px solid #d3d3d3;
                padding-left: 10px;
            }
        }
    }

    .cdts_searching_loader {
        color: #2ea763;
    }

    .cdts_search_icon {
        color: #2ea763;
    }
}