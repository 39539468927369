@import "../../../../web/src/assets/css/constants/base/colors.scss";

.green_tabs {
  width: 100%;
  height: auto;
  // min-height: 00px;
  //  padding: 10px;
  color: black;
}
ul.green_tabs_menu {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid $GREEN;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
ul.green_tabs_menu li {
  width: 33.33%;
  padding: 10px;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    white-space: nowrap;
  }
}
ul.green_tabs_menu li.active {
  background: $GREEN;
  color: $WHITE;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.green_tab_contents_wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.green_tab_contents {
  width: 80%;
  @media screen and (max-width: 599px) {
    width: 100%;
  }
  text-align: justify;
  p {
    font-family: Montserrat;
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #333232;
    @media screen and (max-width: 1000px) {
      font-size: 14px;
    }
  }
}
