@import "../../../../web/src/assets/css/constants/base/colors.scss";

.topbar_container {
  background: $WHITE;
  border-bottom: 3px solid $GRAY_SHADE2;
}

.topbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;

  &>div:first-child {
    cursor: pointer;
    width: 150px;

    @media (max-width: 600px) {
      width: 130px;
      min-width: 130px;
    }

    @media (max-width: 400px) {
      width: 110px;
      min-width: 110px;
    }
  }
}

.question_wrapper {
  width: 40px;
  height: 40px;
  background-color: $ORANGE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $WHITE !important;
  cursor: pointer;
}