@import "../../../../web/src/assets/css/constants/base/colors.scss";

.social_btn{
    box-shadow: none!important;
    font-size: 12.5px!important;
    padding: 8px !important;
    width: 100% !important;
    border-radius: 25px !important;
    text-transform: capitalize!important;

    &:disabled {
      opacity: 0.6;
    }

    .MuiButton-startIcon {
      margin-right: 4px;
    }

    .google_btn_loader {
      color: $GREEN;
    }
}

.social_Img {
    width: 17px;
}

.btn_color_black {
    color: $Black !important;
}

.social_btn_account {
  box-shadow: none!important;
  font-size: 17px!important;
  padding: 10px !important;
  width: 100% !important;
  text-transform: capitalize!important;
  font-weight: bold!important;
  border-radius: 8px !important;
  background-color: #f6f6f6 !important;
  position: relative !important;

  .check_icon {
    position: absolute;
    top: 50%;
    right: 8%;
    transform: translateY(-50%);
    color: $WHITE;
    background-color: $GREEN;
    border-radius: 50%;
    padding: 2.3px;
    font-size: 17px !important;
  }

  .MuiButton-startIcon {
    margin-right: 5px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 15px !important;
  }
}

.btn_color_blue {
  color: $DARK_BLUE !important;
}

.social_Img_account {
  width: 22px;
  height:  22px;
}