@import "../../../../web/src/assets/css/constants/base/global.scss";
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.od_dialog_main_wrapper {
  padding: 0px 50px 50px !important;
  position: relative;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  @media screen and (max-width: 600px) {
    padding: 0px 30px 30px !important;
  }

  @media screen and (max-width: 300px) {
    padding: 0px 20px 20px !important;
  }
}

.od_dialog_main_wrapper::-webkit-scrollbar {
  display: none !important;
}

.od_dialog_print_wrapper {
  padding: 20px 50px 50px !important;
}

.od_loader_container {
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .od_loader {
    color: $GREEN;
  }
}

.od_cancel_order_wrapper {
  display: flex;
  justify-content: flex-end;

  button {
    color: #e74747;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 17px;
    font-weight: 600;
  }
}

.od_status {
  font-family: $MONTSERRAT !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
  margin-bottom: 2px;
}

.od_main_heading {
  font-family: $POPPINS !important;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #3a3a3a;
  text-align: center;
  border-bottom: 5px solid $GREEN;
  margin-bottom: -5px !important;
  padding: 0px 30px;

  @media screen and (max-width: 600px) {
    font-size: 20px !important;
  }

  @media screen and (max-width: 300px) {
    font-size: 18px !important;
  }
}

.od_subheading {
  width: 100%;

  p {
    font-family: $POPPINS;
    font-size: 22px;
    font-weight: bold;
    color: #3a3a3a;
    margin: 10px 0px;

    @media screen and (max-width: 600px) {
      font-size: 16px !important;
    }

    @media screen and (max-width: 300px) {
      font-size: 14px !important;
    }
  }
}

.od_container {
  border: 2px solid #d3c8cc;
  border-radius: 20px;
  padding: 0px !important;
}

.od_details_container {
  padding: 20px !important;

  @media screen and (max-width: 600px) {
    padding: 14px !important;
  }

  @media screen and (max-width: 300px) {
    padding: 10px !important;
  }
}

.od_light_paragraph {
  font-family: $POPPINS !important;
  font-size: 18px !important;
  font-weight: 500;
  color: #8b8b8b;

  @media screen and (max-width: 600px) {
    font-size: 14px !important;
  }

  @media screen and (max-width: 300px) {
    font-size: 12px !important;
  }
}

.od_light_dark_paragraph {
  font-family: $POPPINS !important;
  font-size: 18px !important;
  font-weight: 500;
  color: #484848;

  @media screen and (max-width: 600px) {
    font-size: 14px !important;
  }

  @media screen and (max-width: 300px) {
    font-size: 12px !important;
  }
}

.od_dark_paragraph {
  font-family: $POPPINS !important;
  font-size: 20px !important;
  font-weight: 500;
  color: #000;

  @media screen and (max-width: 600px) {
    font-size: 16px !important;
  }

  @media screen and (max-width: 300px) {
    font-size: 14px !important;
  }
}

.od_total_paragraph {
  font-family: $POPPINS !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000;

  @media screen and (max-width: 600px) {
    font-size: 14px !important;
  }

  @media screen and (max-width: 300px) {
    font-size: 12px !important;
  }
}

.od_download_invoice {
  border-top: 2px solid #d3c8cc !important;
  border-radius: 0px !important;
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 5px !important;
  text-transform: none !important;
  padding: 8px 20px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;

  &:hover {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  p {
    font-family: $POPPINS;
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }

  img {
    height: 22px;
    width: 22px;
  }
}

.od_border_top {
  border-top: 2px solid #d3c8cc !important;
}

.od_dialog_close {
  position: absolute !important;
  top: 12px;
  right: 12px;

  svg {
    color: #000;
  }
}