@import "../../../../web/src/assets/css/constants/base/colors";
@import "../../../../web/src/assets/css/constants/base/global";

.restOrderWrapper {
  .prodListHeader {
    .outlineTextfield {
      width: 75% !important;
      .MuiInputBase-input::placeholder{
        font-family: 'Roboto',sans-serif;
        font-size: 14px;
      }
    }
  }

  & .btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .MuiButton-outlined {
      background-color: orange;
      width: 100%;
      border: none;

      span {
        font-weight: 600;
        color: $WHITE;
      }

      .MuiSvgIcon-root {
        color: orange;
        font-size: 20px;
        padding: 0;
        border: none;
        margin: 0;
      }
    }
    
    @media screen and (max-width: 560px) {
      width: 100%;
    }
  }

  & .todayBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .MuiButton-outlined {
      background-color: white;
      width: 100%;
      border: 2px solid orange;

      span {
        font-weight: 600;
        color: orange;
      }

      .MuiSvgIcon-root {
        color: orange;
        font-size: 20px;
        padding: 0;
        border: none;
        margin: 0;
      }
    }
  }

  .customToggleButton {
    .switch {
      margin: unset !important;
    }
  }
}

.mainModal {
  padding: unset;

  &.restOrdersReportModal {
    width: 80%;
    height: 400px;
    background-color: white;
    border-radius: $BOX_RADIUS;
    color: #000;

    .headerContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $GRAY_SHADE3;
      padding: 8px 10px 5px 10px;

      .headingReport {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 1rem 2.5rem;


        img {
          width: 50px;
          height: 40px;
        }

        h3 {
          font-family: $MONTSERRAT;
          font-weight: 600;
          font-size: 24px;
        }
      }

      .icon {
        .MuiSvgIcon-root {
          font-size: 35px;
          color: $DARK;
          cursor: pointer;
        }
      }
    }

    .marginTop { 
      justify-content: space-around;
      @media screen and (max-width: 1160px) {
        justify-content: center;
      }
      @media screen and (max-width: 960px) {
        margin-top: 20px;
      }
    }

    .todayCard {
      margin-left: 54px;
    }

    .reportCard {
      width: 285px;
      height: 257px;
      margin: 30px 10px 60px 20px;
      padding: 19px 1px 0 0;
      border-radius: 13px;
      box-shadow: 0 0 6px 4px rgb(216, 214, 214);
      background-color: #fff;
      font-family: $MONTSERRAT;

      img {
        @media screen and (max-width: 960px) {
          width: 250px;
        }
      }

      h4 {
        margin: 0 30px 7.3px 35px;
        font-size: 20px;
        font-weight: 500;
        color : #000;
        span {
          color : #000;
          @media screen and (max-width: 560px) {
            font-size: 14px;
          }
        }
      }

      h3 {
        margin: 32px 30px 7.3px 35px;
        font-size: 32px;
        font-weight: 600;
        color: #01c9ff;
      }

      h5 {
        width: 83px;
        margin-top: 26px;
        font-size: 18px;
        font-weight: 500;
        color: #000;
        margin-right: 25px;
      }

      .reportOrders {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .todayOrders {
        margin-top: 30px;
        @media screen and (max-width: 1060px) {
          margin-top: 50px;
        }
      }

      .todayReportImg {
        margin-top: -30px; 
        height: 300px; 
        width: auto;
      }

      .reportOrdersWeek {
        display: flex;
        align-items: center;
      
        h3 {
          margin: 10px 30px 7.3px 35px;
          font-size: 32px;
          font-weight: 600;
          color: #01c9ff;
        }
      
        h5 {
          width: 83px;
          margin-top: 6px;
          font-size: 18px;
          font-weight: 500;
          color: #000;
        }

      }

      .reportsCurve {
        width: 285px;
        height: 114px;
        margin: 22px 0 0;
        padding: 14.3px 50px 64.8px 127px;
        background-image: linear-gradient(184deg, #90e7ff 92%, rgba(190, 241, 255, 0) 40%);
      }
      @media screen and (max-width: 1060px) {
        width: 250px;
        margin-left: auto !important;
        margin-top: 20px;
        margin-bottom: 20px !important;
        margin-right: auto !important;
      }
    }

    @media screen and (max-width: 1160px) {
      overflow-y: auto;
      width: 70%;
    }

    @media screen and (max-width: 960px) {
      overflow-y: auto;
      width: 60%;
    }

    @media screen and (max-width: 560px) {
      overflow-y: auto;
      width: 90%;
    }
  }
}

.ordersFirstRow {
  justify-content: flex-end;
  .restMargin {
    margin-right: 20px;
  }
}