@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';
@import '../constants/base/mixins.scss';

.norecord-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px 30px 20px;
  h3 {
    text-align: center;
    color: $GREEN;
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0 10px 0;
  }

  p {
    text-align: center;
    font-weight: 600;
    margin: 0 0 20px 0;
    word-spacing: 2px;
  }
}
