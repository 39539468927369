@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

mark {
    border-radius: 6px;
    background-color: rgba(28, 136, 43, 0.18);
    padding: 0px 5px;
}

.cdc_main_container {
    padding: 20px 0px;

    .cdc_redirect_container {
        cursor: pointer;
    }

    .cdc_user_info {
        opacity: 0.5;
        font-family: $POPPINS;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        text-transform: capitalize;
    }

    .cdc_heading {
        span,
        mark {
            font-family: $POPPINS;
            font-size: 22px;
            font-weight: 500;
            color: #000;
        }
    }

    .cdc_description_wrapper {
        margin-top: 5px;
    }

    .cdc_description {
        span,
        mark {
            font-family: $POPPINS;
            font-size: 16px;
            font-weight: 500;
            color: #b2b2b2;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .cdc_tags_highlight {
        span,
        mark {
            font-family: $AVENIR_ROMAN;
            font-size: 16px;
            color: #1c892c;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .cdc_separator {
        height: 5px;
        border-radius: 4px;
        background-color: #f9f8fd;
    }

    .cdc_comments_container {
        padding: 0px 0px 5px 20px;

        .cdc_comment_heading {
            font-family: $POPPINS;
            font-size: 16px;
            font-weight: 500;
            color: #000;
        }

        .cdc_not_first {
            border-top: solid 1px #979797;
        }

        .cdc_comment {
            padding: 15px 0px;
        }
    }

    .cdc_no_comments_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 8px;
    }

    .cdc_comment_filter_btn {
        background-color: none;
        text-transform: none;
        border-radius: 18px;
        padding: 4px 10px;

        span {
            font-family: $POPPINS;
            font-size: 16px;
            font-weight: 500;
            color: #1c892c;
        }
    }

    .cdc_bottom_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        border-radius: 32px;
        background-color: #f6faf7;
        padding: 8px 12px;

        .cdc_follow_btn {
            background-color: $WHITE;
            padding: 6px 25px;
            border-radius: 25px;
            text-transform: none;
            height: 40px;
            min-width: 100px;

            span {
                font-family: $POPPINS;
                font-size: 16px;
                font-weight: 500;
                color: #1c892c;
            }
        }

        .cdc_follow_icon {
            height: 20px;
        }

        .cdc_comment_count_container {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-right: 10px;

            img {
                height: 22px;
                width: 22px;
            }

            p {
                font-family: $POPPINS;
                font-size: 16px;
                font-weight: 500;
                color: #1c892c;
            }
        }
    }
}