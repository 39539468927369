@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.sdc_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 21px;
    background-color: #f7f7f7;
    padding: 20px 30px;
    width: 100%;
    cursor: pointer;

    & > div {
        overflow: hidden;
    }

    .sdc_description {

        span,
        mark {
            font-family: $POPPINS;
            font-size: 16px;
            font-weight: 500;
            color: rgba(51, 51, 51, 0.5);
        }

        mark {
            background-color: rgba(28, 137, 44, 0.2);
            padding: 0px 2px;
        }
    }

    .sdc_heading {

        span,
        mark {
            font-family: $POPPINS;
            font-size: 20px;
            font-weight: 500;
            color: #000;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        mark {
            background-color: rgba(28, 137, 44, 0.2);
            padding: 0px 2px;
        }
    }

    .sdc_user_info_container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        margin-top: 8px;

        & p {
            font-family: $POPPINS;
            font-size: 14px;
            font-weight: 500;
            color: #000;
            opacity: 0.5;
        }

        & svg {
            font-size: 8px;
            color: #000;
            opacity: 0.5;
        }
    }

    .sdc_follow_btn {
        background-color: $WHITE;
        padding: 6px 25px;
        border-radius: 25px;
        text-transform: none;
        min-width: 125px;

        span {
            font-family: $POPPINS;
            font-size: 16px;
            font-weight: 500;
            color: #1c892c;
        }
    }

    .sdc_follow_icon {
        height: 20px;
    }

    @media screen and (max-width: 480px) {
      padding: 20px;
    }
}