@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";
@import "../constants/base/mixins.scss";
.restaurantGooglemap {
    & .MuiInputBase-input {
        color: $DARK_SHADE2;
    }
    & .googlemap-data {
        position: relative;
        & .suggestion-item,
        & .suggestion-item--active {
            padding: 5px 10px;
            & span {
                color: $GRAY_SHADE4;
                cursor: pointer;
                &:hover {
                    color: $DARK_SHADE2;
                }
            }
        }
        & .googlemap-dropdown {
            background-color: #fff;
            position: absolute;
            top: 0;
            width: 100%;
            color: #000 !important;
            border-radius: 10px;
            padding: 8px 6px;
            z-index: 2;
            overflow-y: auto;
            color: #000;
        }
    }
}
