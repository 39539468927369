.controlContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 20px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  @media screen and (max-width: 768px) {
    padding: 5px 20px;
  }
  .playPauseContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 20%;

    .MuiSvgIcon-root {
      &:hover {
        cursor: pointer;
      }
      &:nth-child(2) {
        background-color: green;
        padding: 10px;
        border-radius: 50%;
      }
      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }
  }
  .sliderContainer {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: 768px) {
      .MuiSvgIcon-root {
        font-size: 15px;
      }
    }

    span {
      color: white;
      font-size: 12px;
      @media screen and (max-width: 768px) {
        font-size: 8px;
      }
    }
  }
  .loopIconContainer {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .volumeContainer {
      position: relative;
      .sliderContainer {
        position: absolute;
        height: 100%;
        background-color: rgba(204, 195, 195, 0.4);
        top: -50px;
        left: 7px;
        padding: 10px 0px;
        display: none;
      }

      &:hover .sliderContainer {
        display: block;
      }
    }
    @media screen and (max-width: 768px) {
      .MuiSvgIcon-root {
        font-size: 15px;
      }
    }
  }
}
