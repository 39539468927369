@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.farm_catelog_receipt_card {
  background-color: $WHITE;
  width: 100%;
  height: 100%;
  border-radius: 17px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.farm_catelog_img_wrapper {
  width: 100%;
  position: relative;
  height: 270px;
  text-align: center;
  img {
    width: 100%;
    height: 270px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
.farm_Catelog_receipt_date {
  background-color: $WHITE;
  position: absolute;
  bottom: 0;
  left: 20px;
  padding: 6px 20px 4px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  p {
    font-size: 12px;
    font-weight: bold;
    font-family: $MONTSERRAT;
  }
}
.farm_catelog_receipt_contents {
  padding: 30px 25px;
  h5 {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 600;
    color: #4cb479;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.farm_catelog_likes_and_comments_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.farm_catelogs_likes_comment_div {
  display: flex;
  align-items: center;
  width: 100%;

  .forum_like_wrapper {
    display: flex;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.farm_catelogs_likes_comment_div:nth-child(2) {
  justify-content: flex-end;
}

.farm_catelog_like_icon {
  color: #008eea;
}
.farm_catelog_comment_icon {
  color: #ffa700;
}
.like_count {
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  margin-right: 3px;
  color: #000;
  padding-top: 2px;
}
.like_comment_text {
  font-size: 13px;
  font-weight: 500 !important;
  padding-top: 2px;
}

.farm_forum_dialog {
  border-radius: 20px !important;
  overflow-y: scroll !important;
  scrollbar-color: $GREEN transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $GREEN;
    border-radius: 20px;
    border: 3px solid transparent !important;
  }

  @media screen and (max-width: 560px) {
    border-radius: 10px !important;
  }
}

.farm_forum_dialog::-webkit-scrollbar {
  display: none;
}
