@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px $GRAY_SHADE5;
  padding: 40px;
  border-radius: 20px;
}
.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: $GRAY_SHADE6 !important;
  font-size: 15px !important;
}
.Normal_User_Regitration_Input {
  padding: 15px !important;
}
.Normal_User_Regitration_Input_Focused {
  border-color: $GREEN !important;
}
.Normal_User_Regitration_Input_Label {
  color: $GRAY_SHADE5;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}
.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: $DARK;
  font-weight: 600 !important;
}
.create_account_btn-text {
  color: $WHITE;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}
.create_account_btn {
  background-color: $GREEN !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}
.anable_create_account_arrows {
  background-color: $DARK_BLUE;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
  font-weight: 600 !important;
}
.disable_create_account_arrows {
  background-color: $GRAY_SHADE1;
  border-radius: 5px;
  padding: 10px 12px;
  color: $WHITE !important;
}
.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
  a {
    width: 100%;
  }
}
