@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.routesWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #f3f0f0;

  @media (max-width: 1024px) {
    display: none;
  }

  a {
    width: 190px;
    padding: 15px 10px;
    object-fit: contain;
    font-family: $MONTSERRAT;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600 !important;
    letter-spacing: 0.9px;
    color: #333;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
  }
}

.toogle_button_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #f3f0f0;
  padding: 20px 30px;

  button {
    background-color: $GREEN;

    &:hover {
      background-color: $GREEN;
    }
  }
}

a.active_route {
  font-weight: 800;
  background-color: rgba(32, 162, 88, 0.09);
  color: #20a258 !important;
}

.web_routing_tabs_container {
  width: 90%;
}

@media (min-width: 960px) {
  .web_routing_tabs_container {
    display: flex;
    justify-content: center !important;
  }
}

@media screen and (max-width: 599px) {
  .web_routing_tab_wrapper {
    display: flex;
    justify-content: center;
  }
}

/*web routing tabs toggle sidebar*/

.web_routing_tabs_sidebar {
  padding: 20px 0;
  background-color: $WHITE;
  width: 280px;

  a {
    width: 100%;
    padding: 12px 40px;
    text-align: left;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: $Black;
    &:hover {
      background-color: rgba(32, 162, 88, 0.06);
      color: #20a258;
    }
  }
}

.web_routing_tabs_sidebar_toggleSidebar {
  left: 0;
}