@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.leave_img_with_text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 10px;

    h3 {
        font-family: $ROUNDGOTHIC_BOOK !important;
        font-size: 30px;
        color: #333;
        text-align: center;
    }

    p {
        text-align: center;
        font-family: $MONTSERRAT;
        font-size: 14px;
        font-weight: 500;
        color: #5a5a5a;

        span {
            color: $GREEN;
            font-weight: bold;
            cursor: pointer;
        }

        a {
            color: $GREEN;
            font-weight: bold;
            cursor: pointer;
            text-decoration: none;
        }
    }
}