@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.check_out_delivery_cards_container {
  box-shadow: 0px 0px 12px 0px #c3c3c3;
  padding: 40px 30px;
  border-radius: 20px;

  button {
    width: 100%;
    padding: 8px 10px !important;
    border-radius: 50px !important;

    span {
      color: $WHITE;
    }
  }
}

.check_out_left_container {
  height: 100%;
  padding: 40px 30px 60px;
}

.check_out_delivery_card {
  border: 2px solid $GRAY_SHADE2;
  padding: 20px;
  border-radius: 5px;

  h6 {
    color: $Black;
    font-weight: 600;
    font-size: 16px;
    padding: 2px 0px;
  }

  p {
    color: $GRAY_SHADE6 !important;
    padding: 2px 0px;
    font-size: 16px;
  }
}

.check_out_delivery_card_actions_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  button {
    width: fit-content;
    padding: 10px !important;
  }

  img {
    cursor: pointer;
  }
}

.non_editable_action_container {
  justify-content: flex-end !important;
}

.check_out_heading {
  font-family: $ROUNDGOTHIC_BOOK;
  font-size: 28px;
  color: #333;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.checkout_address_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 45vh;

  svg {
    color: $GREEN;
  }
}

.pickup_address_hint {
  font-family: $MONTSERRAT !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #333 !important;
  margin-left: 12px !important;
}

.no_address_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 45vh;

  h2 {
    color: $GREEN;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: $MONTSERRAT;
  }

  h5 {
    color: rgba(51, 51, 51, 0.5);
    font-size: 18px;
    text-align: center;
    font-family: $MONTSERRAT;
  }
}

.order_summary_total_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;

  h3 {
    color: #05172c;
    font-size: 22px;
  }

  h4 {
    font-size: 22px;
    font-weight: bold;
    color: #05172c;
    font-family: $PTSans;
  }
}

.check_out_delivery_card_btn_root {
  background-color: $GREEN !important;
}