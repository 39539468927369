@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/mixins.scss";

.sidebar {
  width: 320px;
  padding-top: 45px;
  background-color: $WHITE;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9;
  position: relative;
  overflow-y: auto;
  overflow-x: auto;
  @include scrollbars();
  & .close-icon-wrapper {
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: #f0f0f0;
    &:hover {
      opacity: 0.8;
    }

    @media screen and (min-width: 1025px) {
      display: none;
    }
  }

  & .closeIcon {
    font-size: 25px;
    color: $GREEN;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $WHITE;
    height: calc(100% - 0px);
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
  }
  & .nav-pills {
    & .nav-link {
      width: 100%;
      padding: 12px 55px;
      text-align: left;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 17px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      position: relative;
      & span {
        color: $GRAY_SHADE3;
        font-weight: 600;
      }
      & .icons {
        padding-right: 8px;
      }
      &.active,
      &.show > .nav-link {
        background-color: #e7ffed;
        & span {
          color: $GREEN;
        }
        & .notification {
          color: white;
        }
      }
      & .notification {
        background-color: $GREEN;
        border-radius: 50%;
        padding: 5px 10px;
        height: 30px;
        width: 30px;
        font-size: 14px;
        color: $WHITE;
        position: absolute;
        right: 20px;
      }
    }
  }
  & .bottom-nav {
    & .nav-link {
      & span {
        color: $DARK;
      }
      &.active,
      &.show > .nav-link {
        background-color: transparent;
        & span {
          color: $DARK;
        }
      }
    }
  }

  .ad-campaign-sidebar-btn {
    border: none;
    background-color: transparent;
  }
}

.toggleSidebar {
  display: flex;
}
