@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';
@import '../constants/base/mixins.scss';

.mainModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 20px;
  margin: 0 auto;

  &.modal-md {
    width: 100%;
    max-width: 600px;
    max-height: 600px;
    overflow-y: scroll;
    @include scrollbars();
  }

  &.modal-lg {
    width: 100%;
    max-width: 1100px;
  }

  & .subModalBlock {
    background-color: $WHITE;
    border-radius: $BOX_RADIUS;

    & .modalHeader {
      padding: 25px;
      display: flex;
      align-items: center;

      & h4 {
        text-align: center;
        margin-left: auto;
      }

      & .closeIcon {
        margin-left: auto;
        font-size: 30px;
        cursor: pointer;
        svg {
          color: #333;
        }
      }

      border-bottom: 1px solid #ccc;

      &.selectTypeHeader {
        & h4 {
          margin-left: unset;
          justify-content: center;
          width: 100%;
        }
      }
    }

    & .farmOrderTitle {
      padding: 18px;
    }

    & .restModalContent {
      padding: 25px;
      overflow-y: auto;
      @include scrollbars();

      @media screen and (max-width: 1500px) {
        height: 500px;
      }

      @media screen and (max-width: 768px) {
        padding: 20px;
      }

      & h5,
      & h6,
      & span {
        margin: 8px 0;
        font-weight: 600;
        color: $DARK;
      }

      & h5 {
        & span {
          color: $GREEN;
        }
      }

      & .orderCard {
        background-color: $GRAY_SHADE11;
        padding: 20px 20px 20px 35px;
        border-radius: 15px;
        width: 100%;
        margin-bottom: 15px;

        @media screen and (max-width: 767px) {
          padding: 10px;
        }

        & .topRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;
          }
        }

        & .leftBlock {
          display: flex;
          align-items: center;

          @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
          }
        }

        & .leftContentBlock {
          padding-left: 15px;

          @media screen and (max-width: 767px) {
            padding-left: 0px;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }

        & .OrderImg {
          height: 60px;
          width: 120px;
          object-fit: cover;
          border-radius: 15px;
          object-position: left;

          @media screen and (max-width: 767px) {
            height: 120px;
            width: 100%;
          }
        }
      }

      & .deliveryTimeRow {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      & .addTimeRow {
        display: flex;
        margin-bottom: 20px;

        & h6 {
          font-size: 14px;
        }

        & .timeMinRow {
          background-color: $GRAY_SHADE11;
          padding: 10px 12px;
          font-size: 15px;
          color: $DARK;
          font-weight: 600;
          border-radius: 5px;
          margin-right: 10px;
        }
      }

      & .btnContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;

        & .greenFilledButton,
        .redOutlineButton {
          width: 100%;

          & .MuiButton-root {
            width: 100%;
            padding: 0 5px;
            height: 45px;
          }
        }
      }
    }

    & .adminOrderModalContent {
      padding: 35px;
      overflow-y: auto;
      max-height: 700px;

      @media screen and (max-width: 1500px) {
        max-height: 600px;
      }

      @media screen and (max-width: 1400px) {
        max-height: 400px;
      }

      @media screen and (max-width: 768px) {
        padding: 20px;
      }

      .orderStatusDropdown {
        width: 170px;
        font-family: $MONTSERRAT;
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 500;
        background-color: #f0f0f0;
        border-radius: 8px;

        & > div {
          border-radius: 8px;
        }

        .MuiSelect-filled.MuiSelect-filled {
          padding: 8px 12px;
        }
      }

      .orderPickedUpButton {
        background-color: #e0e0e0;
        padding: 4px 10px;
        text-transform: none;
        width: 140px;

        span {
          color: #5a5a5a;
          font-size: 14px;
          font-family: $MONTSERRAT;
          font-weight: 600;
        }
      }

      .orderPrintLabelButton {
        background-color: #fff;
        padding: 3px 10px;
        text-transform: none;
        width: 140px;
        border: 1px solid #359843;

        span {
          color: #359843;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .trackingContainer {
        h6 {
          font-size: 22px;
          font-weight: 700;
          font-family: $MONTSERRAT;
          color: #5a5a5a;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          font-family: $MONTSERRAT;
          color: #5a5a5a;
        }
      }

      .farmOrderCard {
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.16);
      }

      .farmOrderNotesWrapper {
        h6 {
          font-family: $POPPINS;
          font-size: 16px;
          margin-bottom: 15px;
        }

        textarea {
          resize: none;
          height: 120px;
          width: 100%;
          padding: 10px;
          border-radius: 12px;
          background-color: #f0f0f0;
          font-size: 13px;
          border: 1px solid #d3d3d3;
          outline: none;
        }
      }

      .farmOrderTrackingWrapper {
        .orderTrackingStatus {
          color: #d97e17;
          font-family: $MONTSERRAT;
          font-size: 15px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 10px;
        }

        .orderConfirmationStatus {
          color: $GREEN;
          font-family: $MONTSERRAT;
          background-color: rgba(32,168,88,0.15);
          font-size: 15px;
          font-weight: 700;
          padding: 8px;
          text-align: center;
          margin-bottom: 10px;
        }

        .orderDeliverText {
          color: #5A5A5A;
          font-size: 15px;
          font-family: $MONTSERRAT;
          font-weight: 700;
          word-wrap: break-word
        }
        
        .orderUserDetails {
          display: flex;
          align-items: center;
          gap: 10px;
          padding-top: 10px;

          .orderUserAvatar {
            width: 65px;
            height: 65px;
          }
        }

        .orderUserInfoDetails {
          p {
            color: #5A5A5A;
            font-size: 15px;
            font-family: $POPPINS;
            word-wrap: break-word
          }

          p:nth-child(1) {
            font-weight: 600;
          }

          p:nth-child(2) {
            font-weight: 400;
          }
        }
      }

      & .priceSection {
        margin-top: -65px;

        @media screen and (max-width: 768px) {
          margin-top: 0;
        }
      }

      & h3 {
        padding-left: 20px;
        font-family: $ROUNDGOTHIC_MEDIUM;
        font-weight: 100;
      }

      & .contentHeader {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        & .farmProfile {
          height: 60px;
          width: 60px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      & .farmCard {
        margin-bottom: 20px;

        & h3 {
          padding-left: 0;
          font-size: 22px;
        }

        & .farmCardContent {
          display: flex;
          padding-top: 20px;

          @media screen and (max-width: 767px) {
            flex-direction: column;
          }

          & .imgBlock {
            border-radius: $FORM_BORDER_RADIUS;

            & .farmSubProfile {
              height: 180px;
              width: 180px;
              object-fit: cover;
              border-radius: $FORM_BORDER_RADIUS;
            }

            @media screen and (max-width: 768px) {
              margin-bottom: 10px;
            }
          }

          & .rightBlock {
            padding-left: 20px;

            @media screen and (max-width: 767px) {
              padding-left: 0;
            }

            & p {
              width: 85%;
              font-family: $POPPINS;
              font-size: 14px;
              line-height: 1.89;
              color: #5a5a5a;

              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }

            & h5 {
              margin: 20px 0;
              color: $GREEN;
              font-family: $MONTSERRAT;
              font-weight: 700;
              font-size: 18px;

              & span {
                color: $GREEN;
                font-family: $MONTSERRAT;
                font-weight: 700;
              }
            }
          }
        }
      }

      & .priceCard {
        box-shadow: 1px 1px 6px 10px $GRAY_SHADE8 !important;

        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }

        & .cardContent {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        & .cardRow {
          display: flex;
          justify-content: space-between;
          width: 100%;

          & h6 {
            font-weight: 400;
            font-family: $MONTSERRAT;
            font-size: 18px;
          }
        }

        & .cardTotalRow {
          & h6 {
            font-weight: 500 !important;
            font-size: 20px;
            color: #000;
          }
        }
      }

      & .greenFilledButton {
        & .MuiButton-root {
          width: 100%;
        }
      }
    }

    & .learningAddPartModalContent {
      padding: 50px;
      overflow-y: auto;

      label {
        color: $DARK;
        font-weight: 600;
      }

      & input::placeholder,
      & textarea::placeholder {
        color: $GRAY_SHADE6;
        font-weight: 400;
        opacity: 1;
      }

      & .btnContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;

        & .MuiButton-root {
          width: 100%;
          height: 50px;
        }

        & .greenOutlineButton,
        & .greenContained {
          width: 100%;
        }
      }

      & .lesson-row {
        display: flex;
        align-items: center;

        & p {
          padding-left: 20px;
        }
      }

      & .titleBox {
        padding: 10px 0;
        margin: 20px 0;
        border-top: 1px solid $GREEN_SHADE11;
        border-bottom: 1px solid $GREEN_SHADE11;
      }

      & .video-imgblock {
        display: flex;
        flex-direction: column;

        & .lessonImg,
        & video {
          width: 180px;
          height: 120px;
          object-fit: cover;
          border-radius: 10px;
        }

        & .captionBlock {
          width: 180px;
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          align-items: center;

          & .deleteIcon {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }

        & .img-title {
          width: 135px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        & .lessonImg {
          width: 180px;
          height: 120px;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      & .customTextAreafield {
        & textarea {
          margin-bottom: 0;
        }
      }
    }

    & .selectTaskModalContent {
      .carContainer {
        .card {
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          box-shadow: 0px 0px 8px 0px #c0b4b4;
          border: 2px solid #fff;
          cursor: pointer;

          .imgContainer {
            img {
              width: 62px;
              height: 62px;
            }
          }

          p {
            font-size: 13px;
            font-weight: 600;
            color: #3f526d;
            margin-top: 10px;
            width: 100%;
            text-align: center;
          }
        }

        &.learningTaskCheckbox {
          input {
            &:checked {
              &+.card {
                border: 2px solid #0218fc;
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    & .mcqaModalContantainer {
      .answerListContainer {
        width: 100%;

        .mcqaHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            color: #676b72 !important;
            font-size: 16px;
            width: 100%;
            text-align: left;
            margin: 10px 0;
          }

          & .checkboxContainer {
            display: flex;
            align-items: center;

            label {
              color: #676b72 !important;
            }
          }

          .customToggleButton {
            .switch {
              margin-top: 0 !important;
            }
          }
        }

        h3 {
          color: #464a53;
          font-size: 16px;
          width: 100%;
          text-align: left;
          font-weight: 700;
        }

        .answerLists {
          .answer {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin: 10px 0;

            .contentContainer {
              border: 1px solid rgb(175, 170, 170);
              border-radius: 5px;
              padding: 5px 10px;
              width: 100%;
              margin: 0 5px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .imgContainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;

                img {
                  width: 65px;
                  height: 40px;
                  object-fit: contain;
                }

                span {
                  &:nth-child(2) {
                    color: #52647c;
                    font-weight: bold;
                  }

                  &:nth-child(3) {
                    color: #52647c;
                  }
                }
              }

              .iconContainer {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .MuiFormControlLabel-root {
                  margin: 0 0 0 5px;
                }

                .icon {
                  .MuiSvgIcon-root {
                    transform: rotate(45deg);
                    color: #cfcfcf;
                  }
                }
              }
            }

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;

              .edit-icon {
                width: 30px;
                height: 40px;
              }

              .MuiButton-text {
                padding: 0 !important;
              }

              .MuiButton-root {
                min-width: unset !important;
              }
            }
          }
        }

        .btnContainer {
          width: 100%;
          margin: 20px 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .addbtn {
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            background: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0),
              #20a258;
            background-position: center;
            background-size: 50% 2px, 2px 50%;
            /*thickness = 2px, length = 50% (25px)*/
            background-repeat: no-repeat;
          }

          span {
            &:nth-child(2) {
              font-weight: 600;
              margin-left: 5px;
              color: #414141;
            }
          }
        }

        h6 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 5px;
        }

        .taskPoint {
          h3 {
            margin-bottom: 10px;
          }
        }
      }
    }

    & .trueFalseModalConent {
      .taskPoint {
        h6 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      .answersListContainer {
        margin-top: 20px;

        h2 {
          font-size: 18px;
          margin: 10px 0;
        }

        p {
          color: #595c64;
          margin-bottom: 10px;
        }

        .answer {
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          margin: 10px 0;

          .contentContainer {
            border-radius: 5px;
            padding: 5px 15px;
            width: 100%;
            margin: 0 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .radioButtonContainer {
              .MuiFormControlLabel-root {
                margin: 0;
              }
            }

            &.true {
              background-color: #e7f1e8;
              border: 1px solid #3e893d;

              .answerDescription {
                color: #3e893d;
              }
            }

            &.false {
              background-color: #fbd8d8;
              border: 1px solid #f35c5f;

              .answerDescription {
                color: #f35c5f;
              }
            }
          }
        }

        .iconContainer {
          & .Mui-checked {
            position: relative;
            padding: 25px;

            &::before {
              content: ' ';
              position: absolute;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background-color: #3f526d;
            }

            & .MuiRadio-root {
              padding: 9px;
            }

            & .MuiIconButton-label {
              position: relative;
              border: 0;
              -ms-transform: rotate(45deg);
              /* IE 9 */
              -webkit-transform: rotate(45deg);
              /* Chrome, Safari, Opera */
              transform: rotate(45deg);

              .MuiSvgIcon-root {
                display: none;
              }

              &::before {
                content: '';
                position: absolute;
                width: 3px;
                height: 11px;
                background-color: #fff;
                left: 0px;
                top: -7px;
              }

              &::after {
                content: '';
                position: absolute;
                width: 7px;
                height: 3px;
                background-color: #fff;
                left: -5px;
                top: 2px;
              }
            }
          }
        }
      }
    }

    & .longAnsModalContent {
      .taskPoint {
        h6 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      .taskTypeContainer {
        margin: 20px 0;

        h2 {
          font-size: 18px;
          margin: 15px 0;
        }

        .taskType {
          border: 1px solid rgba(193, 193, 193, 0.368627451);
          padding: 0 20px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 10px;

          .MuiFormControlLabel-root {
            margin: 0;
          }
        }
      }

      .iconContainer {
        & .Mui-checked {
          position: relative;
          padding: 25px;

          &::before {
            content: ' ';
            position: absolute;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: #3f526d;
          }

          & .MuiRadio-root {
            padding: 9px;
          }

          & .MuiIconButton-label {
            position: relative;
            border: 0;
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Chrome, Safari, Opera */
            transform: rotate(45deg);

            .MuiSvgIcon-root {
              display: none;
            }

            &::before {
              content: '';
              position: absolute;
              width: 3px;
              height: 11px;
              background-color: #fff;
              left: 0px;
              top: -7px;
            }

            &::after {
              content: '';
              position: absolute;
              width: 7px;
              height: 3px;
              background-color: #fff;
              left: -5px;
              top: 2px;
            }
          }
        }
      }
    }

    & .learningPurchaseModalContent {
      padding: 40px;
      overflow-y: auto;
      height: 600px;
      width: 665px;

      @media screen and (max-width: 767px) {
        width: 300px;
        padding: 10px;
      }

      & .profile-row {
        display: flex;
        flex-direction: column;
        width: 116px;
        text-align: center;

        &.profile-right-row {
          align-items: flex-start;
          justify-content: center;
          width: auto;
        }

        & .purchaseProfile {
          height: 116px;
          width: 116px;
          object-fit: contain;

          @media screen and (max-width: 767px) {
            width: 90px;
            height: 90px;
          }
        }

        & .farmTech {
          width: 127px;
          height: 69px;
          object-fit: cover;
          border-radius: 8px;
          margin: 15px 0;

          @media screen and (max-width: 767px) {
            width: 100px;
            height: 50px;
          }
        }

        & h4 {
          color: #000;
          font-size: 14px;

          @media screen and (max-width: 767px) {
            font-size: 13px;
          }
        }

        & h5 {
          color: #5a5a5a;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.93;
        }
      }

      & .purchaseForm {
        margin-top: 20px;

        & label {
          margin-bottom: 10px;
        }

        & .outlineTextfield {
          margin-bottom: 30px;

          & .MuiFormControl-root {
            border-radius: 8px;
            border: solid 1px #979797 !important;
            height: 50px;
          }
        }

        & .btnRow {
          & .MuiFormControl-root {
            height: 50px;
          }

          & .MuiButtonBase-root {
            min-width: 162px;
            height: 55px;

            @media screen and (max-width: 767px) {
              min-width: 100%;
            }

            & .MuiButton-label {
              & span {
                color: #fff;
              }
            }

            &:hover {
              box-shadow: 1px 1px 12px #d8d5d5;
              background-color: initial;

              & .MuiButton-label {
                & span {
                  color: inherit;
                }
              }
            }
          }
        }
      }
    }
  }
}

.orderStatusPaper {
  margin-top: 5px;
  border: 1px solid #d3d3d3;

  ul {
    padding: 0px;
  }

  li {
    font-size: 14px;
    font-family: $MONTSERRAT;
    color: #5a5a5a;
    padding: 6px 12px;
  }
}

.learningModalBlocks {
  & .subModalBlock {
    background-color: $WHITE;
    border-radius: $BOX_RADIUS;

    & .modalHeader {
      padding: 25px;
      display: flex;
      align-items: center;

      & h4 {
        text-align: center;
        margin-left: auto;
      }

      & .closeIcon {
        margin-left: auto;
        font-size: 30px;
        cursor: pointer;
      }

      border-bottom: 1px solid #ccc;

      &.selectTypeHeader {
        & h4 {
          margin-left: unset;
          justify-content: center;
          width: 100%;
        }
      }
    }

    & .learningAddPartModalContent {
      padding: 20px;
      overflow-y: auto;

      label {
        color: $DARK;
        font-weight: 600;
      }

      & input::placeholder,
      & textarea::placeholder {
        color: $GRAY_SHADE6;
        font-weight: 400;
        opacity: 1;
      }

      & .btnContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;

        & .MuiButton-root {
          width: 100%;
          height: 50px;
        }

        & .greenOutlineButton,
        & .greenContained {
          width: 100%;
        }
      }

      & .lesson-row {
        display: flex;
        align-items: center;

        & p {
          padding-left: 20px;
        }
      }

      & .titleBox {
        padding: 10px 0;
        margin: 20px 0;
        border-top: 1px solid $GREEN_SHADE11;
        border-bottom: 1px solid $GREEN_SHADE11;
      }

      & .video-imgblock {
        display: flex;
        flex-direction: column;

        & .lessonImg,
        & video {
          width: 180px;
          height: 120px;
          object-fit: cover;
          border-radius: 10px;
        }

        & .captionBlock {
          width: 180px;
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          align-items: center;

          & .deleteIcon {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }

        & .img-title {
          width: 135px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        & .lessonImg {
          width: 180px;
          height: 120px;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      & .customTextAreafield {
        & textarea {
          margin-bottom: 0;
        }
      }
    }

    & .mcqaModalContantainer {
      .answerListContainer {
        width: 100%;
        margin-top: 20px;

        .mcqaHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            color: #676b72 !important;
            font-size: 16px;
            width: auto;
            text-align: left;
            margin: 10px 0;
          }

          & .checkboxContainer {
            display: flex;
            align-items: center;

            label {
              color: #676b72 !important;
            }
          }

          .customToggleButton {
            .switch {
              margin-top: 0 !important;
              margin-left: 10px !important;
            }
          }
        }

        h3 {
          color: #464a53;
          font-size: 16px;
          width: 100%;
          text-align: left;
          font-weight: 700;
        }

        .answerLists {
          .answer {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin: 10px 0;

            & .regRestInfoForm {
              position: relative;
              width: 100%;
              margin-left: 10px;
            }

            & .MuiFormControl-root {
              height: 53px;
            }

            .contentContainer {
              position: absolute;
              top: 8px;
              right: -5px;
              border-radius: 5px;
              padding: 5px 10px;
              width: 75px;
              margin: 0 5px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              &.contentContainer1 {
                top: 2px;
                left: -6px;
                width: 100%;

                & .iconContainer {
                  width: 70px;
                }
              }

              .imgContainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background-color: #fff;

                img {
                  width: 60px;
                  height: 40px;
                  object-fit: contain;

                  @media screen and (max-width: 448px) {
                    width: 30px;
                  }

                  @media screen and (max-width: 375px) {
                    width: 20px;
                    height: 30px;
                  }
                }

                & .imageTitle {
                  width: 175px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  @media screen and (max-width: 540px) {
                    width: 100px;
                  }

                  @media screen and (max-width: 448px) {
                    width: 80px;
                  }

                  @media screen and (max-width: 375px) {
                    width: 50px;
                  }
                }

                span {
                  &:nth-child(2) {
                    color: #52647c;
                    font-weight: bold;
                    padding-left: 10px;
                  }

                  &:nth-child(3) {
                    color: #52647c;
                  }
                }
              }

              .iconContainer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                .customFileUpload {
                  padding: 0;
                  margin: 0;
                  border: 0;

                  label {
                    padding: 0;
                    margin: 0;
                  }

                  span {
                    padding: 0;
                  }
                }

                .iconBlock {
                  display: flex;
                  width: 100%;
                  justify-content: flex-end;
                }

                .fileUpload {
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                }

                .MuiFormControlLabel-root {
                  margin: 0 0 0 5px;
                }

                & .MuiFormControl-root {
                  border-radius: 0 !important;
                  border-left: 0 !important;
                  border-right: 0 !important;
                }

                .icon {
                  .MuiSvgIcon-root {
                    transform: rotate(45deg);
                    color: #cfcfcf;
                  }
                }

                .MuiSvgIcon-root {
                  color: #c8cace;
                  margin-right: 10px;
                }

                & .customLabelCheckbox {
                  position: relative;

                  & .MuiSvgIcon-root {
                    display: none;
                  }

                  & .MuiIconButton-label {
                    content: '';
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    border: 2px solid#ccc;
                    border-radius: 50%;
                    margin: 0 auto;
                  }
                }

                & .Mui-checked {
                  position: relative;
                  padding: 0;

                  &::before {
                    content: ' ';
                    position: absolute;

                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: #3f526d;
                  }

                  & .MuiIconButton-label {
                    position: relative;
                    border: 0;

                    -ms-transform: rotate(45deg);
                    /* IE 9 */
                    -webkit-transform: rotate(45deg);
                    /* Chrome, Safari, Opera */
                    transform: rotate(45deg);

                    &::before {
                      content: '';
                      position: absolute;
                      width: 3px;
                      height: 11px;
                      background-color: #fff;
                      left: 12px;
                      top: 6px;
                    }

                    &::after {
                      content: '';
                      position: absolute;
                      width: 7px;
                      height: 3px;
                      background-color: #fff;
                      left: 7px;
                      top: 14px;
                    }
                  }
                }
              }
            }

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;

              .edit-icon {
                width: 30px;
                height: 40px;
                margin-left: 10px;
              }

              .mcqDeleteIcon {
                width: 20px;
                height: 24px;
              }

              .MuiButton-text {
                padding: 0 !important;
              }

              .MuiButton-root {
                min-width: unset !important;
              }
            }
          }
        }

        .btnContainer {
          width: 100%;
          margin: 20px 0;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .addbtn {
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            background: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0),
              #20a258;
            background-position: center;
            background-size: 50% 2px, 2px 50%;
            /*thickness = 2px, length = 50% (25px)*/
            background-repeat: no-repeat;
          }

          span {
            &:nth-child(2) {
              font-weight: 600;
              margin-left: 5px;
              color: #414141;
            }
          }
        }

        h6 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 5px;
        }

        .taskPoint {
          h3 {
            margin-bottom: 10px;
          }
        }
      }
    }

    & .trueFalseModalConent {
      .taskPoint {
        h6 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      .answersListContainer {
        margin-top: 20px;

        h2 {
          font-size: 18px;
          margin: 10px 0;
        }

        p {
          color: #595c64;
          margin-bottom: 10px;
        }

        .answer {
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          margin: 10px 0;

          .contentContainer {
            border-radius: 5px;
            padding: 5px 15px;
            width: 100%;
            margin: 0 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .radioButtonContainer {
              .MuiFormControlLabel-root {
                margin: 0;
              }
            }

            &.true {
              background-color: #e7f1e8;
              border: 1px solid #3e893d;

              .answerDescription {
                color: #3e893d;
              }
            }

            &.false {
              background-color: #fbd8d8;
              border: 1px solid #f35c5f;

              .answerDescription {
                color: #f35c5f;
              }
            }
          }
        }

        .iconContainer {
          & .Mui-checked {
            position: relative;
            padding: 25px;

            &::before {
              content: ' ';
              position: absolute;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background-color: #3f526d;
            }

            & .MuiRadio-root {
              padding: 9px;
            }

            & .MuiIconButton-label {
              position: relative;
              border: 0;
              -ms-transform: rotate(45deg);
              /* IE 9 */
              -webkit-transform: rotate(45deg);
              /* Chrome, Safari, Opera */
              transform: rotate(45deg);

              .MuiSvgIcon-root {
                display: none;
              }

              &::before {
                content: '';
                position: absolute;
                width: 3px;
                height: 11px;
                background-color: #fff;
                left: 0px;
                top: -7px;
              }

              &::after {
                content: '';
                position: absolute;
                width: 7px;
                height: 3px;
                background-color: #fff;
                left: -5px;
                top: 2px;
              }
            }
          }
        }
      }
    }

    & .longAnsModalContent {
      .taskPoint {
        h6 {
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      .taskTypeContainer {
        margin: 20px 0;

        h2 {
          font-size: 18px;
          margin: 15px 0;
        }

        & .customTextfield {
          & .MuiFormControl-root {
            height: 54px;
          }
        }

        & .solutionRow {
          margin-top: 10px;

          & label {
            margin: 0;
          }

          & .css-1nvn3ta-JoyTextarea-root {
            height: 185px !important;
            --Textarea-focusedHighlight: inherit;

            & p {
              text-align: right !important;
              color: #484848;
            }
          }

          & textarea {
            font-family: $POPPINS;
            overflow-y: scroll !important;
            @include scrollbars();

            & .MuiTextarea-variantOutlined {
              &:focus {
                border: 1px solid $ORANGE !important;
              }
            }
          }

          & .customTextAreafield {
            position: relative;
            width: 100%;

            & .characterLength {
              position: absolute;
              right: 15px;
              bottom: 15px;
              color: #484848;
            }
          }
        }

        .taskType {
          border: 1px solid rgba(193, 193, 193, 0.368627451);
          padding: 2px 30px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 0 10px 10px 0;

          .MuiFormControlLabel-root {
            margin: 0;
          }

          p {
            white-space: nowrap;
          }
        }
      }

      .iconContainer {
        & .Mui-checked {
          position: relative;
          padding: 25px;

          &::before {
            content: ' ';
            position: absolute;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: #3f526d;
          }

          & .MuiRadio-root {
            padding: 9px;
          }

          & .MuiIconButton-label {
            position: relative;
            border: 0;
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Chrome, Safari, Opera */
            transform: rotate(45deg);

            .MuiSvgIcon-root {
              display: none;
            }

            &::before {
              content: '';
              position: absolute;
              width: 3px;
              height: 11px;
              background-color: #fff;
              left: 0px;
              top: -7px;
            }

            &::after {
              content: '';
              position: absolute;
              width: 7px;
              height: 3px;
              background-color: #fff;
              left: -5px;
              top: 2px;
            }
          }
        }

        & .MuiFormGroup-root {
          flex-direction: row;
          width: 100%;
          flex-wrap: nowrap;
        }
      }
    }
  }
}

.customTextAutosize {
  & .css-1nvn3ta-JoyTextarea-root {
    --Textarea-focusedHighlight: inherit;
    max-height: 200px !important;

    & p {
      text-align: right !important;
      color: #484848;
      font-size: 17px;
    }
  }

  & textarea {
    font-family: $POPPINS;
    overflow-y: scroll !important;
    @include scrollbars();
  }
}

.mcqWordInput {
  & .MuiInputBase-input {
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;

    @media screen and (max-width: 520px) {
      width: 60%;
    }

    @media screen and (max-width: 4100px) {
      width: 40%;
    }
  }
}