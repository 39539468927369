@import "../../../../web/src/assets/css/constants/base/colors.scss";
.breadcrumb_component{
    padding-top:20px!important;
    padding-bottom: 10px!important;
    font-weight: 600 !important;

    @media screen and (max-width: 768px) {
      display: none;
    }
}

.breadcrumb__link{
    color:$Black;
    &:hover{
        color:$Black;
    }
}
.breadcrumb_active_link{
    color:$GREEN;
    &:hover{
        color:$GREEN;
    }
}