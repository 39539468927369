@import "../../../../web/src/assets/css/constants/base/global.scss";

.purchases_course_heading {
    font-family: $ROUNDGOTHIC_BOOK !important;
    font-size: 30px !important;
    color: #333 !important;
    margin-top: 30px !important;
}

.cc_container {
    cursor: pointer;
}

.MuiTypography-root.cc_course_name.MuiTypography-h5 {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-top: 1rem;
}

.MuiTypography-root.cc_author_name.MuiTypography-h6 {
    font-family: $MONTSERRAT;
    font-weight: 500;
    color: #746f6f;
    margin-bottom: 2rem;
    font-size: 15px;
    margin-top: 2px;
}

.default_course_container {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
    width: 100%;
    height: 200px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-weight: 600;
        font-size: 2.3rem;
        color: #fff;
        letter-spacing: 5px;
    }
}

.fav_page_empty_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    padding: 30px;
    height: 40vh;
    border: 2px solid #f0f0f0;
    border-radius: 15px;
    margin-top: 10px;

    p {
        font-weight: 600;
        font-size: 1.6rem;
        font-family: $MONTSERRAT;
        color: #1b1c1e;
        text-align: center;
    }

    .MuiButton-root:hover {
        background-color: $GREEN;
        opacity: 0.9;
    }

    button {
        background-color: $GREEN;
        padding: 8px 30px;
        border-radius: 20px;
        text-transform: none;

        span {
            color: #fff
        }
    }
}