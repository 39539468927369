@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";
.customChipArrayList {
  padding: 0 !important;
  & .MuiChip-root {
    width: 100%;
    justify-content: space-between;
    padding: 22px 15px;
    border-radius: 40px;
    background-color: transparent !important;
    border: 1px solid $GRAY_SHADE1;
    font-size: 17px;
    cursor: pointer;
    &:hover{
      border: 1px solid $GREEN;
    }
    & span {
      color: $DARK_SHADE1;
      font-weight: 500;
    }
  }
}
