@import "../../../../web/src/assets/css/constants/base/index.scss";

.customTab {
  & .MuiTab-root {
    padding: 18px 12px !important;
  }

  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      &.Mui-selected {
        .MuiTab-wrapper {
          color: $GREEN;
        }
      }
    }
  }
}
.row {
  display: flex;
}
.hide {
  display: none;
}
.otpContainer {
  &.customOtpContainer {
    .otpForm {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .otpText {
        font-family: $POPPINS;
        font-weight: 400;
        font-size: 1.2rem;
      }
      input {
        background-color: transparent !important;
        border: 1px solid #c1c1c15e;
        border-radius: 8px;
        margin-right: 15px;
        outline: 0;
        height: 45px;
        width: 45px !important;
        color: $GRAY_SHADE10;
        &.disabledInput {
          cursor: not-allowed;
          opacity: 0.8;
        }
        &.errorInput {
          border-color: $RED;
        }
      }
    }
  }
}
.customSwitch {
  & .switchLabel {
    white-space: nowrap;
    font-size: 15px;
  }
  & .MuiSwitch-input {
    display: block;
  }
  & .MuiSwitch-switchBase {
    &.Mui-checked {
      & + .MuiSwitch-track {
        opacity: 1;
      }
    }
  }
  & .MuiSwitch-track {
    background-color: $GREEN;
    & .Mui-checked {
      background-color: $GREEN;
    }
  }
  & .MuiSwitch-colorSecondary {
    &.Mui-checked {
      & + .MuiSwitch-track {
        background-color: $GREEN;
      }
    }
  }
}
.farmDetailsTabPanel {
  background-color: $WHITE;
  & .restContactNumber {
    & label {
      font-weight: 600;
    }
    & .customTextAreafield {
      & textarea {
        border: 1px solid $GRAY_SHADE7;
        color: $DARK;
        font-weight: 100;
        font-size: 14px;
        &::placeholder {
          color: #adb0b8;
          font-weight: 100;
        }
      }
    }
  }
  & .contactRestInput {
    position: relative;
    & .errorMsg {
      position: absolute;
      margin-top: 3px;
    }
  }
  & .regInfoContainer {
    padding: 30px;
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
    @media screen and (max-width: 560px) {
      padding: 0px 20px 20px 20px;
    }
    & .greenContained {
      & .Mui-disabled {
        cursor: not-allowed;
        pointer-events: auto;
        background-color: $GREEN !important;
        opacity: 0.7;
        & span {
          color: $WHITE !important;
        }
      }
    }
    & .farmSaveButton {
      @media screen and (max-width: 470px) {
        & .MuiButton-root {
          padding: 2px !important;
        }
      }
    }

    & .ordinateHeader {
      @media screen and (max-width: 767px) {
        border: 0 !important;
        line-height: initial !important;
      }
      & span {
        font-size: 18px;
        font-family: $POPPINS;
        @media screen and (max-width: 767px) {
          font-size: 15px;
        }
      }
    }
    & .MuiButton-root {
      padding: 8px 30px !important;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  & .MuiBox-root {
    padding: 0;
  }
  & .restContactNumber {
    & h6 {
      color: $DARK !important;
    }
  }
  & .headerFarm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $GRAY_SHADE7;
    padding: 20px;

    h4 {
      font-size: 22px;
    }

    & span {
      font-size: 17px;
      padding-left: 0;
      color: $ORANGE;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  & .restContactNumber,
  & .ownerDetailsContainer {
    & p {
      color: #464a53;
      font-family: $POPPINS;
      font-size: 15px !important;
      padding: 0 !important;
      margin: 0 !important;
      font-weight: 600;
    }
  }
  & .phoneDetails {
    width: 100%;
    display: flex;
    align-items: center !important;

    & .phoneDetailsRightBlock {
      align-items: center !important;
      & .otpContainer {
        width: auto !important;
      }
    }
    & .phoneDetailsBlock {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      grid-gap: 20px !important;
      gap: 20px !important;
      & .grayBackGroundButton {
        display: flex;
        align-items: center;
        width: 50%;
      }
    }
  }

  & hr {
    background-color: $GRAY;
  }
  & .bannerImgContainer {
    display: flex;
    flex-direction: column;
    & .uploadSubtitle {
      font-family: $POPPINS;
      color: #464a53;
      font-weight: 400;
    }
    & .fileUploadContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      &.pdfFileUploadContainer {
        margin-top: 10px;
      }
    }
    & .btnContainer {
      margin-bottom: 0;
      padding: 0;
    }
    & h6 {
      color: $GREEN;
      font-size: 22px;
      font-weight: bold;
    }
    & .customFileUpload {
      max-width: 150px;
      padding: 10px 20px;
      height: 125px;
    }
    & .fileUploadLabel {
      & span {
        white-space: nowrap;
      }
    }

    & .FileUpload,
    & .headerImageBlock {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    & .headerImage,
    & .headerImageBlock {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      word-break: break-all;
      @media screen and (max-width: 560px) {
        margin-top: 10px;
      }
      &.farmHeaderImage {
        margin-right: 10px;
      }
      & .correctIconInput {
        &:checked {
          & ~ .correctIconLabel {
            opacity: 0;
          }
        }
      }
      & .correctIconLabel {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-radius: 15px;
      }
      & .correct_icon {
        width: 25px;
        height: 25px;
        object-fit: contain;
      }
      & h6 {
        position: absolute;
        bottom: -25px;
        font-size: 13px;
        color: $DARK;
        opacity: 0.8;
      }
      &.headerPdfFileBlock {
        & h6 {
          bottom: 5px;
          left: 5px;
          font-weight: bold;
        }
      }
      & .newUploadFarmImage {
        justify-content: normal !important;
        align-items: normal !important;
        word-break: normal !important;
        overflow: unset !important;
        margin-top: 10px;
      }
    }
    & .newUploadFarm2 {
      @media screen and (max-width: 560px) {
        margin-top: -9px;
      }
    }
    & .newUploadFarm {
      justify-content: normal;
      align-items: normal;
      word-break: normal;
      flex-wrap: wrap;
      @include scrollbars;
      & .farmImg {
        @media screen and (max-width: 400px) {
          width: 120px;
          height: 110px;
        }
        @media screen and (max-width: 350px) {
          width: 100px;
          height: 100px;
        }
      }
      & .pdfFileBlock {
        justify-content: normal;
        align-items: normal;
        word-break: normal;
      }
    }
    & .newUploadFarmCustom {
      & .customFileUpload {
        @media screen and (max-width: 400px) {
          width: 120px;
          height: 110px;
        }
        @media screen and (max-width: 350px) {
          width: 100px;
          height: 100px;
        }
      }
    }
    & .farmImg {
      width: 140px;
      height: 125px;
      object-fit: cover;
      border-radius: 10px;
    }
    & .pdfFileBlock {
      border: 1px solid $GRAY_SHADE3;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      & .download_icon {
        position: absolute;
        top: 9px;
        right: 32px;
        border: 0;
        outline: 0;
        & img {
          height: 16px;
          width: 16px;
          object-fit: contain;
        }
      }
      & .close_icon {
        position: absolute;
        top: 10px;
        right: 10px;
        border: 0;
        z-index: 0 !important;
        cursor: pointer;
        &::before,
        &::after {
          position: absolute;
          left: 15px;
          content: " ";
          height: 22px;
          width: 4px;
          background-color: $WHITE;
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
      & .farmImg {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & .pdf_icon {
        height: 50px;
      }
    }
  }
  & .deliveryDetailsBlock {
    & h6 {
      font-size: 20px;
      text-align: left;
      font-weight: bold;
    }
    & p {
      color: $DARK;
      font-size: 15px;
      padding: 20px 0;
    }
  }

  & .contactRow {
    margin-top: 40px;
    margin-bottom: -5px;
  }

  & .restContactNumber {
    .conactNumberForm {
      margin-top: 10px;
      width: 100% !important;
      display: flex;
      justify-content: flex-start;
      gap: 20px !important;
      & .greenContained {
        padding: 0px 0px;
        & .MuiButton-root {
          padding: 12px 20px !important;
        }
      }
    }
    .customTextfield {
      & .countryCodeIcon {
        border-right: 1px solid $GRAY_SHADE4;
        width: 100%;
        padding-right: 20px;
        p {
          font-size: 20px !important;
          font-weight: 700;
          color: $DARK;
        }
      }
    }
  }

  .ownerDetailsContainer {
    .phoneDetails {
      .customTextfield {
        & .countryCodeIcon {
          border-right: 1px solid $GRAY_SHADE4;
          width: 100%;
          padding-right: 20px;
          p {
            font-size: 20px !important;
            font-weight: 700;
            color: $DARK;
          }
        }
      }
    }
  }
}

.farmOwnerDetailsWrapper {
  .countryCodeIcon {
    border-right: 1px solid $GRAY_SHADE4;
    width: 100%;
    padding-right: 20px;
    p {
      font-size: 20px !important;
      font-weight: 700;
      color: $DARK;
    }
  }
}

.customTextfield {
  & .textFieldMultiline {
    font-size: 14px !important;
  }
}

.remove_icon::after,
.remove_icon::before {
  background-color: #000 !important;
  width: 2px !important;
}

.deliveryDetailBlocks {
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  @include scrollbars();
  & .deliveryDetailRow {
    display: flex;
    justify-content: flex-start;
    background-color: $GRAY_SHADE14;
    border-radius: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding: 0px 25px;
    @media screen and (max-width: 1024px) {
      padding: 10px 0;
    }
    & .deliveryBlocks {
      padding: 10px;
      text-align: left;
      @media screen and (max-width: 768px) {
        padding: 0px 15px;
      }
    }
    & .deliveryBlock1 {
      width: 42%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      & h6 {
        font-weight: 600;
        font-size: 17px;
        width: 400px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: $POPPINS;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
          width: 100%;
        }
      }
    }
    & .deliveryBlock2 {
      width: 42%;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 10px 15px;
      }
      & h6 {
        font-weight: 400;
        font-size: 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: $POPPINS;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
          width: 100%;
        }
      }
    }
    & .deliveryBlock3 {
      width: 8%;
      border: none;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
      @media screen and (max-width: 767px) {
        width: 50%;
      }
      & a {
        color: $RED;
        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
    & .deliveryBlock4 {
      width: 8%;
      border: none;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
      @media screen and (max-width: 767px) {
        width: 50%;
        text-align: right;
      }
      & a {
        color: $GREEN;
        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }
    }

    & .icons-btn {
      margin: 0 15px;
      &.MuiButton-root {
        padding: 0;
        min-width: 0;
      }
      img {
        height: 25px;
        width: 25px;
        object-fit: contain;
      }
    }
  }
}

.restaurant_edit_bank_select {
  & .MuiInputBase-root {
    width: 100%;

    .MuiSvgIcon-root {
      display: block;
    }

    .MuiSelect-select {
      &.MuiSelect-select {
        font-weight: 500;
        font-size: 16px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    svg {
      color: #000 !important;
    }
  }
}

.res_edit_green_contained {
  & .MuiButton-root {
    & .MuiButton-label {
      & span {
        font-size: 15px;
        font-weight: 800;
        padding: 5px 30px;
      }
    }

    & .plus_icon {
      display: none;
    }
  }
}

.res_edit_avail_wrapper {
  margin: 15px 0px 30px 0px;

  & .MuiButton-root {
    border-radius: 30px;

    & .MuiButton-label {
      & span {
        padding: 0px 30px;
        font-weight: 500 !important;
      }
    }
  }
}
.farmImgHead {
  margin-bottom: 10px !important;
}

.outletContainer {
  @media screen and (max-width: 560px) {
    column-gap: 40px;
    row-gap: 10px;
  }
}
.productVariety {
  margin-top: 25px;
  & .productVarietyGrid {
    @media screen and (min-width: 560px) {
      margin-right: 25px;
    }
  }
}
