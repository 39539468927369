@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.custom_google_place_textfield {
    border: 1px solid #c1c1c15e !important;
    background-color: #fff !important;
    border-radius: 5px !important;
    padding: 16px !important;
    font-family: $POPPINS !important;
    font-size: 14px !important;
    height: 50px !important;

    & input {
        font-family: $POPPINS !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    & input::placeholder {
        color: #abafb3 !important;
        font-size: 14px !important;
        font-family: $POPPINS !important;
    }

}

.custom_google_place_detect {
    border: none;
    background-color: transparent;
    outline: none;

    span {
        display: flex;
        gap: 8px;

        p {
            display: flex;
            align-items: center;
            font-family: $POPPINS;
            font-size: 16px;
            color: #000;
        }

        img {
            height: 20px;
            width: 20px;
        }
    }

}