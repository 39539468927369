@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
@import "../../../../web/src/assets/css/constants/base/mixins.scss";

.orderDialogTitle {
  border-bottom: 1px solid #979797;
  h4 {
    text-align: center;
    color: #5a5a5a;
    font-weight: 600;
  }
}

.subModalBlock {
  background-color: $WHITE;
  border-radius: $BOX_RADIUS;

  & .adminOrderModalContent {
    padding: 25px;

    .orderActionWrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
    }

    .orderStatusDropdown {
      width: 165px;
      height: 35px;
      font-family: $MONTSERRAT;
      font-size: 14px;
      color: #5a5a5a;
      font-weight: 500;
      background-color: #f0f0f0;
      border-radius: 4px !important;

      & > div {
        border-radius: 4px;
      }

      & .MuiSelect-select:focus {
        min-height: auto;
      }

      .MuiSelect-filled.MuiSelect-filled {
        padding: 8px 10px;
      }

      svg {
        transition: transform 0.2s;
      }
    }

    .orderStatusButton {
      background-color: #e0e0e0;
      padding: 4px 10px;
      min-width: 130px;
      height: 35px;
      text-transform: none;

      span {
        color: #5a5a5a;
        font-size: 14px;
        font-family: $MONTSERRAT;
        font-weight: 600;
      }
    }

    .orderPrintLabel {
      height: 34px;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 4px 20px;
      border: 1px solid #359843;
      color: #359843;
      border-radius: 4px;
      font-size: 14px;
      font-family: $MONTSERRAT;
      font-weight: 600;
    }

    .trackingContainer {
      h6 {
        font-size: 22px;
        font-weight: 700;
        font-family: $MONTSERRAT;
        color: #5a5a5a;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        font-family: $MONTSERRAT;
        color: #5a5a5a;
      }
    }

    .farmOrderCard {
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16);
    }

    .farmOrderNotesWrapper {
      h6 {
        font-family: $POPPINS;
        font-size: 16px;
        margin-bottom: 15px;
      }

      textarea {
        resize: none;
        height: 120px;
        width: 100%;
        padding: 10px;
        border-radius: 12px;
        background-color: #f0f0f0;
        font-size: 13px;
        border: 1px solid #d3d3d3;
        outline: none;
      }
    }

    .farmOrderTrackingWrapper {
      .orderTrackingStatus {
        color: #d97e17;
        font-family: $MONTSERRAT;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
        text-transform: capitalize;
      }

      .orderDeliverText {
        color: #5a5a5a;
        font-size: 15px;
        font-family: $MONTSERRAT;
        font-weight: 700;
        word-wrap: break-word;
      }

      .orderUserDetails {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-top: 10px;

        .orderUserAvatar {
          width: 65px;
          height: 65px;
        }
      }

      .orderUserInfoDetails {
        p {
          color: #5a5a5a;
          font-family: $POPPINS;
          word-wrap: break-word;
        }

        p:nth-child(1) {
          font-weight: 600;
          font-size: 15px;
          text-transform: capitalize;
          margin-bottom: 2px;
        }

        p:nth-child(2) {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    & .priceSection {
      margin-top: -65px;

      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
    }

    & h3 {
      padding-left: 20px;
      font-family: $ROUNDGOTHIC_MEDIUM;
      font-weight: 100;
    }

    & .contentHeader {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      & .farmProfile {
        height: 60px;
        width: 60px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    & .farmCard {
      margin-bottom: 20px;

      & h3 {
        padding-left: 0;
        font-size: 22px;
        font-family: $MONTSERRAT;
      }

      & .farmCardContent {
        display: flex;
        padding-top: 20px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        & .imgBlock {
          border-radius: $FORM_BORDER_RADIUS;

          & .farmSubProfile {
            height: 180px;
            width: 180px;
            object-fit: cover;
            border-radius: $FORM_BORDER_RADIUS;
          }

          @media screen and (max-width: 768px) {
            margin-bottom: 10px;
          }
        }

        & .rightBlock {
          padding-left: 20px;
          width: 100%;

          @media screen and (max-width: 767px) {
            padding-left: 0;
          }

          & p {
            width: 85%;
            font-size: 14px;
            line-height: 1.5;
            color: #5a5a5a;
            font-family: $MONTSERRAT;

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }

          & h5 {
            margin: 8px 0;
            color: $GREEN;
            font-family: $MONTSERRAT;
            font-weight: 700;
            font-size: 17px;

            & span {
              color: $GREEN;
              font-family: $MONTSERRAT;
              font-weight: 700;
            }
          }
        }
      }
    }

    & .priceCard {
      box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16);

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }

      & .cardContent {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      & .cardRow {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & h6 {
          font-weight: 500;
          font-family: $MONTSERRAT;
          font-size: 16px;
        }
      }

      & .cardTotalRow {
        & h6 {
          font-weight: 600 !important;
          font-size: 18px;
          color: #000;
        }
      }
    }

    & .greenFilledButton {
      & .MuiButton-root {
        width: 100%;
      }
    }
  }
}

.farm-order-menu {
  padding: 0 !important;

  li {
    padding: 10px 16px;
    font-family: $MONTSERRAT;
    font-size: 14px;
  }

  hr {
    margin: 0;
    color: #979797;
  }
}
