.all_white_background_wrapper {
  background-color: white !important;
  width: 100%;
  // height: 100vh;
  padding-top: 25px;
  // padding-bottom: 80px;
  overflow-y: scroll;

  overflow-x: hidden;

  & .create_profile_container {
    width: 100% !important;
  }
  & .create_role_profile_form_container {
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
    & .main-header {
      padding: 20px;
    }

    & .headerLearning {
      margin: 25px -28px;
      padding: 20px 30px;
      // padding: 10px;
      @media screen and (max-width: 767px) {
        margin: 10px -12px;
        padding: 15px;
      }
    }
    & .web_input_field {
      margin: 5px 10px 10px 0;
      @media screen and (max-width: 767px) {
        margin: 0;
      }
      & input {
        color: #000000d4;
      }
    }
  }
}
