@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.AnalyticsTopHeader {
  .list-items {
    padding-top: 10px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    @media screen and (max-width: 580px) {
      gap: 10px;
    }
    li {
      float: left;
      min-width: 200px;
      @media screen and (max-width: 580px) {
        min-width: 100px;
      }
    }
    a {
      display: block;
      text-align: center;
      padding: 15px 50px;
      color: #000;
      text-decoration: none;
      border-radius: 6px;
      background-color: #cad8f9;
      font-weight: bold;
      &:hover {
        color: #000;
      }
      @media screen and (max-width: 1024px) {
        padding: 5px 15px;
      }
    }
  }
}
.analyticsWrapper {
  & .cardContainer {
    &.analyticsContainer {
      & .analyticsListTable {
        width: 100%;
      }
      padding-top: 26px;
      .h5 {
        font-size: 14px;
        font-weight: bold;
      }
      .priceRow {
        padding: 20px 0px 10px 0px;
        small {
          color: #6ddfba;
          font-weight: bold;
        }
      }
      .comparsion {
        padding-bottom: 10px;
      }
      .analytics_header {
        width: 100%;
        // background-color: blue;
        display: flex;
        justify-content: space-between;
        h5 {
          font-weight: bold;
          font-size: 19px;
          width: 100%;
        }
        .option-dropDown {
          width: unset;
        }
      }
      .customer_transaction {
        // padding: 16px;
        width: 100%;

        .customer_ids {
          display: flex;
          width: 100%;
          padding: 10px 40px 10px 0;
          .customer_flex {
            display: flex;
            width: 100%;
          }
          small {
            color: grey;
            font-size: 15px;
          }
          .id_name {
            font-weight: bold;
            font-size: 17px;
          }
          .envelope {
            color: #ddd;
          }
          .circle {
            border-radius: 50%;
            height: 45px;
            width: 45px;
            background-color: #fc5a5a;
            margin-right: 10px;
          }
        }
      }
      .view_more {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 25px;
        border-top: 1px solid $GRAY_SHADE1;
        // margin: 0 -25px !important;
        width: 100%;
        & a {
          font-weight: 600;
          font-size: 14px;
          text-transform: uppercase;
        }
      }
      .view_transactions {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      //analytics card
      .card {
        &.analyticsCard {
          width: 100%;
          border-radius: 14px !important;
          padding: 19px;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 0;
          height: 340px;
          & .prodListHeader {
            margin-bottom: 10px;
          }
        }
      }
      .infoCard {
        padding: 40px;
        &.MuiCard-root {
          padding: 25px 20px 15px 20px;
          @media screen and (max-width: 1024px) {
            padding: 13px 20px 10px 20px;
          }
        }
        .cardContent {
          padding: 10px 20px;

          width: 100%;
        }
      }
    }
  }
  & .anaylticsHistoryRow {
    & .anaylticsCol {
      padding: 0 12px;
    }
  }
}
