@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");

.Select_Role_Container {
  height: 100% !important;
  width: 100%;
  background: $WHITE !important;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 22px;

}

.select_role_loader_container {
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $GREEN;
  }

}

.select_role_blank_container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select_your_role {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 70px 10px 60px;
  padding: 0px 20px;
}

.select_your_role_heading {
  padding-bottom: 10px !important;
  font-family: $POPPINS !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #000 !important;

}

.select_your_role_text {
  font-family: $POPPINS !important;
  font-size: 18px !important;
  text-align: center !important;
  color: #000 !important;
}

.role_container {
  height: 250px;
  text-align: center;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 20px;
  color: $GREEN !important;
  position: relative;
  cursor: pointer;

  .selected_role_container {
    position: absolute;
    top: 15px;
    right: 15px;

    svg {
      color: #ffac0c;
    }
  }

  .role_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      height: 100px;
    }
  }

}

.selected_role {
  border: 1px solid $GREEN;
  background-color: rgba(25, 160, 83, 0.04);
}

.role_card_title {
  font-size: 22.1px !important;
  font-weight: 700 !important;
  color: #19a053;
  text-align: center !important;
  font-family: $POPPINS !important;
}

.select_role_move_button {
  background-color: #263c78 !important;
  width: fit-content !important;
  padding: 6px 0px !important;
  min-width: 40px !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;

  span {
    color: #fff !important;
    font-weight: bold !important;
    font-size: 16px !important;
    padding: 5px 15px !important;
  }
}

.select_role_move_disabled {
  background-color: $GRAY_SHADE1 !important;
}
