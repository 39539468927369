@import "../../../../../web/src/assets/css/constants/base/colors.scss";
//$Banner: "../images/grass-banner.png";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.farm_catelog_top_banner {
  background: url("../images/grass-banner-2.jpg") no-repeat;
  background-size: cover;
  //   background-position: center;
  padding: 60px;
  height: 100%;
  width: 100%;
  margin-bottom: 15px;
  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    color: $WHITE;
    font-weight: 600;
  }
}

.farm_catelog_banner_input {
  // margin-left: 200px;
  padding: 14.5px 18px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
}


.farm_catelog_search_btn {
  width: 149px !important;
  height: 61px !important;
  padding: 20px 20px;
  background-color: #ffac0c !important;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%);
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  position: absolute;
  right: 0;
  // z-index: 2 !important;
  // top: 2px;
}
