@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.shopping_cart_header {
  margin-top: 30px;
  h3 {
    font-family: $ROUNDGOTHIC_BOOK;
    font-size: 26px;
    color: #05172c;
  }
}

.empty_cart_container {
  min-height: 55vh;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  margin-top: 15px !important;

  img {
    height: 180px;

    @media screen and (max-width: 600px) {
      height: 110px;
    }

    @media screen and (max-width: 300px) {
      height: 90px;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 35px 25px;
  }

  p {
    font-size: 24px;
    font-weight: 600;
    font-family: $MONTSERRAT;
    color: $GREEN;
    text-align: center;
  }

  h6 {
    width: 70%;
    text-align: center;
    margin-top: 5px;
    font-size: 18px;
    font-weight: 500;
    font-family: $MONTSERRAT;
    color: rgba(51, 51, 51, 0.5);
  }

  @media screen and (max-width: 600px) {
    min-height: 40vh;
  }
}

.shopping_cart_separator {
  border-bottom: 1px solid #d2d2d2;
  margin: 50px 0px;
}

.shopping_cart_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;

  svg {
    color: $GREEN;
  }
}

.all_cart_detail_container {
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  padding: 40px 30px;
  border-radius: 15px;

  .all_cart_detail_heading {
    font-weight: 500 !important;
    padding-bottom: 8px;
    color: #000000;
    font-size: 20px;
    font-family: $MONTSERRAT;
  }

  .all_cart_detail_content {
    color: #000000;
    font-size: 16px;
    font-family: $MONTSERRAT;
  }

  .all_cart_detail_grid_heading {
    padding: 5px 0px !important;
  }

  .all_cart_detail_grid {
    padding: 0px !important;
  }
}

.coupon_code_container {
  box-shadow: 0 2px 12px 0 rgb(183 189 196 / 50%);
  padding: 35px 20px;
  border-radius: 15px;
}
