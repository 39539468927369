@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.restaurant_landing_page_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;

  svg {
    color: $GREEN;
  }
}

.restaurant_landing_page_banner {
  background: url("../images/polygon-polygon-mask.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding: 120px 100px;

  @media screen and (max-width: 1200px) {
    padding: 60px;
  }

  @media screen and (max-width: 800px) {
    padding: 45px;
  }

  @media screen and (max-width: 600px) {
    background: url("../images/banner1-mobile.png") no-repeat;
    background-size: 100% 100%;
    padding: 25px;
    margin: 15px;
  }
}

.restaurant_landing_page_banner_content {
  width: 100%;
  padding: 10px 0px;
}

.restaurant_landing_page_banner_two {
  margin-bottom: 50px;
  font-family: $ROUNDGOTHIC_BOOK;
  font-weight: 500;
  color: #fff;
  font-size: 60px;
  line-height: 1.11;
  width: 80%;

  @media screen and (max-width: 1500px) {
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    font-size: 48px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 959px) {
    font-size: 40px;
    margin-bottom: 25px;
    width: 60%;
  }

  @media screen and (max-width: 600px) {
    font-size: 34px;
    margin-bottom: 30px;
    width: 60%;
  }

  @media screen and (max-width: 500px) {
    font-size: 26px;
    margin-bottom: 20px;
    width: 55%;
  }
}

.Food_resto_input_btn_wrappper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 2px;
  border-radius: 24px;
  width: 90%;

  @media screen and (max-width: 1200px) {
    width: 95%;
  }

  @media screen and (max-width: 959px) {
    width: 55%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 30px;
  }

}

.Food_resto_input {
  padding: 6px 15px;
  background-color: #fff;
  border-radius: 24px !important;
  border: none;

  input {
    font-family: $MONTSERRAT;
    font-size: 16px;
    font-weight: 600;
    line-height: 2;
    color: rgba(51, 51, 51, 0.5);
  }

  @media screen and (max-width: 600px) {
    padding: 4px 14px;

    input {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 300px) {
    padding: 2px 12px;

    input {
      font-size: 12px;
    }
  }
}

.Food_resto_btn {
  font-family: $MONTSERRAT !important;
  font-size: 16px !important;
  font-weight: bold !important;
  background-color: #feab0b !important;
  padding: 0px 30px !important;
  text-transform: capitalize !important;
  border-radius: 24px !important;
  cursor: pointer !important;
  height: 100% !important;

  @media screen and (max-width: 600px) {
    padding: 0px 24px !important;

    span {
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 300px) {
    padding: 0px 20px !important;

    span {
      font-size: 12px !important;
    }
  }
}

.restaurant_popular_categories_section {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  background-color: rgba(244, 237, 226, 0.65)
}

.submit_restaurant_bubble {
  position: absolute;
  right: -30px;
  bottom: 240px;
  width: 236px;
  height: 234px;
  z-index: -1;
}

.submit_restaurant_rings {
  position: absolute;
  left: -289px;
  bottom: -289px;
  width: 436px;
  height: 436px;
  z-index: -1;
}

.restaurant_category_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.restaurant_green_background_heading_new {
  font-family: $MONTSERRAT;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  padding: 2px 10px;
  border-radius: 8px;
  background-color: #5eae53;
  width: fit-content;
  font-size: 14px;
  line-height: 2;
  text-align: center;

  @media screen and (max-width: 300px) {
    font-size: 12px;
  }
}

.popular_categories_dish_card {
  border-radius: 15px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
  cursor: pointer;
  outline: none;
}

.popular_categories_dish_card_selected {
  background-color: #feab0b;
  box-shadow: 10px 20px 40px 0 rgba(97, 49, 0, 0.2);

  .dish_count {
    background-color: #5eae53;
  }

  .dish_name_price_wrapper {
    h6 {
      color: #ffffff;
    }

    p {
      color: rgba(255, 255, 255, 0.5);
    }
  }

}

.heading_wrapper {
  h4 {
    margin: 30px 0px;
    font-weight: 500;
    font-family: $ROUNDGOTHIC_BOOK;
    font-size: 38px;
    color: #333333;
    text-align: center;

    @media screen and (max-width: 600px) {
      font-size: 30px;
      margin: 20px 0px;
    }

    @media screen and (max-width: 300px) {
      font-size: 22px;
      margin: 15px 0px;
    }
  }
}

.heading_wrapper_res {
  .restaurant_big_heading {
    font-family: $ROUNDGOTHIC_BOOK;
  }
}

.dish_categories_content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 12px;
}

.dish_img_wrapper {
  height: 120px;
  width: 120px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;

  img {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 500px) {
    height: 90px;
    width: 90px;

    img {
      height: 30px;
      width: 30px;
    }
  }
}

.dish_count {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #afd3aa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $WHITE;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: 500px) {
    height: 30px;
    width: 30px;
  }
}

.dish_name_price_wrapper {
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;

  h6 {
    font-family: $MONTSERRAT;
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding-bottom: 5px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-family: $MONTSERRAT !important;
    font-size: 14px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.3);
    text-align: center;
    line-height: 1.71;
  }

  @media screen and (max-width: 500px) {
    h6 {
      font-size: 17px;
    }
    p {
      font-size: 13px;
    }
  }
}

.restaurant_middle_content_wrapper {
  margin-top: 60px;
  margin-bottom: 60px;

  @media screen and (max-width: 600px) {
    margin-top: 25px;
  }

  @media screen and (max-width: 300px) {
    margin-top: 10px;
  }
}

.restaurant_big_heading {
  font-family: $ROUNDGOTHIC_BOOK;
  font-size: 52px;
  line-height: 1.14;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    font-size: 38px;
  }

  @media screen and (max-width: 300px) {
    font-size: 34px;
    margin: 15px 0px;
  }
}

.restaurant_middle_text {
  font-family: $MONTSERRAT;
  font-size: 16px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.5);
}

.restaurant_view_all_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  .restaurant_view_all_btn {
    text-transform: none;
    border-radius: 20px;
    padding: 5px 20px;

    span {
      font-size: 17px;
      color: #333;
    }
  }
}

.restaurants_arrow_icons_wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #feab0b !important;

  @media screen and (max-width: 300px) {
    height: 30px !important;
    width: 30px !important;
  }

}

.restaurants_arrow_icons_disable {
  opacity: 0.9;
}

.restaurants_arrow_icons {
  color: #ffffff;
  font-size: 19px;

  @media screen and (max-width: 300px) {
    font-size: 16px !important;
  }
}

.restaurant_famous_dish_card {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: relative;
  cursor: pointer;
}

.famous_dish_img_wrapper {
  width: 100%;

  img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    height: 100%;
  }
}

.restaurant_star_rating_and_text_wrapper {
  padding: 10px;
  border-radius: 8px;
  background-color: #feab0b;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  width: 100px;
  position: absolute;
  top: 20px;
  left: 20px;

  p {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }

  @media screen and (max-width: 600px) {
    padding: 6px;
    width: 70px;
    top: 15px;
    left: 15px;

    h6 {
      font-size: 8px;
    }
  }

  @media screen and (max-width: 300px) {
    padding: 6px;
    width: 60px;

    h6 {
      font-size: 10px;
    }
  }

}

.star_icon_resto {
  font-size: 15px;
  font-weight: bold;
}

.famous_dish_contents {
  padding: 20px;

  h6 {
    font-family: $MONTSERRAT;
    font-weight: 600;
    color: #333;
    font-size: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 600px) {
    padding: 12px 14px;

    h6 {
      font-size: 19px;
    }
  }

  @media screen and (max-width: 300px) {
    padding: 15px;

    h6 {
      font-size: 18px;
    }
  }
}

.famous_dish_timing_and_name_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 14px;

  p {
    font-family: $MONTSERRAT;
    color: #333333;
    font-size: 17px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    font-family: $MONTSERRAT;
    color: rgba(51, 51, 51, 0.5);
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 768px) {
    margin-top: 5px;
    p {
      font-size: 15px;
    }

    span {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 8px;
    margin-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;

    p {
      font-size: 14px;
    }

    span {
      font-size: 15px;
      text-align: end;
      width: 100%;
    }
  }
}

.woman_chef_img_wrapper {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.restaurant_list_content_wrapper {
  margin-top: 70px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.res_bottom_text {
  font-family: $MONTSERRAT;
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
  color: rgba(51, 51, 51, 0.5);
  margin-bottom: 10px;
}

.restaurant_submit_green_btn {
  background-color: #5eae53 !important;
  padding: 4px 9px !important;
  border-radius: 8px !important;
}

.restaurant_submit_green_btn_color {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.submit_restaurant_heading {
  font-family: $MONTSERRAT;
  font-size: 52px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: normal;
  color: #333;
  margin-top: 20px;
  margin-bottom: 15px;

  @media screen and (max-width: 600px) {
    font-size: 30px;
  }

  @media screen and (max-width: 300px) {
    font-size: 22px;
  }
}

.rest_submit_orange_btn {
  font-family: $MONTSERRAT !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 24px !important;
  background-color: #feab0b !important;
  padding: 10px 25px !important;
  text-transform: capitalize !important;
  width: 150px !important;

  @media screen and (max-width: 300px) {
    font-size: 10px;
    padding: 6px 18px !important;
  }
}

.restaurant_list_heading_wrapper {
  margin-bottom: 35px !important;

  @media screen and (max-width: 600px) {
    margin-bottom: 15px !important;
  }

  @media screen and (max-width: 300px) {
    margin-bottom: 10px !important;
  }
}