@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';
@import '../constants/base/mixins.scss';

.tableContainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: $BOX_SHADOW;
  padding-right: 0;
  @include scrollbars();

  & td {
    font-family: $MONTSERRAT;
    font-size: 14px !important;

    h5 {
      font-size: 14px !important;
    }
  }

  & .MuiTableCell-stickyHeader {
    position: unset;
  }

  & .tableHeaderContainer {
    &.MuiTableContainer-root {
      height: 500px !important;
      min-height: 500px !important;
    }
  }
  & .tableBodyContainer {
    &.MuiTableContainer-root {
      height: 500px !important;
      min-height: 500px !important;
    }
  }
  & .headerListContainer {
    padding: 20px;
    & .prodListHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 1299px) {
        flex-wrap: wrap;
      }
      & .link {
        font-weight: 600;
        color: $DARK;
        &:hover {
          color: $GREEN;
          text-decoration: none;
        }
      }
      & h4,
      h6 {
        font-weight: bold;
        white-space: nowrap;
      }
      & .blocks {
        display: flex;
        align-items: center;
        @media screen and (max-width: 560px) {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
        }
      }
      & .orderblocks {
        display: flex;
        align-items: center;
        @media screen and (max-width: 560px) {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
        }
        @media screen and (max-width: 560px) {
          width: 100%;
          flex-direction: row;
          align-items: center;
        }
      }
      & .block1 {
        display: flex;
        align-items: center;
        padding-left: 20px;

        & .btnContainer {
          .MuiButton-label {
            color: #fff;
            text-transform: capitalize;
            padding: 5px 0px;
          }
        }

        @media screen and (max-width: 1299px) {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-left: 0;
          margin: 10px 0;
        }

        @media screen and (max-width: 560px) {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 0px !important;
          margin: 10px 0;
          align-items: flex-start;
        }
        & .addButton {
          padding-left: 10px;
          @media screen and (max-width: 767px) {

            .customButton {
              .MuiButton-root {
                width: 100%;
                .MuiButton-label {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .plus_icon {
                    &::after {
                      position: relative;
                      left: -10px;
                    }
                    &::before {
                      position: relative;
                      left: -10px;
                      top: 4px;
                    }
                  }
                }
              }
            }
          }
          @media screen and (max-width: 600px) {
            width: 100%;
            padding-left: 0px;
          }
        }

        & .rightOrders {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @media screen and (max-width: 560px) {
            margin-top: 10px;
          }
        }

        & .text {
          padding-right: 10px;
          font-size: 12px;
          color: #000;
          font-weight: 400;
          white-space: nowrap;
          margin-right: 20px;
          @media screen and (max-width: 1024px) {
            width: 100px;
          }
          @media screen and (max-width: 560px) {
            margin-right: 24px;
            font-size: 14px;
            width: 100px;
          }
        }

        .customSelect {
          .MuiFormControl-root {
            width: 100%;
          }
        }
      }
    }
    & .prodListBottomRow {
      margin-top: 20px;
      display: flex;
      .MuiInput-formControl {
        margin-top: 0;
      }
      .MuiInputLabel-root {
        left: 13px;
        color: #ccc;
        font-weight: 500;
        transform: translate(0, 16.5px) !important;
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      .block1 {
        padding-left: 20px;
        width: 15%;
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        .outlineSelect {
          width: 100%;

          .customSelect {
            @media screen and (max-width: 1024px) {
              width: 100%;
            }
            .MuiFormControl-root {
              @media screen and (max-width: 1024px) {
                width: 100%;
              }
            }
          }
        }
      }
      .outlineTextfield {
        width: 83%;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
    
  }
  &.MuiTableContainer-root {
    box-shadow: $BOX_SHADOW;
    overflow: inherit;
    overflow-x: auto;
    & .tableHeader {
      padding: 0 5px;
      & h6,
      & a {
        font-weight: 600;
        color: $DARK;
        &:hover {
          color: $GREEN;
          text-decoration: none;
        }
      }
    }
    & .tableHead {
      z-index: 5;
      position: sticky !important;
      top: -1px !important;
      font-size: 11px;
      color: #44444f;

      .tableHead-menu-column {
        width: 50px !important;
      }

      & .MuiTableCell-head {
        padding: 5px 10px;
        background-color: $SKYBLUE_SHADE4;
        color: $GRAY_SHADE4;
        font-weight: 600;
        width: 100px;
        white-space: nowrap;
        text-transform: uppercase;
        border-top: 1px solid rgba(224, 224, 224, 1);
        @media screen and (max-width: 768px) {
          padding: 5px 20px;
        }
        &:first-child {
          width: 200px;
          padding-left: 20px;
        }
      }

      &.dashBoadTableHead {
        z-index: 5;
        position: sticky;
        top: 0;

        & .MuiTableCell-head {
          text-transform: none;
        }
      }
    }
    & .MuiTableCell-body {
      color: $GRAY_SHADE6;
      font-size: 17px;
      white-space: nowrap;
      padding: 20px 10px;
      cursor: pointer;
      &:first-child {
        padding: 16px 20px;
      }
      &.BoldCell {
        font-weight: 600;
      }
      &.paymentCell {
        text-transform: uppercase;
      }
      & .productCell {
        display: flex;
        align-items: center;

        & h5 {
          padding-left: 30px;
          font-weight: bold;
          display: inline-block;
          width: 180px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .productHead {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0px;
    margin-left: -5px;
    & span {
      padding-left: 24px;
      &:nth-child(2) {
        font-family: $POPPINS !important;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.8px;
        color: #44444f;
      }
    }
  }
  & .checkboxnameCell {
    display: flex;
    align-items: center;
    & .customCheckbox {
      margin-right: 20px;
    }
    & span {
      &:nth-child(2) {
        color: $GRAY_SHADE6;
      }
    }
  }
  & .MuiTableCell-body {
    color: $GRAY_SHADE6;
    font-size: 17px;
    white-space: nowrap;
    &.BoldCell {
      font-weight: 600;
    }
    &.paymentCell {
      text-transform: uppercase;
    }
    & .productCell {
      display: flex;
      align-items: center;
      width: 100%;
      & h5 {
        padding-left: 30px;
        font-weight: bold;
        font-family: $POPPINS !important;
        font-size: 18px;
        letter-spacing: 0.23px;
        color: #171725;
        display: inline-block;
        width: 180px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
      & p {
        padding-left: 20px;
        font-weight: bold;
        font-size: 15px;
        width: 200px;
        word-break: break-word;
        word-wrap: wrap;
        white-space: pre-wrap;
      }
    }
  }
  & .outlineChip {
    & .customChips {
      &.MuiChip-root {
        background-color: transparent;
        border: 1px solid $GREEN_SHADE1;
        & span {
          color: $GREEN_SHADE1;
          text-transform: uppercase;
        }
      }
    }
  }
  & .prodImage {
    height: 80px;
    width: 80px;
    object-fit: fill;
    border-radius: 8px;
  }
  & .table-block {
    & .tableHead {
      position: sticky;
      top: 0;
      z-index: 5;
      & .MuiTableCell-head {
        padding: 5px 0;
        background-color: $SKYBLUE_SHADE4;
        color: #44444f;
        font-weight: 600;
        width: 100px;
        white-space: nowrap;
        text-transform: uppercase;
        font-family: $POPPINS !important;
        font-size: 11px;
        letter-spacing: 0.8px;

        &:first-child {
          width: 200px;
          padding-left: 20px;
        }
        &:nth-child(4) {
          text-align: center;
        }
      }
    }
    & .MuiTableBody-root {
      & .MuiTableRow-root {
        & .MuiTableCell-body {
          .productCell {
            width: 100%;
          }
        }
      }
    }
    & .MuiTableContainer-root {
      border-bottom-left-radius: $BOX_RADIUS;
      border-bottom-right-radius: $BOX_RADIUS;
      box-shadow: $BOX_SHADOW;
      @include scrollbars();
    }
  }
  &.analyticsTable {
    width: 100%;
    &.MuiTableContainer-root {
      & .tableHead {
        & .MuiTableCell-head {
          background-color: $WHITE;
          color: $DARK;
          font-size: 12px;
          letter-spacing: 2px;
          padding-left: 0;
          text-align: left;
          @media screen and (max-width: 768px) {
            padding: 5px 15px;
          }
        }
      }
      & .MuiTableCell-body {
        padding: 8px 0;
        border: 0;
      }
      & .MuiTypography-body1 {
        color: $DARK;
        font-size: 15px;
        white-space: nowrap;
      }
      & .productCell {
        &:first-child {
          gap: 20px;
        }
        p {
          padding: 0;
          font-weight: normal;
        }
        .paymentSuccessIcon {
          background-color: $GREEN_SHADE1;
          color: $WHITE;
          padding: 5px;
          font-size: 30px;
          border-radius: 50%;
        }
        .paymentFailIcon {
          background-color: $RED;
          color: $WHITE;
          padding: 5px;
          font-size: 30px;
          border-radius: 50%;
        }
        .paymentRefundIcon {
          background-color: $DANGER;
          color: $WHITE;
          padding: 5px;
          font-size: 30px;
          border-radius: 50%;
        }
        .paymentProcessIcon {
          background-color: $SKYBLUE_SHADE3;
          color: $WHITE;
          padding: 5px;
          font-size: 30px;
          border-radius: 50%;
        }
      }
    }
  }
  & .restforums {
    & .MuiTableContainer-root {
      & .tableHead {
        & .MuiTableCell-head {
          width: 100px;
          &:nth-child(1) {
            width: 40%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 10%;
          }
          &:nth-child(5) {
            width: 5%;
          }
          &:nth-child(6) {
            width: 5%;
          }
        }
      }
      & .MuiTableBody-root {
        & .MuiTableRow-root {
          .MuiTableCell-body {
            font-weight: normal;
          }
        }
      }
    }
    & .prodImage {
      margin-left: 20px;
    }
  }
  & .prodListTabel {
    & .MuiTableContainer-root {
      & .MuiTableBody-root {
        & .MuiTableRow-root {
          .MuiTableCell-body {
            &:nth-child(2) {
              font-weight: normal;
            }
            &:nth-child(3) {
              font-weight: normal;
            }
            &:nth-child(4) {
              font-weight: normal;
            }
          }
        }
      }
      & .prodImage {
        margin-left: 20px;
      }
    }
  }
  & .restDishesTable {
    border-radius: 0px !important;
    & .MuiTableContainer-root {
      & .tableHead {
        & .MuiTableCell-head {
          width: 100px;
          &:nth-child(1) {
            width: 40%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 10%;
          }
          &:nth-child(5) {
            width: 5%;
          }
        }
      }
    }
    & .prodImage {
      margin-left: 20px;
    }
  }
  & .adminLearningCourseTable {
    & .MuiTableContainer-root {
      & .tableHead {
        & .MuiTableCell-head {
          width: 100px;
          &:nth-child(1) {
            width: 20%;
          }
        }
      }
      & h5 {
        font-size: 17px;
      }
    }
  }
  & .custListTable {
    & .MuiTableContainer-root {
      & .tableHead {
        & .MuiTableCell-head {
          width: 100px;
          &:nth-child(1) {
            width: 35%;
          }
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 5%;
          }
        }
      }
    }
  }
  & .campTable {
    & .MuiTableContainer-root {
      & .tableHead {
        & .MuiTableCell-head {
          width: 100px;
          &:nth-child(1) {
            width: 30%;
          }
          &:nth-child(2) {
            width: 8%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 39%;

            @media screen and (max-width: 768px) {
              padding-left: 20px;
            }
          }
          &:nth-child(5) {
            width: 3%;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-body {
          .fundProgressBar {
            width: 100%;
            .fundScale {
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                color: $DARK;
                font-size: 12px;
                &:nth-child(2) {
                  margin: 0 10px;
                }
              }
            }
            .customLinearProgressbar {
              width: 100%;
            }
          }
        }
      }
    }
    & .prodImage {
      margin-left: 20px;
    }
  }
  &.restOrderListContainer {
    &.MuiTableContainer-root {
      & .tableHead {
        & .MuiTableCell-head {
          width: 100px;
          text-align: left;
          &:nth-child(5) {
            text-align: center;
          }
        }
      }
      & .MuiTableCell-body {
        text-align: left;
        font-size: 15px;
        &.accordianCell {
          padding: 0;
          border: 0;
        }
      }
      & h5 {
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
  &.purchaseTable {
    &.MuiTableContainer-root {
      & .tableHead {
        & .MuiTableCell-head {
          padding-right: 50px;
        }
      }
      & .MuiTableCell-body {
        padding-right: 50px;
      }
    }
  }
  &.learningPurchasesContainer {
    & .tableHead {
      z-index: 5;
      position: sticky !important;
      top: -1px !important;
      font-size: 11px;
      color: #44444f;

      & .MuiTableCell-head {
        text-align: left !important;
      }
    }
    & .dropDownCell {
      text-align: right !important;
    }
    & .course-purchase-row {
      display: flex;
      align-items: center;
      & .VideoInput_video,
      & .techPurchase {
        height: 50px;
        width: 80px;
        object-fit: cover;
        border-radius: 15px;
      }
      & .customerPurchaseDefaultCourse {
        height: 50px;
        width: 80px;
        object-fit: cover;
        border-radius: 15px;
      }
      & .customerPurchase {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
      & h5 {
        margin-left: 10px;
        width: 150px;
        white-space: normal;
        line-height: 25px;
      }
    }
  }
}

.blocks {
  h6 {
    color: #333;
  }
}

.course-purchase-dialog-row {
  display: flex;
  align-items: center;
  padding: 20px 0;
  & .VideoInput_video,
  & .techPurchase {
    width: 240px;
    object-fit: cover;
    border-radius: 15px;
  }
  & .customerPurchaseDefaultCourse {
    width: 240px;
    object-fit: cover;
    border-radius: 15px;
  }
  & .customerPurchase {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  & h5 {
    margin-left: 10px;
    width: 150px;
    white-space: normal;
    line-height: 25px;
  }
}

.viewLink {
  color: $GREEN;
  text-decoration: underline;
  &:hover {
    color: $SKYBLUE_SHADE3;
  }
}

.subModalBlockPurchase {
  @media screen and (max-width: 772px) {
    padding-bottom: 20px;
  }
}
.learningCourseTitle{
  width: 200px !important;
    word-break: break-word !important;
    word-wrap: wrap !important; 
    white-space: pre-wrap !important;
}