@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.farm_forum_modal_container {
  /* width */
  ::-webkit-scrollbar {
    width: 5px !important;
    display: none !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
}

.forum_content_wrapper {
  padding: 30px;

  @media screen and (max-width: 600px) {
    padding: 20px;
  }

  @media screen and (max-width: 400px) {
    padding: 20px 10px;
  }
}

.carrot_banner {
  height: 60vh;
  background-size: 100% 100%;
  background-position: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  position: relative;

  @media screen and (max-width: 600px) {
    height: 40vh;
  }

  button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}

.farm_forum_comments_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #979797;
  padding: 20px 0px;
  gap: 8px;

  h5 {
    font-weight: 600;
  }

  span {
    color: $GREEN;
    font-weight: 600;
    border-bottom: 1px solid $GREEN;
  }
}

.farm_forum_comment_textarea_wrapper {
  border: 1px solid $GRAY_SHADE1;
  border-radius: 20px;
  padding: 20px;
}

.farm_forum_likes_comments_wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;

  span {
    margin-top: 6px;
    color: $Black;

    span {
      font-weight: 600;
      color: $Black;
      padding: 0px 5px;
    }
  }

  p {
    color: $Black;
    display: flex;
    align-items: flex-end;
    gap: 2px;

    img {
      width: 22px !important;
    }
  }

  .forum_like_wrapper {
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: 300px) {
    flex-direction: column;
    gap: 10px;
  }
}

.farm_forum_scroll_textarea {
  width: 100%;
  resize: none;
  border: 1px solid $GRAY_SHADE1;
  border-radius: 20px;
  padding: 20px;
  overflow-y: auto;

  &:focus {
    outline: none;
  }
}

.farm_forum_comment_textarea {
  width: 100%;

  resize: none;
  border: none;

  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.farm_forum_comment_textarea_btn_wrapper {
  display: flex;
  justify-content: flex-end;

  button {
    border-radius: 25px;
    background-color: #ffac0c;

    &:hover {
      border: 1px solid #ffac0c;

      span {
        color: #ffac0c;
      }
    }

    span {
      color: $WHITE;
      padding: 3px 12px;
      text-transform: none;
    }
  }

  .reply_btn_disabled {
    background-color: #f3f3f3;

    span {
      color: #3a3a3a;
    }
  }
}

.forum_loader_container {
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $GRAY_SHADE1;
  border-radius: 20px;

  svg {
    color: $GREEN;
  }
}
