@import "../../../../web/src/assets/css/constants/base/global.scss";
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.commentReviewContainer {
  width: 100%;
  margin: 20px 0;
  border: 1px solid $GRAY_SHADE3;
  padding: 30px;
  border-radius: $BORDER_RADIUS;
  @media screen and (max-width: 560px) {
    padding: 20px 15px;
  }
  & .detailsContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    @media screen and (max-width: 560px) {
      flex-direction: row;
      gap: 0;
    }
    .imageContainer {
      // width: 20%;
      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media screen and (max-width: 560px) {
        width: 20%;
      }
      .MuiAvatar-root {
        width: 80px;
        height: 80px;
        @media screen and (max-width: 560px) {
          width: 40px;
          height: 40px;
        }
      }
    }
    .reviewContainer {
      width: 100%;
      @media screen and (max-width: 560px) {
        margin-top: -5px;
      }

      .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: unset;

        h5 {
          font-size: 20px;
          color: black;
          font-weight: 600;
          text-transform: capitalize;
          @media screen and (max-width: 560px) {
            font-size: 14px;
          }
        }
        h6 {
          color: $GRAY_SHADE4;
          font-weight: 600;
          margin: 5px 0;
        }
        p {
          color: $GRAY_SHADE3;
          font-size: 15px;
          margin: 0;
        }
      }
      .rating {
        margin-top: 10px;
        .MuiRating-iconFilled {
          color: #ffb400;
        }
      }

      .review {
        margin-top: 10px;
        color: $GRAY_SHADE4;
        font-size: 16px;
        font-family: $POPPINS;
        @media screen and (max-width: 560px) {
          margin-left: 8px;
        }
      }
    }
  }

  & .buttonContainer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .MuiSvgIcon-root {
      color: brown;
      font-size: 35px;
      padding: 1px;
      border: 1px solid brown;
      border-radius: 3px;
      margin-right: 20px;
    }
    .MuiButton-contained {
      background-color: $GREEN;
      span {
        font-weight: 600;
        color: $WHITE;
      }
      .MuiSvgIcon-root {
        color: $WHITE;
        font-size: 20px;
        padding: 0;
        border: none;
        margin: 0;
      }
    }
    .MuiButton-outlined {
      background-color: orange;
      border: none;
      span {
        font-weight: 600;
        color: $WHITE;
      }
      .MuiSvgIcon-root {
        color: orange;
        font-size: 20px;
        padding: 0;
        border: none;
        margin: 0;
      }
    }
    & .dustbinBtnOutline{
      border: 1.2px solid red;
    padding: 7px;
    border-radius: 4px;
    margin-right: 18px;
    cursor: pointer;
    & img{
      width: 20px;
    }
    }
  }
}
