@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.cart_payment_method_title {
  font-weight: 600 !important;
  padding-bottom: 10px;
  color: $Black;
  font-size: 18px;
  text-transform: capitalize;
}

.cart_payment_details {
  display: flex;
  justify-content: space-between;
  p {
    padding: 3px 0px;
    color: $Black;
  }
}
.cart_total_payment_wrapper {
  border-top: 1px solid $Black;
  padding: 10px 0px 0px !important;
  margin: 15px 0px 0px !important;
  p {
    font-weight: 600;
  }
}
.radio_label {
  color: $Black !important;
  font-family: $MONTSERRAT !important;
}
.radio_checked {
  color: $GREEN !important;
  span {
    color: $GREEN !important;
  }
  &.radio_label {
    font-weight: 800 !important;
  }
}

.radio_disabled {
  color: rgba(0, 0, 0, 0.38);
}

.payment_option_checkbox {
  & .Mui-checked {
    & .Mui-disabled {
      color: #20a258 !important;
    }
    & .MuiIconButton-label, svg {
      color: #20a258
    }
  }
}

.checked_radio_label {
  color: $Black !important;
  font-family: $MONTSERRAT !important;
  font-weight: 600 !important;
}

.radio_checked .radio_label {
  font-weight: 800 !important;
  color: black;
}