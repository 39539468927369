@import "../../../../web/src/assets/css/constants/base/colors.scss";

.setting_layout_container {
  background-color: $WHITE;
}
.setting_layout_wrapper {
  border-radius: 10px;
  margin: 50px auto;
  background-color: #fff;
  box-shadow: 0 2px 18px 4px rgba(176, 176, 176, 0.22);
  -moz-box-shadow: 0 2px 18px 4px rgba(176, 176, 176, 0.22);
  -webkit-box-shadow: 0 2px 18px 4px rgba(176, 176, 176, 0.22);
  -o-box-shadow: 0 2px 18px 4px rgba(176, 176, 176, 0.22);

  @media screen and (max-width: 960px) {
    margin: 10px 0px 30px;
  }
}

.web_sidenav {
  border-right: 2px solid $GRAY_SHADE1;
  @media screen and (max-width: 960px) {
    border: none;
  }
}
