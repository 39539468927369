@import "../../../../web/src/assets/css/constants/base/colors.scss";
$Banner: "../images/page-header-bg@2x.png";

.grass_banner {
  background: url($Banner) no-repeat;
  background-size: cover;
  //   background-position: center;
  padding: 60px;
  height: 260px;
  display: flex;
  align-items: center;
}
.green_top_banner_text_wrapper{
    color:white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3{
        font-size: 40px;
        font-weight: 800;
        color:white;
        text-transform: uppercase;
         margin-top: 10px!important;
         margin-bottom: 6px!important;
    }
    span{
        color:white;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;

        
    }
}
.breadFont{
    @media screen and (max-width: 648px) {
        h3{
            font-size: 30px;
        }
        span{
            font-size: 12px;
        }
      }
      @media screen and (max-width: 520px) {
        h3{
            font-size: 20px;
        }
        span{
            font-size: 10px;
        }
      }  
      @media screen and (max-width: 390px) {
        h3{
            font-size: 15px;
        }
        span{
            font-size: 8px;
        }
      }   
      @media screen and (max-width: 320px) {
        h3{
            font-size: 11px;
        }
        span{
            font-size: 8px;
        }
      }     
}
.Leave_icon{
    color:white;
    font-size: 25px!important;
   
}
.bullet{
    color: white;
    font-size: 11px!important;
    margin-left:8px;
    margin-right: 8px;

   
}