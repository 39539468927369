@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.common_heading {
    font-family: $ROUNDGOTHIC_MEDIUM !important;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (max-width: 480px) {
        font-size: 23px;
    }
    
}

