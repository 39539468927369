@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.custom_quantity_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f5f5;
  border-radius: 26px;
  gap: 8px;
  width: fit-content;

  button {
    border: none;
    background-color: transparent;
    height: 33px;
    width: 33px;
    font-size: 18px;
    font-weight: bold;
    color: #264653;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #264653;
    margin-right: 7px;
    font-family: $PTSans;
  }

  button:last-child {
    height: 34px;
    width: 34px;
    background-color: #ffffff;
    box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
  }
}

.custom_quantity_container_disabled {
  opacity: 0.7;
}
