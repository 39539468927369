@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.container {
  flex: 1;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  background-color: #ffffffff;
  border: 1px solid black;
  border-radius: 18px;
  align-items: middle;
  margin-top: 8%;
}

.form {
  margin-top: 20px;
}

.login_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding: 0 12%;
  border-bottom: 5px solid #5cb85c;
}

.signup_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  border-bottom: 5px solid #e0e0e0;
  padding: 0 5%;
}

.login_btn_box {
  width: 50%;
}

.form_group {
  padding-bottom: 16px;
}

.label-title {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 12px;
  color: grey;
}

.form_control {
  width: 83%;
  border: none;
  height: 2rem;
  background: #d1d1d1;
  padding: 5px 25px;
  border-radius: 5px;
}

.forgotPassword {
  color: black;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 49%;
  z-index: -1;
}

.checkBoxContainerView {
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: -7px;
  z-index: -1;
}

.rememberMe {
  color: #6200ee;
  font-weight: bold;
  align-self: center;
  z-index: -1;
}

.orLabel {
  color: #00000000;
  font-weight: bold;
  align-self: center;
  margin: 20;
}

.login_signup_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout_wrapper {
  background-color: $WHITE;
  border-radius: 35px;
}

.forgot_password_header {
  position: relative;

  img {
    width: 45%;
  }

  button {
    position: absolute;
    left: 25px;
    top: 60%;
    transform: translateY(-60%);

    svg {
      color: #515156;
    }
  }
}

.form_wrapper {
  padding: 40px;
}

.logo_wrapper {
  padding: 20px;

  display: flex;
  justify-content: center;
  border-bottom: 1px solid $GREEN;
}

.forgot_PW_tab_container {
  width: "100%";
  border-bottom: 5px solid $GRAY_SHADE2;
  display: flex;
  justify-content: center;
}

.forgot_PW_tab {
  width: fit-content;
  text-align: center;
  border-bottom: 5px solid $GREEN;
  margin-bottom: -5px !important;
  padding: 0px 20px 14px;
  font-weight: 500 !important;
  color: #000;
  line-height: normal !important;
  font-size: 22px !important;
  font-family: $ROUNDGOTHIC_BOOK !important;
}

.btn {
  background-color: $GREEN !important;
  width: 100%;
  color: $WHITE !important;
  padding: 10px !important;
  border-radius: 8px !important;
  & span {
    color: $WHITE;
  }
}

.btn_label {
  color: "white" !important;
}

.reset_pwd_text {
  color: $Black;
  text-align: center;
  padding-top: 21px;
  font-size: 14px !important;
  font-family: $MONTSERRAT;

  span {
    color: $GREEN;
  }
}

.button {
  width: 100%;
  background: #5cb85c;
  color: white;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}

.terms_of_condition {
  color: $DARK;
  text-align: center;
  padding: 20px 0px !important;
  font-size: 15px !important;

  span {
    color: $GREEN;
  }
}

.fp_content_wrapper {
  padding: 10px 50px 50px;

  @media screen and (max-width: 600px) {
    padding: 15px 40px 40px;
  }
}