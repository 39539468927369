@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.section {
  padding: 60px;
}

.product_details {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .prd_h3 {
    font-family: $ROUNDGOTHIC_BOOK !important;
  }
}

@media screen and (max-width: 428px) {
  .product_details_tabs {
    margin-bottom: 20px;
  }
}

.product_price {
  color: $GREEN;
  font-size: 22px !important;
  font-weight: 600 !important;
}

.product_compare_at_price {
  color: #77808a !important;
  margin-left: 10px !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  text-decoration: line-through;
}

.product_price_tags_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  padding-bottom: 5px;

  .product_price_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.farm_product_price {
  display: inline-block;
}

.product_details_label {
  color: $Black;
  text-transform: uppercase;
  padding-right: 10px;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: $MONTSERRAT !important;
}

.product_details_user_review {
  text-transform: none !important;
  font-size: 18px !important;
  display: inline-block !important;
}

.product_details_review_box {
  display: flex;
  align-items: center;
}

.product_details_review_box>span {
  margin-left: 15px;
}

.product_details_children_wrapper {
  border: 1px solid $GRAY_SHADE3;
  padding: 20px 30px;
  border-radius: 20px;
}

.product_details_customer_review_count {
  color: $GRAY_SHADE4;
  border-bottom: 1px solid $GRAY_SHADE1;
  width: fit-content;
  line-height: 15px !important;
}

.product_details_top_right_heading {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;

  p {
    float: right;
    color: $GREEN;
    text-decoration-line: underline;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 600;
    position: relative;
  }
}

@media screen and (max-width: 428px) {
  .product_details_top_right_heading {
    justify-content: flex-end;

    p {
      float: left;
      position: relative;
      top: -35px;
      left: 10px;
    }
  }
}

.review_label {
  color: $Black;
  font-weight: 600 !important;
  font-size: 18px;
  margin-left: 5px;
}

.write_review_text_area {
  width: 100%;
  resize: none;
  border: 1px solid $GRAY_SHADE3;
  border-radius: 20px !important;
  outline: none;
  padding: 15px;
  font-family: $MONTSERRAT !important;
}

.product_fav {
  background-color: #fff;
  padding: 6px !important;

  &:hover {
    background-color: #fff !important;
  }

  svg {
    color: #394053;
  }

  .fav_fill_icon {
    color: $RED !important;
  }
}

.product_category {
  color: $GREEN;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.product_tags {
  background-color: #9be5bb;
  border-radius: 15px;
  padding: 2px 10px;
  font-family: $MONTSERRAT;
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
}

.product_details_tabs {
  margin-top: 30px;
  display: flex;
}

.product_details_tab {
  background-color: $GREEN;
  color: $WHITE !important;
  border-radius: 12px;
  padding: 8px 24px;
  font-size: 18px;
}

.product_details_tab:hover {
  opacity: 0.9;
}

.product_details_review_heading {
  display: flex;
  align-items: center;
}

.cart_action_container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;

  .product_variant_wrapper {
    flex-grow: 1;

    .product_variant_select {
      width: 100%;
      border-radius: 8px;
      background-color: #f4f5f5;
      
      .MuiSelect-filled {
        border-radius: 8px;
        padding: 10px 15px;
      }

      &.Mui-focused {
        background-color: #f4f5f5;
        border-radius: 8px !important;
      }
    }
  }
}

.cart_btn_container {
  width: 100%;
  margin-top: 15px;

  button {
    width: 100%;
  }
}

.product_stock_info,
.product_less_stock_info {
  color: $RED;
  font-family: $OPEN_SANS !important;
  font-size: 15px !important;
  margin-top: 3px !important;
  padding-left: 2px;
}

.product_less_stock_info {
  color: $RED_SHADE1;
}

.product_details_info {
  display: flex;
  align-items: center;
}

.product_add_cart {
  padding: 7px 25px !important;
  border-radius: 24px !important;
  background-color: $GREEN !important;
  min-width: 120px !important;

  &:disabled {
    opacity: 0.7;
  }
}

.add_cart_loader {
  color: #ffffff !important;
}

.product_details_grey_txt {
  color: #5a5a5a !important;
  font-weight: 500 !important;
  padding-top: 5px;
  font-family: $MONTSERRAT !important;
}