@import "../../../../web/src/assets/css/constants/base/index.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.restOrderList {
  height: 100%;
  background-color: $WHITE;
  border-radius: 10px;
  & .tableContainer {
    margin-bottom: 0;
  }
  &.farmOrderList {
    & .outlineTextfield {
      width: 100% !important;
    }
  }
  & .tabContainer {
    & .customTab {
      & .tableContainer {
        margin-top: 20px;
      }
    }
  }
}

.dishSorting {
  font-family: $POPPINS;
  font-size: 16px;
}

.custom_center_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
