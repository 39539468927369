@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.ce_main_wrapper {
    padding: 80px 0 50px;
    background-color: rgba(20, 118, 32, 0.06);

    .ce_main_heading {
        font-family: $ROUNDGOTHIC_BOOK;
        font-size: 50px;
        line-height: 1.14;
        color: #333;
    }

    .ce_description {
        margin-top: 12px;
        font-family: $MONTSERRAT;
        font-size: 16px;
        font-weight: 600;
        line-height: 2;
        color: rgba(51, 51, 51, 0.5);
    }

    .ce_carousel_wrapper {
        padding: 50px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
        min-height: 200px !important;

        .ce_carousel {
            width: 100vw;
        }
    }

    .ce_empty_container {
        height: 40vh;
        border: 2px solid #d3d3d3;
        border-radius: 20px;
        padding: 15px;
        color: #3bad6c;
        font-size: 23px;
        font-family: $MONTSERRAT;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 480px) {
        padding: 30px 0px 0px;
        .ce_main_heading {
            font-size: 45px;
        }

        .ce_description {
            font-size: 15px;
        }
    }
}