@import "colors.scss";

@mixin scrollbars() {

  // For Google Chrome
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    border-radius: 8px;
    scrollbar-face-color: $GRAY_SHADE8;
    scrollbar-track-color: $GRAY_SHADE8;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $GRAY_SHADE1;
    scrollbar-face-color: $GRAY_SHADE8;
    scrollbar-track-color: $GRAY_SHADE8;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 'inset 0 0 6px rgba(0,0,0,0.00)';
    scrollbar-face-color: $GRAY_SHADE8;
    scrollbar-track-color: $GRAY_SHADE8;
  }
}