@import "../../../../web/src/assets/css/constants/base/global.scss";
@import "../../../../web/src/assets/css/constants/base/colors.scss";

$Banner: "../images/business-teaching-rhpe-8-jc.png";
$card-padding: 20px;
$grid-gutter: 31px;
$color-bg: #f8f8f8;

.container-img {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.backIcon {
  height: 45px;
  width: 45px;
  margin-left: 10px;
  padding: 6px;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  @media screen and (max-width: 400px) {
    height: 25px;
    width: 25px;
  }
}

.heartIcon {
  height: 30px;
  width: 30px;

  @media screen and (max-width: 400px) {
    height: 20px;
    width: 20px;
  }
}

.courseHeader {
  color: #169d4e;
  padding-left: 15px;
  font-weight: 700;
  word-spacing: 4px;
  margin-right: 75px;

  @media screen and (max-width: 560px) {
    font-size: 16px;
    margin-right: 0;
  }

  @media screen and (max-width: 400px) {
    font-size: 14px;
    margin-right: 0;
  }
}

.courseHeaderBox {
  padding: 20px;
  background-color: #f4f9f5;

  @media screen and (max-width: 560px) {
    box-shadow: 0 4px 4px 0 rgba(21, 21, 21, 0.18);
    border: none !important;
  }
}

.rightBox {
  margin-left: 80px;
  margin-top: 27px;
  width: 100%;
  text-align: right;
  box-sizing: content-box;

  @media screen and (max-width: 400px) {
    margin-left: 50px;
  }
}

.container-img-2 {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  height: 380px !important;

  .image {
    position: absolute;
    display: block;
    width: 100%;
    height: 380px !important;
    border-radius: 15px;

    @media screen and (max-width: 768px) {
      position: relative;
      padding: 20px;
      height: 340px !important;
    }

    @media screen and (max-width: 560px) {
      position: relative;
      padding: 20px;
      border-radius: 5px;
      height: 278px !important;
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    height: 340px !important;
  }

  @media screen and (max-width: 560px) {
    position: relative;
    height: 278px !important;
  }
}

.container-educator-img {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;

  .image {
    height: 450px !important;
    margin-top: 30px;
    margin-left: 0px;
    position: absolute;
    display: block;
    width: 100%;
    border-radius: 8px;

    @media screen and (max-width: 768px) {
      height: 300px !important;
    }

    @media screen and (max-width: 600px) {
      height: 270px !important;
    }

    @media screen and (max-width: 520px) {
      height: 220px !important;
    }

    @media screen and (max-width: 400px) {
      height: 177px !important;
    }
  }
}

.rightEducatorContainer {
  text-align: justify;
  margin-left: -5px;
  margin-top: 20px;
  height: 430px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  padding: 10px 40px;
  border-radius: 10px;

  .h3 {
    margin-top: 20px;
    font-size: 27px;
    font-weight: bold;
    text-transform: capitalize;
    color: #333;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }

    @media screen and (max-width: 640px) {
      font-size: 18px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 0;
    padding: 10px 0 0 20px !important;
    height: 100%;
  }
}

.discussionData {
  margin-top: 20px;
  margin-left: 1.8rem;
}

.discContainer {
  background-color: rgba(20, 118, 32, 0.08);
  padding: 22px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;

  h5 {
    font-size: 20px;
    font-family: $MONTSERRAT;
    font-weight: 500;

    @media screen and (max-width: 560px) {
      font-size: 12px !important;
      font-family: $POPPINS;
    }
  }

  p {
    font-size: 18px;
    font-family: $MONTSERRAT;
    font-weight: 500;

    @media screen and (max-width: 560px) {
      font-size: 12px !important;
      font-family: $POPPINS;
    }
  }

  .text {
    .MuiInputBase-input {
      margin-top: 1rem;
      font-size: 16px;
      text-align: justify;

      @media screen and (max-width: 560px) {
        font-size: 12px !important;
      }
    }
  }

  @media screen and (max-width: 560px) {
    padding: 16px;
  }
}

.margin-2 {
  margin: 2rem;
  flex: 1;

  @media screen and (max-width: 560px) {
    margin: 10px 20px;
  }
}

.greenTick {
  width: 40px;
  height: 40px;
  margin-right: 20px;

  @media screen and (max-width: 1160px) {
    width: 35px;
    height: 35px;
  }

  @media screen and (max-width: 960px) {
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 768px) {
    width: 25px;
    height: 25px;
  }

  @media screen and (max-width: 560px) {
    width: 20px;
    height: 20px;
  }
}

.taskTitleWrapper {
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 640px) {
    padding: 0;
  }
}

.ques {
  padding: 0 60px 0 82px;

  @media screen and (max-width: 960px) {
    padding: 0 0 0 20px;
  }
}

.lessonTabSection {
  padding-left: 40px;
  border-bottom: 4px;
  border-color: divider;
  display: flex;
  justify-content: space-between;

  span.MuiTab-wrapper {
    text-transform: capitalize;

    @media screen and (max-width: 1160px) {
      font-size: 32px !important;
    }

    @media screen and (max-width: 960px) {
      font-size: 28px !important;
    }

    @media screen and (max-width: 768px) {
      font-size: 24px !important;
    }

    @media screen and (max-width: 640px) {
      font-size: 20px !important;
    }

    @media screen and (max-width: 560px) {
      font-size: 18px !important;
      font-family: $POPPINS !important;
    }
  }

  @media screen and (max-width: 750px) {
    padding-left: 0;
    flex-direction: column-reverse;
  }
}

.searchWrapper {
  width: 304px;

  .searchBox {
    background-color: #fff;
    border-radius: 24px;
    border: solid 1px #979797;
    font-size: 14px;
    font-family: $MONTSERRAT;

    @media screen and (max-width: 750px) {
      font-family: $POPPINS !important;
      border-radius: 5px;
      background-color: rgba(20, 118, 32, 0.08);
      border: none;
    }
  }

  @media screen and (max-width: 880px) {
    width: 250px;
    margin-right: 50px !important;
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    padding: 0 2rem;
  }

  @media screen and (max-width: 560px) {
    margin-top: 12px;
    width: 100%;
    padding: 0 1rem;
  }
}

.discussionWrapper {
  margin: 0 auto;
  width: 90%;

  .partName {
    margin-left: 20px;
    font-size: 20px;
    font-family: $POPPINS;

    @media screen and (max-width: 560px) {
      font-size: 12px;
      color: #000;
      font-weight: 500;
    }
  }

  .lessonName {
    margin-left: 10px;
    font-size: 20px;
    font-family: $POPPINS;
    font-weight: bold;

    @media screen and (max-width: 560px) {
      font-size: 12px;
      color: #000;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 560px) {
    width: 100%;
  }
}

.DiscussionSection {
  .MuiBox-root {
    @media screen and (max-width: 560px) {
      padding: 0;
    }
  }
}

.margin3 {
  margin-top: 3rem;
}

.margin4 {
  margin-bottom: 4rem;
}

.container-img-3 {
  position: relative;
  width: 100%;
  max-width: 250px;
}

.overlay {
  width: 200px;
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  transition: 0.5s ease;
  opacity: 0;
  font-size: 20px;
  padding: 13px;
  text-align: center;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.replyDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    font-weight: 600;
    color: #000;
    font-size: 20px;
    font-family: $MONTSERRAT;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }
}

.container-img .overlay {
  opacity: 0.69;
}

.container-img-2 .overlay {
  opacity: 0.05;
}

.flex {
  display: flex;
  padding: 1rem;
  justify-content: center;

  .para {
    color: $GRAY_SHADE15;
    font-weight: 500;
    font-size: 16px;
  }
}

.shareMenu {
  position: absolute !important;
  top: 11% !important;
  padding: 20px;
}

.learningHeaderContainer {
  justify-content: unset;
  gap: 1rem;
  align-items: center;
}

.flexOne {
  display: flex;
  padding: 0.5rem;
  justify-content: unset;
  text-align: justify;
  align-items: center;
  margin: 0 0 12px 1rem;

  .rightArrowIcon {
    height: 25px;
    width: 25px;
    margin-right: 1rem;

    @media screen and (max-width: 560px) {
      width: 20px;
      height: 20px;
    }
  }

  .para {
    color: $GRAY_SHADE15;
    font-weight: 500;
    font-size: 16px;

    @media screen and (max-width: 1160px) {
      font-size: 14px;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
      font-family: $POPPINS !important;
    }
  }

  @media screen and (max-width: 560px) {
    padding: 0;
    margin: 0 0 12px 0;
  }
}

.reviewGrid {
  margin: 2.5rem 0 2.5rem;
  justify-content: space-between !important;
}

.reviewText {
  font-weight: bolder;
  font-size: 18px;
}

.reviewImageContainer {
  position: relative;
  margin-right: 20px;
  width: 100px;
  height: 100px;
  padding-top: 0;

  .image {
    position: absolute;
    width: 100px !important;
    height: 100px !important;
    border-radius: 50%;
  }
}

@media screen and (max-width: 900px) {
  .course-con-1 img {
    width: 70%;
  }
}

@media screen and (max-width: 1340px) {
  .course-con-1 img {
    width: 90%;
  }
}

.MuiChip-root {
  border-radius: 10px;
  padding: 4px;
  min-width: 150px;
}

.intrest {
  min-width: 150px;
  list-style: none;
  border: 1px solid;
  border-radius: 10px;
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.intrest:hover {
  color: #20a258;
  background-color: #ffff;
  font-weight: bold;
  cursor: pointer;
}

.chip {
  font-size: large !important;
  font-weight: bold !important;
  background-color: white !important;
  border: 1px solid #00000073 !important;
  border-radius: 18px !important;
  cursor: pointer !important;
  padding: 1.2rem !important;
}

.MuiChip-label {
  color: #8c8c8c !important;
  font-size: 18px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 960px) {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 560px) {
    font-size: 12px;
    font-family: $POPPINS;
  }
}

.MuiChip-label:hover {
  color: #3fa74a !important;
}

.chip:hover {
  border: 1px solid #3fa74a !important;
}

.chip .MuiChip-clickableColorPrimary {
  color: rgb(17, 19, 19);
}

.black-font button {
  color: black !important;
}

.nav-link {
  min-width: 200;
}

.nav-link::after {
  content: "";
  border-bottom: 2px solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.avtar-grp {
  position: absolute;
  max-width: 250px;
  padding: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  bottom: -4rem;
  background: white;
  border-radius: 10px;
  left: -6rem;
  box-shadow: 0px 5px 10px #ccc;

  p {
    color: $GRAY_SHADE15;
    font-weight: 500;
    font-family: $POPPINS;
  }

  h6 {
    font-family: $POPPINS;
    font-size: 14px;
    color: #000;

    @media screen and (max-width: 560px) {
      font-size: 11px;
      font-family: $POPPINS;
    }
  }

  @media screen and (max-width: 960px) {
    height: 160px;
    top: 93%;
    left: 65% !important;
  }

  @media screen and (max-width: 600px) {
    height: 125px;
    padding: 20px;
    width: 160px;
    align-items: center;
    justify-content: center;
    grid-gap: 0;
    gap: 0;
    top: 93%;
    left: 65% !important;
  }

  @media screen and (max-width: 560px) {
    height: 100px;
    padding: 20px;
    width: 145px;
    align-items: center;
    justify-content: center;
    grid-gap: 0;
    gap: 0;
    top: 93%;
    left: 65% !important;
  }

  @media screen and (max-width: 400px) {
    height: 100px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    grid-gap: 0;
    gap: 0;
    top: 93%;
    left: 55% !important;
  }
}

.one {
  position: absolute;
  max-width: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  top: 18rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #ccc;
  padding: 1rem 0.5rem;
  width: 200px;
  align-items: center;
  left: 59%;

  .avatarImgWrapper {
    width: 62px;
    height: 62px;
  }

  p {
    color: $GRAY_SHADE15;
    font-weight: 500;
    font-family: $POPPINS;
  }

  h6 {
    font-family: $POPPINS;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-transform: capitalize;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    max-width: 170px;
    top: 16rem;
    left: 56%;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 560px) {
    position: absolute;
    max-width: 145px;
    top: 14rem;
    left: 56%;
    margin-bottom: 20px;
  }
}

.avatarImg {
  width: 62px;
  height: 62px;

  & .MuiAvatarGroup-root {
    padding: 12px;
  }

  & .MuiAvatarGroup-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.delDisIcon {
  border: 1px solid red;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  width: 32px;
  height: 35px;

  @media screen and (max-width: 560px) {
    width: 29px;
    height: 28px;
  }
}

.edit_Disc {
  border: 1px solid #3bad6c !important;
  margin-left: 10px;
  padding: 4px 30px;
  border-radius: 25px;
  font-size: 16px;
  color: #3bad6c;

  span {
    color: #3bad6c !important;
    padding: 0px 30px;
    text-transform: none;
    font-weight: 600;
  }

  @media screen and (max-width: 560px) {
    border-radius: 5px !important;
    padding: 4px 20px;
    font-size: 12px;
    font-family: $POPPINS;
  }
}

.padding {
  padding: 0 5rem;

  @media screen and (max-width: 960px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 560px) {
    padding: 0;
    margin-top: 1.2rem;
    font-family: $POPPINS;
  }
}

.padding2 {
  padding: 0 5rem;
  margin-top: 2.5rem;

  @media screen and (max-width: 960px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 560px) {
    padding: 0;
    margin-top: 1.2rem;
    font-family: $POPPINS;
  }
}

.margin1 {
  margin-top: 4.5rem;
}

.courseInfoContainer {
  padding: 30px;
  border: none;
  box-shadow: 0px 5px 10px #ccc;

  @media screen and (max-width: 960px) {
    padding: 16px 10px;
  }

  @media screen and (max-width: 560px) {
    padding: 0 20 20px;
    box-shadow: none;
  }
}

.courseDetail {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.learningPadding {
  padding: 0 0 0 3rem;

  .searchbox {
    padding-left: 1rem;

    @media screen and (max-width: 1160px) {
      margin-left: -60px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: -55px;
      flex-direction: row;
      padding: 0;
    }

    .customTextfield {
      margin-left: 32px;

      @media screen and (max-width: 768px) {
        margin-left: -32px;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 1160px) {
    padding: 0 0 0 1rem;
  }

  @media screen and (max-width: 960px) {
    padding: 0 2.5rem;
  }

  @media screen and (max-width: 600px) {
    padding: 0 1.2rem;
  }
}

.listSearchbox {
  display: flex;
  margin: 0 auto;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding: 20px;
    width: 100%;
  }

  .customTextfield {
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.left-img {
  width: 450px;
  height: 500px;
  border-radius: 15px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 400px !important;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 300px !important;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    height: 210px !important;
  }
}

.heading {
  margin: 1.5rem 4.5rem;
  color: black;
  font-family: $ROUNDGOTHIC_MEDIUM !important;
}

.sub-heading {
  margin: 0.5rem 4.7rem;
  color: black;
  font-family: $ROUNDGOTHIC_MEDIUM !important;
}

.course-con {
  margin: 2rem 0rem 2.5rem 5rem !important;
}

.container {
  display: flex !important;
  justify-content: center !important;
}

.replyBox {
  width: 100%;
  height: 240px;
  text-align: right;
  box-sizing: content-box;
}

.saveBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
}

.editDelContainer {
  display: flex;
  justify-content: flex-end;

  .replyBtnWrapper {
    display: flex;
    margin-top: 1rem;
    margin-left: 83%;
    cursor: pointer;

    .delIcon {
      border: 1px solid red;
      margin-right: 10px;
      border-radius: 50%;
      padding: 6px;
      width: 32px;
      height: 35px;
    }

    .edit_reply {
      border: 1px solid #3bad6c !important;
      border-radius: 25px !important;
      cursor: pointer;
      padding: 4px 30px;
      font-size: 16px;
      color: #3bad6c;

      span {
        color: #3bad6c !important;
        padding: 0px 30px;
        text-transform: none;
        font-weight: 600;
      }
    }
  }
}

.center-sec {
  margin: 3rem;
  display: flex;
  justify-content: center;

  .sec-1 {
    height: 350px;
    display: flex;
    justify-content: center;
  }
}

.enrolledContainer {
  margin-top: 3rem;

  .margin3 {
    @media screen and (max-width: 768px) {
      padding: 0 20px;
      margin: 24px 0 50px;
    }

    @media screen and (max-width: 560px) {
      padding: 0 20px;
      margin-top: 24px 0;
    }
  }

  @media screen and (max-width: 960px) {
    margin-top: 21px;
    flex-direction: column-reverse;
  }
}

.enrolledTabContainer {
  padding: 0px 40px 0;
  width: 100%;
  margin-top: 20px;

  .MuiBox-root {
    @media screen and (max-width: 560px) {
      padding: 0;
      margin-top: 6px;
    }
  }

  @media screen and (max-width: 960px) {
    padding: 70px 0px;
  }

  @media screen and (max-width: 768px) {
    padding: 32px 0px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 560px) {
    padding: 35px 20px;
  }
}

.tabBox {
  padding-left: 40px;
  border-bottom: 4px;
  border-color: divider;

  .MuiTab-wrapper {
    @media screen and (max-width: 768px) {
      font-size: 20px !important;
    }

    @media screen and (max-width: 560px) {
      font-size: 12px !important;
      font-family: $POPPINS !important;
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
}

@media screen and (max-width: 480px) {
  .avtar-grp {
    left: 1rem;
  }

  .heading {
    margin: 0.5rem !important;
  }

  .sub-heading {
    margin: 0.5rem !important;
  }

  .course-con {
    margin: 2rem 0rem 0rem 0.75rem !important;
  }

  .center-sec {
    margin: 0rem;
    display: block;

    .sec-1 {
      display: block;

      img {
        width: 100% !important;
      }
    }
  }
}

.slick-slide {
  width: 100%;
  padding: 20px;

  @media screen and (max-width: 600px) {
    padding: 10px;
  }

  .sliderWrapper {
    position: relative;
  }

  img {
    position: relative;
  }
}

.slick-slide.slick-active.slick-center {
  .popTeachCardContainer {
    height: 420px;
  }

  .slider-title {
    font-size: 26px;
  }

  .popTeachName {
    font-size: 40px;
  }
}

.swiperWrapper {
  width: 100vw;

  .swiper-slide {
    padding: 20px;
    transition: 0.5s;
  }

  .swiper-slide-active {
    padding: 0;
    border: 5px solid #000;
  }
}

.swiperContainer {
  .swiper-pagination {
    display: none;
    margin-top: 0px;

    .swiper-pagination-bullet {
      width: 40px;
      height: 7px;
      border-radius: 10px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: #000;
      opacity: 1;
      background: rgba(0, 0, 0, 0.2);

      @media screen and (max-width: 767px) {
        width: 30px;
      }
    }

    .swiper-slide {
      margin-top: 20px;
      transition: 0.5s;
    }

    .swiper-slide-active {
      transition: 0.5s;
      margin-top: 0;
      zoom: 120%;
    }

    .swiperAarrowRow {
      display: flex;
      justify-content: space-between;
      max-width: 350px;
      margin: 100px auto 0 auto;
      position: relative;

      @media screen and (max-width: 767px) {
        max-width: 550px;
      }

      .prevNavigation,
      .nextNavigation {
        cursor: pointer;
        z-index: 10;

        .MuiSvgIcon-root {
          margin-top: -20px;
        }
      }
    }
  }
}

.swiper-slide {
  width: 200px !important;
}

@media screen and (max-width: 960px) {
  .avtar-grp {
    left: 1rem;
  }
}

@media screen and (max-width: 370px) {
  .avtar-grp img {
    height: 3rem !important;
  }
}

@media screen and (max-width: 960px) {
  .page1-img {
    height: 500px;
  }
}

@media screen and (max-width: 430px) {
  .h1 {
    font-size: 49px;
  }
}

@media screen and (max-width: 370px) {
  .h10 {
    font-size: 47px !important;
  }
}

.page1-img {
  margin-top: 2rem;
  width: 100%;
  height: auto;
}

.customAccordian6 .MuiAccordion-root .MuiAccordionSummary-root {
  flex-direction: row !important;
}

.MuiAccordionSummary-content {
  margin-left: 1rem !important;
}

.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon {
  color: black;
  border: 1px solid;
  background: transparent !important;
}

.customAccordian .AccordionSummary .MuiIconButton-label::before,
.customAccordian .AccordionSummary .MuiIconButton-label::after {
  content: " ";
  position: absolute;
  height: 2px;
  width: 12px;
  background-color: #000 !important;
}

.flex-2 {
  display: flex;
  gap: 3.5rem;
  margin: 0 5rem 1rem 5rem;
  flex-wrap: wrap;
  justify-content: center;

  h3 {
    color: $GREEN;
    font-family: $MONTSERRAT;
    font-weight: 600;
    font-size: 34px;
  }

  h5,
  span {
    color: $GRAY;
    font-weight: bold;
  }

  h6 {
    color: $GRAY_SHADE15;
    font-weight: bold;
    font-size: 15px;
  }

  .chip-active {
    border: 1px solid #3fa74a !important;
    color: #3fa74a !important;

    .MuiChip-label {
      color: #3fa74a !important;
    }
  }

  @media screen and (max-width: 960px) {
    justify-content: center;
    gap: 1.2rem;
    margin: 30px 0;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    gap: 1rem;
    margin: 30px 0;
  }

  @media screen and (max-width: 560) {
    justify-content: center;
    gap: 0.8rem;
    margin: 30px 0;
  }
}

.timeWrapper {
  text-align: center;
  display: flex;
}

.clockImg {
  margin-right: 20px;
  width: 50px;
  height: 50px;

  @media screen and (max-width: 960px) {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 640px) {
    margin-right: 5px;
    width: 26px;
    height: 26px;
  }
}

.lessonLength {
  text-align: center;
  padding-right: 40px;
  border-right: 2px solid #92929d;

  h5 {
    font-weight: 500px;
    font-size: 28px;

    @media screen and (max-width: 960px) {
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      width: 80px;
      color: #000;
      font-weight: normal;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 640px) {
    border-right: none;
    padding-right: 0;
  }
}

.enrollStud {
  text-align: center;

  h5 {
    font-weight: 500;
    font-size: 28px;

    @media screen and (max-width: 960px) {
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      color: #000;
      font-weight: normal;
      font-size: 12px;
    }
  }
}

.progressBox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0 16px;

  .width {
    width: 230px;

    @media screen and (max-width: 560px) {
      width: 180px;
    }
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 560px) {
    margin: 11px 0;
  }
}

.priceContainer {
  text-align: center;

  .coursePrice {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 31px;
    letter-spacing: 1;
    color: #000;

    @media screen and (max-width: 960px) {
      font-size: 20px;
    }

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      font-weight: 500;
      font-size: 12px;
    }

    span {
      font-size: 12px;

      @media screen and (max-width: 640px) {
        font-family: $POPPINS;
        font-size: 9px;
      }
    }
  }

  @media screen and (max-width: 640px) {
    text-align: center;
  }
}

.enrollBtnWrapper {
  display: flex;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.flex-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  h3 {
    color: $GREEN;
    font-family: $MONTSERRAT;
    font-weight: 600;
    font-size: 34px;
  }

  .chip-active {
    border: 1px solid #3fa74a !important;
    color: #3fa74a !important;

    .MuiChip-label {
      color: #3fa74a !important;
    }
  }
}

.overlay2 {
  width: 100%;
  border-radius: 37px;
  align-items: center;
}

.btn-1 {
  .MuiButton-label {
    span {
      color: #20a258 !important;
    }
  }
}

.AccordionDetails {
  flex-direction: column;
  margin-left: 182px;

  .para {
    margin-top: -10px;
    font-size: 21px;
    word-spacing: 4;
    color: $GRAY_SHADE15;
  }
}

.MuiAccordionDetails-root {
  margin-left: 1rem;
}

.paper {
  margin: 1rem 2rem !important;
}

.MuiGrid-spacing-xs-1 {
  width: calc(100% + -168px) !important;
  margin-left: 94px !important;
  margin-right: 94px !important;
}

.outletType,
.openDaysCheckBox {
  .MuiGrid-spacing-xs-1 {
    margin-left: 0 !important;
  }
}

.MuiGrid-spacing-xs-10 {
  margin: 0 !important;
}

.MuiFormControl-root {
  width: 100%;

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.textFieldMultiline {
  .MuiInputBase-input::placeholder {
    font-family: $POPPINS;
    font-size: 15px;
    color: #abafb3;
    font-weight: normal;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddd0d05e !important;
    border-radius: 8px !important;
  }
}

.bottom-line {
  border-bottom: 2px solid #3fa74a;
}

@media screen and (max-width: 1260px) {
  .image-1 img {
    width: 1100px !important;
    height: 420px;
  }
}

@media screen and (max-width: 1160px) {
  .image-1 img {
    width: 1000px !important;
    height: 400px;
  }
}

@media screen and (max-width: 1060px) {
  .image-1 img {
    width: 900px !important;
    height: 380px;
  }
}

@media screen and (max-width: 960px) {
  .image-1 img {
    width: 800px !important;
    height: 360;
  }
}

@media screen and (max-width: 860px) {
  .image-1 img {
    width: 700px !important;
    height: 340px;
  }
}

@media screen and (max-width: 760px) {
  .image-1 img {
    width: 600px !important;
    height: 330px;
  }
}

@media screen and (max-width: 660px) {
  .image-1 img {
    width: 450px !important;
    height: 320px;
  }
}

@media screen and (max-width: 560px) {
  .image-1 img {
    width: 350px !important;
    height: 300px;
  }
}

@media screen and (max-width: 460px) {
  .image-1 img {
    width: 350px !important;
    height: 280px !important;
  }
}

@media screen and (max-width: 400px) {
  .image-1 img {
    width: 290px !important;
    height: 260px !important;
  }
}

@media screen and (max-width: 328px) {
  .image-1 img {
    width: 265px !important;
    height: 260px !important;
  }
}

.regular-checkbox {
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.circleBtn-1 {
  .customButton .MuiButton-root:hover .MuiButton-label span {
    color: #ffff !important;
  }
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.regular-checkbox:checked:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #99a1a7;
}

.btn-2 {
  min-width: 16.5rem !important;
}

.btn-start {
  width: 355px;

  @media screen and (max-width: 560px) {
    width: 100%;
  }
}

.contentBlock {
  .search {
    margin-left: 20px;
    font-family: $MONTSERRAT;
    font-weight: bold;
    color: $GRAY_SHADE7;
  }

  @media screen and (min-width: 1160px) {
    padding-left: 0 !important;
  }

  @media screen and (min-width: 600px) {
    padding-left: 32px !important;
  }
}

//----landingpage2-------
.paginationContainer {
  .MuiPagination-ul {
    li {
      .Mui-selected {
        background-color: #169d4e;
        color: #fff;
        border-color: #169d4e;
      }
    }
  }
}

//-----landingpage3--------

.course-overview {
  .h3 {
    font-family: $ROUNDGOTHIC_BOOK;
    font-size: 42px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    color: #333;
    margin-top: 10px;
    text-transform: capitalize;

    @media screen and (max-width: 960px) {
      font-size: 36px;
    }

    @media screen and (max-width: 768px) {
      font-size: 32px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }

  h5 {
    font-family: $MONTSERRAT;
    font-size: 22px;
    color: #333;
    font-weight: 600;
    margin-top: 3px;
    text-transform: capitalize;

    @media screen and (max-width: 960px) {
      font-size: 20px;
    }

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      font-size: 12px;
      color: #000;
      font-weight: normal !important;
    }
  }

  .para {
    font-family: $MONTSERRAT;
    font-size: 22px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: -0.5px;
    color: #333;
    margin-top: 18px;
    word-spacing: 4px;
    text-align: justify;
    word-break: break-word;

    @media screen and (max-width: 960px) {
      font-size: 20px;
    }

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      color: #919191;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 560px) {
    padding: 32px;
    margin-top: 20px;
  }

  @media screen and (max-width: 560px) {
    padding: 20px;
    margin-top: 20px;
  }
}

.timeCal {
  font-family: $MONTSERRAT;

  h3 {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -0.91px;
    color: #20a258;

    @media screen and (max-width: 960px) {
      font-size: 22px;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      font-size: 15px;
    }
  }

  h6 {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: -0.41px;
    text-align: justify;
    color: #333;

    @media screen and (max-width: 960px) {
      font-size: 14px;
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

    @media screen and (max-width: 560px) {
      font-family: $POPPINS;
      font-size: 10px;
    }
  }

  @media screen and (max-width: 560px) {
    width: 80px;
    text-align: left;
  }
}

.courseFlexContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 640px) {
    justify-content: space-evenly;
  }
}

.enrollBtnContainer {
  display: none;

  .customButton {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      padding: 20px;
      text-align: center;
    }

    @media screen and (max-width: 560px) {
      display: block;
    }
  }

  .MuiGrid-grid-xs-12 {
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .circleBtn .MuiButton-root {
    @media screen and (max-width: 560px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

.enrolled-tabs {
  span.MuiTab-wrapper {
    color: #000;
    font-family: $MONTSERRAT;
    font-size: 24px;
    font-weight: 600;
  }
}

.reply-wrapper {
  font-family: $MONTSERRAT;
  margin: 2rem 0 0 5rem;
  background-color: #f0f0f0;
  padding: 36px 39.8px 22px 38px;
  border-radius: 26px;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  margin-bottom: 0px;
}

.reply_btn {
  background-color: $GREEN !important;
  padding: 10px 20px;
  border-radius: 24px;
  border: none;
  color: $WHITE;
  display: flex;

  span {
    color: $WHITE;
    text-transform: capitalize;
  }

  p {
    display: flex;
    color: $WHITE;
    padding-left: 10px;
  }
}

.description {
  margin-top: 1rem;
  color: $GRAY_SHADE15;
  font-size: 14px;
  font-family: $POPPINS;
  margin-bottom: 0.7rem;
}

.knowlink {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
  color: #3bae6d;
  font-weight: bold;
  cursor: pointer;
}

.knowlinkNew {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
  color: #3bae6d;
  font-weight: bold;
}


.send-btn {
  border-radius: 34px !important;
  margin: 0.5rem;
  text-transform: capitalize !important;
  background-color: #ffc107 !important;

  @media screen and (max-width: 560px) {
    padding: 6px !important;
    font-size: 12px;
  }
}

.replyContainer {
  margin-right: 20px;
}

.replyDesc {
  font-family: $MONTSERRAT;
  margin-top: 1rem;
  font-size: 16px;
  text-align: justify;
  color: #5a5a5a;
  font-weight: 500;
}

.replyText {
  margin-left: 80px;
  margin-top: 27px;
  width: 100%;
  text-align: right;
  box-sizing: content-box;
}

.editText {
  margin-top: 1rem;
  font-size: 16px;
  text-align: justify;
}

.saveButton {
  border-radius: 34px;
  margin: 0.5rem;
}

.textContainer {
  margin: 1.5rem 0 4rem auto;
  display: flex;

  @media screen and (max-width: 560px) {
    position: relative;
    font-size: 12px !important;
  }
}

.postBtn {
  padding: 10px 30px;
  display: flex;
  margin-left: 2rem;

  @media screen and (max-width: 560px) {
    position: absolute;
    font-size: 12px;
    top: 13%;
    left: 75%;
    padding: 4px 12px;
    border-radius: 3px !important;
  }
}

.textBox {
  border: 1px solid lightgray;
  border-radius: 15px;
  width: 82%;
  margin: 0;

  .MuiInputBase-input {
    @media screen and (max-width: 560px) {
      font-size: 12px;
      font-family: $POPPINS !important;
    }
  }

  @media screen and (max-width: 560px) {
    border-radius: 5px;
    width: 100%;
  }
}

.author {
  color: $GRAY_SHADE15;
  font-size: 26px;
  font-weight: 500;
  margin-top: 1.5rem;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 560px) {
    font-size: 14px;
    font-family: $POPPINS !important;
    font-weight: 600;
    margin-top: 11px;
  }
}

.status-num {
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: $GRAY_SHADE15;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 560px) {
    margin-right: 85px;
    font-size: 12px;
    font-family: $POPPINS !important;
  }
}

.day-status {
  font-size: 20px;
  font-weight: 500;
  color: $GRAY_SHADE15;
  margin-bottom: 8px;
}

.enrolledLessonHeading {
  font-size: 20px;
  font-weight: 600;
}

.circleBtn .customButton {
  width: 100%;

  .comm-btn {
    color: black !important;
  }
}

.MuiTab-wrapper {
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  font-size: 22px;
}

.imageHeaderContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 560px) {
    margin-top: 20px;
  }

  .image-1 {
    h1 {
      font-size: 38px;
      text-align: center;
      margin-bottom: 1.5rem;
      color: black;
      font-weight: 500;

      @media screen and (max-width: 1160px) {
        font-size: 32px;
      }

      @media screen and (max-width: 960px) {
        font-size: 28px;
      }

      @media screen and (max-width: 768px) {
        font-size: 24px;
      }

      @media screen and (max-width: 640px) {
        font-size: 20px;
      }

      @media screen and (max-width: 560px) {
        font-size: 18px;
        font-family: $POPPINS !important;
      }
    }
  }
}

.section-title {
  margin-top: 2.5rem;
  font-family: $POPPINS;
  font-weight: bold;
  text-align: center;
  color: black;
  font-size: 40px;

  @media screen and (max-width: 1160px) {
    font-size: 32px;
  }

  @media screen and (max-width: 960px) {
    font-size: 28px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (max-width: 560px) {
    font-size: 18px;
    margin: 0 0 32px;
  }
}

.MuiAccordionDetails-root.AccordionSection {
  display: flex;
  padding: 0px;
  margin-left: 0;
  border-bottom: none;
}

.MuiPaper-elevation1 {
  box-shadow: 0 2px 6px -1px rgba(20, 118, 32, 0.11);
}

.video-player {
  position: relative;
  width: 750px;
  height: 500px;

  video {
    width: 750px;
    height: 500px;
    object-fit: contain;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .player-controls {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 750px;
    height: 80px;
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;

    img {
      width: 50px !important;
      height: 50px !important;
      border-radius: 50%;
      box-shadow: rgba(0, 24, 52, 0.08) 0px 24px 48px 0px;
    }
  }
}

.business_banner {
  background: url($Banner) no-repeat;
  background-size: cover;
  padding: 60px;
  display: flex;
  width: 100%;
  align-items: center;

  @media screen and (max-width: 960px) {
    padding: 60px 30px;
  }
}

.businessTeachContainer {
  @media screen and (max-width: 960px) {
    padding: 0 !important;
  }
}

.banner_text {
  color: $WHITE;
  font-weight: 600;
  align-items: center !important;
}

.banner_btn {
  border: 2px solid $WHITE !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  padding: 3px 16px !important;
  font-size: 20px !important;
  align-items: center !important;
}

.btn-label {
  color: $WHITE !important;
  align-items: center !important;
}

.video-player1 {
  margin-left: 20px;
  height: 560px;
  max-width: 1291px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  .video {
    width: 100%;
    border-radius: 20px;
  }

  .player-controls {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @media screen and (max-width: 560px) {
      height: 50px;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }

    img {
      width: 50px !important;
      height: 50px !important;
      border-radius: 50%;
      box-shadow: rgba(0, 24, 52, 0.08) 0px 24px 48px 0px;

      @media screen and (max-width: 560px) {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    height: 238px;
    border-radius: 5px !important;
  }
}

video {
  border-radius: 20px;

  @media screen and (max-width: 560px) {
    border-radius: 5px !important;
  }
}

.lessonInfoContainer {
  margin-top: 4rem;
  box-shadow: 0 0 10px #ccc;

  .marginTwo {
    margin: 2rem 2rem;

    @media screen and (max-width: 560px) {
      margin: 2rem 0 !important;
    }
  }

  @media screen and (max-width: 560px) {
    box-shadow: none;
    margin-top: 35px;
  }
}

.course-intro {
  font-family: $MONTSERRAT;
}

.MuiTypography-root.course-name.MuiTypography-h5 {
  font-family: $MONTSERRAT;
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin-top: 0.5rem;
  padding-left: 20px;
  text-transform: capitalize;

  @media screen and (max-width: 600px) {
    margin: 0;
    font-size: 14px;
    font-family: $POPPINS;
  }
}

.MuiTypography-root.author-name.MuiTypography-h6 {
  font-family: $MONTSERRAT;
  font-weight: 500;
  color: #746f6f;
  margin-bottom: 2.5rem;
  padding-left: 20px;
  font-size: 17px;
  text-transform: capitalize;

  @media screen and (max-width: 600px) {
    margin: 5px 0 20px !important;
    font-size: 12px;
    font-family: $POPPINS;
  }
}

p.MuiTypography-root.overview-box.MuiTypography-body1 {
  font-size: 42px;
  font-weight: 500;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 960px) {
    font-size: 32px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (max-width: 560px) {
    font-size: 15px;
    font-family: $POPPINS;
  }
}

.overviewBoxWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 100px;

  @media screen and (max-width: 768px) {
    margin-top: 12px;
    padding: 20px;
  }
}

.customAccordian .AccordionDetails {
  .MuiTypography-h5 {
    font-family: $POPPINS;
    font-size: 30px;
  }

  &.overviewDetails {
    font-family: $POPPINS;
    margin-top: -108px;

    @media screen and (max-width: 560px) {
      margin-top: -70px;
    }
  }

  .paragraph {
    text-align: justify;
    margin-right: 35px;

    @media screen and (max-width: 560px) {
      margin-right: 0;
    }

    .para {
      font-family: $POPPINS;
      color: $GRAY_SHADE19;
      margin: 0 0 20px 150px;
      font-size: 21px;
      font-weight: normal;

      @media screen and (max-width: 560px) {
        margin: 0 0 20px 70px;
        font-size: 12px;
      }
    }
  }
}

.customAccordian6 .AccordionSummary {
  .MuiTypography-body1 {
    &.resource-title {
      font-family: $POPPINS;
    }
  }

  .lesson {
    font-weight: bold;
    font-size: 20px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }

    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }
}

.customAccordian6 .MuiPaper-elevation1 {
  box-shadow: none;
}

.leftDiscussion {
  width: 85%;
  margin-left: 3.7rem;

  .reply {
    background-color: $GRAY_SHADE2;

    h5 {
      font-size: 20px;
      font-weight: 500;
      font-family: $MONTSERRAT;

      @media screen and (max-width: 560px) {
        font-size: 12px !important;
        font-family: $POPPINS;
      }
    }

    p {
      font-size: 18px;
      font-weight: 500;
      font-family: $MONTSERRAT;

      @media screen and (max-width: 560px) {
        font-size: 12px !important;
        font-family: $POPPINS;
      }
    }

    .MuiInputBase-input {
      margin-top: 1rem;
      font-size: 16px;
      text-align: justify;

      @media screen and (max-width: 560px) {
        font-size: 12px !important;
      }
    }

    @media screen and (max-width: 560px) {
      padding: 16px;
    }
  }

  @media screen and (max-width: 560px) {
    margin-left: 0 !important;
  }
}

input[type="checkbox"] {
  display: none;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  font-family: $POPPINS;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #9098b1;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  height: 25px;
  width: 25px;
  border-radius: 15px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #3fa74a;
  margin-left: -50px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.circleBtn {
  &:hover {
    color: #3fa74a;
  }
}

.circleBtn.searchButton {
  margin-left: -4.4rem;
  margin-top: 1.45rem;

  @media screen and (max-width: 600px) {
    margin-left: -5.4rem;
  }

  @media screen and (max-width: 560px) {
    margin-left: -4.4rem;
  }

  .courseSearch {
    margin-top: 0;
    background-color: #ffc107;
    height: 47px;
    width: 125px;
    border: none;

    @media screen and (max-width: 600px) {
      border-radius: 0;
      height: 32px;
      width: 0;
    }
  }
}

.complete-btn {
  font-size: 20px;
  padding: 12px 24px;
  background-color: rgb(42, 172, 79);
  color: white;
  border-radius: 50px;
  border: none;
  font-family: $POPPINS;
  font-weight: 500;
}

.complete-btn:hover {
  background-color: white;
  color: #3fa74a;
  border: 1px solid #3fa74a;
}

.react-pdf__Page {
  text-align: center;
}

.react-pdf__Page__canvas {
  width: 270px !important;
  height: 320px !important;
  overflow: auto;
}

.react-pdf__Page__textContent {
  display: none;
}

span.MuiTab-wrapper {
  color: #000;
  font-size: 22px;
}

.comment_wrapper {
  margin: 0;
  padding: 20px;
  border-radius: 26px;
  display: flex;
  justify-content: flex-start;
  background-color: #f0f0f0;
}

.MuiBox-root.MuiBox-root-16.discussion {
  margin: 0;
}

.search-discussion {
  position: absolute;
  top: 27%;
  right: 10%;
  border-radius: 20px;
  height: 38px;
  width: 350px;
}

.task-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 837px;
  height: 530px;
  padding: 20px;
  background-color: white;
  border-radius: 27px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    height: 90% !important;
    width: 85%;
    padding: 10px 5px;
  }

  @media screen and (max-width: 560px) {
    height: 90% !important;
    width: 85%;
    padding: 0;
  }

  .shortAns {
    font-size: 18px;
    font-family: $POPPINS;
    height: 122px;
  }
}

.boxAns {
  p {
    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }
}

.boxAns::-webkit-scrollbar {
  display: none;
}

p.MuiTypography-root.answeredText.MuiTypography-body1 {
  font-size: 18px;
  font-family: $POPPINS;
  height: 122px;
}

p.MuiTypography-root.select-text.MuiTypography-body1 {
  font-family: $MONTSERRAT;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin: 0 0 20px;

  @media screen and (max-width: 1160px) {
    font-size: 22px;
  }

  @media screen and (max-width: 960px) {
    font-size: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 560px) {
    font-family: $POPPINS;
    font-size: 16px;
    margin: 0 5px 5px;
  }
}

.horizontal-line {
  margin: 0 -20px;
  border-bottom: 2px solid #ccc;
}

p.MuiTypography-root.task-title.MuiTypography-body1 {
  font-family: $MONTSERRAT;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin-top: 15px;

  @media screen and (max-width: 1160px) {
    font-size: 18px;
  }

  @media screen and (max-width: 560px) {
    font-family: $POPPINS;
    font-size: 16px;
    font-weight: 500;
  }
}

.task-question {
  margin-top: 12px;
  width: 768px;
  background-color: #f5f5f5;

  @media screen and (max-width: 560px) {
    width: 100%;
  }
}

.question {
  font-family: $MONTSERRAT;
  padding: 20px 20px 20px 17px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #000;

  @media screen and (max-width: 1160px) {
    font-size: 14px;
  }

  @media screen and (max-width: 560px) {
    font-family: $POPPINS;
    font-size: 12px;
    font-weight: 500;
    color: #4c4b4b;
  }
}

p.MuiTypography-root.task-option.MuiTypography-body1 {
  font-family: $MONTSERRAT;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin: 15px 0px;

  @media screen and (max-width: 1160px) {
    font-size: 16px;
  }

  @media screen and (max-width: 560px) {
    font-family: $POPPINS;
    font-size: 14px;
  }
}

.ansWrapper {
  margin-top: 24px;
  width: 768px;

  @media screen and (max-width: 560px) {
    width: 0;
  }
}

.option-A {
  width: 56px;
  height: 56px;
  font-family: "Open Sans", sans-serif;
  font-size: 27px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin: 0 6px 0 0;
  padding: 8px 15.8px 8px 16px;
  border-radius: 8px;
  background-color: #8db1f6;
  border: none;

  @media screen and (max-width: 1160px) {
    width: 40px;
    height: 40px;
    font-family: $POPPINS;
    font-size: 20px;
    padding: 0;
  }
}

.option-container {
  width: 373px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  background-color: #fff;
  border: solid 1px #ccc;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 400px) {
    font-family: $POPPINS;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.options-popup {
  width: 300px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  background-color: #fff;
  border: solid 1px #ccc;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    width: 280px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 960px) {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 400px) {
    font-family: $POPPINS;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.select-popup {
  width: 300px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  cursor: pointer;
  text-align: left;
  background-color: #147620;
  color: #fff;
  font-size: 16px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    width: 280px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 960px) {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 400px) {
    font-family: $POPPINS;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.actOptions {
  width: 280px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  background-color: #fff;
  border: solid 1px #ccc;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    font-size: 16px !important;
    padding: 10px 12px;
    margin: 12px 15px 12px 0;
  }

  @media screen and (max-width: 560px) {
    font-size: 12px !important;
    font-family: $POPPINS;
    padding: 10px 20px;
    margin: 5px 0;
    width: 150px;
  }
}

.actOptions-popup {
  width: 240px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  background-color: #fff;
  border: solid 1px #ccc;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    font-size: 14px;
    padding: 10px 20px;
    margin: 10px 10px 10px 0;
  }

  @media screen and (max-width: 560px) {
    font-family: $POPPINS;
    font-size: 12px;
    padding: 10px 20px;
    margin: 5px 0;
    width: 150px;
  }
}

.selectAction {
  width: 280px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #ccc;
  cursor: pointer;
  text-align: center;
  background-color: #147620;
  color: #fff;
  font-size: 26px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    font-size: 16px !important;
    padding: 10px 12px;
    margin: 12px 15px 12px 0;
  }

  @media screen and (max-width: 560px) {
    font-size: 12px !important;
    font-family: $POPPINS;
    padding: 10px 20px;
    margin: 5px 0;
    width: 150px;
  }
}

.actionResult {
  display: flex;
  margin-left: 30px;

  @media screen and (max-width: 1160px) {
    margin-left: 0 !important;
  }
}

.actionResultPopup {
  display: flex;
  margin-right: 20px;
}

.selectAction-popup {
  width: 240px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #ccc;
  cursor: pointer;
  text-align: center;
  background-color: #147620;
  color: #fff;
  font-size: 26px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    font-size: 14px;
    padding: 10px 20px;
    margin: 10px 10px 10px 0;
  }

  @media screen and (max-width: 560px) {
    font-family: $POPPINS;
    font-size: 12px;
    padding: 10px 20px;
    margin: 5px 0;
    width: 150px;
  }
}

.wrongAct {
  background-color: #d16565;
  width: 280px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #ccc;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 26px;
  font-family: $MONTSERRAT;
}

.fullBtnContainer {
  margin: 0 25px 0 0;
  padding: 16px 56px 16.2px;
  border-radius: 37px;
  border: solid 1px #147620;
  background-color: #fff;
  color: "#000";
  font-family: $MONTSERRAT;
  font-size: 16px;
  font-weight: 500;
  align-items: flex-end;

  @media screen and (max-width: 1160px) {
    padding: 14px 20px !important;
  }

  @media screen and (max-width: 960px) {
    padding: 12px 15px !important;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 8px !important;
    font-size: 14px;
  }

  @media screen and (max-width: 560px) {
    padding: 6px 3px !important;
    border-radius: 5px !important;
    font-size: 12px;
  }
}

.fullScreenIcon {
  width: 19px;
  height: 19px;
  margin-right: 20px;

  @media screen and (max-width: 960px) {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }

  @media screen and (max-width: 768px) {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }

  @media screen and (max-width: 560px) {
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }
}

.ansContainer {
  border: 1px solid #979797;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;

  p {
    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 560px) {
    width: 285px;
  }
}

.evenOption {
  display: flex;
  margin-left: 30px;

  @media screen and (max-width: 1160px) {
    margin-left: 20px;
    align-items: center;
  }

  @media screen and (max-width: 710px) {
    margin-left: 0;
    align-items: center;
  }
}

.textImage {
  margin-top: 20px;

  @media screen and (max-width: 1160px) {
    margin-top: 0px !important;
  }
}

p.MuiTypography-root.Options.MuiTypography-body1 {
  font-family: $MONTSERRAT;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}

.cancelBtn {
  margin: 0 25px 0 0;
  padding: 16px 56px 16.2px;
  border-radius: 37px;
  border: solid 1px #147620;
  background-color: #fff;
  color: "#000";
  font-family: $MONTSERRAT;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  @media screen and (max-width: 960px) {
    font-size: 14px;
    padding: 10px 28px !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 8px 20px !important;
  }

  @media screen and (max-width: 560px) {
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 10px !important;
    margin: 0 10px 0 0;
  }
}

.submitBtn {
  padding: 16px 56px 16.2px;
  border-radius: 37px;
  background-image: linear-gradient(106deg, #2dae43 -27%, #147620 151%);
  font-family: $MONTSERRAT;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;

  @media screen and (max-width: 960px) {
    font-size: 14px;
    padding: 10px 28px !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 10px 20px !important;
  }

  @media screen and (max-width: 560px) {
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 10px !important;
    margin: 0 10px 0 0;
  }
}

.taskBtnContainer {
  display: flex;
  margin: 45px 0 20px;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    margin: 0 !important;
  }
}

.imageOpt-Container {
  display: flex;
  margin-left: 80px;

  @media screen and (max-width: 1160px) {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
    margin-top: 5px !important;
  }
}

.textOptContainer {
  display: flex;

  @media screen and (max-width: 560px) {
    margin-left: 0 !important;
  }
}

.optionPopup {
  @media screen and (max-width: 560px) {
    margin-left: 0 !important;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
  }
}

.nextLine {
  margin-top: 20px;

  @media screen and (max-width: 1160px) {
    margin-top: 10px !important;
  }

  @media screen and (max-width: 560px) {
    margin-top: 0px !important;
  }
}

.nextLineSingle {
  margin-top: 10px !important;
  margin-bottom: 10px !important;

  @media screen and (max-width: 1160px) {
    margin-top: 10px !important;
    margin-left: 0px !important;
  }

  @media screen and (max-width: 724px) {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-left: 0px !important;
  }
}

.twoOptions {
  margin-top: 20px;

  @media screen and (max-width: 1160px) {
    margin-top: 10px !important;
  }

  @media screen and (max-width: 560px) {
    margin-top: 5px !important;
  }
}

.select {
  width: 373px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  cursor: pointer;
  text-align: left;
  background-color: #147620;
  color: #fff;
  font-size: 16px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 400px) {
    font-family: $POPPINS;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.selectImage {
  width: 240px;
  height: 160px;
  background-color: #147620;
  cursor: pointer;
  padding: 10px;
  border: solid 1px #abafb3;
  border-radius: 7px;
  margin-left: 14px;

  @media screen and (max-width: 1160px) {
    width: 220px;
  }

  @media screen and (max-width: 400px) {
    width: 160px;
  }
}

.optionImage {
  width: 240px;
  height: 160px;
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
  border: solid 1px #abafb3;
  border-radius: 7px;
  margin-left: 14px;

  @media screen and (max-width: 1160px) {
    width: 220px;
  }

  @media screen and (max-width: 400px) {
    width: 160px;
  }
}

.wrongImage {
  width: 240px;
  height: 160px;
  background-color: #d16565;
  cursor: pointer;
  padding: 10px;
  border: solid 1px #abafb3;
  border-radius: 7px;
  margin-left: 14px;

  @media screen and (max-width: 1160px) {
    width: 220px !important;
  }

  @media screen and (max-width: 400px) {
    width: 160px !important;
  }
}

.imageSize {
  width: 100%;
  height: 100%;
}

.wrong {
  background-color: #d16565;
  width: 373px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 16px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 400px) {
    font-family: $POPPINS;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.wrong-popup {
  background-color: #d16565;
  width: 300px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 16px;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1160px) {
    width: 280px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 960px) {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }

  @media screen and (max-width: 400px) {
    font-family: $POPPINS;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.wrongAct-popup {
  background-color: #d16565;
  width: 240px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #ccc;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 26px;
  font-family: $MONTSERRAT;
}

.optionColor {
  font-family: $MONTSERRAT;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.selectOptImage {
  width: 145px;
  border: 2px solid #ccc;
  padding: 20px 0px;
  margin-left: 10px;
  background-color: #147620;
}

.wrongOptImage {
  width: 145px;
  border: 2px solid #ccc;
  padding: 20px 0px;
  margin-left: 10px;
  background-color: #d16565;
}

.popTeacher {
  text-align: center;
  padding: 1rem;
  font-family: $ROUNDGOTHIC_BOOK;
  font-size: 38px;
  font-weight: 400;
  color: #333;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    font-family: $POPPINS;
  }
}

.MuiTypography-root.popCourse.MuiTypography-h2 {
  color: #333;
  font-family: $ROUNDGOTHIC_BOOK;
  font-size: 56px;
  font-weight: 400;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    text-align: left;
    font-family: $POPPINS;
  }
}

.MuiTypography-root.popText.MuiTypography-h5 {
  margin: 1rem 0;
  color: rgba(51, 51, 51, 0.5);
  font-size: 16px;
  font-weight: 600;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 960px) {
    margin: 1rem 1rem 1rem 0;
  }

  @media screen and (max-width: 560px) {
    font-size: 12px;
    text-align: left;
    font-family: $POPPINS;
    margin: 1rem 0;
  }
}

p.MuiTypography-root.taskPoints.MuiTypography-body1 {
  font-family: $POPPINS;
  font-size: 16px;
  padding: 16px 25px 16.2px;
  border: 1px solid #000;
  border-radius: 37px;
  margin: 0;

  @media screen and (max-width: 1160px) {
    padding: 14px 20px !important;
  }

  @media screen and (max-width: 960px) {
    padding: 12px 15px !important;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 8px !important;
    font-size: 14px;
  }

  @media screen and (max-width: 560px) {
    padding: 6px 3px !important;
    border-radius: 5px !important;
    font-size: 12px;
  }
}

.pointContainer {
  display: flex;
  align-items: center;
  margin: 30px 0 20px;
}

.paraText {
  margin-top: 1rem;
  font-size: 16px;
  margin-left: -50px;
  font-family: "Helvetica", sans-serif;
  color: #000;
  width: 485px;
  text-align: justify;
  letter-spacing: 0.23px;

  @media screen and (max-width: 1160px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 960px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 768px) {
    margin-left: -30px;
    font-size: 14px;
    width: 180px;
  }

  @media screen and (max-width: 560px) {
    font-size: 12px;
    width: 145px;
    font-family: $POPPINS;
    color: #5a5959;
  }
}

.slide {
  position: relative;
  width: 600px;
  text-align: center;

  .slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 999;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: center;

    .slider-title {
      color: #fff;
      margin-bottom: 10px;
      font-size: 26px;
      font-weight: 600;
    }

    .slider-desc {
      color: #fff;
      margin-bottom: 30px;
      font-size: 18px;
    }
  }
}

.slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.slick-center {
  transform: scale(1.08);
  z-index: 1;
}

p.MuiTypography-root.select-option.MuiTypography-body1 {
  color: #9098b1;
  font-size: 18px;
  font-weight: 500;
  font-family: $POPPINS;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: 1160px) {
    font-size: 14px !important;
  }

  @media screen and (max-width: 724px) {
    font-size: 14px !important;
    margin-left: 0px;
  }

  @media screen and (max-width: 560px) {
    font-size: 12px !important;
    margin-left: 0px;
  }
}

h4.MuiTypography-root.taskCount.MuiTypography-h4 {
  font-family: $POPPINS;
  color: #000;
  font-weight: 500;

  @media screen and (max-width: 1160px) {
    font-size: 28px;
  }

  @media screen and (max-width: 960px) {
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 640px) {
    font-size: 16px;
  }

  @media screen and (max-width: 560px) {
    font-size: 12px;
    font-weight: 400;
  }
}

textarea.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline.MuiInput-inputMultiline {
  font-family: $POPPINS;
}

.taskTitle {
  p {
    font-family: $POPPINS !important;
    font-size: 25px;
    font-weight: 500;
    color: #9098b1 !important;

    @media screen and (max-width: 960px) {
      font-size: 20px !important;
    }

    @media screen and (max-width: 768px) {
      font-size: 15px !important;
    }

    @media screen and (max-width: 560px) {
      font-size: 12px !important;
    }
  }
}

.expandedTitle {
  p {
    font-family: $POPPINS !important;
    font-size: 25px;
    font-weight: 500;
    color: #000 !important;

    @media screen and (max-width: 960px) {
      font-size: 20px !important;
    }

    @media screen and (max-width: 768px) {
      font-size: 15px !important;
    }

    @media screen and (max-width: 560px) {
      font-size: 12px !important;
    }
  }
}

.taskAccordion {
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 8px;
  }
}

.react-player {
  width: 1170px;
  height: 550px;
}

.player-controls {
  position: absolute;
  left: 48px;
  bottom: -235px;
  width: 1168px;
  height: 70px;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  img {
    width: 50px !important;
    height: 50px !important;
  }
}

.MuiGrid-root.video-player1.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12 {
  width: 1291px;
}

.sliderSlickContainer {
  position: relative;
  height: 380px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sliderImage {
  position: relative;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sliderDetailContainer {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column-i;
  align-items: center;
  justify-content: flex-end;
  border-radius: 20px;
  padding: 0px 20px 20px;
  gap: 10px;
}

.sliderName {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.sliderSkill {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.ce_carousel_wrapper {
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  min-height: 200px !important;

  @media screen and (max-width: 600px) {
    padding: 0;
  }

  .ce_carousel {
    width: 100vw;

    @media screen and (max-width: 960px) {
      .popTeachCardContainer {
        height: 300px;
      }

      .slick-slide.slick-active.slick-center {
        .popTeachName {
          font-family: $POPPINS;
          font-size: 28px;
        }

        .popTeachCardContainer {
          height: 340px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      .popTeachCardContainer {
        height: 185px;
      }

      .slick-slide.slick-active.slick-center {
        .popTeachName {
          font-family: $POPPINS;
          font-size: 12px;
        }

        .popTeachCardContainer {
          height: 200px;
        }
      }
    }
  }
}

.slick-track {
  margin: auto;
}

.shareModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 180px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 20px;
  border-radius: 12px;

  @media screen and (max-width: 600px) {
    width: 450px;
  }

  @media screen and (max-width: 500px) {
    width: 360px;
  }

  @media screen and (max-width: 400px) {
    width: 300px;
  }
}

p.MuiTypography-root.shareCourseText.MuiTypography-body1 {
  font-family: $POPPINS;
  font-weight: 500;
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
}

.copyBtn {
  padding: 0 20px;
  border: none;
  background-color: #20a258;
  color: #fff;
  display: flex;
  align-items: center;
}

.copiedTxt {
  position: absolute;
  top: 85px;
  right: 20px;
  color: #20a258;

  @media screen and (max-width: 600px) {
    top: 75%;
    left: 35%;
  }
}

svg.MuiSvgIcon-root.threeDots {
  position: relative;
  font-size: 40px;
  border-radius: 50%;
  cursor: pointer;
  background-color: white;
  margin-left: auto;
  border: 1px solid green;

  @media screen and (max-width: 560px) {
    font-size: 25px;
  }
}

.MuiGrid-root.shareContainer.MuiGrid-container {
  width: 400px;
  display: flex;
  border: 1px solid rgb(32, 162, 88);
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    width: 400px;
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 500px) {
    width: 320px;
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 400px) {
    width: 260px;
    flex-wrap: nowrap;
  }
}

.sharelink {
  .Mui-disabled {
    -webkit-text-fill-color: #000 !important;
  }
}

.completedBtn {
  h5 {
    color: $GREEN_SHADE6;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 600;
  }
}

.lessonBtn {
  border-radius: 40px;
  padding: 10px 20px;
  border: 1px solid $GREEN_SHADE6;
  background-color: $GREEN;

  .MuiButton-label {
    text-transform: none;
    color: $WHITE;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 560px) {
    border-radius: 5px !important;
    padding: 10px 12px;
  }
}

.overviewLesson {
  margin: 0 auto;
  width: 90%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.react-player1 {
  position: relative;
  overflow: hidden;

  .video {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .player-controls1 {
    position: absolute;
    left: 0;
    bottom: 7px;
    width: 100%;
    height: 70px;
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    img {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

.popTeachCardContainer {
  position: relative;
  height: 380px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.popTeachCardImage {
  position: relative;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popCardDetailContainer {
  font-family: $MONTSERRAT;
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 20px;
  padding: 0px 20px 20px;
  gap: 10px;

  @media screen and (max-width: 600px) {
    border-radius: 5px;
    padding: 0px 15px 15px;
    grid-gap: 8px;
    gap: 8px;
  }

  .popTeachName {
    font-family: $MONTSERRAT;
    font-size: 36px;
    font-weight: 600;
    color: #fff;
    text-align: center;

    @media screen and (max-width: 960px) {
      font-size: 24px;
      font-weight: 500;
    }

    @media screen and (max-width: 600px) {
      font-size: 12px;
      font-family: $POPPINS;
      font-weight: 500;
    }
  }

  .popTeachSkill {
    font-family: $MONTSERRAT;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-align: center;

    @media screen and (max-width: 960px) {
      font-size: 14px;
    }

    @media screen and (max-width: 600px) {
      font-size: 10px;
      font-family: $POPPINS;
      font-weight: normal;
    }
  }
}

.courses_img_wrapper {
  width: 100%;
  cursor: pointer;
  height: 250px;

  img {
    padding: 20px;
    border-radius: 30px;
    width: 100%;
    height: 100%;
  }
}

.learningHeading {
  font-family: $ROUNDGOTHIC_MEDIUM;
  font-weight: 500;
  font-size: 62px;
  padding-left: 2.5rem;

  @media screen and (max-width: 1160px) {
    padding-left: 0;
    font-size: 52px;
  }

  @media screen and (max-width: 960px) {
    font-size: 48px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    padding-left: 0;
    margin-bottom: 50px;
    margin-left: -32px;
  }

  @media screen and (max-width: 600px) {
    padding: 0;
    position: absolute;
    font-family: $POPPINS;
    font-size: 20px;
    font-weight: 500;
    width: 170px;
    top: 15%;
    left: 5%;
    margin-left: 0;
  }
}

.totalStudents {
  font-weight: 500;
  margin-top: 20px;
  font-family: $POPPINS;
}

.educatorDescription {
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  text-align: justify;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 560px) {
    margin-top: 12px;
    font-size: 12px;
    font-family: $POPPINS;
    color: #919191;
  }
}

.task_result_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 100vw;

  svg {
    color: $GREEN;
  }
}

.courseEnrolledText {
  h6 {
    color: #92929d;
    font-weight: 500 !important;
  }
}

.taskImageContainer {
  width: 100%;

  div {
    padding-top: 0;
    max-height: 250px;
    max-width: 700px;
  }

  img {
    width: 100%;

    @media screen and (max-width: 1160px) {
      width: 100% !important;
    }

    .taskImage {
      @media screen and (max-width: 768px) {
        width: 350px !important;
        object-fit: fill;
      }

      @media screen and (max-width: 560px) {
        width: 285px !important;
        object-fit: fill;
      }
    }
  }
}

.imageWrapper {
  padding-top: 0;
  height: 138px;
}

.reactPlayerWrapper {
  video {
    object-fit: contain;

    @media screen and (max-width: 560px) {
      object-fit: contain;
    }
  }
}

.videoThumbnail {
  video {
    border-radius: 8px !important;
    object-fit: fill !important;

    @media screen and (max-width: 560px) {
      width: 102px !important;
      height: 57px !important;
      border-radius: 5px !important;
    }
  }

  .video {
    height: 84px;
    width: 150px;
    object-fit: fill;
    border-radius: 8px !important;
    border: 1px solid #ccc;

    @media screen and (max-width: 560px) {
      width: 102px !important;
      height: 57px !important;
      border-radius: 5px !important;
    }
  }

  .video1 {
    height: 75px;
    width: 125px;
    object-fit: fill;
    border-radius: 8px !important;
    border: 1px solid #ccc;

    @media screen and (max-width: 560px) {
      width: 73px !important;
      height: 44px !important;
      border-radius: 5px !important;
    }
  }
}

.playerBox {
  margin-bottom: 150px;
}

.playerPadding {
  padding-left: 10px;

  @media screen and (max-width: 960px) {
    padding: 0 20px;
    margin-bottom: 2rem !important;
  }
}

.popularCoursesContainer {
  margin: 4rem 3rem 1rem 3rem;
  margin-left: 120px;

  @media screen and (max-width: 1160px) {
    margin-left: 38px;
  }

  @media screen and (max-width: 960px) {
    margin: 43px 17px 5px 38px;
    font-weight: normal;

    div {
      margin-right: 40px;
    }
  }

  @media screen and (max-width: 600px) {
    margin: 43px 17px 5px 19px;
    font-weight: normal;

    div {
      margin-right: 40px;
    }
  }
}

.popCourseWrapper {
  margin: 2rem 3rem 1rem 3rem;

  @media screen and (max-width: 1160px) {
    margin: 0 0 12px 0 !important;
    width: 100% !important;
    font-weight: normal;
    padding: 20 0px;
  }
}

.viewBtnWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem !important;
}

.videoRightBox {
  margin-left: 20px;
}

.popTeachContainer {
  margin-top: 2rem;
  background-color: #e9eee9;
  justify-content: center;

  @media screen and (max-width: 600px) {
    margin-top: 0;
    background-color: #fff;
  }
}

.popTeachPadding {
  padding: 2rem;

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
}

.enrollImgContainer {
  display: flex;
  justify-content: center;
}

.learningEnrollSection {
  margin-top: 8rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 960px) {
    margin-top: 11rem;
  }

  @media screen and (max-width: 600px) {
    margin-top: 14rem;
  }

  @media screen and (max-width: 560px) {
    margin-top: 180px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 400px) {
    margin-top: 200px;
    margin-bottom: 0px;
  }
}

.enrollContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 60px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 40px;
  }
}

.enrollHeading {
  width: 563px;
  letter-spacing: 4.36px;
  font-size: 46px;
  margin-left: -50px;
  font-weight: 500;

  @media screen and (max-width: 1160px) {
    width: auto;
    margin-left: 0;
  }

  @media screen and (max-width: 960px) {
    width: auto;
    margin: 0;
    font-size: 32px;
  }

  @media screen and (max-width: 768px) {
    margin-left: -30px;
    box-sizing: content-box;
    width: 180px;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #000;
  }

  @media screen and (max-width: 560px) {
    box-sizing: content-box;
    width: auto !important;
    font-family: $POPPINS;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #000;
  }
}

.landingEnroll {
  margin-top: 2rem;
  margin-left: -50px;

  @media screen and (max-width: 1160px) {
    margin-left: 0;
  }

  @media screen and (max-width: 768px) {
    margin-left: -30px;
  }
}

.headerImg {
  animation-duration: 500ms;
  margin-top: 25px;
  border-radius: 15px;
  width: 750px;
  height: 510px;

  @media screen and (max-width: 960px) {
    width: 100%;
    height: 380px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 320px;
    margin-top: 0;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    height: 230px;
    margin-top: 0;
  }
}

.groupImg {
  height: 4rem;
  width: 150px;

  @media screen and (max-width: 600px) {
    width: 120px;
    margin: 0;
  }

  @media screen and (max-width: 560px) {
    width: 100px;
    margin: 10px 0;
  }
}

.MuiFormControl-root.MuiTextField-root.searchContainer {
  padding-left: 20px;
  min-width: 350px;
  background-color: white;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #ccc;
  margin-top: 1.5rem;
  color: darkgray;

  @media screen and (max-width: 600px) {
    min-width: 0;
    border-radius: 0;
  }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.landingSearch {
  margin-top: -6px;
  background-color: #ffc107;
  color: #fff;
  height: 45px;
  width: 125px;
  border: none;
  &:hover {
    opacity: 0.92;
    span {
      color: #fff;
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: -0.7rem;
    width: 60px;
    height: 30px;
    border-radius: 0px;
  }
}

.circleBtn.searchBoxContainer {
  margin-left: -3.5rem;
  margin-top: 30px;

  @media screen and (max-width: 600px) {
    margin-top: 40px;
    margin-left: -5.5rem;
  }

  @media screen and (max-width: 560px) {
    margin-top: 40px;
    margin-left: -4.5rem;
  }
}

.selectText {
  text-align: center;
  font-size: 56px;
  font-family: $RIGHTEOUS;
  margin: 2rem 0rem;
  font-weight: lighter;
  color: #333;

  @media screen and (max-width: 960px) {
    font-size: 48px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    color: #000;
    font-weight: 500px;
    margin: 0;
  }

  @media screen and (max-width: 560px) {
    font-size: 24px;
    font-family: $POPPINS;
    color: #000;
    font-weight: 500px;
    margin: 0;
  }
}

.selectCourseContainer {
  margin-top: 2rem;
}

.MuiTypography-root.courseText.MuiTypography-h5 {
  margin: 3.5rem 0 0.1rem 7.2rem;
  color: #333;
  font-size: 56px;
  font-family: $RIGHTEOUS;

  @media screen and (max-width: 960px) {
    font-size: 40px;
    padding: 0 2.5rem;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    padding: 0 2rem;
    margin: 0;
  }

  @media screen and (max-width: 560px) {
    font-size: 20px;
    font-family: $POPPINS;
    padding: 0 1.35rem;
    margin: 0;
  }
}

p.MuiTypography-root.courselistText.MuiTypography-body1 {
  font-size: 16px;
  margin: 0.5rem 0 2rem 7.2rem;
  color: rgba(51, 51, 51, 0.5);
  font-family: $MONTSERRAT;
  font-weight: 600;

  @media screen and (max-width: 960px) {
    font-size: 14px;
    padding: 0 2.5rem;
    margin: 5px 0 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 0 2rem;
    margin: 5px 0 0;
  }

  @media screen and (max-width: 560px) {
    font-size: 12px;
    font-family: $POPPINS;
    padding: 0 1.35rem;
    margin: 5px 0 0;
  }
}

.educatorHeadline {
  color: #9098b1;
  font-weight: 500;
  font-size: 12px;
}

.lessonWrapper {
  padding: 0 1rem;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
}

.courseTitle {
  font-size: 48px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }

  @media screen and (max-width: 560px) {
    font-size: 18px;
    font-family: $POPPINS !important;
    color: #000;
    width: 126px;
  }
}

.reviewPadding {
  .comment {
    font-size: 16px;
    font-family: $MONTSERRAT;
  }

  .comment_date {
    display: flex;
    justify-content: flex-end;
  }

  h5 {
    font-weight: 600;
    color: #000;
  }
}

@media screen and (max-width: 560px) {
  body,
  html {
    font-family: $POPPINS !important;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.landingSearch {
    margin-top: -0.7rem;
    width: 60px;
    height: 30px;
    border-radius: 0px;
  }

  .avtar-grp {
    h6 {
      font-size: 11px;
      font-family: $POPPINS;
    }

    p {
      font-size: 11px;
    }

    .totalStudents {
      margin-top: 0;
    }
  }

  .enrollImgContainer {
    height: 100%;
  }

  .enrollHeading {
    box-sizing: content-box;
    width: 145px;
    font-family: $POPPINS;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #000;
  }

  .landingEnroll {
    margin-top: 0;
  }

  .MuiGrid-spacing-xs-1 {
    width: 100% !important;
    margin: 0 !important;
  }

  .courses_img_wrapper {
    height: 150px !important;

    div {
      height: 150px !important;
    }

    img {
      width: 100%;
      height: 150px !important;
    }
  }

  .circleBtn .MuiButton-root {
    padding: 7px 11px 8.1px;
    border-radius: 5px;
    width: 100% !important;

    .MuiButton-label span {
      font-size: 12px;
    }
  }

  // landingpage2

  .selectCourseContainer {
    margin-top: 28px;
    padding-right: 0;
  }

  .chip {
    border-radius: 3px !important;
    padding: 5px 10px !important;
  }

  .MuiChip-root {
    min-width: 0px !important;
  }

  .chip-active {
    border: 1px solid #3fa74a !important;
    color: #3fa74a !important;

    .MuiChip-label {
      color: #3fa74a !important;
    }
  }

  // landing page 3

  .courseDetail {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .container-img-2 {
    position: relative;
    height: 278px !important;

    .image {
      position: relative;
      padding: 20px;
      border-radius: 5px;
      height: 278px !important;
    }
  }

  .one {
    position: absolute;
    max-width: 145px;
    top: 14rem;
    left: 56%;
    margin-bottom: 20px;

    h6,
    p {
      font-size: 12px;
    }

    .avatarImgWrapper {
      width: 50px;
      height: 50px;
    }
  }

  .margin1 {
    margin-top: 0;

    .MuiPaper-outlined {
      border: none;
    }
  }

  .customAccordian {
    padding: 0 20px;
    border-top: #fff !important;
    border-bottom: #fff !important;

    .MuiAccordion-root {
      background-color: #f2f7fe !important;
    }
  }

  .margin3 {
    margin-top: 0;
    margin-bottom: 0 !important;
  }

  .rightEducatorContainer {
    width: 100%;
    margin-top: 0;
    padding: 10px 0 0 20px !important;
    height: 100%;
  }

  .margin4 {
    padding: 0 20px;
    margin-bottom: 0;
  }

  .knowlink {
    font-size: 12px;
    font-weight: 500;
    font-family: $POPPINS;
  }

  .comment_wrapper {
    flex-direction: row;
    border-radius: 5px !important;

    h5,
    p {
      font-size: 12px;
      font-weight: normal;
      font-family: $POPPINS !important;
    }

    p {
      color: #7d7979;
    }

    .StyledRating {
      font-size: 16px;
    }

    .reviewPadding {
      padding: 2px !important;
    }

    .rightReviewContainer {
      align-items: center;
      justify-content: space-between;
    }

    .reply_btn {
      padding: 5px 10px;
      font-size: 12px;
      font-family: $POPPINS;
      border-radius: 3px;

      .replyIcon {
        padding-left: 4px;
        color: #fff;

        .MuiSvgIcon-root {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .reviewWrapper {
    padding: 20px;
  }

  .reviewText {
    font-weight: 500;
    font-size: 16px;
  }

  .reviewImageContainer {
    width: 35px;
    height: 35px;

    .image {
      width: 35px !important;
      height: 35px !important;
    }
  }

  .replyBox {
    height: 100%;
  }

  .reply-wrapper {
    padding: 14px;
    border-radius: 5px;

    .MuiInputBase-input {
      font-size: 12px;
      font-family: $POPPINS;
    }

    .send-btn {
      padding: 6px;
      font-size: 12px;
      font-family: $POPPINS;
      text-transform: capitalize;
      border-radius: 5px !important;
    }

    .saveBtnWrapper {
      margin-top: 0;
      margin-bottom: 10px;
    }

    .MuiFormControl-root {
      margin-top: 0 !important;
    }

    .MuiOutlinedInput-root {
      padding: 0;
    }
  }

  .discussion {
    height: auto !important;

    pre {
      font-size: 12px !important;
      color: #5e5e5e !important;
    }

    .replyDetail {
      font-family: $POPPINS !important;

      h5,
      p {
        font-size: 12px;
      }
    }
  }

  .editDelContainer {
    justify-content: left;

    .replyBtnWrapper {
      margin-left: 0;
      margin-top: 0;

      .edit_reply {
        border-radius: 5px !important;
        padding: 4px 20px;
        font-size: 12px;
        font-family: $POPPINS;
      }
    }
  }

  .review_label {
    font-size: 14px;
    font-family: $POPPINS !important;
  }

  .StyledRating {
    font-size: 16px !important;
  }

  .write_review_text_area {
    border-radius: 5px;
    height: 130px !important;
    font-size: 14px;
  }

  .green_round_btn {
    border-radius: 5px !important;
    padding: 4px 12px !important;
    font-size: 12px !important;
  }

  .rightBox {
    height: 57px;
  }

  .day-status {
    font-size: 14px;
    font-family: $POPPINS !important;
  }

  .enrolledLessonHeading {
    font-size: 12px;
    font-family: $POPPINS !important;
  }

  .tabBox {
    padding-left: 0;

    .MuiTab-wrapper {
      font-size: 12px !important;
      font-family: $POPPINS !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .video-player {
    width: 100%;
    height: 320px;

    div {
      width: 100% !important;
      height: 0 !important;
    }

    video {
      object-fit: contain;
      border-radius: 5px;
      height: 320px !important;
    }

    .player-controls {
      bottom: 1px;
      height: 60px !important;
      border-radius: 0;

      img {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .video-player {
    width: 100%;
    height: 238px;

    div {
      width: 100% !important;
      height: 0 !important;
    }

    video {
      object-fit: contain;
      border-radius: 5px;
      height: 238px !important;
    }

    .player-controls {
      bottom: 1px;
      height: 50px !important;
      border-radius: 0;

      img {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
}
