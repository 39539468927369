@import './colors.scss';
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');
@import url('https://fonts.cdnfonts.com/css/avenir');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap');

$BOX_SHADOW: 0px 0px 4px 0px $WHITE;
$BOX_SHADOW2: 0px 16px 20px 0px $GRAY_SHADE8;
$BOX_RADIUS: 20px;
$BORDER_RADIUS: 15px;
$FORM_BORDER_RADIUS: 8px;
$CUSTOM_RADIUS: 20px;
$ROUND_BORDER_RADIUS: 50%;
$drawerWidth: 240px;

// font family
$MONTSERRAT: 'Montserrat', sans-serif;
$POPPINS: 'Poppins', sans-serif;
$OPEN_SANS: 'Open Sans', sans-serif;
$RIGHTEOUS: 'AllRoundGothicW01-Medium';
$ROUNDGOTHIC_BOLD: 'AllRoundGothicW01-Bold';
$ROUNDGOTHIC_MEDIUM: 'AllRoundGothicW01-Medium';
$ROUNDGOTHIC_BOOK: 'AllRoundGothicW01-Book';
$INTER: 'Inter', sans-serif;
$AVENIR_ROMAN: 'Avenir', sans-serif;
$PTSans: 'PT Sans', sans-serif;

@font-face {
  font-family: $ROUNDGOTHIC_BOLD;
  src: url('//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot');
  src: url('//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold')
      format('svg');
}

@font-face {
  font-family: $ROUNDGOTHIC_MEDIUM;
  src: url('//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot');
  src: url('//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium')
      format('svg');
}

@font-face {
  font-family: $ROUNDGOTHIC_BOOK;
  src: url('//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot');
  src: url('//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book')
      format('svg');
}

.errorMsg {
  color: $RED !important;
  font-size: 13px;
  font-weight: 500 !important;
}
