.restaurant_categories_slider {

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        background: #000;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        border-radius: 50%;
        opacity: 0.8;

        svg {
            color: #fff;
            font-size: 20px;
        }
    }

    .slick-prev {
        left: -22px;
        padding-right: 2px;
        @media screen and (max-width: 800px) {
            left: -15px;
        }
    }

    .slick-next {
        right: -22px;
        padding-left: 2px;
        @media screen and (max-width: 800px) {
            right: -15px;
        }
    }

    .slick-prev::before,
    .slick-next::before {
        display: none;
    }

    .custom-indicator {
        bottom: -65px;
    }

    .custom-indicator li {
        width: 50px;
        height: 50px;
        filter: grayscale(100%);
    }

    .custom-indicator li.slick-active {
        filter: grayscale(0%);
    }

    .multi__image:hover {
        transform: scale(1.1);
        transition: 500ms;
    }
}