@import '../../../../../web/src/assets/css/constants/base/colors.scss';
@import '../../../../../web/src/assets/css/constants/base/global.scss';

.guest_landing_banner_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    padding: 75px 20px;
    gap: 20px;

    @media screen and (max-width: 500px) {
        padding: 35px 15px;
    }

    img {
        height: 67.5px;
        @media screen and (max-width: 500px) {
            height: 40px;
        }
    }

    h3 {
        font-family: $MONTSERRAT;
        font-size: 75px;
        font-weight: 800;
        color: #ffffff;
        text-align: center;
        @media screen and (max-width: 800px) {
            font-size: 52px;
        }
        @media screen and (max-width: 500px) {
            font-size: 34px;
        }
    }

    button {
        font-family: $MONTSERRAT;
        background-color: #ffffff;
        padding: 13px 65px;
        text-transform: none;
        border-radius: 36px;
        @media screen and (max-width: 800px) {
            padding: 10px 50px;
        }
        @media screen and (max-width: 500px) {
            padding: 8px 40px;
        }

        &:hover {
            background-color: #ffffff;
            opacity: 0.95;
        }

        span {
            color: #16a252;
            font-size: 18px;
            font-weight: bold;
            @media screen and (max-width: 800px) {
                font-size: 15px;
            }
            @media screen and (max-width: 500px) {
                font-size: 13px;
            }
        }
    }
}