@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";
.check_out_bitcoin_cointainer {
  box-shadow: 0px 0px 12px 0px #c3c3c3;
  padding: 40px 30px;
  border-radius: 20px;
  margin: 40px 0px 0px;
  p {
    color: $Black;
    font-size: 15px;
    font-weight: 100 !important;
  }
  h4 {
    color: $Black;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 500;
  }
}
.bitcoin_copy_address_container {
  div {
    background-color: $GRAY_SHADE2;
    border-radius: 5px;
    padding: 10px 30px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: $Black;
  }
}
.checkout_btn {
  width: 100%;
  margin-top: 30px;
  button {
    width: 100%;
    background-color: $GREEN !important;
    border-radius: 50px !important;
    padding: 10px !important;
    span {
      color: $WHITE;
      text-transform: none;
    }
  }

  .Mui-disabled {
    background-color: #ebe8e8 !important;

    span {
      color: $BLACK;
    }
  }
}

/*bitcoin modal*/

.bitcoin_payment_form_wrapper {
  padding: 30px 35px;
  .bitcoin_payment_form_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    text-align: center;
    img {
      padding-left: 30px;
      height: 80%;
      width: 80%;
    }
  }
  h5,
  h6,
  p {
    padding: 5px;
  }
  h5 {
    font-family: $POPPINS;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-top: 10px;
  }
  h6 {
    font-family: $POPPINS;
    font-size: 18px !important;
    text-align: center;
    font-weight: 300 !important;
    color: #000;
  }
  p {
    color: $GREEN;
    font-size: 18px;
    font-family: $POPPINS;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
  }
  button {
    width: 100%;
    background-color: $GREEN !important;
    border-radius: 30px !important;
    padding: 15px !important;
    span {
      color: $WHITE;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
  }
}
