@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.clf_main_wrapper {
    padding: 50px 0px 80px 0px;

    .clf_main_heading {
        font-family: $ROUNDGOTHIC_BOOK;
        font-size: 50px;
        color: #333;
    }

    .clf_description {
        font-family: $MONTSERRAT;
        font-size: 16px;
        font-weight: 600;
        line-height: 2;
        color: rgba(51, 51, 51, 0.5);
    }

    @media screen and (max-width: 480px) {
        padding: 10px 0px 40px 0px;
        .clf_main_heading {
            font-size: 42px;
        }

        .clf_description {
            font-size: 15px;
        }
    }
}

.view_all_btn_container {
    display: flex;
    justify-content: center;
    align-items: center;

    .orange_btn {
        padding: 10px 50px;
        border-radius: 24px;
        background-color: #feab0b;
        text-transform: none;

        &:hover {
            span {
                color: #000;
            }
        }

        span {
            font-family: $MONTSERRAT;
            font-size: 16px;
            font-weight: bold;
            color: $WHITE;
        }
    }
}

.clf_empty_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 40vh;
    gap: 15px;
    border: 2px solid #d3d3d3;
    border-radius: 20px;

    .clf_loader {
        color: $GREEN;
    }

    h4 {
        color: #333;
        font-weight: 600;
        font-size: 24px;
        font-family: $MONTSERRAT;
    }

    button {
        background-color: $GREEN;
        text-transform: none;
        padding: 8px 20px;
        border-radius: 8px;

        &:hover {
            span {
                color: #333;
            }
        }

        span {
            color: $WHITE;
            font-size: 16px;
        }
    }
}