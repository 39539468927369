@import "../../../../web/src/assets/css/constants/base/colors.scss";

.footer_container {
  background-color: #610808;
  padding: 40px;
  width: 100% !important;

  @media screen and (max-width: 1024px) {
    padding: 30px 0;
  }
}

.footer_logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  & img {
    @media screen and (max-width: 1024px) {
      width: 150px;
    }
  }

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
  }
}

.footer_wrapper {
  color: $WHITE;
}

.foot_list_wrapper {
  padding-bottom: 5px;
}

.foot_list_title {
  font-size: 18px;
  font-weight: 600;
  color: $WHITE;
  margin-bottom: 10px !important;
  text-transform: capitalize;

  @media screen and (max-width: 1024px) {
    font-size: 14px;
    padding-top: 20px;
  }
}

.list-unstyled {
  padding: 3px 0px;
}

.foot_list_item {
  font-size: 16px;
  color: $GRAY_SHADE1 !important;

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
}

.foot_list_item:hover {
  color: $GREEN !important;
}

.subscribe_text:hover {
  color: $GRAY_SHADE1 !important;
}

.foot_input_field {
  border-radius: 50px !important;
  box-shadow: none !important;
  padding: 5px 5px 5px 20px !important;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.contact_us {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.local_store_link {
  font-size: 19px;
  color: $GRAY_SHADE1;
  text-decoration: underline;
  width: fit-content;

  &:hover {
    color: $GREEN;
  }

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
}

.contact_icons {
  border: 2px solid $GRAY_SHADE1;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root {
    font-size: 15px;
  }
}

.contact-item {
  padding-left: 10px;
}

.contact-icon {
  color: $GRAY_SHADE1 !important;
}

.send_icon {
  background-color: $GREEN !important;
  color: $WHITE !important;
}

.social_icons_list {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 5px;

  button {
    border: none;
    background-color: transparent;

    img {
      height: 35px;
      width: 35px;
      cursor: pointer;
    }
  }
}

.social_icon {
  color: $WHITE;
  font-size: 32px !important;
  cursor: pointer;
}

.copywrite {
  width: 100%;
  text-align: center;
  background-color: #222f26;
  padding: 15px;
  p {
    color: #b8b8b8 !important;
  }
}

.email_error {
  color: red;
  margin-top: 3px;
  margin-left: 22px;
}

.contact_us_footer {
  outline: none;
  border: none;
  padding: 5px 40px;
  margin-top: 15px;
  border-radius: 20px;
  color: #494444;

  @media screen and (max-width: 768px) {
    padding: 3px 25px;
    font-size: 10px;
  }
}
