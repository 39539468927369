.render_img_main_wrapper{
    width: 100%;
    border-radius: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    img{
        width: 100%;
        height: 400px;
        // border-radius: 30px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        object-fit: cover;


    }

}
.farm_small_img_main_wrapper{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
}

.farm_small_img_wrapper{
    width: 100px;
    img{
        width: 100%;
        height: auto;
        cursor: pointer;
        object-fit: cover;

    }
}

.image-gallery-icon:hover {
    color: #20a258  !important
}

.image-gallery-thumbnail {
    border-radius: 3px !important;
    
    img {
        border-radius: 2px !important;
        object-fit:fill !important;
    }
    &.active {
        border-color: #20a258 !important;
    }

    &:hover {
        border-color: #20a258 !important;
    }
}

.image-gallery-slide  .image-gallery-image {
    border-radius: 10px !important;
}
