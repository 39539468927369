@import '../constants/base/index.scss';

.customTab {

  .MuiTabs-fixed {
    width: 100%;
    // overflow-x: auto !important;
  }
  & .MuiTabs-root {
    .MuiTabs-scroller {

      &.MuiTabs-indicator {
        background-color: blue;
        padding: 30px;
      }
      .PrivateTabIndicator-colorSecondary-5 {
        background-color: $GREEN !important;
      }
      .MuiTabs-flexContainer {
        border-bottom: 2px solid $GRAY_SHADE3;
        // overflow-x: auto;
        .MuiButtonBase-root {
          .MuiTab-wrapper {
            text-transform: none;
            font-size: 16px;
            color: #575764;
            display: flex;
            align-items: center;
            font-weight: 800;
            & .title {
              padding-right: 25px;
              color: #575764;
            }
            & .badge {
              position: absolute;
              right: 0;
              margin: 0;
              top: 24px;
              color: #fff;
              background-color: #ff8900;
              padding: 5px 8px;
              border-radius: 0;
              font-size: 15px;
            }
          }
          &.Mui-selected {
            .MuiTab-wrapper,
            & .title {
              color: #20a258;
            }
          }
        }
      }
    }
  }
  & .orderTab {
    padding: 0;
    overflow: auto;
    .MuiBox-root {
      padding: 0;
      .MuiTypography-root {
        .tableContainer {
          margin-top: 20px;
          &.MuiTableContainer-root {
            box-shadow: none;
            border-radius: 0px;
            border-radius: none;
            min-height: 300px;
            @include scrollbars();

            & .tableHead {
              & .MuiTableCell-head {
                background-color: $SKYBLUE_SHADE4;
                color: #44444f;
                font-weight: 600;
                font-size: 13px;
                width: 100px;
                white-space: nowrap;
                text-transform: uppercase;
                padding: 5px 10px;
                font-family: $MONTSERRAT;
                &:first-child {
                  width: 200px;
                  padding-left: 40px;
                }

                &:last-child {
                  padding-right: 10px;
                }
              }
            }
          }

          & .MuiTableCell-body {
            color: #000;
            font-weight: normal;
            font-size: 12px;
            white-space: nowrap;
            font-family: $MONTSERRAT;
            &:first-child {
              width: 200px;
              padding-left: 80px;
              font-weight: 500;
            }

            &:nth-child(5) {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
            &.paymentCell {
              text-transform: uppercase;
            }
            & .customerCell {
              display: flex;
              align-items: center;
              & h5 {
                padding-left: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #000;
              }
            }
          }

          & .customerImage {
            height: 40px;
            width: 40px;
            object-fit: cover;
            border-radius: $ROUND_BORDER_RADIUS;
          }
          & .orderPreparingRow {
            cursor: pointer;
          }
        }
      }
    }
    & .cookingList {
      margin: 20px 0;
      & .cookingrow {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: $GRAY_SHADE11;
        padding: 8px 30px;
        border-radius: 15px;
        margin-bottom: 10px;
        font-family: $MONTSERRAT;

        & .blocks:nth-child(2) {
          flex-grow: 2;
        }
        & .blocks:nth-child(3) {
          flex-grow: 3;
        }
        & .blocks:nth-child(4) {
          flex-grow: 8;
        }
      }
      & .orderImg {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 10px;
      }
      & h6 {
        font-size: 16px;
        font-weight: 600;
        color: #333;

        &.title {
          margin: 10px 0;
          font-size: 14px;
          font-weight: 400;
          color: #000;
        }
        @media screen and (max-width: 560px) {
          font-size: 12px;
        }
      }
      & h5 {
        font-size: 22px;
        color: #333;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @media screen and (max-width: 560px) {
          font-size: 16px;
        }
      }
      & .addOns {
        font-family: 'OpenSans', sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: #000;
      }

      & .outlineTextfield {
        margin-bottom: 10px;
      }
      & .greenContained {
        & .MuiButton-root {
          background-color: orange;
          border-color: orange;
          padding: 10px 40px;
          @media screen and (max-width: 560px) {
            padding: 5px 12px;
          }
          & span {
            font-size: 17px;
          }
          &:hover {
            background-color: transparent;
            border-color: orange;
            & span {
              color: orange;
            }
          }
        }
        height: auto !important;
      }
    }
    & .accordian {
      width: 100%;
      & .scrollBlock {
        max-height: 120px;
        overflow: scroll;
        @include scrollbars();
      }
    }
    & .setItemBtn {
      & .MuiButton-root {
        padding: 5.5px 10.5px;
        margin-top: 3px;
      }
    }
  }
  & .adminOrderTab {
    background-color: white;
    overflow: auto;
    .MuiBox-root {
      padding: 0;
    }

    & .dangerCheckbox {
      display: flex;
      width: 100%;
      align-items: center;
      &.payment-checkbox {
        justify-content: center;
      }
    }
  }
  & .restOrderTab {
    & .MuiBox-root {
      padding: 0 20px;
    }
  }
  &.courseDetailTab {
    & .MuiTabs-scroller {
      background-color: $GRAY_SHADE8;
    }
    & .MuiTabs-flexContainer {
      justify-content: space-around;
      border-bottom: 1px solid transparent !important;
      width: 60%;
      margin: 0 auto;
      @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        overflow-x: auto;
        @include scrollbars();
      }
      .MuiTab-root {
        padding: 15px 12px;
      }
      & .MuiTab-wrapper {
        font-size: 17px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    & .tabPanel {
      height: 420px;
      min-height: 420px;
      overflow-y: auto;
      padding-top: 40px;
      & p {
        font-size: 16px;
        color: $DARK_SHADE2;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
  }
  &.customfarmTab {
    background-color: $WHITE;
    height: 100%;
    & .MuiTabs-root {
      background-color: $WHITE;
      border-top-left-radius: $CUSTOM_RADIUS;
      border-top-right-radius: $CUSTOM_RADIUS;

      .MuiTabs-flexContainer {
        .MuiButtonBase-root {
          &.Mui-selected {
            .MuiTab-wrapper {
              color: green;
            }
          }
        }
      }
    }
  }
  &.restDetailTab {
    background-color: $WHITE;
    height: 100%;
    & .MuiTabs-root {
      background-color: $WHITE;
      border-top-left-radius: $CUSTOM_RADIUS;
      border-top-right-radius: $CUSTOM_RADIUS;
      padding: 0px 0;
      & .MuiTabs-flexContainer {
        & .MuiButtonBase-root {

          &.MuiTab-root {
            padding: 23px 18px !important;
            flex-shrink: unset;
            @media screen and (max-width: 560px) {
              padding: 23px 8px !important;
            }
          }
          & .MuiTab-wrapper {
            text-transform: none !important;
            font-size: 15px;
            @media screen and (max-width: 560px) {
              font-size: 12px;
            }
          }

          &.Mui-selected {
            & .MuiTab-wrapper {
              color: $GREEN;
            }
          }
        }
      }
    }
    & .MuiBox-root {
      padding: 0;
    }
  }
  &.learningCourseDetailTab {
    & .MuiBox-root {
      padding: 0;
    }
      & .MuiButtonBase-root.Mui-selected {
        & .MuiTab-wrapper {
          color: #20a258;
        }
    }
  }

  & .badge {
    .MuiBadge-anchorOriginBottomRightRectangle {
      bottom: 50% !important;
    }
    .MuiBadge-badge {
      border-radius: 2px;
      background-color: #ff8900;
      color: white;
      font-weight: 700;
    }
  }
}
.processCell {
  & .MuiButton-containedPrimary {
    background-color: $GREEN;
    & span {
      color: $WHITE;
    }
  }
  & .MuiButton-containedPrimary :hover {
    background-color: $GREEN;
    color: $WHITE;
  }
  & .greenContained .MuiButton-root .MuiButton-label span {
    font-family: $MONTSERRAT;
    font-size: 12px;
    font-weight: 400;
  }
}

.customTab .orderTab .cookingList h6 {
  color: #333;
}

.orderRest {
  .MuiSelect-select.MuiSelect-select {
    color: #000;
  }
}

.farmCollapse {
  & .cookingList {
    margin: 20px 0;
    & .cookingrow {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: $GRAY_SHADE11;
      padding: 8px 30px;
      border-radius: 15px;
      margin-bottom: 10px;
      font-family: $MONTSERRAT;

      & .blocks:nth-child(2) {
        flex-grow: 2;
        padding-left: 30px;
      }
      & .blocks:nth-child(3) {
        flex-grow: 3;
      }
      & .blocks:nth-child(4) {
        flex-grow: 8;
      }
    }
    & .orderImg {
      height: 40px;
      width: 40px;
      object-fit: cover;
      border-radius: 10px;
    }
    & h6 {
      font-size: 16px;
      font-weight: 600;
      color: #333;

      &.title {
        margin: 10px 0;
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }
    }
    & h5 {
      font-size: 22px;
      color: #333;
      font-weight: 600;
    }
    & .addOns {
      font-family: 'OpenSans', sans-serif;
      font-size: 15px;
      font-weight: 600;
      color: #000;
    }

    & .outlineTextfield {
      margin-bottom: 10px;
    }
    & .greenContained {
      & .MuiButton-root {
        background-color: orange;
        border-color: orange;
        padding: 10px 40px;
        & span {
          font-size: 17px;
        }
        &:hover {
          background-color: transparent;
          border-color: orange;
          & span {
            color: orange;
          }
        }
      }
      height: auto !important;
    }
  }
  & .accordian {
    width: 100%;
    & .scrollBlock {
      max-height: 120px;
      overflow: scroll;
      @include scrollbars();
    }
  }
}

.MuiTabs-scroller.MuiTabs-fixed {
  overflow-x: hidden !important;
}

.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include scrollbars();
}
