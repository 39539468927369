@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.ndd_user_details {
  display: flex;
  align-items: center;
  gap: 15px;

  div {
    border-radius: 50%;
    padding: 4px;
    border: 2px solid $GREEN;

    img {
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }
  }

  p {
    font-family: $POPPINS;
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }
}

.ndd_content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ndd_heading_container {
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: #f6faf7;
  padding: 12px 30px;
  border-radius: 24px;

  .ndd_topic_select {
    border: 2px dashed #35a042 !important;
    border-radius: 8px;
    color: #35a042;
    font-size: 14px;
    width: 120px;

    .MuiSelect-select.MuiSelect-select {
      padding: 6px 15px;

      &:focus {
        background-color: transparent;
      }
    }
  }

  .ndd_heading {
    padding: 0px 10px;
    font-family: $MONTSERRAT;
    color: #333;

    input::placeholder {
      color: #abafb3;
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    gap: 5px;
    padding: 10px 15px;

    .ndd_topic_select {
      .MuiSelect-select.MuiSelect-select {
        padding: 4px 8px;
      }
    }
  }
}

.ndd_description {
  border: none;
  border-radius: 14px;
  padding: 15px 30px;
  width: 100%;
  background-color: #f6faf7;
  resize: none;
  outline: none;
  color: #333;
  font-family: $MONTSERRAT;

  &::placeholder {
    color: #abafb3;
  }
}

.ndd_submit_btn_container {
  justify-content: flex-start !important;
  padding: 20px 24px 24px !important;

  button {
    border-radius: 25px;
    text-transform: none;
    padding: 8px 30px;
    background-image: linear-gradient(104deg, #2dae43 -27%, #147620 151%);

    span {
      color: $WHITE;
    }
  }

  .ndd_submit_disabled {
    background-image: none;
    background-color: #f7f7f7;

    span {
      color: #000;
    }
  }
}

// }

.ndd_topic_menu_loader {
  background-color: $WHITE !important;

  div {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  & svg {
    color: $GREEN !important;
  }
}

.sdd_dialog_title {
  padding: 40px 40px 10px !important;

  @media screen and (max-width: 480px) {
    padding: 25px 20px 10px !important;
  }
}

.sdd_dialog_content {
  padding: 0px 40px 40px !important;

  @media screen and (max-width: 480px) {
    padding: 0px 20px 20px !important;
  }
}

.sdd_search_field {
  padding: 8px 15px !important;
  border-radius: 12px;
  background-color: #f6faf7;

  & input {
    font-family: $MONTSERRAT;
    font-size: 16px;
    font-weight: 600;
    color: #818181;

    &::placeholder {
      color: #818181 !important;
    }
  }
}

.sdd_search_icon {
  color: #0fa64f;
}

.sdd_topic_heading {
  font-family: $POPPINS;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-bottom: 10px;
  z-index: 1;
}

.sdd_empty_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;

  & svg {
    color: $GREEN;
  }

  p {
    opacity: 0.5;
    font-family: $POPPINS;
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
}

.sdd_topics_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-top: 15px;
}