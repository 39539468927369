@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.mainHeader {
  width: 100%;
  background-color: $WHITE;
  border-radius: 20px;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    padding: 10px;
  }

  & .headerLogo {
    width: 135px;
    height: 45px;
    object-fit: contain;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      width: 100px;
    }
  }

  & .blocks {
    padding: 0 8px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
      padding: 0;
    }
  }

  & .block1 {
    & .userBlock {
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      & h5 {
        white-space: nowrap;
      }

      @media screen and (max-width: 1400px) {
        padding-left: 10px;

        & h5 {
          font-size: 18px;
        }

        & span {
          font-size: 14px !important;
        }
      }

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    & .form-group {
      width: 100%;
    }

    & .toggleIcon {
      margin-right: 10px;
      cursor: pointer;

      @media screen and (min-width: 1025px) {
        display: none;
      }
    }
  }

  & .block2 {
    width: 800px;

    @media screen and (max-width: 1670px) {
      width: 500px;
    }

    @media screen and (max-width: 1400px) {
      width: 260px;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      display: none;
    }
  }

  & .headerSelect {
    @media screen and (max-width: 1025px) {
      display: none;
    }
  }

  & .block3 {
    align-items: center;

    & .Icons {
      position: relative;
      margin: 0 12px;
      cursor: pointer;

      @media screen and (max-width: 1024px) {
        margin: 0 10px;
      }

      &.headerSearchIcon {
        @media screen and (min-width: 1024px) {
          display: none;
        }
      }

      & .notificationImg {
        height: 20px;
        width: 20px;
        object-fit: contain;
      }

      & span {
        content: " ";
        position: absolute;
        padding: 5px;
        height: 5px;
        width: 5px;
        top: 0;
        right: -1px;
        border-radius: 50%;
        background-color: $GREEN;

        @media screen and (max-width: 1024px) {
          right: 10px;
        }
      }
    }

    & .form-select {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  & .notificationWrapper {
    margin: 0px 12px;
  }
}

.connectSquareHeaderWrapper {
  display: block;

  @media screen and (max-width: 599px) {
    display: none;
  }
}

.connectSquareBlockWrapper {
  width: 100%;
  background-color: $WHITE;
  border-radius: 16px;
  padding: 10px;
  margin-top: 8px;
  display: none;

  @media screen and (max-width: 599px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.connectContainer {
  display: flex;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: 599px) {
    gap: 8px;
  }

  .connectButton {
    padding: 9.5px 12px;
    border-radius: 10px;
    background-color: $ORANGE;

    &:hover {
      background-color: $ORANGE;
    }

    span {
      color: $WHITE;
      font-size: 0.9rem;
      text-transform: none;
      font-family: $MONTSERRAT;
    }
  }

  @media screen and (max-width: 599px) {
    width: 100%;

    a {
      width: 100%;
    }

    .connectButton {
      width: 100%;
    }
  }
}

.headerContentSm {
  background-color: $WHITE;
  border-radius: 12px;
  padding: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
    margin: 0px 0 8px 0;
  }

  & .blocks {
    @media screen and (max-width: 1024px) {
      display: flex;
      padding: 0 5px;
      margin: 5px 0;
      width: 100%;
    }
  }

  & .block1 {
    & .userBlock {
      padding-left: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }

    & .form-group {
      width: 100%;
    }
  }

  & .block2 {
    width: 100%;
  }

  & .block3 {
    align-items: center;
  }
}