@import "../../../../web/src/assets/css/constants/base/colors";
@import "../../../../web/src/assets/css/constants/base/global";

.restaurantForumsEditContainer {
  width: 100%;

  background-color: $WHITE;
  border-radius: $BORDER_RADIUS;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $GRAY_SHADE3;
    padding: 40px 20px 30px 20px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    @media screen and (max-width: 560px) {
      padding: 20px 0;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .leftSideHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .MuiSvgIcon-root {
        font-size: 30px;
        color: #3a3a3a;
        @media screen and (max-width: 560px) {
          font-size: 20px;
        }
      }
      h4 {
        font-weight: 900;
        margin-left: 5px;
        @media screen and (max-width: 560px) {
          font-size: 16px;
          margin-left: -5px;
        }
      }
    }
    .rightSideHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;

      .campaign_delete_icon {
        width: 27px;
        height: 28px;
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
        margin-top: 20px;
        justify-content: space-between;
        margin-right: 20px;
      }
      @media screen and (max-width: 560px) {
        margin: 0 20px 0 10px;
        width: 75%;
        gap: 5px;
      }
      .green-outline {
        width: 150px;
        & .MuiSvgIcon-root {
          font-size: 25px;

          color: $GREEN;
        }
      }
      & .MuiSvgIcon-root {
        font-size: 40px;
        color: rgb(223, 37, 37);
      }

      .delIconSize{
        width: 27px;
        height : 28px;
        margin-top: 10px ;
        cursor: pointer;
        @media screen and (max-width: 560px) {
          width: 22px;
          height : 22px;
          margin-top: 0px;
        }
      }
    }
  }
  .forumMainWrapper {
    padding: 40px 20px 30px 20px;
    width: 100%;

    overflow: hidden;
    & .imageContainer {
      position: relative;
      .image {
        width: 100%;
        height: 250px;
        img {
          border-radius: $FORM_BORDER_RADIUS;
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
      .editform {
        margin-left: 1050px;
        margin-bottom: -60px;
        text-decoration: black;
      }
      .editforum {
        position: absolute;
        top: 20px;
        right: 10px;
      }
      .ReseditForum {
        background-color: white;
        text-decoration-color: black;
        color: black;
      }

      .editBannerBtn {
        position: absolute;
        top: 10px;
        right: 20px;
        background-color: $WHITE;
        border-radius: $FORM_BORDER_RADIUS;
        padding: 5px 20px;
        span {
          color: black;
        }
      }
    }
    & .forumResponseContainer {
      & .campaignSubtitle {
        white-space: nowrap;
        & span {
          padding-left: 10px;
          color: $GREEN;
        }
      }
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: space-between;
      }
      & .likesCommentsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & .likeComments {
          display: flex;
          align-items: center;
        }
        & .likeComments {
          .MuiSvgIcon-root {
            color: blue;
            font-size: 30px;
          }
          .count {
            color: black;
            margin: 0 5px;
            font-size: 20px;
            font-weight: 700;
          }
          & span {
            color: $GRAY_SHADE6;
          }

          &:nth-child(2) {
            margin-left: 30px;
            .MuiSvgIcon-root {
              color: orange;
            }
          }
        }
      }
      & .buttonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .MuiButton-label{
          text-transform: none;
        }
        @media screen and (max-width: 768px) {
          margin-top: 20px;
          justify-content: center;
          width: 100%;
        }
        .MuiButtonBase-root {
          background-color: $GREEN;
          border-radius: $FORM_BORDER_RADIUS;
          padding: 10px 30px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          span {
            color: $WHITE;
          }
        }
      }
    }
    & .forumHeaderDescription {
      width: 100%;
      margin: 20px 0;
      h4 {
        margin: 10px 0;
        font-size: 17px;
        color: $GRAY_SHADE6;
      }
      textarea {
        width: 100%;
        height: 100%;
        border-radius: $FORM_BORDER_RADIUS;
        padding: 10px;
        border-color: $GRAY_SHADE3;
        color: $GRAY_SHADE4;
      }
      .customTextArea {
        position: relative;

        .MuiTextarea-root {
          padding: 0;
        }

        textarea {
          width: 100%;
          height: 100%;
          border-radius: $FORM_BORDER_RADIUS;
          padding: 10px;
          border-color: $GRAY_SHADE3;
          color: $DARK;
        }

        .textCount {
          position: absolute;
          top: 50%;

          transform: translateY(-50%);
          right: 20px;
        }
      }
    }
    & .donationContainer {
      .donationHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          color: $GREEN;
        }
        hr {
          width: 100%;
          margin-left: 10px;
          height: 2px;
          background-color: $GREEN;
        }
      }

      & .donationInfo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        @media screen and (max-width: 560px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }
        h3 {
          font-size: 17px;
          color: $GRAY_SHADE6;
          font-weight: 700;
          text-align: left;
        }

        .requiredDontation {
          h3 {
            margin: 10px 0;
          }
          .outlineTextfield {
            min-width: 300px;
            @media screen and (max-width: 560px) {
              min-width: auto;
            }

            .customTextfield .MuiOutlinedInput-adornedStart{
              font-size: 1.3rem;
            }
          }


          
        }
        .donationRaised {
          h3 {
            margin: 15px 0;
          }
          h4 {
            padding: 8px 0;
          }
        }
        .donationRaisingpercentage {
          background-color: $GRAY_SHADE8;
          border-radius: 20px;

          @media screen and (max-width: 560px) {
            width: 100%;
          }
          .donationBlock {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & .customProgressbar {
              width: unset !important;
              height: unset !important;
              .circularprog {
                width: unset !important;
                height: unset !important;
                padding: unset !important;
                min-height: unset !important;
                background-color: unset;
                margin-bottom: 20px;
                @media screen and (max-width: 560px) {
                  margin-left: 20px;
                }
                h3 {
                  display: none;
                }
              }
            }
            h3 {
              margin: 0 10px;
              @media screen and (max-width: 560px) {
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
      & .farmsDonation{
        flex-wrap: wrap;
      }

      .donnersContainer {
        margin-top: 20px;
        width: 100%;
        h3 {
          color: $GRAY_SHADE6;
          font-size: 17px;
          font-weight: 600;
        }
        .donnersList {
          margin-top: 20px;
          width: 100%;

          & .donnerContainer {
            border: 1px solid $GRAY_SHADE1;
            border-radius: 7px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 30px;

            margin: 20px 0;

            .profile {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }

              p {
                color: $BLACK;
                margin-left: 20px;
                font-size: 16px;
                font-weight: 700;
                text-transform: capitalize;
              }
            }

            p {
              color: $GRAY_SHADE1;
              font-weight: 600;
            }

            h3 {
              color: $GREEN;
              font-weight: 600;
              font-size: 22px;
            }
          }
        }

        .empty_donners_container {
          border: 1px solid $GRAY_SHADE1;
          border-radius: 7px;
          height: 25vh;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;

          p {
            font-size: 20px;
            font-family: $MONTSERRAT;
            color: #3a3a3a;
          }
        }
      }
    }
    .commentContainer {
      width: 100%;
      h4 {
        font-size: 17px;
        color: $GRAY_SHADE6;
      }
      .replyComment {
        padding-left: 20px;
      }
      .newComment {
        position: relative;
        textarea {
          width: 100%;
          height: 100%;

          margin: 10px 0;
          color: $GRAY_SHADE4;
          border-color: $GRAY_SHADE4;
          padding: 20px;
          border-radius: $BORDER_RADIUS;
        }
        .commentbtn {
          position: absolute;
          right: 30px;
          bottom: 50px;
          .MuiButton-contained {
            background-color: orange;
            span {
              font-weight: 600;
              color: $WHITE;
            }
            .MuiSvgIcon-root {
              color: $WHITE;
              font-size: 20px;
              padding: 0;
              border: none;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.farmforumreply{
  display: flex;
    width: 170px;
    justify-content: space-between;
    
}

.newCommentreply {
   & textarea{
      height: 175px !important;
    }
}
.replyBtnContainer{
  position: absolute;
  right: 30px;
  bottom: 50px;
  .MuiButton-contained {
    background-color: orange;
    span {
      font-weight: 600;
      color: $WHITE;
    }
    .MuiSvgIcon-root {
      color: $WHITE;
      font-size: 20px;
      padding: 0;
      border: none;
      margin: 0;
    }
  }
}

.sendBtn {
  margin-left : 10px !important;
}

.cancelBtn {
  margin-right: 10px !important;
}