@import '../constants/base/';
@import '../constants/base/global.scss';

.MuiMenu-paper {
  & .sortDropDown {
    & .MuiListItem-root {
      text-transform: capitalize;
    }
  }
}
.customSelect {
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  & .MuiSelect-select {
    text-transform: capitalize;
    &.MuiSelect-select {
      font-weight: normal;
      font-family: $POPPINS;
      height: 20px;
      font-size: 18px;
      border-radius: 8px;
    }
  }
  & .MuiInputBase-root {
    &:hover {
      border: 0;
    }
  }
  & menuitem {
    font-weight: 600;
  }
  .MuiSelect-select {
    &:focus {
      background-color: transparent;
    }
  }
  & .MuiInput-underline {
    &::before {
      border: 0;
    }
    &::after {
      border: 0;
    }
    &:hover {
      &:not(.Mui-disabled) {
        &::before {
          border: 0;
        }
      }
    }
    &:focus {
      &:not(.Mui-disabled) {
        &::before {
          border: 0;
        }
      }
    }
  }
  & .MuiFormControl-root {
    width: 100%;
    background-color: transparent !important;
    border: 0px solid transparent !important;
  }
}
.outlineSelect {
  width: 100%;
  & .MuiSelect-select {
    padding: 12px 25px 12px 15px !important;
    border: 1px solid $GRAY_SHADE7;
    &.MuiSelect-select {
      font-weight: 500;
    }
    &:focus {
      border-radius: 10px;
      border: 1px solid $GRAY_SHADE7 !important;
    }
  }
}
.filledSelect {
  width: 100%;
  & .MuiInputBase-root {
    background-color: $GREEN;
    color: $WHITE;
    border-radius: $FORM_BORDER_RADIUS;
    padding: 5px 20px 5px 20px;
    height: 53px;
    position: relative;
    .MuiSvgIcon-root {
      display: none;
    }
    .MuiSelect-select {
      width: 100px;
      &::before {
        content: ' ';
        position: absolute;
        left: 20px;
        top: 20px;
        transform: translateY(-50%) rotate(-45deg);
        border: 0px;
        border-left: 3px solid white;
        border-bottom: 3px solid white;
        border-top: 0px solid transparent;
        height: 10px;
        width: 10px;
      }
      padding-right: 2px;
      padding-left: 15px;
      &.MuiSelect-select {
        font-weight: 600;
        margin-left: 5px;
        font-size: 15px;
      }
    }
  }
  & .MuiFormControl-root {
    border: 1px solid transparent !important;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &.leftIcon {
    & .MuiInputBase-root {
      padding: 5px 20px 5px 0px;
      .MuiSelect-select {
        &::before {
          content: ' ';
          left: unset;
          right: 20px;
          top: 20px;
        }
      }
    }
  }
}
.menu-customize {
  font-weight: normal;

  & .MuiSelect-select.MuiInputBase-input {
    text-transform: capitalize;
  }

  & .MuiPaper-rounded {
    border-radius: 10px;
  }
  & .MuiMenuItem-root:hover {
    background-color: $GREEN_SHADE3;
    color: $GREEN;
  }
}
.multilineSelect {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-radius: $FORM_BORDER_RADIUS;
  border: 1px solid $GRAY_SHADE2 !important;

  & .sorttext {
    font-size: 15px;
    width: 100%;
    padding: 10px 5px;
    white-space: nowrap;
  }
  .customSelect {
    width: 100%;
    margin: 0px 0;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    .MuiFormControl-root {
      width: 100% !important;
      outline: none;
      background-color: #ffffff !important;

      & .MuiSelect-select {
        &.MuiSelect-select {
          font-weight: 900;
          font-size: 18px;

          position: relative;
          &::after {
            content: '';
            height: 39px;
            width: 1px;
            background-color: $GRAY_SHADE2;
            position: absolute;
            top: 0px;
            right: 25px;
          }

          &.MuiInputBase-input {
            padding: 13px 40px 10px 3px !important;
            height: 100%;
          }
        }
        &:focus {
          border-radius: 10px;
        }
      }
    }
  }
}
.green-outline {
  width: 100%;
  & .MuiInputBase-root {
    background-color: $GREEN_SHADE5;
    border: 1px solid $GREEN_SHADE6 !important;
    border-radius: 5px;
    padding: 0px;
    width: 100%;
    .MuiSvgIcon-root {
      color: $GREEN_SHADE6;
    }
    & .MuiSelect-select {
      text-transform: capitalize;
      &.MuiSelect-select {
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize !important;
        color: $GREEN_SHADE6;
        margin-right: 0px;
      }
      &.MuiInputBase-input {
        padding: 10px 28px 10px 9px !important;
      }
    }
    &:hover {
      border-radius: 5px 5px 0 0;
    }
  }
  & .MuiFormControl-root {
    width: 100%;
  }
  & .dragdropUrl {
    & .MuiSelect-select {
      text-transform: none;
      &.MuiSelect-select {
        text-transform: none !important;
      }
    }
  }
}

.orange-outline {
  width: 100%;
  & .MuiInputBase-root {
    background-color: #fcf6ec;
    border: 1px solid #dc9b2d !important;
    border-radius: 5px;
    padding: 0px;
    width: 100%;
    .MuiSvgIcon-root {
      color: #dc9b2d;
    }
    & .MuiSelect-select {
      text-transform: capitalize;
      &.MuiSelect-select {
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize !important;
        color: #dc9b2d;
        margin-right: 0px;
      }
      &.MuiInputBase-input {
        padding: 10px 28px 10px 9px !important;
      }
    }
    &:hover {
      border-radius: 5px 5px 0 0;
    }
  }
  & .MuiFormControl-root {
    width: 100%;
  }
  & .dragdropUrl {
    & .MuiSelect-select {
      text-transform: none;
      &.MuiSelect-select {
        text-transform: none !important;
      }
    }
  }
}

.deactiveStatus {
  width: 100%;
  & .MuiInputBase-root {
    background-color: $DANGER5;
    border: 1px solid #fc4b4e !important;
    border-radius: 5px;
    padding: 0px;
    width: 100%;
    .MuiSvgIcon-root {
      color: #fc4b4e;
    }
    & .MuiSelect-select {
      text-transform: capitalize;
      &.MuiSelect-select {
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize !important;
        color: #fc4b4e;
        margin-right: 0px;
      }
      &.MuiInputBase-input {
        padding: 10px 28px 10px 9px !important;
      }
    }
    &:hover {
      border-radius: 5px 5px 0 0;
    }
  }
  & .MuiFormControl-root {
    width: 100%;
  }
  & .dragdropUrl {
    & .MuiSelect-select {
      text-transform: none;
      &.MuiSelect-select {
        text-transform: none !important;
      }
    }
  }
}

.adminRestDishesContainer,
.commonDropdown {
  .customSelect {
    &.dishCategorySelect {
      & .MuiInput-formControl {
        margin-top: 0 !important;
      }
      & .MuiInputBase-root {
        .MuiSvgIcon-root {
          display: block;
        }
        .MuiSelect-select {
          &::before {
            margin-right: 10px;
            text-transform: capitalize;
            font-family: $MONTSERRAT;
            font-size: 16px;
            letter-spacing: 0.1px;
            color: #92929d;
          }
          &.MuiSelect-select {
            font-weight: 500;
            margin-left: 5px;
            font-size: 16px;
            align-items: center;
            font-family: 'Roboto', sans-serif;
            letter-spacing: 0.1px;
            color: #44444f;
            @media screen and (max-width: 560px) {
              margin-left: 0;
            }
          }
        }
      }
      & .paper {
        background-color: red;
      }
    }
    &.restaurantDropDown {
      & .MuiInput-formControl {
        margin-top: 0 !important;
      }
      & .MuiInputBase-root {
        @media screen and (max-width: 560px) {
          padding: 0 !important;
        }
        .MuiSvgIcon-root {
          display: block;
        }
        .MuiSelect-select {
          &::before {
            content: 'Restaurant:';
            margin-right: 10px;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.3px;
            color: #929292;
          }
          &.MuiSelect-select {
            font-family: $MONTSERRAT;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.1px;
            color: #4b4b4b;
          }
          & span {
            color: #4f4f4f;
          }
        }
      }
    }
    &.commonOrdersDropDown {
      display: flex;
      justify-content: center;

      & .MuiInputBase-root {
        & svg {
          margin-right: 15px;
        }
        .MuiSelect-select {
          display: flex;
          justify-content: center;

          &.MuiSelect-select {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.1px;
            color: #44444f;

            &.Mui-disabled {
              color: rgba(0, 0, 0, 0.26);
            }
          }

          &.MuiInputBase-input {
            padding: 10px 0px !important;
          }
        }
      }
    }
    &.dishSortSelect {
      border-radius: 10px !important;

      & .MuiFormControl-root {
        padding: 0px !important;
      }

      & .MuiMenu-paper {
        border-radius: 10px !important;

        ul {
          padding: 0px !important;
        }
      }

      & .MuiInputBase-root {
        border-radius: 10px !important;
        border: 1px solid #e2e2ea !important;

        &.Mui-focused {
          border-radius: 15px 15px 0 0;
        }

        .MuiSvgIcon-root {
          display: block;
        }
        .MuiSelect-select {
          min-width: 150px !important;
          max-width: 150px !important;
          &::before {
            content: ' Sort by:';
            margin-right: 10px;
            font-family: $MONTSERRAT;
            font-size: 14px;
            font-weight: 500;
            color: #92929d;
          }

          &.MuiSelect-select {
            margin-left: 0;
            text-transform: capitalize;
            font-family: $MONTSERRAT;
            font-size: 14px;
            font-weight: 600;
            color: #44444f;

            &::after {
              content: '';
              height: 100%;
              width: 1px;
              background-color: #e2e2ea;
              position: absolute;
              top: 0px;
              right: 25px;
            }
            &.MuiInputBase-input {
              padding: 6px 28px 6px 10px !important;
              text-transform: capitalize !important;
            }
          }
        }
      }
    }
    &.dishListStatus {
      width: 100%;
      height: 100%;

      & .MuiFormControl-root {
        height: 100%;
      }
      & .MuiInputBase-root {
        border: 1px solid $GRAY_SHADE7;
        border-radius: 10px;
        height: 100%;

        & .MuiSelect-select {
          padding: unset;
          padding: 10px 5px 10px 20px;
          text-transform: capitalize;
          &.MuiSelect-select {
            font-weight: 500;
            text-align: left;
          }
          &:focus {
            border-radius: 10px;
            border: 0px solid $GRAY_SHADE7 !important;
          }
        }
      }
    }
    &.itemStatus {
      margin-top: 1px !important;
      @media screen and (max-width: 560px) {
        margin-top: 0px !important;
      }
      & .MuiSelect-select {
        background-color: #e7ffed;
        border: 1px solid #20a258;
        border-radius: 5px;
        color: #20a258;
        width: 100px;
        min-width: 85px !important;
        max-width: 150px !important;
        position: relative;
        margin-top: 12px;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 500;
        @media screen and (max-width: 560px) {
          margin-top: 0px;
        }
        &::after {
          content: '';
          position: absolute;
          right: 10px;
          top: 10px;
          border: 1px solid #20a258;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
      &.campaignSelect {
        .MuiFormControl-root {
          @media screen and (max-width: 560px) {
            width: 85%;
          }
        }
        .MuiSelect-select {
          padding-left: 10px;
          @media screen and (max-width: 560px) {
            padding-right: 0;
          }
        }
      }
      &.danger-variant {
        & .MuiSelect-select {
          background-color: $DANGER5;
          border: 1px solid $RED;
          color: $RED;
          &::after {
            border-color: $RED;
          }
        }
      }
      &.warning-variant {
        & .MuiSelect-select {
          background-color: $ORANGE_SHADE1;
          border: 1px solid $ORANGE;
          color: $ORANGE;
          &::after {
            border-color: $ORANGE;
          }
        }
      }
      & .draft {
        & .MuiSelect-select {
          color: #d98f08;
          border: 1px solid #d98f08;
          background-color: rgba(217, 143, 8, 0.08);
          &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 10px;
            border: 1px solid #d98f08;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
        }
      }
      & .deactive {
        & .MuiSelect-select {
          color: #fc4b4e;
          border: 1px solid #fc4b4e;
          background-color: rgba(252, 75, 78, 0.08);
          &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 10px;
            border: 1px solid #fc4b4e;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
        }
      }
      & .MuiFormControl-root {
        margin-bottom: 2px;
      }
      & .MuiSvgIcon-root {
        display: none;
      }
      & .MuiInputBase-root {
        display: flex;
        justify-content: center;
      }
    }
    &.farmOrdersDropDown {
      & span {
        color: #4b4b4b;
      }
      &.prodFarmSelect {
        .MuiSelect-select {
          &::before {
            content: ' Farm:' !important;
          }
        }
      }
      .MuiSelect-select {
        color: $GRAY_SHADE16 !important;
        &::before {
          content: ' Show:' !important;
          color: $GRAY_SHADE15;
        }
        .MuiSelect-selectMenu {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    &.farmSortDropDown {
      .MuiSelect-select {
        padding: 10px 25px 10px 15px !important;
        font-size: 14px !important;
        text-transform: capitalize;
        &::before {
          content: ' Sort By:' !important;
        }
      }
    }
  }
  & .reportsButton {
    margin-left: 20px;
  }
  &.contentHeaderDropdown {
    .MuiSelect-select {
      &::before {
        display: none;
      }
    }
  }
}

.css-1d9cypr-MuiStack-root {
  .MuiSelect-select {
    color: $GRAY_SHADE16 !important;
    &::before {
      content: 'Restaurant: ' !important;
      color: $GRAY_SHADE15;
    }
  }
  @media screen and (max-width: 425px) {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin: 10px 0;
  }
}

.statusBox {
  width: 100% !important;
  max-width: 100% !important;
  padding: 10px;
}
