@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
.educationFilterWapper {
  // height: 100%;
  background-color: $WHITE;
  width: 100%;
  padding-top: 30px;
  & .commonSection {
    margin-bottom: 50px;
  }
  & .subContentWrapper {
    max-width: 1450px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    @media screen and (max-width: 1440px) {
      max-width: 1200px;
    }
  }
  & .filterCoursesWrapper {
    @media screen and (min-width: 1025px) {
      padding-left: 30px;
    }
    & .filterCoursesHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      & h4 {
        margin-right: 10px;
        text-align: left;
        width: 100%;
      }
      & .courselist {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid $GRAY_SHADE1;
        border-radius: 30px;
        padding: 5px 25px;

        & span {
          margin-right: 15px;
          white-space: nowrap;
        }
        & .MuiSelect-select {
          padding-right: 0;
        }
        & .MuiSvgIcon-root {
          display: none;
        }
      }
    }
    & .filterBtnRow {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }

    & .paginationRow {
      margin-top: 40px;

      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
  & .farmBannerSection {
    background: url("../images/farm-banner.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    height: 550px;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-left: 100px;
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
    // @media screen and (max-width: 1440px) {
    //   max-width: 1200px;
    // }
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: -1;
    }
    & h1 {
      font-weight: 900;
      font-family: $MONTSERRAT;
    }
    & h5 {
      margin: 30px 0;
    }
  }
  & .learnSection {
    & h2 {
      font-family: $MONTSERRAT;
      text-align: center;
      margin-bottom: 50px;
    }
  }
  & .reviewSection {
    flex-direction: row;

    & .reviewHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;

      & a {
        font-weight: 500;
        font-size: 19px;
        color: $GREEN;
        text-decoration: underline;
      }
    }
    & .commentBlock {
      & h6 {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
      }
      & .circleBtn {
        & .MuiButton-root {
          padding: 10px 60px;
        }
      }
    }
  }
  & .filterRightBlock {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      width: 100%;
    }
  }
  & .greenOutlineGrayButton {
    display: none;
    justify-content: space-between;
    margin-left: 10px;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}
