@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";
// @import { BannerImg } from "../assets";
// @import {BannerImg} from "../../";

.educationWapper {
  // height: 100%;
  background-color: $WHITE;
  width: 100%;
  & .commonSection {
    margin-bottom: 100px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
  & .bannerSection {
    background-image: url("../images/banner1.png");
    background-size: cover;
    background-position: center center;
    height: 600px;
    width: 100%;
    position: relative;
    z-index: 1;
    &::after {
      content: " ";
      height: 100%;
      width: 100%;
      background-color: rgba(76, 175, 80, 0.8);
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    & .banner_content {
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      position: relative;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 40px 0;
      }
      & .bannerBlocks {
        width: 50%;
        margin: 0 10px;
        @media screen and (max-width: 767px) {
          width: 90%;
          margin: 0 10px;
        }
        &:nth-child(2) {
          margin-right: 0;
          @media screen and (max-width: 767px) {
            margin: 0;
          }
        }
        & h1 {
          font-family: $ROUNDGOTHIC_MEDIUM;
          font-size: 40px;
          letter-spacing: 4px;
        }
      }
      & .contentBlock {
        padding-left: 150px;
        
        @media screen and (max-width: 767px) {
          padding-left: 0;
        }
        @media screen and (max-width: 1440px) {
          padding-left: 10px;
        }
        & h1,
        p {
          color: $WHITE;
        }
        & h1 {
          font-family: $ROUNDGOTHIC_MEDIUM;
        }
        & p {
          color: $WHITE;
          font-weight: normal;
          margin: 40px 0;
        }
      }
      & .searchbox {
        position: relative;
        width: 80%;

        & .plus_icon {
          display: none;
        }
        & .MuiFormControl-root {
          padding: 3px 30px;
          border-radius: 30px;
          @media screen and (max-width: 767px) {
            padding: 3px 10px;
          }
          & input {
            color: $DARK_SHADE2 !important;
            font-size: 16px;
            font-weight: 600;

            &::placeholder {
              color: $DARK_SHADE2 !important;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
        & .MuiInputBase-root {
          & input {
            color: $DARK_SHADE2;
            font-size: 18px;
            font-weight: 500;
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
            &::placeholder {
              color: $DARK_SHADE2;
              font-size: 18px;
              font-weight: 500;
              opacity: 1;
              @media screen and (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
        }
        & .MuiButton-root {
          position: absolute;
          top: 0;
          right: -1px;
          padding: 15px 40px;
          height: 55px;
          border-radius: 30px;
          background-color: $DANGER1;
          @media screen and (max-width: 767px) {
            padding: 17px 30px;
          }
          & .MuiButton-label {
            & span {
              padding-left: 0;

              font-size: 18px;
              color: $WHITE;
            }
          }
        }
      }
      & .videoBlock {
        display: flex;
        align-items: center;
        position: relative;
        & iframe {
          width: 100%;
          height: 500px;
          border-radius: 20px 0 0 20px;
          position: absolute;
          @media screen and (max-width: 767px) {
            height: 200px;
            position: unset;
          }
          @media screen and (max-width: 768px) {
            border-radius: 20px;
          }
          @media screen and (max-width: 1440px) {
            border-radius: 15px 0 0 15px;
          }
        }
      }
    }
  }
  & .subContentWrapper {
    max-width: 1600px;
    margin: 0 auto;
    padding: 10px;
    & h2 {
      text-align: center;
      margin-bottom: 0px;
    }
    & h6 {
      text-align: center;
      margin-bottom: 10px;
      color: $GREEN;
    }
    @media screen and (max-width: 1440px) {
      max-width: 1200px;
    }
    // @media screen and (max-width: 768px) {
    //   max-width: 900px;
    // }
    // @media screen and (max-width: 767px) {
    //   max-width: 100%;
    // }
  }
  & .choseusSection {
    & .cardmb {
      margin-bottom: 30px;
    }
    & p {
      padding: 50px 50px 50px 0;
      letter-spacing: 2px;
      color: $GREEN_BLACK;
    }
    & h2 {
      font-size: 35px;
    }
    & h4 {
      color: $DARK;
      font-family: $POPPINS;
      font-size: 22px;
      font-weight: 800;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
    & .cardFooter {
      display: flex;
      justify-content: flex-end;
      position: relative;
      & .imgOverlay {
        z-index: 1;
        &::after {
          content: " ";
          background-color: #d1e8d8;
          padding: 0 10px 10px 10px;
          position: absolute;
          right: 0;
          z-index: 1;
          height: 100%;
          bottom: -38px;
          width: 152px;
          z-index: -1;
          border-top-left-radius: 50%;
        }
      }

      & img {
        width: 180px;
        height: 120px;
        object-fit: contain;
        z-index: 3;
      }
    }
  }
  & .courseSection {
    & h6 {
      font-size: 20px;
    }
    & h2 {
      padding-bottom: 40px;
      font-size: 35px;
    }

    & .courseImg {
      width: 100%;
      border-radius: 20px;
      max-width: 550px;
    }
    // & .imgBg {
    //   // background-color: $GREEN;
    //   // border-radius: 50%;
    //   // height: 100px;
    //   // width: 100px;
    // }
    & .joinCourseCard {
      background-color: transparent;
      max-width: 500px;
      box-shadow: none !important;
      & img {
        height: 60px;
        width: 60px;
        object-fit: contain;
      }
      &.active {
        box-shadow: 0px 16px 20px 0px #cccccc94 !important;
      }
      & .cardContent {
        display: flex;
      }
      & .cardSubContent {
        padding-left: 20px;
        & h5 {
          font-weight: 600;
        }
        & p {
          font-weight: 600;
          color: $GRAY_SHADE3;
          padding-top: 10px;
          word-spacing: 2px;
          line-height: 25px;
        }
      }
    }
    & .courseCard {
      border-radius: 15px;

      & .courseImg {
        border-radius: 15px;
      }
      & .cardContent {
        .MuiTypography-h5 {
          font-family: $ROUNDGOTHIC_MEDIUM;
        }
      }
      & .MuiButton-label {
        & span {
          text-transform: capitalize;
          font-size: 20px;
          margin-right: 10px;
          color: $DARK;
          font-family: $ROUNDGOTHIC_MEDIUM;
        }
      }
      & .MuiButtonBase-root {
        &:hover {
          background-color: transparent;
        }
      }
    }
    .circleBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }
  }
  .aboutCourses {
    .leftContainer {
      // padding: 30px;
      h4 {
        font-size: 40px;
        font-weight: 300;
        line-height: 1.4;
        font-family: $ROUNDGOTHIC_MEDIUM;
      }
      .content {
        color: $GRAY_SHADE4;
        line-height: 2;
        margin-top: 20px;
      }
      .courseCountails {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        margin-top: 50px;
        .block {
          h3 {
            color: $GREEN;
            font-size: 25px;
          }
          p {
            font-size: 10px;
            color: $GRAY_SHADE4;
            font-family: $ROUNDGOTHIC_MEDIUM;
          }
        }
      }
    }

    .introVideo {
      position: relative;
      width: 100%;

      height: 100%;
      padding: 0px;
      z-index: 4;

      .videoBlock {
        width: 100%;
        height: 100%;
        z-index: 4;

        &.bannerBlocks {
          width: 100%;
          height: 100%;
          z-index: 4;
        }
        iframe {
          width: 80%;
          height: 100%;
          border-radius: 20px;
          z-index: 4;
        }
      }
      .dottedSideBar {
        position: absolute;
        top: 100px;
        right: 0px;
        z-index: 1;
        width: 100px;
        // display: flex;
        // align-items: flex-end;
        // justify-content: center;
        img {
          // position: absolute;
          // bottom: 0;
        }

        @media screen and (max-width: 1024px) {
          width: 80px;
          top: 180px;
        }
        @media screen and (max-width: 834px) {
          width: 70px;
          top: 180px;
        }
        @media screen and (max-width: 767px) {
          width: 60px;
          top: 240px;
        }
        @media screen and (max-width: 599px) {
          top: 40px;
        }
      }
    }
  }
  .educatorsContainer {
    margin-top: 150px;
    h1 {
      font-family: unset;
      width: 100%;
      text-align: center;
      margin-bottom: 100px;
    }
    .swiperContainer {
      .swiperAarrowRow {
        display: flex;
        justify-content: space-between;

        max-width: 350px;
        // text-align: center;
        margin: 100px auto 0 auto;
        // padding: 20px;
        @media screen and (max-width: 767px) {
          max-width: 550px;
        }
        position: relative;
        .prevNavigation,
        .nextNavigation {
          color: $ORANGE;
          cursor: pointer;
          z-index: 10;
          .MuiSvgIcon-root {
            margin-top: -20px;
          }
        }
      }

      .swiper-pagination {
        margin-top: 0px;

        .swiper-pagination-bullet {
          width: 40px;
          height: 7px;
          border-radius: 10px;
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          color: #000;
          opacity: 1;
          background: rgba(0, 0, 0, 0.2);
          @media screen and (max-width: 767px) {
            width: 30px;
          }
        }

        .swiper-pagination-bullet-active {
          color: #fff;
          background: #007aff;
        }
      }
    }
  }
}
