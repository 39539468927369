@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.done_container {
  position: absolute;
  width: 100%;
  height: 110%;
  padding: 0 25px 4px 25px !important;
  background-color: #fff;
  text-align: center;
}
.done_container_wrapper {
  padding: 50px 30px;
  background: #fff;
  border-radius: 14px;
}

.Farmars-Profile-cre {
  font-family: $POPPINS !important;
  font-size: 24px !important;
  font-weight: bold !important;
  margin: 14px 0 0 0 !important;
  color: #000;
}

.Now-you-can-go-to-da {
  font-family: $POPPINS !important ;
  font-size: 22px;
  color: #000;
  margin-bottom: 10px;
}

.Add-another-Role {
  border-radius: 9px !important;
  padding: 12px 25px !important;
  background-color: #20a258 !important;
  border: solid 1px #20a258 !important;
  width: 100% !important;
  text-transform: none !important;

  span {
    color: #fff;
    font-family: $POPPINS;
    font-size: 16px;
    font-weight: 600;
  }
}

.Go-To-Dashboard {
  border-radius: 9px !important;
  padding: 12px 25px !important;
  border: solid 1px #20a258 !important;
  background-color: rgba(32, 162, 88, 0.04) !important;
  width: 100% !important;
  text-transform: none !important;
  
  span {
    color: #20a258;
    font-family: $POPPINS;
    font-size: 16px;
    font-weight: 600;
  }
}
