@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.create_role_main_wrapper {
  position: relative !important;
}

.address_button_container {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    padding: 0 !important;
  }
}

.create_profile_container {
  width: 80vw !important;
  padding: 30px;

  @media screen and (max-width: 1024px) {
    width: 100% !important;
    padding: 10px;
  }

  & .headerLearning {
    margin: 0 -30px;
    padding: 20px 30px;
  }

  & .MuiTabs-root {
    border-bottom: 2px solid #bbbbbb;
    margin: 0 -30px;

    @media screen and (max-width: 767px) {
      margin: 0;
    }

    & .MuiButtonBase-root {
      opacity: 1;
      margin: 0;
      padding: 0;

      & .MuiTab-wrapper {
        color: #20a258;
        margin-left: -30px;

        @media screen and (max-width: 767px) {
          margin-left: 0;
        }
      }

      &::before {
        content: " ";
        position: absolute;
        height: 3px;
        width: 200px;
        background-color: #20a258;
        top: 45px;
        z-index: 9;
      }
    }
  }
}

.create_role_profile_form_container {
  box-shadow: 0px 0px 12px 0px $GRAY_SHADE1;
  padding: 40px 30px;
  border-radius: 20px;
  margin: 40px 0px 100px;

  h3 {
    text-align: center;
    font-weight: 600;
    font-family: $MONTSERRAT;
    font-size: 28px;
    @media screen and (max-width: 560px) {
      font-size: 22px;
    }
  }

  h3,
  h4 {
    color: $Black;
    text-transform: capitalize;
  }
}

.create_profile_location_message {
  width: 100%;
  text-align: center;
}

.gps_location {
  color: $GREEN;
}

.create_role_profile_otp_input_container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.create_role_profile_input_root {
  width: 100%;
  padding: 0px !important;
  border-radius: 5px;
  background: none !important;
  border: 1px solid $GRAY_SHADE1;
  font-family: $POPPINS !important;
  font-size: 14px !important;
}

.create_role_profile_input {
  padding: 16px !important;
  border-radius: 15px;
}

.create_role_profile_input::placeholder {
  color: #394053 !important;
  font-family: $POPPINS !important;
  font-size: 14px !important;
}

.create_profile_label {
  padding: 5px 0px !important;
  font-size: 16px !important;
  font-family: $POPPINS !important;
  font-weight: 600 !important;
  color: #464a53 !important;
}

.create_profile_label_text {
  padding: 5px 0px;
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: $POPPINS !important;
  color: #464a53 !important;
}

.create_role_profile_desc {
  font-family: $POPPINS !important;
  font-size: 16px !important;
  color: #000 !important;
}

.create_profile_owner_heading {
  font-family: $POPPINS !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  letter-spacing: 0.1px !important;
  color: #171725 !important;
}

.create_role_profile_btn {
  background: $GREEN !important;
  padding: 11px 12px !important;
  width: 100%;
  text-transform: capitalize !important;

  span {
    color: $WHITE !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: $POPPINS;
  }
}

.create_role_profile_email {
  margin-top: 17px !important;
}

.create_role_otp_container {
  padding-top: 0 !important;
}

.create_role_btn_farm {
  margin-top: 17px !important;
}

.create_role_profile_btn_disabled {
  background: #58bc81 !important;
  padding: 11px 12px !important;
  width: 100%;

  span {
    color: $WHITE !important;
    font-size: 16px !important;
    font-family: $POPPINS;
  }
}

.OTP_header {
  font-weight: bold;
  font-size: 16px;
  font-family: $POPPINS !important;
  padding-bottom: 5px;
  color: #464a53;
}

.OTP_header1 {
  padding-bottom: 5px;
  font-family: $POPPINS !important;
  font-size: 16px;
  color: #464a53;
}

.create_profile_radio {
  font-family: $POPPINS !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #464a53 !important;
}

.OTP_Error {
  color: red;
  padding-left: 100px;
  padding-top: 25px;
}

.otp_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: $GREEN;
  }
}

.OTP_Success_Msg {
  color: $GREEN !important;
  font-weight: bold !important;
  font-size: 18px !important;
  padding-top: 45px !important;
}

.OTP_Success_Msg1 {
  color: $GREEN !important;
  font-weight: bold;
  font-size: 18px;
  padding-top: 35px;
}

.gps_input_field {
  width: 100%;
}

.create_role_profile_detect_location_input_wrapper {
  border: 1px solid $GRAY_SHADE1;
  border-radius: 5px;
  padding: 0px;
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    border-left: 1px solid $GRAY_SHADE1;
    padding: 0px 15px 0px 10px;

    p {
      padding-left: 10px;
      color: $Black;
      font-weight: 600;
    }
  }
}

.create_role_profile_detect_location_input_root {
  width: 100%;
  padding: 0px !important;
  border-radius: 5px;
  background: none !important;
  border: none !important;
}

.create_role_profile_mobile_number_input_wrapper {
  border: 1px solid #c1c1c15e;
  border-radius: 5px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 10px 10px 0px;

  div:nth-child(1) {
    display: flex;
    align-items: center !important;
    border-right: 1px solid #c3c3c3;
    padding: 0px 15px 0px 10px !important;

    p {
      padding: 0px 10px !important;
      color: $Black;
      font-weight: 600;
      font-size: 18px;
    }
  }

  & input {
    padding: 15px 16px !important;
  }

  div:nth-child(2) {
    width: 100%;
  }
}

.custom_dialog_paper {
  border-radius: 20px !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.custom_dialog_paper::-webkit-scrollbar {
  display: none !important;
}

.custom_roundish_dialog_paper {
  border-radius: 35px !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.custom_roundish_dialog_paper::-webkit-scrollbar {
  display: none !important;
}

.create_profile_nav_btn {
  background-color: #263c78 !important;
  padding: 15px 0px !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  min-width: 50px !important;
  z-index: 1 !important;

  @media screen and (max-width: 550px) {
    min-width: 40px !important;
    padding: 10px 0px !important;
  }

  @media screen and (max-width: 340px) {
    min-width: 35px !important;
    padding: 8px 0px !important;
  }

  span {
    color: #fff !important;
    font-size: 16px !important;
    max-width: 20px !important;
  }
}

.select_role_move_disabled {
  background-color: $GRAY_SHADE1 !important;
}

.create_profile_back {
  left: 2vw !important;

  @media screen and (max-width: 550px) {
    left: 1.2vw !important;
  }
}

.create_profile_right {
  right: 2vw !important;

  @media screen and (max-width: 550px) {
    right: 1.2vw !important;
  }
}

.mediaUploaderLearning {
  & .no-profile {
    border-radius: 50%;
    object-fit: contain;
    height: 140px;
    width: 140px;
  }
}

.educationalCartUpload {
  width: 100%;
  margin-bottom: 25px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  background-color: #fff;
  font-size: 1em;
  font-weight: bold;
  color: $DARK;
  display: flex;
  align-items: center;

  & .uploadTitle {
    width: 100%;
    font-family: $POPPINS;
    padding: 16px 54px 15px 21px;
  }

  & .inputfile {
    & + label {
      font-weight: bold;
      color: $DARK;
      display: block;
      border-left: solid 1px #abafb3;
      padding: 16px 20px 15px 20px;
      font-family: $POPPINS;
    }
  }
}

.farm_otp_input {
  & .MuiFilledInput-input {
    padding: 16px 12px !important;
  }
}
