@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";
@import "../../../../../web/src/assets/css/constants/base/mixins.scss";

.guest_landing_banner {
  background: url("../images/PlantHand.png") no-repeat;
  background-size: 100% 100%;
  padding: 20px 0px;
}

.guest_landing_slider_indicator {
  svg {
    width: 18px;
    height: 18px;
    color: #ffffff;
  }
}

.guest_landing_active_indicator {
  svg {
    fill: #7dc642;
  }
}

.center_box {
  background-color: rgba(244, 237, 226, 0.7);
}

.landing_info_first {
  margin-top: 50px;
  padding: 80px 0px;
  position: relative;
}

.guest_landing_flower {
  position: absolute;
  height: 538px;
  width: 111px;
  right: 0px;
  top: 0px;
}

.landing_question_heading {
  font-family: $ROUNDGOTHIC_BOOK !important;
  font-size: 38px !important;
  color: #333333 !important;
  margin-bottom: 20px !important;
}

.landing_info_boxes {
  padding: 50px 30px;
  border-radius: 10px;
  background-color: rgba(42, 166, 95, 0.12);
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  h5 {
    font-weight: bold;
    font-family: $MONTSERRAT;
    font-size: 24px;
    color: #000000;
  }

  p {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.72;
    color: #333232;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 5px;
    word-break: break-word;
    @include scrollbars();

    &::-webkit-scrollbar-thumb {
      background-color: $GREEN;
    }
  }

  .landing_info_boxes_image {
    text-align: center;

    img {
      height: 95px;
      width: 95px;
    }
  }
}

.landing_info_boxes_center {
  background-color: $GREEN_SHADE10;
  position: relative;
  overflow: hidden;

  h5 {
    color: #ffffff;
  }

  p {
    color: #ffffff;

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
  }

  .landing_box_bubble {
    background-color: rgba(255, 255, 255, 0.23);
    top: 5px;
    right: -14px;
    border-radius: 50%;
    position: absolute;
    height: 320px;
    width: 320px;
    transform: translate(40%, -40%);
  }
}

.landing_info_second {
  margin: 180px 0 100px 0;
}

.landing_info_second_box {
  background-color: #e5f4ec;
  width: 95%;
  border-bottom-right-radius: 130px;
  border-top-right-radius: 25px;
}

.landing_info_images_container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: -50px;
}

.landing_info_sunset {
  height: 448px;
}

.landing_info_grasscut {
  height: 96px;
  display: block;
  margin-bottom: 30px;
}

.landing_info_faces {
  height: 416px;
}

.landing_info_about {
  display: flex;
  flex-direction: column;
  margin: 70px 0px 30px 50px;
  padding: 30px;
  cursor: pointer;

  &:hover {
    border-radius: 20px;
    box-shadow: 0 2px 27px 6px rgba(0, 0, 0, 0.07);
    transition: all 0.5s;

    .about_us_arrow {
      opacity: 1;
    }
  }

  .about_us_arrow {
    color: #333;
    font-size: 30px;
    rotate: 180deg;
    opacity: 0;
    transition: all 0.5s;
  }

  h6 {
    font-family: $MONTSERRAT;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    color: $GREEN_SHADE10;
  }
}

.about_subheading {
  p {
    font-family: $ROUNDGOTHIC_BOOK;
    font-size: 38px;
    line-height: 1.68;
    color: #333333;
    margin-top: 18px;
    word-break: break-word;
  }
}

.about_description {
  p {
    margin-top: 18px;
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.72;
    color: #333232;
    word-break: break-word;
  }
}

.landing_info_third {
  display: flex;
  gap: 50px;

  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
}

.farming_left_portion {
  padding: 0px 90px 0px 130px;

  // Farming heading
  p:nth-child(1) {
    font-family: $MONTSERRAT;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: normal;
    color: $GREEN_SHADE10;
  }

  // Farming subheading
  p:nth-child(2) {
    font-family: $ROUNDGOTHIC_BOOK;
    font-size: 38px;
    line-height: 1.5;
    color: #333333;
    width: 85%;
    word-break: break-word;
  }

  // Farming description
  p:nth-child(3) {
    font-family: $MONTSERRAT;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.72;
    color: #333232;
    word-break: break-word;
  }
}

.farming_carousel {
  width: 40vw;

  @media screen and (max-width: 959px) {
    width: 100%;
    padding: 0px 60px;
  }

  .farm-slick {
    .slick-slide {
      padding: 0px 10px;
    }
  }
}

.view_farm_button {
  padding: 4px 23px !important;
  border-radius: 25px !important;
  border: 1px solid $GREEN_SHADE10 !important;
  font-family: $MONTSERRAT !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  margin-top: 40px !important;

  span {
    color: #000000 !important;
  }
}

.farming_images_container {
  display: flex;
  justify-content: flex-end;
}

.farming_images_arrows {
  display: flex;
  justify-content: flex-end;
  margin-top: 72px;

  img {
    cursor: pointer;
  }

  img:last-child {
    margin-right: 40px;
    margin-left: 20px;
  }
}

.farming_images {
  height: 415px !important;
  padding: 0px !important;
  border-radius: 15px !important;
  object-fit: cover;

  @media (min-width: 475px) and (max-width: 599px) {
    height: 325px !important;
  }

  @media (min-width: 380px) and (max-width: 474px) {
    height: 250px !important;
  }
}

.farming_images:not(:last-child) {
  margin-right: 10px;
}

.landing_info_fourth {
  margin-top: 80px;
  background-color: #e5f4ec;
  padding: 30px 0;
}

.landing_info_offer_heading {
  margin-bottom: 50px;

  div:nth-last-child(2) p {
    font-family: $MONTSERRAT;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    color: $GREEN_SHADE10;
  }

  div:last-child p {
    font-family: $ROUNDGOTHIC_BOOK;
    font-size: 38px;
    color: #333333;
  }
}

.landing_info_offer_boxes {
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 30px;
  box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.08);

  p:nth-last-child(2) {
    font-family: $MONTSERRAT;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    word-break: break-word;
  }

  p:last-child {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.72;
    color: #333232;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 5px;
    word-break: break-word;
    @include scrollbars();
  }
}

.landing_info_fifth {
  padding: 80px 0px;
  position: relative;
}

.guest_landing_old_home {
  position: absolute;
  height: 181px;
  width: 192px;
  left: 0px;
  bottom: 0;
}

.landing_info_testimonial_boxes {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 40px 45px;
  box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.08);
  border-radius: 25px;
  gap: 30px;
  border-top-left-radius: 100px;
  position: relative;
  z-index: 9;

  p {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.72;
    color: #333232;
    max-height: 150px;
    overflow-y: auto;
    padding-right: 5px;
    word-break: break-word;
    @include scrollbars();
  }
}

.testimonial_quote {
  position: absolute;
  bottom: 35px;
  right: 43px;
  height: 39px;
  width: 55px;
  z-index: -1;

  @media (min-width: 600px) and (max-width: 959px) {
    bottom: 20px;
    right: 28px;
    height: 28px;
    width: 42px;
  }

  @media (min-width: 960px) and (max-width: 1279px) {
    bottom: 30px;
    right: 35px;
    height: 30px;
    width: 45px;
  }
}

.testimonial_user_box {
  display: flex;
  align-items: center;

  div:nth-last-child(2) {
    height: 64px;
    width: 64px;
    box-shadow: 0 16px 24px 0 rgba(0, 24, 52, 0.08);
  }

  div:last-child {
    margin-left: 20px;

    h6:nth-last-child(2) {
      font-family: $MONTSERRAT;
      font-size: 16px;
      font-weight: 600;
      line-height: 2;
      color: #333333;
    }

    h6:last-child {
      font-family: $MONTSERRAT;
      font-size: 16px;
      line-height: 2;
      color: #333333;
    }
  }
}

@media (min-width: 280px) and (max-width: 474px) {
  .landing_question_heading {
    font-size: 25px !important;
  }

  .about_subheading p {
    font-size: 30px;
    line-height: 1.24;
  }

  .farming_left_portion {
    padding: 0px 40px;

    // Farming subheading
    p:nth-child(2) {
      font-size: 28px;
      line-height: 1.2;
      width: 100%;
    }
  }

  .landing_info_offer_heading {
    margin-bottom: 15px;

    div:last-child p {
      font-size: 28px;
    }
  }

  .landing_info_second {
    margin: 120px 0 80px;
  }

  .landing_info_about {
    margin: 0px;
  }

  .farming_carousel {
    padding: 0px 40px;
  }

  .landing_info_fourth {
    margin-top: 30px;
    padding: 30px 0;
    background-color: #e5f4ec;
  }

  .landing_info_fifth {
    padding: 30px 0;
  }

  .landing_info_sunset {
    height: 240px;
  }

  .landing_info_grasscut {
    height: 50px;
  }

  .landing_info_faces {
    height: 230px;
  }
}

@media (min-width: 475px) and (max-width: 599px) {
  .landing_question_heading {
    font-size: 28px !important;
  }

  .about_subheading p {
    font-size: 36px;
    line-height: 1.32;
  }

  .farming_left_portion {
    padding: 0px 40px;

    // Farming subheading
    p:nth-child(2) {
      font-size: 36px;
      line-height: 1.3;
      width: 100%;
    }
  }

  .landing_info_offer_heading {
    margin-bottom: 20px;

    div:last-child p {
      font-size: 36px;
    }
  }

  .landing_info_second {
    margin: 130px 0 90px;
  }

  .landing_info_about {
    margin: 0px;
  }

  .farming_carousel {
    padding: 0px 40px;
  }

  .landing_info_fourth {
    margin-top: 30px;
    padding: 30px 0;
    background-color: #e5f4ec;
  }

  .landing_info_fifth {
    padding: 30px 0;
  }

  .landing_info_sunset {
    height: 325px;
  }

  .landing_info_grasscut {
    height: 70px;
  }

  .landing_info_faces {
    height: 310px;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .landing_info_sunset {
    height: 380px;
  }

  .landing_info_grasscut {
    height: 80px;
  }

  .landing_info_faces {
    height: 352px;
  }
}