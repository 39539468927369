@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.view_addresses_loader {
  display: flex;
  width: 100%;
  min-height: 400px;
  justify-content: center;
  align-items: center;

  & svg {
    color: $GREEN;
  }
}

.product_grey_text {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
}

.add_address {
  background-color: $GREEN !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  padding: 8px 20px !important;
}

.address_action_container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.addresses_blank_container {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $GRAY_SHADE2;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;

  p {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-align: center;
  }
}

.add_address_form_wrapper {
  padding: 5px 60px 35px;
}

.add_address_modal_container {
  .modal_box {
    width: 100%;
    height: 100%;
  }
}
