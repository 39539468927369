@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.create-dish-container {
  background-color: #e3e9f6;

  .create-blk {
    border-radius: 14px;
    background-color: #fff;
  }
  .create_dish {
    .credit_dish_blk {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #979797;

      button {
        height: 40px;
        width: 40px;
      }

      .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
        color: #000;
      }
      .credit_dish_txt {
        padding-left: 10px;
        font-family: $POPPINS;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.1px;
        color: #171725;
      }
    }
    .dish_inputs {
      padding: 20px;
      .outlineTextfield {
        .MuiSvgIcon-root {
          display: none;
        }
      }
      .dish_label {
        padding-bottom: 4px;
        margin-top: 10px;
        font-family: $POPPINS;
        font-size: 18px;
        font-weight: 600;
        color: #464a53;
      }
      .description {
        border-radius: 4px;
        width: 100%;
        margin-top: 6px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        color: #ddd;
      }
      .Description_txt {
        margin-top: 20px;
        padding-bottom: 4px;
        font-family: $POPPINS;
        font-size: 18px;
        font-weight: 600;
        color: #464a53;
      }
    }
    .dish_img_blk {
      padding: 20px;
      .dish_img {
        font-family: $POPPINS;
        font-size: 22px;
        font-weight: 600;
        color: #20a258;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      .add_photo {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        .dish_images_cover {
          display: flex;
          align-items: center;
          border-radius: 10px;
          max-width: 130px;
          height: 126px;
          margin: 10px 0px;
          position: relative;
        }

        .dish_remove_icon {
          position: absolute;
          z-index: 9999;
          top: -7%;
          right: -5%;
          height: 10px;
          width: 10px;

          svg {
            font-size: 22px;
            background-color: $GREEN;
            border-radius: 50%;
            color: #ffffff;
          }
        }

        .dish_image {
          height: 100%;
          width: 100%;
          border-radius: 10px;
          object-fit: fill;
        }
      }
    }
    .pricing {
      padding: 20px;
      .pricing-text {
        font-size: 15px;
        color: #59bd83;
        font-weight: 600;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .customheading {
        width: 100%;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          font-weight: 600;
          color: $GREEN;
          font-size: 20px;
          padding-right: 10px;
          @media screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
        hr {
          width: 100%;
          color: $GREEN;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    .create_dish_addons_heading {
      hr {
        width: 87% !important;
        @media screen and (max-width: 960px) {
          width: 80% !important;
        }
        @media screen and (max-width: 560px) {
          width: 70% !important;
        }
      }
    }

    .create_dish_add_ons_button {
      margin-top: 20px;
      width: 100%;
      padding: 4px 0px;
      border-radius: 7px;
      border: 1px solid $GREEN;
      background-color: rgba(32, 162, 88, 0.06);
    }

    .create_dish_add_ons_button_label {
      font-family: $POPPINS;
      font-size: 20px;
      font-weight: 500;
      color: $GREEN;
      text-transform: none;
    }

    .form-pricing {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 20px;

      .price_field_container {
        width: 100%;
      }
      .outlineTextfield {
        width: 100%;

        .MuiInputBase-input {
          width: 100%;
        }
      }
      .dish_price1 {
        font-family: $POPPINS;
        font-weight: 800;
        padding-bottom: 4px;
        font-size: 15px;
        margin: 0;
        @media screen and (max-width: 360px) {
          font-size: 14px;
        }
      }
      .dish_price2 {
        font-family: $POPPINS;
        font-size: 15px;
        font-weight: 800;
        padding-bottom: 4px;
        margin: 0;
        @media screen and (max-width: 360px) {
          font-size: 14px;
        }
      }
    }
  }
  .product-status {
    padding: 20px;
    .product-text {
      padding-top: 10px;
      padding-bottom: 10px;
      font-family: $POPPINS;
      font-size: 22px;
      font-weight: 600;
      color: #20a258;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    .create_dish_delete {
      text-align: right;
      padding-top: 30px;
      font-family: $POPPINS;
      font-size: 18px;
      font-weight: 500;
      color: #f90202;
      cursor: pointer;
    }
    .green-outline {
      width: 100%;
    }
  }
  .dish-category {
    padding: 20px;
    min-height: 500px;
    margin-bottom: 10px;
    .select-text {
      font-family: $POPPINS;
      font-size: 22px;
      font-weight: 600;
      color: #20a258;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .create_dish_discovery {
      padding-top: 14px !important;
      font-family: $POPPINS;
      font-size: 14px;
      font-weight: 500;
      color: #abafb3;
    }
    .tag_label {
      padding-top: 15px;
      padding-bottom: 4px;
      font-family: $POPPINS;
      font-size: 18px;
      font-weight: 600;
      color: #464a53;
    }
    .outlineTextfield {
      .MuiSvgIcon-root {
        display: none;
      }
    }

    @media screen and (max-width: 1024px) {
      min-height: auto;
    }

    @media screen and (max-width: 768px) {
      & .select-text {
        font-size: 18px;
      }
    }
  }
}

.btns {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;

  button {
    border-radius: 7px;
    padding: 9px 35px;
    span {
      text-transform: none;
      font-family: $POPPINS;
      font-size: 15px;
      font-weight: 600;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .cancel {
    background-color: #f8f8f8;

    span {
      color: #000;
    }
  }

  .save {
    background-color: #20a258;
    span {
      color: #fff;
    }
  }
}

.add_ons_category_delete {
  height: 26px;
  cursor: pointer;
}

.add_ons_details_container {
  margin-top: 10px;
  padding: 23px 20px;
  border-radius: 7px;
  background-color: #f3f3f3;

  @media screen and (max-width: 560px) {
    padding: 23px 16px;
  }

  .add_ons_details_child {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }

  .add_ons_detail {
    width: 100%;

    p {
      font-family: $POPPINS;
      font-size: 18px;
      font-weight: 600;
      color: #464a53;
      margin-bottom: 8px;
    }
  }

  .add_on_details_action_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    border: 1px solid $GRAY_SHADE3;
    border-radius: 6px;

    img {
      height: 24px;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  .add_ons_delete_other {
    div {
      margin-top: 0px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .create-dish-container {
    .create-blk {
      margin-right: 0px !important;
      margin-bottom: 20px;
    }
  }
}

.add_on_details_heading {
  font-family: $POPPINS;
  font-size: 18px;
  font-weight: 600;
  color: #464a53;
  margin-bottom: 8px;
}

.dish_type_container {
  text-align: right;

  .dish_switch_label {
    font-family: $MONTSERRAT;
    font-weight: 600;
    color: #464a53;
  }
}

.create_dish_loader_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  height: 73vh;

  .create_dish_loader {
    color: $GREEN !important;
  }
}

.delete_dish_dialog {
  min-width: 30vw !important;

  .delete_dish_dialog_title {
    border-bottom: 1px solid #979797 !important;
    padding: 10px 20px !important;

    p {
      font-size: 18px;
      color: #171725;
      font-weight: 600;
      font-family: $MONTSERRAT;
    }
  }

  .delete_dish_dialog_content {
    padding: 20px 25px 35px 23px !important;

    p {
      color: #000;
    }
  }

  .delete_dish_dialog_actions {
    margin: 0px 0px 15px 12px !important;
    display: flex;
    justify-content: flex-start;
  }

  .delete_dish_delete_btn {
    background-color: $GREEN !important;
    padding: 6px 20px;
    border-radius: 5px;

    span {
      color: #ffffff;
      text-transform: none;
      font-family: $POPPINS;
      font-weight: 500;
    }
  }

  .delete_dish_cancel_btn {
    background-color: $GRAY_SHADE13;
    padding: 6px 20px;
    margin-left: 10px;
    border-radius: 5px;

    span {
      text-transform: none;
      font-family: $POPPINS;
      font-weight: 500;
      color: #000;
    }
  }
}
