@import "../../../../web/src/assets/css/constants/base/colors.scss";
$Banner: "../images/page-header-bg@2x.png";
$BannerNew: "../images/image-footer-calltoaction@2x.png";

.green_banner_new {
  background: url($BannerNew) no-repeat !important;
  background-size: cover;
  padding: 60px;
  display: flex;
  width: 100%;
  align-items: center;

  .banner_text {
    color: $WHITE;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.56;
  
    @media screen and (max-width: 1200px) {
      font-size: 18px;
      line-height: 1.2;
    }
  
    @media screen and (max-width: 1024px) {
      line-height: 1.2;
      font-size: 16px;
    }

    @media screen and (max-width: 599px) {
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 40px;
  }

  @media screen and (max-width: 350px) {
    padding: 25px 15px;
  }
}


.btnBlock {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 600px) {
    margin-top: 20px !important;
  }
}

.green_banner_btn {
  border: 2px solid $WHITE !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  padding: 3px 16px !important;
  font-size: 20px !important;

  @media screen and (max-width: 1200px) {
    font-size: 18px !important;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px !important;
  }
}

.btn-label {
  color: $WHITE !important;
}