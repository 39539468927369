@import "../../../../web/src/assets/css/constants/base/colors";
@import "../../../../web/src/assets/css/constants/base/global";
@import "../../../../web/src/assets/css/constants/base/index.scss";

.mediaContainer {
  padding: 25px 20px 25px 20px;

  &.forumsMediaContainer {
    padding: 0;
  }

  .mediaHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      color: $GREEN;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .green-outline {
      width: 250px;

      @media screen and (max-width: 768px) {
        width: 150px;
      }

      & .MuiInputBase-root {
        border-radius: 5px;
      }
    }
  }

  & .mediaUploader {
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 200px;
    text-align: center;
    border: 1px solid $GRAY_SHADE3;
    border-radius: $BORDER_RADIUS;
    color: $GREEN;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .MuiSvgIcon-root {
        font-size: 90px;

        @media screen and (max-width: 768px) {
          font-size: 70px;
        }
      }

      input {
        display: none;
      }
    }

    &.videoMediaUploader {
      height: auto;
      margin-top: 0;
      width: 175px;
      padding: 7px 10px;
      border-radius: 10px;
      border-color: $GREEN;

      .MuiSvgIcon-root {
        font-size: 30px;
        margin-right: 10px;
      }

      label {
        flex-direction: row;

        & span {
          color: $GREEN;
          font-weight: bold;
        }
      }
    }

    &.videoMediaUploader {
      height: auto;
      margin-top: 0;
      width: 175px;
      padding: 7px 10px;
      border-radius: 10px;
      border-color: $GREEN;

      .MuiSvgIcon-root {
        font-size: 30px;
        margin-right: 10px;
      }

      label {
        flex-direction: row;

        & span {
          color: $GREEN;
          font-weight: bold;
        }
      }
    }
  }

  & .media-main-block {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;

    & .inputrow {
      display: flex;
      justify-content: space-between;

      & .MuiFormControl-root {
        flex: 1;
      }

      & .btn-container {
        margin: 0px;

        & .save {
          height: 45px;
        }
      }
    }

    & .btn-container {
      margin: 0;
    }

    & .media-chip-block {
      margin: 0 -8px 20px -8px;
      position: relative;

      & .customUrlrow {
        align-items: flex-start;
        width: 100%;

        & .chipRow {
          display: flex;
          flex-wrap: wrap;
        }

        & .MuiButtonBase-root {
          height: 30px;
          margin: 5px 10px;
          width: 140px;
          overflow: hidden;
          justify-content: space-between;
          text-overflow: ellipsis;

          & span {
            color: #464a53;
            font-size: 13px;
          }
        }

        /* width */
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
          border-radius: 100%;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }
}

.chipContainer {
  padding: 0;
  margin-top: 20px;

  & .upload-chip {
    display: flex;
    align-items: center;
    background-color: #c1c1c18c;
    padding: 5px 8px !important;
    border-radius: 5px;

    & h6 {
      color: $GRAY_SHADE10;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 160px;
      white-space: nowrap;
    }

    & .closeIcon {
      width: 20px;
      height: 20px;
      color: $GRAY_SHADE10;
      margin-left: 10px;
    }
  }
}

.adminAddProductWrapper {

  .MuiFormLabel-root {
    font-weight: 600;
  }

  .outlineTextfield {
    label {
      font-weight: 600;
      margin-bottom: 5px;
      color: $GRAY_SHADE18;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  legend {
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }

  p {
    margin-top: 5px;
    margin-bottom: 10px !important;
    font-family: $POPPINS;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    color: #abafb3 !important;
    width: 100%;
    text-align: left;
    padding: 10px 0 10px 0;

    @media screen and (max-width: 768px) {
      font-size: 13px;
    }
  }

  .productDetailsContainer {
    background-color: $WHITE;
    border-radius: $BORDER_RADIUS;

    .taskPoint-title {
      margin-bottom: 10px;
    }

    & .texteditor-block {
      margin: 20px 20px 0 20px !important;
    }

    .customheading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-weight: 600;
        color: $GREEN;
        padding-right: 10px;
        font-family: $POPPINS;

        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }

      .optionalTitle {
        font-size: 14px;
        padding-left: 5px;
        color: #abafb3;
      }

      .codeTitle {
        color: #464a53 !important;
      }

      hr {
        width: 100%;
        color: $GREEN;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-self: flex-start;
      border-bottom: 1px solid $GRAY_SHADE2;
      padding: 20px;
      font-family: $POPPINS;

      @media screen and (max-width: 768px) {
        padding: 12px;
      }

      & > div {
        margin-right: 5px;
        padding: 10px;
        border-radius: 50%;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }

      .MuiSvgIcon-root {
        font-size: 30px;
        cursor: pointer;
      }

      h4 {
        font-weight: 900;
        margin-left: 5px;
      }
    }

    .productTitleContainer {
      padding: 25px;
      &.learningCourseContainer {
        padding: 25px;

        & label {
          font-weight: 600;
          color: #464a53;
          font-family: $POPPINS !important;
        }

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6,
        & p {
          font-family: $POPPINS !important;
        }

        & .accordianHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          padding-top: 10px;

          & label,
          & .btn-container {
            margin: 0;
          }

          & .btn-container {
            display: flex;
          }
        }
      }

      &.contentContainerScroll {
        max-height: 600px !important;
        overflow-y: scroll;
        @include scrollbars;

        @media screen and (max-width: 540px) {
          padding: 10px !important;
        }

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6,
        & p {
          font-family: $POPPINS !important;
        }

        & .partContainerScroll {
          max-height: 500px !important;
          overflow-y: scroll;
          @include scrollbars;
        }
      }

      .textarea-wrapper {
        margin-top: 10px;

        label {
          font-weight: 600;

          @media screen and (max-width: 768px) {
            font-size: 13px;
          }
        }

        textarea {
          width: 100%;
          border-radius: $BORDER_RADIUS;
          border-color: $GRAY_SHADE3;
          margin-top: 5px;
        }
      }
    }

    .uploadSection {
      &.upload-mt {
        margin: 10px 0;
      }

      background-color: #edf8f2;
      border-radius: 5px;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      & .uploadImgbg {
        position: relative;
        background-color: #edf8f2;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 10px 15px;
        padding: 2px;
        width: auto;

        img {
          width: 100px;
          height: 100px;
          padding: 2px;
          object-fit: cover;
        }

        & .close-icon-bg {
          background-color: #edf8f2;
          border: 1px solid #000;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -10px;
          top: -10px;
          height: 22px;
          width: 22px;
          cursor: pointer;
          border-radius: 50%;

          & .close-icon {
            color: #20a258;
            font-size: 24px;
            font-weight: 600;
            display: inline-block;
            transform: rotate(45deg);
          }
        }
      }
    }

    .priceInfo {
      padding: 25px 20px 25px 20px;

      .priceCard {
        padding-left: 10px;
        margin-top: 20px;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .radioGroups {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .block1 {
          padding-right: 10px;
        }

        .block2 {
          width: 100%;
          height: 100%;
          text-align: center;
          margin-left: 20px;
          padding: 130px 100px;
          background-color: $GRAY_SHADE2;
          border-radius: $BORDER_RADIUS;

          @media screen and (max-width: 768px) {
            margin-right: 20px;
            margin-left: 0px;
          }
        }
      }
    }

    .inventoryContainer {
      padding: 25px 20px 25px 20px;

      .inventoryBlocks {
        .block1 {
          margin-top: 10px;

          display: flex;
          align-items: center;
          justify-content: center;

          .outlineTextfield {
            padding: 0 5px;

            label {
              font-weight: 600;
              margin: 10px 0;
            }
          }
        }

        .block2 {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .checkBoxContainer {
            display: flex;
            align-items: center;
            justify-content: center;

            &:nth-child(2) {
              margin-left: 10px;
            }
          }
        }

        .block3 {
          margin-top: 10px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          gap: 30px;

          .selectContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .filledSelect {
              width: 40%;

              & .MuiInputBase-root {
                padding: 0px 30px 0px 0px;
              }

              .MuiSelect-select {
                &::before {
                  top: 25px;
                }
              }
            }
          }

          .outlineTextfield {
            padding: 0 5px;

            label {
              font-weight: 600;
              margin: 10px 0;
            }
          }
        }

        .track_quan {
          align-items: flex-end;

          .selectContainer {
            margin-top: 41px;
          }

          @media screen and (max-width: 588px) {
            grid-gap: 0px;
            gap: 0px;

            & .selectContainer {
              margin-left: 12px;

              & .filledSelect {
                width: 90%;
              }
            }
          }

          @media screen and (max-width: 404px) {
            & label {
              width: 122px;
            }
          }

          @media screen and (max-width: 358px) {
            & .outlineTextfield {
              margin-left: 36px;
            }

            & .selectContainer {
              & .filledSelect {
                width: 64%;
              }
            }
          }
        }
      }
    }

    .shippingContainer {
      padding: 25px 20px 25px 20px;

      .fieldWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;

        & > div:nth-child(1) {
          width: 100%;
        }
      }

      .productState {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0;

        legend {
          margin-top: 3px;
        }
      }

      .productWeight {
        hr {
          width: 100%;
          color: $GREEN;
          margin: 30px 0;
        }

        .weightDetails {
          margin-top: 10px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          gap: 30px;

          .outlineTextfield {
            padding: 0 5px;

            label {
              font-weight: 600;
              margin: 10px 0;
            }
          }

          .selectContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .filledSelect {
              width: 40%;

              & .MuiInputBase-root {
                padding: 0px 30px 0px 0px;
              }

              .MuiSelect-select {
                &::before {
                  top: 25px;
                }
              }
            }
          }
        }

        .track_quan {
          align-items: flex-start;

          .selectContainer {
            margin-top: 41px;
          }

          @media screen and (max-width: 588px) {
            grid-gap: 0px;
            gap: 0px;

            & .selectContainer {
              margin-left: 12px;

              & .filledSelect {
                width: 90%;
              }
            }
          }

          @media screen and (max-width: 404px) {
            & label {
              width: 122px;
            }
          }

          @media screen and (max-width: 358px) {
            & .outlineTextfield {
              margin-left: 36px;
            }

            & .selectContainer {
              & .filledSelect {
                width: 64%;
              }
            }
          }
        }

        .customsInfo {
          & .autocompBlock {
            & .MuiAutocomplete-root {
              width: 100%;
            }
          }

          h4 {
            @media screen and (max-width: 768px) {
              font-size: 15px;
            }
          }

          .outlineSelect {
            .MuiSelect-select {
              border-radius: 8px;
            }

            label {
              font-weight: 600;
              margin: 10px 0;
              color: #464a53;
            }
          }
        }
      }

      .dimensionsSelect {
        background-color: $GREEN;
        min-width: 90px;
        border-radius: 8px !important;
        border: none;

        .MuiSelect-select {
          color: #fff !important;
          width: 100%;
          padding: 17.5px 15px !important;
        }

        svg {
          color: #fff;
          right: 10px;
        }
      }
    }

    .previewContainer {
      padding: 25px 20px 25px 20px;

      & .previewHeader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-weight: 600;
          color: $GREEN;
          white-space: nowrap;
          padding-right: 10px;

          @media screen and (max-width: 768px) {
            font-size: 18px;
            white-space: normal;
          }
        }

        hr {
          width: 100%;
          color: $GREEN;
        }
      }
    }

    .variantDetailsWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 10px;
    }

    .add-variant-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      button {
        border-radius: 5px;
        border: 1px solid $GREEN;
        background-color: $GREEN_SHADE9;
        color: $GREEN;
        padding: 9px 24px;
        font-family: $MONTSERRAT;
        font-weight: "bold";
        font-size: 17px;
      }
    }

    @media screen and (max-width: 768px) {

      .previewContainer {
        padding: 20px 20px 0px;
      }
    }

    & .course-educator-row {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & .btn-container {
        margin: 0;
      }

      & .green-outline {
        width: 250px;

        & .MuiFormControl-root {
          padding: 0;
        }
      }
    }

    & .education-row {
      padding: 20px 0;
      width: 85%;

      & .priceInfo {
        padding: 15px 0;
      }

      & .productState {
        display: flex;
        align-items: center;
        padding: 15px 0;
      }

      & .mediaContainer {
        padding: 0;

        & .mediaUploader {
          width: 70%;
          height: 250px;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;

        & .mediaUploader {
          width: 100% !important;
        }
      }
    }

    & .edu-btn-container {
      margin: 0;
    }

    & .learningCourseContent {
      & p {
        font-weight: 500;
      }

      & .MuiPaper-elevation {
        margin-bottom: 10px;
      }

      & .MuiButtonBase-root {
        padding: 0;
      }

      & .edit-icon {
        height: 30px;
        width: 30px;
        object-fit: contain;
      }

      & .header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 50px 0 20px 0;

        & .left-block {
          display: flex;
          align-items: center;
        }
      }

      & .lessonAccordian {
        & .taskOptionBlock {
          max-height: 260px;
          @include scrollbars();
          overflow-y: scroll;
          margin-bottom: 15px;
        }

        &.MuiAccordion-root {
          box-shadow: none;
          border: 1px solid $GREEN;
          margin-bottom: 10px !important;
        }

        & .MuiAccordionDetails-root {
          padding: 10px;
        }

        & .MuiAccordionSummary-expandIconWrapper {
          margin-right: 20px;
        }

        & .MuiAccordionSummary-content {
          margin-left: 0;
        }

        & .accordionHeader {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding-right: 15px;

          @media screen and (max-width: 510px) {
            flex-direction: column;
            align-items: normal;

            & .lessonHead {
              margin-bottom: 20px;
            }

            & .right-block {
              margin-left: -20px;
            }
          }

          & .right-block {
            display: flex;
            align-items: center;
          }

          & .edit-icon {
            height: 25px;
            width: 25px;
            object-fit: contain;
          }
        }

        & .titleBox {
          padding: 10px 0;
          margin: 20px 0;
          border-top: 1px solid $GREEN_SHADE11;
          border-bottom: 1px solid $GREEN_SHADE11;
        }

        & .lesson-row {
          display: flex;
          align-items: center;

          & p {
            padding-left: 20px;
          }
        }

        & .task-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          & .left-block {
            display: flex;
            align-items: center;
          }

          .edit-icon {
            height: 25px;
            width: 25px;
            object-fit: contain;
          }
        }

        & .video-imgblock {
          display: flex;
          flex-direction: column;

          & .lessonImg,
          & video {
            width: 180px;
            height: 120px;
            object-fit: cover;
            border-radius: 10px;
          }

          & .captionBlock {
            width: 180px;
            display: flex;
            margin-top: 10px;
            justify-content: space-between;
            align-items: center;

            & .deleteIcon {
              width: 30px;
              height: 30px;
              object-fit: contain;
            }
          }
        }
      }

      & .greenContained,
      & .greenOutlineButton {
        .MuiButtonBase-root {
          padding: 8px 25px;
        }
      }

      & .learningCourseFooter {
        padding-top: 20px;

        & .add-part-row {
          margin-top: 50px;
          width: 100%;
          border-radius: 5px;
          padding: 10px;
          border: 1px dashed $GRAY_SHADE19;

          & span {
            color: $DARK;
            font-weight: 500;
            text-transform: capitalize;
          }

          & .plus-icon {
            height: 50px;
            width: 50px;
            border-radius: 10px;
            background: #20a258;
            font-size: 30px;
            color: #fff;
            display: flex;
            justify-content: center;
            margin: 0 20px;
          }
        }
      }
    }
  }

  .productStatusContainer {
    .productStatus {
      padding: 25px 20px;
      background-color: $WHITE;
      border-radius: $BORDER_RADIUS;

      & .outlineSelect {
        & .itemStatus {
          & .MuiSelect-select {
            width: 100%;
            max-width: 100% !important;
            margin-top: 0;
          }
        }
      }

      h4 {
        color: $GREEN;

        margin: 20px 0;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      h6 {
        font-weight: 600;
        font-size: 18px;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }

      .checkboxContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 15px;

        .MuiFormControlLabel-label {
          font-weight: 600;
          color: $DARK_SHADE1;
        }
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
          font-size: 20px;
          font-weight: 800;
          color: $DANGER1;
          margin: 20px 0;

          text-decoration: underline;

          @media screen and (max-width: 768px) {
            font-size: 15px;
          }
        }

        .MuiSvgIcon-root {
          margin-left: 10px;

          @media screen and (max-width: 768px) {
            margin-left: 0;
          }
        }
      }

      h5 {
        color: $DANGER1;
        margin-bottom: 10px;

        @media screen and (max-width: 768px) {
          font-size: 15px;
        }
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 20px 0 0;

        .MuiButton-label {
          color: red;
          font-weight: 900;
        }
      }
    }

    .productTypeContainer {
      background-color: $WHITE;
      border-radius: $BORDER_RADIUS;
      padding: 25px 20px;
      margin-top: 20px;

      h5 {
        color: $GREEN;
        text-transform: uppercase;
        margin: 15px 0;
        font-weight: bold;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      .customSelect {
        width: 100%;
      }

      .outlineTextfield {
        margin: 10px 0;

        label {
          font-weight: 600;
          margin: 10px 0;
        }
      }
    }
  }
}

.dateTimePickerConatiner {
  cursor: pointer !important;
  margin: 20px 0;
  position: relative;

  & .datepicker_row {
    display: flex;

    & .pencil_icon {
      position: absolute;
      left: 230px;

      img {
        height: 20px;
        width: 20px;
        object-fit: contain;
      }
    }
  }

  .MuiInputBase-root {
    cursor: pointer !important;

    .MuiInputBase-input {
      padding: 0;
      font-size: 20px;
      font-weight: 800;
      color: coral;
      text-decoration: underline;
      font-family: $POPPINS;
    }

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }

  .MuiInput-underline {
    cursor: pointer !important;

    &::before,
    &::after {
      border: 0 !important;
    }
  }
}

.circularprog1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px !important;
  min-height: 400px;

  .MuiCircularProgress-root {
    width: 110px !important;
  }

  .MuiCircularProgress-colorPrimary {
    color: #20a258;
  }
}

.Online-Store-label {
  width: 122px;
  height: 20px;
  margin: 17px 85px 30px 12px;
  font-family: $POPPINS;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #000;
}

.salesCheckbox {
  & .Online-Store-label {
    margin: 0;
  }

  &.customCheckbox {
    & input:checked {
      & + label {
        &::after {
          top: 0;
          border: solid $WHITE !important;
        }
      }
    }
  }
}

.farmOwnerCheckbox {
  display: flex;
  align-items: center;
  height: 100%;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 20px 0 0;

  button {
    font-family: $POPPINS;
    text-transform: none;
  }

  .cancel {
    background-color: $GRAY_SHADE3;

    .MuiButton-label {
      color: black;
    }
  }

  .save {
    background-color: $GREEN;

    .MuiButton-label {
      color: $WHITE;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 30px 0px 0px;
    button {
      width: 100%;
    }
  }
}

.createPartModal {
  .carContainer {
    padding: 20px 50px;

    .card {
      border-radius: 10px;

      display: flex;

      flex-direction: column;

      align-items: center;

      justify-content: center;

      padding: 20px 10px 18px 10px;

      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.63);

      .imgContainer {
        img {
          width: 62px;

          height: 62px;
        }
      }

      p {
        font-size: 13px;

        font-weight: 600;

        color: #3f526d;

        margin-top: 10px;

        width: 100%;

        text-align: center;
      }
    }
  }

  h1 {
    width: 100%;

    text-align: center;

    border-bottom: 1px solid black;

    padding: 25px 20px;

    font-size: 25px;
  }

  .btnContainer {
    width: 100%;
    background-color: #20a258;
  }
}

.imageUploadIcon {
  height: 18px;
  margin-right: 10px;
  margin-bottom: 3px;
}

.mediaUploadcourse {
  & span {
    color: #20a258;
  }

  & img {
    margin-bottom: 20px !important;
    width: 62px !important;
  }
}

.studentSelect {
  & .MuiMenu-paper {
    top: 188px;
    right: 16px;
    width: 300px;
    height: 337px;
  }
}

.adminAddProductWrapper {
  & .lessonDescription {
    font-family: $POPPINS !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #464a53 !important;
  }
}

.per_item_radio {
  @media screen and (max-width: 342px) {
    & .MuiFormGroup-root {
      justify-content: flex-start !important;
    }
  }
}

.sm-screen-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 0px 12px;
  gap: 10px;

  button {
    border-radius: 7px;
    text-transform: none;
    padding: 10px;
    span {
      font-family: $POPPINS;
      font-weight: 500;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .cancel {
    background-color: $GRAY_SHADE3;

    .MuiButton-label {
      color: black;
    }
  }

  .save {
    background-color: $GREEN;

    .MuiButton-label {
      color: $WHITE;
    }
  }
}