@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.farm_sidebar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.farm_sidebar_common_box {
  width: 100%;
  border-radius: 12px;
  border: solid 1px #e6e6e6;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
}

.farm_sidebar_heading {
  display: flex;
  align-items: center;
}

.farm_sidebar_box_heading {
  font-size: 15px;
  font-weight: bold !important;
  color: $DARK_SHADE1;
  text-transform: capitalize;
}

.farm_sidebar_range {
  .MuiSlider-track {
    color: $GREEN;
    height: 3px;
  }

  .MuiSlider-thumb {
    color: $GREEN;
  }

  .MuiSlider-mark {
    color: $GREEN;
  }

  .MuiSlider-marked {
    color: $GREEN;
  }
}

.price_container {

  font-family: $MONTSERRAT;
  color: #818181 !important;
  font-size: 15px !important;

  span {
    color: $Black;
    font-family: $MONTSERRAT;
    font-size: 14px;
  }
}

.related_category_contents {
  p {
    font-size: 15px;
    color: #818181;
    font-weight: 500;
    margin-bottom: 5px;
    cursor: pointer;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .selected {
    font-weight: bold !important;
  }
}

.sidebar_text_and_content_wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 25px;
  &>div {
    overflow-x: hidden;
  }
}

.farm_side_bar_icons {
  font-size: 13px !important;
  color: #818181;
}

.refine_by_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0;
  padding: 8px 15px 8px 20px;
  border-radius: 25px;
  border: solid 1px #979797;

  p {
    color: #818181;
    font-family: $MONTSERRAT;
    font-size: 15px;
    font-weight: 500;
  }
}

.refine_by_content_active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0;
  padding: 8px 15px 8px 20px;
  border-radius: 25px;
  border: solid 1px #20a258;
  background-color: #20a258;

  p {
    color: white;
    font-family: $MONTSERRAT;
    font-size: 15px;
    font-weight: 500;
  }
}

.side_bar_close_icon {
  color: #818181;
}

.side_bar_close_icon_active {
  color: white;
}

.filter_items {
  p {
    color: #818181;
    font-size: 15px;
    padding-top: 5px;
  }
}

.active_filter {
  color: $GREEN !important;
}

.product_tag_content_wrapper {
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  padding: 20px;

  .product_tag_header {
    color: #333;
    font-size: 15px;
    font-family: $MONTSERRAT;
    margin-bottom: 30px;
  }

  .selected {
    color: white;
    font-weight: bold;
    background-color: #20a258;
    font-family: $MONTSERRAT;
    border: 1px solid #20a258
  }
}

.product_tag_content {
  p {
    font-size: 13px;
    padding: 6px 12px;
    border-radius: 24px;
    border: solid 0.5px #979797;
    color: #818181;
    cursor: pointer;
    text-align: center;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}