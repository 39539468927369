@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.experts_loader {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $GREEN;
  }
}

.experts_upper_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .experts_filter_icon {
    border: 1px solid #d3d3d3;
    padding: 8px;
    min-width: 40px !important;

    &:hover {
      img {
        filter: invert(0%) sepia(4%) saturate(0%) hue-rotate(324deg)
          brightness(95%) contrast(105%);
      }
    }

    img {
      height: 20px;
      width: 20px;
    }
  }

  .isFilterEnabled {
    background-color: $GREEN;

    img {
      filter: invert(100%) sepia(0%) saturate(7477%) hue-rotate(107deg)
        brightness(102%) contrast(105%);
    }
  }
}

.experts_filter_menu_wrapper {
  padding: 10px 0px;

  div {
    padding: 6px 12px;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .isExpertFilterSelected {
    background-color: rgba(0, 0, 0, 0.08);
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
}

.edd_main_wrapper {
  .edd_image_loader {
    color: $GREEN;
  }

  .edd_loader_container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;

    svg {
      color: $GREEN;
    }
  }

  .edd_main_heading {
    font-family: $MONTSERRAT;
    font-size: 25px;
    font-weight: 600;
    color: #333;
  }

  .edd_secondary_heading {
    font-family: $INTER;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.19px;
    color: #394052;
  }

  .edd_name_heading {
    font-family: $INTER;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1.48px;
    color: #1e8d2f;
  }

  .edd_education_details,
  .edd_experience_details {
    font-family: $INTER;
    font-size: 13px;
    letter-spacing: 1.11px;
    color: #394052;
    margin-top: 4px;
  }

  .edd_experience_details {
    font-weight: 500;
    margin-top: 16px;
  }

  .edd_website {
    font-family: $INTER;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.53;
    letter-spacing: 1.11px;
    color: #5580ea;
    text-decoration: underline;
  }

  .edd_social_container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-top: 8px;
  }

  .edd_expert_description {
    font-family: $INTER;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    opacity: 0.57;
    margin-top: 15px;
    word-wrap: break-word;
  }
}
