@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.shopping_cart_card_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;

  h4 {
    font-family: $ROUNDGOTHIC_BOOK;
    font-size: 24px;
    font-weight: normal;
    color: #05172c;
  }

  .order_summary_quantity {
    color: #333;
    font-size: 17px;
  }

  button {
    margin-top: -11px;

    img {
      cursor: pointer;
      height: 45px;
      border-radius: 50%;
    }
  }
}

.cart_product_image {
  height: 200px;
  width: 96%;
  object-fit: fill;
  border-radius: 12px;
}

.shopping_cart_product_name {
  font-family: $ROUNDGOTHIC_BOOK;
  font-size: 24px;
  color: #05172c;
  font-weight: normal;
  line-height: normal;
  word-break: break-word;
}

.shopping_cart_details_wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.shopping_cart_price_wrapper {
  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  .cart_variant_wrapper {
    display: flex;
    gap: 12px;
    margin-top: 5px;

    & > h6 {
      font-size: 17px;
      color: #bbbbbb;
      margin-top: 7px;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;
    }
  }

  .order_summary_variant {
    display: flex;
    align-items: center;
    gap: 5px;
    h6,
    p {
      font-size: 17px;
      color: #333;
      font-weight: 600;
    }
  }
}

.cart_variant_btn {
  color: #bbb;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 24px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 12px;
  min-height: 34px;
}

.cart_selected_variant {
  color: $GREEN;
  border: 1px solid $GREEN;
}

.shopping_cart_card_instruction {
  font-family: $MONTSERRAT;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin-top: 2px;
}

.shopping_cart_card_paragraph {
  font-family: $MONTSERRAT;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.cart_price {
  span {
    font-family: $MONTSERRAT;
    color: #fc4b4e;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 800;
  }

  del {
    font-family: $MONTSERRAT;
    color: #818992;
    font-size: 18px;
    font-weight: bold;
  }
}

.cart_buy_now_btn {
  background-color: #ffac0c !important;
  border-radius: 25px !important;
  padding: 8px 20px !important;
  min-width: 90px !important;
}

.cart_btn_label {
  color: $WHITE !important;
}

.cart_buy_disabled {
  background-color: #ebe8e8 !important;

  span {
    color: $Black !important;
  }
}
