@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/index.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.donate_banner {
  background: url("../images/group-20@3x.png") no-repeat;
  background-size: cover;
  padding: 90px 140px 200px;
  background-color: rgba(12, 12, 12, 0.22);
  background-blend-mode: multiply;

  .donate_container_header {
    color: $ORANGE2;
    padding: 10px 0px;
    font-family: $MONTSERRAT !important;
    font-size: 80px;
    font-weight: 900 !important;
    line-height: 1;
    letter-spacing: 0.1px;
    height: 100%;
  }

  @media screen and (max-width: 1000px) {
    padding: 80px 100px 160px;

    .donate_container_header {
      font-size: 62px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 60px 50px 120px;

    .donate_container_header {
      font-size: 38px;
    }
  }

}

.donate_green_container {
  height: 45px;
  width: 100%;
  background-color: $GREEN;
}

.web_input_field {

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: none !important;
  }
}

.donate_container {
  margin-top: -120px;

  h1 {
    color: $ORANGE2;
    padding: 10px 0px;
    font-family: $MONTSERRAT !important;
    font-size: 80px;
    font-weight: 900 !important;
    line-height: 1;
    letter-spacing: 0.1px;
    width: 80%;
  }

  .donate_description {
    color: $Black;
    font-family: $MONTSERRAT;
    font-size: 14px;
    line-height: 1.71;
  }

  @media screen and (max-width: 600px) {
    margin-top: -70px;
  }
}

.featured_donate_wrapper {
  padding: 40px 50px;
  background-color: $WHITE;
  box-shadow: 4px 6.9px 17px 0 rgba(0, 0, 0, 0.2);
  border: 10px solid #fafafa;
  border-radius: 8px;

  h4 {
    color: $ORANGE3;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 24px;
    font-family: $MONTSERRAT;
    line-height: 1.33;
  }

  h2 {
    font-weight: 600;
    margin-top: 10px;
    font-size: 36px;
    font-family: $MONTSERRAT;
    line-height: 1.14;
    color: #424242;
  }

  @media screen and (max-width: 800px) {
    padding: 30px;

    h4 {
      font-size: 20px;
    }

    h2 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 30px;

    h4 {
      font-size: 18px;
    }

    h2 {
      font-size: 25px;
    }
  }
}

.amount_donate {
  p {
    text-transform: capitalize;
    margin: 0px 0px 10px;
    font-weight: 600;
    font-family: $MONTSERRAT;
    font-size: 16px;
    line-height: 1.31;
    color: #616161;
  }
}

.radio-label {
  color: #616161 !important;
  font-weight: 600 !important;
  font-family: $MONTSERRAT !important;
  font-size: 18px !important;

  svg {
    color: #616161 !important;
  }
}

.donate_leave_reply {
  border-top: 2px solid #f3f3f3;
  margin-top: 50px;
  padding: 50px 0px;

  h4 {
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 500;
    font-family: $MONTSERRAT;
    line-height: 0.7;
    color: #424242;
  }

  p {
    font-family: $MONTSERRAT;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #616161;
  }
}

.donate_input_root {
  width: 100%;
  background-color: $WHITE !important;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  color: $GRAY_SHADE6 !important;
  padding: 4px 8px;
}

.donate_input {
  width: 100%;
  outline: none;
  border: none;
  padding: 13px 10px !important;
}

.donate_reply_input_root {
  width: 100%;
  background-color: $WHITE !important;
  border-radius: 6px !important;
  border: 1px solid #e9e9e9 !important;
  padding: 4px 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 2.29 !important;
  color: #616161 !important;
  font-family: $MONTSERRAT !important;

  & input::placeholder {
    opacity: 1 !important;
  }
}

.donate_input_focused {
  border: 1px solid $ORANGE !important;
  outline: none !important;
}

.donate_text_area {
  width: 100%;
  resize: none;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none !important;
  border: 1px solid #e9e9e9 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 2.29 !important;
  color: #616161 !important;
  font-family: $MONTSERRAT !important;

  &:focus {
    border: 1px solid $ORANGE !important;
  }

  &::placeholder {
    color: #616161;
  }
}

.donate_btn {
  background-color: $ORANGE3 !important;
  padding: 10px 24px !important;
  margin-top: 20px !important;
  border-radius: 20px !important;
}

.donate_btn_2 {
  background-color: $ORANGE3 !important;
  padding: 10px 20px !important;
  border-radius: 20px !important;
  width: 100% !important;
}

.donate_btn_label {
  color: $WHITE !important;
  font-size: 12px !important;
  font-family: $MONTSERRAT !important;
  font-weight: 500 !important;
}

.donate_btn_disabled {
  background-color: #f3f3f3 !important;

  span {
    color: #333 !important;
  }
}

@media screen and (max-width: 428px) {
  .donate_container {
    h1 {
      color: $ORANGE !important;
      font-weight: 900 !important;
      font-size: 60px;
      text-align: left;
      padding: 30px 0px;
    }
  }

}