@import '../constants/base/colors.scss';
.customChips {
  &.MuiChip-root {
    border-radius: 7px;
    background-color: $GREEN_SHADE1;
    padding: 4px 7px;
    height: auto;
    font-size: 0.6rem;
    & span {
      color: $WHITE;
      padding: 0;
      font-weight: 600;
    }
  }
}

.greenContainChip {
  .customChips {
    &.MuiChip-root {
      background-color: $GREEN_SHADE3;
      font-size: 0.7rem;

      & span {
        color: $GREEN_SHADE1;
      }
    }
  }
}

.redContainChip {
  .customChips {
    &.MuiChip-root {
      background-color: $DANGER2;
      font-size: 0.7rem;

      & span {
        color: $DANGER3;
      }
    }
  }
}

.blueContainChip {
  .customChips {
    &.MuiChip-root {
      background-color: $BLUE_SHADE1;

      & span {
        color: $BLUE_SHADE2;
      }
    }
  }
}
