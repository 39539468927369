@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.landing_page_reorder {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;

  @media screen and (max-width: 768px) {
    padding: 10px 0px;

    button {
      font-size: 13px;
      padding: 7px 10px !important;
    }
  }

  @media screen and (max-width: 600px) {
    button {
      width: 100%;
    }
  }
}

.llp_loader_container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $GREEN;
  }
}

.landing_page_container {
  background-color: rgba(244, 237, 226, 0.65);
}

.landing_page_color_container {
  background-color: #f3f3f3;
}

.landing_page_section {
  padding: 60px 0px;

  @media screen and (max-width: 768px) {
    padding: 10px 0px 40px;
  }
}
.user_details_section {
  padding: 20px 10px;
  .user_name {
    font-size: 30px;
    color: $GREEN;
    .user_name_value {
      text-transform: capitalize;
      color: $GREEN;
    }

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
}

.landing_page_section_details {
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .h1 {
    font-family: $ROUNDGOTHIC_BOOK !important;
    font-weight: lighter !important;
    font-size: 34px !important;
    color: #333 !important;

    @media screen and (max-width: 768px) {
      font-size: 26px !important;
    }
  }
}

.green_small_heading {
  font-size: 20px;
  font-weight: 600 !important;
  font-family: $MONTSERRAT !important;
  color: #3bad6c !important;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
}

.landing_page_courses_container {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3bad6c;
  font-size: 23px;
  font-family: $MONTSERRAT;
  border: 2px solid #d3d3d3;
  border-radius: 20px;
  padding: 15px;
  text-align: center;
}

//green middle banner

.green_mid_banner {
  background: url(../images//grass-banner.png);
  background-size: cover;
  width: 100%;
  padding: 60px;
  text-align: center;
  margin-bottom: 10px;
  p {
    color: $WHITE;
    font-size: 38px;
    text-transform: capitalize;
    font-family: $ROUNDGOTHIC_BOOK;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 10px;
  margin-bottom: 20px;

    p {
      font-size: 26px;
    }
  }
}

//latest updates section

.latest_updates_card_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 50vh;
  cursor: pointer;

  img {
    position: relative;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}

.latest_updates_card_ad {
  height: 47.2vh;
}

.latest_updates_card_name_wrapper {
  width: 90%;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px 20px;
  margin: 0px 15px 0px;
  opacity: 0.87;
  background-color: #fff;
  @media (max-width: 540px) {
    padding: 10px;
    word-wrap: break-word;
  }
}
.latest_updates_card_name {
  font-size: 20px !important;
  text-align: center;
  color: $GREEN;
  font-weight: 600 !important;
  padding: 0px 30px;
  font-family: $MONTSERRAT !important;
  @media (max-width: 540px) {
    font-size: 15px !important;
  }
}

//farms which u liked

.farms_liked_card_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 40vh;

  img {
    position: relative;
    object-fit: cover;
    width: 100%;
    border-radius: 20px;
  }
  &:hover .card_hidden {
    display: block;
    transition: all 0.8s ease-in-out;
  }

  &:hover .farms_liked_card_name_wrapper {
    display: none;
  }
}

.empty_star_rating {
  color: #fff;
}

.farms_liked_card_name_wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 40px;
  margin: 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 6%, rgba(0, 0, 0, 0.7));
}
.farms_liked_card_name {
  font-size: 20px !important;
  text-align: center;
  color: $WHITE;
  font-weight: 600 !important;
  text-transform: capitalize;
  font-family: $MONTSERRAT !important;
}

.favourate_icon_wrapper {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  z-index: 99;
  right: 12px;
  top: 12px;
  cursor: pointer;
  box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);

  button {
    background-color: $WHITE;
    padding: 8px;
    svg {
      color: $GREEN;
    }
  }
}
.farms_liked_rating_wrapper {
  display: flex;
  justify-content: center;
}
.rated_star {
  color: $ORANGE;
}
.un_rated_star {
  color: $WHITE;
}
.card_hidden {
  display: none;
  position: absolute;

  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 1;
  width: 100%;
  background-color: rgba(25, 161, 79, 0.82);
  border-radius: 20px;
  text-align: center;
  transition: all 1s ease-in;

  .farms_liked_card_desc {
    color: $WHITE;
    font-family: $MONTSERRAT;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.69;
    text-align: center;
    margin-top: 20px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}
.card_hidden_wrapper {
  height: 85%;
  width: 90%;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  margin: 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease-in;
}
.view_card_details_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: $MONTSERRAT;
    font-size: 16px;
    font-weight: 500;
    color: $WHITE;
  }

  button {
    cursor: pointer;
    border: 1px solid $WHITE;
    border-radius: 50%;
    color: $WHITE;
    padding: 6px;

    svg {
    color: $WHITE;

    }
  }
}
.green-btn {
  background-color: $GREEN !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  width: 180px;
}

.grey_text {
  color: #5a5a5a !important;
  font-weight: 500 !important;
  padding-top: 5px;
  font-family: $MONTSERRAT !important;
  word-break: break-word;
}

.landing_page_EHF {
  background-color: #feefda; 
}

//favourate cources


.fav_courses_card_wrapper {
  position: relative;
  border-radius: 20px;
  img {
    height: 260px;
    object-fit: cover;
    border-radius: 20px;
  }
}
.fav_course_item {
  background-color: rgba(12, 12, 12, 0.42);
  border-radius: 20px;
  position: absolute;
  top: 0%;
  left: 0%;
  padding: 25px 30px;
  color: $WHITE !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .fav_course_item_text {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

}
.fav_course_name {
  text-transform: capitalize;
  color: $WHITE !important;
  font-family: $MONTSERRAT !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
.fav_course_author {
  color: $WHITE !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: $MONTSERRAT !important;
}

.fav_courses_rating_wrapper {
  display: flex;
  align-items: center;
}
.fav_courses_rating {
  position: relative;
}
.favourate_icon {
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 10px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;

  button {
    background-color: $WHITE;
    padding: 8px;
    box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
    
    svg {
      color: $GREEN;
    }
  }
}

/*bottom section*/

.bottom_section_card_wrapper {
  background-color: $WHITE;
  border-radius: 20px;
  height: 100%;
  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
.bottom_section_card_img_wrapper {
  position: relative;
  p {
    font-family: $MONTSERRAT;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 6px;
    margin: 0px 15px;
    padding: 8px 20px 2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 14px;
    font-weight: 600;
  }
}

.bottom_section_card_details {
  padding: 15px 25px 25px;
  p {
    font-size: 16px;
    font-weight: 500;
    font-family: $MONTSERRAT;
    color: #000;
    margin-bottom: 15px;
  }
}
.green_question_text {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: $MONTSERRAT !important;
  color: #4cb479 !important;
}
.arrow {
  text-align: right !important;
}
.landing_page_btn_container_arrow {
  text-align: right;
  .arrow-btn {
    background-color: none !important;
    text-align: right !important;
    text-decoration: none !important;
    .arrow-btn-label {
      background-color: none !important;
    }
    span {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
.arrow-btn-label-dark {
  color: $DARK_SHADE2 !important;
}
.landing_page_btn_container_center {
  text-align: center;
}
.arrow-btn-label-grey {
  background-color: none !important;
  text-decoration: none !important;
  color: $GRAY_SHADE1 !important;
}
@media (max-width: 599px) {
  .landing_page_btn_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
