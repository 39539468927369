@import "web/src/assets/css/constants/base/colors.scss";
@import "web/src/assets/css/constants/base/global.scss";

.checkout_current_step_box {
  display: flex;
  justify-content: center;
  min-height: 50vh;
}

.checkout_login_container {
  text-align: center;
  
  p {
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: $MONTSERRAT;
    margin-bottom: 10px;
  }

  .checkout_login_button {
  padding: 10px 25px !important;
  background-color: $GREEN !important;

  span {
    text-transform: capitalize;
    color: #ffffff;
  }
}
}


.checkout_order_dialog_paper {
    border-radius: 35px !important;
    -ms-overflow-style: none !important; // Hide scrollbar for Internet explorer
    scrollbar-width: none !important; // Hide scrollbar for Firefox
  }
  
.checkout_order_dialog_paper::-webkit-scrollbar { 
  display: none !important;  /* Hide scrollbar for Safari and Chrome */
}

.checkout_loader_container {

  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;

  svg {
    color: $GREEN !important;
  }
}

.no_card_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20vh;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  margin-bottom: 10px;

  h2 {
    color: $GREEN;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: $MONTSERRAT;
  }

  h5 {
    color: rgba(51, 51, 51, 0.5);
    font-size: 18px;
    text-align: center;
    font-family: $MONTSERRAT;
  }
}

.reward_radio_label {
  width: 100% !important;
}

.reward_payment_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .reward_heading {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    width: 100%;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: $Black;
  }

  .selected_reward_heading {
    font-weight: 600;
  }

  .reward_price {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
}

.loyalty_point_wrapper {
  margin-top: 5px;
  h6 {
    color: #333;
    font-size: 14px;
    font-family: $MONTSERRAT;
  }

  & .MuiFilledInput-input {
    padding: 15px;
  }

  .applicable_point_message {
    font-size: 13px;
    color: #FC4B4E;
    font-family: $MONTSERRAT;
    text-align: end;
    margin-top: 2px;
  }
}

.applicable_points_loader_wrapper {
  display: flex;
  justify-content: flex-end;

  & > span {
    width: 50%;
  }
}

.checkout_payment_loader {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $GREEN;
  }
}