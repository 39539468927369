@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';
.customList {
  & .listItems {
    display: flex;
    padding: 15px 40px;
    align-items: center;
    cursor: pointer;
    position: relative;
    & .leftIcon {
      &::before {
        content: ' ';
        position: absolute;
        right: 22px;
        bottom: 19px;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid $GRAY_SHADE3;
      }
    }
    & .bottomIcon {
      &::before {
        content: ' ';
        position: absolute;
        right: 32px;
        bottom: 24px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid $GRAY_SHADE3;
        transform: rotate(-90deg);
        border-radius: 3px;
        transition: transform 0.3s;
      }
    }
    & .bottomIconActive {
      &::before {
        content: ' ';
        position: absolute;
        right: 32px;
        bottom: 24px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid $GREEN;
        border-radius: 3px;
        transition: transform 0.3s;
      }
    }

    & .MuiSvgIcon-root {
      display: none;
    }
    & .MuiListItemIcon-root {
      min-width: 28px;
    }
    & .MuiTypography-root {
      color: #bbb;
      font-weight: 600;
      font-family: $POPPINS;
      font-size: 1.1rem;
      line-height: 1;
      text-transform: capitalize;
    }
    &:hover,
    &:active,
    &.listItemsActive {
      & .MuiTypography-root {
        color: #009540;
      }
    }
  }
  & .collapseList {
    & .listItems {
      padding: 0;
    }
    & .MuiListItemText-root {
      margin-bottom: 2px;
      color: $GRAY_SHADE3;
      font-weight: 600;
      cursor: pointer;
      & span {
        color: #bbb;
        font-weight: 600;
        font-family: $POPPINS;
        padding: 10px 55px 10px 75px !important;
      }
      &:hover,
      &.listItemsTextActive {
        & span {
          background-color: #e7ffed;
          color: $GREEN;
        }
      }
    }
  }
}
