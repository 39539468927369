@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.account_details_heading_wrapper {
  width: 100%;
  background-color: #f3f0f0;
  padding: 10px;
  border-radius: 6px;

  h5 {
    color: black;
    font-weight: 600;
    font-size: 18px;
    margin-left: 15px;
  }
}

.account_setting_save_btn {
  background: #20a258 !important;
  border-radius: 50px !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  padding: 12px 0px !important;
  box-shadow: none !important;
  width: 100%;
  span {
    color: #fff;
  }
}
.account_setting_save_btn_color {
  color: white !important;
}

.account_setting_changePWD_btn {
  background: white !important;
  border-radius: 24px !important;
  color: black !important;
  text-transform: capitalize !important;
  padding: 10px 0px !important;
  border: 1px solid #20a258 !important;
  font-weight: 900 !important;
  box-shadow: none !important;
  width: 100% !important;
}
.not_connected_s {
  color: black !important;
  font-size: 16px !important;
  line-height: normal !important;  
}

.account_setting_role_card_grid {
  display: grid;
}
.account_setting_role_card_wrapper {
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 170px;

  img {
    height: 100px !important;
  }

  p {
    color: #20a258;
    font-weight: bold;
  }
}

  .para {
    font-size: 16px;
  }

.as_profile_pic_container {
  padding: 5px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.as_profile_heading {
  font-size: 16px !important;
  color: #3a3a3a !important;
  font-weight: 600 !important;
  font-family: $MONTSERRAT !important;
  text-align: center;
  margin-bottom: 20px !important;
}

.as_profile_wrapper {
  position: relative;

  img {
    border-radius: 50%;
    width: 120px !important;
    position: initial !important;
  }

  .as_profile_edit {
    position: absolute;
    right: 5px;
    top: 20px;
    background-color: $GREEN !important;
    transform: translateY(-50%);
    height: 30px !important;
    width: 30px !important;

    &:hover {
      background-color: $GREEN;
    }

    svg {
      color: $WHITE;
      font-size: 18px !important;
    }
  }
}

.cp_field_container {
  padding: 10px 40px 30px 40px;
}

.update_password_header {
  position: relative;

  img {
    width: 45%;
  }

  button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      color: #515156;
    }
  }
}