@import "../../../../web/src/assets/css/constants/base/colors.scss";


.web_pagination_container {
    display: flex;
    align-items: center;
    justify-content: center;
    .Mui-selected {
        background-color: $GREEN !important;
        color: $WHITE !important;
        border: 1px solid $GREEN;
    }
    .MuiPaginationItem-root{
        @media screen and (max-width: 400px) {
            min-width: 26px !important;
        }
    }
}
.cmsPagination{
    justify-content: flex-start;

}

.left_align_pagination_container {
    justify-content: flex-start !important;
}