@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.web_sidebar_link_view_header {
  h5 {
    font-family: $MONTSERRAT !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    color: #000 !important;
  }
}

.track_order_btn {
  background-color: #ffa700 !important;
  border-radius: 50px !important;
  padding: 8px 18px !important;
  font-weight: bolder !important;
}

.history_invoice_btn {
  background-color: #f3f0f0 !important;
  border-radius: 50px !important;
  padding: 8px 18px !important;
  font-weight: bolder !important;

  span {
    color: #000 !important;
  }
}

.od_course_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.wishlist_card_wrapper {
  margin-bottom: 5% !important;
}

.product_name {
  font-weight: 900 !important;
  font-size: 18px !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order_history_product_desc {
  font-family: $MONTSERRAT !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #5a5a5a;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span {
    font-family: $MONTSERRAT;
    font-size: 12px;
    font-weight: 500;
    color: #5a5a5a;
  }
}

.order_history_product_price {
  font-family: $MONTSERRAT;
  font-size: 18px;
  font-weight: bold;
  color: #179d4e;
  margin-top: 15px !important;
}

.btn_tab_ordrhstry {
  text-transform: capitalize !important;
  background-color: #f3f0f0 !important;
  margin: 10px !important;
  border-radius: 20px !important;

  span {
    color: #000 !important;
    font-weight: 500 !important;
    padding: 0 10px !important;
  }
}

.track_order_details_link {
  margin: 0px 0px !important;

  .store_name {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 900 !important;
    color: #000;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .order_number {
    font-size: 14px;
    color: #000;
    font-weight: lighter !important;
    word-break: break-word;
  }

  .order_link {
    color: $GREEN;
    text-decoration: underline;
    border: none;
    background-color: transparent;
  }

  .order_link_wrapper {
    display: flex !important;
    flex-direction: column !important;;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    width: 100%;
  }
}

.order_history_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;

  .order_history_loader {
    color: $GREEN;
  }
}

.order_history_blank_container {
  height: 25vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px solid $GRAY_SHADE2;
  border-radius: 10px;
  margin-bottom: 20px !important;
  padding: 10px 15px;

  p {
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-align: center;
  }
}

.course_od_blank_container {
  border: none !important;
  margin-bottom: 0px !important;
}

.od_action_btn {
  background-color: #f3f3f3 !important;

  span {
    color: #3a3a3a !important;
  }
}
