@import "../../../../web/src/assets/css/constants/base/global.scss";
@import "../../../../web/src/assets/css/constants/base/colors.scss";

.dishes_list_heading {
    font-family: $POPPINS;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #171725;
}

.dish_type_menus {
    font-family: $POPPINS  !important;
    font-size: 16px !important;
    font-weight: 500;
    color: #44444f !important;

    &:hover {
        color: #ffffff !important;
    }
}

.dish_list_sort_menus {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    margin: 0px !important;
    padding-bottom: 0 !important;
    font-family: $POPPINS  !important;
    font-weight: 500 !important;
    color: #44444f !important;

    &:hover {
        color: #ffffff !important;
    }
}

.dish_list_filter_status_menus {
    font-family: $MONTSERRAT  !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    letter-spacing: 0.13px !important;
    color: #000 !important;
    margin: 0px !important;
    background-color: #fff !important;

    &:hover {
        background-color: #fff !important;
        color: $GREEN  !important;
    }
}

.selected_status_menu {
    color: $GREEN  !important;
}

.loader_container {
    height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: $GREEN;
    }
}

.no_dishes_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 51vh;

    h4 {
        color: $GREEN;
        font-weight: 600;
        font-size: 20px;
        font-family: $MONTSERRAT;
        margin-bottom: 6px;
    }

    p {
        font-size: 15px;
        margin-bottom: 15px;
        font-weight: 600;
        word-spacing: 2px;
    }

}

.dish_list_status_menus {
    font-family: $POPPINS;
    font-size: 16px;
    font-weight: 500 !important;
    color: #20a258 !important;

    &:hover {
        background-color: unset !important;
        color: #20a258 !important;
    }
}

.de_active_menu {
    color: #fc4b4e !important;

    &:hover {
        background-color: unset !important;
        color: #fc4b4e !important;
    }
}

.draft_menu {
    color: #d98f08 !important;

    &:hover {
        background-color: unset !important;
        color: #d98f08 !important;
    }
}

.cookingDes {
    .MuiInputBase-input.MuiInput-input {
        color: #5a5a5a;
        font-family: Montserrat;
        font-size: 16px;
    }
}

.custom_formik_error {
    font-size: 12px;
    color: $RED;
    margin: 0px !important;
    width: 100% !important;
    line-height: normal !important;
    letter-spacing: normal !important;
}