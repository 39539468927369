@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.farm_card {
  width: 100%;
}

.farm_card_box_shadow {
  width: 100%;
  box-shadow: 0px 0px 2px 2px $GRAY_SHADE1;
  border-radius: 20px;

  .farm_card_content {
    padding: 20px;

    h6 {
      font-weight: 800 !important;
      font-size: 18px !important;
    }

    p {
      color: #5a5a5a;
      font-size: 14px;
      font-family: $MONTSERRAT;
      text-align: justify;
      min-height: 21px;
    }

    .duration {
      color: $Black;
      margin: 30px 0px;
    }
  }

}

.farm_img_wrapper {
  border-radius: 15px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 15px;
  }
}

.farm_card_content {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  h6 {
    font-weight: 900 !important;
    font-size: 18px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  p {
    color: #5a5a5a;
    font-size: 14px;
    font-family: $MONTSERRAT;
    text-align: justify;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: 21px;
  }

  .duration {
    color: $Black;
    margin: 30px 0px;
  }
}

.read_more_btn {
  padding: 9px 16px !important;
  border-radius: 29.5px !important;
  background-color: #f3f0f0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-transform: capitalize !important;
  transition: 0.5s !important;

  img {
    transition: transform 0.25s;
  }

  &:hover {
    background-color: #e1dfdf !important;

    img {
      transform: translateX(3px)
    }
  }

  @media screen and (max-width: 400px) {
    padding: 7px 11px !important;

    span {
      font-size: 13px;
    }

    img {
      height: 15px;
    }
  }

}

.read_more_btn_color {
  color: black;
}

.iconFilled {
  color: #ffb400;
}

.iconHover {
  color: #ffb400;
}

.card_price_wrapper {

  .product_price_wrapper {
    display: flex !important;
    align-items: center;
  }

  h5 {
    color: $GREEN;
    font-size: 22px;
    font-weight: 800 !important;
  }

}


.image_loader {
  color: #20a258 !important;
}