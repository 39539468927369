@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.custom_green_round_btn {
  background-color: $GREEN !important;
  color: $WHITE !important;
  padding: 10px 30px !important;
  border-radius: 24px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: $MONTSERRAT !important;
}

.green_btn_label {
  color: $WHITE;
}

.green_btn_root {
  border-radius: 24px !important;
  background-color: $GREEN !important;
}