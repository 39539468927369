@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.shopping_cart_wrapper_box {
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  padding: 20px;
  border-radius: 15px;
}

.web_input_field {

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: none !important;
  }
}

.green_line {
  border-bottom: 3px solid $GREEN !important;
  margin: 10px 0px 20px;
  width: 100px;
}

.shopping_cart_details {
  display: flex;
  justify-content: space-between;
  font-family: $ROUNDGOTHIC_MEDIUM !important;
  margin-bottom: 30px;

  h3 {
    font-family: $ROUNDGOTHIC_BOOK;
    font-size: 28px;
    color: #333;

    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }

    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  img {
    border-radius: 50%;
    height: 60px;
    width: 60px;

    @media screen and (max-width: 400px) {
      height: 40px;
      width: 40px;
    }
  }
}

.shopping_cart_select_root {
  width: 100% !important;
  border: none !important;

  &:focus {
    border: none !important;
  }

  .shopping_cart_select {
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    font-size: 14px;
    font-family: $MONTSERRAT;
    font-weight: 600;
    color: #000;

    &::before {
      content: "Delivery Address: " !important;
      font-size: 14px;
      font-family: $MONTSERRAT;
      font-weight: 500;
      color: #000;
    }
  }
}

.shopping_cart_select_focused {
  border: none !important;
}

.cart_total_price_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    color: #05172c;
    font-size: 23px;
  }

  h4 {
    font-size: 22px;
    font-weight: bold;
    color: #05172c;
    font-family: $PTSans;
  }
}

.apply_coupon_wrapper {
  p {
    color: $Black;
    text-transform: capitalize;
    padding: "0px 8px";
    font-family: $MONTSERRAT;
    font-size: 18px;
    font-weight: 600;
  }
}

.add_coupon_input_root {
  width: 100% !important;
  border-radius: 50px !important;
  margin-top: 15px !important;
  background-color: $WHITE !important;
  border: 1px solid $GRAY_SHADE1;
}

.add_coupon_applied {
  border: 1px dashed $GRAY_SHADE4 !important;
  background-color: $GRAY_SHADE2 !important;

  input {
    color: $GRAY_SHADE4 !important;
    font-weight: 500;
  }
}

.add_coupon_input {
  width: 100% !important;
  border-radius: 50px !important;
  padding: 12px 20px !important;

  &::placeholder {
    font-family: $MONTSERRAT;
    font-size: 14px;
    font-weight: 600;
    color: #5a5a5a;
  }
}

.apply_coupon_btn {
  background-color: $GREEN !important;
  border-radius: 24px !important;
  padding: 13px 32px !important;
  min-width: 120px !important;
  line-height: normal !important;
  width: 100% !important;

  span {
    font-family: $MONTSERRAT;
    font-size: 16px;
  }

  &.Mui-disabled {
    background-color: #ebe8e8 !important;

    span {
      color: $BLACK !important;
    }
  }
}

.remove_coupon_btn {
  background-color: #fc5558 !important;
}

.note_to_order_label {
  padding: 0px 5px 15px;
  font-size: 16px;
  color: #05172c;
}

.note_to_order_textarea {
  width: 100%;
  resize: none;
  border-radius: 12px;
  border: 1px solid $GRAY_SHADE1;
  padding: 25px 40px;

  &::placeholder {
    line-height: normal;
    color: #77808a;
  }
}

.shopping_cart_address_button {
  padding: 8px 24px !important;
  background-color: $GREEN !important;
  border-radius: 50px !important;

  &:hover {
    background-color: $GREEN;
  }

  span {
    color: $WHITE;
  }
}

.shopping_cart_address_dialog {
  border-radius: 37px !important;
  padding: 12px 0px;
  -ms-overflow-style: none !important; // Hide scrollbar for Internet explorer
  scrollbar-width: none !important; // Hide scrollbar for Firefox
}

.shopping_cart_address_dialog::-webkit-scrollbar {
  display: none !important;
  /* Hide scrollbar for Safari and Chrome */
}

.cart_restaurant_time_details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;

  p {
    font-family: $MONTSERRAT;
    font-size: 21px;
    color: #333;
    letter-spacing: normal;

    @media screen and (max-width: 1100px) {
      font-size: 19px;
    }

    @media screen and (max-width: 400px) {
      font-size: 17px;
    }
  }

  .cart_restaurant_delivery_time {
    color: $GREEN;
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
  }
}

.restaurant_close_desc {
  color: #fff;
  font-family: $MONTSERRAT;
  padding: 3px 8px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: $RED;
  opacity: 0.8;
  text-transform: none;

  svg {
    margin-right: 5px;
  }
}