@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';

// ================= CHART CSS =================
.chartContainer {
  margin-bottom: 25px;
  width: 100%;
  background-color: white;
  border-radius: $BOX_RADIUS;

  & .card {
    &.MuiCard-root {
      padding: 15px 20px 0px 20px;
    }
  }

  &.cardContainer {
    padding-top: 0;
  }

  @media screen and (max-width: 1024px) {
    margin: 10px 0 25px 0;
  }

  & h5 {
    font-size: 18px;
    font-weight: bolder;
    white-space: nowrap;
  }

  & .chartCard {
    width: 100%;

    & .charthead {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    & .listMenu {
      display: flex;

      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }

      & .listItems {
        margin: 0 15px;
        position: relative;

        & span {
          margin-left: 5px;
          color: $DARK_SHADE1;
          font-size: 12px;
        }

        &::before {
          content: ' ';
          position: absolute;
          width: 13px;
          height: 15px;
          left: -15px;
          top: 5px;
          border-radius: 50%;
        }

        &.primary {
          &::before {
            background-color: $SKYBLUE_SHADE3;
          }
        }

        &.success {
          &::before {
            background-color: $GREEN;
          }
        }
      }
    }
  }

  .header {
    width: 100%;
    border-bottom: 1px solid $GRAY_SHADE2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 580px) {
      flex-direction: column;
      padding: 20px 20px;
    }

    h3 {
      width: 100%;
      text-align: left;
      font-weight: 900;
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 580px) {
        margin-top: 14px;
      }

      .graph-btn {
        background-color: $WHITE;
        color: $GRAY_SHADE3;
        font-weight: 500;
        padding: 5px 15px;
        border-radius: 8px;
        outline: none;
        border: none;
        margin: 0 2px;

        &.active {
          color: $WHITE;
          background-color: $SKYBLUE_SHADE3;
        }
      }
    }
  }

  .dashboardChart {
    width: 100%;
    height: 350px;

    @media screen and (max-width: 580px) {
      height: 250px;
    }

    #lineChart {
      width: 100%;
      height: 100%;

      @media screen and (max-width: 580px) {
        padding: 20px 20px;
      }
    }
  }
}