@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.res_heading_wrapper {
  p {
    font-family: $ROUNDGOTHIC_MEDIUM;
    font-size: 36px;
    color: #000;
    font-weight: normal;
    margin-bottom: 20px;
  }
}

.farm_catelogs_tabs_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.farm_catelog_tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  border-radius: 8px;
  background-color: #f3f3f3;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);

  img {
    width: 25px;
    margin-right: 10px;
  }

  p {
    font-family: $MONTSERRAT;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
}

.farm_catelog_tab_active {
  background-color: #3bad6c;

  p {
    color: white;
  }
}

.farm_catelog_middle_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding: 80px 40px;
  width: 100%;
  background-color: rgba(12, 12, 12, 0.22);
  background-blend-mode: multiply;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 40px;
  }
}

.farm_details_review_heading {
  display: flex;
  width: 100%;
  align-items: center;

  p {
    color: $Black;
    font-weight: 600 !important;
    font-size: 18px;
    padding: 15px 0px;
    padding-right: 10px;
  }
}

.farm_details_review_block {
  margin-top: 45px !important;
}

.farm_details_write_review_heading {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.write_review_with_underline {
  color: $GREEN;
  font-size: 18px;
  text-decoration: underline;
  font-weight: bold;
  padding: 15px 0px;
}

.Learn_some_new_heading_wrapper {
  margin-top: 35px;
  margin-bottom: 15px;

  h4 {
    font-family: $MONTSERRAT;
    font-size: 30px;
    font-weight: 500;
    color: #333;
    text-align: center;
  }
}

.farm_catelog_middle_banner_contents_wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
  }
}

.middle_banner_left_content {
  h1 {
    font-family: $MONTSERRAT !important;
    font-size: 56px !important;
    font-weight: 800 !important;
    color: #000 !important;
  }

  h5 {
    font-family: $MONTSERRAT !important;
    font-weight: 500 !important;
    color: #000;
    margin: 15px 0px 25px;
    font-size: 20px !important;
    line-height: 1.23;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 38px !important;
    }

    h5 {
      font-size: 18px !important;
    }
  }

  @media (max-width: 300px) {
    h1 {
      font-size: 28px !important;
    }

    h5 {
      font-size: 16px !important;
    }
  }

  @media (max-width: 400px) {
    font-size: 35px;
  }
}

.farm_donate_btn_product {
  background-color: #ffa700 !important;
  padding: 12px 30px !important;
  margin-top: 10px !important;
  border-radius: 45px !important;
  font-size: 18px !important;

  @media (max-width: 300px) {
    font-size: 14px !important;
    padding: 8px 20px !important;
  }
}

.farm_donate_btn_label {
  font-weight: bold !important;
  font-family: $MONTSERRAT !important;
  color: #fff;
}

.orange_circle_wrapper {
  display: inline-flex;
  justify-content: center;
}

.orange_circle {
  width: 240px;
  height: 240px;
  padding: 52px 40px 72px 45px;
  border: solid 18px #ffa700;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p,
  h5 {
    font-family: $MONTSERRAT;
    color: #000;
  }

  p {
    font-size: 15px;
    font-weight: 500;
  }

  h5 {
    font-size: 28px !important;
    font-weight: 800;
    margin-bottom: 15px;
  }
}

.restaurant_catelog_select_icon {
  color: #000000 !important;
  top: 2px !important;
}

.farm_donate_generate {
  font-size: 20px;
  color: #000000;
  @media (max-width: 300px) {
    font-size: 17px;
  }
}

.farm_donate_gain_amount {
  color: #000000;
  font-size: 45px;
  text-shadow: 1.5px 0px #000000;
  letter-spacing: 1px;
  font-weight: 700;

  @media (max-width: 300px) {
    font-size: 25px;
  }
}

.farm_donate_raise_amount {
  color: #000000;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
  text-shadow: 0.8px 0px #000000;
  letter-spacing: 1px;

  @media (max-width: 300px) {
    font-size: 13px;
    margin-top: 5px;
  }
}

.restaurant_main_wrapper {
  margin-top: 50px;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
}
