@import "../../../../web/src/assets/css/constants/base/colors.scss";
.filter_input_root{
    border-radius: 30px!important;
    fieldset {
      top: 0px
    }
}

.filter_input_root:focus{
    border: 'none';
}
.filter_input_input {
    padding: 14px 12px 14px 12px!important;
}
.filter_input_icon{
    width: 16px;
    object-fit: contain;
}

.filter_advance_search_btn{
    background-color: $GREEN !important;
    width: 100%;
    color: "white" !important;
    padding: 11px 12px!important;
    border-radius: 30px !important;
    text-transform: uppercase !important;
    & > span {
      color: #fff;
    }
  }
  
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $GREEN !important;
}