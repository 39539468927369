@import "../../../../web/src/assets/css/constants/base/index.scss";
.regStepperContainer {
  background-color: $WHITE;
  border-radius: $BORDER_RADIUS;
  font-family: $ROUNDGOTHIC_MEDIUM;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $POPPINS;
  }
  h4 {
    font-weight: 700;
  }
  p {
    color: $GRAY_SHADE5;
  }
  .regStepperHeader {
    display: flex;
    align-items: center;
    justify-self: space-between;
    border-bottom: 1px solid $GRAY_SHADE2;
    padding: 20px 20px 20px 20px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      padding: 20px 10px;
      gap: 20px;
    }
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-self: flex-start;
      @media screen and (max-width: 767px) {
        justify-content: center;
      }
      .MuiSvgIcon-root {
        font-size: 30px;
      }
      h4 {
        font-weight: 900;
        margin-left: 5px;
      }
    }
    .stepperContainer {
      width: 100%;
      padding-left: 30px;
      @media screen and (max-width: 767px) {
        padding-left: 0px;
        background-color: black;
      }
    }
  }

  .regInfoContainer {
    padding: 0px 20px 20px 20px;
    & .verify-block {
      display: flex;
      align-items: center;
      height: 100%;
      &.farm-verify-block {
        margin-bottom: 0;
      }
      & .verify-icon {
        background-color: transparent;
        border: 2px solid $GREEN;
        border-radius: 50%;
        height: 21px;
        width: 21px;
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 1px;
          left: 5px;
          width: 7px;
          height: 12px;
          border: solid #20a258;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }
      & .text-verify {
        position: relative;
        font-weight: 600;
        font-size: 17px;
        color: $GREEN;
        text-transform: capitalize;
        margin-left: 5px;
      }
    }
    & .verifyMaginBlock {
      margin-left: 55px;
      @media screen and (min-width: 1560px) {
        margin-left: 25px;
      }
      @media screen and (max-width: 599px) {
        margin-left: 0px;
      }
    }
    .regAdress {
      h4 {
        font-weight: 700;
        margin: 30px 0;
        font-size: 22px;
      }
      .regRestInfoForm {
        margin: 24px 0;
        h6 {
          font-weight: 600;
          margin: 10px 0;
        }
        p {
          color: $GRAY_SHADE4;
          margin: 10px 0;
        }

        & .detectIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          border-left: 1px solid #979797;
          padding-left: 30px;

          img {
            height: 20px;
            width: 20px;
          }

          p {
            color: $DARK;
            font-weight: 700;
          }

          @media screen and (max-width: 768px) {
            padding-left: 10px;
            gap: 10px;
            p {
              font-size: 15px;
            }
          }
        }
      }
    }

    .iframeContainer {
      margin: 40px 0;
      .coOrdinateContainer {
        margin: 20px 0;
        .ordinateHeader {
          width: 100%;
          text-align: center;
          border-bottom: 1px solid $GRAY_SHADE3;
          line-height: 0.1em;
          margin: 30px 0 30px;
          span {
            background: #fff;
            padding: 0 10px;
            color: $DARK;
          }
        }

        .coOrdinate-form {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0;
          gap: 30px;
          @media screen and (max-width: 767px) {
            flex-direction: column;
          }
        }
      }
    }

    .restContactNumber {
      margin: 20px 0;

      h6 {
        font-weight: 600;
        color: $GRAY_SHADE4;
        margin: 10px 0;
      }
      p {
        font-size: 12px;
        margin: 5px 0;
      }
      .contact {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        .conactNumber {
          width: 100%;
          h6 {
            font-size: 18px;
          }
          p {
            font-weight: 400;
          }
          .conactNumberForm {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            @media screen and (max-width: 767px) {
              flex-direction: column;
            }
            & .numberField {
              width: 35%;
              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }
            & .greenContained {
              padding: 0px;
              @media screen and (max-width: 767px) {
                width: 100%;
              }
              & .MuiButton-root {
                padding: 8px 30px !important;
                @media screen and (max-width: 767px) {
                  width: 100%;
                }
              }
            }

            & .countryCodeIcon {
              border-right: 1px solid $GRAY_SHADE4;
              p {
                font-size: 20px;
                font-weight: 700;
                color: $DARK;
                padding-right: 20px;
              }
            }
          }
        }
      }
      .otpContainer {
        width: 100%;
        .otpForm {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          grid-gap: 5px;
          gap: 5px;
          p {
            font-family: $POPPINS;
          }
          .outlineTextfield {
            width: 50px;
            @media screen and (max-width: 767px) {
              width: 50px;
            }
          }
          input {
            background-color: transparent !important;
            border: 1px solid #c1c1c15e;
            border-radius: 8px;
            margin-right: 15px;
            outline: 0;
            height: 45px;
            width: 45px !important;
            color: $GRAY_SHADE10;
            &.disabledInput {
              cursor: not-allowed;
              opacity: 0.8;
            }
            &.errorInput {
              border-color: $RED;
            }
          }
        }
      }
      &.restDetailsContact {
        & .conactNumber,
        & .otpContainer {
          width: 50% !important;
        }
      }
    }

    .ownerDetailsContainer {
      margin: 40px 0;
      .detailsHeader {
        font-size: 22px !important;
      }
      h4 {
        font-weight: 700;
        margin: 20px 0;
      }
      p {
        font-size: 18px !important;
        margin: 10px 0;
        font-weight: 700;
        color: $DARK_SHADE1;
      }
      .nameEmails {
        width: 75%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 30px;
        @media screen and (max-width: 767px) {
          flex-direction: column;
          gap: 20px;
        }
        .outlineTextfield {
          width: 100%;
        }
      }
      .ownerDetails {
        width: 100%;
      }
      .phoneDetails {
        display: flex;
        align-items: flex-end !important;
        justify-content: flex-start;
        gap: 10px;
        margin: 30px 0;
        & .restPhone {
          margin-right: 30px;
          align-self: flex-end;
          @media screen and (max-width: 560px) {
            align-self: auto;
          }
        }
        & .grayBackGroundButton {
          width: 50%;
        }
        @media screen and (max-width: 768px) {
          gap: 20px;
          flex-wrap: wrap;
          align-items: flex-start !important;
          margin: 10px 0;

          & > div:nth-child(1) {
            width: 100%;
          }
        }
        .customRadio {
          width: 100%;
        }
        .verifyForm {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          flex-direction: row;
          width: 100%;
          gap: 20px;
          &.verifyRegForm {
            width: 100%;
          }
          .greenContained {
            padding: 0px;

            & .MuiButton-root {
              padding: 8px 30px !important;
            }
          }

          .restVerifyBtn {
            margin-right: 40px;
            align-self: flex-end;
            @media screen and (max-width: 560px) {
              align-self: flex-start;
            }
          }
          & .countryCodeIcon {
            border-right: 1px solid $GRAY_SHADE4;
            p {
              font-size: 20px;
              font-weight: 700;
              color: $DARK;
              padding-right: 20px;
            }
          }
        }
      }
    }
  }

  .restTypeTimingsContainer {
    padding: 40px 20px 30px 20px;
    .restTypeCommonHeader {
      h4 {
        font-family: $POPPINS;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.1px;
        color: #171725;
      }

      p {
        margin: 10px 0;
        color: #464a53;
        font-size: 18px;
        font-weight: 400;
        font-family: $POPPINS;
      }
    }
    .radioBoxContainer {
      margin-left: -10px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      & .errorMsg {
        margin-left: 10px;
      }
      & .MuiFormGroup-root {
        flex-direction: row;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .outletType {
      .block {
        margin: 30px 0;
        h6 {
          color: #464a53;
          font-weight: 600;
          font-size: 18px;
          margin: 10px 0;
        }
      }
    }

    .restOperationTime {
      margin: 10px 0;
      .restOpenCLose {
        width: 70%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .timingsFrom {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 30px;
          gap: 30px;

          span {
            color: $DARK;
            font-weight: 700;

            &:nth-child(2) {
              padding-top: 25px;
            }

            h6 {
              color: $DARK;
              font-weight: 700;
              margin: 5px 0;
            }
          }
        }
        .openDaysCheckBox {
          margin: 20px 0;
          h6 {
            color: $DARK;
            font-weight: 700;
          }
        }
      }
    }
    .bannerImgContainer {
      margin: 30px 0;
      & h6 {
        font-weight: 600;
        color: $GREEN;
        font-size: 22px;
      }
      & .FileUpload {
        & .customFileUpload {
          max-width: 150px;
        }
      }
    }
  }
  &.restAddDetailsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767px) {
      justify-content: flex-start;
      display: block;
    }

    & .restTypeTimingsContainer {
      padding: 20px 0 0 0;
    }
    & .bankDetaisCOntainer {
      padding: 20px 0 20px 0;
      h4 {
        font-size: 22px;
      }
      @media screen and (max-width: 767px) {
        padding-bottom: 0;
      }
      & .bankDetalsFormContainer {
        margin: 20px 0 30px 0;
      }
    }
  }

  & .bankDetaisCOntainer {
    &.adminFarmBankDetaisContainer {
      padding: 0;
    }
    padding: 40px 20px 30px 20px;
    .bankDetalsFormContainer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 30px;
      margin: 30px 0;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .formContainer {
        width: 100%;
        height: 100%;
        h6 {
          font-weight: 700;
          color: $DARK;
          margin: 10px 0;
          font-size: 18px;
        }

        .outlineSelect {
          width: 100%;

          .MuiSelect-select {
            padding: 12px 19px !important;
          }
          .MuiFormControl-root {
            width: 100%;
          }
        }
      }
    }

    .uploadDetail {
      margin: 30px 0;
      & .uploadSubtitle {
        font-family: $POPPINS;
        font-weight: 600;
        color: #464a53;
        font-size: 15px;
        opacity: 0.9;
      }
    }
    .checkBoxContainer {
      margin-top: 100px;
      margin-bottom: -50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        color: $DARK_SHADE1;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
        a {
          font-weight: 700;
          color: $GREEN;
          margin-left: 5px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.checkBoxRegContainer {
        margin: 0;
        label {
          font-family: $POPPINS;
          color: #464a53;
        }
      }
    }
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 30px 0 10px;
    gap: 10px;
    .greenContained,
    .grayButton {
      & .MuiButton-root {
        & .MuiButton-label {
          & span {
            font-size: 15px;

            font-weight: 800;
            padding: 5px 30px;
          }
        }
      }
    }
    &.regBtnContainer {
      margin: 0;
    }
    @media screen and (max-width: 560px) {
      flex-wrap: nowrap;
      gap: 0;
    }

    @media screen and (max-width: 768px) {
      .greenContained,
      .grayButton {
        width: 100%;
      }

      button:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}

// .modalContainer {
.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  box-shadow: 24;
  padding: 40px;
  border-radius: $BORDER_RADIUS;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;

  @media screen and (max-width: 767px) {
    width: 300px;
    padding: 25px;
  }
  .modalImgContainer {
    width: 100%;
    height: 250px;

    & > img {
      object-fit: cover;
    }

    @media screen and (max-width: 767px) {
      height: 150px;
    }
  }
  .modalHeading {
    text-align: center;
    width: 100%;
    font-weight: 700;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }

  .content {
    color: $DARK_SHADE1;
    text-align: center;
    margin: 10px 0;
    width: 100%;
    font-size: 17px;
    @media screen and (max-width: 767px) {
      font-size: 10px;
    }
  }
  .greenContained {
    .MuiButton-root {
      padding: 5px 40px;
    }
  }
}

.ownerDetailsContainer .countryCodeIcon {
  border-right: 1px solid $GRAY_SHADE4;
  .inputAdornment {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: $DARK;
    padding-right: 20px !important;
  }
}

.restOwnerCheckBox.customCheckbox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;

  label {
    font-family: $POPPINS;
  }
}
// }

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-family: $POPPINS;
  color: #464a53;
}

.custom_action_btn {
  width: 100% !important;
  min-height: 50px !important;
}

.resDesc {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}

.headingDetails {
  font-size: 18px !important;
}
