@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.restaurant_map_left_container {
  padding: 30px 20px;
  min-height: 100vh;
  max-height: 100vh;
  width: 320px;
  display: inline-block;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #d3d3d3;
  }

  .restaurant_map_total_heading {
    p {
      height: 32px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      color: #333333;
    }
  }

  .restaurant_map_all_restaurants {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 20px;

    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #d3d3d3;
    }

    @media screen and (max-width: 1000px) {
      display: block;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    max-height: auto;
    min-height: auto;
    overflow-x: hidden;

    .restaurant_map_total_heading {
      p {
        margin-left: 15px;
      }
    }
  }
}

.restaurant_map_loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;

  svg {
    color: $GREEN;
  }
}

.restaurant_map_dish_card_wrapper {
  @media screen and (max-width: 1000px) {
    display: inline-block;
    margin: 0 10px;
  }
}

.restaurant_map_dish_card {
  width: 270px;
  height: 208px;
  padding: 15px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6)
  );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  border: 6px solid #fff;

  @media screen and (max-width: 300px) {
    width: 220px;
    height: 160px;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    border-radius: 6px;
    object-fit: fill;
  }

  .dish_card_rating {
    width: 75px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    background-color: #eb8e78;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    p {
      color: #ffffff;
      font-family: $MONTSERRAT;
      font-size: 18px;
      line-height: 1.6;
    }

    @media screen and (max-width: 300px) {
      width: 65px;
      height: 40px;

      p {
        font-size: 14px;
      }
    }
  }

  .dish_card_details {
    margin-left: 4px;

    p {
      color: #ffffff;
      font-family: $MONTSERRAT !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p:nth-child(1) {
      font-family: $MONTSERRAT;
      font-size: 14px;
      line-height: 1.8;
    }

    p:nth-child(2) {
      font-size: 24px;
      line-height: 1;
    }
  }
}

.active_restaurant_map_dish_card {
  border: 6px solid #f49b3f;
}

.restaurant_google_map {
  height: 100vh;
  width: calc(100vw - 338px);
  display: inline-block;

  @media screen and (max-width: 1000px) {
    display: block;
    width: 95vw;
    margin: 0px auto;
  }
}

.restaurant_map_selected_card {
  width: 280px;
  border-radius: 12px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transform: translate(-50%, -100%);
  left: 12px;
  bottom: 48px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ffffff;

  .restaurant_map_upper_wrapper {
    height: 200px;
    position: relative;
    padding: 16px;

    img {
      width: 100%;
      height: 200px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      object-fit: fill;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .selected_restaurant_card_rating_container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .selected_restaurant_card_rating {
      padding: 5px 14px 5px 12px;
      border-radius: 8px;
      background-color: #ffac0c;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      p {
        font-family: $MONTSERRAT;
        font-size: 18px;
        color: #ffffff;
      }
    }

    .selected_restaurant_card_close {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffac0c;
      height: 40px;
      width: 40px;
      border-radius: 50%;

      svg {
        color: #ffffff;
      }
    }
  }

  .selected_restaurant_card_details {
    padding: 20px;

    p {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .selected_card_dish {
      font-family: $MONTSERRAT;
      font-size: 18px;
      color: #333;
      margin-bottom: 12px;
    }

    .selected_card_restaurant {
      font-family: $MONTSERRAT;
      font-size: 22px;
      font-weight: bold;
      color: #333;
    }

    .selected_card_address {
      font-family: $MONTSERRAT;
      font-size: 16px;
      color: rgba(51, 51, 51, 0.5);
    }

    .selected_card_time_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      margin-top: 15px;

      .selected_card_time {
        font-family: $MONTSERRAT;
        font-size: 16px;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .selected_card_price {
        font-family: $MONTSERRAT;
        font-size: 16px;
        color: rgba(51, 51, 51, 0.5);
      }
    }

    .selected_card_order_button {
      width: 100%;
      height: 48px;
      margin-top: 12px;
      border-radius: 12px;
      text-transform: none;
      background-color: #1ca055;
      text-align: center;

      span {
        color: #ffffff;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 300px) {
    width: 220px;
  }

  @media screen and (max-width: 500px) {
    width: 240px;

    .restaurant_map_upper_wrapper {
      height: 190px;

      .selected_restaurant_card_rating {
        padding: 3px 10px;

        p {
          font-size: 16px;
        }
      }

      .selected_restaurant_card_close {
        height: 35px;
        width: 35px;
      }
    }

    .selected_restaurant_card_details {
      .selected_card_dish {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .selected_card_restaurant {
        font-size: 20px;
      }

      .selected_card_address {
        font-size: 14px;
      }

      .selected_card_time_container {
        p {
          font-size: 14px !important;
        }
      }

      .selected_card_order_button {
        height: 40px;

        span {
          font-size: 14px;
        }
      }
    }
  }
}

.restaurant_map_selected_card::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -14px;
  border-width: 14px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.restaurant_map_pin {
  height: 100%;
  width: 100%;

  svg {
    color: #ea4335;
    font-size: 50px;
  }
}

.restaurant_map_pin_hover {
  svg {
    opacity: 0.9;
  }
}
