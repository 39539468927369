@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.restaurant_details_images {
  margin-top: 20px;

  img {
    border-radius: 12px;
    height: 448px;
    object-fit: fill;
    cursor: pointer !important;
  }

  .other_restaurant_images_container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer !important;
  }

  .hover_effect_container {
    height: 208px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 12px;
    cursor: pointer;

    .last_image_hover {
      height: 100%;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.4);
      font-size: 40px;
      font-weight: bold;

      span {
        color: #ffffff;
      }
    }
  }

  .other_restaurant_images {
    height: 208px !important;
    object-fit: cover !important;
  }
}

.restaurant_details_wrapper {
  h1 {
    color: $Black;
    font-weight: 600;
    font-size: 50px;

    @media screen and (max-width: 600px) {
      font-size: 30px;
    }

    @media screen and (max-width: 300px) {
      font-size: 26px;
    }
  }

  p {
    color: $GRAY_SHADE4;
  }

  .tag {
    background-color: #f3f3f3;
    border-radius: 8px;
    padding: 11px 16px;
    font-family: $MONTSERRAT;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }

  .restaurant_details_wrapper_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .res_details_info_box {
      display: flex;
      align-items: center;
      gap: 15px;
      flex-wrap: wrap;
    }

    .rating_number_box {
      background-color: $GREEN;
      border-radius: 10px;
      padding: 7px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;

      svg {
        color: $WHITE;
        font-size: 30px;
      }

      p {
        padding-left: 10px;
        color: $WHITE;
        font-size: 15px;
      }
    }

    h6 {
      color: $GRAY_SHADE6;
      max-width: 200px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      color: $GREEN;
      font-weight: 600;
      border-bottom: 1px solid $GREEN;
    }
  }
}

.restaurant_details_wrapper_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learn_something_new_card {
  box-shadow: 0px 0px 2px 2px $GRAY_SHADE1;
  border-radius: 20px;

  .learn_something_new_card_img_wrapper {
    position: relative;

    div {
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: $WHITE;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 10px 20px;
      color: $Black;
    }
  }

  img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .learn_something_new_card_content {
    padding: 20px 20px 30px;
  }

  h5 {
    color: $GREEN;
    font-weight: 600;
    padding: 10px 0px 30px;
  }
}

.restaurant_details_menu_header {
  margin-top: 20px;
  border-bottom: 1px solid $GRAY_SHADE1;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  p {
    text-transform: capitalize;
    font-weight: 500;
    color: $GRAY_SHADE6;
    font-size: 20px;
  }

  .restaurant_close_desc {
    color: #fff;
    font-family: $MONTSERRAT;
    padding: 5px 8px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $RED;
    opacity: 0.8;
    text-transform: none;

    svg {
      margin-right: 5px;
    }
  }
}

.restaurant_details_menu_heading {
  p {
    text-transform: uppercase;
    color: $GRAY_SHADE6;
    font-size: 15px;
  }
}

.restaurant_details_menu_item {
  background-color: $GRAY_SHADE2;
  padding: 15px;
  border-radius: 15px;

  .restaurant_details_menu_item_description {
    div {
      display: flex;
      align-items: center;
      gap: 10px;

      h6 {
        font-family: $MONTSERRAT;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
        color: #333;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    h6 {
      font-family: $MONTSERRAT;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      color: #333;
    }

    .res_details_desc_container {
      display: flex;
      align-items: center;

      p {
        font-family: $MONTSERRAT;
        font-size: 16px;
        color: rgba(51, 51, 51, 0.5);
      }

      button {
        font-weight: bold;
        color: #20a258;
        border: none;
        font-size: 15px;
        outline: none;
      }
    }
  }

  .restaurant_details_menu_item_details {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding-top: 10px;

    @media screen and (max-width: 400px) {
      flex-direction: column;
    }

    svg {
      color: $GREEN;
      font-size: 55px;
      cursor: pointer;
    }

    p {
      font-size: 18px;
      color: #333;
    }

    del {
      color: #818992;
      font-size: 18px;
    }
  }

  img {
    width: 100%;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;

    @media screen and (max-width: 599px) {
      height: 240px;
    }
  }
}

.restaurant_details_place_order_wrapper_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $GRAY_SHADE2;
  padding: 35px 30px;
  border-radius: 15px;

  .restaurant_order_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .place_order_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      color: $GRAY_SHADE6;
    }

    h2 {
      font-family: $INTER;
      font-weight: 500;
    }

    span {
      font-family: $MONTSERRAT;
      font-size: 14px;
      font-weight: 500;
      color: rgba(51, 51, 51, 0.5);
    }
  }

  .deliver {
    border-bottom: 1px solid $GRAY_SHADE1;
    padding-bottom: 20px;
  }

  h6,
  h5,
  h4,
  p {
    color: $Black;
  }

  h6 {
    text-transform: uppercase;
    font-size: 12px;
    color: #333333 !important;
  }

  h4 {
    font-size: 24px;
    color: #333333;
  }
}

.restaurant_details_place_order_address_textfield {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $WHITE;
  border-radius: 15px;
  padding: 10px;

  input {
    width: 90%;
    border: none;
    outline: none;
  }
}

.restaurant_details_place_order_address_textfield_root {
  border: none;
  outline: none;
  width: 100%;
  background-color: $WHITE !important;
}

.restaurant_details_place_order_address_textfield_input {
  background-color: $WHITE !important;
  width: 100%;
  padding: 10px !important;
}

.restaurant_details_order {
  .remove_btn_container {
    display: flex;
    justify-content: center;
  }

  .restaurant_details_dish_details {
    display: flex;
    gap: 2px;

    p {
      margin-left: 5px;
      display: inline-block;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  img {
    min-width: 60px;
    height: 50px;
    border-radius: 8px;
  }

  span,
  del {
    color: $GRAY_SHADE4;
    font-size: 15px;
  }

  .remove_order {
    width: 30px !important;
    height: 30px !important;

    svg {
      border-radius: 50% !important;
      background-color: $GREEN !important;
      color: #ffffff;
    }
  }
}

.restaurant_details_quantity_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d2d2d2;
  border-radius: 26px;
  max-height: 25px;
  width: 64px;

  button {
    border: none;
    background-color: transparent;
    height: 22px;
    width: 22px;
    font-size: 14px;
    font-weight: bold;
    color: #264653;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #264653;
    padding-right: 5px;
  }

  button:last-child {
    height: 26px;
    width: 26px;
    background-color: #ffffff;
    border-radius: 50%;
    font-size: 16px;
  }
}

.res_details_price {
  display: block;
  font-family: $INTER;
}

.restaurant_details_order_addons {
  margin-top: 5px;

  p {
    font-size: 9px;
    font-weight: 600;
    color: #000000;
    margin-top: 3px;
  }

  span {
    font-size: 11px;
    font-weight: 600;
    color: #000000;
  }
}

.place_order_btn {
  background-color: $GREEN !important;
  width: 100%;
  border-radius: 50px !important;
  padding: 10px !important;
}

//middle banner

.restaurant_details_middle_banner {
  padding: 130px;
  background: url("../images/green_overlayed_restaurants_banner.jpg") no-repeat;
  background-size: cover;
  margin: 30px 0px 20px;

  h1,
  h5,
  p {
    color: $WHITE;
  }

  h1 {
    font-weight: 800;
    font-size: 50px;
  }
}

.restaurant_add_on_dialog_paper {
  width: 80vw !important;
  max-width: 840px !important;
  border-radius: 35px !important;
  -ms-overflow-style: none !important; // Hide scrollbar for Internet explorer
  scrollbar-width: none !important; // Hide scrollbar for Firefox
}

.restaurant_add_on_dialog_paper::-webkit-scrollbar {
  display: none !important;
  /* Hide scrollbar for Safari and Chrome */
}

.restaurant_lsn_wrapper {
  margin-top: 60px;
  margin-bottom: 100px;

  @media screen and (max-width: 600px) {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  @media screen and (max-width: 300px) {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

.restaurant_details_lsn_heading {
  font-family: $MONTSERRAT !important;
  font-size: 38px !important;
  font-weight: 500;
  color: #333333;
  margin-bottom: 30px;
  text-align: center;
}

.restaurant_donate_generate {
  font-size: 20px;
}

.restaurant_donate_gain_amount {
  font-size: 45px;
  text-shadow: 1.5px 0px #ffffff;
  letter-spacing: 1px;
  font-weight: 700;
}

.restaurant_donate_raise_amount {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  text-shadow: 0.8px 0px #ffffff;
  letter-spacing: 1px;
}

.restaurant_donate_btn {
  border-radius: 45px !important;
  background-color: #ffa700 !important;
  padding: 12px 25px !important;
  margin-top: 10px !important;
}

.restaurant_donate_btn_label {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #ffffff !important;
}

.restaurant_details_address_root {
  border-radius: 12px !important;
  background-color: #ffffff !important;
  padding: 0 !important;
  border: none !important;
  outline: none !important;

  fieldset {
    top: 0px !important;
  }
}

.address_loader_box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 10px 0px;
  background-color: #fff;

  .address_circular_loader {
    color: $GREEN;
  }
}

.address_list_container {
  border-radius: 8px;
}

.restaurant_details_address_input {
  padding: 12px 14px !important;
  height: 100% !important;
}

.menu_item_disable_btn {
  svg {
    color: $GRAY_SHADE1 !important;
  }
}

.res_details_more_btn_container {
  display: flex;
  justify-content: center;

  button {
    padding: 8px 30px !important;
    border-radius: 12px !important;
    border: solid 1px rgba(32, 162, 88, 0.3) !important;

    span {
      text-transform: none;
      font-family: $MONTSERRAT;
      font-size: 14px;
      font-weight: 500;
      color: #20a258;
    }
  }
}
