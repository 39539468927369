@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.cdd_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  border-top: 3px solid #f9f8fd;

  svg {
    color: $GREEN;
  }
}

.cdd_content_container {
  padding: 20px 60px 30px;

  & > p {
    font-family: $MONTSERRAT;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: rgba(51, 51, 51, 0.5);
  }

  .cdd_separator {
    height: 5px;
    margin: 25px 0 15px 0;
    border-radius: 4px;
    background-color: #f9f8fd;
  }

  .cdd_comments_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 15px 0px;
  }

  .cdd_add_comment_wrapper {
    border: 1px solid $GRAY_SHADE1;
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    margin: 20px 0px 15px;

    textarea {
      width: 100%;
      resize: none;
      border: none;

      &:focus {
        border: none !important;
        outline: none !important;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 20px 20px 30px;

    & > p {
      font-size: 15px;
    }
  }
}
