@import "../../../../web/src/assets/css/constants/base/global.scss";
@import "../../../../web/src/assets/css/constants/base/colors.scss";
.mainHeader {
  &.farmHeader {
    box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.75);
  }
  margin-bottom: 2px;
  & .heading {
    border-left: 2px solid $GREEN;
    padding-left: 20px;

    & h5 {
      font-family: $ROUNDGOTHIC_MEDIUM;
      font-weight: 700;
    }
  }

  & .courseProgress {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    & p {
      font-weight: 600;
    }

    & .progressBarContaine {
      position: relative;

      & .MuiCircularProgress-root {
        color: $GREEN !important;
      }
      & .progresValue {
        position: absolute;
        top: 30%;
        left: 30%;
      }
    }
  }

  & .rating {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    & p {
      font-weight: 600;
    }
  }
}
