@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.farm_details_top_section_wrappper {
  margin-top: 20px;

  .farm_details_btn_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    .farm_view_product_btn {
      background-color: $GREEN;
      padding: 7px 30px;
      border-radius: 24px;
      text-transform: none;

      span {
        font-weight: 600;
        font-size: 16px;
        font-family: $MONTSERRAT;
        color: #fff;
      }
    }
  }
}

.Farm_Img_render_Wrapper {
  width: 100%;

  img {
    width: 100%;
    height: 500px;
  }
}

.farm_details_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .farm_name_fac_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow-x: hidden;

    h3 {
      font-family: $ROUNDGOTHIC_BOOK !important;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      padding: 6px;
    }
  }

  .farm_social_img_wrapper {
    display: flex;
    align-items: center;
    gap: 15px;

    .farm_social_img {
      height: 25px !important;
      width: 25px !important;
    }
  }
}

.farm_certified_tags_container {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 5px 0px 10px;
  min-height: 25px;

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.farm_details_review {
  margin-top: 70px !important;
}

.farm_details_context {
  color: #5a5a5a;
  font-family: $MONTSERRAT !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.89;
  overflow-y: auto;
  max-height: 250px;
  margin-bottom: 10px;
  padding-right: 20px;
  word-wrap: break-word;

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d3d3d3;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $GREEN;
    border-radius: 10px;
    opacity: 0.5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $GREEN;
    opacity: 1;
  }
}

.farm_details_review_heading {
  h3 {
    font-family: $MONTSERRAT !important;
    margin-bottom: 2px;
    margin-right: 5px;
    font-size: 20px;
    font-weight: 600;
    color: #000;

    @media (max-width: 600px) {
      font-size: 18px;
    }

    @media (max-width: 400px) {
      font-size: 16px;
    }
  }
}

.write_review_with_underline {
  color: #179d4e;
  font-size: 22px;
  text-decoration: underline;
  font-weight: 600;
  text-align: right;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
}

.review_submit_btn {
  background: $GREEN !important;
  border-radius: 30px !important;
  padding: 6px 50px !important;

  span {
    color: $WHITE;
    text-transform: none;
    font-size: 18px;
    font-family: $MONTSERRAT;
    font-weight: 500;
  }

  @media screen and (max-width: 600px) {
    padding: 6px 40px !important;

    span {
      font-size: 15px;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  border-radius: 50%;
  height: 45px;
  width: 45px;

  &::after {
    font-size: 20px;
    font-weight: 600;
  }
}

.swiper-button-prev,
.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.swiper-button-next {
  &:after {
    position: relative;
    left: 1px;
  }
}

.swiper-button-prev {
  &:after {
    position: relative;
    left: -1px;
  }
}

.popular_view_btn_container {
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
}

.farm_detail_review_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taskDescrip {
  margin: 10px 5px 18px 0;
  padding: 10px;
  border: #e1e1e1 solid 1px;
  border-radius: 10px;
  word-wrap: break-word;

  & p {
    color: rgba(0, 0, 0, 0.87) !important;
    line-height: 1.4 !important;
  }
}