@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';
.customDropdown {
  & .userAvtar {
    height: 40px;
    width: 40px;
    object-fit: contain;
    border-radius: 50%;
  }
  & .btnGroup {
    position: relative;
    cursor: pointer;
    &::before {
      content: ' ';
      position: absolute;
      right: -5px;
      top: 20px;
      border: 0px;
      border-left: 3px solid $GRAY_SHADE5;
      border-bottom: 3px solid $GRAY_SHADE5;
      border-top: 0px solid transparent;
      height: 10px;
      width: 10px;
      transform: rotate(-45deg);
      transition: transform 0.3s;
    }
  }

  & .btnGroupOpenMenu {
    &::before {
      transform: rotate(135deg);
    }
  }

  & .MuiButton-root:hover {
    background-color: transparent;
  }
}

.menu-customize {
  & .MuiPaper-rounded {
    border-radius: 10px;
  }
  & .MuiMenuItem-root:hover {
    background-color: #fff;
    color: $GREEN;
  }
  & .MuiMenuItem-root {
    text-transform: capitalize !important;
    font-family: $MONTSERRAT;
    font-size: 16px;
    letter-spacing: 0.11px;
    font-weight: 500;
    color: #000;
  }
}

.option-dropDown {
  width: 100%;
  .customDropdown {
    & .userAvtar {
      display: none;
    }
    & .MuiMenuItem-root {
      text-transform: capitalize;
    }
    & .btnGroup {
      position: relative;
      height: 30px;
      &::before {
        content: '...';
        position: absolute;
        text-align: center;
        left: 0px;
        top: -5px;
        border: 0px;
        overflow: hidden;
        color: $GRAY_SHADE5;
        font-size: 20px;

        font-weight: 900;
        height: 100%;
        width: 100%;

        transform: rotate(0deg);
      }
    }
    & .MuiButton-root:hover {
      background-color: transparent;
    }
  }
}
