@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";


.restaurant_details_wrapper_details {

    h1 {
        font-family: $ROUNDGOTHIC_MEDIUM !important;
        font-size: 72px;
        font-weight: 500;
        color: #333333;
    }
}
.get_direction {
    font-family: $MONTSERRAT !important;
    font-size: 20px;
    font-weight: bold;
    color: #20a258;
  }

  .h3 {
      font-family: $MONTSERRAT;
  }