.farm_popular_products_slider {

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
        background: rgba($color: #000000, $alpha: 0.7);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        border-radius: 50%;

        svg {
            color: #fff;
            font-size: 20px;
        }
    }

    .slick-prev {
        padding-right: 2px;

        @media screen and (max-width: 1300px) {
            left: -16px;
        }

        @media screen and (max-width: 800px) {
            left: -10px;
        }
    }

    .slick-next {
        padding-left: 2px;

        @media screen and (max-width: 1300px) {
            right: -16px;
        }

        @media screen and (max-width: 800px) {
            right: -10px;
        }
    }

    .slick-prev::before,
    .slick-next::before {
        display: none;
    }

    .custom-indicator {
        bottom: -65px;
    }

    .custom-indicator li {
        width: 50px;
        height: 50px;
        filter: grayscale(100%);
    }

    .custom-indicator li.slick-active {
        filter: grayscale(0%);
    }

    .multi__image:hover {
        transform: scale(1.1);
        transition: 500ms;
    }
}