@import "../../../../web/src/assets/css/constants/base/global.scss";

.Block2 {
  width: 60px;
}
.flex {
  display: flex;
  gap: 1rem;
  padding: unset;
  justify-content: unset;
  align-items: center;
}

.Course {
  font-size: medium;
  font-weight: bolder;
  columns: silver;
  display: flex;
}
.text {
  color: green;
  font-size: 22px;
  font-weight: bold;
}

.text-course {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
.box {
  margin: 2.5rem 0 0 2rem;
  border-radius: 10px;
}
.TextField {
  margin-top: 0.3rem;
}
.Box3 {
  display: flex;
  flex-direction: column;
  margin: 0.7rem 1.5rem;
  gap: 2rem;
}

.footer-button {
  display: flex;
  gap: 1rem;
}
.footer-btn01 {
  padding: 1.5rem;
}

.footer-btn02 {
  padding: 1.5rem;
}
.h5 {
  font-weight: bolder;
  color: #484242;
}
.delete {
  display: flex;
  justify-content: right;
  align-self: flex-end;
}

.MuiGrid-root.Block2.MuiGrid-item.MuiGrid-grid-xs-6 {
  max-width: 100%;
  flex-grow: 0;
  flex-basis: 70%;
}

.MuiGrid-root.MuiGrid-grid-xs-3 {
  max-width: 70%;
  flex-grow: 0;
  flex-basis: 0%;
}

.MuiBox-root.MuiBox-root-9.flex {
  justify-content: left;
  padding: 1.5rem 0 0 0;
  margin: 1rem;
}

p.MuiTypography-root.create-course.MuiTypography-body1 {
  font-family: $POPPINS;
}

span.MuiTab-wrapper {
  font-size: 18px;
  color: #bbbbbb;
  font-weight: 600;
}
