@import "../../../../../../web/src/assets/css/constants/base/colors.scss";

.chp_loader_container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;

    svg {
        color: $GREEN;
    }
}