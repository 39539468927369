@import "../../../../web/src/assets/css/constants/base/colors.scss";

.web_sidebar_link_view {
  padding: 20px 40px 40px;
}

.web_sidebar_link_view_header {
  border-bottom: 2px solid #dfe8e1;
  padding: 10px 0px 20px !important;
  font-size: 25px !important;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  h5 {
    font-size: 22px !important;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.wishlist_card_wrapper {
  border: 2px solid $GRAY_SHADE2;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
}

.product_name {
  padding: 5px 0px;
  font-size: 18px !important;
  font-weight: 600;
  text-transform: capitalize;
  color: $Black !important;
}

.product_grey_text {
  padding: 5px 0px;
  font-size: 16px !important;
  font-weight: 500;
  color: $GRAY_SHADE4;
  text-transform: capitalize;

  span {
    color: $GRAY_SHADE4;
    text-transform: capitalize;
  }
}

.view_addresses_icon_btn {
  background-color: $GRAY_SHADE2 !important;
  margin: 5px !important;
}

.product_price {
  color: $GREEN;
  font-weight: 600;
  font-size: 20px;
  padding: 5px 0px;
}

.product-review-bar {
  display: flex;
}

.review-star {
  padding-right: 5px;
}

.btn-buy-now {
  width: 100%;
  background-color: #20a258 !important;
  color: #fff !important;
  border-radius: 20px !important;
  margin: 10px 0px !important;
  padding: 5px 20px !important;
  text-transform: capitalize !important;
}

.btn-ord-now {
  width: 100%;
  color: #20a258 !important;
  border: 1px solid #20a258 !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  padding: 5px 20px !important;
}

.product_image_center {
  padding: 10px;
}

.product_image_wrapper {
  height: 100px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    object-fit: fill;
  }
}

@media (max-width: 599px) {
  .product_image_center {
    display: flex;
    justify-content: center;
  }

  .product_image_wrapper {
    display: flex;
    justify-content: center;
    height: 200px;

    img {
      object-fit: fill;
    }
  }
}

@media (max-width: 420px) {
  .web_sidebar_link_view_header {
    flex-direction: column;
  }
}

.cwp_courses_container {
  border-radius: 6px;
  border: solid 2px #f7f5f9;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}
