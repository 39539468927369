@import "../../../../web/src/assets/css/constants/base/";

.tableContainer {
  & .farmListsContainer {
    width: 100% !important;

    padding: 0 10px;

    .farmContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0px;
      width: 100%;

      border: 1px solid $GRAY_SHADE2;
      border-radius: 5px;
      padding: 10px 20px;

      h4 {
        font-size: 15px;
      }

      .option-dropDown {
        width: unset;
      }
    }
  }

  &.discussionTableContainer {
    height: auto;

    & .discussionTableHeader {
      width: 100%;
      padding: 40px 30px 20px 30px;
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: space-between;
      border-bottom: 2px solid #ccc;

      & .discussionHeading {
        font-weight: 600;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 15px 14px;
        display: flex;
        align-items: flex-start;
      }

      & .outlineSelect {
        width: auto;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      & .MuiSelect-select {
        width: 300px;
        min-width: auto !important;
        max-width: 250px !important;

        &::after {
          top: 15px;
        }
      }

      & .MuiAccordionSummary-content {
        margin: 0;
        padding: 20px 10px;
      }
    }

    & .discussionAccordian {
      box-shadow: none !important;

      &::before {
        display: none;
      }

      & .MuiAccordionSummary-root {
        padding: 10px 30px;
        margin-bottom: 10px;
        background-color: #f9f9f9;
      }

      & .MuiAccordionSummary-content {
        margin: 0 !important;
        padding: 0;

        & p {
          font-size: 20px;
        }

        & .MuiSvgIcon-root {
          fill: #394053;
        }
      }

      & .MuiAccordionDetails-root {
        p {
          width: 100%;
        }
      }

      & .MuiList-root {
        margin-right: 20px;

        @media screen and (max-width: 767px) {
          margin-right: 0;
        }

        & .MuiListItem-root {
          padding-bottom: 5px;
        }

        & .MuiListItem-root,
        & a {
          padding: 0;

          &:hover {
            background-color: transparent;
          }

          & .MuiTypography-root {
            color: #394053;
            font-size: 15px;
            font-weight: 600;

            @media screen and (max-width: 767px) {
              font-size: 13px;
            }
          }
        }

        & a {

          &:hover,
          &.active {
            & .MuiTypography-root {
              color: $GREEN;
            }
          }
        }

        & .badge {
          background-color: $GREEN;
          padding: 7px;
          color: #fff;
          border-radius: 50%;
          height: 25px;
          width: 25px;
        }
      }
    }

    & .discussionBody {
      overflow-y: scroll;
      height: 565px;
      @include scrollbars();

      @media screen and (max-width: 768px) {
        height: 200px;
        border: 1px solid #4aa561;
        border-radius: 10px;
        margin: 30px 10px;
      }
    }

    & .chatNoDiscussionData {
      margin: 20px;
    }

    & .leftBox {
      border-right: 2px solid #ccc;
      margin-top: 8px;
      padding: 0;
    }

    & .chatWrapper {
      & .chatBody {
        padding: 30px 15px 0 15px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
        overflow-wrap: break-word;
        @include scrollbars();
        max-height: 565px;

        @media screen and (max-width: 768px) {
          border: 1px solid #b6bcc5;
          margin: 10px;
          border-radius: 10px;
          padding: 5px;
        }

        & .receiveChatBodyBox {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        & .sentChatBodyBox {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        & .chatBox {
          display: flex;
          flex-direction: column;
          padding: 20px;
          border-radius: 10px 0 10px 10px;
          margin-bottom: 20px;
          max-width: 700px;
          width: 700px;

          @media screen and (max-width: 768px) {
            width: 100%;
            padding: 8px;
          }

          & h3 {
            font-weight: 600;
            color: #0e0e0e;
            font-size: 20px;

            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }

          p {
            font-size: 17px;
            margin: 15px 0;

            @media screen and (max-width: 767px) {
              font-size: 13px;
            }
          }

          & span {
            color: #909090;
            font-weight: 600;
            font-size: 15px;

            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }

          & .chatRow {
            display: flex;
            margin: 0;
            justify-content: space-between;
            width: 100%;
            border-radius: 10px;
          }

          & .chatBottomRow {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            & .customButton {
              margin-bottom: 0 !important;

              & .MuiButton-root {
                padding: 0;
                max-width: 100px;
                height: 33px;
              }
            }

            & .DelIcon {
              height: 30px;
              width: 30px;
              object-fit: contain;
            }

            & .DelButton {
              padding: 0;

              &:hover {
                background-color: transparent;
              }
            }

            & .greenOutlineButton {
              & .MuiButton-root {
                border-radius: 30px;
                border-width: 2px;
                background-color: transparent;
                padding: 0;

                & .MuiButton-label {
                  padding: 0;

                  & span {
                    color: $GREEN;
                  }
                }
              }
            }
          }

          &.receivedChatBox {
            margin-left: 100px;
            background-color: #f5f5f5;

            @media screen and (max-width: 768px) {
              margin-left: 0;
            }
          }

          &.sentChatBox {
            background-color: #edf4ed;
            margin-right: 100px;

            @media screen and (max-width: 768px) {
              margin-right: 0;
            }

            & .chatBottomRow {
              justify-content: flex-start;
            }
          }
        }
      }

      & .chatFooter {
        display: flex;
        padding: 15px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        & .greenContained {
          margin-left: 20px;

          @media screen and (max-width: 767px) {
            margin-top: 20px;
            margin-left: 0;
          }

          & .MuiButton-root {
            padding: 5px 20px;
            border-radius: 30px;
            border-color: #c3c3c3;

            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }

        & .MuiFormControl-root {
          border-radius: 30px;
        }
      }

      & .chatEditForm {
        display: flex;
        padding: 5px;
        border: 1px solid #97b7ab;
        border-radius: 5px;
        align-items: center;
        margin-bottom: 40px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }

        & .customButton {
          margin-bottom: 0 !important;
        }

        & .outlineTextfield {
          background-color: transparent !important;
        }

        .MuiFormControl-root {
          border-radius: 5px;
        }

        & .greenContained {
          margin-left: 20px;

          @media screen and (max-width: 767px) {
            margin-top: 20px;
            margin-left: 0;
          }

          & .MuiButton-root {
            padding: 5px 20px;
            border-radius: 5px;

            & span {
              color: $WHITE;
            }

            &:hover {
              & span {
                color: $GREEN;
              }
            }

            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }
    }

    & .discussionComment {
      & .MuiInputBase-root {
        width: 100%;
      }
    }
  }
}