@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.cd_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;

  svg {
    color: $GREEN;
  }
}

.cd_topics_main_wrapper {
  overflow: hidden;
  position: relative;
}

.cd_topics_container {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  padding: 0px 15px;
  cursor: pointer;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 7px;

  &::-webkit-scrollbar {
    height: 9px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #d3d3d3;
  }

  .cd_topic {
    padding: 0px 10px;

    & > p {
      padding-bottom: 10px;
      opacity: 0.5;
      font-family: $POPPINS;
      font-size: 16px;
      font-weight: 500;
      color: #000;
      text-align: center;
      min-width: 110px;
    }

    & > div {
      margin-bottom: -5px;
      height: 5px;
      border-radius: 3px;
    }
  }

  .cd_topic_active {
    & > p {
      opacity: 1;
    }

    & > div {
      background-image: linear-gradient(to right, #7fb886 -19%, #35a042 109%);
    }
  }
}

.cd_topics_line {
  position: absolute;
  left: 0;
  bottom: 11px;
  width: 100%;
  height: 5px;
  border-radius: 4px;
  background-color: #f9f8fd;
  z-index: -1;
}
