@import "../../../../web/src/assets/css/constants/base/mixins.scss";

.policy_wrapper {
  padding: "30px 40px 0px 30px";

  @media screen and (max-width: 768px) {
    padding: "30px 40px 0px 30px";
  }
}

.policy_container {
  height: 60vh;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    margin-top: 25px;
    max-height: 55vh;
  }
}

.policy_tab_box {
  padding: 42px;

  & .MuiTabs-root {
    border-bottom: 1px #dcd6d6 solid;

    & .MuiButtonBase-root {
      &:before {
        background-color: #20a258;
      }
    }

    & .MuiTab-wrapper {
      color: #2a2525;
    }
  }

  & .PrivateTabIndicator-colorSecondary-25 {
    background-color: #20a258;
  }

  & .MuiTabs-indicator {
    background-color: #20a258;
  }

  @media screen and (max-width: 768px) {
    padding: 20px;

    & .MuiTab-wrapper {
      font-size: 11px;
    }
  }

  & .tabPanel {
    & .MuiBox-root {
      overflow-y: scroll;
      @include scrollbars();

      @media screen and (max-width: 1920px) {
        max-height: 500px;
      }

      @media screen and (max-width: 1024px) {
        max-height: 450px;
      }

      @media screen and (max-width: 768px) {
        max-height: 350px;
      }

      @media screen and (max-width: 375px) {
        max-height: 250px;
      }
    }
  }
}
