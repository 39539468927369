@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
.educationCourseDetailWapper {
  // height: 100%;
  background-color: $WHITE;
  width: 100%;
  padding-top: 30px;
  & .commonSection {
    margin-bottom: 70px;
  }
  & .nav {
    padding: 20px 0 0 20px;
    @media screen and (max-width: 1024px) {
      padding: 0;
    }
    & li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 10px;
      }
      & img {
        height: 25px;
        width: 25px;
      }
      & p {
        margin: 0;
        padding-left: 10px;
      }
    }
  }
  & .subContentWrapper {
    max-width: 1450px;
    margin: 0 auto;
    padding: 10px;
  }
  & .publicSpeakingSection {
   display: flex;
   flex-direction: column;
    align-items: center;
    & h1 {
      font-size: 40px;
      font-weight: 100;
      font-family: $ROUNDGOTHIC_MEDIUM;
      @media screen and (max-width: 1024px) {
        font-size: 30px;
      }
    }
    & p {
      text-align: center;
      margin: 30px 0;
      font-weight: 600;
      font-size: 16px;
      opacity: 0.9;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    & .videoBlock {
      & iframe {
        width: 600px;
        height: 400px;
        border-radius: 20px;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
  & .enrollSection {
    & .courseCard {
      padding: 40px 25px;
      @media screen and (max-width: 768px) {
        padding: 10px;
      }
      & .cardContent {
        display: flex;
        @media screen and (min-width: 600px) and (max-width: 1024px) {
          flex-direction: column;
          align-items: center;
        }
        & img {
          height: 50px;
          width: 50px;
        }
        & h3 {
          font-weight: 800;
          color: $DARK;
          @media screen and (max-width: 1440px) {
            font-size: 16px;
          }
        }
        & p {
          padding-top: 0;
          font-size: 14px;
          color: $DARK_SHADE1;
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
        & .rightBlock {
          padding-left: 20px;
          @media screen and (min-width: 600px) and (max-width: 1024px) {
            padding-left: 0;
            padding-top: 20px;
            text-align: center;
          }
        }
      }
    }
    & .greenOutlineButton {
      height: calc(100% - 30px);
      width: 100%;
      height: 100%;

      @media screen and (max-width: 1024px) {
        height: calc(100% - 10px);
      }
      & .MuiButtonBase-root {
        height: 100%;
        width: 100%;
        background: transparent;
        background-image: url("../images/farm-banner.jpg");
        background-size: cover;
        position: relative;
        border: 0;
        @media screen and (max-width: 1024px) {
          padding: 50px;
        }

        & .MuiButton-label {
          & span {
            font-weight: 600;
            font-size: 30px;
            color: $WHITE;
            z-index: 2;
            @media screen and (max-width: 1024px) {
              font-size: 18px;
            }
          }
        }
        &::before {
          content: " ";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(89, 189, 131, 0.5);
          z-index: 1;
          border-radius: $FORM_BORDER_RADIUS;
        }
      }
      & .customButton {
        height: 100%;
      }
    }
  }
  & .trainerSection {
    & .trainerBlock {
      display: flex;
      justify-content: center;
    }
    & .trainerImg {
      border-radius: 20px;
      border: 1px solid $GRAY_SHADE10;
      width: 400px;
      height: 600px;
      object-fit: cover;
      @media screen and (max-width: 1024px) {
        width: 400px;
        height: 400px;
      }
    }
    & .serviceImg {
      width: 500px;
      height: 500px;
      object-fit: cover;
      @media screen and (max-width: 767px) {
        width: 300px;
        height: 300px;
      }
    }
    & .trainerRightBlock {
      width: 70%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      //   background-color: brown;
      height: 100%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      & h1 {
        font-family: $MONTSERRAT;
        color: $GREEN_SHADE7;
        font-size: 45px;
        font-weight: 100;
        line-height: 70px;
        @media screen and (max-width: 1024px) {
          font-size: 24px;
          line-height: 40px;
        }
      }
      & p {
        color: $GREEN_SHADE7;
        font-size: 15px;
        font-weight: 100;
        margin: 20px 0;
        word-spacing: 5px;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
          margin: 5px 0;
        }
      }
    }
  }
  & .buyCourseSection {
    & .nav {
      display: flex;
      justify-content: center;
      padding: 0;
      & li {
        width: 100%;
        padding: 10px 80px;
        margin: 10px 0;
        justify-content: flex-start;
        white-space: nowrap;
        @media screen and (max-width: 1024px) {
          padding: 10px;
        }
        &:nth-child(even) {
          background-color: $DANGER4;
        }
      }
      &.priceNav {
        & li {
          justify-content: center;
        }
        & .MuiSvgIcon-root {
          height: 0.9em;
          width: 0.9em;
          &.cancelIcon {
            color: $GRAY_SHADE10;
          }
        }
      }
    }

    & .classesCard {
      & .courseCard {
        justify-content: center;
        height: calc(100% - 156px);
        @media screen and (max-width: 767px) {
          height: auto;
        }
      }
    }
    & .advancedCard {
      // padding-left: 20px;
      @media screen and (max-width: 767px) {
        padding-left: 0px;
      }
    }

    & .card {
      & .cardContent {
        max-height: 550px;
        border: 1px solid $GRAY_SHADE3;
      }
      & .cardHeader {
        width: 100%;
        min-height: 100px;
      }
      &.courseCard {
        box-shadow: none;
        height: 100%;
        // border: 1px solid black;
        padding: 0;
        & .cardHeader,
        & .cardFooter {
          width: 100%;
          text-align: center;
          border: 1px solid $GRAY_SHADE3;
          & h5 {
            color: $Black;
          }
        }

        & .cardHeader {
          padding: 50px 0;
          border-bottom: 0;
          border-top-left-radius: $BOX_RADIUS;
          border-top-right-radius: $BOX_RADIUS;
          position: relative;
          & .priceCheckbox {
            position: absolute;
            top: 10px;
            right: 10px;
          }
          &.active {
            background-color: $GREEN_SHADE8;
            & h5 {
              color: $WHITE;
            }
          }
        }
        & .cardFooter {
          padding: 20px 0;
          border-top: 0;
          border-bottom-left-radius: $BOX_RADIUS;
          border-bottom-right-radius: $BOX_RADIUS;
          & .bottomContent {
            display: flex;
            justify-content: center;
            align-items: baseline;
            margin-top: 10px;
            & span {
              margin-right: 20px;
            }
          }
        }
        &.details {
        //  display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
          .cardContent {
            margin-top: 125px;
            // background-color: palegreen;
            border-top-left-radius: $BOX_RADIUS;
            border-bottom-left-radius: $BOX_RADIUS;
            border-right: none;
            @media screen and (max-width: 1024px) {
              margin-top: 120px;
            }
          }
        }
        &.basicPrice {
        //  display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
        }
        &.adVancePrice {
        //  display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
          padding-left: 10px;
        }
        & .monthContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $GREEN_SHADE8;
          border-radius: 10px;
          width: 80%;
          padding: 10px 20px;
          margin: 10px auto;
          & .rightBlock {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
          & h5,
          & h6,
          & span {
            color: $WHITE;
          }
          & h6 {
            font-size: 20px;
          }
          & span {
            font-size: 14px;
          }
        }
        & .greenOutlineGrayButton {
          & .MuiButton-root {
            border: 1px solid #c3c3c3;
            background-color: transparent;
            width: 80%;
            padding: 15px 20px;
          }
        }
      }
    }
  }
  & .studentCardSection {
    margin: 0;
    background-color: rgb(254, 250, 238);
    padding: 100px 0;
    @media screen and (min-width: 768px) {
      padding: 100px 30px;
    }
    .studentCardHeader {
      width: 100%;
      margin-bottom: 50px;
      h3 {
        position: relative;
        z-index: 2;
        font-family: $ROUNDGOTHIC_MEDIUM;
        font-size: 26px;
        & span {
          .MuiSvgIcon-root {
            font-size: 90px;
            color: $ORANGE;
            transform: rotate(180deg);
            position: absolute;
            top: -45px;
            left: -47px;
            z-index: -1;
          }
        }
      }
    }
    .cardContainer {
      .MuiGrid-item {
        padding: 6px 30px;
        @media screen and (min-width: 768px) {
          &:nth-child(2) {
            margin-top: 10px;
          }
          &:nth-child(5) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

#input-with-searchIcon-textfield::placeholder{
  font-size: 14px;
  font-family: $MONTSERRAT;
  color: #9098b1;
}