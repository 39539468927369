@import "../../../../web/src/assets/css/constants/base/colors";
@import "../../../../web/src/assets/css/constants/base/global";

.formcreate {
  width: 100%;

  background-color: $WHITE;
  border-radius: $BORDER_RADIUS;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $GRAY_SHADE3;
    padding: 40px 20px 30px 20px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .leftSideHeader {
      width: 100%;
      display: flex;
      align-items: center;
      
      justify-content: flex-start;
      .MuiSvgIcon-root {
        font-size: 30px;
      }
      h4 {
        font-weight: 900;
        // font-size: 35px;
        margin-left: 5px;
      }
    }
    .rightSideHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;

      .campaign_delete_icon {
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
        margin-top: 20px;
        justify-content: space-between;
      }
      .green-outline {
        width: 150px;
        & .MuiSvgIcon-root {
          font-size: 25px;

          color: $GREEN;
        }
      }
      & .MuiSvgIcon-root {
        font-size: 40px;
        color: rgb(223, 37, 37);
      }
    }
  }
  .MainWrapper {
    padding: 40px 20px 30px 20px;
    // background-color: green;
    margin-bottom: 30px;
    width: 100%;

    overflow: hidden;
    & .imagee {
      position: relative;
      //   background-color: red;
      .image {
        width: 100%;
        height: 250px;
        img {
          border-radius: $FORM_BORDER_RADIUS;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .editBannerBtn {
        position: absolute;
        top: 10px;
        right: 20px;
        background-color: $WHITE;
        border-radius: $FORM_BORDER_RADIUS;
        padding: 5px 20px;
        span {
          color: black;
        }
      }
    }
    & .ResponseContainer {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: space-between;
      }
      & .campig {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & .likeComments {
          .MuiSvgIcon-root {
            color: blue;
            font-size: 30px;
          }
          .count {
            color: black;
            margin: 0 5px;
            font-size: 20px;
            font-weight: 700;
          }
          & span {
            color: $GRAY_SHADE6;
          }

          
        }
      }
      & .buttonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media screen and (max-width: 768px) {
          margin-top: 20px;
          justify-content: center;
          width: 100%;
        }
        .MuiButtonBase-root {
          background-color: $GREEN;
          border-radius: $FORM_BORDER_RADIUS;
          padding: 10px 30px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          span {
            color: $WHITE;
          }
        }
      }
    }
    & .HeaderDescription {
      width: 100%;
      margin: 20px 0;
      h4 {
        margin: 10px 0;
        font-size: 17px;
        // color: rgb(0, 0, 0);
        color: black;
        // background-color: green;
      }
    }
    .Donation{
      color: green;
      margin-bottom: 15px;
    }
    .Raised{
      margin-left: -1020px;
      text-align: left;
    }
    .ReqAmount{
      display: flex;
      flex-direction: row;
      // background-color: green;
    }
    .h5{
      color: green;
    }
    .ReqAmountCreate{
      display: flex;
      flex-direction: column;
    }
    .editbanner{
      // background-color: black;
      // margin-left: 1050px;
      // // color: black;
      // margin-bottom: -60px;
      // text-decoration: black;
      position: absolute;
      top:20px;
      right:10px
    }
    .editforum{
      background-color: white;
      text-decoration-color: black;
      color: black;
    }
            }
          }

.adminRestDishesContainer .customSelect.farmOrdersDropDown 
          .MuiSelect-select::before, .commonDropdown 
          .customSelect.farmOrdersDropDown.restForum .MuiSelect-select::before{
            content : "Restaurant:" !important;
}
        
.adminRestDishesContainer .customSelect.farmOrdersDropDown 
          .MuiSelect-select::before, .commonDropdown 
          .customSelect.farmOrdersDropDown.dishesType .MuiSelect-select::before{
            content : "Show:" !important;
}
      
    
  

