.res_timing_wrapper {
    margin: 15px 15px 20px 15px !important;
    border-radius: 13px;
    background-color: #f1f1f1;
}

.availSettingHeaderWrapper {
    border-bottom: 2px solid #949494;
    display: flex;
    justify-content: space-between;
    padding: 20px !important;
}

.availSettingContentWrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px !important;
}

.availSettingCustomTime {
    .MuiFormControl-root {
        background-color: #fff;
        border-radius: 7px;
        border: solid 1px #abafb3;
    }
    .MuiInputBase-input {
        @media screen and (max-width: 560px) {
            font-size: 12px;
        }
    }
}

.availSettingRemove {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
        color: #464a53;
    }
}