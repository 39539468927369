@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.reply_header {
  display: flex;
  justify-content: space-between;

  h5 {
    color: $Black;
    font-weight: 600;
    font-family: $MONTSERRAT;
    font-size: 20px;
  }
  p {
    font-family: $MONTSERRAT;
    font-size: 14px;
    font-weight: 500;
    color: #5a5a5a;
  }
}

.reply_edit_textfield {
  margin-top: 10px !important;
  & input {
    color: #5a5a5a !important;
    font-family: $MONTSERRAT !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    border-radius: 20px;
    padding: 10px 12px;
    border: 1px solid #c3c3c3 !important;
    background-color: #fff;

    @media screen and (max-width: 450px) {
      font-size: 14px !important;
    }
  }
}

.reply_edit_delete_wrapper {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 15px;
  @media screen and (max-width: 450px) {
    gap: 10px;
  }
}

.delete_reply {
  border: 1px solid red !important;
  border-radius: 50% !important;
  min-width: 0 !important;
  line-height: normal !important;
  width: 36px !important;
  
  img {
    height: 20px !important;
    width: 20px !important;
    margin: 0px !important;
    @media screen and (max-width: 450px) {
      height: 18px !important;
      width: 20px !important;
    }
  }

  svg {
    color: red !important;
  }

  @media (max-width: 413px) {
    width: 100%;
  }
}

.edit_reply {
  border: 1px solid #3bad6c !important;
  border-radius: 25px !important;

  span {
    color: #3bad6c !important;
    padding: 0px 30px;
    text-transform: none;
    font-weight: 600;
    @media screen and (max-width: 450px) {
      padding: 0px 10px;
      font-size: 12px;
    }
    @media screen and (max-width: 560px) {
      padding: 0px 10px;
      font-size: 12px;
      border-radius: 5px;
    }
  }
}

.cancel_reply {
  border-radius: 25px !important;
  background-color: #fff !important;
  border: 1px solid #c3c3c3 !important;
  @media screen and (max-width: 560px) {
      padding: 0px !important;
      height: 35px;
      margin: 10px 10px 0 0 !important;
  }

  span {
    color: #000000 !important;
    padding: 0px 25px;
    text-transform: none;
    font-weight: 500;
    @media screen and (max-width: 450px) {
      font-size: 12px !important;
      padding: 0px 10px !important;
    }
  }

}

.save_reply {
  border-radius: 25px !important;
  background-color: #3bad6c !important;
  @media screen and (max-width: 560px) {
    height: 35px;
    padding: 0 !important;
    margin: 10px 10px 0 0 !important;
  }

  span {
    color: $WHITE !important;
    padding: 0px 30px;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
    @media screen and (max-width: 450px) {
      font-size: 12px !important;
      padding: 0px 10px !important;
    }
  }

}

.reply_profile_img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 1px solid #979797;
    margin-right: 20px;

    @media screen and (max-width: 600px) {
      width: 38px;
      height: 38px;
    }
}