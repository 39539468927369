@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";


.contact_container_wrapper {
  padding: 80px 0 100px 0;
   @media screen and (max-width: 480px) {
      padding-top: 40px;

  }
}

.contact_right_container {
  .green_small_heading {
    font-family: $ROUNDGOTHIC_BOOK !important ;
    font-size: 25px;
  }
  h3 {
    font-family: $ROUNDGOTHIC_BOOK !important;
    letter-spacing: 1px;
    font-size: 1cm;
  }
 
}

.contact_container{
  & .MuiFilledInput-underline{
    &:hover{
  &::before ,
  &::after{
      border-bottom: none;
      border-bottom: none;
      
    }
  }
   &::before ,
  &::after{
      border-bottom: none;
      border-bottom: none;
      
    }
  }
}

.contact_us_text_area {
  width: 100% !important;
  border: none !important;
  outline: none;
  background-color: #f6f6f6 !important;
  resize: none;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px ;
  font-weight: 600 !important;
  color: $GRAY_SHADE6;
   &::placeholder{
    color: #adb0b8;

   }
}

.contact_us_heading {
  color: $GREEN;
  font-size: 18px !important;

  font-weight: 600 !important;
  margin: 15px 0px !important;
}

.contact_us_title {
  font-size: 30px !important;
  margin: 15px 0px !important;
}

.contact_us_text {
  margin: 15px 0px 0px !important;
}
.map_image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  min-height: 450px;
  background: url(../map.png) no-repeat;
  background-size: cover;
  background-position: center;
  @media screen  and (max-width: 768px){
    min-height: 320px;
  }
}
.contact_us_btn {
  background-color: $GREEN !important;
  padding: 10px 20px !important;
  border-radius: 50px !important;
  padding: 15px 20px !important;
  font-weight: 900 !important;
}
.btn-label {
  text-transform: capitalize;
}



