@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';
.breadCrumbsrow {
    margin-bottom: 10px;
    & .MuiBreadcrumbs-li
    {
        & a
        {
            color: $DARK;
        }
        & .MuiTypography-body1
        {
            color: $GREEN;
            font-weight: 500;
        }
    }
    & .MuiBreadcrumbs-separator {
        font-size: 35px;
        margin-bottom: 5px;
    }
}
