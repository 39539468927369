@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,500;1,700&family=Righteous&display=swap" rel="stylesheet');
@import './constants/base/global.scss';
@import './constants/base/colors.scss';
@import './constants/base/mixins.scss';

@import '../../assets/css/content/dropdown.scss';
@import '../../assets/css/content/card.scss';
@import '../../assets/css/content/table.scss';
@import '../../assets/css/content/chart.scss';
@import '../../assets/css/content/utility.scss';

body,
html {
  background-color: $WHITE !important;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: $MONTSERRAT;
  overflow: hidden;
  @include scrollbars();
}

// common css
input[type='radio'],
input[type='checkbox'] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: $DARK;
  font-family: $MONTSERRAT;
}

.h1 {
  color: $DARK;
  font-size: 30px;
  font-weight: 600;
  padding-right: 10px;

  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
}

h2 {
  color: $DARK;
  font-size: 26px;
  font-weight: 100;
  font-family: $RIGHTEOUS;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
}

h4 {
  color: $DARK;
  font-size: 22px;
  font-weight: 600;
  font-family: $MONTSERRAT;

  @media screen and (max-width: 1024px) {
    font-size: 19px;
  }
}

.h6 {
  color: $GRAY_SHADE3;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
}

span {
  color: $GRAY_SHADE1;
  font-family: $MONTSERRAT;
}

p {
  font-family: $MONTSERRAT;
  font-size: 14px;

  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
}

a {
  text-decoration: none;
  font-family: $MONTSERRAT;
}

button,
a,
label {
  margin-bottom: 0;
  font-family: $MONTSERRAT;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: $MONTSERRAT;
}

.mainWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #e3e9f6;
  overflow: hidden;
}

.contentWrapper {
  width: 100%;
  overflow-y: auto;
  flex: 1;
  display: flex;
  padding: 20px 0;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 8px 0;
  }

  & .subContentWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    background-color: #e3e9f6;
    border-radius: 20px;
    @include scrollbars();

    @media screen and (max-width: 1024px) {
      margin-left: 0;
    }

    @media screen and (max-width: 480px) {
      border-radius: 12px;
    }
  }
}

// ================= FORM CONTROLS =================
.btn {
  &:focus {
    border: 0;
    box-shadow: none;
  }

  &.dropdown-toggle {
    padding: 0;
    position: relative;

    &::after {
      border: 0;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      margin-left: 10px;
      border-color: $GRAY_SHADE4;
    }
  }
}

.searchIcon {
  color: $GRAY_SHADE4;
}

.form-group {
  position: relative;
  width: 100%;

  .form-control {
    background-color: #f0f0f0;
    border: 0;
    padding: 12px;
    padding-left: 45px;
    border-radius: 10px;
    color: $GRAY_SHADE3;

    &::placeholder {
      color: $GRAY_SHADE3;
    }

    &:focus {
      background-color: $GRAY_SHADE2;
      box-shadow: none;
    }
  }
}

.form-select {
  background-color: $GREEN;
  color: $WHITE;
  background-image: none;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-menu {
  border-radius: 10px;

  & .dropdown-item:focus,
  & .dropdown-item:hover {
    background-color: $GREEN_SHADE2;
  }
}

// chart css
.chartContainer {
  margin-bottom: 25px;

  @media screen and (max-width: 1024px) {
    margin: 10px 0 25px 0;
  }

  & h5 {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }

  & .chartCard {
    width: 100%;

    & .charthead {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    & .listMenu {
      display: flex;

      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }

      & .listItems {
        margin: 0 15px;
        position: relative;

        & span {
          margin-left: 5px;
          color: $DARK_SHADE1;
          font-size: 12px;
        }

        &::before {
          content: ' ';
          position: absolute;
          width: 13px;
          height: 15px;
          left: -15px;
          top: 5px;
          border-radius: 50%;
        }

        &.primary {
          &::before {
            background-color: $SKYBLUE_SHADE3;
          }
        }

        &.success {
          &::before {
            background-color: $GREEN;
          }
        }
      }
    }
  }
}

.farms_all_pages_container {
  width: 100vw;
  height: auto;
  background-color: $WHITE;
  padding-top: 30px !important;
  padding-bottom: 70px !important;

  @media only screen and (max-width: 600px) {
    padding-bottom: 30px !important;
    padding-top: 10px !important;
  }
}