@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
@import "../../../../web/src/assets/css/constants/base/mixins.scss";
$testBg: "../group-10@2x.png";

.about_section_wrapper {
  background-color: white !important;
  height: auto;
  padding-top: 20px !important;
}

.aboutUsHeading{
  h3 {
    font-size: 38px;
    font-family: $MONTSERRAT !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
    
  }
}
.team_section_wrapper {
  padding-top: 20px;
  padding-bottom: 60px;
}

.aboutus_ourteam_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.green_small_heading {
  color: $GREEN;
  font-weight: bold;
  font-size: 16px;
}

.aboutus_tabs {
  width: 65%;
  display: flex;
  border-bottom: 3px solid $GREEN;

  span {
    width: 33.33%;
    color: black;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }
}

.first_vegi_img_wrapper {
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

}
.vision_wrapper{
  width: 90%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
.heading_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    color: #333;
    line-height: 1.68;
    font-size: 30px;
    text-align: center;
  }
}

.about_us_our_team_cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .formerImg {
    width: 300px;
    height: 300px;
    object-fit: contain;
    border-radius: 10px;
  }
}

.team_card_content {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  h3 {
    color: $GREEN;
    font-size: 21px;
    font-weight: 800;

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
}

.service_section_wrapper {
  background-color: $ORANGE;
  padding-top: 40px;
  padding-bottom: 40px;
}

.service_section_card_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1,
  p {
    color: white;
  }

  h1 {
    font-weight: 600;
  }

  p {
    margin-left: 20px;
    text-transform: uppercase;
  }
}

.achivement_batch_section_wrapper {
  padding-bottom: 70px;
  padding-top: 70px;
  background-color: $WHITE;
}

.batch_img_wrapper {

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;

    @media screen and (max-width: 767px) {
      width: 45px;
      height: 45px;
      object-fit: contain;
    }
  }
}

.aboutus_testimonial_section {
  background: url($testBg) no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;
  min-width: none;
  object-fit: contain;
  @media screen and (min-width: 1125px) {
    background-position: 100% 100%;
  position: relative;

  .testimonial_old_home {
    position: absolute;
    width: 194.2px;
    height: 181px;
    object-fit: contain;
    left: 0;
    bottom: 0;
  }
}

.aboutus_testimonial_card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  background-color: white;
  width: 90%;
  padding: 50px 40px 25px 45px;
  border-top-left-radius: 80px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.testimonial_content {
  display: flex;
  gap: 15px;
  padding: 10px 0px;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  div {
    width: 100%;
    padding-right: 35px;
    h6 {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 4px;
      color: #333;
      font-family: $MONTSERRAT;
    }

    p {
      font-size: 15px;
      color: #333;
      text-transform: capitalize;
      font-weight: 500;
      font-family: $MONTSERRAT;
      word-wrap: break-word;
    }
  }
}

.comma_icon_wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -30px;
  @media screen and (max-width: 1024px){
    margin-top: 4px;
  }
  @media screen and (max-width: 600px){
    margin-top: -30px;
  }

  img {
    width: 50px;
  }
}

.testimonial_text_wrapper {
  @include scrollbars();
  max-height: 140px;
  overflow-y: scroll;
  padding-right: 2px;

  .testimonial_text {
    font-family: $MONTSERRAT;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.72;
    color: #333232;
  }
}
}
.aboutHeading{
  font-family: $MONTSERRAT;
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  color: #61bd88;
  @media screen and (max-width: 768px) {
   font-size: 25px;
  }
}
.farm_love{
  font-family:$ROUNDGOTHIC_BOOK;
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  color: #333;
  @media screen and (max-width: 768px) {
    font-size: 28px;
   }
}
.visionPara{
  width: 90% !important;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
.aboutmargin{
  margin-bottom: 40px;
}
.missionPara{
  width: 100% !important;
}
.missionHeading{
  text-align: center;
}