@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.container {
  flex: 1;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  background-color: #ffffffff;
  border: 1px solid black;
  border-radius: 18px;
  align-items: middle;
  margin-top: 8%;
}

.form {
  margin-top: 20px;
}

.web_input_field {

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: none !important;
  }

  .Mui-focused {
    color: $GREEN;
  }

  p {
    color: #a1a4a9;
  }

  &.endorementUser {
    display: flex;
    flex-wrap: wrap;

    & .urlClass {
      flex: 70%;
      border-right: 0;
      border-radius: 5px 0px 0px 5px !important;
    }

    & .userNameClass {
      flex: 30%;
      border-radius: 0px 5px 5px 0px !important;
    }

    @media (max-width: 320px) {
      & .urlClass {
        flex: 100%;
        border-bottom: 0;
        border-right: 1px solid #c3c3c3;
        border-radius: 5px 5px 0px 0px !important;
      }

      &.userNameClass {
        flex: 100%;
        border-radius: 0px 0px 5px 5px !important;
      }
    }
  }

  &.endorment {
    & .MuiFilledInput-inputAdornedEnd {
      border-right: 1.5px #dcdddf solid;
      border-radius: 0;
    }

    & .MuiInputAdornment-positionEnd {
      width: 221px;
      // padding-left: 10px;
      display: flex;
      justify-content: center;
    }

    p {
      font-family: $POPPINS !important;
    }
  }
}

.contributeForms {
  .web_input_field {


    &.endorementUser {
      & .urlClass {
        flex: 25%;
      }

      & .userNameClass {
        flex: 75%;
      }

      @media (max-width: 420px) {
        & .urlClass {
          flex: 50%;
          border-radius: 5px 5px 0px 0px !important;
        }

        &.userNameClass {
          flex: 50%;
          border-radius: 0px 0px 5px 5px !important;
        }
      }
    }
  }
}

.headerLearning {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 75.7%, 0.368627451);
  padding: 20px;

  h4 {
    font-family: $POPPINS;
  }
}

.Profileheader {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 75.7%, 0.368627451);
  margin: 0 -30px;
  width: 75%;
  padding: 20px 30px;

  h4 {
    font-family: $POPPINS;
    font-size: 20px;
  }
}

.helperText {
  font-weight: 500;
  margin-left: 24px;
  color: #a1a4a9;
  font-family: $POPPINS;
}

.helperText2 {
  margin-left: 0;
  margin-top: 3px;
}

.mediaUploaderLearning {
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 200px;
  text-align: center;
  border: 1px solid #dcdddf;
  border-radius: 6px;
  color: #20a258;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mediaUploaderLearninghelper {
  width: 120px;
  height: 176px;
  margin-bottom: 9px;
  padding: 6px !important;

  img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
  }
}

.textUpdateBlock {
  width: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .textUpdate {
    color: #20a258;
    font-size: 14px;
    font-weight: 600;
    margin-left: 3px;
    cursor: pointer;
  }

  img {
    width: 20px;
    margin-right: -35px;
  }
}

.button {
  width: 100%;
  background: #5cb85c;
  color: white;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}

.signup_validations {
  color: red;
  font-size: 12px !important;
  padding: 10px 0px 0px 10px;
}

.login_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding: 0 12%;
  border-bottom: 5px solid #5cb85c;
}

.signup_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  border-bottom: 5px solid #e0e0e0;
  padding: 0 5%;
}

.login_btn_box {
  width: 50%;
}

.form_group {
  padding-bottom: 16px;
}

.label-title {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 12px;
  // margin-vertical: 8px,
  color: grey;
}

.form_control {
  width: 83%;
  border: none;
  height: 2rem;
  background: #d1d1d1;
  padding: 5px 25px;
  border-radius: 5px;
}

.forgotPassword_wrapper {
  button {
    background-color: transparent !important;
    padding: 0px;

    span {
      font-family: $OPEN_SANS;
      font-size: 15px;
      font-weight: 500;
      color: #000;
    }

    :hover {
      background-color: transparent !important;
    }
  }

  span {
    color: black !important;
    font-size: 15px;
    text-decoration: none;
    text-transform: none !important;
  }
}

.forgotPassword {
  color: black;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 49%;
  z-index: -1;
}

.checkBoxContainerView {
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: -7px;
  z-index: -1;
}

.rememberMe {
  color: #6200ee;
  font-weight: bold;
  align-self: center;
  z-index: -1;
}

.orLabel {
  color: #00000000;
  font-weight: bold;
  align-self: center;
  margin: 20;
}

.login_signup_container {
  width: 100vh;
  height: 100%;
  display: flex;
  margin-top: 10%;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.login_layout_wrapper {
  background-color: $WHITE;
  border-radius: 35px;
}

.form_wrapper {
  padding: 40px;
}

.logo_wrapper {
  padding: 25px 15px 15px !important;

  display: flex;
  justify-content: center;
  border-bottom: 1px solid $GREEN;
}

.active_tab {
  border-bottom: 5px solid $GREEN !important;
  color: #03014c !important;
  text-align: center;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 10px;
  font-family: $ROUNDGOTHIC_MEDIUM;

  @media screen and (max-width: 600px) {
    font-size: 16px !important;
    padding: 6px;
  }
}

.tab {
  width: 100%;
  text-align: center;
  padding: 10px;
  border-bottom: 5px solid $GRAY_SHADE1;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #8886a9;
  font-family: $ROUNDGOTHIC_MEDIUM;

  @media screen and (max-width: 600px) {
    font-size: 16px !important;
    padding: 6px;
  }
}

.error {
  color: rgb(243, 81, 81);
  font-size: 13px;
  font-weight: 500px;
}

.sm_login_tab {
  @media (max-width: 419px) {
    padding-bottom: 30px;
  }
}

.forgot-password {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn {
  background-color: $GREEN !important;
  width: 100%;
  color: "white" !important;
  padding: 10px !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.login_signup_btn {
  background-color: $GREEN !important;
  width: 100%;
  color: "white" !important;
  padding: 10px !important;
  border-radius: 30px !important;
  text-transform: capitalize !important;
  & > span {
    color: #fff;
  }
}

.login_signup_loader {
  color: $WHITE !important;
}

.btn_label {
  color: "white" !important;
}

.Login_Input {
  font-family: $MONTSERRAT !important;
}

.Login_Input_Field {
  border-radius: 8px !important;
  width: 100% !important;
  color: $GRAY_SHADE6;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 10px !important;
  background-color: #f6f6f6 !important;
  border: 1px solid #f6f6f6 !important;
}

.Login_Input_Field_Focus {
  border: 1px solid $GREEN !important;
  color: $GREEN;
  font-weight: 600;
}

.terms_of_condition {
  color: $DARK;
  text-align: center;
  padding: 20px 0px;
  font-size: 15px !important;

  span {
    color: $GREEN;
  }
}

.login_input_icons {
  color: #a2a2a2;
  font-size: 20px !important;
}

.password_visibility_icon {
  color: $GRAY_SHADE6;
  font-size: 20px !important;
  cursor: pointer;
}