@import "../../../../../web/src/assets/css/constants/base/colors.scss";

.container {
  flex: 1;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  background-color: #ffffffff;
  border: 1px solid black;
  border-radius: 18px;
  align-items: middle;
  margin-top: 8%;
}

.form {
  margin-top: 20px;
}
.web_input_field {
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-bottom: none !important;
  }
  .Mui-focused {
    color: $GREEN;
  }
}

.button {
  width: 100%;
  background: #5cb85c;
  color: white;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}
.signup_validations {
  color: red;
  font-size: 12px !important;
  padding: 10px 0px 0px 10px;
}

.login_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding: 0 12%;
  border-bottom: 5px solid #5cb85c;
}
.signup_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  border-bottom: 5px solid #e0e0e0;
  padding: 0 5%;
}
.login_btn_box {
  width: 50%;
}

.form_group {
  padding-bottom: 16px;
}

.label-title {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 12px;
  // margin-vertical: 8px,
  color: grey;
}

.form_control {
  width: 83%;
  border: none;
  height: 2rem;
  background: #d1d1d1;
  padding: 5px 25px;
  border-radius: 5px;
}
.forgotPassword_wrapper {
  button {
    background-color: transparent !important;
    padding: 0px;
    :hover {
      background-color: transparent !important;
    }
  }
  span {
    color: black !important;
    font-size: 15px;
    text-decoration: none;
    text-transform: none !important;
  }
}
.forgotPassword {
  color: black;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 49%;
  z-index: -1;
}
.checkBoxContainerView {
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: -7px;
  z-index: -1;
}
.rememberMe {
  color: #6200ee;
  font-weight: bold;
  align-self: center;
  z-index: -1;
}
.orLabel {
  color: #00000000;
  font-weight: bold;
  align-self: center;
  margin: 20;
}

.login_signup_container {
  width: 100vh;
  height: 100%;
  display: flex;
  margin-top: 10%;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.layout_wrapper {
  background-color: $WHITE;
  border-radius: 35px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.form_wrapper {
  padding: 40px;
}
.logo_wrapper {
  padding: 20px;

  display: flex;
  justify-content: center;
  border-bottom: 1px solid $GREEN;
}

.error {
  color: rgb(243, 81, 81);
  font-size: 13px;
  font-weight: 500;
}
.sm_login_tab {
  @media (max-width: 419px) {
    padding-bottom: 30px;
  }
}
.forgot-password {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.btn {
  background-color: $GREEN !important;
  width: 100%;
  color: "white" !important;
  padding: 10px !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}
.btn_label {
  color: "white" !important;
}
.Login_Input {
  padding: 12px 10px !important;
}
.Login_Input_Field {
  border-radius: 8px !important;
  width: 100% !important;
  border: 2px solid transparent;
  color: $GRAY_SHADE6;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
}
.Login_Input_Field_Focus {
  border: 2px solid $GREEN;
  color: $GREEN;
  font-weight: 600;
}

.terms_of_condition {
  color: $DARK;
  text-align: center;
  padding: 20px 0px;
  font-size: 15px !important;
  span {
    color: $GREEN;
  }
}
.login_input_icons {
  color: $GRAY_SHADE6;
  font-size: 20px !important;
}

@media screen and (max-width: 415px) {
  .adjust_tab {
    font-weight: 500 !important;
    padding: 38px 10px;
  }
}

