@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';

.customProgress {
  margin-right: 15px;
  width: 100%;
  .circularprogedit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
    text-align: center;
    margin-left: 70px;
    background-color: $GRAY_SHADE8;
    border-radius: $BORDER_RADIUS;
    padding-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-right: 20px;
      margin-left: 0px;
    }
    .circularprog {
      padding: 20px 0;
      font-size: 17px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      min-height: 0px;
      padding: 5px !important;
      margin-left: -150px;
    }
    .circularProgressedit {
      position: relative;
      display: flex;
      flex-direction: row;
      p {
        position: absolute;
        padding: unset;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: 32px;
        font-weight: 600;
        color: $DARK;
        width: 40%;
        text-align: center;
      }
    }
    h4 {
      padding: 20px 0;
      font-weight: 600;
      color: $DARK;
    }
    .MuiCircularProgress-root {
      width: 130px !important;
      height: auto !important;
    }
    .MuiCircularProgress-colorPrimary {
      color: #20a258;
    }
  }
}
.customLinearProgressbar {
  & progress {
    border-radius: 7px;
    width: 100%;
    height: 7px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }
  progress::-webkit-progress-bar {
    background-color: $GRAY_SHADE3;
    border-radius: 7px;
  }
  progress::-webkit-progress-value {
    background-color: $GREEN;
    border-radius: 7px;
  }
  progress::-moz-progress-bar {
    /* style rules */
    background-color: $GREEN;
    border-radius: 7px;
  }
}
