@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,500;1,700&family=Righteous&display=swap" rel="stylesheet);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,500;1,700&family=Righteous&display=swap" rel="stylesheet);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@import url(//db.onlinewebfonts.com/c/ff9f9b187dc224f3f985a8fadbd88d45?family=AllRoundGothicW01-Medium);
@import url(//db.onlinewebfonts.com/c/8df141f447c5686cf9dbae8dabb1e71a?family=AllRoundGothicW01-Bold);
@import url(//db.onlinewebfonts.com/c/dd266e8a31d9cbae26395f9d100e4e4b?family=AllRoundGothicW01-Book);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@300&family=Work+Sans:wght@200;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.cdnfonts.com/css/avenir);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700;800&display=swap);
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customAccordian .MuiAccordion-root {
  margin-bottom: 20px;
  padding: 10px 20px;
  box-shadow: none;
  border: 1px solid #c1c1c1;
  border-radius: 10px !important;
}
@media screen and (max-width: 1024px) {
  .customAccordian .MuiAccordion-root {
    padding: 10px;
  }
}
.customAccordian .topTitle {
  color: #20a258;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1024px) {
  .customAccordian .topTitle {
    font-size: 1rem;
  }
}
.customAccordian .subTitle {
  padding-left: 30px;
  color: #000000;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1024px) {
  .customAccordian .subTitle {
    font-size: 1rem;
  }
}
.customAccordian p {
  font-size: 16px;
}
@media screen and (max-width: 1024px) {
  .customAccordian p {
    font-size: 14px;
  }
}
.customAccordian .AccordionSummary .MuiSvgIcon-root {
  display: none;
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon {
  background-color: #ec9e31;
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon:hover {
  background-color: #ec9e31;
}
.customAccordian .AccordionSummary .MuiIconButton-label {
  position: relative;
}
.customAccordian .AccordionSummary .MuiIconButton-label::before, .customAccordian .AccordionSummary .MuiIconButton-label::after {
  content: " ";
  position: absolute;
  height: 2px;
  width: 12px;
  background-color: #ffffff;
}
.customAccordian .AccordionSummary .MuiIconButton-label::after {
  transform: rotate(90deg);
  background-color: #ffffff;
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg);
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon.Mui-expanded .MuiIconButton-label::after {
  display: none;
}
.customAccordian .AccordionDetails {
  display: flex;
  flex-direction: column;
}
.customAccordian .AccordionDetails h6 {
  margin: 10px 0;
  font-weight: 600;
  color: #000000;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.breadCrumbsrow {
  margin-bottom: 10px;
}
.breadCrumbsrow .MuiBreadcrumbs-li a {
  color: #394053;
}
.breadCrumbsrow .MuiBreadcrumbs-li .MuiTypography-body1 {
  color: #20a258;
  font-weight: 500;
}
.breadCrumbsrow .MuiBreadcrumbs-separator {
  font-size: 35px;
  margin-bottom: 5px;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customButton .MuiButton-root {
  border-radius: 8px;
  background-color: #20a258;
  border: 2px solid #20a258;
}
.customButton .MuiButton-root:hover {
  background-color: transparent;
  border-color: #20a258;
}
.customButton .MuiButton-root:hover .custom_btn_loader {
  color: #20a258 !important;
}
.customButton .MuiButton-root:hover .MuiButton-label span {
  color: #20a258;
}
.customButton .MuiButton-root:hover .plus_icon::before {
  background-color: #20a258;
}
.customButton .MuiButton-root:hover .plus_icon::after {
  background-color: #20a258;
}
.customButton .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  text-transform: capitalize;
  white-space: nowrap;
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 767px) {
  .customButton .MuiButton-root .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.customButton .MuiButton-root .plus_icon::before {
  content: " ";
  width: 12px;
  height: 3px;
  background-color: #ffffff;
  display: block;
  position: absolute;
  transition: all 0.15s cubic-bezier(0.42, 0, 0.58, 1);
  opacity: 1;
  border-radius: 2px;
  transform: translate(-50%, -50%) rotate(90deg);
  left: 17px;
}
.customButton .MuiButton-root .plus_icon::after {
  content: " ";
  width: 12px;
  height: 3px;
  background-color: #ffffff;
  display: block;
  position: absolute;
  transition: all 0.15s cubic-bezier(0.42, 0, 0.58, 1);
  opacity: 1;
  border-radius: 2px;
  transform: translate(-50%, -50%);
  left: 17px;
}
.customButton.TransparentButton .MuiButton-root {
  padding: 15px;
  opacity: 1;
  border: 1px solid #20a258;
  background-color: rgba(32, 162, 88, 0.6);
}
.customButton.TransparentButton .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  text-transform: capitalize;
}

.greenFilledButton .MuiButton-root {
  border-radius: 8px;
  background-color: #20a258;
  padding: 10px 30px;
  border: 2px solid #20a258;
}
@media screen and (max-width: 1440px) {
  .greenFilledButton .MuiButton-root {
    padding: 5px 10px;
  }
}
.greenFilledButton .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  white-space: nowrap;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1440px) {
  .greenFilledButton .MuiButton-root .MuiButton-label span {
    font-size: 13px;
    padding-left: 0;
  }
}
.greenFilledButton .MuiButton-root:hover {
  background-color: transparent !important;
}
.greenFilledButton .MuiButton-root:hover .MuiButton-label span {
  color: #20a258;
}
.greenFilledButton .MuiButton-root .plus_icon {
  display: none;
}
.greenFilledButton.primaryBtn .MuiButton-root {
  background-color: #ec9e31;
  border-color: #ec9e31;
  padding: 15px;
}
.greenFilledButton.primaryBtn .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 18px;
}

.greenOutlineButton .MuiButton-root {
  border-radius: 8px;
  border: 1px solid #59bd83;
  background-color: #edf8f2;
}
.greenOutlineButton .MuiButton-root .MuiButton-label span {
  color: #59bd83;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 800;
  padding: 3px 20px;
}
.greenOutlineButton .MuiButton-root .plus_icon {
  display: none;
}
.greenOutlineButton .MuiButton-root:hover {
  background-color: #edf8f2;
}
.greenOutlineButton.reportsButton .MuiButton-root {
  background-color: transparent;
}
@media screen and (max-width: 560px) {
  .greenOutlineButton.reportsButton .MuiButton-label img {
    width: 25px !important;
    height: 20px !important;
  }
}
.greenOutlineButton.reportsButton .MuiButton-label span {
  color: #000;
  font-size: 16px;
  padding: 3px 10px;
}
@media screen and (max-width: 560px) {
  .greenOutlineButton.reportsButton .MuiButton-label span {
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .greenOutlineButton.reportsButton {
    justify-content: space-between;
    margin-left: 80px;
  }
}

.redOutlineButton .customButton .MuiButton-root {
  border-radius: #F3666A;
  border: 1px solid red;
  background-color: #fdeeee;
}
.redOutlineButton .customButton .MuiButton-root .MuiButton-label span {
  color: #F3666A;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 800;
  padding: 3px 20px;
}
.redOutlineButton .customButton .MuiButton-root .MuiButton-label:hover span {
  color: #F3666A;
}
.redOutlineButton .customButton .MuiButton-root .plus_icon {
  display: none;
}
.redOutlineButton .customButton .MuiButton-root:hover {
  background-color: red;
}
.redOutlineButton .customButton .MuiButton-root:hover .MuiButton-label span {
  color: #fdeeee;
}
.redOutlineButton.reportsButton .MuiButton-root {
  background-color: transparent;
}
.redOutlineButton.reportsButton .MuiButton-label span {
  color: #000;
  font-size: 16px;
  padding: 3px 10px;
}
@media screen and (max-width: 560px) {
  .redOutlineButton.reportsButton {
    justify-content: space-between;
  }
}

.greenOutlineGrayButton .MuiButton-root {
  border: 1px solid #c3c3c3;
  background-color: transparent;
}
.greenOutlineGrayButton .MuiButton-root .MuiButton-label span {
  color: #394053;
  font-weight: 600;
  padding-left: 0;
}
.greenOutlineGrayButton .MuiButton-root .plus_icon {
  display: none;
}

.plainButton .MuiButton-root {
  border: 0;
  background-color: transparent;
}
.plainButton .MuiButton-root .MuiButton-label span {
  color: #394053;
  font-size: 17px;
  font-weight: 600;
  padding-left: 0;
}
.plainButton .MuiButton-root .plus_icon {
  display: none;
}

.greenContained {
  height: 100%;
}
.greenContained .MuiButton-root {
  border-radius: 8px;
  border: 1px solid #59bd83;
  background-color: #20a258;
}
.greenContained .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.greenContained .MuiButton-root .plus_icon {
  display: none;
}

.redContained {
  height: 100%;
}
.redContained .MuiButton-root {
  border-radius: 8px;
  border: 1px solid #F3666A;
  background-color: #F3666A;
}
.redContained .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.redContained .MuiButton-root .plus_icon {
  display: none;
}
.redContained .MuiButton-root:hover {
  border-color: #F3666A;
}
.redContained .MuiButton-root:hover .MuiButton-label span {
  color: #F3666A;
}

.grayButton {
  height: 100%;
}
.grayButton .MuiButton-root {
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #f8f8f8;
}
.grayButton .MuiButton-root .MuiButton-label span {
  color: black;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.grayButton .MuiButton-root .plus_icon {
  display: none;
}
.grayButton .MuiButton-root:hover {
  background-color: #f8f8f8;
}

.darkGrayButton {
  height: 100%;
}
.darkGrayButton .MuiButton-root {
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: rgba(151, 151, 151, 0.68);
}
.darkGrayButton .MuiButton-root .MuiButton-label span {
  color: black;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.darkGrayButton .MuiButton-root .plus_icon {
  display: none;
}
.darkGrayButton .MuiButton-root:hover {
  background-color: rgba(151, 151, 151, 0.68);
}

.linkButtonWrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.linkButton {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.linkButton .MuiButton-root {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.linkButton .MuiButton-root span {
  width: 100% !important;
}
@media screen and (max-width: 768px) {
  .linkButton .MuiButton-root {
    padding: 0;
  }
}
.linkButton .MuiButton-root .MuiButton-label span {
  color: #f90202;
  font-size: 15px;
  text-transform: capitalize;
}
.linkButton .MuiButton-root .MuiButton-contained {
  box-shadow: 0;
}
.linkButton .MuiButton-root .plus_icon {
  display: none;
}
.linkButton .MuiButton-root:hover {
  background-color: transparent;
}

.circleBtn .MuiButton-root {
  border-radius: 40px;
  padding: 10px 40px;
  border: 1px solid #59bd83;
  background-color: #20a258;
}
.circleBtn .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  padding: 0;
}
.circleBtn .MuiButton-root .plus_icon {
  display: none;
}
.circleBtn .MuiButton-root:hover {
  background-color: transparent;
  border: 1px solid #20a258;
}
.circleBtn .MuiButton-root:hover span {
  color: #20a258;
}

.custom_btn_loader {
  color: #fff !important;
}

.cancel-order-button {
  background-color: #f90202 !important;
  border-radius: 6px !important;
  padding: 4px 14px !important;
  text-transform: none !important;
}
.cancel-order-button span {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@media screen and (max-width: 1024px) {
  .cardContainer.MuiGrid-spacing-xs-5 {
    margin: 0 -20px;
    padding-top: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer.MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 2px 22px;
  }
}
.cardContainer .card {
  width: 100%;
}
.cardContainer .card.MuiCard-root {
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 1600px) {
  .cardContainer .card.MuiCard-root {
    padding: 15px;
  }
}
.cardContainer .card.MuiCard-root .cardContent {
  padding: 0;
}
.cardContainer .card.MuiCard-root .cardContent .h6 {
  color: #c1c1c1;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
  padding-bottom: 5px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root .cardContent .h6 {
    font-size: 13px;
  }
}
.cardContainer .card.MuiCard-root .priceRow {
  display: flex;
  align-items: center;
}
.cardContainer .card.MuiCard-root .priceRow .h1 {
  color: #394053;
  font-size: 30px;
  font-weight: 600;
  padding-right: 10px;
}
@media screen and (max-width: 1600px) {
  .cardContainer .card.MuiCard-root .priceRow .h1 {
    font-size: 19px;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root .priceRow .h1 {
    font-size: 17px;
  }
}
.cardContainer .card.MuiCard-root .iconCircle {
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(103, 103, 177);
}
.cardContainer .card.restDetailsCard {
  cursor: pointer;
}
.cardContainer .card.restDetailsCard.MuiCard-root {
  background-color: #ffffff;
  padding: 15px 25px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  border: 1px solid #c1c1c1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-evenly;
}
.cardContainer .card.restDetailsCard.MuiCard-root.activeRestDetailCard {
  background-color: #edf8f2;
  border: 1px solid #59bd83;
}
.cardContainer .card.restDetailsCard .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cardContainer .card.restDetailsCard .header .MuiSvgIcon-root {
  color: #59bd83;
  font-size: 40px;
}
.cardContainer .card.restDetailsCard .header .h6 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-left: 20px;
  width: 100%;
  line-height: 1.1;
  text-align: unset;
}
.cardContainer .card.restDetailsCard .description {
  margin-top: 10px;
}
.cardContainer .card.restDetailsCard .description p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.cardContainer .card.choseCard {
  position: relative;
}
.cardContainer .card.choseCard.MuiCard-root {
  background-color: #ffffff;
  padding: 50px 0 0 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px #c1c1c1;
}
.cardContainer .card.courseCard {
  flex-direction: column;
  padding: 15px;
  height: 100%;
  box-shadow: 0px 16px 20px 0px rgba(204, 204, 204, 0.5803921569);
}
.cardContainer .card.courseCard .MuiCardMedia-img {
  border-radius: 15px;
}
.cardContainer .card.courseCard .cardContent {
  width: 100%;
  padding-top: 20px;
}
.cardContainer .card.courseCard .cardContent p {
  font-weight: 400;
  color: #c1c1c1;
  padding-top: 10px;
  word-spacing: 2px;
  line-height: 25px;
  font-size: 17px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.courseCard .cardContent p {
    font-size: 14px;
  }
}
.cardContainer .card.courseCard .CardActions {
  width: 100%;
  padding: 20px 0;
}
.cardContainer .card.purchaseCard {
  margin-bottom: 0;
  flex-direction: column !important;
  padding: 15px;
  box-shadow: 0px 16px 20px 0px rgba(204, 204, 204, 0.5803921569) !important;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.purchaseCard {
    margin-bottom: 30px;
  }
}
.cardContainer .card.purchaseCard .imgContainer {
  width: 100%;
  position: relative;
}
.cardContainer .card.purchaseCard .imgContainer .rating {
  position: absolute;
  top: 10px;
  left: 10px;
}
.cardContainer .card.purchaseCard .imgContainer .rating .MuiRating-iconEmpty {
  color: white;
}
.cardContainer .card.purchaseCard .courseImg {
  border-radius: 15px;
}
.cardContainer .card.purchaseCard .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.purchaseCard .MuiTypography-h5 {
  font-size: 20px;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .MuiTypography-h5 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard h4 {
    font-size: 16px;
  }
}
.cardContainer .card.purchaseCard .cardContent {
  padding: 20px 20px 0 20px !important;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .cardContent {
    padding: 5px 5px 0 5px !important;
  }
}
.cardContainer .card.purchaseCard .cardContent .MuiTypography-body2 {
  color: #c3c3c3;
  margin: 4px 0;
}
.cardContainer .card.purchaseCard .CardActions {
  justify-content: space-between;
  width: 100%;
  padding: 20px !important;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .CardActions {
    padding: 5px 5px 0 5px !important;
  }
}
.cardContainer .card.receiptCard {
  margin-bottom: 0;
  flex-direction: column !important;
  padding: 0px;
  box-shadow: 0px 0px 10px 0px #c3c3c3;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard {
    margin-bottom: 30px;
  }
}
.cardContainer .card.receiptCard .courseImg {
  border-radius: 15px 0 0 0;
}
.cardContainer .card.receiptCard .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.receiptCard .MuiTypography-h4 {
  font-size: 28px;
  color: #59bd83;
  font-weight: 600;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .MuiTypography-h4 {
    font-size: 16px;
  }
}
.cardContainer .card.receiptCard .cardContent {
  padding: 20px 20px 0 20px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .cardContent {
    padding: 20px !important;
  }
}
.cardContainer .card.receiptCard .cardContent .receiptDate {
  position: absolute;
  top: -40px;
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
  font-size: 18px;
  font-weight: 600;
}
.cardContainer .card.receiptCard .CardActions {
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 40px 20px !important;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions {
    padding: 20px !important;
  }
}
.cardContainer .card.receiptCard .CardActions .actionRow {
  display: flex;
  align-items: center;
  margin-left: 0;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions .actionRow {
    margin-bottom: 10px;
  }
}
.cardContainer .card.receiptCard .CardActions .actionRow h5 {
  font-weight: 600;
  padding: 0 5px;
}
.cardContainer .card.receiptCard .CardActions .actionRow .actionIcons {
  height: 30px;
  width: 30px;
}
.cardContainer .card.reviewCard {
  padding: 20px;
  border-radius: 15px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #e2dada;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .cardContainer .card.reviewCard {
    flex-direction: column;
    align-items: center;
  }
}
.cardContainer .card.reviewCard .profileImg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid #515156;
}
.cardContainer .card.reviewCard .cardContent {
  flex-direction: column;
  width: 100%;
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  .cardContainer .card.reviewCard .cardContent {
    padding-left: 0;
    padding-top: 20px;
  }
}
.cardContainer .card.reviewCard .cardContent .topRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cardContainer .card.reviewCard .cardContent .topRow h5 {
  font-weight: bold;
}
.cardContainer .card.reviewCard .cardContent .ratingRow {
  margin: 15px 0;
  display: flex;
}
.cardContainer .card.reviewCard .cardContent .ratingRow .MuiRating-icon {
  font-size: 30px;
}
.cardContainer .card.reviewCard .cardContent .ratingRow .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.reviewCard .cardContent p {
  font-size: 17px;
}
.cardContainer .card.studentCard {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 1px 1px 9px #c1c1c1;
  padding: 30px;
  margin-bottom: 0;
}
.cardContainer .card.studentCard .MuiCardHeader-root {
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span {
  font-family: "AllRoundGothicW01-Medium";
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span:nth-child(1) {
  color: #394053;
  font-weight: 600;
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span:nth-child(2) {
  color: #808080;
}
.cardContainer .card.studentCard .MuiCardContent-root {
  padding: 20px 0 0 0;
}
.cardContainer .card.studentCard .MuiCardContent-root p {
  color: #808080;
}
.cardContainer .card.coursePlayCard {
  box-shadow: 1px 1px 20px 0px rgba(204, 204, 204, 0.5803921569);
  padding: 15px 15px 150px 15px;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .cardContainer .card.coursePlayCard {
    border: 0;
    box-shadow: none;
    padding: 0;
  }
}

.educatorCard.MuiCard-root {
  width: 100%;
  border-radius: 8px 8px 0 0;
}
.educatorCard.MuiCard-root .MuiCardMedia-media {
  height: 300px;
}
.educatorCard.MuiCard-root .cardContent {
  background-color: #ffffff;
  margin-top: -20px;
  border-radius: 8px 8px 0 0;
}
.educatorCard.MuiCard-root .cardContent .MuiTypography-h5 {
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  word-spacing: 2px;
  letter-spacing: 2px;
}
.educatorCard.MuiCard-root .cardContent .MuiTypography-body2 {
  text-align: center;
  font-size: 13px;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.chartContainer {
  margin-bottom: 25px;
  width: 100%;
  background-color: white;
  border-radius: 20px;
}
.chartContainer .card.MuiCard-root {
  padding: 15px 20px 0px 20px;
}
.chartContainer.cardContainer {
  padding-top: 0;
}
@media screen and (max-width: 1024px) {
  .chartContainer {
    margin: 10px 0 25px 0;
  }
}
.chartContainer h5 {
  font-size: 18px;
  font-weight: bolder;
  white-space: nowrap;
}
.chartContainer .chartCard {
  width: 100%;
}
.chartContainer .chartCard .charthead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .chartContainer .chartCard .charthead {
    flex-direction: column;
  }
}
.chartContainer .chartCard .listMenu {
  display: flex;
}
@media screen and (max-width: 767px) {
  .chartContainer .chartCard .listMenu {
    margin-top: 10px;
  }
}
.chartContainer .chartCard .listMenu .listItems {
  margin: 0 15px;
  position: relative;
}
.chartContainer .chartCard .listMenu .listItems span {
  margin-left: 5px;
  color: #5d5e6a;
  font-size: 12px;
}
.chartContainer .chartCard .listMenu .listItems::before {
  content: " ";
  position: absolute;
  width: 13px;
  height: 15px;
  left: -15px;
  top: 5px;
  border-radius: 50%;
}
.chartContainer .chartCard .listMenu .listItems.primary::before {
  background-color: #5babfb;
}
.chartContainer .chartCard .listMenu .listItems.success::before {
  background-color: #20a258;
}
.chartContainer .header {
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
@media screen and (max-width: 580px) {
  .chartContainer .header {
    flex-direction: column;
    padding: 20px 20px;
  }
}
.chartContainer .header h3 {
  width: 100%;
  text-align: left;
  font-weight: 900;
}
.chartContainer .header .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 580px) {
  .chartContainer .header .button-container {
    margin-top: 14px;
  }
}
.chartContainer .header .button-container .graph-btn {
  background-color: #ffffff;
  color: #c1c1c1;
  font-weight: 500;
  padding: 5px 15px;
  border-radius: 8px;
  outline: none;
  border: none;
  margin: 0 2px;
}
.chartContainer .header .button-container .graph-btn.active {
  color: #ffffff;
  background-color: #5babfb;
}
.chartContainer .dashboardChart {
  width: 100%;
  height: 350px;
}
@media screen and (max-width: 580px) {
  .chartContainer .dashboardChart {
    height: 250px;
  }
}
.chartContainer .dashboardChart #lineChart {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 580px) {
  .chartContainer .dashboardChart #lineChart {
    padding: 20px 20px;
  }
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customCheckbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.customCheckbox input + .is_same_number::before {
  border-radius: 50%;
}
.customCheckbox input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 14px;
  border: solid #20a258;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.customCheckbox input:checked + label::before {
  background-color: rgba(32, 162, 88, 0.08);
}
.customCheckbox input:checked + .is_same_number::after {
  border-radius: 50%;
  top: 7.2px;
  left: 2.75px;
  width: 11px;
  height: 11px;
  background-color: #20a258;
  transform: rotate(0) !important;
  border-width: 0;
}
.customCheckbox label {
  position: relative;
  cursor: pointer;
}
.customCheckbox label::before {
  content: "";
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  background-color: rgba(32, 162, 88, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: -1px !important;
}
.customCheckbox label.product_checkbox::before {
  margin-bottom: 5px;
}

.circleCheckbox input:checked + label::after {
  border: solid #ec9e31;
  border-width: 0 4px 4px 0;
}
.circleCheckbox input:checked + label::before {
  background-color: #ffffff;
}
.circleCheckbox label::before {
  border-radius: 50%;
  border-color: #ffffff;
}

.customLabelCheckbox .MuiSvgIcon-root {
  height: 1.2em;
  width: 1.2em;
}
.customLabelCheckbox .Mui-checked .MuiSvgIcon-root {
  color: #20a258;
}
.customLabelCheckbox .MuiTypography-body1 {
  color: #394053;
}

.customToggleButton .switch {
  cursor: pointer;
  width: 80px;
  height: 36px;
  background: #fff;
  position: relative;
  transition: background-color 0.2s;
  border: 1px solid #808080;
  border-radius: 30px;
  margin-left: 30px;
  margin-top: 20px;
}
@media screen and (max-width: 560px) {
  .customToggleButton .switch {
    width: 75px;
    height: 30px;
  }
}
.customToggleButton .switch .switchCheckbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}
.customToggleButton .switch .switchCheckbox:checked + .redSwitch {
  left: 100%;
  transform: translateX(-100%);
}
.customToggleButton .switch .switchCheckbox:checked + .greenSwitch {
  left: 100%;
  transform: translateX(-100%);
}
.customToggleButton .switch .switchCheckbox:checked + .switchButton {
  left: 97%;
  transform: translateX(-100%);
}
.customToggleButton .switch .switchButton {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 30px;
  height: 30px;
  transition: 0.3s;
  background-color: #20a258;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
.customToggleButton .switch .redSwitch {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  transition: 0.3s;
  background: red;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
@media screen and (max-width: 560px) {
  .customToggleButton .switch .redSwitch {
    width: 30px;
    height: 30px;
  }
}
.customToggleButton .switch .greenSwitch {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  transition: 0.3s;
  background: #20a258;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
@media screen and (max-width: 560px) {
  .customToggleButton .switch .greenSwitch {
    width: 30px;
    height: 30px;
  }
}
.customToggleButton .switch .switchLabels {
  display: relative;
  z-index: 0;
  height: 100%;
  font: 14pt "Segoe UI", Verdana, sans-serif;
}
.customToggleButton .switch .switchLabels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  font-size: 14px;
  color: #515156;
  font-weight: 600;
}
.customToggleButton .switch .switchLabels span:first-child {
  left: 0;
}
.customToggleButton .switch .switchLabels span:last-child {
  right: 0;
}
@media screen and (max-width: 560px) {
  .customToggleButton .switch .switchLabels span {
    font-size: 12px;
    width: 48%;
  }
}

.dangerCheckbox input:checked + label::before {
  background-color: #ec9e31;
  border: 1px solid #ec9e31;
  margin-right: 0;
  padding: 9px;
}
.dangerCheckbox input:checked + label::after {
  top: 4px;
  left: 6px;
  height: 13px;
  border-color: #ffffff;
  border-radius: 3px;
}

.grayBackGroundButton .switchLabel {
  font-size: 10px;
  margin-right: 10px;
  color: #515156;
  font-weight: bold;
}
.grayBackGroundButton .customToggleButton .switch {
  border: unset;
  background-color: #f0f0f0;
  margin: unset;
}
.grayBackGroundButton .acceptOrderBtn {
  display: flex;
  justify-content: center;
}

.mcqCheckWidth .MuiFormControlLabel-root {
  width: 28px !important;
}

.customChips.MuiChip-root {
  border-radius: 7px;
  background-color: #6ddfba;
  padding: 4px 7px;
  height: auto;
  font-size: 0.6rem;
}
.customChips.MuiChip-root span {
  color: #ffffff;
  padding: 0;
  font-weight: 600;
}

.greenContainChip .customChips.MuiChip-root {
  background-color: #e7ffed;
  font-size: 0.7rem;
}
.greenContainChip .customChips.MuiChip-root span {
  color: #6ddfba;
}

.redContainChip .customChips.MuiChip-root {
  background-color: #fdeeee;
  font-size: 0.7rem;
}
.redContainChip .customChips.MuiChip-root span {
  color: #f59495;
}

.blueContainChip .customChips.MuiChip-root {
  background-color: #edf7fe;
}
.blueContainChip .customChips.MuiChip-root span {
  color: #76c4fc;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customChipArrayList {
  padding: 0 !important;
}
.customChipArrayList .MuiChip-root {
  width: 100%;
  justify-content: space-between;
  padding: 22px 15px;
  border-radius: 40px;
  background-color: transparent !important;
  border: 1px solid #c3c3c3;
  font-size: 17px;
  cursor: pointer;
}
.customChipArrayList .MuiChip-root:hover {
  border: 1px solid #20a258;
}
.customChipArrayList .MuiChip-root span {
  color: #5d5e6a;
  font-weight: 500;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customStepperContainer .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-active {
  color: #f8892b;
}
.customStepperContainer .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-completed {
  color: #f8892b;
}
.customStepperContainer .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .MuiStepLabel-labelContainer {
  display: none;
  visibility: hidden;
}

.stepIcon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-top: -8px;
  z-index: 2;
}
.stepIcon:hover {
  cursor: pointer;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customDropdown .userAvtar {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
}
.customDropdown .btnGroup {
  position: relative;
  cursor: pointer;
}
.customDropdown .btnGroup::before {
  content: " ";
  position: absolute;
  right: -5px;
  top: 20px;
  border: 0px;
  border-left: 3px solid #bababa;
  border-bottom: 3px solid #bababa;
  border-top: 0px solid transparent;
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  transition: transform 0.3s;
}
.customDropdown .btnGroupOpenMenu::before {
  transform: rotate(135deg);
}
.customDropdown .MuiButton-root:hover {
  background-color: transparent;
}

.menu-customize .MuiPaper-rounded {
  border-radius: 10px;
}
.menu-customize .MuiMenuItem-root:hover {
  background-color: #fff;
  color: #20a258;
}
.menu-customize .MuiMenuItem-root {
  text-transform: capitalize !important;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.11px;
  font-weight: 500;
  color: #000;
}

.option-dropDown {
  width: 100%;
}
.option-dropDown .customDropdown .userAvtar {
  display: none;
}
.option-dropDown .customDropdown .MuiMenuItem-root {
  text-transform: capitalize;
}
.option-dropDown .customDropdown .btnGroup {
  position: relative;
  height: 30px;
}
.option-dropDown .customDropdown .btnGroup::before {
  content: "...";
  position: absolute;
  text-align: center;
  left: 0px;
  top: -5px;
  border: 0px;
  overflow: hidden;
  color: #bababa;
  font-size: 20px;
  font-weight: 900;
  height: 100%;
  width: 100%;
  transform: rotate(0deg);
}
.option-dropDown .customDropdown .MuiButton-root:hover {
  background-color: transparent;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customFileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 20px;
  max-width: 370px;
  margin: 10px 0;
}
.customFileUpload .fileUploadInput {
  display: none;
}
.customFileUpload .fileUploadLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customFileUpload .fileUploadLabel img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
.customFileUpload .fileUploadLabel span {
  color: #20a258;
  font-weight: 600;
  padding-top: 10px;
}
.customFileUpload .fileUploadIcon img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 23px;
}

.form-control {
  background-color: #c3c3c3;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customList .listItems {
  display: flex;
  padding: 15px 40px;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.customList .listItems .leftIcon::before {
  content: " ";
  position: absolute;
  right: 22px;
  bottom: 19px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #c1c1c1;
}
.customList .listItems .bottomIcon::before {
  content: " ";
  position: absolute;
  right: 32px;
  bottom: 24px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #c1c1c1;
  transform: rotate(-90deg);
  border-radius: 3px;
  transition: transform 0.3s;
}
.customList .listItems .bottomIconActive::before {
  content: " ";
  position: absolute;
  right: 32px;
  bottom: 24px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #20a258;
  border-radius: 3px;
  transition: transform 0.3s;
}
.customList .listItems .MuiSvgIcon-root {
  display: none;
}
.customList .listItems .MuiListItemIcon-root {
  min-width: 28px;
}
.customList .listItems .MuiTypography-root {
  color: #bbb;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  line-height: 1;
  text-transform: capitalize;
}
.customList .listItems:hover .MuiTypography-root, .customList .listItems:active .MuiTypography-root, .customList .listItems.listItemsActive .MuiTypography-root {
  color: #009540;
}
.customList .collapseList .listItems {
  padding: 0;
}
.customList .collapseList .MuiListItemText-root {
  margin-bottom: 2px;
  color: #c1c1c1;
  font-weight: 600;
  cursor: pointer;
}
.customList .collapseList .MuiListItemText-root span {
  color: #bbb;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 10px 55px 10px 75px !important;
}
.customList .collapseList .MuiListItemText-root:hover span, .customList .collapseList .MuiListItemText-root.listItemsTextActive span {
  background-color: #e7ffed;
  color: #20a258;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.mainModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 20px;
  margin: 0 auto;
}
.mainModal.modal-md {
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  overflow-y: scroll;
}
.mainModal.modal-md::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.mainModal.modal-md::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.mainModal.modal-md::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.mainModal.modal-lg {
  width: 100%;
  max-width: 1100px;
}
.mainModal .subModalBlock {
  background-color: #ffffff;
  border-radius: 20px;
}
.mainModal .subModalBlock .modalHeader {
  padding: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.mainModal .subModalBlock .modalHeader h4 {
  text-align: center;
  margin-left: auto;
}
.mainModal .subModalBlock .modalHeader .closeIcon {
  margin-left: auto;
  font-size: 30px;
  cursor: pointer;
}
.mainModal .subModalBlock .modalHeader .closeIcon svg {
  color: #333;
}
.mainModal .subModalBlock .modalHeader.selectTypeHeader h4 {
  margin-left: unset;
  justify-content: center;
  width: 100%;
}
.mainModal .subModalBlock .farmOrderTitle {
  padding: 18px;
}
.mainModal .subModalBlock .restModalContent {
  padding: 25px;
  overflow-y: auto;
}
.mainModal .subModalBlock .restModalContent::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.mainModal .subModalBlock .restModalContent::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.mainModal .subModalBlock .restModalContent::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
@media screen and (max-width: 1500px) {
  .mainModal .subModalBlock .restModalContent {
    height: 500px;
  }
}
@media screen and (max-width: 768px) {
  .mainModal .subModalBlock .restModalContent {
    padding: 20px;
  }
}
.mainModal .subModalBlock .restModalContent h5, .mainModal .subModalBlock .restModalContent h6, .mainModal .subModalBlock .restModalContent span {
  margin: 8px 0;
  font-weight: 600;
  color: #394053;
}
.mainModal .subModalBlock .restModalContent h5 span {
  color: #20a258;
}
.mainModal .subModalBlock .restModalContent .orderCard {
  background-color: #f3f3f3;
  padding: 20px 20px 20px 35px;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .restModalContent .orderCard {
    padding: 10px;
  }
}
.mainModal .subModalBlock .restModalContent .orderCard .topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .restModalContent .orderCard .topRow {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
}
.mainModal .subModalBlock .restModalContent .orderCard .leftBlock {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .restModalContent .orderCard .leftBlock {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
.mainModal .subModalBlock .restModalContent .orderCard .leftContentBlock {
  padding-left: 15px;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .restModalContent .orderCard .leftContentBlock {
    padding-left: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.mainModal .subModalBlock .restModalContent .orderCard .OrderImg {
  height: 60px;
  width: 120px;
  object-fit: cover;
  border-radius: 15px;
  object-position: left;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .restModalContent .orderCard .OrderImg {
    height: 120px;
    width: 100%;
  }
}
.mainModal .subModalBlock .restModalContent .deliveryTimeRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mainModal .subModalBlock .restModalContent .addTimeRow {
  display: flex;
  margin-bottom: 20px;
}
.mainModal .subModalBlock .restModalContent .addTimeRow h6 {
  font-size: 14px;
}
.mainModal .subModalBlock .restModalContent .addTimeRow .timeMinRow {
  background-color: #f3f3f3;
  padding: 10px 12px;
  font-size: 15px;
  color: #394053;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 10px;
}
.mainModal .subModalBlock .restModalContent .btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.mainModal .subModalBlock .restModalContent .btnContainer .greenFilledButton,
.mainModal .subModalBlock .restModalContent .btnContainer .redOutlineButton {
  width: 100%;
}
.mainModal .subModalBlock .restModalContent .btnContainer .greenFilledButton .MuiButton-root,
.mainModal .subModalBlock .restModalContent .btnContainer .redOutlineButton .MuiButton-root {
  width: 100%;
  padding: 0 5px;
  height: 45px;
}
.mainModal .subModalBlock .adminOrderModalContent {
  padding: 35px;
  overflow-y: auto;
  max-height: 700px;
}
@media screen and (max-width: 1500px) {
  .mainModal .subModalBlock .adminOrderModalContent {
    max-height: 600px;
  }
}
@media screen and (max-width: 1400px) {
  .mainModal .subModalBlock .adminOrderModalContent {
    max-height: 400px;
  }
}
@media screen and (max-width: 768px) {
  .mainModal .subModalBlock .adminOrderModalContent {
    padding: 20px;
  }
}
.mainModal .subModalBlock .adminOrderModalContent .orderStatusDropdown {
  width: 170px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #5a5a5a;
  font-weight: 500;
  background-color: #f0f0f0;
  border-radius: 8px;
}
.mainModal .subModalBlock .adminOrderModalContent .orderStatusDropdown > div {
  border-radius: 8px;
}
.mainModal .subModalBlock .adminOrderModalContent .orderStatusDropdown .MuiSelect-filled.MuiSelect-filled {
  padding: 8px 12px;
}
.mainModal .subModalBlock .adminOrderModalContent .orderPickedUpButton {
  background-color: #e0e0e0;
  padding: 4px 10px;
  text-transform: none;
  width: 140px;
}
.mainModal .subModalBlock .adminOrderModalContent .orderPickedUpButton span {
  color: #5a5a5a;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.mainModal .subModalBlock .adminOrderModalContent .orderPrintLabelButton {
  background-color: #fff;
  padding: 3px 10px;
  text-transform: none;
  width: 140px;
  border: 1px solid #359843;
}
.mainModal .subModalBlock .adminOrderModalContent .orderPrintLabelButton span {
  color: #359843;
  font-weight: 600;
  font-size: 14px;
}
.mainModal .subModalBlock .adminOrderModalContent .trackingContainer h6 {
  font-size: 22px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #5a5a5a;
}
.mainModal .subModalBlock .adminOrderModalContent .trackingContainer p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #5a5a5a;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderCard {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16);
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderNotesWrapper h6 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderNotesWrapper textarea {
  resize: none;
  height: 120px;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  background-color: #f0f0f0;
  font-size: 13px;
  border: 1px solid #d3d3d3;
  outline: none;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderTrackingStatus {
  color: #d97e17;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderConfirmationStatus {
  color: #20a258;
  font-family: "Montserrat", sans-serif;
  background-color: rgba(32, 168, 88, 0.15);
  font-size: 15px;
  font-weight: 700;
  padding: 8px;
  text-align: center;
  margin-bottom: 10px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderDeliverText {
  color: #5A5A5A;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserDetails {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 10px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserDetails .orderUserAvatar {
  width: 65px;
  height: 65px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserInfoDetails p {
  color: #5A5A5A;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  word-wrap: break-word;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserInfoDetails p:nth-child(1) {
  font-weight: 600;
}
.mainModal .subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserInfoDetails p:nth-child(2) {
  font-weight: 400;
}
.mainModal .subModalBlock .adminOrderModalContent .priceSection {
  margin-top: -65px;
}
@media screen and (max-width: 768px) {
  .mainModal .subModalBlock .adminOrderModalContent .priceSection {
    margin-top: 0;
  }
}
.mainModal .subModalBlock .adminOrderModalContent h3 {
  padding-left: 20px;
  font-family: "AllRoundGothicW01-Medium";
  font-weight: 100;
}
.mainModal .subModalBlock .adminOrderModalContent .contentHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.mainModal .subModalBlock .adminOrderModalContent .contentHeader .farmProfile {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard {
  margin-bottom: 20px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard h3 {
  padding-left: 0;
  font-size: 22px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent {
  display: flex;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent {
    flex-direction: column;
  }
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .imgBlock {
  border-radius: 8px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .imgBlock .farmSubProfile {
  height: 180px;
  width: 180px;
  object-fit: cover;
  border-radius: 8px;
}
@media screen and (max-width: 768px) {
  .mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .imgBlock {
    margin-bottom: 10px;
  }
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock {
  padding-left: 20px;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock {
    padding-left: 0;
  }
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock p {
  width: 85%;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.89;
  color: #5a5a5a;
}
@media screen and (max-width: 768px) {
  .mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock p {
    width: 100%;
  }
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock h5 {
  margin: 20px 0;
  color: #20a258;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 18px;
}
.mainModal .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock h5 span {
  color: #20a258;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.mainModal .subModalBlock .adminOrderModalContent .priceCard {
  box-shadow: 1px 1px 6px 10px #f7f7f7 !important;
}
@media screen and (max-width: 768px) {
  .mainModal .subModalBlock .adminOrderModalContent .priceCard {
    margin-bottom: 20px;
  }
}
.mainModal .subModalBlock .adminOrderModalContent .priceCard .cardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mainModal .subModalBlock .adminOrderModalContent .priceCard .cardRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mainModal .subModalBlock .adminOrderModalContent .priceCard .cardRow h6 {
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}
.mainModal .subModalBlock .adminOrderModalContent .priceCard .cardTotalRow h6 {
  font-weight: 500 !important;
  font-size: 20px;
  color: #000;
}
.mainModal .subModalBlock .adminOrderModalContent .greenFilledButton .MuiButton-root {
  width: 100%;
}
.mainModal .subModalBlock .learningAddPartModalContent {
  padding: 50px;
  overflow-y: auto;
}
.mainModal .subModalBlock .learningAddPartModalContent label {
  color: #394053;
  font-weight: 600;
}
.mainModal .subModalBlock .learningAddPartModalContent input::-webkit-input-placeholder, .mainModal .subModalBlock .learningAddPartModalContent textarea::-webkit-input-placeholder {
  color: #515156;
  font-weight: 400;
  opacity: 1;
}
.mainModal .subModalBlock .learningAddPartModalContent input::placeholder, .mainModal .subModalBlock .learningAddPartModalContent textarea::placeholder {
  color: #515156;
  font-weight: 400;
  opacity: 1;
}
.mainModal .subModalBlock .learningAddPartModalContent .btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}
.mainModal .subModalBlock .learningAddPartModalContent .btnContainer .MuiButton-root {
  width: 100%;
  height: 50px;
}
.mainModal .subModalBlock .learningAddPartModalContent .btnContainer .greenOutlineButton, .mainModal .subModalBlock .learningAddPartModalContent .btnContainer .greenContained {
  width: 100%;
}
.mainModal .subModalBlock .learningAddPartModalContent .lesson-row {
  display: flex;
  align-items: center;
}
.mainModal .subModalBlock .learningAddPartModalContent .lesson-row p {
  padding-left: 20px;
}
.mainModal .subModalBlock .learningAddPartModalContent .titleBox {
  padding: 10px 0;
  margin: 20px 0;
  border-top: 1px solid #b6e0c8;
  border-bottom: 1px solid #b6e0c8;
}
.mainModal .subModalBlock .learningAddPartModalContent .video-imgblock {
  display: flex;
  flex-direction: column;
}
.mainModal .subModalBlock .learningAddPartModalContent .video-imgblock .lessonImg, .mainModal .subModalBlock .learningAddPartModalContent .video-imgblock video {
  width: 180px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}
.mainModal .subModalBlock .learningAddPartModalContent .video-imgblock .captionBlock {
  width: 180px;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}
.mainModal .subModalBlock .learningAddPartModalContent .video-imgblock .captionBlock .deleteIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.mainModal .subModalBlock .learningAddPartModalContent .video-imgblock .img-title {
  width: 135px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mainModal .subModalBlock .learningAddPartModalContent .video-imgblock .lessonImg {
  width: 180px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}
.mainModal .subModalBlock .learningAddPartModalContent .customTextAreafield textarea {
  margin-bottom: 0;
}
.mainModal .subModalBlock .selectTaskModalContent .carContainer .card {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0px 0px 8px 0px #c0b4b4;
  border: 2px solid #fff;
  cursor: pointer;
}
.mainModal .subModalBlock .selectTaskModalContent .carContainer .card .imgContainer img {
  width: 62px;
  height: 62px;
}
.mainModal .subModalBlock .selectTaskModalContent .carContainer .card p {
  font-size: 13px;
  font-weight: 600;
  color: #3f526d;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
.mainModal .subModalBlock .selectTaskModalContent .carContainer.learningTaskCheckbox input:checked + .card {
  border: 2px solid #0218fc;
  box-shadow: none;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer {
  width: 100%;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader p {
  color: #676b72 !important;
  font-size: 16px;
  width: 100%;
  text-align: left;
  margin: 10px 0;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader .checkboxContainer {
  display: flex;
  align-items: center;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader .checkboxContainer label {
  color: #676b72 !important;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader .customToggleButton .switch {
  margin-top: 0 !important;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer h3 {
  color: #464a53;
  font-size: 16px;
  width: 100%;
  text-align: left;
  font-weight: 700;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px 0;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer {
  border: 1px solid rgb(175, 170, 170);
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 20px;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer img {
  width: 65px;
  height: 40px;
  object-fit: contain;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer span:nth-child(2) {
  color: #52647c;
  font-weight: bold;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer span:nth-child(3) {
  color: #52647c;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .MuiFormControlLabel-root {
  margin: 0 0 0 5px;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .icon .MuiSvgIcon-root {
  transform: rotate(45deg);
  color: #cfcfcf;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon .edit-icon {
  width: 30px;
  height: 40px;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon .MuiButton-text {
  padding: 0 !important;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon .MuiButton-root {
  min-width: unset !important;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .btnContainer {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .btnContainer .addbtn {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0), #20a258;
  background-position: center;
  background-size: 50% 2px, 2px 50%;
  /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .btnContainer span:nth-child(2) {
  font-weight: 600;
  margin-left: 5px;
  color: #414141;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.mainModal .subModalBlock .mcqaModalContantainer .answerListContainer .taskPoint h3 {
  margin-bottom: 10px;
}
.mainModal .subModalBlock .trueFalseModalConent .taskPoint h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer {
  margin-top: 20px;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer h2 {
  font-size: 18px;
  margin: 10px 0;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer p {
  color: #595c64;
  margin-bottom: 10px;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .answer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px 0;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer {
  border-radius: 5px;
  padding: 5px 15px;
  width: 100%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer .radioButtonContainer .MuiFormControlLabel-root {
  margin: 0;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer.true {
  background-color: #e7f1e8;
  border: 1px solid #3e893d;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer.true .answerDescription {
  color: #3e893d;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer.false {
  background-color: #fbd8d8;
  border: 1px solid #f35c5f;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer.false .answerDescription {
  color: #f35c5f;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked {
  position: relative;
  padding: 25px;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked::before {
  content: " ";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #3f526d;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiRadio-root {
  padding: 9px;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiIconButton-label {
  position: relative;
  border: 0;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiIconButton-label .MuiSvgIcon-root {
  display: none;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiIconButton-label::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 11px;
  background-color: #fff;
  left: 0px;
  top: -7px;
}
.mainModal .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiIconButton-label::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 3px;
  background-color: #fff;
  left: -5px;
  top: 2px;
}
.mainModal .subModalBlock .longAnsModalContent .taskPoint h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.mainModal .subModalBlock .longAnsModalContent .taskTypeContainer {
  margin: 20px 0;
}
.mainModal .subModalBlock .longAnsModalContent .taskTypeContainer h2 {
  font-size: 18px;
  margin: 15px 0;
}
.mainModal .subModalBlock .longAnsModalContent .taskTypeContainer .taskType {
  border: 1px solid rgba(193, 193, 193, 0.368627451);
  padding: 0 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}
.mainModal .subModalBlock .longAnsModalContent .taskTypeContainer .taskType .MuiFormControlLabel-root {
  margin: 0;
}
.mainModal .subModalBlock .longAnsModalContent .iconContainer .Mui-checked {
  position: relative;
  padding: 25px;
}
.mainModal .subModalBlock .longAnsModalContent .iconContainer .Mui-checked::before {
  content: " ";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #3f526d;
}
.mainModal .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiRadio-root {
  padding: 9px;
}
.mainModal .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiIconButton-label {
  position: relative;
  border: 0;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.mainModal .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiIconButton-label .MuiSvgIcon-root {
  display: none;
}
.mainModal .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiIconButton-label::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 11px;
  background-color: #fff;
  left: 0px;
  top: -7px;
}
.mainModal .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiIconButton-label::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 3px;
  background-color: #fff;
  left: -5px;
  top: 2px;
}
.mainModal .subModalBlock .learningPurchaseModalContent {
  padding: 40px;
  overflow-y: auto;
  height: 600px;
  width: 665px;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .learningPurchaseModalContent {
    width: 300px;
    padding: 10px;
  }
}
.mainModal .subModalBlock .learningPurchaseModalContent .profile-row {
  display: flex;
  flex-direction: column;
  width: 116px;
  text-align: center;
}
.mainModal .subModalBlock .learningPurchaseModalContent .profile-row.profile-right-row {
  align-items: flex-start;
  justify-content: center;
  width: auto;
}
.mainModal .subModalBlock .learningPurchaseModalContent .profile-row .purchaseProfile {
  height: 116px;
  width: 116px;
  object-fit: contain;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .learningPurchaseModalContent .profile-row .purchaseProfile {
    width: 90px;
    height: 90px;
  }
}
.mainModal .subModalBlock .learningPurchaseModalContent .profile-row .farmTech {
  width: 127px;
  height: 69px;
  object-fit: cover;
  border-radius: 8px;
  margin: 15px 0;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .learningPurchaseModalContent .profile-row .farmTech {
    width: 100px;
    height: 50px;
  }
}
.mainModal .subModalBlock .learningPurchaseModalContent .profile-row h4 {
  color: #000;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .learningPurchaseModalContent .profile-row h4 {
    font-size: 13px;
  }
}
.mainModal .subModalBlock .learningPurchaseModalContent .profile-row h5 {
  color: #5a5a5a;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.93;
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm {
  margin-top: 20px;
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm label {
  margin-bottom: 10px;
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm .outlineTextfield {
  margin-bottom: 30px;
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm .outlineTextfield .MuiFormControl-root {
  border-radius: 8px;
  border: solid 1px #979797 !important;
  height: 50px;
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm .btnRow .MuiFormControl-root {
  height: 50px;
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm .btnRow .MuiButtonBase-root {
  min-width: 162px;
  height: 55px;
}
@media screen and (max-width: 767px) {
  .mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm .btnRow .MuiButtonBase-root {
    min-width: 100%;
  }
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm .btnRow .MuiButtonBase-root .MuiButton-label span {
  color: #fff;
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm .btnRow .MuiButtonBase-root:hover {
  box-shadow: 1px 1px 12px #d8d5d5;
  background-color: initial;
}
.mainModal .subModalBlock .learningPurchaseModalContent .purchaseForm .btnRow .MuiButtonBase-root:hover .MuiButton-label span {
  color: inherit;
}

.orderStatusPaper {
  margin-top: 5px;
  border: 1px solid #d3d3d3;
}
.orderStatusPaper ul {
  padding: 0px;
}
.orderStatusPaper li {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #5a5a5a;
  padding: 6px 12px;
}

.learningModalBlocks .subModalBlock {
  background-color: #ffffff;
  border-radius: 20px;
}
.learningModalBlocks .subModalBlock .modalHeader {
  padding: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.learningModalBlocks .subModalBlock .modalHeader h4 {
  text-align: center;
  margin-left: auto;
}
.learningModalBlocks .subModalBlock .modalHeader .closeIcon {
  margin-left: auto;
  font-size: 30px;
  cursor: pointer;
}
.learningModalBlocks .subModalBlock .modalHeader.selectTypeHeader h4 {
  margin-left: unset;
  justify-content: center;
  width: 100%;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent {
  padding: 20px;
  overflow-y: auto;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent label {
  color: #394053;
  font-weight: 600;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent input::-webkit-input-placeholder, .learningModalBlocks .subModalBlock .learningAddPartModalContent textarea::-webkit-input-placeholder {
  color: #515156;
  font-weight: 400;
  opacity: 1;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent input::placeholder, .learningModalBlocks .subModalBlock .learningAddPartModalContent textarea::placeholder {
  color: #515156;
  font-weight: 400;
  opacity: 1;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .btnContainer .MuiButton-root {
  width: 100%;
  height: 50px;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .btnContainer .greenOutlineButton, .learningModalBlocks .subModalBlock .learningAddPartModalContent .btnContainer .greenContained {
  width: 100%;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .lesson-row {
  display: flex;
  align-items: center;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .lesson-row p {
  padding-left: 20px;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .titleBox {
  padding: 10px 0;
  margin: 20px 0;
  border-top: 1px solid #b6e0c8;
  border-bottom: 1px solid #b6e0c8;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .video-imgblock {
  display: flex;
  flex-direction: column;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .video-imgblock .lessonImg, .learningModalBlocks .subModalBlock .learningAddPartModalContent .video-imgblock video {
  width: 180px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .video-imgblock .captionBlock {
  width: 180px;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .video-imgblock .captionBlock .deleteIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .video-imgblock .img-title {
  width: 135px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .video-imgblock .lessonImg {
  width: 180px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}
.learningModalBlocks .subModalBlock .learningAddPartModalContent .customTextAreafield textarea {
  margin-bottom: 0;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer {
  width: 100%;
  margin-top: 20px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader p {
  color: #676b72 !important;
  font-size: 16px;
  width: auto;
  text-align: left;
  margin: 10px 0;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader .checkboxContainer {
  display: flex;
  align-items: center;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader .checkboxContainer label {
  color: #676b72 !important;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .mcqaHeader .customToggleButton .switch {
  margin-top: 0 !important;
  margin-left: 10px !important;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer h3 {
  color: #464a53;
  font-size: 16px;
  width: 100%;
  text-align: left;
  font-weight: 700;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px 0;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .regRestInfoForm {
  position: relative;
  width: 100%;
  margin-left: 10px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .MuiFormControl-root {
  height: 53px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer {
  position: absolute;
  top: 8px;
  right: -5px;
  border-radius: 5px;
  padding: 5px 10px;
  width: 75px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer.contentContainer1 {
  top: 2px;
  left: -6px;
  width: 100%;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer.contentContainer1 .iconContainer {
  width: 70px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer img {
  width: 60px;
  height: 40px;
  object-fit: contain;
}
@media screen and (max-width: 448px) {
  .learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer img {
    width: 30px;
  }
}
@media screen and (max-width: 375px) {
  .learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer img {
    width: 20px;
    height: 30px;
  }
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer .imageTitle {
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 540px) {
  .learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer .imageTitle {
    width: 100px;
  }
}
@media screen and (max-width: 448px) {
  .learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer .imageTitle {
    width: 80px;
  }
}
@media screen and (max-width: 375px) {
  .learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer .imageTitle {
    width: 50px;
  }
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer span:nth-child(2) {
  color: #52647c;
  font-weight: bold;
  padding-left: 10px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .imgContainer span:nth-child(3) {
  color: #52647c;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .customFileUpload {
  padding: 0;
  margin: 0;
  border: 0;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .customFileUpload label {
  padding: 0;
  margin: 0;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .customFileUpload span {
  padding: 0;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .iconBlock {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .fileUpload {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .MuiFormControlLabel-root {
  margin: 0 0 0 5px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .MuiFormControl-root {
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .icon .MuiSvgIcon-root {
  transform: rotate(45deg);
  color: #cfcfcf;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .MuiSvgIcon-root {
  color: #c8cace;
  margin-right: 10px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .customLabelCheckbox {
  position: relative;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .customLabelCheckbox .MuiSvgIcon-root {
  display: none;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .customLabelCheckbox .MuiIconButton-label {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin: 0 auto;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .Mui-checked {
  position: relative;
  padding: 0;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .Mui-checked::before {
  content: " ";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #3f526d;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .Mui-checked .MuiIconButton-label {
  position: relative;
  border: 0;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .Mui-checked .MuiIconButton-label::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 11px;
  background-color: #fff;
  left: 12px;
  top: 6px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .contentContainer .iconContainer .Mui-checked .MuiIconButton-label::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 3px;
  background-color: #fff;
  left: 7px;
  top: 14px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon .edit-icon {
  width: 30px;
  height: 40px;
  margin-left: 10px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon .mcqDeleteIcon {
  width: 20px;
  height: 24px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon .MuiButton-text {
  padding: 0 !important;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .answerLists .answer .icon .MuiButton-root {
  min-width: unset !important;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .btnContainer {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .btnContainer .addbtn {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0), #20a258;
  background-position: center;
  background-size: 50% 2px, 2px 50%;
  /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .btnContainer span:nth-child(2) {
  font-weight: 600;
  margin-left: 5px;
  color: #414141;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.learningModalBlocks .subModalBlock .mcqaModalContantainer .answerListContainer .taskPoint h3 {
  margin-bottom: 10px;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .taskPoint h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer {
  margin-top: 20px;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer h2 {
  font-size: 18px;
  margin: 10px 0;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer p {
  color: #595c64;
  margin-bottom: 10px;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .answer {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px 0;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer {
  border-radius: 5px;
  padding: 5px 15px;
  width: 100%;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer .radioButtonContainer .MuiFormControlLabel-root {
  margin: 0;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer.true {
  background-color: #e7f1e8;
  border: 1px solid #3e893d;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer.true .answerDescription {
  color: #3e893d;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer.false {
  background-color: #fbd8d8;
  border: 1px solid #f35c5f;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .answer .contentContainer.false .answerDescription {
  color: #f35c5f;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked {
  position: relative;
  padding: 25px;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked::before {
  content: " ";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #3f526d;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiRadio-root {
  padding: 9px;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiIconButton-label {
  position: relative;
  border: 0;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiIconButton-label .MuiSvgIcon-root {
  display: none;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiIconButton-label::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 11px;
  background-color: #fff;
  left: 0px;
  top: -7px;
}
.learningModalBlocks .subModalBlock .trueFalseModalConent .answersListContainer .iconContainer .Mui-checked .MuiIconButton-label::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 3px;
  background-color: #fff;
  left: -5px;
  top: 2px;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskPoint h6 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer {
  margin: 20px 0;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer h2 {
  font-size: 18px;
  margin: 15px 0;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .customTextfield .MuiFormControl-root {
  height: 54px;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow {
  margin-top: 10px;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow label {
  margin: 0;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow .css-1nvn3ta-JoyTextarea-root {
  height: 185px !important;
  --Textarea-focusedHighlight: inherit;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow .css-1nvn3ta-JoyTextarea-root p {
  text-align: right !important;
  color: #484848;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow textarea {
  font-family: "Poppins", sans-serif;
  overflow-y: scroll !important;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow textarea::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow textarea::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow textarea::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow textarea .MuiTextarea-variantOutlined:focus {
  border: 1px solid #ec9e31 !important;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow .customTextAreafield {
  position: relative;
  width: 100%;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .solutionRow .customTextAreafield .characterLength {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #484848;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .taskType {
  border: 1px solid rgba(193, 193, 193, 0.368627451);
  padding: 2px 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 10px 10px 0;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .taskType .MuiFormControlLabel-root {
  margin: 0;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .taskTypeContainer .taskType p {
  white-space: nowrap;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .iconContainer .Mui-checked {
  position: relative;
  padding: 25px;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .iconContainer .Mui-checked::before {
  content: " ";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #3f526d;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiRadio-root {
  padding: 9px;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiIconButton-label {
  position: relative;
  border: 0;
  /* IE 9 */
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.learningModalBlocks .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiIconButton-label .MuiSvgIcon-root {
  display: none;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiIconButton-label::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 11px;
  background-color: #fff;
  left: 0px;
  top: -7px;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .iconContainer .Mui-checked .MuiIconButton-label::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 3px;
  background-color: #fff;
  left: -5px;
  top: 2px;
}
.learningModalBlocks .subModalBlock .longAnsModalContent .iconContainer .MuiFormGroup-root {
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
}

.customTextAutosize .css-1nvn3ta-JoyTextarea-root {
  --Textarea-focusedHighlight: inherit;
  max-height: 200px !important;
}
.customTextAutosize .css-1nvn3ta-JoyTextarea-root p {
  text-align: right !important;
  color: #484848;
  font-size: 17px;
}
.customTextAutosize textarea {
  font-family: "Poppins", sans-serif;
  overflow-y: scroll !important;
}
.customTextAutosize textarea::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTextAutosize textarea::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTextAutosize textarea::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

.mcqWordInput .MuiInputBase-input {
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}
@media screen and (max-width: 520px) {
  .mcqWordInput .MuiInputBase-input {
    width: 60%;
  }
}
@media screen and (max-width: 4100px) {
  .mcqWordInput .MuiInputBase-input {
    width: 40%;
  }
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 480px) {
  .customPagination {
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
  }
}
.customPagination .MuiPagination-ul {
  grid-gap: 5px;
  gap: 5px;
}
.customPagination .MuiPagination-ul .MuiPaginationItem-page:hover {
  background-color: #20a258;
  color: #ffffff;
  border: 1px solid #20a258;
}
.customPagination .MuiPagination-ul .MuiPaginationItem-page.Mui-selected {
  background-color: transparent;
  color: #20a258;
  border: none;
}
.customPagination .MuiPagination-ul .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
  background-color: #20a258;
}
.customPagination .MuiPagination-ul li {
  border-radius: 8px;
  height: 32px;
  width: 32px;
}
.customPagination .MuiPagination-ul li button {
  margin: 0;
  padding: 0;
  border-radius: 8px;
}
.customPagination .MuiPagination-ul li:first-child, .customPagination .MuiPagination-ul li:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.customPagination .MuiPagination-ul li:first-child button, .customPagination .MuiPagination-ul li:last-child button {
  border: 1px solid #e2e2ea;
  background-color: #ffffff;
}
.customPagination .MuiPagination-ul li:first-child button svg, .customPagination .MuiPagination-ul li:last-child button svg {
  font-size: 22px;
  color: #92929d;
}
.customPagination .MuiPagination-ul li:first-child button:hover, .customPagination .MuiPagination-ul li:last-child button:hover {
  background-color: #20a258;
  border: 1px solid #20a258;
}
.customPagination .MuiPagination-ul li:first-child button:hover svg, .customPagination .MuiPagination-ul li:last-child button:hover svg {
  color: #fff;
}
.customPagination .MuiPagination-ul li:first-child button.Mui-disabled, .customPagination .MuiPagination-ul li:last-child button.Mui-disabled {
  background-color: #fff;
}
.customPagination .MuiPagination-ul li:first-child .MuiPaginationItem-page:hover, .customPagination .MuiPagination-ul li:last-child .MuiPaginationItem-page:hover {
  color: unset !important;
  border: unset;
}
.customPagination .pagePerViewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e2ea;
  border-radius: 8px;
  height: 32px;
}
.customPagination .pagePerViewContainer p {
  padding: 0px 12px;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-family: "Roboto", sans-serif;
  color: #92929d;
}
.customPagination .pagePerViewContainer .btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px;
  flex-direction: column;
  border-left: 1px solid #e2e2ea;
  padding: 0px 8px;
  height: 100%;
}
.customPagination .pagePerViewContainer .btnContainer .icon {
  width: 0;
  height: 0;
  border-radius: 5px;
  color: #92929d;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #92929d;
  cursor: pointer;
  padding: 0px;
}
.customPagination .pagePerViewContainer .btnContainer .icon:hover {
  border-left-color: #20a258;
}
.customPagination .pagePerViewContainer .btnContainer .icon:disabled {
  border-left-color: #92929d;
  cursor: default;
}
.customPagination .pagePerViewContainer .btnContainer .icon:nth-child(1) {
  transform: rotate(270deg);
}
.customPagination .pagePerViewContainer .btnContainer .icon:nth-child(2) {
  transform: rotate(90deg);
}

.restOrderPagination {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  bottom: -1;
  background-color: #fff;
  z-index: 1;
  border-top: 1px solid rgba(193, 193, 193, 0.4);
}
@media screen and (max-width: 480px) {
  .restOrderPagination div:nth-child(1) {
    display: none;
  }
}
.restOrderPagination div {
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #44444f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customProgressbar {
  margin-right: 15px;
  width: 100%;
}
.customProgressbar .circularprog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: 20px;
  padding: 80px 100px;
  background-color: #f7f7f7;
  border-radius: 15px;
}
@media screen and (max-width: 768px) {
  .customProgressbar .circularprog {
    margin-right: 20px;
    margin-left: 0px;
  }
}
.customProgressbar .circularprog .circularprogLabel {
  padding: 20px 0;
  font-size: 17px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.customProgressbar .circularprog .circularProgressBar {
  position: relative;
}
.customProgressbar .circularprog .circularProgressBar p {
  position: absolute;
  padding: unset;
  top: 50%;
  transform: translateY(-85%);
  left: 0;
  font-size: 20px;
  font-weight: 600;
  color: #394053 !important;
  width: 100%;
  text-align: center;
}
.customProgressbar .circularprog h3 {
  padding: 20px 0;
  font-weight: 600;
  color: #394053;
}
.customProgressbar .circularprog .MuiCircularProgress-root {
  width: 130px !important;
  height: auto !important;
}

.customLinearProgressbar progress {
  border-radius: 7px;
  width: 100%;
  height: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.customLinearProgressbar progress::-webkit-progress-bar {
  background-color: #c1c1c1;
  border-radius: 7px;
}
.customLinearProgressbar progress::-webkit-progress-value {
  background-color: #20a258;
  border-radius: 7px;
}
.customLinearProgressbar progress::-moz-progress-bar {
  /* style rules */
  background-color: #20a258;
  border-radius: 7px;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customRadio {
  background-color: white;
}
.customRadio .Mui-checked {
  position: relative;
}
.customRadio .Mui-checked::before {
  content: " ";
  position: absolute;
  left: 15px;
  top: 15px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #20a258;
}
.customRadio .MuiFormControlLabel-root {
  background-color: white;
  margin: 0;
}
.customRadio .MuiFormControlLabel-root.MuiSelect-select {
  font-weight: 600;
  font-size: 15px;
  border-radius: 15px;
}
.customRadio .MuiSvgIcon-root {
  color: #20a258;
}
.customRadio .MuiSvgIcon-root:last-child {
  display: none;
}
.customRadio span {
  color: #5d5e6a;
  font-weight: 600;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.MuiMenu-paper .sortDropDown .MuiListItem-root {
  text-transform: capitalize;
}

@media screen and (max-width: 1024px) {
  .customSelect {
    width: 100%;
  }
}
.customSelect .MuiSelect-select {
  text-transform: capitalize;
}
.customSelect .MuiSelect-select.MuiSelect-select {
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  height: 20px;
  font-size: 18px;
  border-radius: 8px;
}
.customSelect .MuiInputBase-root:hover {
  border: 0;
}
.customSelect menuitem {
  font-weight: 600;
}
.customSelect .MuiSelect-select:focus {
  background-color: transparent;
}
.customSelect .MuiInput-underline::before {
  border: 0;
}
.customSelect .MuiInput-underline::after {
  border: 0;
}
.customSelect .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: 0;
}
.customSelect .MuiInput-underline:focus:not(.Mui-disabled)::before {
  border: 0;
}
.customSelect .MuiFormControl-root {
  width: 100%;
  background-color: transparent !important;
  border: 0px solid transparent !important;
}

.outlineSelect {
  width: 100%;
}
.outlineSelect .MuiSelect-select {
  padding: 12px 25px 12px 15px !important;
  border: 1px solid rgba(193, 193, 193, 0.368627451);
}
.outlineSelect .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
}
.outlineSelect .MuiSelect-select:focus {
  border-radius: 10px;
  border: 1px solid rgba(193, 193, 193, 0.368627451) !important;
}

.filledSelect {
  width: 100%;
}
.filledSelect .MuiInputBase-root {
  background-color: #20a258;
  color: #ffffff;
  border-radius: 8px;
  padding: 5px 20px 5px 20px;
  height: 53px;
  position: relative;
}
.filledSelect .MuiInputBase-root .MuiSvgIcon-root {
  display: none;
}
.filledSelect .MuiInputBase-root .MuiSelect-select {
  width: 100px;
  padding-right: 2px;
  padding-left: 15px;
}
.filledSelect .MuiInputBase-root .MuiSelect-select::before {
  content: " ";
  position: absolute;
  left: 20px;
  top: 20px;
  transform: translateY(-50%) rotate(-45deg);
  border: 0px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  border-top: 0px solid transparent;
  height: 10px;
  width: 10px;
}
.filledSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 600;
  margin-left: 5px;
  font-size: 15px;
}
.filledSelect .MuiFormControl-root {
  border: 1px solid transparent !important;
}
@media screen and (max-width: 1024px) {
  .filledSelect .MuiFormControl-root {
    width: 100%;
  }
}
.filledSelect.leftIcon .MuiInputBase-root {
  padding: 5px 20px 5px 0px;
}
.filledSelect.leftIcon .MuiInputBase-root .MuiSelect-select::before {
  content: " ";
  left: unset;
  right: 20px;
  top: 20px;
}

.menu-customize {
  font-weight: normal;
}
.menu-customize .MuiSelect-select.MuiInputBase-input {
  text-transform: capitalize;
}
.menu-customize .MuiPaper-rounded {
  border-radius: 10px;
}
.menu-customize .MuiMenuItem-root:hover {
  background-color: #e7ffed;
  color: #20a258;
}

.multilineSelect {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #f0f0f0 !important;
}
.multilineSelect .sorttext {
  font-size: 15px;
  width: 100%;
  padding: 10px 5px;
  white-space: nowrap;
}
.multilineSelect .customSelect {
  width: 100%;
  margin: 0px 0;
}
@media screen and (max-width: 1024px) {
  .multilineSelect .customSelect {
    width: 100%;
  }
}
.multilineSelect .customSelect .MuiFormControl-root {
  width: 100% !important;
  outline: none;
  background-color: #ffffff !important;
}
.multilineSelect .customSelect .MuiFormControl-root .MuiSelect-select.MuiSelect-select {
  font-weight: 900;
  font-size: 18px;
  position: relative;
}
.multilineSelect .customSelect .MuiFormControl-root .MuiSelect-select.MuiSelect-select::after {
  content: "";
  height: 39px;
  width: 1px;
  background-color: #f0f0f0;
  position: absolute;
  top: 0px;
  right: 25px;
}
.multilineSelect .customSelect .MuiFormControl-root .MuiSelect-select.MuiSelect-select.MuiInputBase-input {
  padding: 13px 40px 10px 3px !important;
  height: 100%;
}
.multilineSelect .customSelect .MuiFormControl-root .MuiSelect-select:focus {
  border-radius: 10px;
}

.green-outline {
  width: 100%;
}
.green-outline .MuiInputBase-root {
  background-color: #edf8f2;
  border: 1px solid #59bd83 !important;
  border-radius: 5px;
  padding: 0px;
  width: 100%;
}
.green-outline .MuiInputBase-root .MuiSvgIcon-root {
  color: #59bd83;
}
.green-outline .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.green-outline .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
  color: #59bd83;
  margin-right: 0px;
}
.green-outline .MuiInputBase-root .MuiSelect-select.MuiInputBase-input {
  padding: 10px 28px 10px 9px !important;
}
.green-outline .MuiInputBase-root:hover {
  border-radius: 5px 5px 0 0;
}
.green-outline .MuiFormControl-root {
  width: 100%;
}
.green-outline .dragdropUrl .MuiSelect-select {
  text-transform: none;
}
.green-outline .dragdropUrl .MuiSelect-select.MuiSelect-select {
  text-transform: none !important;
}

.orange-outline {
  width: 100%;
}
.orange-outline .MuiInputBase-root {
  background-color: #fcf6ec;
  border: 1px solid #dc9b2d !important;
  border-radius: 5px;
  padding: 0px;
  width: 100%;
}
.orange-outline .MuiInputBase-root .MuiSvgIcon-root {
  color: #dc9b2d;
}
.orange-outline .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.orange-outline .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
  color: #dc9b2d;
  margin-right: 0px;
}
.orange-outline .MuiInputBase-root .MuiSelect-select.MuiInputBase-input {
  padding: 10px 28px 10px 9px !important;
}
.orange-outline .MuiInputBase-root:hover {
  border-radius: 5px 5px 0 0;
}
.orange-outline .MuiFormControl-root {
  width: 100%;
}
.orange-outline .dragdropUrl .MuiSelect-select {
  text-transform: none;
}
.orange-outline .dragdropUrl .MuiSelect-select.MuiSelect-select {
  text-transform: none !important;
}

.deactiveStatus {
  width: 100%;
}
.deactiveStatus .MuiInputBase-root {
  background-color: rgba(221, 146, 147, 0.2392156863);
  border: 1px solid #fc4b4e !important;
  border-radius: 5px;
  padding: 0px;
  width: 100%;
}
.deactiveStatus .MuiInputBase-root .MuiSvgIcon-root {
  color: #fc4b4e;
}
.deactiveStatus .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.deactiveStatus .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
  color: #fc4b4e;
  margin-right: 0px;
}
.deactiveStatus .MuiInputBase-root .MuiSelect-select.MuiInputBase-input {
  padding: 10px 28px 10px 9px !important;
}
.deactiveStatus .MuiInputBase-root:hover {
  border-radius: 5px 5px 0 0;
}
.deactiveStatus .MuiFormControl-root {
  width: 100%;
}
.deactiveStatus .dragdropUrl .MuiSelect-select {
  text-transform: none;
}
.deactiveStatus .dragdropUrl .MuiSelect-select.MuiSelect-select {
  text-transform: none !important;
}

.adminRestDishesContainer .customSelect.dishCategorySelect .MuiInput-formControl,
.commonDropdown .customSelect.dishCategorySelect .MuiInput-formControl {
  margin-top: 0 !important;
}
.adminRestDishesContainer .customSelect.dishCategorySelect .MuiInputBase-root .MuiSvgIcon-root,
.commonDropdown .customSelect.dishCategorySelect .MuiInputBase-root .MuiSvgIcon-root {
  display: block;
}
.adminRestDishesContainer .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select::before,
.commonDropdown .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select::before {
  margin-right: 10px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #92929d;
}
.adminRestDishesContainer .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  align-items: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.1px;
  color: #44444f;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
    margin-left: 0;
  }
}
.adminRestDishesContainer .customSelect.dishCategorySelect .paper,
.commonDropdown .customSelect.dishCategorySelect .paper {
  background-color: red;
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInput-formControl,
.commonDropdown .customSelect.restaurantDropDown .MuiInput-formControl {
  margin-top: 0 !important;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root {
    padding: 0 !important;
  }
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root .MuiSvgIcon-root,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root .MuiSvgIcon-root {
  display: block;
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select::before,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select::before {
  content: "Restaurant:";
  margin-right: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #929292;
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #4b4b4b;
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select span,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select span {
  color: #4f4f4f;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown,
.commonDropdown .customSelect.commonOrdersDropDown {
  display: flex;
  justify-content: center;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root svg,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root svg {
  margin-right: 15px;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select {
  display: flex;
  justify-content: center;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #44444f;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select.Mui-disabled,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiInputBase-input,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiInputBase-input {
  padding: 10px 0px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect,
.commonDropdown .customSelect.dishSortSelect {
  border-radius: 10px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiFormControl-root,
.commonDropdown .customSelect.dishSortSelect .MuiFormControl-root {
  padding: 0px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiMenu-paper,
.commonDropdown .customSelect.dishSortSelect .MuiMenu-paper {
  border-radius: 10px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiMenu-paper ul,
.commonDropdown .customSelect.dishSortSelect .MuiMenu-paper ul {
  padding: 0px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root {
  border-radius: 10px !important;
  border: 1px solid #e2e2ea !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root.Mui-focused,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root.Mui-focused {
  border-radius: 15px 15px 0 0;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSvgIcon-root,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSvgIcon-root {
  display: block;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select {
  min-width: 150px !important;
  max-width: 150px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select::before,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select::before {
  content: " Sort by:";
  margin-right: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #92929d;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  margin-left: 0;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #44444f;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select::after,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select::after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #e2e2ea;
  position: absolute;
  top: 0px;
  right: 25px;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select.MuiInputBase-input,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select.MuiInputBase-input {
  padding: 6px 28px 6px 10px !important;
  text-transform: capitalize !important;
}
.adminRestDishesContainer .customSelect.dishListStatus,
.commonDropdown .customSelect.dishListStatus {
  width: 100%;
  height: 100%;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiFormControl-root,
.commonDropdown .customSelect.dishListStatus .MuiFormControl-root {
  height: 100%;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiInputBase-root,
.commonDropdown .customSelect.dishListStatus .MuiInputBase-root {
  border: 1px solid rgba(193, 193, 193, 0.368627451);
  border-radius: 10px;
  height: 100%;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select,
.commonDropdown .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select {
  padding: unset;
  padding: 10px 5px 10px 20px;
  text-transform: capitalize;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  text-align: left;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select:focus,
.commonDropdown .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select:focus {
  border-radius: 10px;
  border: 0px solid rgba(193, 193, 193, 0.368627451) !important;
}
.adminRestDishesContainer .customSelect.itemStatus,
.commonDropdown .customSelect.itemStatus {
  margin-top: 1px !important;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.itemStatus,
.commonDropdown .customSelect.itemStatus {
    margin-top: 0px !important;
  }
}
.adminRestDishesContainer .customSelect.itemStatus .MuiSelect-select,
.commonDropdown .customSelect.itemStatus .MuiSelect-select {
  background-color: #e7ffed;
  border: 1px solid #20a258;
  border-radius: 5px;
  color: #20a258;
  width: 100px;
  min-width: 85px !important;
  max-width: 150px !important;
  position: relative;
  margin-top: 12px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.itemStatus .MuiSelect-select,
.commonDropdown .customSelect.itemStatus .MuiSelect-select {
    margin-top: 0px;
  }
}
.adminRestDishesContainer .customSelect.itemStatus .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus .MuiSelect-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid #20a258;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.itemStatus.campaignSelect .MuiFormControl-root,
.commonDropdown .customSelect.itemStatus.campaignSelect .MuiFormControl-root {
    width: 85%;
  }
}
.adminRestDishesContainer .customSelect.itemStatus.campaignSelect .MuiSelect-select,
.commonDropdown .customSelect.itemStatus.campaignSelect .MuiSelect-select {
  padding-left: 10px;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.itemStatus.campaignSelect .MuiSelect-select,
.commonDropdown .customSelect.itemStatus.campaignSelect .MuiSelect-select {
    padding-right: 0;
  }
}
.adminRestDishesContainer .customSelect.itemStatus.danger-variant .MuiSelect-select,
.commonDropdown .customSelect.itemStatus.danger-variant .MuiSelect-select {
  background-color: rgba(221, 146, 147, 0.2392156863);
  border: 1px solid red;
  color: red;
}
.adminRestDishesContainer .customSelect.itemStatus.danger-variant .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus.danger-variant .MuiSelect-select::after {
  border-color: red;
}
.adminRestDishesContainer .customSelect.itemStatus.warning-variant .MuiSelect-select,
.commonDropdown .customSelect.itemStatus.warning-variant .MuiSelect-select {
  background-color: #ffefd3;
  border: 1px solid #ec9e31;
  color: #ec9e31;
}
.adminRestDishesContainer .customSelect.itemStatus.warning-variant .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus.warning-variant .MuiSelect-select::after {
  border-color: #ec9e31;
}
.adminRestDishesContainer .customSelect.itemStatus .draft .MuiSelect-select,
.commonDropdown .customSelect.itemStatus .draft .MuiSelect-select {
  color: #d98f08;
  border: 1px solid #d98f08;
  background-color: rgba(217, 143, 8, 0.08);
}
.adminRestDishesContainer .customSelect.itemStatus .draft .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus .draft .MuiSelect-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid #d98f08;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.adminRestDishesContainer .customSelect.itemStatus .deactive .MuiSelect-select,
.commonDropdown .customSelect.itemStatus .deactive .MuiSelect-select {
  color: #fc4b4e;
  border: 1px solid #fc4b4e;
  background-color: rgba(252, 75, 78, 0.08);
}
.adminRestDishesContainer .customSelect.itemStatus .deactive .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus .deactive .MuiSelect-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid #fc4b4e;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.adminRestDishesContainer .customSelect.itemStatus .MuiFormControl-root,
.commonDropdown .customSelect.itemStatus .MuiFormControl-root {
  margin-bottom: 2px;
}
.adminRestDishesContainer .customSelect.itemStatus .MuiSvgIcon-root,
.commonDropdown .customSelect.itemStatus .MuiSvgIcon-root {
  display: none;
}
.adminRestDishesContainer .customSelect.itemStatus .MuiInputBase-root,
.commonDropdown .customSelect.itemStatus .MuiInputBase-root {
  display: flex;
  justify-content: center;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown span,
.commonDropdown .customSelect.farmOrdersDropDown span {
  color: #4b4b4b;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown.prodFarmSelect .MuiSelect-select::before,
.commonDropdown .customSelect.farmOrdersDropDown.prodFarmSelect .MuiSelect-select::before {
  content: " Farm:" !important;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown .MuiSelect-select,
.commonDropdown .customSelect.farmOrdersDropDown .MuiSelect-select {
  color: #44444f !important;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown .MuiSelect-select::before,
.commonDropdown .customSelect.farmOrdersDropDown .MuiSelect-select::before {
  content: " Show:" !important;
  color: #92929d;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown .MuiSelect-select .MuiSelect-selectMenu,
.commonDropdown .customSelect.farmOrdersDropDown .MuiSelect-select .MuiSelect-selectMenu {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adminRestDishesContainer .customSelect.farmSortDropDown .MuiSelect-select,
.commonDropdown .customSelect.farmSortDropDown .MuiSelect-select {
  padding: 10px 25px 10px 15px !important;
  font-size: 14px !important;
  text-transform: capitalize;
}
.adminRestDishesContainer .customSelect.farmSortDropDown .MuiSelect-select::before,
.commonDropdown .customSelect.farmSortDropDown .MuiSelect-select::before {
  content: " Sort By:" !important;
}
.adminRestDishesContainer .reportsButton,
.commonDropdown .reportsButton {
  margin-left: 20px;
}
.adminRestDishesContainer.contentHeaderDropdown .MuiSelect-select::before,
.commonDropdown.contentHeaderDropdown .MuiSelect-select::before {
  display: none;
}

.css-1d9cypr-MuiStack-root .MuiSelect-select {
  color: #44444f !important;
}
.css-1d9cypr-MuiStack-root .MuiSelect-select::before {
  content: "Restaurant: " !important;
  color: #92929d;
}
@media screen and (max-width: 425px) {
  .css-1d9cypr-MuiStack-root {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin: 10px 0;
  }
}

.statusBox {
  width: 100% !important;
  max-width: 100% !important;
  padding: 10px;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customTab .MuiTabs-fixed {
  width: 100%;
}
.customTab .MuiTabs-root .MuiTabs-scroller.MuiTabs-indicator {
  background-color: blue;
  padding: 30px;
}
.customTab .MuiTabs-root .MuiTabs-scroller .PrivateTabIndicator-colorSecondary-5 {
  background-color: #20a258 !important;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer {
  border-bottom: 2px solid #c1c1c1;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper {
  text-transform: none;
  font-size: 16px;
  color: #575764;
  display: flex;
  align-items: center;
  font-weight: 800;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper .title {
  padding-right: 25px;
  color: #575764;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper .badge {
  position: absolute;
  right: 0;
  margin: 0;
  top: 24px;
  color: #fff;
  background-color: #ff8900;
  padding: 5px 8px;
  border-radius: 0;
  font-size: 15px;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .MuiTab-wrapper, .customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .title {
  color: #20a258;
}
.customTab .orderTab {
  padding: 0;
  overflow: auto;
}
.customTab .orderTab .MuiBox-root {
  padding: 0;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer {
  margin-top: 20px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root {
  box-shadow: none;
  border-radius: 0px;
  border-radius: none;
  min-height: 300px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
  background-color: #e3eaf7;
  color: #44444f;
  font-weight: 600;
  font-size: 13px;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  padding: 5px 10px;
  font-family: "Montserrat", sans-serif;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:first-child {
  width: 200px;
  padding-left: 40px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:last-child {
  padding-right: 10px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body {
  color: #000;
  font-weight: normal;
  font-size: 12px;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body:first-child {
  width: 200px;
  padding-left: 80px;
  font-weight: 500;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body:nth-child(5) {
  padding-left: 20px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body:last-child {
  padding-right: 20px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body.paymentCell {
  text-transform: uppercase;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body .customerCell {
  display: flex;
  align-items: center;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body .customerCell h5 {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .customerImage {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .orderPreparingRow {
  cursor: pointer;
}
.customTab .orderTab .cookingList {
  margin: 20px 0;
}
.customTab .orderTab .cookingList .cookingrow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f3f3f3;
  padding: 8px 30px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}
.customTab .orderTab .cookingList .cookingrow .blocks:nth-child(2) {
  flex-grow: 2;
}
.customTab .orderTab .cookingList .cookingrow .blocks:nth-child(3) {
  flex-grow: 3;
}
.customTab .orderTab .cookingList .cookingrow .blocks:nth-child(4) {
  flex-grow: 8;
}
.customTab .orderTab .cookingList .orderImg {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 10px;
}
.customTab .orderTab .cookingList h6 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.customTab .orderTab .cookingList h6.title {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
@media screen and (max-width: 560px) {
  .customTab .orderTab .cookingList h6 {
    font-size: 12px;
  }
}
.customTab .orderTab .cookingList h5 {
  font-size: 22px;
  color: #333;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 560px) {
  .customTab .orderTab .cookingList h5 {
    font-size: 16px;
  }
}
.customTab .orderTab .cookingList .addOns {
  font-family: "OpenSans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.customTab .orderTab .cookingList .outlineTextfield {
  margin-bottom: 10px;
}
.customTab .orderTab .cookingList .greenContained {
  height: auto !important;
}
.customTab .orderTab .cookingList .greenContained .MuiButton-root {
  background-color: orange;
  border-color: orange;
  padding: 10px 40px;
}
@media screen and (max-width: 560px) {
  .customTab .orderTab .cookingList .greenContained .MuiButton-root {
    padding: 5px 12px;
  }
}
.customTab .orderTab .cookingList .greenContained .MuiButton-root span {
  font-size: 17px;
}
.customTab .orderTab .cookingList .greenContained .MuiButton-root:hover {
  background-color: transparent;
  border-color: orange;
}
.customTab .orderTab .cookingList .greenContained .MuiButton-root:hover span {
  color: orange;
}
.customTab .orderTab .accordian {
  width: 100%;
}
.customTab .orderTab .accordian .scrollBlock {
  max-height: 120px;
  overflow: scroll;
}
.customTab .orderTab .accordian .scrollBlock::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .accordian .scrollBlock::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .accordian .scrollBlock::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .setItemBtn .MuiButton-root {
  padding: 5.5px 10.5px;
  margin-top: 3px;
}
.customTab .adminOrderTab {
  background-color: white;
  overflow: auto;
}
.customTab .adminOrderTab .MuiBox-root {
  padding: 0;
}
.customTab .adminOrderTab .dangerCheckbox {
  display: flex;
  width: 100%;
  align-items: center;
}
.customTab .adminOrderTab .dangerCheckbox.payment-checkbox {
  justify-content: center;
}
.customTab .restOrderTab .MuiBox-root {
  padding: 0 20px;
}
.customTab.courseDetailTab .MuiTabs-scroller {
  background-color: #f7f7f7;
}
.customTab.courseDetailTab .MuiTabs-flexContainer {
  justify-content: space-around;
  border-bottom: 1px solid transparent !important;
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .customTab.courseDetailTab .MuiTabs-flexContainer {
    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;
  }
  .customTab.courseDetailTab .MuiTabs-flexContainer::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    border-radius: 8px;
    scrollbar-face-color: #f7f7f7;
    scrollbar-track-color: #f7f7f7;
  }
  .customTab.courseDetailTab .MuiTabs-flexContainer::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #c3c3c3;
    scrollbar-face-color: #f7f7f7;
    scrollbar-track-color: #f7f7f7;
  }
  .customTab.courseDetailTab .MuiTabs-flexContainer::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
    box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
    scrollbar-face-color: #f7f7f7;
    scrollbar-track-color: #f7f7f7;
  }
}
.customTab.courseDetailTab .MuiTabs-flexContainer .MuiTab-root {
  padding: 15px 12px;
}
.customTab.courseDetailTab .MuiTabs-flexContainer .MuiTab-wrapper {
  font-size: 17px;
}
@media screen and (max-width: 767px) {
  .customTab.courseDetailTab .MuiTabs-flexContainer .MuiTab-wrapper {
    font-size: 14px;
  }
}
.customTab.courseDetailTab .tabPanel {
  height: 420px;
  min-height: 420px;
  overflow-y: auto;
  padding-top: 40px;
}
.customTab.courseDetailTab .tabPanel p {
  font-size: 16px;
  color: #1e1e1e;
  margin-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .customTab.courseDetailTab .tabPanel p {
    font-size: 14px;
  }
}
.customTab.customfarmTab {
  background-color: #ffffff;
  height: 100%;
}
.customTab.customfarmTab .MuiTabs-root {
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.customTab.customfarmTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
  color: green;
}
.customTab.restDetailTab {
  background-color: #ffffff;
  height: 100%;
}
.customTab.restDetailTab .MuiTabs-root {
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 0;
}
.customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
  padding: 23px 18px !important;
  flex-shrink: unset;
}
@media screen and (max-width: 560px) {
  .customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
    padding: 23px 8px !important;
  }
}
.customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper {
  text-transform: none !important;
  font-size: 15px;
}
@media screen and (max-width: 560px) {
  .customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper {
    font-size: 12px;
  }
}
.customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
  color: #20a258;
}
.customTab.restDetailTab .MuiBox-root {
  padding: 0;
}
.customTab.learningCourseDetailTab .MuiBox-root {
  padding: 0;
}
.customTab.learningCourseDetailTab .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
  color: #20a258;
}
.customTab .badge .MuiBadge-anchorOriginBottomRightRectangle {
  bottom: 50% !important;
}
.customTab .badge .MuiBadge-badge {
  border-radius: 2px;
  background-color: #ff8900;
  color: white;
  font-weight: 700;
}

.processCell .MuiButton-containedPrimary {
  background-color: #20a258;
}
.processCell .MuiButton-containedPrimary span {
  color: #ffffff;
}
.processCell .MuiButton-containedPrimary :hover {
  background-color: #20a258;
  color: #ffffff;
}
.processCell .greenContained .MuiButton-root .MuiButton-label span {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.customTab .orderTab .cookingList h6 {
  color: #333;
}

.orderRest .MuiSelect-select.MuiSelect-select {
  color: #000;
}

.farmCollapse .cookingList {
  margin: 20px 0;
}
.farmCollapse .cookingList .cookingrow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f3f3f3;
  padding: 8px 30px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}
.farmCollapse .cookingList .cookingrow .blocks:nth-child(2) {
  flex-grow: 2;
  padding-left: 30px;
}
.farmCollapse .cookingList .cookingrow .blocks:nth-child(3) {
  flex-grow: 3;
}
.farmCollapse .cookingList .cookingrow .blocks:nth-child(4) {
  flex-grow: 8;
}
.farmCollapse .cookingList .orderImg {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 10px;
}
.farmCollapse .cookingList h6 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.farmCollapse .cookingList h6.title {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.farmCollapse .cookingList h5 {
  font-size: 22px;
  color: #333;
  font-weight: 600;
}
.farmCollapse .cookingList .addOns {
  font-family: "OpenSans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.farmCollapse .cookingList .outlineTextfield {
  margin-bottom: 10px;
}
.farmCollapse .cookingList .greenContained {
  height: auto !important;
}
.farmCollapse .cookingList .greenContained .MuiButton-root {
  background-color: orange;
  border-color: orange;
  padding: 10px 40px;
}
.farmCollapse .cookingList .greenContained .MuiButton-root span {
  font-size: 17px;
}
.farmCollapse .cookingList .greenContained .MuiButton-root:hover {
  background-color: transparent;
  border-color: orange;
}
.farmCollapse .cookingList .greenContained .MuiButton-root:hover span {
  color: orange;
}
.farmCollapse .accordian {
  width: 100%;
}
.farmCollapse .accordian .scrollBlock {
  max-height: 120px;
  overflow: scroll;
}
.farmCollapse .accordian .scrollBlock::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.farmCollapse .accordian .scrollBlock::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.farmCollapse .accordian .scrollBlock::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

.MuiTabs-scroller.MuiTabs-fixed {
  overflow-x: hidden !important;
}

.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.tableContainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  padding-right: 0;
}
.tableContainer::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer td {
  font-family: "Montserrat", sans-serif;
  font-size: 14px !important;
}
.tableContainer td h5 {
  font-size: 14px !important;
}
.tableContainer .MuiTableCell-stickyHeader {
  position: unset;
}
.tableContainer .tableHeaderContainer.MuiTableContainer-root {
  height: 500px !important;
  min-height: 500px !important;
}
.tableContainer .tableBodyContainer.MuiTableContainer-root {
  height: 500px !important;
  min-height: 500px !important;
}
.tableContainer .headerListContainer {
  padding: 20px;
}
.tableContainer .headerListContainer .prodListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1299px) {
  .tableContainer .headerListContainer .prodListHeader {
    flex-wrap: wrap;
  }
}
.tableContainer .headerListContainer .prodListHeader .link {
  font-weight: 600;
  color: #394053;
}
.tableContainer .headerListContainer .prodListHeader .link:hover {
  color: #20a258;
  text-decoration: none;
}
.tableContainer .headerListContainer .prodListHeader h4,
.tableContainer .headerListContainer .prodListHeader h6 {
  font-weight: bold;
  white-space: nowrap;
}
.tableContainer .headerListContainer .prodListHeader .blocks {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .blocks {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
.tableContainer .headerListContainer .prodListHeader .orderblocks {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .orderblocks {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .orderblocks {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.tableContainer .headerListContainer .prodListHeader .block1 .btnContainer .MuiButton-label {
  color: #fff;
  text-transform: capitalize;
  padding: 5px 0px;
}
@media screen and (max-width: 1299px) {
  .tableContainer .headerListContainer .prodListHeader .block1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    margin: 10px 0;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0px !important;
    margin: 10px 0;
    align-items: flex-start;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .addButton {
  padding-left: 10px;
}
@media screen and (max-width: 767px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root {
    width: 100%;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label .plus_icon::after {
    position: relative;
    left: -10px;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label .plus_icon::before {
    position: relative;
    left: -10px;
    top: 4px;
  }
}
@media screen and (max-width: 600px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton {
    width: 100%;
    padding-left: 0px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .rightOrders {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .rightOrders {
    margin-top: 10px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .text {
  padding-right: 10px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 20px;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .text {
    width: 100px;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .text {
    margin-right: 24px;
    font-size: 14px;
    width: 100px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .customSelect .MuiFormControl-root {
  width: 100%;
}
.tableContainer .headerListContainer .prodListBottomRow {
  margin-top: 20px;
  display: flex;
}
.tableContainer .headerListContainer .prodListBottomRow .MuiInput-formControl {
  margin-top: 0;
}
.tableContainer .headerListContainer .prodListBottomRow .MuiInputLabel-root {
  left: 13px;
  color: #ccc;
  font-weight: 500;
  transform: translate(0, 16.5px) !important;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .block1 {
  padding-left: 20px;
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect .customSelect {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect .customSelect .MuiFormControl-root {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .outlineTextfield {
  width: 83%;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .outlineTextfield {
    width: 100%;
  }
}
.tableContainer.MuiTableContainer-root {
  box-shadow: 0px 0px 4px 0px #ffffff;
  overflow: inherit;
  overflow-x: auto;
}
.tableContainer.MuiTableContainer-root .tableHeader {
  padding: 0 5px;
}
.tableContainer.MuiTableContainer-root .tableHeader h6, .tableContainer.MuiTableContainer-root .tableHeader a {
  font-weight: 600;
  color: #394053;
}
.tableContainer.MuiTableContainer-root .tableHeader h6:hover, .tableContainer.MuiTableContainer-root .tableHeader a:hover {
  color: #20a258;
  text-decoration: none;
}
.tableContainer.MuiTableContainer-root .tableHead {
  z-index: 5;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -1px !important;
  font-size: 11px;
  color: #44444f;
}
.tableContainer.MuiTableContainer-root .tableHead .tableHead-menu-column {
  width: 50px !important;
}
.tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
  padding: 5px 10px;
  background-color: #e3eaf7;
  color: #808080;
  font-weight: 600;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  border-top: 1px solid rgb(224, 224, 224);
}
@media screen and (max-width: 768px) {
  .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
    padding: 5px 20px;
  }
}
.tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:first-child {
  width: 200px;
  padding-left: 20px;
}
.tableContainer.MuiTableContainer-root .tableHead.dashBoadTableHead {
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.tableContainer.MuiTableContainer-root .tableHead.dashBoadTableHead .MuiTableCell-head {
  text-transform: none;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body {
  color: #515156;
  font-size: 17px;
  white-space: nowrap;
  padding: 20px 10px;
  cursor: pointer;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body:first-child {
  padding: 16px 20px;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body.BoldCell {
  font-weight: 600;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body.paymentCell {
  text-transform: uppercase;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body .productCell {
  display: flex;
  align-items: center;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body .productCell h5 {
  padding-left: 30px;
  font-weight: bold;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.tableContainer .productHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0px;
  margin-left: -5px;
}
.tableContainer .productHead span {
  padding-left: 24px;
}
.tableContainer .productHead span:nth-child(2) {
  font-family: "Poppins", sans-serif !important;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: #44444f;
}
.tableContainer .checkboxnameCell {
  display: flex;
  align-items: center;
}
.tableContainer .checkboxnameCell .customCheckbox {
  margin-right: 20px;
}
.tableContainer .checkboxnameCell span:nth-child(2) {
  color: #515156;
}
.tableContainer .MuiTableCell-body {
  color: #515156;
  font-size: 17px;
  white-space: nowrap;
}
.tableContainer .MuiTableCell-body.BoldCell {
  font-weight: 600;
}
.tableContainer .MuiTableCell-body.paymentCell {
  text-transform: uppercase;
}
.tableContainer .MuiTableCell-body .productCell {
  display: flex;
  align-items: center;
  width: 100%;
}
.tableContainer .MuiTableCell-body .productCell h5 {
  padding-left: 30px;
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  letter-spacing: 0.23px;
  color: #171725;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.tableContainer .MuiTableCell-body .productCell p {
  padding-left: 20px;
  font-weight: bold;
  font-size: 15px;
  width: 200px;
  word-break: break-word;
  word-wrap: wrap;
  white-space: pre-wrap;
}
.tableContainer .outlineChip .customChips.MuiChip-root {
  background-color: transparent;
  border: 1px solid #6ddfba;
}
.tableContainer .outlineChip .customChips.MuiChip-root span {
  color: #6ddfba;
  text-transform: uppercase;
}
.tableContainer .prodImage {
  height: 80px;
  width: 80px;
  object-fit: fill;
  border-radius: 8px;
}
.tableContainer .table-block .tableHead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}
.tableContainer .table-block .tableHead .MuiTableCell-head {
  padding: 5px 0;
  background-color: #e3eaf7;
  color: #44444f;
  font-weight: 600;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif !important;
  font-size: 11px;
  letter-spacing: 0.8px;
}
.tableContainer .table-block .tableHead .MuiTableCell-head:first-child {
  width: 200px;
  padding-left: 20px;
}
.tableContainer .table-block .tableHead .MuiTableCell-head:nth-child(4) {
  text-align: center;
}
.tableContainer .table-block .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body .productCell {
  width: 100%;
}
.tableContainer .table-block .MuiTableContainer-root {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 4px 0px #ffffff;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer.analyticsTable {
  width: 100%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
  background-color: #ffffff;
  color: #394053;
  font-size: 12px;
  letter-spacing: 2px;
  padding-left: 0;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .tableContainer.analyticsTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
    padding: 5px 15px;
  }
}
.tableContainer.analyticsTable.MuiTableContainer-root .MuiTableCell-body {
  padding: 8px 0;
  border: 0;
}
.tableContainer.analyticsTable.MuiTableContainer-root .MuiTypography-body1 {
  color: #394053;
  font-size: 15px;
  white-space: nowrap;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell:first-child {
  grid-gap: 20px;
  gap: 20px;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell p {
  padding: 0;
  font-weight: normal;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentSuccessIcon {
  background-color: #6ddfba;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentFailIcon {
  background-color: red;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentRefundIcon {
  background-color: #ff8d8d;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentProcessIcon {
  background-color: #5babfb;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 40%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 10%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 10%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(6) {
  width: 5%;
}
.tableContainer .restforums .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
  font-weight: normal;
}
.tableContainer .restforums .prodImage {
  margin-left: 20px;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(2) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(3) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(4) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .prodImage {
  margin-left: 20px;
}
.tableContainer .restDishesTable {
  border-radius: 0px !important;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 40%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 10%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 10%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .restDishesTable .prodImage {
  margin-left: 20px;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 20%;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root h5 {
  font-size: 17px;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 35%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 30%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 8%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 39%;
}
@media screen and (max-width: 768px) {
  .tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
    padding-left: 20px;
  }
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 3%;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar {
  width: 100%;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale span {
  color: #394053;
  font-size: 12px;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale span:nth-child(2) {
  margin: 0 10px;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .customLinearProgressbar {
  width: 100%;
}
.tableContainer .campTable .prodImage {
  margin-left: 20px;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
  text-align: left;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  text-align: center;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .MuiTableCell-body {
  text-align: left;
  font-size: 15px;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .MuiTableCell-body.accordianCell {
  padding: 0;
  border: 0;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root h5 {
  font-size: 15px;
  font-weight: 700;
}
.tableContainer.purchaseTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
  padding-right: 50px;
}
.tableContainer.purchaseTable.MuiTableContainer-root .MuiTableCell-body {
  padding-right: 50px;
}
.tableContainer.learningPurchasesContainer .tableHead {
  z-index: 5;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -1px !important;
  font-size: 11px;
  color: #44444f;
}
.tableContainer.learningPurchasesContainer .tableHead .MuiTableCell-head {
  text-align: left !important;
}
.tableContainer.learningPurchasesContainer .dropDownCell {
  text-align: right !important;
}
.tableContainer.learningPurchasesContainer .course-purchase-row {
  display: flex;
  align-items: center;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .VideoInput_video, .tableContainer.learningPurchasesContainer .course-purchase-row .techPurchase {
  height: 50px;
  width: 80px;
  object-fit: cover;
  border-radius: 15px;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .customerPurchaseDefaultCourse {
  height: 50px;
  width: 80px;
  object-fit: cover;
  border-radius: 15px;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .customerPurchase {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.tableContainer.learningPurchasesContainer .course-purchase-row h5 {
  margin-left: 10px;
  width: 150px;
  white-space: normal;
  line-height: 25px;
}

.blocks h6 {
  color: #333;
}

.course-purchase-dialog-row {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.course-purchase-dialog-row .VideoInput_video, .course-purchase-dialog-row .techPurchase {
  width: 240px;
  object-fit: cover;
  border-radius: 15px;
}
.course-purchase-dialog-row .customerPurchaseDefaultCourse {
  width: 240px;
  object-fit: cover;
  border-radius: 15px;
}
.course-purchase-dialog-row .customerPurchase {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.course-purchase-dialog-row h5 {
  margin-left: 10px;
  width: 150px;
  white-space: normal;
  line-height: 25px;
}

.viewLink {
  color: #20a258;
  text-decoration: underline;
}
.viewLink:hover {
  color: #5babfb;
}

@media screen and (max-width: 772px) {
  .subModalBlockPurchase {
    padding-bottom: 20px;
  }
}

.learningCourseTitle {
  width: 200px !important;
  word-break: break-word !important;
  word-wrap: wrap !important;
  white-space: pre-wrap !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customTextfield .MuiInputLabel-root {
  position: absolute;
  top: -25px;
  font-size: 21px;
  font-weight: bold;
  color: #394053;
}
.customTextfield .MuiFormControl-root {
  background-color: #f0f0f0;
  border: 0;
  padding: 3px 10px;
  margin-top: 0px;
  border-radius: 10px;
  color: #394053;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.customTextfield .MuiFormControl-root .MuiInputBase-input::-webkit-input-placeholder {
  color: #394053;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.customTextfield .MuiFormControl-root .MuiInputBase-input::placeholder {
  color: #394053;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.customTextfield .MuiFormControl-root .MuiInput-underline::before {
  border: 0;
}
.customTextfield .MuiFormControl-root .MuiInput-formControl {
  margin: 0;
  width: 100%;
  font-size: 14px;
}
.customTextfield .MuiFormControl-root .MuiInput-underline::after {
  border: 0;
}
.customTextfield .MuiFormControl-root .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: 0;
}
.customTextfield .MuiFormControl-root .MuiInput-underline:focus:not(.Mui-disabled)::before {
  border: 0;
}
.customTextfield .MuiFormControl-root .customLabel {
  margin-bottom: 10px;
}

.outlineTextfield {
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 10px;
}
.outlineTextfield .errorMsg {
  margin: 0;
}
.outlineTextfield .MuiFormControl-root {
  background-color: transparent !important;
  border: 1px solid rgba(193, 193, 193, 0.368627451) !important;
  padding: 0px 10px;
}
.outlineTextfield .MuiInputBase-root input::-webkit-input-placeholder {
  color: red;
}
.outlineTextfield .MuiInputBase-root input::placeholder {
  color: red;
}
.outlineTextfield.readOnlyField .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 5px !important;
  text-align: center;
}
.outlineTextfield.boxRadiusBorder .MuiFormControl-root {
  border-radius: 8px;
}
.outlineTextfield.boxRadiusBorder .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 5px !important;
  font-family: "Poppins", sans-serif !important;
}
.outlineTextfield.inventoryTextbox .MuiFormControl-root {
  height: 54px;
}

.sideLine {
  border-left: 1px solid gray; /* add the border             */
  padding: 0 7px; /* left and right padding     */
}

.customTextAreafield textarea {
  resize: none;
  padding: 3px 10px;
  border-radius: 10px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  color: #394053;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.customTextAreafield textarea:focus {
  outline: none !important;
  border-color: #c1c1c1;
}

.normalTextArea textarea {
  resize: none;
  padding: 3px 10px;
  border-radius: 10px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.normalTextArea textarea:focus {
  outline: none !important;
  border-color: #c1c1c1;
}

.ownerEmailField {
  width: 31.5% !important;
}
@media screen and (max-width: 768px) {
  .ownerEmailField {
    width: 100% !important;
  }
}

.handleOpacity .Mui-disabled {
  opacity: 0.6;
}

.textFieldMultiline .MuiInputBase-input {
  font-size: 14px;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restaurantGooglemap .MuiInputBase-input {
  color: #1e1e1e;
}
.restaurantGooglemap .googlemap-data {
  position: relative;
}
.restaurantGooglemap .googlemap-data .suggestion-item, .restaurantGooglemap .googlemap-data .suggestion-item--active {
  padding: 5px 10px;
}
.restaurantGooglemap .googlemap-data .suggestion-item span, .restaurantGooglemap .googlemap-data .suggestion-item--active span {
  color: #808080;
  cursor: pointer;
}
.restaurantGooglemap .googlemap-data .suggestion-item span:hover, .restaurantGooglemap .googlemap-data .suggestion-item--active span:hover {
  color: #1e1e1e;
}
.restaurantGooglemap .googlemap-data .googlemap-dropdown {
  background-color: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  color: #000 !important;
  border-radius: 10px;
  padding: 8px 6px;
  z-index: 2;
  overflow-y: auto;
  color: #000;
}

body,
html {
  background-color: #e3eaf7 !important;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
body::-webkit-scrollbar,
html::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
body::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

.MuiDialogContent-root::-webkit-scrollbar,
.MuiMenu-paper::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.MuiDialogContent-root::-webkit-scrollbar-thumb,
.MuiMenu-paper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.MuiDialogContent-root::-webkit-scrollbar-track,
.MuiMenu-paper::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

input[type=radio],
input[type=checkbox] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: #394053;
}

h1,
.h1 {
  color: #394053;
  font-size: 30px;
  font-weight: 600;
  padding-right: 10px;
  font-family: "AllRoundGothicW01-Medium";
}
@media screen and (max-width: 1024px) {
  h1,
.h1 {
    font-size: 20px;
  }
}

h2 {
  color: #394053;
  font-size: 28px;
  font-weight: 100;
  font-family: "AllRoundGothicW01-Medium";
}
@media screen and (max-width: 1024px) {
  h2 {
    font-size: 18px;
  }
}

h4 {
  color: #394053;
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1024px) {
  h4 {
    font-size: 19px;
  }
}

h5 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1024px) {
  h5 {
    font-size: 16px;
  }
}

.h6 {
  color: #c1c1c1;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
}

span {
  color: #c3c3c3;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
@media screen and (max-width: 1024px) {
  p {
    font-size: 13px;
  }
}

a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

button,
a,
label {
  cursor: pointer;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Poppins", sans-serif;
}

.mainWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  overflow: hidden;
}

.contentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px 0;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .contentWrapper {
    padding: 8px 0;
  }
}
.contentWrapper .subContentWrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
@media screen and (max-width: 1024px) {
  .contentWrapper .subContentWrapper {
    margin-left: 0;
  }
}
@media screen and (max-width: 560px) {
  .contentWrapper .subContentWrapper {
    overflow-x: hidden;
    margin-left: 0;
  }
}

.FilterIcon {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.btn:focus {
  border: 0;
  box-shadow: none;
}
.btn.dropdown-toggle {
  padding: 0;
  position: relative;
}
.btn.dropdown-toggle::after {
  border: 0;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-color: #808080;
}

.searchIcon {
  color: #808080;
}

.form-group {
  position: relative;
  width: 100%;
}
.form-group .form-control {
  background-color: #f0f0f0;
  border: 0;
  padding: 12px;
  padding-left: 45px;
  border-radius: 10px;
  color: #c1c1c1;
}
.form-group .form-control::-webkit-input-placeholder {
  color: #c1c1c1;
}
.form-group .form-control::placeholder {
  color: #c1c1c1;
}
.form-group .form-control:focus {
  background-color: #f0f0f0;
  box-shadow: none;
}

.form-select {
  background-color: #20a258;
  color: #ffffff;
  background-image: none;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-menu {
  border-radius: 10px;
}
.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover {
  background-color: rgba(109, 223, 186, 0.2117647059);
}

.chartContainer {
  margin-bottom: 25px;
}
@media screen and (max-width: 1024px) {
  .chartContainer {
    margin: 10px 0 25px 0;
  }
}
.chartContainer h5 {
  font-size: 16px;
  font-weight: bolder;
  white-space: nowrap;
}
.chartContainer .chartCard {
  width: 100%;
}
.chartContainer .chartCard .charthead {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .chartContainer .chartCard .charthead {
    flex-direction: column;
  }
}
.chartContainer .chartCard .listMenu {
  display: flex;
}
@media screen and (max-width: 767px) {
  .chartContainer .chartCard .listMenu {
    margin-top: 10px;
  }
}
.chartContainer .chartCard .listMenu .listItems {
  margin: 0 15px;
  position: relative;
}
.chartContainer .chartCard .listMenu .listItems span {
  margin-left: 5px;
  color: #5d5e6a;
  font-size: 12px;
}
.chartContainer .chartCard .listMenu .listItems::before {
  content: " ";
  position: absolute;
  width: 13px;
  height: 15px;
  left: -15px;
  top: 5px;
  border-radius: 50%;
}
.chartContainer .chartCard .listMenu .listItems.primary::before {
  background-color: #5babfb;
}
.chartContainer .chartCard .listMenu .listItems.success::before {
  background-color: #20a258;
}

.farms_all_pages_container {
  width: 100vw;
  height: auto;
  background-color: #ffffff;
  padding-top: 30px !important;
  padding-bottom: 70px !important;
}
@media only screen and (max-width: 600px) {
  .farms_all_pages_container {
    padding-bottom: 30px !important;
    padding-top: 10px !important;
  }
}

.MuiInputBase-root {
  border-radius: 5px !important;
  border-color: rgba(193, 193, 193, 0.368627451) !important;
}

.MuiSelect-root > .CorrectIcon {
  display: none;
}

.custom_location_error {
  color: red;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
  padding: 4px 0px;
}

.detailsProfile {
  padding: 0 !important;
  margin-top: 0 !important;
}
.detailsProfile img {
  border-radius: 10px !important;
}

.newtextUpdateBlock {
  width: 81px !important;
  margin-top: 4px !important;
  margin-bottom: 14px;
}
.newtextUpdateBlock label {
  color: #20a258 !important;
}

.studentStatus {
  text-transform: capitalize;
}
.studentStatus.studentBlock {
  color: #F3666A;
}
.studentStatus.studentActive {
  color: #20a258;
}

.blocks h4 {
  font-family: "Poppins", sans-serif;
}

.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-body {
  border-bottom-color: transparent;
}

.imgLabel {
  width: 44px;
  height: 44px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.mainHeader {
  width: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .mainHeader {
    padding: 10px;
  }
}
.mainHeader .headerLogo {
  width: 135px;
  height: 45px;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .mainHeader .headerLogo {
    width: 100px;
  }
}
.mainHeader .blocks {
  padding: 0 8px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .mainHeader .blocks {
    padding: 0;
  }
}
.mainHeader .block1 .userBlock {
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.mainHeader .block1 .userBlock h5 {
  white-space: nowrap;
}
@media screen and (max-width: 1400px) {
  .mainHeader .block1 .userBlock {
    padding-left: 10px;
  }
  .mainHeader .block1 .userBlock h5 {
    font-size: 18px;
  }
  .mainHeader .block1 .userBlock span {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mainHeader .block1 .userBlock {
    display: none;
  }
}
.mainHeader .block1 .form-group {
  width: 100%;
}
.mainHeader .block1 .toggleIcon {
  margin-right: 10px;
  cursor: pointer;
}
@media screen and (min-width: 1025px) {
  .mainHeader .block1 .toggleIcon {
    display: none;
  }
}
.mainHeader .block2 {
  width: 800px;
}
@media screen and (max-width: 1670px) {
  .mainHeader .block2 {
    width: 500px;
  }
}
@media screen and (max-width: 1400px) {
  .mainHeader .block2 {
    width: 260px;
  }
}
@media screen and (max-width: 1024px) {
  .mainHeader .block2 {
    width: 100%;
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .mainHeader .headerSelect {
    display: none;
  }
}
.mainHeader .block3 {
  align-items: center;
}
.mainHeader .block3 .Icons {
  position: relative;
  margin: 0 12px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .mainHeader .block3 .Icons {
    margin: 0 10px;
  }
}
@media screen and (min-width: 1024px) {
  .mainHeader .block3 .Icons.headerSearchIcon {
    display: none;
  }
}
.mainHeader .block3 .Icons .notificationImg {
  height: 20px;
  width: 20px;
  -o-object-fit: contain;
     object-fit: contain;
}
.mainHeader .block3 .Icons span {
  content: " ";
  position: absolute;
  padding: 5px;
  height: 5px;
  width: 5px;
  top: 0;
  right: -1px;
  border-radius: 50%;
  background-color: #20a258;
}
@media screen and (max-width: 1024px) {
  .mainHeader .block3 .Icons span {
    right: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .mainHeader .block3 .form-select {
    display: none;
  }
}
.mainHeader .notificationWrapper {
  margin: 0px 12px;
}

.connectSquareHeaderWrapper {
  display: block;
}
@media screen and (max-width: 599px) {
  .connectSquareHeaderWrapper {
    display: none;
  }
}

.connectSquareBlockWrapper {
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 10px;
  margin-top: 8px;
  display: none;
}
@media screen and (max-width: 599px) {
  .connectSquareBlockWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.connectContainer {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
@media screen and (max-width: 599px) {
  .connectContainer {
    grid-gap: 8px;
    gap: 8px;
  }
}
.connectContainer .connectButton {
  padding: 9.5px 12px;
  border-radius: 10px;
  background-color: #ec9e31;
}
.connectContainer .connectButton:hover {
  background-color: #ec9e31;
}
.connectContainer .connectButton span {
  color: #ffffff;
  font-size: 0.9rem;
  text-transform: none;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 599px) {
  .connectContainer {
    width: 100%;
  }
  .connectContainer a {
    width: 100%;
  }
  .connectContainer .connectButton {
    width: 100%;
  }
}

.headerContentSm {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  display: none;
}
@media screen and (max-width: 1024px) {
  .headerContentSm {
    display: flex;
    margin: 0px 0 8px 0;
  }
}
@media screen and (max-width: 1024px) {
  .headerContentSm .blocks {
    display: flex;
    padding: 0 5px;
    margin: 5px 0;
    width: 100%;
  }
}
.headerContentSm .block1 .userBlock {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.headerContentSm .block1 .form-group {
  width: 100%;
}
.headerContentSm .block2 {
  width: 100%;
}
.headerContentSm .block3 {
  align-items: center;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customDropdown .userAvtar {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
}
.customDropdown .btnGroup {
  position: relative;
  cursor: pointer;
}
.customDropdown .btnGroup::before {
  content: " ";
  position: absolute;
  right: -5px;
  top: 20px;
  border: 0px;
  border-left: 3px solid #bababa;
  border-bottom: 3px solid #bababa;
  border-top: 0px solid transparent;
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  transition: transform 0.3s;
}
.customDropdown .btnGroupOpenMenu::before {
  transform: rotate(135deg);
}
.customDropdown .MuiButton-root:hover {
  background-color: transparent;
}

.menu-customize .MuiPaper-rounded {
  border-radius: 10px;
}
.menu-customize .MuiMenuItem-root:hover {
  background-color: #fff;
  color: #20a258;
}
.menu-customize .MuiMenuItem-root {
  text-transform: capitalize !important;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.11px;
  font-weight: 500;
  color: #000;
}

.option-dropDown {
  width: 100%;
}
.option-dropDown .customDropdown .userAvtar {
  display: none;
}
.option-dropDown .customDropdown .MuiMenuItem-root {
  text-transform: capitalize;
}
.option-dropDown .customDropdown .btnGroup {
  position: relative;
  height: 30px;
}
.option-dropDown .customDropdown .btnGroup::before {
  content: "...";
  position: absolute;
  text-align: center;
  left: 0px;
  top: -5px;
  border: 0px;
  overflow: hidden;
  color: #bababa;
  font-size: 20px;
  font-weight: 900;
  height: 100%;
  width: 100%;
  transform: rotate(0deg);
}
.option-dropDown .customDropdown .MuiButton-root:hover {
  background-color: transparent;
}
.sidebar {
  width: 320px;
  padding-top: 45px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9;
  position: relative;
  overflow-y: auto;
  overflow-x: auto;
}
.sidebar::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.sidebar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.sidebar .close-icon-wrapper {
  border-radius: 50%;
  padding: 4px;
  position: absolute;
  right: 12px;
  top: 12px;
  background-color: #f0f0f0;
}
.sidebar .close-icon-wrapper:hover {
  opacity: 0.8;
}
@media screen and (min-width: 1025px) {
  .sidebar .close-icon-wrapper {
    display: none;
  }
}
.sidebar .closeIcon {
  font-size: 25px;
  color: #20a258;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .sidebar {
    justify-content: flex-start;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    height: calc(100% - 0px);
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
  }
}
.sidebar .nav-pills .nav-link {
  width: 100%;
  padding: 12px 55px;
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
}
.sidebar .nav-pills .nav-link span {
  color: #c1c1c1;
  font-weight: 600;
}
.sidebar .nav-pills .nav-link .icons {
  padding-right: 8px;
}
.sidebar .nav-pills .nav-link.active, .sidebar .nav-pills .nav-link.show > .nav-link {
  background-color: #e7ffed;
}
.sidebar .nav-pills .nav-link.active span, .sidebar .nav-pills .nav-link.show > .nav-link span {
  color: #20a258;
}
.sidebar .nav-pills .nav-link.active .notification, .sidebar .nav-pills .nav-link.show > .nav-link .notification {
  color: white;
}
.sidebar .nav-pills .nav-link .notification {
  background-color: #20a258;
  border-radius: 50%;
  padding: 5px 10px;
  height: 30px;
  width: 30px;
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  right: 20px;
}
.sidebar .bottom-nav .nav-link span {
  color: #394053;
}
.sidebar .bottom-nav .nav-link.active, .sidebar .bottom-nav .nav-link.show > .nav-link {
  background-color: transparent;
}
.sidebar .bottom-nav .nav-link.active span, .sidebar .bottom-nav .nav-link.show > .nav-link span {
  color: #394053;
}
.sidebar .ad-campaign-sidebar-btn {
  border: none;
  background-color: transparent;
}

.toggleSidebar {
  display: flex;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customList .listItems {
  display: flex;
  padding: 15px 40px;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.customList .listItems .leftIcon::before {
  content: " ";
  position: absolute;
  right: 22px;
  bottom: 19px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #c1c1c1;
}
.customList .listItems .bottomIcon::before {
  content: " ";
  position: absolute;
  right: 32px;
  bottom: 24px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #c1c1c1;
  transform: rotate(-90deg);
  border-radius: 3px;
  transition: transform 0.3s;
}
.customList .listItems .bottomIconActive::before {
  content: " ";
  position: absolute;
  right: 32px;
  bottom: 24px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #20a258;
  border-radius: 3px;
  transition: transform 0.3s;
}
.customList .listItems .MuiSvgIcon-root {
  display: none;
}
.customList .listItems .MuiListItemIcon-root {
  min-width: 28px;
}
.customList .listItems .MuiTypography-root {
  color: #bbb;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  line-height: 1;
  text-transform: capitalize;
}
.customList .listItems:hover .MuiTypography-root, .customList .listItems:active .MuiTypography-root, .customList .listItems.listItemsActive .MuiTypography-root {
  color: #009540;
}
.customList .collapseList .listItems {
  padding: 0;
}
.customList .collapseList .MuiListItemText-root {
  margin-bottom: 2px;
  color: #c1c1c1;
  font-weight: 600;
  cursor: pointer;
}
.customList .collapseList .MuiListItemText-root span {
  color: #bbb;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 10px 55px 10px 75px !important;
}
.customList .collapseList .MuiListItemText-root:hover span, .customList .collapseList .MuiListItemText-root.listItemsTextActive span {
  background-color: #e7ffed;
  color: #20a258;
}
.customChips.MuiChip-root {
  border-radius: 7px;
  background-color: #6ddfba;
  padding: 4px 7px;
  height: auto;
  font-size: 0.6rem;
}
.customChips.MuiChip-root span {
  color: #ffffff;
  padding: 0;
  font-weight: 600;
}

.greenContainChip .customChips.MuiChip-root {
  background-color: #e7ffed;
  font-size: 0.7rem;
}
.greenContainChip .customChips.MuiChip-root span {
  color: #6ddfba;
}

.redContainChip .customChips.MuiChip-root {
  background-color: #fdeeee;
  font-size: 0.7rem;
}
.redContainChip .customChips.MuiChip-root span {
  color: #f59495;
}

.blueContainChip .customChips.MuiChip-root {
  background-color: #edf7fe;
}
.blueContainChip .customChips.MuiChip-root span {
  color: #76c4fc;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customButton .MuiButton-root {
  border-radius: 8px;
  background-color: #20a258;
  border: 2px solid #20a258;
}
.customButton .MuiButton-root:hover {
  background-color: transparent;
  border-color: #20a258;
}
.customButton .MuiButton-root:hover .custom_btn_loader {
  color: #20a258 !important;
}
.customButton .MuiButton-root:hover .MuiButton-label span {
  color: #20a258;
}
.customButton .MuiButton-root:hover .plus_icon::before {
  background-color: #20a258;
}
.customButton .MuiButton-root:hover .plus_icon::after {
  background-color: #20a258;
}
.customButton .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  text-transform: capitalize;
  white-space: nowrap;
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 767px) {
  .customButton .MuiButton-root .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.customButton .MuiButton-root .plus_icon::before {
  content: " ";
  width: 12px;
  height: 3px;
  background-color: #ffffff;
  display: block;
  position: absolute;
  transition: all 0.15s cubic-bezier(0.42, 0, 0.58, 1);
  opacity: 1;
  border-radius: 2px;
  transform: translate(-50%, -50%) rotate(90deg);
  left: 17px;
}
.customButton .MuiButton-root .plus_icon::after {
  content: " ";
  width: 12px;
  height: 3px;
  background-color: #ffffff;
  display: block;
  position: absolute;
  transition: all 0.15s cubic-bezier(0.42, 0, 0.58, 1);
  opacity: 1;
  border-radius: 2px;
  transform: translate(-50%, -50%);
  left: 17px;
}
.customButton.TransparentButton .MuiButton-root {
  padding: 15px;
  opacity: 1;
  border: 1px solid #20a258;
  background-color: rgba(32, 162, 88, 0.6);
}
.customButton.TransparentButton .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  text-transform: capitalize;
}

.greenFilledButton .MuiButton-root {
  border-radius: 8px;
  background-color: #20a258;
  padding: 10px 30px;
  border: 2px solid #20a258;
}
@media screen and (max-width: 1440px) {
  .greenFilledButton .MuiButton-root {
    padding: 5px 10px;
  }
}
.greenFilledButton .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  white-space: nowrap;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1440px) {
  .greenFilledButton .MuiButton-root .MuiButton-label span {
    font-size: 13px;
    padding-left: 0;
  }
}
.greenFilledButton .MuiButton-root:hover {
  background-color: transparent !important;
}
.greenFilledButton .MuiButton-root:hover .MuiButton-label span {
  color: #20a258;
}
.greenFilledButton .MuiButton-root .plus_icon {
  display: none;
}
.greenFilledButton.primaryBtn .MuiButton-root {
  background-color: #ec9e31;
  border-color: #ec9e31;
  padding: 15px;
}
.greenFilledButton.primaryBtn .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 18px;
}

.greenOutlineButton .MuiButton-root {
  border-radius: 8px;
  border: 1px solid #59bd83;
  background-color: #edf8f2;
}
.greenOutlineButton .MuiButton-root .MuiButton-label span {
  color: #59bd83;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 800;
  padding: 3px 20px;
}
.greenOutlineButton .MuiButton-root .plus_icon {
  display: none;
}
.greenOutlineButton .MuiButton-root:hover {
  background-color: #edf8f2;
}
.greenOutlineButton.reportsButton .MuiButton-root {
  background-color: transparent;
}
@media screen and (max-width: 560px) {
  .greenOutlineButton.reportsButton .MuiButton-label img {
    width: 25px !important;
    height: 20px !important;
  }
}
.greenOutlineButton.reportsButton .MuiButton-label span {
  color: #000;
  font-size: 16px;
  padding: 3px 10px;
}
@media screen and (max-width: 560px) {
  .greenOutlineButton.reportsButton .MuiButton-label span {
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .greenOutlineButton.reportsButton {
    justify-content: space-between;
    margin-left: 80px;
  }
}

.redOutlineButton .customButton .MuiButton-root {
  border-radius: #F3666A;
  border: 1px solid red;
  background-color: #fdeeee;
}
.redOutlineButton .customButton .MuiButton-root .MuiButton-label span {
  color: #F3666A;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 800;
  padding: 3px 20px;
}
.redOutlineButton .customButton .MuiButton-root .MuiButton-label:hover span {
  color: #F3666A;
}
.redOutlineButton .customButton .MuiButton-root .plus_icon {
  display: none;
}
.redOutlineButton .customButton .MuiButton-root:hover {
  background-color: red;
}
.redOutlineButton .customButton .MuiButton-root:hover .MuiButton-label span {
  color: #fdeeee;
}
.redOutlineButton.reportsButton .MuiButton-root {
  background-color: transparent;
}
.redOutlineButton.reportsButton .MuiButton-label span {
  color: #000;
  font-size: 16px;
  padding: 3px 10px;
}
@media screen and (max-width: 560px) {
  .redOutlineButton.reportsButton {
    justify-content: space-between;
  }
}

.greenOutlineGrayButton .MuiButton-root {
  border: 1px solid #c3c3c3;
  background-color: transparent;
}
.greenOutlineGrayButton .MuiButton-root .MuiButton-label span {
  color: #394053;
  font-weight: 600;
  padding-left: 0;
}
.greenOutlineGrayButton .MuiButton-root .plus_icon {
  display: none;
}

.plainButton .MuiButton-root {
  border: 0;
  background-color: transparent;
}
.plainButton .MuiButton-root .MuiButton-label span {
  color: #394053;
  font-size: 17px;
  font-weight: 600;
  padding-left: 0;
}
.plainButton .MuiButton-root .plus_icon {
  display: none;
}

.greenContained {
  height: 100%;
}
.greenContained .MuiButton-root {
  border-radius: 8px;
  border: 1px solid #59bd83;
  background-color: #20a258;
}
.greenContained .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.greenContained .MuiButton-root .plus_icon {
  display: none;
}

.redContained {
  height: 100%;
}
.redContained .MuiButton-root {
  border-radius: 8px;
  border: 1px solid #F3666A;
  background-color: #F3666A;
}
.redContained .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.redContained .MuiButton-root .plus_icon {
  display: none;
}
.redContained .MuiButton-root:hover {
  border-color: #F3666A;
}
.redContained .MuiButton-root:hover .MuiButton-label span {
  color: #F3666A;
}

.grayButton {
  height: 100%;
}
.grayButton .MuiButton-root {
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #f8f8f8;
}
.grayButton .MuiButton-root .MuiButton-label span {
  color: black;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.grayButton .MuiButton-root .plus_icon {
  display: none;
}
.grayButton .MuiButton-root:hover {
  background-color: #f8f8f8;
}

.darkGrayButton {
  height: 100%;
}
.darkGrayButton .MuiButton-root {
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: rgba(151, 151, 151, 0.68);
}
.darkGrayButton .MuiButton-root .MuiButton-label span {
  color: black;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.darkGrayButton .MuiButton-root .plus_icon {
  display: none;
}
.darkGrayButton .MuiButton-root:hover {
  background-color: rgba(151, 151, 151, 0.68);
}

.linkButtonWrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.linkButton {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.linkButton .MuiButton-root {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.linkButton .MuiButton-root span {
  width: 100% !important;
}
@media screen and (max-width: 768px) {
  .linkButton .MuiButton-root {
    padding: 0;
  }
}
.linkButton .MuiButton-root .MuiButton-label span {
  color: #f90202;
  font-size: 15px;
  text-transform: capitalize;
}
.linkButton .MuiButton-root .MuiButton-contained {
  box-shadow: 0;
}
.linkButton .MuiButton-root .plus_icon {
  display: none;
}
.linkButton .MuiButton-root:hover {
  background-color: transparent;
}

.circleBtn .MuiButton-root {
  border-radius: 40px;
  padding: 10px 40px;
  border: 1px solid #59bd83;
  background-color: #20a258;
}
.circleBtn .MuiButton-root .MuiButton-label span {
  color: #ffffff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  padding: 0;
}
.circleBtn .MuiButton-root .plus_icon {
  display: none;
}
.circleBtn .MuiButton-root:hover {
  background-color: transparent;
  border: 1px solid #20a258;
}
.circleBtn .MuiButton-root:hover span {
  color: #20a258;
}

.custom_btn_loader {
  color: #fff !important;
}

.cancel-order-button {
  background-color: #f90202 !important;
  border-radius: 6px !important;
  padding: 4px 14px !important;
  text-transform: none !important;
}
.cancel-order-button span {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customCheckbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.customCheckbox input + .is_same_number::before {
  border-radius: 50%;
}
.customCheckbox input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 14px;
  border: solid #20a258;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.customCheckbox input:checked + label::before {
  background-color: rgba(32, 162, 88, 0.08);
}
.customCheckbox input:checked + .is_same_number::after {
  border-radius: 50%;
  top: 7.2px;
  left: 2.75px;
  width: 11px;
  height: 11px;
  background-color: #20a258;
  transform: rotate(0) !important;
  border-width: 0;
}
.customCheckbox label {
  position: relative;
  cursor: pointer;
}
.customCheckbox label::before {
  content: "";
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  background-color: rgba(32, 162, 88, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: -1px !important;
}
.customCheckbox label.product_checkbox::before {
  margin-bottom: 5px;
}

.circleCheckbox input:checked + label::after {
  border: solid #ec9e31;
  border-width: 0 4px 4px 0;
}
.circleCheckbox input:checked + label::before {
  background-color: #ffffff;
}
.circleCheckbox label::before {
  border-radius: 50%;
  border-color: #ffffff;
}

.customLabelCheckbox .MuiSvgIcon-root {
  height: 1.2em;
  width: 1.2em;
}
.customLabelCheckbox .Mui-checked .MuiSvgIcon-root {
  color: #20a258;
}
.customLabelCheckbox .MuiTypography-body1 {
  color: #394053;
}

.customToggleButton .switch {
  cursor: pointer;
  width: 80px;
  height: 36px;
  background: #fff;
  position: relative;
  transition: background-color 0.2s;
  border: 1px solid #808080;
  border-radius: 30px;
  margin-left: 30px;
  margin-top: 20px;
}
@media screen and (max-width: 560px) {
  .customToggleButton .switch {
    width: 75px;
    height: 30px;
  }
}
.customToggleButton .switch .switchCheckbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}
.customToggleButton .switch .switchCheckbox:checked + .redSwitch {
  left: 100%;
  transform: translateX(-100%);
}
.customToggleButton .switch .switchCheckbox:checked + .greenSwitch {
  left: 100%;
  transform: translateX(-100%);
}
.customToggleButton .switch .switchCheckbox:checked + .switchButton {
  left: 97%;
  transform: translateX(-100%);
}
.customToggleButton .switch .switchButton {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 30px;
  height: 30px;
  transition: 0.3s;
  background-color: #20a258;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
.customToggleButton .switch .redSwitch {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  transition: 0.3s;
  background: red;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
@media screen and (max-width: 560px) {
  .customToggleButton .switch .redSwitch {
    width: 30px;
    height: 30px;
  }
}
.customToggleButton .switch .greenSwitch {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
  transition: 0.3s;
  background: #20a258;
  border-radius: 50%;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
@media screen and (max-width: 560px) {
  .customToggleButton .switch .greenSwitch {
    width: 30px;
    height: 30px;
  }
}
.customToggleButton .switch .switchLabels {
  display: relative;
  z-index: 0;
  height: 100%;
  font: 14pt "Segoe UI", Verdana, sans-serif;
}
.customToggleButton .switch .switchLabels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  font-size: 14px;
  color: #515156;
  font-weight: 600;
}
.customToggleButton .switch .switchLabels span:first-child {
  left: 0;
}
.customToggleButton .switch .switchLabels span:last-child {
  right: 0;
}
@media screen and (max-width: 560px) {
  .customToggleButton .switch .switchLabels span {
    font-size: 12px;
    width: 48%;
  }
}

.dangerCheckbox input:checked + label::before {
  background-color: #ec9e31;
  border: 1px solid #ec9e31;
  margin-right: 0;
  padding: 9px;
}
.dangerCheckbox input:checked + label::after {
  top: 4px;
  left: 6px;
  height: 13px;
  border-color: #ffffff;
  border-radius: 3px;
}

.grayBackGroundButton .switchLabel {
  font-size: 10px;
  margin-right: 10px;
  color: #515156;
  font-weight: bold;
}
.grayBackGroundButton .customToggleButton .switch {
  border: unset;
  background-color: #f0f0f0;
  margin: unset;
}
.grayBackGroundButton .acceptOrderBtn {
  display: flex;
  justify-content: center;
}

.mcqCheckWidth .MuiFormControlLabel-root {
  width: 28px !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 480px) {
  .customPagination {
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
  }
}
.customPagination .MuiPagination-ul {
  grid-gap: 5px;
  gap: 5px;
}
.customPagination .MuiPagination-ul .MuiPaginationItem-page:hover {
  background-color: #20a258;
  color: #ffffff;
  border: 1px solid #20a258;
}
.customPagination .MuiPagination-ul .MuiPaginationItem-page.Mui-selected {
  background-color: transparent;
  color: #20a258;
  border: none;
}
.customPagination .MuiPagination-ul .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
  background-color: #20a258;
}
.customPagination .MuiPagination-ul li {
  border-radius: 8px;
  height: 32px;
  width: 32px;
}
.customPagination .MuiPagination-ul li button {
  margin: 0;
  padding: 0;
  border-radius: 8px;
}
.customPagination .MuiPagination-ul li:first-child, .customPagination .MuiPagination-ul li:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.customPagination .MuiPagination-ul li:first-child button, .customPagination .MuiPagination-ul li:last-child button {
  border: 1px solid #e2e2ea;
  background-color: #ffffff;
}
.customPagination .MuiPagination-ul li:first-child button svg, .customPagination .MuiPagination-ul li:last-child button svg {
  font-size: 22px;
  color: #92929d;
}
.customPagination .MuiPagination-ul li:first-child button:hover, .customPagination .MuiPagination-ul li:last-child button:hover {
  background-color: #20a258;
  border: 1px solid #20a258;
}
.customPagination .MuiPagination-ul li:first-child button:hover svg, .customPagination .MuiPagination-ul li:last-child button:hover svg {
  color: #fff;
}
.customPagination .MuiPagination-ul li:first-child button.Mui-disabled, .customPagination .MuiPagination-ul li:last-child button.Mui-disabled {
  background-color: #fff;
}
.customPagination .MuiPagination-ul li:first-child .MuiPaginationItem-page:hover, .customPagination .MuiPagination-ul li:last-child .MuiPaginationItem-page:hover {
  color: unset !important;
  border: unset;
}
.customPagination .pagePerViewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e2ea;
  border-radius: 8px;
  height: 32px;
}
.customPagination .pagePerViewContainer p {
  padding: 0px 12px;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-family: "Roboto", sans-serif;
  color: #92929d;
}
.customPagination .pagePerViewContainer .btnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px;
  flex-direction: column;
  border-left: 1px solid #e2e2ea;
  padding: 0px 8px;
  height: 100%;
}
.customPagination .pagePerViewContainer .btnContainer .icon {
  width: 0;
  height: 0;
  border-radius: 5px;
  color: #92929d;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #92929d;
  cursor: pointer;
  padding: 0px;
}
.customPagination .pagePerViewContainer .btnContainer .icon:hover {
  border-left-color: #20a258;
}
.customPagination .pagePerViewContainer .btnContainer .icon:disabled {
  border-left-color: #92929d;
  cursor: default;
}
.customPagination .pagePerViewContainer .btnContainer .icon:nth-child(1) {
  transform: rotate(270deg);
}
.customPagination .pagePerViewContainer .btnContainer .icon:nth-child(2) {
  transform: rotate(90deg);
}

.restOrderPagination {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  bottom: -1;
  background-color: #fff;
  z-index: 1;
  border-top: 1px solid rgba(193, 193, 193, 0.4);
}
@media screen and (max-width: 480px) {
  .restOrderPagination div:nth-child(1) {
    display: none;
  }
}
.restOrderPagination div {
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #44444f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.norecord-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px 30px 20px;
}
.norecord-container h3 {
  text-align: center;
  color: #20a258;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 10px 0;
}
.norecord-container p {
  text-align: center;
  font-weight: 600;
  margin: 0 0 20px 0;
  word-spacing: 2px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.mediaContainer {
  padding: 25px 20px 25px 20px;
}
.mediaContainer.forumsMediaContainer {
  padding: 0;
}
.mediaContainer .mediaHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mediaContainer .mediaHeader h4 {
  color: #20a258;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .mediaContainer .mediaHeader h4 {
    font-size: 20px;
  }
}
.mediaContainer .mediaHeader .green-outline {
  width: 250px;
}
@media screen and (max-width: 768px) {
  .mediaContainer .mediaHeader .green-outline {
    width: 150px;
  }
}
.mediaContainer .mediaHeader .green-outline .MuiInputBase-root {
  border-radius: 5px;
}
.mediaContainer .mediaUploader {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 200px;
  text-align: center;
  border: 1px solid #c1c1c1;
  border-radius: 15px;
  color: #20a258;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mediaContainer .mediaUploader label {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mediaContainer .mediaUploader label .MuiSvgIcon-root {
  font-size: 90px;
}
@media screen and (max-width: 768px) {
  .mediaContainer .mediaUploader label .MuiSvgIcon-root {
    font-size: 70px;
  }
}
.mediaContainer .mediaUploader label input {
  display: none;
}
.mediaContainer .mediaUploader.videoMediaUploader {
  height: auto;
  margin-top: 0;
  width: 175px;
  padding: 7px 10px;
  border-radius: 10px;
  border-color: #20a258;
}
.mediaContainer .mediaUploader.videoMediaUploader .MuiSvgIcon-root {
  font-size: 30px;
  margin-right: 10px;
}
.mediaContainer .mediaUploader.videoMediaUploader label {
  flex-direction: row;
}
.mediaContainer .mediaUploader.videoMediaUploader label span {
  color: #20a258;
  font-weight: bold;
}
.mediaContainer .mediaUploader.videoMediaUploader {
  height: auto;
  margin-top: 0;
  width: 175px;
  padding: 7px 10px;
  border-radius: 10px;
  border-color: #20a258;
}
.mediaContainer .mediaUploader.videoMediaUploader .MuiSvgIcon-root {
  font-size: 30px;
  margin-right: 10px;
}
.mediaContainer .mediaUploader.videoMediaUploader label {
  flex-direction: row;
}
.mediaContainer .mediaUploader.videoMediaUploader label span {
  color: #20a258;
  font-weight: bold;
}
.mediaContainer .media-main-block {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
}
.mediaContainer .media-main-block .inputrow {
  display: flex;
  justify-content: space-between;
}
.mediaContainer .media-main-block .inputrow .MuiFormControl-root {
  flex: 1 1;
}
.mediaContainer .media-main-block .inputrow .btn-container {
  margin: 0px;
}
.mediaContainer .media-main-block .inputrow .btn-container .save {
  height: 45px;
}
.mediaContainer .media-main-block .btn-container {
  margin: 0;
}
.mediaContainer .media-main-block .media-chip-block {
  margin: 0 -8px 20px -8px;
  position: relative;
}
.mediaContainer .media-main-block .media-chip-block .customUrlrow {
  align-items: flex-start;
  width: 100%;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.mediaContainer .media-main-block .media-chip-block .customUrlrow .chipRow {
  display: flex;
  flex-wrap: wrap;
}
.mediaContainer .media-main-block .media-chip-block .customUrlrow .MuiButtonBase-root {
  height: 30px;
  margin: 5px 10px;
  width: 140px;
  overflow: hidden;
  justify-content: space-between;
  text-overflow: ellipsis;
}
.mediaContainer .media-main-block .media-chip-block .customUrlrow .MuiButtonBase-root span {
  color: #464a53;
  font-size: 13px;
}
.mediaContainer .media-main-block .media-chip-block .customUrlrow::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 100%;
}
.mediaContainer .media-main-block .media-chip-block .customUrlrow::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.mediaContainer .media-main-block .media-chip-block .customUrlrow::-webkit-scrollbar-thumb {
  background: #888;
}
.mediaContainer .media-main-block .media-chip-block .customUrlrow::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chipContainer {
  padding: 0;
  margin-top: 20px;
}
.chipContainer .upload-chip {
  display: flex;
  align-items: center;
  background-color: rgba(193, 193, 193, 0.5490196078);
  padding: 5px 8px !important;
  border-radius: 5px;
}
.chipContainer .upload-chip h6 {
  color: #757575;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
}
.chipContainer .upload-chip .closeIcon {
  width: 20px;
  height: 20px;
  color: #757575;
  margin-left: 10px;
}

.adminAddProductWrapper .MuiFormLabel-root {
  font-weight: 600;
}
.adminAddProductWrapper .outlineTextfield label {
  font-weight: 600;
  margin-bottom: 5px;
  color: #464a53;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .outlineTextfield label {
    font-size: 14px;
  }
}
.adminAddProductWrapper legend {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper legend {
    font-size: 15px;
  }
}
.adminAddProductWrapper p {
  margin-top: 5px;
  margin-bottom: 10px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #abafb3 !important;
  width: 100%;
  text-align: left;
  padding: 10px 0 10px 0;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper p {
    font-size: 13px;
  }
}
.adminAddProductWrapper .productDetailsContainer {
  background-color: #ffffff;
  border-radius: 15px;
}
.adminAddProductWrapper .productDetailsContainer .taskPoint-title {
  margin-bottom: 10px;
}
.adminAddProductWrapper .productDetailsContainer .texteditor-block {
  margin: 20px 20px 0 20px !important;
}
.adminAddProductWrapper .productDetailsContainer .customheading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adminAddProductWrapper .productDetailsContainer .customheading h4 {
  font-weight: 600;
  color: #20a258;
  padding-right: 10px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productDetailsContainer .customheading h4 {
    font-size: 20px;
  }
}
.adminAddProductWrapper .productDetailsContainer .customheading .optionalTitle {
  font-size: 14px;
  padding-left: 5px;
  color: #abafb3;
}
.adminAddProductWrapper .productDetailsContainer .customheading .codeTitle {
  color: #464a53 !important;
}
.adminAddProductWrapper .productDetailsContainer .customheading hr {
  width: 100%;
  color: #20a258;
}
.adminAddProductWrapper .productDetailsContainer .header {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productDetailsContainer .header {
    padding: 12px;
  }
}
.adminAddProductWrapper .productDetailsContainer .header > div {
  margin-right: 5px;
  padding: 10px;
  border-radius: 50%;
}
.adminAddProductWrapper .productDetailsContainer .header > div:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.adminAddProductWrapper .productDetailsContainer .header .MuiSvgIcon-root {
  font-size: 30px;
  cursor: pointer;
}
.adminAddProductWrapper .productDetailsContainer .header h4 {
  font-weight: 900;
  margin-left: 5px;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer {
  padding: 25px;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer {
  padding: 25px;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer label {
  font-weight: 600;
  color: #464a53;
  font-family: "Poppins", sans-serif !important;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer h1, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer h2, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer h3, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer h4, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer h5, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer h6, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer p {
  font-family: "Poppins", sans-serif !important;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer .accordianHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-top: 10px;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer .accordianHeader label, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer .accordianHeader .btn-container {
  margin: 0;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.learningCourseContainer .accordianHeader .btn-container {
  display: flex;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll {
  max-height: 600px !important;
  overflow-y: scroll;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
@media screen and (max-width: 540px) {
  .adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll {
    padding: 10px !important;
  }
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll h1, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll h2, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll h3, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll h4, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll h5, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll h6, .adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll p {
  font-family: "Poppins", sans-serif !important;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll .partContainerScroll {
  max-height: 500px !important;
  overflow-y: scroll;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll .partContainerScroll::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll .partContainerScroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer.contentContainerScroll .partContainerScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer .textarea-wrapper {
  margin-top: 10px;
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer .textarea-wrapper label {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productDetailsContainer .productTitleContainer .textarea-wrapper label {
    font-size: 13px;
  }
}
.adminAddProductWrapper .productDetailsContainer .productTitleContainer .textarea-wrapper textarea {
  width: 100%;
  border-radius: 15px;
  border-color: #c1c1c1;
  margin-top: 5px;
}
.adminAddProductWrapper .productDetailsContainer .uploadSection {
  background-color: #edf8f2;
  border-radius: 5px;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.adminAddProductWrapper .productDetailsContainer .uploadSection.upload-mt {
  margin: 10px 0;
}
.adminAddProductWrapper .productDetailsContainer .uploadSection .uploadImgbg {
  position: relative;
  background-color: #edf8f2;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 15px;
  padding: 2px;
  width: auto;
}
.adminAddProductWrapper .productDetailsContainer .uploadSection .uploadImgbg img {
  width: 100px;
  height: 100px;
  padding: 2px;
  -o-object-fit: cover;
     object-fit: cover;
}
.adminAddProductWrapper .productDetailsContainer .uploadSection .uploadImgbg .close-icon-bg {
  background-color: #edf8f2;
  border: 1px solid #000;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  border-radius: 50%;
}
.adminAddProductWrapper .productDetailsContainer .uploadSection .uploadImgbg .close-icon-bg .close-icon {
  color: #20a258;
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  transform: rotate(45deg);
}
.adminAddProductWrapper .productDetailsContainer .priceInfo {
  padding: 25px 20px 25px 20px;
}
.adminAddProductWrapper .productDetailsContainer .priceInfo .priceCard {
  padding-left: 10px;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.adminAddProductWrapper .productDetailsContainer .priceInfo .priceCard .radioGroups {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adminAddProductWrapper .productDetailsContainer .priceInfo .priceCard .block1 {
  padding-right: 10px;
}
.adminAddProductWrapper .productDetailsContainer .priceInfo .priceCard .block2 {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: 20px;
  padding: 130px 100px;
  background-color: #f0f0f0;
  border-radius: 15px;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productDetailsContainer .priceInfo .priceCard .block2 {
    margin-right: 20px;
    margin-left: 0px;
  }
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer {
  padding: 25px 20px 25px 20px;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block1 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block1 .outlineTextfield {
  padding: 0 5px;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block1 .outlineTextfield label {
  font-weight: 600;
  margin: 10px 0;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block2 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block2 .checkBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block2 .checkBoxContainer:nth-child(2) {
  margin-left: 10px;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block3 {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block3 .selectContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block3 .selectContainer .filledSelect {
  width: 40%;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block3 .selectContainer .filledSelect .MuiInputBase-root {
  padding: 0px 30px 0px 0px;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block3 .selectContainer .filledSelect .MuiSelect-select::before {
  top: 25px;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block3 .outlineTextfield {
  padding: 0 5px;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .block3 .outlineTextfield label {
  font-weight: 600;
  margin: 10px 0;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .track_quan {
  align-items: flex-end;
}
.adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .track_quan .selectContainer {
  margin-top: 41px;
}
@media screen and (max-width: 588px) {
  .adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .track_quan {
    grid-gap: 0px;
    grid-gap: 0px;
    gap: 0px;
  }
  .adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .track_quan .selectContainer {
    margin-left: 12px;
  }
  .adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .track_quan .selectContainer .filledSelect {
    width: 90%;
  }
}
@media screen and (max-width: 404px) {
  .adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .track_quan label {
    width: 122px;
  }
}
@media screen and (max-width: 358px) {
  .adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .track_quan .outlineTextfield {
    margin-left: 36px;
  }
  .adminAddProductWrapper .productDetailsContainer .inventoryContainer .inventoryBlocks .track_quan .selectContainer .filledSelect {
    width: 64%;
  }
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer {
  padding: 25px 20px 25px 20px;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .fieldWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .fieldWrapper > div:nth-child(1) {
  width: 100%;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productState {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productState legend {
  margin-top: 3px;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight hr {
  width: 100%;
  color: #20a258;
  margin: 30px 0;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .weightDetails {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .weightDetails .outlineTextfield {
  padding: 0 5px;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .weightDetails .outlineTextfield label {
  font-weight: 600;
  margin: 10px 0;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .weightDetails .selectContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .weightDetails .selectContainer .filledSelect {
  width: 40%;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .weightDetails .selectContainer .filledSelect .MuiInputBase-root {
  padding: 0px 30px 0px 0px;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .weightDetails .selectContainer .filledSelect .MuiSelect-select::before {
  top: 25px;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .track_quan {
  align-items: flex-start;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .track_quan .selectContainer {
  margin-top: 41px;
}
@media screen and (max-width: 588px) {
  .adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .track_quan {
    grid-gap: 0px;
    grid-gap: 0px;
    gap: 0px;
  }
  .adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .track_quan .selectContainer {
    margin-left: 12px;
  }
  .adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .track_quan .selectContainer .filledSelect {
    width: 90%;
  }
}
@media screen and (max-width: 404px) {
  .adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .track_quan label {
    width: 122px;
  }
}
@media screen and (max-width: 358px) {
  .adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .track_quan .outlineTextfield {
    margin-left: 36px;
  }
  .adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .track_quan .selectContainer .filledSelect {
    width: 64%;
  }
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .customsInfo .autocompBlock .MuiAutocomplete-root {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .customsInfo h4 {
    font-size: 15px;
  }
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .customsInfo .outlineSelect .MuiSelect-select {
  border-radius: 8px;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .productWeight .customsInfo .outlineSelect label {
  font-weight: 600;
  margin: 10px 0;
  color: #464a53;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .dimensionsSelect {
  background-color: #20a258;
  min-width: 90px;
  border-radius: 8px !important;
  border: none;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .dimensionsSelect .MuiSelect-select {
  color: #fff !important;
  width: 100%;
  padding: 17.5px 15px !important;
}
.adminAddProductWrapper .productDetailsContainer .shippingContainer .dimensionsSelect svg {
  color: #fff;
  right: 10px;
}
.adminAddProductWrapper .productDetailsContainer .previewContainer {
  padding: 25px 20px 25px 20px;
}
.adminAddProductWrapper .productDetailsContainer .previewContainer .previewHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adminAddProductWrapper .productDetailsContainer .previewContainer .previewHeader h4 {
  font-weight: 600;
  color: #20a258;
  white-space: nowrap;
  padding-right: 10px;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productDetailsContainer .previewContainer .previewHeader h4 {
    font-size: 18px;
    white-space: normal;
  }
}
.adminAddProductWrapper .productDetailsContainer .previewContainer .previewHeader hr {
  width: 100%;
  color: #20a258;
}
.adminAddProductWrapper .productDetailsContainer .variantDetailsWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin-top: 10px;
}
.adminAddProductWrapper .productDetailsContainer .add-variant-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.adminAddProductWrapper .productDetailsContainer .add-variant-wrapper button {
  border-radius: 5px;
  border: 1px solid #20a258;
  background-color: #d8eee0;
  color: #20a258;
  padding: 9px 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: "bold";
  font-size: 17px;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productDetailsContainer .previewContainer {
    padding: 20px 20px 0px;
  }
}
.adminAddProductWrapper .productDetailsContainer .course-educator-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.adminAddProductWrapper .productDetailsContainer .course-educator-row .btn-container {
  margin: 0;
}
.adminAddProductWrapper .productDetailsContainer .course-educator-row .green-outline {
  width: 250px;
}
.adminAddProductWrapper .productDetailsContainer .course-educator-row .green-outline .MuiFormControl-root {
  padding: 0;
}
.adminAddProductWrapper .productDetailsContainer .education-row {
  padding: 20px 0;
  width: 85%;
}
.adminAddProductWrapper .productDetailsContainer .education-row .priceInfo {
  padding: 15px 0;
}
.adminAddProductWrapper .productDetailsContainer .education-row .productState {
  display: flex;
  align-items: center;
  padding: 15px 0;
}
.adminAddProductWrapper .productDetailsContainer .education-row .mediaContainer {
  padding: 0;
}
.adminAddProductWrapper .productDetailsContainer .education-row .mediaContainer .mediaUploader {
  width: 70%;
  height: 250px;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productDetailsContainer .education-row {
    width: 100%;
  }
  .adminAddProductWrapper .productDetailsContainer .education-row .mediaUploader {
    width: 100% !important;
  }
}
.adminAddProductWrapper .productDetailsContainer .edu-btn-container {
  margin: 0;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent p {
  font-weight: 500;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .MuiPaper-elevation {
  margin-bottom: 10px;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .MuiButtonBase-root {
  padding: 0;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .edit-icon {
  height: 30px;
  width: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 50px 0 20px 0;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .header-content .left-block {
  display: flex;
  align-items: center;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .taskOptionBlock {
  max-height: 260px;
  overflow-y: scroll;
  margin-bottom: 15px;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .taskOptionBlock::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .taskOptionBlock::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .taskOptionBlock::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian.MuiAccordion-root {
  box-shadow: none;
  border: 1px solid #20a258;
  margin-bottom: 10px !important;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .MuiAccordionDetails-root {
  padding: 10px;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .MuiAccordionSummary-expandIconWrapper {
  margin-right: 20px;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .MuiAccordionSummary-content {
  margin-left: 0;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .accordionHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}
@media screen and (max-width: 510px) {
  .adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .accordionHeader {
    flex-direction: column;
    align-items: normal;
  }
  .adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .accordionHeader .lessonHead {
    margin-bottom: 20px;
  }
  .adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .accordionHeader .right-block {
    margin-left: -20px;
  }
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .accordionHeader .right-block {
  display: flex;
  align-items: center;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .accordionHeader .edit-icon {
  height: 25px;
  width: 25px;
  -o-object-fit: contain;
     object-fit: contain;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .titleBox {
  padding: 10px 0;
  margin: 20px 0;
  border-top: 1px solid #b6e0c8;
  border-bottom: 1px solid #b6e0c8;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .lesson-row {
  display: flex;
  align-items: center;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .lesson-row p {
  padding-left: 20px;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .task-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .task-content .left-block {
  display: flex;
  align-items: center;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .task-content .edit-icon {
  height: 25px;
  width: 25px;
  -o-object-fit: contain;
     object-fit: contain;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .video-imgblock {
  display: flex;
  flex-direction: column;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .video-imgblock .lessonImg, .adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .video-imgblock video {
  width: 180px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .video-imgblock .captionBlock {
  width: 180px;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .lessonAccordian .video-imgblock .captionBlock .deleteIcon {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .greenContained .MuiButtonBase-root, .adminAddProductWrapper .productDetailsContainer .learningCourseContent .greenOutlineButton .MuiButtonBase-root {
  padding: 8px 25px;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .learningCourseFooter {
  padding-top: 20px;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .learningCourseFooter .add-part-row {
  margin-top: 50px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  border: 1px dashed #9098b1;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .learningCourseFooter .add-part-row span {
  color: #394053;
  font-weight: 500;
  text-transform: capitalize;
}
.adminAddProductWrapper .productDetailsContainer .learningCourseContent .learningCourseFooter .add-part-row .plus-icon {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background: #20a258;
  font-size: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 0 20px;
}
.adminAddProductWrapper .productStatusContainer .productStatus {
  padding: 25px 20px;
  background-color: #ffffff;
  border-radius: 15px;
}
.adminAddProductWrapper .productStatusContainer .productStatus .outlineSelect .itemStatus .MuiSelect-select {
  width: 100%;
  max-width: 100% !important;
  margin-top: 0;
}
.adminAddProductWrapper .productStatusContainer .productStatus h4 {
  color: #20a258;
  margin: 20px 0;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productStatusContainer .productStatus h4 {
    font-size: 18px;
  }
}
.adminAddProductWrapper .productStatusContainer .productStatus h6 {
  font-weight: 600;
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productStatusContainer .productStatus h6 {
    font-size: 14px;
  }
}
.adminAddProductWrapper .productStatusContainer .productStatus .checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}
.adminAddProductWrapper .productStatusContainer .productStatus .checkboxContainer .MuiFormControlLabel-label {
  font-weight: 600;
  color: #5d5e6a;
}
.adminAddProductWrapper .productStatusContainer .productStatus .link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.adminAddProductWrapper .productStatusContainer .productStatus .link a {
  font-size: 20px;
  font-weight: 800;
  color: coral;
  margin: 20px 0;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productStatusContainer .productStatus .link a {
    font-size: 15px;
  }
}
.adminAddProductWrapper .productStatusContainer .productStatus .link .MuiSvgIcon-root {
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productStatusContainer .productStatus .link .MuiSvgIcon-root {
    margin-left: 0;
  }
}
.adminAddProductWrapper .productStatusContainer .productStatus h5 {
  color: coral;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productStatusContainer .productStatus h5 {
    font-size: 15px;
  }
}
.adminAddProductWrapper .productStatusContainer .productStatus .btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0 0;
}
.adminAddProductWrapper .productStatusContainer .productStatus .btns .MuiButton-label {
  color: red;
  font-weight: 900;
}
.adminAddProductWrapper .productStatusContainer .productTypeContainer {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 25px 20px;
  margin-top: 20px;
}
.adminAddProductWrapper .productStatusContainer .productTypeContainer h5 {
  color: #20a258;
  text-transform: uppercase;
  margin: 15px 0;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .adminAddProductWrapper .productStatusContainer .productTypeContainer h5 {
    font-size: 18px;
  }
}
.adminAddProductWrapper .productStatusContainer .productTypeContainer .customSelect {
  width: 100%;
}
.adminAddProductWrapper .productStatusContainer .productTypeContainer .outlineTextfield {
  margin: 10px 0;
}
.adminAddProductWrapper .productStatusContainer .productTypeContainer .outlineTextfield label {
  font-weight: 600;
  margin: 10px 0;
}

.dateTimePickerConatiner {
  cursor: pointer !important;
  margin: 20px 0;
  position: relative;
}
.dateTimePickerConatiner .datepicker_row {
  display: flex;
}
.dateTimePickerConatiner .datepicker_row .pencil_icon {
  position: absolute;
  left: 230px;
}
.dateTimePickerConatiner .datepicker_row .pencil_icon img {
  height: 20px;
  width: 20px;
  -o-object-fit: contain;
     object-fit: contain;
}
.dateTimePickerConatiner .MuiInputBase-root {
  cursor: pointer !important;
}
.dateTimePickerConatiner .MuiInputBase-root .MuiInputBase-input {
  padding: 0;
  font-size: 20px;
  font-weight: 800;
  color: coral;
  text-decoration: underline;
  font-family: "Poppins", sans-serif;
}
.dateTimePickerConatiner .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  display: none;
}
.dateTimePickerConatiner .MuiInput-underline {
  cursor: pointer !important;
}
.dateTimePickerConatiner .MuiInput-underline::before, .dateTimePickerConatiner .MuiInput-underline::after {
  border: 0 !important;
}

.circularprog1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px !important;
  min-height: 400px;
}
.circularprog1 .MuiCircularProgress-root {
  width: 110px !important;
}
.circularprog1 .MuiCircularProgress-colorPrimary {
  color: #20a258;
}

.Online-Store-label {
  width: 122px;
  height: 20px;
  margin: 17px 85px 30px 12px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #000;
}

.salesCheckbox .Online-Store-label {
  margin: 0;
}
.salesCheckbox.customCheckbox input:checked + label::after {
  top: 0;
  border: solid #ffffff !important;
}

.farmOwnerCheckbox {
  display: flex;
  align-items: center;
  height: 100%;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  margin: 20px 0 0;
}
.btn-container button {
  font-family: "Poppins", sans-serif;
  text-transform: none;
}
.btn-container .cancel {
  background-color: #c1c1c1;
}
.btn-container .cancel .MuiButton-label {
  color: black;
}
.btn-container .save {
  background-color: #20a258;
}
.btn-container .save .MuiButton-label {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .btn-container {
    margin: 30px 0px 0px;
  }
  .btn-container button {
    width: 100%;
  }
}

.createPartModal .carContainer {
  padding: 20px 50px;
}
.createPartModal .carContainer .card {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px 18px 10px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.63);
}
.createPartModal .carContainer .card .imgContainer img {
  width: 62px;
  height: 62px;
}
.createPartModal .carContainer .card p {
  font-size: 13px;
  font-weight: 600;
  color: #3f526d;
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
.createPartModal h1 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid black;
  padding: 25px 20px;
  font-size: 25px;
}
.createPartModal .btnContainer {
  width: 100%;
  background-color: #20a258;
}

.imageUploadIcon {
  height: 18px;
  margin-right: 10px;
  margin-bottom: 3px;
}

.mediaUploadcourse span {
  color: #20a258;
}
.mediaUploadcourse img {
  margin-bottom: 20px !important;
  width: 62px !important;
}

.studentSelect .MuiMenu-paper {
  top: 188px;
  right: 16px;
  width: 300px;
  height: 337px;
}

.adminAddProductWrapper .lessonDescription {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #464a53 !important;
}

@media screen and (max-width: 342px) {
  .per_item_radio .MuiFormGroup-root {
    justify-content: flex-start !important;
  }
}

.sm-screen-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 0px 12px;
  grid-gap: 10px;
  gap: 10px;
}
.sm-screen-btn-container button {
  border-radius: 7px;
  text-transform: none;
  padding: 10px;
}
.sm-screen-btn-container button span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .sm-screen-btn-container button {
    width: 100%;
  }
}
.sm-screen-btn-container .cancel {
  background-color: #c1c1c1;
}
.sm-screen-btn-container .cancel .MuiButton-label {
  color: black;
}
.sm-screen-btn-container .save {
  background-color: #20a258;
}
.sm-screen-btn-container .save .MuiButton-label {
  color: #ffffff;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.AnalyticsTopHeader .list-items {
  padding-top: 10px;
}
.AnalyticsTopHeader ul {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 18px;
  gap: 18px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}
@media screen and (max-width: 580px) {
  .AnalyticsTopHeader ul {
    grid-gap: 10px;
    gap: 10px;
  }
}
.AnalyticsTopHeader ul li {
  float: left;
  min-width: 200px;
}
@media screen and (max-width: 580px) {
  .AnalyticsTopHeader ul li {
    min-width: 100px;
  }
}
.AnalyticsTopHeader ul a {
  display: block;
  text-align: center;
  padding: 15px 50px;
  color: #000;
  text-decoration: none;
  border-radius: 6px;
  background-color: #cad8f9;
  font-weight: bold;
}
.AnalyticsTopHeader ul a:hover {
  color: #000;
}
@media screen and (max-width: 1024px) {
  .AnalyticsTopHeader ul a {
    padding: 5px 15px;
  }
}

.analyticsWrapper .cardContainer.analyticsContainer {
  padding-top: 26px;
}
.analyticsWrapper .cardContainer.analyticsContainer .analyticsListTable {
  width: 100%;
}
.analyticsWrapper .cardContainer.analyticsContainer .h5 {
  font-size: 14px;
  font-weight: bold;
}
.analyticsWrapper .cardContainer.analyticsContainer .priceRow {
  padding: 20px 0px 10px 0px;
}
.analyticsWrapper .cardContainer.analyticsContainer .priceRow small {
  color: #6ddfba;
  font-weight: bold;
}
.analyticsWrapper .cardContainer.analyticsContainer .comparsion {
  padding-bottom: 10px;
}
.analyticsWrapper .cardContainer.analyticsContainer .analytics_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.analyticsWrapper .cardContainer.analyticsContainer .analytics_header h5 {
  font-weight: bold;
  font-size: 19px;
  width: 100%;
}
.analyticsWrapper .cardContainer.analyticsContainer .analytics_header .option-dropDown {
  width: unset;
}
.analyticsWrapper .cardContainer.analyticsContainer .customer_transaction {
  width: 100%;
}
.analyticsWrapper .cardContainer.analyticsContainer .customer_transaction .customer_ids {
  display: flex;
  width: 100%;
  padding: 10px 40px 10px 0;
}
.analyticsWrapper .cardContainer.analyticsContainer .customer_transaction .customer_ids .customer_flex {
  display: flex;
  width: 100%;
}
.analyticsWrapper .cardContainer.analyticsContainer .customer_transaction .customer_ids small {
  color: grey;
  font-size: 15px;
}
.analyticsWrapper .cardContainer.analyticsContainer .customer_transaction .customer_ids .id_name {
  font-weight: bold;
  font-size: 17px;
}
.analyticsWrapper .cardContainer.analyticsContainer .customer_transaction .customer_ids .envelope {
  color: #ddd;
}
.analyticsWrapper .cardContainer.analyticsContainer .customer_transaction .customer_ids .circle {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  background-color: #fc5a5a;
  margin-right: 10px;
}
.analyticsWrapper .cardContainer.analyticsContainer .view_more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  border-top: 1px solid #c3c3c3;
  width: 100%;
}
.analyticsWrapper .cardContainer.analyticsContainer .view_more a {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}
.analyticsWrapper .cardContainer.analyticsContainer .view_transactions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.analyticsWrapper .cardContainer.analyticsContainer .card.analyticsCard {
  width: 100%;
  border-radius: 14px !important;
  padding: 19px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  height: 340px;
}
.analyticsWrapper .cardContainer.analyticsContainer .card.analyticsCard .prodListHeader {
  margin-bottom: 10px;
}
.analyticsWrapper .cardContainer.analyticsContainer .infoCard {
  padding: 40px;
}
.analyticsWrapper .cardContainer.analyticsContainer .infoCard.MuiCard-root {
  padding: 25px 20px 15px 20px;
}
@media screen and (max-width: 1024px) {
  .analyticsWrapper .cardContainer.analyticsContainer .infoCard.MuiCard-root {
    padding: 13px 20px 10px 20px;
  }
}
.analyticsWrapper .cardContainer.analyticsContainer .infoCard .cardContent {
  padding: 10px 20px;
  width: 100%;
}
.analyticsWrapper .anaylticsHistoryRow .anaylticsCol {
  padding: 0 12px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customTextfield .MuiInputLabel-root {
  position: absolute;
  top: -25px;
  font-size: 21px;
  font-weight: bold;
  color: #394053;
}
.customTextfield .MuiFormControl-root {
  background-color: #f0f0f0;
  border: 0;
  padding: 3px 10px;
  margin-top: 0px;
  border-radius: 10px;
  color: #394053;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  white-space: nowrap;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.customTextfield .MuiFormControl-root .MuiInputBase-input::-webkit-input-placeholder {
  color: #394053;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.customTextfield .MuiFormControl-root .MuiInputBase-input::placeholder {
  color: #394053;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.customTextfield .MuiFormControl-root .MuiInput-underline::before {
  border: 0;
}
.customTextfield .MuiFormControl-root .MuiInput-formControl {
  margin: 0;
  width: 100%;
  font-size: 14px;
}
.customTextfield .MuiFormControl-root .MuiInput-underline::after {
  border: 0;
}
.customTextfield .MuiFormControl-root .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: 0;
}
.customTextfield .MuiFormControl-root .MuiInput-underline:focus:not(.Mui-disabled)::before {
  border: 0;
}
.customTextfield .MuiFormControl-root .customLabel {
  margin-bottom: 10px;
}

.outlineTextfield {
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 10px;
}
.outlineTextfield .errorMsg {
  margin: 0;
}
.outlineTextfield .MuiFormControl-root {
  background-color: transparent !important;
  border: 1px solid rgba(193, 193, 193, 0.368627451) !important;
  padding: 0px 10px;
}
.outlineTextfield .MuiInputBase-root input::-webkit-input-placeholder {
  color: red;
}
.outlineTextfield .MuiInputBase-root input::placeholder {
  color: red;
}
.outlineTextfield.readOnlyField .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 5px !important;
  text-align: center;
}
.outlineTextfield.boxRadiusBorder .MuiFormControl-root {
  border-radius: 8px;
}
.outlineTextfield.boxRadiusBorder .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 5px !important;
  font-family: "Poppins", sans-serif !important;
}
.outlineTextfield.inventoryTextbox .MuiFormControl-root {
  height: 54px;
}

.sideLine {
  border-left: 1px solid gray; /* add the border             */
  padding: 0 7px; /* left and right padding     */
}

.customTextAreafield textarea {
  resize: none;
  padding: 3px 10px;
  border-radius: 10px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  color: #394053;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.customTextAreafield textarea:focus {
  outline: none !important;
  border-color: #c1c1c1;
}

.normalTextArea textarea {
  resize: none;
  padding: 3px 10px;
  border-radius: 10px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  padding: 15px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.normalTextArea textarea:focus {
  outline: none !important;
  border-color: #c1c1c1;
}

.ownerEmailField {
  width: 31.5% !important;
}
@media screen and (max-width: 768px) {
  .ownerEmailField {
    width: 100% !important;
  }
}

.handleOpacity .Mui-disabled {
  opacity: 0.6;
}

.textFieldMultiline .MuiInputBase-input {
  font-size: 14px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customProgressbar {
  margin-right: 15px;
  width: 100%;
}
.customProgressbar .circularprog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: 20px;
  padding: 80px 100px;
  background-color: #f7f7f7;
  border-radius: 15px;
}
@media screen and (max-width: 768px) {
  .customProgressbar .circularprog {
    margin-right: 20px;
    margin-left: 0px;
  }
}
.customProgressbar .circularprog .circularprogLabel {
  padding: 20px 0;
  font-size: 17px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.customProgressbar .circularprog .circularProgressBar {
  position: relative;
}
.customProgressbar .circularprog .circularProgressBar p {
  position: absolute;
  padding: unset;
  top: 50%;
  transform: translateY(-85%);
  left: 0;
  font-size: 20px;
  font-weight: 600;
  color: #394053 !important;
  width: 100%;
  text-align: center;
}
.customProgressbar .circularprog h3 {
  padding: 20px 0;
  font-weight: 600;
  color: #394053;
}
.customProgressbar .circularprog .MuiCircularProgress-root {
  width: 130px !important;
  height: auto !important;
}

.customLinearProgressbar progress {
  border-radius: 7px;
  width: 100%;
  height: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.customLinearProgressbar progress::-webkit-progress-bar {
  background-color: #c1c1c1;
  border-radius: 7px;
}
.customLinearProgressbar progress::-webkit-progress-value {
  background-color: #20a258;
  border-radius: 7px;
}
.customLinearProgressbar progress::-moz-progress-bar {
  /* style rules */
  background-color: #20a258;
  border-radius: 7px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.MuiMenu-paper .sortDropDown .MuiListItem-root {
  text-transform: capitalize;
}

@media screen and (max-width: 1024px) {
  .customSelect {
    width: 100%;
  }
}
.customSelect .MuiSelect-select {
  text-transform: capitalize;
}
.customSelect .MuiSelect-select.MuiSelect-select {
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  height: 20px;
  font-size: 18px;
  border-radius: 8px;
}
.customSelect .MuiInputBase-root:hover {
  border: 0;
}
.customSelect menuitem {
  font-weight: 600;
}
.customSelect .MuiSelect-select:focus {
  background-color: transparent;
}
.customSelect .MuiInput-underline::before {
  border: 0;
}
.customSelect .MuiInput-underline::after {
  border: 0;
}
.customSelect .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: 0;
}
.customSelect .MuiInput-underline:focus:not(.Mui-disabled)::before {
  border: 0;
}
.customSelect .MuiFormControl-root {
  width: 100%;
  background-color: transparent !important;
  border: 0px solid transparent !important;
}

.outlineSelect {
  width: 100%;
}
.outlineSelect .MuiSelect-select {
  padding: 12px 25px 12px 15px !important;
  border: 1px solid rgba(193, 193, 193, 0.368627451);
}
.outlineSelect .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
}
.outlineSelect .MuiSelect-select:focus {
  border-radius: 10px;
  border: 1px solid rgba(193, 193, 193, 0.368627451) !important;
}

.filledSelect {
  width: 100%;
}
.filledSelect .MuiInputBase-root {
  background-color: #20a258;
  color: #ffffff;
  border-radius: 8px;
  padding: 5px 20px 5px 20px;
  height: 53px;
  position: relative;
}
.filledSelect .MuiInputBase-root .MuiSvgIcon-root {
  display: none;
}
.filledSelect .MuiInputBase-root .MuiSelect-select {
  width: 100px;
  padding-right: 2px;
  padding-left: 15px;
}
.filledSelect .MuiInputBase-root .MuiSelect-select::before {
  content: " ";
  position: absolute;
  left: 20px;
  top: 20px;
  transform: translateY(-50%) rotate(-45deg);
  border: 0px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  border-top: 0px solid transparent;
  height: 10px;
  width: 10px;
}
.filledSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 600;
  margin-left: 5px;
  font-size: 15px;
}
.filledSelect .MuiFormControl-root {
  border: 1px solid transparent !important;
}
@media screen and (max-width: 1024px) {
  .filledSelect .MuiFormControl-root {
    width: 100%;
  }
}
.filledSelect.leftIcon .MuiInputBase-root {
  padding: 5px 20px 5px 0px;
}
.filledSelect.leftIcon .MuiInputBase-root .MuiSelect-select::before {
  content: " ";
  left: unset;
  right: 20px;
  top: 20px;
}

.menu-customize {
  font-weight: normal;
}
.menu-customize .MuiSelect-select.MuiInputBase-input {
  text-transform: capitalize;
}
.menu-customize .MuiPaper-rounded {
  border-radius: 10px;
}
.menu-customize .MuiMenuItem-root:hover {
  background-color: #e7ffed;
  color: #20a258;
}

.multilineSelect {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #f0f0f0 !important;
}
.multilineSelect .sorttext {
  font-size: 15px;
  width: 100%;
  padding: 10px 5px;
  white-space: nowrap;
}
.multilineSelect .customSelect {
  width: 100%;
  margin: 0px 0;
}
@media screen and (max-width: 1024px) {
  .multilineSelect .customSelect {
    width: 100%;
  }
}
.multilineSelect .customSelect .MuiFormControl-root {
  width: 100% !important;
  outline: none;
  background-color: #ffffff !important;
}
.multilineSelect .customSelect .MuiFormControl-root .MuiSelect-select.MuiSelect-select {
  font-weight: 900;
  font-size: 18px;
  position: relative;
}
.multilineSelect .customSelect .MuiFormControl-root .MuiSelect-select.MuiSelect-select::after {
  content: "";
  height: 39px;
  width: 1px;
  background-color: #f0f0f0;
  position: absolute;
  top: 0px;
  right: 25px;
}
.multilineSelect .customSelect .MuiFormControl-root .MuiSelect-select.MuiSelect-select.MuiInputBase-input {
  padding: 13px 40px 10px 3px !important;
  height: 100%;
}
.multilineSelect .customSelect .MuiFormControl-root .MuiSelect-select:focus {
  border-radius: 10px;
}

.green-outline {
  width: 100%;
}
.green-outline .MuiInputBase-root {
  background-color: #edf8f2;
  border: 1px solid #59bd83 !important;
  border-radius: 5px;
  padding: 0px;
  width: 100%;
}
.green-outline .MuiInputBase-root .MuiSvgIcon-root {
  color: #59bd83;
}
.green-outline .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.green-outline .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
  color: #59bd83;
  margin-right: 0px;
}
.green-outline .MuiInputBase-root .MuiSelect-select.MuiInputBase-input {
  padding: 10px 28px 10px 9px !important;
}
.green-outline .MuiInputBase-root:hover {
  border-radius: 5px 5px 0 0;
}
.green-outline .MuiFormControl-root {
  width: 100%;
}
.green-outline .dragdropUrl .MuiSelect-select {
  text-transform: none;
}
.green-outline .dragdropUrl .MuiSelect-select.MuiSelect-select {
  text-transform: none !important;
}

.orange-outline {
  width: 100%;
}
.orange-outline .MuiInputBase-root {
  background-color: #fcf6ec;
  border: 1px solid #dc9b2d !important;
  border-radius: 5px;
  padding: 0px;
  width: 100%;
}
.orange-outline .MuiInputBase-root .MuiSvgIcon-root {
  color: #dc9b2d;
}
.orange-outline .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.orange-outline .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
  color: #dc9b2d;
  margin-right: 0px;
}
.orange-outline .MuiInputBase-root .MuiSelect-select.MuiInputBase-input {
  padding: 10px 28px 10px 9px !important;
}
.orange-outline .MuiInputBase-root:hover {
  border-radius: 5px 5px 0 0;
}
.orange-outline .MuiFormControl-root {
  width: 100%;
}
.orange-outline .dragdropUrl .MuiSelect-select {
  text-transform: none;
}
.orange-outline .dragdropUrl .MuiSelect-select.MuiSelect-select {
  text-transform: none !important;
}

.deactiveStatus {
  width: 100%;
}
.deactiveStatus .MuiInputBase-root {
  background-color: rgba(221, 146, 147, 0.2392156863);
  border: 1px solid #fc4b4e !important;
  border-radius: 5px;
  padding: 0px;
  width: 100%;
}
.deactiveStatus .MuiInputBase-root .MuiSvgIcon-root {
  color: #fc4b4e;
}
.deactiveStatus .MuiInputBase-root .MuiSelect-select {
  text-transform: capitalize;
}
.deactiveStatus .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
  color: #fc4b4e;
  margin-right: 0px;
}
.deactiveStatus .MuiInputBase-root .MuiSelect-select.MuiInputBase-input {
  padding: 10px 28px 10px 9px !important;
}
.deactiveStatus .MuiInputBase-root:hover {
  border-radius: 5px 5px 0 0;
}
.deactiveStatus .MuiFormControl-root {
  width: 100%;
}
.deactiveStatus .dragdropUrl .MuiSelect-select {
  text-transform: none;
}
.deactiveStatus .dragdropUrl .MuiSelect-select.MuiSelect-select {
  text-transform: none !important;
}

.adminRestDishesContainer .customSelect.dishCategorySelect .MuiInput-formControl,
.commonDropdown .customSelect.dishCategorySelect .MuiInput-formControl {
  margin-top: 0 !important;
}
.adminRestDishesContainer .customSelect.dishCategorySelect .MuiInputBase-root .MuiSvgIcon-root,
.commonDropdown .customSelect.dishCategorySelect .MuiInputBase-root .MuiSvgIcon-root {
  display: block;
}
.adminRestDishesContainer .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select::before,
.commonDropdown .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select::before {
  margin-right: 10px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #92929d;
}
.adminRestDishesContainer .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  margin-left: 5px;
  font-size: 16px;
  align-items: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.1px;
  color: #44444f;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.dishCategorySelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
    margin-left: 0;
  }
}
.adminRestDishesContainer .customSelect.dishCategorySelect .paper,
.commonDropdown .customSelect.dishCategorySelect .paper {
  background-color: red;
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInput-formControl,
.commonDropdown .customSelect.restaurantDropDown .MuiInput-formControl {
  margin-top: 0 !important;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root {
    padding: 0 !important;
  }
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root .MuiSvgIcon-root,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root .MuiSvgIcon-root {
  display: block;
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select::before,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select::before {
  content: "Restaurant:";
  margin-right: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #929292;
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #4b4b4b;
}
.adminRestDishesContainer .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select span,
.commonDropdown .customSelect.restaurantDropDown .MuiInputBase-root .MuiSelect-select span {
  color: #4f4f4f;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown,
.commonDropdown .customSelect.commonOrdersDropDown {
  display: flex;
  justify-content: center;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root svg,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root svg {
  margin-right: 15px;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select {
  display: flex;
  justify-content: center;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #44444f;
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select.Mui-disabled,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiSelect-select.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.adminRestDishesContainer .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiInputBase-input,
.commonDropdown .customSelect.commonOrdersDropDown .MuiInputBase-root .MuiSelect-select.MuiInputBase-input {
  padding: 10px 0px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect,
.commonDropdown .customSelect.dishSortSelect {
  border-radius: 10px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiFormControl-root,
.commonDropdown .customSelect.dishSortSelect .MuiFormControl-root {
  padding: 0px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiMenu-paper,
.commonDropdown .customSelect.dishSortSelect .MuiMenu-paper {
  border-radius: 10px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiMenu-paper ul,
.commonDropdown .customSelect.dishSortSelect .MuiMenu-paper ul {
  padding: 0px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root {
  border-radius: 10px !important;
  border: 1px solid #e2e2ea !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root.Mui-focused,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root.Mui-focused {
  border-radius: 15px 15px 0 0;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSvgIcon-root,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSvgIcon-root {
  display: block;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select {
  min-width: 150px !important;
  max-width: 150px !important;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select::before,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select::before {
  content: " Sort by:";
  margin-right: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #92929d;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  margin-left: 0;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #44444f;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select::after,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select::after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #e2e2ea;
  position: absolute;
  top: 0px;
  right: 25px;
}
.adminRestDishesContainer .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select.MuiInputBase-input,
.commonDropdown .customSelect.dishSortSelect .MuiInputBase-root .MuiSelect-select.MuiSelect-select.MuiInputBase-input {
  padding: 6px 28px 6px 10px !important;
  text-transform: capitalize !important;
}
.adminRestDishesContainer .customSelect.dishListStatus,
.commonDropdown .customSelect.dishListStatus {
  width: 100%;
  height: 100%;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiFormControl-root,
.commonDropdown .customSelect.dishListStatus .MuiFormControl-root {
  height: 100%;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiInputBase-root,
.commonDropdown .customSelect.dishListStatus .MuiInputBase-root {
  border: 1px solid rgba(193, 193, 193, 0.368627451);
  border-radius: 10px;
  height: 100%;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select,
.commonDropdown .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select {
  padding: unset;
  padding: 10px 5px 10px 20px;
  text-transform: capitalize;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select.MuiSelect-select,
.commonDropdown .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  text-align: left;
}
.adminRestDishesContainer .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select:focus,
.commonDropdown .customSelect.dishListStatus .MuiInputBase-root .MuiSelect-select:focus {
  border-radius: 10px;
  border: 0px solid rgba(193, 193, 193, 0.368627451) !important;
}
.adminRestDishesContainer .customSelect.itemStatus,
.commonDropdown .customSelect.itemStatus {
  margin-top: 1px !important;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.itemStatus,
.commonDropdown .customSelect.itemStatus {
    margin-top: 0px !important;
  }
}
.adminRestDishesContainer .customSelect.itemStatus .MuiSelect-select,
.commonDropdown .customSelect.itemStatus .MuiSelect-select {
  background-color: #e7ffed;
  border: 1px solid #20a258;
  border-radius: 5px;
  color: #20a258;
  width: 100px;
  min-width: 85px !important;
  max-width: 150px !important;
  position: relative;
  margin-top: 12px;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.itemStatus .MuiSelect-select,
.commonDropdown .customSelect.itemStatus .MuiSelect-select {
    margin-top: 0px;
  }
}
.adminRestDishesContainer .customSelect.itemStatus .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus .MuiSelect-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid #20a258;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.itemStatus.campaignSelect .MuiFormControl-root,
.commonDropdown .customSelect.itemStatus.campaignSelect .MuiFormControl-root {
    width: 85%;
  }
}
.adminRestDishesContainer .customSelect.itemStatus.campaignSelect .MuiSelect-select,
.commonDropdown .customSelect.itemStatus.campaignSelect .MuiSelect-select {
  padding-left: 10px;
}
@media screen and (max-width: 560px) {
  .adminRestDishesContainer .customSelect.itemStatus.campaignSelect .MuiSelect-select,
.commonDropdown .customSelect.itemStatus.campaignSelect .MuiSelect-select {
    padding-right: 0;
  }
}
.adminRestDishesContainer .customSelect.itemStatus.danger-variant .MuiSelect-select,
.commonDropdown .customSelect.itemStatus.danger-variant .MuiSelect-select {
  background-color: rgba(221, 146, 147, 0.2392156863);
  border: 1px solid red;
  color: red;
}
.adminRestDishesContainer .customSelect.itemStatus.danger-variant .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus.danger-variant .MuiSelect-select::after {
  border-color: red;
}
.adminRestDishesContainer .customSelect.itemStatus.warning-variant .MuiSelect-select,
.commonDropdown .customSelect.itemStatus.warning-variant .MuiSelect-select {
  background-color: #ffefd3;
  border: 1px solid #ec9e31;
  color: #ec9e31;
}
.adminRestDishesContainer .customSelect.itemStatus.warning-variant .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus.warning-variant .MuiSelect-select::after {
  border-color: #ec9e31;
}
.adminRestDishesContainer .customSelect.itemStatus .draft .MuiSelect-select,
.commonDropdown .customSelect.itemStatus .draft .MuiSelect-select {
  color: #d98f08;
  border: 1px solid #d98f08;
  background-color: rgba(217, 143, 8, 0.08);
}
.adminRestDishesContainer .customSelect.itemStatus .draft .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus .draft .MuiSelect-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid #d98f08;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.adminRestDishesContainer .customSelect.itemStatus .deactive .MuiSelect-select,
.commonDropdown .customSelect.itemStatus .deactive .MuiSelect-select {
  color: #fc4b4e;
  border: 1px solid #fc4b4e;
  background-color: rgba(252, 75, 78, 0.08);
}
.adminRestDishesContainer .customSelect.itemStatus .deactive .MuiSelect-select::after,
.commonDropdown .customSelect.itemStatus .deactive .MuiSelect-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 10px;
  border: 1px solid #fc4b4e;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.adminRestDishesContainer .customSelect.itemStatus .MuiFormControl-root,
.commonDropdown .customSelect.itemStatus .MuiFormControl-root {
  margin-bottom: 2px;
}
.adminRestDishesContainer .customSelect.itemStatus .MuiSvgIcon-root,
.commonDropdown .customSelect.itemStatus .MuiSvgIcon-root {
  display: none;
}
.adminRestDishesContainer .customSelect.itemStatus .MuiInputBase-root,
.commonDropdown .customSelect.itemStatus .MuiInputBase-root {
  display: flex;
  justify-content: center;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown span,
.commonDropdown .customSelect.farmOrdersDropDown span {
  color: #4b4b4b;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown.prodFarmSelect .MuiSelect-select::before,
.commonDropdown .customSelect.farmOrdersDropDown.prodFarmSelect .MuiSelect-select::before {
  content: " Farm:" !important;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown .MuiSelect-select,
.commonDropdown .customSelect.farmOrdersDropDown .MuiSelect-select {
  color: #44444f !important;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown .MuiSelect-select::before,
.commonDropdown .customSelect.farmOrdersDropDown .MuiSelect-select::before {
  content: " Show:" !important;
  color: #92929d;
}
.adminRestDishesContainer .customSelect.farmOrdersDropDown .MuiSelect-select .MuiSelect-selectMenu,
.commonDropdown .customSelect.farmOrdersDropDown .MuiSelect-select .MuiSelect-selectMenu {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.adminRestDishesContainer .customSelect.farmSortDropDown .MuiSelect-select,
.commonDropdown .customSelect.farmSortDropDown .MuiSelect-select {
  padding: 10px 25px 10px 15px !important;
  font-size: 14px !important;
  text-transform: capitalize;
}
.adminRestDishesContainer .customSelect.farmSortDropDown .MuiSelect-select::before,
.commonDropdown .customSelect.farmSortDropDown .MuiSelect-select::before {
  content: " Sort By:" !important;
}
.adminRestDishesContainer .reportsButton,
.commonDropdown .reportsButton {
  margin-left: 20px;
}
.adminRestDishesContainer.contentHeaderDropdown .MuiSelect-select::before,
.commonDropdown.contentHeaderDropdown .MuiSelect-select::before {
  display: none;
}

.css-1d9cypr-MuiStack-root .MuiSelect-select {
  color: #44444f !important;
}
.css-1d9cypr-MuiStack-root .MuiSelect-select::before {
  content: "Restaurant: " !important;
  color: #92929d;
}
@media screen and (max-width: 425px) {
  .css-1d9cypr-MuiStack-root {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin: 10px 0;
  }
}

.statusBox {
  width: 100% !important;
  max-width: 100% !important;
  padding: 10px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.dishes_list_heading {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #171725;
}

.dish_type_menus {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500;
  color: #44444f !important;
}
.dish_type_menus:hover {
  color: #ffffff !important;
}

.dish_list_sort_menus {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  margin: 0px !important;
  padding-bottom: 0 !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #44444f !important;
}
.dish_list_sort_menus:hover {
  color: #ffffff !important;
}

.dish_list_filter_status_menus {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 0.13px !important;
  color: #000 !important;
  margin: 0px !important;
  background-color: #fff !important;
}
.dish_list_filter_status_menus:hover {
  background-color: #fff !important;
  color: #20a258 !important;
}

.selected_status_menu {
  color: #20a258 !important;
}

.loader_container {
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader_container svg {
  color: #20a258;
}

.no_dishes_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 51vh;
}
.no_dishes_container h4 {
  color: #20a258;
  font-weight: 600;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 6px;
}
.no_dishes_container p {
  font-size: 15px;
  margin-bottom: 15px;
  font-weight: 600;
  word-spacing: 2px;
}

.dish_list_status_menus {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
  color: #20a258 !important;
}
.dish_list_status_menus:hover {
  background-color: unset !important;
  color: #20a258 !important;
}

.de_active_menu {
  color: #fc4b4e !important;
}
.de_active_menu:hover {
  background-color: unset !important;
  color: #fc4b4e !important;
}

.draft_menu {
  color: #d98f08 !important;
}
.draft_menu:hover {
  background-color: unset !important;
  color: #d98f08 !important;
}

.cookingDes .MuiInputBase-input.MuiInput-input {
  color: #5a5a5a;
  font-family: Montserrat;
  font-size: 16px;
}

.custom_formik_error {
  font-size: 12px;
  color: red;
  margin: 0px !important;
  width: 100% !important;
  line-height: normal !important;
  letter-spacing: normal !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restOrderWrapper .prodListHeader .outlineTextfield {
  width: 75% !important;
}
.restOrderWrapper .prodListHeader .outlineTextfield .MuiInputBase-input::-moz-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.restOrderWrapper .prodListHeader .outlineTextfield .MuiInputBase-input:-ms-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.restOrderWrapper .prodListHeader .outlineTextfield .MuiInputBase-input::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.restOrderWrapper .btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}
.restOrderWrapper .btnContainer .MuiButton-outlined {
  background-color: orange;
  width: 100%;
  border: none;
}
.restOrderWrapper .btnContainer .MuiButton-outlined span {
  font-weight: 600;
  color: #ffffff;
}
.restOrderWrapper .btnContainer .MuiButton-outlined .MuiSvgIcon-root {
  color: orange;
  font-size: 20px;
  padding: 0;
  border: none;
  margin: 0;
}
@media screen and (max-width: 560px) {
  .restOrderWrapper .btnContainer {
    width: 100%;
  }
}
.restOrderWrapper .todayBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}
.restOrderWrapper .todayBtn .MuiButton-outlined {
  background-color: white;
  width: 100%;
  border: 2px solid orange;
}
.restOrderWrapper .todayBtn .MuiButton-outlined span {
  font-weight: 600;
  color: orange;
}
.restOrderWrapper .todayBtn .MuiButton-outlined .MuiSvgIcon-root {
  color: orange;
  font-size: 20px;
  padding: 0;
  border: none;
  margin: 0;
}
.restOrderWrapper .customToggleButton .switch {
  margin: unset !important;
}

.mainModal {
  padding: unset;
}
.mainModal.restOrdersReportModal {
  width: 80%;
  height: 400px;
  background-color: white;
  border-radius: 20px;
  color: #000;
}
.mainModal.restOrdersReportModal .headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  padding: 8px 10px 5px 10px;
}
.mainModal.restOrdersReportModal .headerContainer .headingReport {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  margin: 1rem 2.5rem;
}
.mainModal.restOrdersReportModal .headerContainer .headingReport img {
  width: 50px;
  height: 40px;
}
.mainModal.restOrdersReportModal .headerContainer .headingReport h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
}
.mainModal.restOrdersReportModal .headerContainer .icon .MuiSvgIcon-root {
  font-size: 35px;
  color: #394053;
  cursor: pointer;
}
.mainModal.restOrdersReportModal .marginTop {
  justify-content: space-around;
}
@media screen and (max-width: 1160px) {
  .mainModal.restOrdersReportModal .marginTop {
    justify-content: center;
  }
}
@media screen and (max-width: 960px) {
  .mainModal.restOrdersReportModal .marginTop {
    margin-top: 20px;
  }
}
.mainModal.restOrdersReportModal .todayCard {
  margin-left: 54px;
}
.mainModal.restOrdersReportModal .reportCard {
  width: 285px;
  height: 257px;
  margin: 30px 10px 60px 20px;
  padding: 19px 1px 0 0;
  border-radius: 13px;
  box-shadow: 0 0 6px 4px rgb(216, 214, 214);
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 960px) {
  .mainModal.restOrdersReportModal .reportCard img {
    width: 250px;
  }
}
.mainModal.restOrdersReportModal .reportCard h4 {
  margin: 0 30px 7.3px 35px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.mainModal.restOrdersReportModal .reportCard h4 span {
  color: #000;
}
@media screen and (max-width: 560px) {
  .mainModal.restOrdersReportModal .reportCard h4 span {
    font-size: 14px;
  }
}
.mainModal.restOrdersReportModal .reportCard h3 {
  margin: 32px 30px 7.3px 35px;
  font-size: 32px;
  font-weight: 600;
  color: #01c9ff;
}
.mainModal.restOrdersReportModal .reportCard h5 {
  width: 83px;
  margin-top: 26px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-right: 25px;
}
.mainModal.restOrdersReportModal .reportCard .reportOrders {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainModal.restOrdersReportModal .reportCard .todayOrders {
  margin-top: 30px;
}
@media screen and (max-width: 1060px) {
  .mainModal.restOrdersReportModal .reportCard .todayOrders {
    margin-top: 50px;
  }
}
.mainModal.restOrdersReportModal .reportCard .todayReportImg {
  margin-top: -30px;
  height: 300px;
  width: auto;
}
.mainModal.restOrdersReportModal .reportCard .reportOrdersWeek {
  display: flex;
  align-items: center;
}
.mainModal.restOrdersReportModal .reportCard .reportOrdersWeek h3 {
  margin: 10px 30px 7.3px 35px;
  font-size: 32px;
  font-weight: 600;
  color: #01c9ff;
}
.mainModal.restOrdersReportModal .reportCard .reportOrdersWeek h5 {
  width: 83px;
  margin-top: 6px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.mainModal.restOrdersReportModal .reportCard .reportsCurve {
  width: 285px;
  height: 114px;
  margin: 22px 0 0;
  padding: 14.3px 50px 64.8px 127px;
  background-image: linear-gradient(184deg, #90e7ff 92%, rgba(190, 241, 255, 0) 40%);
}
@media screen and (max-width: 1060px) {
  .mainModal.restOrdersReportModal .reportCard {
    width: 250px;
    margin-left: auto !important;
    margin-top: 20px;
    margin-bottom: 20px !important;
    margin-right: auto !important;
  }
}
@media screen and (max-width: 1160px) {
  .mainModal.restOrdersReportModal {
    overflow-y: auto;
    width: 70%;
  }
}
@media screen and (max-width: 960px) {
  .mainModal.restOrdersReportModal {
    overflow-y: auto;
    width: 60%;
  }
}
@media screen and (max-width: 560px) {
  .mainModal.restOrdersReportModal {
    overflow-y: auto;
    width: 90%;
  }
}

.ordersFirstRow {
  justify-content: flex-end;
}
.ordersFirstRow .restMargin {
  margin-right: 20px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.headerContainer {
  border-bottom: 1px solid #c1c1c1;
}

.headingReport {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.headingReport img {
  width: 40px;
  height: 30px;
}
.headingReport h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 22px;
}

.reportCardWrapper {
  padding: 40px;
}

.reportCard {
  width: 290px;
  height: 275px;
  padding: 20px 0px 30px;
  border-radius: 12px;
  box-shadow: 0 0 6px 4px rgb(216, 214, 214);
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.reportCard h4 {
  margin: 0 30px 7.3px 35px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.reportCard h4 span {
  color: #000;
}
@media screen and (max-width: 560px) {
  .reportCard h4 span {
    font-size: 14px;
  }
}
.reportCard h3 {
  margin: 32px 30px 7.3px 35px;
  font-size: 32px;
  font-weight: 600;
  color: #01c9ff;
}
.reportCard h5 {
  width: 83px;
  margin-top: 26px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-right: 25px;
}
.reportCard .reportOrders {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reportCard .reportOrdersWeek {
  display: flex;
  align-items: center;
}
.reportCard .reportOrdersWeek h3 {
  margin: 10px 30px 7.3px 35px;
  font-size: 32px;
  font-weight: 600;
  color: #01c9ff;
}
.reportCard .reportOrdersWeek h5 {
  width: 83px;
  margin-top: 6px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.reportCard .reportsCurve {
  width: 285px;
  height: 114px;
  margin: 22px 0 0;
  padding: 14.3px 50px 64.8px 127px;
  background-image: linear-gradient(184deg, #90e7ff 92%, rgba(190, 241, 255, 0) 40%);
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.formcreate {
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
}
.formcreate .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  padding: 40px 20px 30px 20px;
}
@media screen and (max-width: 768px) {
  .formcreate .header {
    flex-direction: column;
  }
}
.formcreate .header .leftSideHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.formcreate .header .leftSideHeader .MuiSvgIcon-root {
  font-size: 30px;
}
.formcreate .header .leftSideHeader h4 {
  font-weight: 900;
  margin-left: 5px;
}
.formcreate .header .rightSideHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 15px;
  gap: 15px;
}
.formcreate .header .rightSideHeader .campaign_delete_icon {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .formcreate .header .rightSideHeader {
    margin-top: 20px;
    justify-content: space-between;
  }
}
.formcreate .header .rightSideHeader .green-outline {
  width: 150px;
}
.formcreate .header .rightSideHeader .green-outline .MuiSvgIcon-root {
  font-size: 25px;
  color: #20a258;
}
.formcreate .header .rightSideHeader .MuiSvgIcon-root {
  font-size: 40px;
  color: rgb(223, 37, 37);
}
.formcreate .MainWrapper {
  padding: 40px 20px 30px 20px;
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden;
}
.formcreate .MainWrapper .imagee {
  position: relative;
}
.formcreate .MainWrapper .imagee .image {
  width: 100%;
  height: 250px;
}
.formcreate .MainWrapper .imagee .image img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.formcreate .MainWrapper .imagee .editBannerBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px 20px;
}
.formcreate .MainWrapper .imagee .editBannerBtn span {
  color: black;
}
.formcreate .MainWrapper .ResponseContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .formcreate .MainWrapper .ResponseContainer {
    flex-direction: column;
    justify-content: space-between;
  }
}
.formcreate .MainWrapper .ResponseContainer .campig {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.formcreate .MainWrapper .ResponseContainer .campig .likeComments .MuiSvgIcon-root {
  color: blue;
  font-size: 30px;
}
.formcreate .MainWrapper .ResponseContainer .campig .likeComments .count {
  color: black;
  margin: 0 5px;
  font-size: 20px;
  font-weight: 700;
}
.formcreate .MainWrapper .ResponseContainer .campig .likeComments span {
  color: #515156;
}
.formcreate .MainWrapper .ResponseContainer .buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .formcreate .MainWrapper .ResponseContainer .buttonContainer {
    margin-top: 20px;
    justify-content: center;
    width: 100%;
  }
}
.formcreate .MainWrapper .ResponseContainer .buttonContainer .MuiButtonBase-root {
  background-color: #20a258;
  border-radius: 8px;
  padding: 10px 30px;
}
@media screen and (max-width: 768px) {
  .formcreate .MainWrapper .ResponseContainer .buttonContainer .MuiButtonBase-root {
    width: 100%;
  }
}
.formcreate .MainWrapper .ResponseContainer .buttonContainer .MuiButtonBase-root span {
  color: #ffffff;
}
.formcreate .MainWrapper .HeaderDescription {
  width: 100%;
  margin: 20px 0;
}
.formcreate .MainWrapper .HeaderDescription h4 {
  margin: 10px 0;
  font-size: 17px;
  color: black;
}
.formcreate .MainWrapper .Donation {
  color: green;
  margin-bottom: 15px;
}
.formcreate .MainWrapper .Raised {
  margin-left: -1020px;
  text-align: left;
}
.formcreate .MainWrapper .ReqAmount {
  display: flex;
  flex-direction: row;
}
.formcreate .MainWrapper .h5 {
  color: green;
}
.formcreate .MainWrapper .ReqAmountCreate {
  display: flex;
  flex-direction: column;
}
.formcreate .MainWrapper .editbanner {
  position: absolute;
  top: 20px;
  right: 10px;
}
.formcreate .MainWrapper .editforum {
  background-color: white;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  color: black;
}

.adminRestDishesContainer .customSelect.farmOrdersDropDown .MuiSelect-select::before,
.commonDropdown .customSelect.farmOrdersDropDown.restForum .MuiSelect-select::before {
  content: "Restaurant:" !important;
}

.adminRestDishesContainer .customSelect.farmOrdersDropDown .MuiSelect-select::before,
.commonDropdown .customSelect.farmOrdersDropDown.dishesType .MuiSelect-select::before {
  content: "Show:" !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customTab .MuiTabs-fixed {
  width: 100%;
}
.customTab .MuiTabs-root .MuiTabs-scroller.MuiTabs-indicator {
  background-color: blue;
  padding: 30px;
}
.customTab .MuiTabs-root .MuiTabs-scroller .PrivateTabIndicator-colorSecondary-5 {
  background-color: #20a258 !important;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer {
  border-bottom: 2px solid #c1c1c1;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper {
  text-transform: none;
  font-size: 16px;
  color: #575764;
  display: flex;
  align-items: center;
  font-weight: 800;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper .title {
  padding-right: 25px;
  color: #575764;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper .badge {
  position: absolute;
  right: 0;
  margin: 0;
  top: 24px;
  color: #fff;
  background-color: #ff8900;
  padding: 5px 8px;
  border-radius: 0;
  font-size: 15px;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .MuiTab-wrapper, .customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .title {
  color: #20a258;
}
.customTab .orderTab {
  padding: 0;
  overflow: auto;
}
.customTab .orderTab .MuiBox-root {
  padding: 0;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer {
  margin-top: 20px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root {
  box-shadow: none;
  border-radius: 0px;
  border-radius: none;
  min-height: 300px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
  background-color: #e3eaf7;
  color: #44444f;
  font-weight: 600;
  font-size: 13px;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  padding: 5px 10px;
  font-family: "Montserrat", sans-serif;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:first-child {
  width: 200px;
  padding-left: 40px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:last-child {
  padding-right: 10px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body {
  color: #000;
  font-weight: normal;
  font-size: 12px;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body:first-child {
  width: 200px;
  padding-left: 80px;
  font-weight: 500;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body:nth-child(5) {
  padding-left: 20px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body:last-child {
  padding-right: 20px;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body.paymentCell {
  text-transform: uppercase;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body .customerCell {
  display: flex;
  align-items: center;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .MuiTableCell-body .customerCell h5 {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .customerImage {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.customTab .orderTab .MuiBox-root .MuiTypography-root .tableContainer .orderPreparingRow {
  cursor: pointer;
}
.customTab .orderTab .cookingList {
  margin: 20px 0;
}
.customTab .orderTab .cookingList .cookingrow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f3f3f3;
  padding: 8px 30px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}
.customTab .orderTab .cookingList .cookingrow .blocks:nth-child(2) {
  flex-grow: 2;
}
.customTab .orderTab .cookingList .cookingrow .blocks:nth-child(3) {
  flex-grow: 3;
}
.customTab .orderTab .cookingList .cookingrow .blocks:nth-child(4) {
  flex-grow: 8;
}
.customTab .orderTab .cookingList .orderImg {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 10px;
}
.customTab .orderTab .cookingList h6 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.customTab .orderTab .cookingList h6.title {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
@media screen and (max-width: 560px) {
  .customTab .orderTab .cookingList h6 {
    font-size: 12px;
  }
}
.customTab .orderTab .cookingList h5 {
  font-size: 22px;
  color: #333;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 560px) {
  .customTab .orderTab .cookingList h5 {
    font-size: 16px;
  }
}
.customTab .orderTab .cookingList .addOns {
  font-family: "OpenSans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.customTab .orderTab .cookingList .outlineTextfield {
  margin-bottom: 10px;
}
.customTab .orderTab .cookingList .greenContained {
  height: auto !important;
}
.customTab .orderTab .cookingList .greenContained .MuiButton-root {
  background-color: orange;
  border-color: orange;
  padding: 10px 40px;
}
@media screen and (max-width: 560px) {
  .customTab .orderTab .cookingList .greenContained .MuiButton-root {
    padding: 5px 12px;
  }
}
.customTab .orderTab .cookingList .greenContained .MuiButton-root span {
  font-size: 17px;
}
.customTab .orderTab .cookingList .greenContained .MuiButton-root:hover {
  background-color: transparent;
  border-color: orange;
}
.customTab .orderTab .cookingList .greenContained .MuiButton-root:hover span {
  color: orange;
}
.customTab .orderTab .accordian {
  width: 100%;
}
.customTab .orderTab .accordian .scrollBlock {
  max-height: 120px;
  overflow: scroll;
}
.customTab .orderTab .accordian .scrollBlock::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .accordian .scrollBlock::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .accordian .scrollBlock::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .orderTab .setItemBtn .MuiButton-root {
  padding: 5.5px 10.5px;
  margin-top: 3px;
}
.customTab .adminOrderTab {
  background-color: white;
  overflow: auto;
}
.customTab .adminOrderTab .MuiBox-root {
  padding: 0;
}
.customTab .adminOrderTab .dangerCheckbox {
  display: flex;
  width: 100%;
  align-items: center;
}
.customTab .adminOrderTab .dangerCheckbox.payment-checkbox {
  justify-content: center;
}
.customTab .restOrderTab .MuiBox-root {
  padding: 0 20px;
}
.customTab.courseDetailTab .MuiTabs-scroller {
  background-color: #f7f7f7;
}
.customTab.courseDetailTab .MuiTabs-flexContainer {
  justify-content: space-around;
  border-bottom: 1px solid transparent !important;
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .customTab.courseDetailTab .MuiTabs-flexContainer {
    width: 100%;
    justify-content: flex-start;
    overflow-x: auto;
  }
  .customTab.courseDetailTab .MuiTabs-flexContainer::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    border-radius: 8px;
    scrollbar-face-color: #f7f7f7;
    scrollbar-track-color: #f7f7f7;
  }
  .customTab.courseDetailTab .MuiTabs-flexContainer::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #c3c3c3;
    scrollbar-face-color: #f7f7f7;
    scrollbar-track-color: #f7f7f7;
  }
  .customTab.courseDetailTab .MuiTabs-flexContainer::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
    box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
    scrollbar-face-color: #f7f7f7;
    scrollbar-track-color: #f7f7f7;
  }
}
.customTab.courseDetailTab .MuiTabs-flexContainer .MuiTab-root {
  padding: 15px 12px;
}
.customTab.courseDetailTab .MuiTabs-flexContainer .MuiTab-wrapper {
  font-size: 17px;
}
@media screen and (max-width: 767px) {
  .customTab.courseDetailTab .MuiTabs-flexContainer .MuiTab-wrapper {
    font-size: 14px;
  }
}
.customTab.courseDetailTab .tabPanel {
  height: 420px;
  min-height: 420px;
  overflow-y: auto;
  padding-top: 40px;
}
.customTab.courseDetailTab .tabPanel p {
  font-size: 16px;
  color: #1e1e1e;
  margin-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .customTab.courseDetailTab .tabPanel p {
    font-size: 14px;
  }
}
.customTab.customfarmTab {
  background-color: #ffffff;
  height: 100%;
}
.customTab.customfarmTab .MuiTabs-root {
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.customTab.customfarmTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
  color: green;
}
.customTab.restDetailTab {
  background-color: #ffffff;
  height: 100%;
}
.customTab.restDetailTab .MuiTabs-root {
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0px 0;
}
.customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
  padding: 23px 18px !important;
  flex-shrink: unset;
}
@media screen and (max-width: 560px) {
  .customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root {
    padding: 23px 8px !important;
  }
}
.customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper {
  text-transform: none !important;
  font-size: 15px;
}
@media screen and (max-width: 560px) {
  .customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper {
    font-size: 12px;
  }
}
.customTab.restDetailTab .MuiTabs-root .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
  color: #20a258;
}
.customTab.restDetailTab .MuiBox-root {
  padding: 0;
}
.customTab.learningCourseDetailTab .MuiBox-root {
  padding: 0;
}
.customTab.learningCourseDetailTab .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
  color: #20a258;
}
.customTab .badge .MuiBadge-anchorOriginBottomRightRectangle {
  bottom: 50% !important;
}
.customTab .badge .MuiBadge-badge {
  border-radius: 2px;
  background-color: #ff8900;
  color: white;
  font-weight: 700;
}

.processCell .MuiButton-containedPrimary {
  background-color: #20a258;
}
.processCell .MuiButton-containedPrimary span {
  color: #ffffff;
}
.processCell .MuiButton-containedPrimary :hover {
  background-color: #20a258;
  color: #ffffff;
}
.processCell .greenContained .MuiButton-root .MuiButton-label span {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.customTab .orderTab .cookingList h6 {
  color: #333;
}

.orderRest .MuiSelect-select.MuiSelect-select {
  color: #000;
}

.farmCollapse .cookingList {
  margin: 20px 0;
}
.farmCollapse .cookingList .cookingrow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f3f3f3;
  padding: 8px 30px;
  border-radius: 15px;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}
.farmCollapse .cookingList .cookingrow .blocks:nth-child(2) {
  flex-grow: 2;
  padding-left: 30px;
}
.farmCollapse .cookingList .cookingrow .blocks:nth-child(3) {
  flex-grow: 3;
}
.farmCollapse .cookingList .cookingrow .blocks:nth-child(4) {
  flex-grow: 8;
}
.farmCollapse .cookingList .orderImg {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 10px;
}
.farmCollapse .cookingList h6 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.farmCollapse .cookingList h6.title {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.farmCollapse .cookingList h5 {
  font-size: 22px;
  color: #333;
  font-weight: 600;
}
.farmCollapse .cookingList .addOns {
  font-family: "OpenSans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}
.farmCollapse .cookingList .outlineTextfield {
  margin-bottom: 10px;
}
.farmCollapse .cookingList .greenContained {
  height: auto !important;
}
.farmCollapse .cookingList .greenContained .MuiButton-root {
  background-color: orange;
  border-color: orange;
  padding: 10px 40px;
}
.farmCollapse .cookingList .greenContained .MuiButton-root span {
  font-size: 17px;
}
.farmCollapse .cookingList .greenContained .MuiButton-root:hover {
  background-color: transparent;
  border-color: orange;
}
.farmCollapse .cookingList .greenContained .MuiButton-root:hover span {
  color: orange;
}
.farmCollapse .accordian {
  width: 100%;
}
.farmCollapse .accordian .scrollBlock {
  max-height: 120px;
  overflow: scroll;
}
.farmCollapse .accordian .scrollBlock::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.farmCollapse .accordian .scrollBlock::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.farmCollapse .accordian .scrollBlock::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

.MuiTabs-scroller.MuiTabs-fixed {
  overflow-x: hidden !important;
}

.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.customTab .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.tableContainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  padding-right: 0;
}
.tableContainer::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer td {
  font-family: "Montserrat", sans-serif;
  font-size: 14px !important;
}
.tableContainer td h5 {
  font-size: 14px !important;
}
.tableContainer .MuiTableCell-stickyHeader {
  position: unset;
}
.tableContainer .tableHeaderContainer.MuiTableContainer-root {
  height: 500px !important;
  min-height: 500px !important;
}
.tableContainer .tableBodyContainer.MuiTableContainer-root {
  height: 500px !important;
  min-height: 500px !important;
}
.tableContainer .headerListContainer {
  padding: 20px;
}
.tableContainer .headerListContainer .prodListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1299px) {
  .tableContainer .headerListContainer .prodListHeader {
    flex-wrap: wrap;
  }
}
.tableContainer .headerListContainer .prodListHeader .link {
  font-weight: 600;
  color: #394053;
}
.tableContainer .headerListContainer .prodListHeader .link:hover {
  color: #20a258;
  text-decoration: none;
}
.tableContainer .headerListContainer .prodListHeader h4,
.tableContainer .headerListContainer .prodListHeader h6 {
  font-weight: bold;
  white-space: nowrap;
}
.tableContainer .headerListContainer .prodListHeader .blocks {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .blocks {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
.tableContainer .headerListContainer .prodListHeader .orderblocks {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .orderblocks {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .orderblocks {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.tableContainer .headerListContainer .prodListHeader .block1 .btnContainer .MuiButton-label {
  color: #fff;
  text-transform: capitalize;
  padding: 5px 0px;
}
@media screen and (max-width: 1299px) {
  .tableContainer .headerListContainer .prodListHeader .block1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    margin: 10px 0;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0px !important;
    margin: 10px 0;
    align-items: flex-start;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .addButton {
  padding-left: 10px;
}
@media screen and (max-width: 767px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root {
    width: 100%;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label .plus_icon::after {
    position: relative;
    left: -10px;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label .plus_icon::before {
    position: relative;
    left: -10px;
    top: 4px;
  }
}
@media screen and (max-width: 600px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton {
    width: 100%;
    padding-left: 0px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .rightOrders {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .rightOrders {
    margin-top: 10px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .text {
  padding-right: 10px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 20px;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .text {
    width: 100px;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .text {
    margin-right: 24px;
    font-size: 14px;
    width: 100px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .customSelect .MuiFormControl-root {
  width: 100%;
}
.tableContainer .headerListContainer .prodListBottomRow {
  margin-top: 20px;
  display: flex;
}
.tableContainer .headerListContainer .prodListBottomRow .MuiInput-formControl {
  margin-top: 0;
}
.tableContainer .headerListContainer .prodListBottomRow .MuiInputLabel-root {
  left: 13px;
  color: #ccc;
  font-weight: 500;
  transform: translate(0, 16.5px) !important;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .block1 {
  padding-left: 20px;
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect .customSelect {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect .customSelect .MuiFormControl-root {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .outlineTextfield {
  width: 83%;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .outlineTextfield {
    width: 100%;
  }
}
.tableContainer.MuiTableContainer-root {
  box-shadow: 0px 0px 4px 0px #ffffff;
  overflow: inherit;
  overflow-x: auto;
}
.tableContainer.MuiTableContainer-root .tableHeader {
  padding: 0 5px;
}
.tableContainer.MuiTableContainer-root .tableHeader h6, .tableContainer.MuiTableContainer-root .tableHeader a {
  font-weight: 600;
  color: #394053;
}
.tableContainer.MuiTableContainer-root .tableHeader h6:hover, .tableContainer.MuiTableContainer-root .tableHeader a:hover {
  color: #20a258;
  text-decoration: none;
}
.tableContainer.MuiTableContainer-root .tableHead {
  z-index: 5;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -1px !important;
  font-size: 11px;
  color: #44444f;
}
.tableContainer.MuiTableContainer-root .tableHead .tableHead-menu-column {
  width: 50px !important;
}
.tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
  padding: 5px 10px;
  background-color: #e3eaf7;
  color: #808080;
  font-weight: 600;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  border-top: 1px solid rgb(224, 224, 224);
}
@media screen and (max-width: 768px) {
  .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
    padding: 5px 20px;
  }
}
.tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:first-child {
  width: 200px;
  padding-left: 20px;
}
.tableContainer.MuiTableContainer-root .tableHead.dashBoadTableHead {
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.tableContainer.MuiTableContainer-root .tableHead.dashBoadTableHead .MuiTableCell-head {
  text-transform: none;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body {
  color: #515156;
  font-size: 17px;
  white-space: nowrap;
  padding: 20px 10px;
  cursor: pointer;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body:first-child {
  padding: 16px 20px;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body.BoldCell {
  font-weight: 600;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body.paymentCell {
  text-transform: uppercase;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body .productCell {
  display: flex;
  align-items: center;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body .productCell h5 {
  padding-left: 30px;
  font-weight: bold;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.tableContainer .productHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0px;
  margin-left: -5px;
}
.tableContainer .productHead span {
  padding-left: 24px;
}
.tableContainer .productHead span:nth-child(2) {
  font-family: "Poppins", sans-serif !important;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: #44444f;
}
.tableContainer .checkboxnameCell {
  display: flex;
  align-items: center;
}
.tableContainer .checkboxnameCell .customCheckbox {
  margin-right: 20px;
}
.tableContainer .checkboxnameCell span:nth-child(2) {
  color: #515156;
}
.tableContainer .MuiTableCell-body {
  color: #515156;
  font-size: 17px;
  white-space: nowrap;
}
.tableContainer .MuiTableCell-body.BoldCell {
  font-weight: 600;
}
.tableContainer .MuiTableCell-body.paymentCell {
  text-transform: uppercase;
}
.tableContainer .MuiTableCell-body .productCell {
  display: flex;
  align-items: center;
  width: 100%;
}
.tableContainer .MuiTableCell-body .productCell h5 {
  padding-left: 30px;
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  letter-spacing: 0.23px;
  color: #171725;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.tableContainer .MuiTableCell-body .productCell p {
  padding-left: 20px;
  font-weight: bold;
  font-size: 15px;
  width: 200px;
  word-break: break-word;
  word-wrap: wrap;
  white-space: pre-wrap;
}
.tableContainer .outlineChip .customChips.MuiChip-root {
  background-color: transparent;
  border: 1px solid #6ddfba;
}
.tableContainer .outlineChip .customChips.MuiChip-root span {
  color: #6ddfba;
  text-transform: uppercase;
}
.tableContainer .prodImage {
  height: 80px;
  width: 80px;
  object-fit: fill;
  border-radius: 8px;
}
.tableContainer .table-block .tableHead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}
.tableContainer .table-block .tableHead .MuiTableCell-head {
  padding: 5px 0;
  background-color: #e3eaf7;
  color: #44444f;
  font-weight: 600;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif !important;
  font-size: 11px;
  letter-spacing: 0.8px;
}
.tableContainer .table-block .tableHead .MuiTableCell-head:first-child {
  width: 200px;
  padding-left: 20px;
}
.tableContainer .table-block .tableHead .MuiTableCell-head:nth-child(4) {
  text-align: center;
}
.tableContainer .table-block .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body .productCell {
  width: 100%;
}
.tableContainer .table-block .MuiTableContainer-root {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 4px 0px #ffffff;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer.analyticsTable {
  width: 100%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
  background-color: #ffffff;
  color: #394053;
  font-size: 12px;
  letter-spacing: 2px;
  padding-left: 0;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .tableContainer.analyticsTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
    padding: 5px 15px;
  }
}
.tableContainer.analyticsTable.MuiTableContainer-root .MuiTableCell-body {
  padding: 8px 0;
  border: 0;
}
.tableContainer.analyticsTable.MuiTableContainer-root .MuiTypography-body1 {
  color: #394053;
  font-size: 15px;
  white-space: nowrap;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell:first-child {
  grid-gap: 20px;
  gap: 20px;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell p {
  padding: 0;
  font-weight: normal;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentSuccessIcon {
  background-color: #6ddfba;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentFailIcon {
  background-color: red;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentRefundIcon {
  background-color: #ff8d8d;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentProcessIcon {
  background-color: #5babfb;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 40%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 10%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 10%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(6) {
  width: 5%;
}
.tableContainer .restforums .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
  font-weight: normal;
}
.tableContainer .restforums .prodImage {
  margin-left: 20px;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(2) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(3) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(4) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .prodImage {
  margin-left: 20px;
}
.tableContainer .restDishesTable {
  border-radius: 0px !important;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 40%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 10%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 10%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .restDishesTable .prodImage {
  margin-left: 20px;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 20%;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root h5 {
  font-size: 17px;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 35%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 30%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 8%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 39%;
}
@media screen and (max-width: 768px) {
  .tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
    padding-left: 20px;
  }
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 3%;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar {
  width: 100%;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale span {
  color: #394053;
  font-size: 12px;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale span:nth-child(2) {
  margin: 0 10px;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .customLinearProgressbar {
  width: 100%;
}
.tableContainer .campTable .prodImage {
  margin-left: 20px;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
  text-align: left;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  text-align: center;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .MuiTableCell-body {
  text-align: left;
  font-size: 15px;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .MuiTableCell-body.accordianCell {
  padding: 0;
  border: 0;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root h5 {
  font-size: 15px;
  font-weight: 700;
}
.tableContainer.purchaseTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
  padding-right: 50px;
}
.tableContainer.purchaseTable.MuiTableContainer-root .MuiTableCell-body {
  padding-right: 50px;
}
.tableContainer.learningPurchasesContainer .tableHead {
  z-index: 5;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -1px !important;
  font-size: 11px;
  color: #44444f;
}
.tableContainer.learningPurchasesContainer .tableHead .MuiTableCell-head {
  text-align: left !important;
}
.tableContainer.learningPurchasesContainer .dropDownCell {
  text-align: right !important;
}
.tableContainer.learningPurchasesContainer .course-purchase-row {
  display: flex;
  align-items: center;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .VideoInput_video, .tableContainer.learningPurchasesContainer .course-purchase-row .techPurchase {
  height: 50px;
  width: 80px;
  object-fit: cover;
  border-radius: 15px;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .customerPurchaseDefaultCourse {
  height: 50px;
  width: 80px;
  object-fit: cover;
  border-radius: 15px;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .customerPurchase {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.tableContainer.learningPurchasesContainer .course-purchase-row h5 {
  margin-left: 10px;
  width: 150px;
  white-space: normal;
  line-height: 25px;
}

.blocks h6 {
  color: #333;
}

.course-purchase-dialog-row {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.course-purchase-dialog-row .VideoInput_video, .course-purchase-dialog-row .techPurchase {
  width: 240px;
  object-fit: cover;
  border-radius: 15px;
}
.course-purchase-dialog-row .customerPurchaseDefaultCourse {
  width: 240px;
  object-fit: cover;
  border-radius: 15px;
}
.course-purchase-dialog-row .customerPurchase {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.course-purchase-dialog-row h5 {
  margin-left: 10px;
  width: 150px;
  white-space: normal;
  line-height: 25px;
}

.viewLink {
  color: #20a258;
  text-decoration: underline;
}
.viewLink:hover {
  color: #5babfb;
}

@media screen and (max-width: 772px) {
  .subModalBlockPurchase {
    padding-bottom: 20px;
  }
}

.learningCourseTitle {
  width: 200px !important;
  word-break: break-word !important;
  word-wrap: wrap !important;
  white-space: pre-wrap !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.pickerContainer {
  margin-left: 10px;
  width: 100%;
}
.pickerContainer .MuiButton-root {
  font-size: 0.6rem;
}
.pickerContainer .MuiButton-root.MuiButton-outlined {
  background-color: #c1c1c1;
  width: 100%;
  border: none;
}
.pickerContainer .MuiButton-root.MuiButton-outlined span {
  font-weight: 600;
  color: #ffffff;
}
.pickerContainer .MuiButton-root.MuiButton-outlined .MuiSvgIcon-root {
  color: orange;
  font-size: 20px;
  padding: 0;
  border: none;
  margin: 0;
}
.pickerContainer .MuiPaper-root {
  background-color: blue;
}
.pickerContainer .grayButton {
  height: 100%;
}
.pickerContainer .grayButton .MuiButton-root {
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #f3f3f3;
  white-space: nowrap;
}
.pickerContainer .grayButton .MuiButton-root .MuiButton-label {
  color: black;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
}
.pickerContainer .grayButton .MuiButton-root .MuiButton-label span {
  padding: 0 10px;
}
.pickerContainer .grayButton .MuiButton-root .plus_icon {
  display: none;
}
.pickerContainer .grayButton .MuiButton-root:hover {
  background-color: #f8f8f8;
}

.dateRangePicker {
  overflow: hidden;
  width: 100%;
  padding: 10px 20px;
}
.dateRangePicker .text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  font-size: 10px;
}
.dateRangePicker .text span {
  font-weight: 700;
  font-size: 14px;
  color: #394053;
}
.dateRangePicker .DateRangePicker {
  width: 100%;
  margin-top: 10px;
}
.dateRangePicker .DateRangePicker .DateRangePicker__Weekdays {
  display: none;
}
.dateRangePicker .DateRangePicker .DateRangePicker__MonthDates {
  border: none !important;
  margin-top: 10px;
}
.dateRangePicker .DateRangePicker .DateRangePicker__Month {
  margin: unset !important;
  width: 100%;
}
.dateRangePicker .DateRangePicker .DateRangePicker__Date {
  border: 0 solid white !important;
}
.dateRangePicker .DateRangePicker .DateRangePicker__MonthHeader {
  border-bottom: 1px solid #c1c1c1;
  line-height: 40px;
  padding-bottom: 45px !important;
}
.dateRangePicker .DateRangePicker .DateRangePicker__MonthHeader .DateRangePicker__MonthHeaderLabel {
  color: #8994a6;
}
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarHighlight {
  display: none;
}
.dateRangePicker .DateRangePicker .DateRangePicker__PaginationArrow {
  border-radius: 50%;
}
.dateRangePicker .DateRangePicker .DateRangePicker__PaginationArrow:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.dateRangePicker .DateRangePicker .DateRangePicker__PaginationArrow--next {
  right: 0px;
}
.dateRangePicker .DateRangePicker .DateRangePicker__PaginationArrow--next .DateRangePicker__PaginationArrowIcon--next {
  border: solid #8994a6;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(-45deg);
  left: 9px;
  top: 11px;
}
.dateRangePicker .DateRangePicker .DateRangePicker__PaginationArrow--previous {
  left: 0px;
}
.dateRangePicker .DateRangePicker .DateRangePicker__PaginationArrow--previous .DateRangePicker__PaginationArrowIcon--previous {
  border: solid #8994a6;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(135deg);
  right: 9px;
  top: 11px;
}
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection {
  background-color: #e7ffed;
  border: 1px solid #e7ffed;
}
.dateRangePicker .DateRangePicker .DateRangePicker__DateLabel {
  font-size: 14px !important;
  color: #8994a6;
}
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection--start,
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection--end {
  background-color: #20a258;
  border: 1px solid #20a258;
  border-radius: 5px;
}
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection--start .DateRangePicker__DateLabel,
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection--end .DateRangePicker__DateLabel {
  color: white !important;
}
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection--start.DateRangePicker__DateLabel,
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection--end.DateRangePicker__DateLabel {
  color: white;
  font-size: 16px !important;
}
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection--start {
  left: 0;
}
.dateRangePicker .DateRangePicker .DateRangePicker__CalendarSelection--end {
  right: 0;
}
.dateRangePicker .DateRangePicker .DateRangePicker__Date--weekend {
  background-color: white;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.create-dish-container {
  background-color: #e3e9f6;
}
.create-dish-container .create-blk {
  border-radius: 14px;
  background-color: #fff;
}
.create-dish-container .create_dish .credit_dish_blk {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #979797;
}
.create-dish-container .create_dish .credit_dish_blk button {
  height: 40px;
  width: 40px;
}
.create-dish-container .create_dish .credit_dish_blk .MuiSvgIcon-root {
  width: 24px;
  height: 24px;
  color: #000;
}
.create-dish-container .create_dish .credit_dish_blk .credit_dish_txt {
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #171725;
}
.create-dish-container .create_dish .dish_inputs {
  padding: 20px;
}
.create-dish-container .create_dish .dish_inputs .outlineTextfield .MuiSvgIcon-root {
  display: none;
}
.create-dish-container .create_dish .dish_inputs .dish_label {
  padding-bottom: 4px;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #464a53;
}
.create-dish-container .create_dish .dish_inputs .description {
  border-radius: 4px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  color: #ddd;
}
.create-dish-container .create_dish .dish_inputs .Description_txt {
  margin-top: 20px;
  padding-bottom: 4px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #464a53;
}
.create-dish-container .create_dish .dish_img_blk {
  padding: 20px;
}
.create-dish-container .create_dish .dish_img_blk .dish_img {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #20a258;
}
@media screen and (max-width: 768px) {
  .create-dish-container .create_dish .dish_img_blk .dish_img {
    font-size: 18px;
  }
}
.create-dish-container .create_dish .dish_img_blk .add_photo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.create-dish-container .create_dish .dish_img_blk .add_photo .dish_images_cover {
  display: flex;
  align-items: center;
  border-radius: 10px;
  max-width: 130px;
  height: 126px;
  margin: 10px 0px;
  position: relative;
}
.create-dish-container .create_dish .dish_img_blk .add_photo .dish_remove_icon {
  position: absolute;
  z-index: 9999;
  top: -7%;
  right: -5%;
  height: 10px;
  width: 10px;
}
.create-dish-container .create_dish .dish_img_blk .add_photo .dish_remove_icon svg {
  font-size: 22px;
  background-color: #20a258;
  border-radius: 50%;
  color: #ffffff;
}
.create-dish-container .create_dish .dish_img_blk .add_photo .dish_image {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  -o-object-fit: fill;
     object-fit: fill;
}
.create-dish-container .create_dish .pricing {
  padding: 20px;
}
.create-dish-container .create_dish .pricing .pricing-text {
  font-size: 15px;
  color: #59bd83;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}
.create-dish-container .create_dish .pricing .customheading {
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.create-dish-container .create_dish .pricing .customheading h4 {
  font-weight: 600;
  color: #20a258;
  font-size: 20px;
  padding-right: 10px;
}
@media screen and (max-width: 768px) {
  .create-dish-container .create_dish .pricing .customheading h4 {
    font-size: 18px;
  }
}
.create-dish-container .create_dish .pricing .customheading hr {
  width: 100%;
  color: #20a258;
}
@media screen and (max-width: 768px) {
  .create-dish-container .create_dish .pricing .customheading hr {
    width: 100%;
  }
}
.create-dish-container .create_dish .create_dish_addons_heading hr {
  width: 87% !important;
}
@media screen and (max-width: 960px) {
  .create-dish-container .create_dish .create_dish_addons_heading hr {
    width: 80% !important;
  }
}
@media screen and (max-width: 560px) {
  .create-dish-container .create_dish .create_dish_addons_heading hr {
    width: 70% !important;
  }
}
.create-dish-container .create_dish .create_dish_add_ons_button {
  margin-top: 20px;
  width: 100%;
  padding: 4px 0px;
  border-radius: 7px;
  border: 1px solid #20a258;
  background-color: rgba(32, 162, 88, 0.06);
}
.create-dish-container .create_dish .create_dish_add_ons_button_label {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #20a258;
  text-transform: none;
}
.create-dish-container .create_dish .form-pricing {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-gap: 20px;
  gap: 20px;
}
.create-dish-container .create_dish .form-pricing .price_field_container {
  width: 100%;
}
.create-dish-container .create_dish .form-pricing .outlineTextfield {
  width: 100%;
}
.create-dish-container .create_dish .form-pricing .outlineTextfield .MuiInputBase-input {
  width: 100%;
}
.create-dish-container .create_dish .form-pricing .dish_price1 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  padding-bottom: 4px;
  font-size: 15px;
  margin: 0;
}
@media screen and (max-width: 360px) {
  .create-dish-container .create_dish .form-pricing .dish_price1 {
    font-size: 14px;
  }
}
.create-dish-container .create_dish .form-pricing .dish_price2 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 800;
  padding-bottom: 4px;
  margin: 0;
}
@media screen and (max-width: 360px) {
  .create-dish-container .create_dish .form-pricing .dish_price2 {
    font-size: 14px;
  }
}
.create-dish-container .product-status {
  padding: 20px;
}
.create-dish-container .product-status .product-text {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #20a258;
}
@media screen and (max-width: 768px) {
  .create-dish-container .product-status .product-text {
    font-size: 18px;
  }
}
.create-dish-container .product-status .create_dish_delete {
  text-align: right;
  padding-top: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #f90202;
  cursor: pointer;
}
.create-dish-container .product-status .green-outline {
  width: 100%;
}
.create-dish-container .dish-category {
  padding: 20px;
  min-height: 500px;
  margin-bottom: 10px;
}
.create-dish-container .dish-category .select-text {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #20a258;
  padding-top: 10px;
  padding-bottom: 10px;
}
.create-dish-container .dish-category .create_dish_discovery {
  padding-top: 14px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #abafb3;
}
.create-dish-container .dish-category .tag_label {
  padding-top: 15px;
  padding-bottom: 4px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #464a53;
}
.create-dish-container .dish-category .outlineTextfield .MuiSvgIcon-root {
  display: none;
}
@media screen and (max-width: 1024px) {
  .create-dish-container .dish-category {
    min-height: auto;
  }
}
@media screen and (max-width: 768px) {
  .create-dish-container .dish-category .select-text {
    font-size: 18px;
  }
}

.btns {
  padding: 20px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 5px;
}
.btns button {
  border-radius: 7px;
  padding: 9px 35px;
}
.btns button span {
  text-transform: none;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .btns button {
    width: 100%;
  }
}
.btns .cancel {
  background-color: #f8f8f8;
}
.btns .cancel span {
  color: #000;
}
.btns .save {
  background-color: #20a258;
}
.btns .save span {
  color: #fff;
}

.add_ons_category_delete {
  height: 26px;
  cursor: pointer;
}

.add_ons_details_container {
  margin-top: 10px;
  padding: 23px 20px;
  border-radius: 7px;
  background-color: #f3f3f3;
}
@media screen and (max-width: 560px) {
  .add_ons_details_container {
    padding: 23px 16px;
  }
}
.add_ons_details_container .add_ons_details_child {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
}
.add_ons_details_container .add_ons_detail {
  width: 100%;
}
.add_ons_details_container .add_ons_detail p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #464a53;
  margin-bottom: 8px;
}
.add_ons_details_container .add_on_details_action_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  border: 1px solid #c1c1c1;
  border-radius: 6px;
}
.add_ons_details_container .add_on_details_action_icon img {
  height: 24px;
  border-radius: 6px;
  cursor: pointer;
}
.add_ons_details_container .add_ons_delete_other div {
  margin-top: 0px !important;
}

@media screen and (max-width: 768px) {
  .create-dish-container .create-blk {
    margin-right: 0px !important;
    margin-bottom: 20px;
  }
}
.add_on_details_heading {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #464a53;
  margin-bottom: 8px;
}

.dish_type_container {
  text-align: right;
}
.dish_type_container .dish_switch_label {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #464a53;
}

.create_dish_loader_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  height: 73vh;
}
.create_dish_loader_box .create_dish_loader {
  color: #20a258 !important;
}

.delete_dish_dialog {
  min-width: 30vw !important;
}
.delete_dish_dialog .delete_dish_dialog_title {
  border-bottom: 1px solid #979797 !important;
  padding: 10px 20px !important;
}
.delete_dish_dialog .delete_dish_dialog_title p {
  font-size: 18px;
  color: #171725;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.delete_dish_dialog .delete_dish_dialog_content {
  padding: 20px 25px 35px 23px !important;
}
.delete_dish_dialog .delete_dish_dialog_content p {
  color: #000;
}
.delete_dish_dialog .delete_dish_dialog_actions {
  margin: 0px 0px 15px 12px !important;
  display: flex;
  justify-content: flex-start;
}
.delete_dish_dialog .delete_dish_delete_btn {
  background-color: #20a258 !important;
  padding: 6px 20px;
  border-radius: 5px;
}
.delete_dish_dialog .delete_dish_delete_btn span {
  color: #ffffff;
  text-transform: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.delete_dish_dialog .delete_dish_cancel_btn {
  background-color: #f2f2f2;
  padding: 6px 20px;
  margin-left: 10px;
  border-radius: 5px;
}
.delete_dish_dialog .delete_dish_cancel_btn span {
  text-transform: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customFileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 20px;
  max-width: 370px;
  margin: 10px 0;
}
.customFileUpload .fileUploadInput {
  display: none;
}
.customFileUpload .fileUploadLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customFileUpload .fileUploadLabel img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
.customFileUpload .fileUploadLabel span {
  color: #20a258;
  font-weight: 600;
  padding-top: 10px;
}
.customFileUpload .fileUploadIcon img {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 23px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restaurantForumsEditContainer {
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
}
.restaurantForumsEditContainer .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  padding: 40px 20px 30px 20px;
}
@media screen and (max-width: 768px) {
  .restaurantForumsEditContainer .header {
    flex-direction: column;
  }
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .header {
    padding: 20px 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.restaurantForumsEditContainer .header .leftSideHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.restaurantForumsEditContainer .header .leftSideHeader .MuiSvgIcon-root {
  font-size: 30px;
  color: #3a3a3a;
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .header .leftSideHeader .MuiSvgIcon-root {
    font-size: 20px;
  }
}
.restaurantForumsEditContainer .header .leftSideHeader h4 {
  font-weight: 900;
  margin-left: 5px;
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .header .leftSideHeader h4 {
    font-size: 16px;
    margin-left: -5px;
  }
}
.restaurantForumsEditContainer .header .rightSideHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 15px;
  gap: 15px;
}
.restaurantForumsEditContainer .header .rightSideHeader .campaign_delete_icon {
  width: 27px;
  height: 28px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .restaurantForumsEditContainer .header .rightSideHeader {
    margin-top: 20px;
    justify-content: space-between;
    margin-right: 20px;
  }
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .header .rightSideHeader {
    margin: 0 20px 0 10px;
    width: 75%;
    grid-gap: 5px;
    gap: 5px;
  }
}
.restaurantForumsEditContainer .header .rightSideHeader .green-outline {
  width: 150px;
}
.restaurantForumsEditContainer .header .rightSideHeader .green-outline .MuiSvgIcon-root {
  font-size: 25px;
  color: #20a258;
}
.restaurantForumsEditContainer .header .rightSideHeader .MuiSvgIcon-root {
  font-size: 40px;
  color: rgb(223, 37, 37);
}
.restaurantForumsEditContainer .header .rightSideHeader .delIconSize {
  width: 27px;
  height: 28px;
  margin-top: 10px;
  cursor: pointer;
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .header .rightSideHeader .delIconSize {
    width: 22px;
    height: 22px;
    margin-top: 0px;
  }
}
.restaurantForumsEditContainer .forumMainWrapper {
  padding: 40px 20px 30px 20px;
  width: 100%;
  overflow: hidden;
}
.restaurantForumsEditContainer .forumMainWrapper .imageContainer {
  position: relative;
}
.restaurantForumsEditContainer .forumMainWrapper .imageContainer .image {
  width: 100%;
  height: 250px;
}
.restaurantForumsEditContainer .forumMainWrapper .imageContainer .image img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}
.restaurantForumsEditContainer .forumMainWrapper .imageContainer .editform {
  margin-left: 1050px;
  margin-bottom: -60px;
  -webkit-text-decoration: black;
          text-decoration: black;
}
.restaurantForumsEditContainer .forumMainWrapper .imageContainer .editforum {
  position: absolute;
  top: 20px;
  right: 10px;
}
.restaurantForumsEditContainer .forumMainWrapper .imageContainer .ReseditForum {
  background-color: white;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  color: black;
}
.restaurantForumsEditContainer .forumMainWrapper .imageContainer .editBannerBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px 20px;
}
.restaurantForumsEditContainer .forumMainWrapper .imageContainer .editBannerBtn span {
  color: black;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .campaignSubtitle {
  white-space: nowrap;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .campaignSubtitle span {
  padding-left: 10px;
  color: #20a258;
}
@media screen and (max-width: 768px) {
  .restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer {
    flex-direction: column;
    justify-content: space-between;
  }
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments {
  display: flex;
  align-items: center;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments .MuiSvgIcon-root {
  color: blue;
  font-size: 30px;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments .count {
  color: black;
  margin: 0 5px;
  font-size: 20px;
  font-weight: 700;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments span {
  color: #515156;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments:nth-child(2) {
  margin-left: 30px;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments:nth-child(2) .MuiSvgIcon-root {
  color: orange;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer .MuiButton-label {
  text-transform: none;
}
@media screen and (max-width: 768px) {
  .restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer {
    margin-top: 20px;
    justify-content: center;
    width: 100%;
  }
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer .MuiButtonBase-root {
  background-color: #20a258;
  border-radius: 8px;
  padding: 10px 30px;
}
@media screen and (max-width: 768px) {
  .restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer .MuiButtonBase-root {
    width: 100%;
  }
}
.restaurantForumsEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer .MuiButtonBase-root span {
  color: #ffffff;
}
.restaurantForumsEditContainer .forumMainWrapper .forumHeaderDescription {
  width: 100%;
  margin: 20px 0;
}
.restaurantForumsEditContainer .forumMainWrapper .forumHeaderDescription h4 {
  margin: 10px 0;
  font-size: 17px;
  color: #515156;
}
.restaurantForumsEditContainer .forumMainWrapper .forumHeaderDescription textarea {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  border-color: #c1c1c1;
  color: #808080;
}
.restaurantForumsEditContainer .forumMainWrapper .forumHeaderDescription .customTextArea {
  position: relative;
}
.restaurantForumsEditContainer .forumMainWrapper .forumHeaderDescription .customTextArea .MuiTextarea-root {
  padding: 0;
}
.restaurantForumsEditContainer .forumMainWrapper .forumHeaderDescription .customTextArea textarea {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  border-color: #c1c1c1;
  color: #394053;
}
.restaurantForumsEditContainer .forumMainWrapper .forumHeaderDescription .customTextArea .textCount {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationHeading h4 {
  color: #20a258;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationHeading hr {
  width: 100%;
  margin-left: 10px;
  height: 2px;
  background-color: #20a258;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo h3 {
  font-size: 17px;
  color: #515156;
  font-weight: 700;
  text-align: left;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .requiredDontation h3 {
  margin: 10px 0;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .requiredDontation .outlineTextfield {
  min-width: 300px;
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .requiredDontation .outlineTextfield {
    min-width: auto;
  }
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .requiredDontation .outlineTextfield .customTextfield .MuiOutlinedInput-adornedStart {
  font-size: 1.3rem;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaised h3 {
  margin: 15px 0;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaised h4 {
  padding: 8px 0;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage {
  background-color: #f7f7f7;
  border-radius: 20px;
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage {
    width: 100%;
  }
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage .donationBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage .donationBlock .customProgressbar {
  width: unset !important;
  height: unset !important;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage .donationBlock .customProgressbar .circularprog {
  width: unset !important;
  height: unset !important;
  padding: unset !important;
  min-height: unset !important;
  background-color: unset;
  margin-bottom: 20px;
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage .donationBlock .customProgressbar .circularprog {
    margin-left: 20px;
  }
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage .donationBlock .customProgressbar .circularprog h3 {
  display: none;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage .donationBlock h3 {
  margin: 0 10px;
}
@media screen and (max-width: 560px) {
  .restaurantForumsEditContainer .forumMainWrapper .donationContainer .donationInfo .donationRaisingpercentage .donationBlock h3 {
    margin: 0 10px 0 0;
  }
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .farmsDonation {
  flex-wrap: wrap;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer {
  margin-top: 20px;
  width: 100%;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer h3 {
  color: #515156;
  font-size: 17px;
  font-weight: 600;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .donnersList {
  margin-top: 20px;
  width: 100%;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .donnersList .donnerContainer {
  border: 1px solid #c3c3c3;
  border-radius: 7px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  margin: 20px 0;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .donnersList .donnerContainer .profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .donnersList .donnerContainer .profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .donnersList .donnerContainer .profile p {
  color: #000;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .donnersList .donnerContainer p {
  color: #c3c3c3;
  font-weight: 600;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .donnersList .donnerContainer h3 {
  color: #20a258;
  font-weight: 600;
  font-size: 22px;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .empty_donners_container {
  border: 1px solid #c3c3c3;
  border-radius: 7px;
  height: 25vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.restaurantForumsEditContainer .forumMainWrapper .donationContainer .donnersContainer .empty_donners_container p {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  color: #3a3a3a;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer {
  width: 100%;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer h4 {
  font-size: 17px;
  color: #515156;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer .replyComment {
  padding-left: 20px;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer .newComment {
  position: relative;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer .newComment textarea {
  width: 100%;
  height: 100%;
  margin: 10px 0;
  color: #808080;
  border-color: #808080;
  padding: 20px;
  border-radius: 15px;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer .newComment .commentbtn {
  position: absolute;
  right: 30px;
  bottom: 50px;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer .newComment .commentbtn .MuiButton-contained {
  background-color: orange;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer .newComment .commentbtn .MuiButton-contained span {
  font-weight: 600;
  color: #ffffff;
}
.restaurantForumsEditContainer .forumMainWrapper .commentContainer .newComment .commentbtn .MuiButton-contained .MuiSvgIcon-root {
  color: #ffffff;
  font-size: 20px;
  padding: 0;
  border: none;
  margin: 0;
}

.farmforumreply {
  display: flex;
  width: 170px;
  justify-content: space-between;
}

.newCommentreply textarea {
  height: 175px !important;
}

.replyBtnContainer {
  position: absolute;
  right: 30px;
  bottom: 50px;
}
.replyBtnContainer .MuiButton-contained {
  background-color: orange;
}
.replyBtnContainer .MuiButton-contained span {
  font-weight: 600;
  color: #ffffff;
}
.replyBtnContainer .MuiButton-contained .MuiSvgIcon-root {
  color: #ffffff;
  font-size: 20px;
  padding: 0;
  border: none;
  margin: 0;
}

.sendBtn {
  margin-left: 10px !important;
}

.cancelBtn {
  margin-right: 10px !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.commentReviewContainer {
  width: 100%;
  margin: 20px 0;
  border: 1px solid #c1c1c1;
  padding: 30px;
  border-radius: 15px;
}
@media screen and (max-width: 560px) {
  .commentReviewContainer {
    padding: 20px 15px;
  }
}
.commentReviewContainer .detailsContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 30px;
  gap: 30px;
}
@media screen and (max-width: 768px) {
  .commentReviewContainer .detailsContainer {
    flex-direction: column;
  }
}
@media screen and (max-width: 560px) {
  .commentReviewContainer .detailsContainer {
    flex-direction: row;
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (max-width: 768px) {
  .commentReviewContainer .detailsContainer .imageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 560px) {
  .commentReviewContainer .detailsContainer .imageContainer {
    width: 20%;
  }
}
.commentReviewContainer .detailsContainer .imageContainer .MuiAvatar-root {
  width: 80px;
  height: 80px;
}
@media screen and (max-width: 560px) {
  .commentReviewContainer .detailsContainer .imageContainer .MuiAvatar-root {
    width: 40px;
    height: 40px;
  }
}
.commentReviewContainer .detailsContainer .reviewContainer {
  width: 100%;
}
@media screen and (max-width: 560px) {
  .commentReviewContainer .detailsContainer .reviewContainer {
    margin-top: -5px;
  }
}
.commentReviewContainer .detailsContainer .reviewContainer .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: unset;
}
.commentReviewContainer .detailsContainer .reviewContainer .heading h5 {
  font-size: 20px;
  color: black;
  font-weight: 600;
  text-transform: capitalize;
}
@media screen and (max-width: 560px) {
  .commentReviewContainer .detailsContainer .reviewContainer .heading h5 {
    font-size: 14px;
  }
}
.commentReviewContainer .detailsContainer .reviewContainer .heading h6 {
  color: #808080;
  font-weight: 600;
  margin: 5px 0;
}
.commentReviewContainer .detailsContainer .reviewContainer .heading p {
  color: #c1c1c1;
  font-size: 15px;
  margin: 0;
}
.commentReviewContainer .detailsContainer .reviewContainer .rating {
  margin-top: 10px;
}
.commentReviewContainer .detailsContainer .reviewContainer .rating .MuiRating-iconFilled {
  color: #ffb400;
}
.commentReviewContainer .detailsContainer .reviewContainer .review {
  margin-top: 10px;
  color: #808080;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 560px) {
  .commentReviewContainer .detailsContainer .reviewContainer .review {
    margin-left: 8px;
  }
}
.commentReviewContainer .buttonContainer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.commentReviewContainer .buttonContainer .MuiSvgIcon-root {
  color: brown;
  font-size: 35px;
  padding: 1px;
  border: 1px solid brown;
  border-radius: 3px;
  margin-right: 20px;
}
.commentReviewContainer .buttonContainer .MuiButton-contained {
  background-color: #20a258;
}
.commentReviewContainer .buttonContainer .MuiButton-contained span {
  font-weight: 600;
  color: #ffffff;
}
.commentReviewContainer .buttonContainer .MuiButton-contained .MuiSvgIcon-root {
  color: #ffffff;
  font-size: 20px;
  padding: 0;
  border: none;
  margin: 0;
}
.commentReviewContainer .buttonContainer .MuiButton-outlined {
  background-color: orange;
  border: none;
}
.commentReviewContainer .buttonContainer .MuiButton-outlined span {
  font-weight: 600;
  color: #ffffff;
}
.commentReviewContainer .buttonContainer .MuiButton-outlined .MuiSvgIcon-root {
  color: orange;
  font-size: 20px;
  padding: 0;
  border: none;
  margin: 0;
}
.commentReviewContainer .buttonContainer .dustbinBtnOutline {
  border: 1.2px solid red;
  padding: 7px;
  border-radius: 4px;
  margin-right: 18px;
  cursor: pointer;
}
.commentReviewContainer .buttonContainer .dustbinBtnOutline img {
  width: 20px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.createcampaignEditContainer {
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
}
.createcampaignEditContainer .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  padding: 40px 20px 30px 20px;
}
@media screen and (max-width: 768px) {
  .createcampaignEditContainer .header {
    flex-direction: column;
  }
}
.createcampaignEditContainer .header .leftSideHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.createcampaignEditContainer .header .leftSideHeader .MuiSvgIcon-root {
  font-size: 30px;
}
.createcampaignEditContainer .header .leftSideHeader h4 {
  font-weight: 900;
  margin-left: 5px;
}
.createcampaignEditContainer .header .rightSideHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 15px;
  gap: 15px;
}
.createcampaignEditContainer .header .rightSideHeader .campaign_delete_icon {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .createcampaignEditContainer .header .rightSideHeader {
    margin-top: 20px;
    justify-content: space-between;
  }
}
.createcampaignEditContainer .forumMainWrapper {
  padding: 40px 20px 30px 20px;
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden;
}
.createcampaignEditContainer .forumMainWrapper .imageContainer {
  position: relative;
}
.createcampaignEditContainer .forumMainWrapper .imageContainer .image {
  width: 100%;
  height: 250px;
}
.createcampaignEditContainer .forumMainWrapper .imageContainer .image img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.createcampaignEditContainer .forumMainWrapper .imageContainer .editBannerBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px 20px;
}
.createcampaignEditContainer .forumMainWrapper .imageContainer .editBannerBtn span {
  color: black;
}
.createcampaignEditContainer .forumMainWrapper .forumResponseContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .createcampaignEditContainer .forumMainWrapper .forumResponseContainer {
    flex-direction: column;
    justify-content: space-between;
  }
}
.createcampaignEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.createcampaignEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments .MuiSvgIcon-root {
  color: blue;
  font-size: 30px;
}
.createcampaignEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments .count {
  color: black;
  margin: 0 5px;
  font-size: 20px;
  font-weight: 700;
}
.createcampaignEditContainer .forumMainWrapper .forumResponseContainer .likesCommentsContainer .likeComments span {
  color: #515156;
}
.createcampaignEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .createcampaignEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer {
    margin-top: 20px;
    justify-content: center;
    width: 100%;
  }
}
.createcampaignEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer .MuiButtonBase-root {
  background-color: #20a258;
  border-radius: 8px;
  padding: 10px 30px;
}
@media screen and (max-width: 768px) {
  .createcampaignEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer .MuiButtonBase-root {
    width: 100%;
  }
}
.createcampaignEditContainer .forumMainWrapper .forumResponseContainer .buttonContainer .MuiButtonBase-root span {
  color: #ffffff;
}
.createcampaignEditContainer .forumMainWrapper .forumHeaderDescription {
  width: 100%;
  margin: 20px 0;
}
.createcampaignEditContainer .forumMainWrapper .forumHeaderDescription h4 {
  margin: 10px 0;
  font-size: 17px;
  color: grey;
}
.createcampaignEditContainer .forumMainWrapper .forumHeaderDescription textarea {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  border-color: #c1c1c1;
  color: #808080;
}
.createcampaignEditContainer .forumMainWrapper .Donation {
  color: green;
  margin-bottom: 15px;
}
.createcampaignEditContainer .forumMainWrapper .Raise {
  margin-left: -950px;
  text-align: left;
}
.createcampaignEditContainer .forumMainWrapper .h5 {
  color: green;
}
.createcampaignEditContainer .forumMainWrapper .ReqAmount {
  display: flex;
  flex-direction: row;
}
.createcampaignEditContainer .forumMainWrapper .DonaRaisedt {
  margin-left: 70px;
}
.createcampaignEditContainer .forumMainWrapper .edit {
  position: absolute;
  top: 20px;
  right: 10px;
}
.createcampaignEditContainer .forumMainWrapper .editCamp {
  background-color: white;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  color: black;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer {
  margin-top: 20px;
  width: 100%;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer h3 {
  color: #515156;
  font-size: 17px;
  font-weight: 600;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer .donnersList {
  margin-top: 20px;
  width: 100%;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer .donnersList .donnerContainer {
  border: 1px solid #c3c3c3;
  border-radius: 7px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  margin: 20px 0;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer .donnersList .donnerContainer .profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer .donnersList .donnerContainer .profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer .donnersList .donnerContainer .profile p {
  color: #000;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer .donnersList .donnerContainer p {
  color: #c3c3c3;
  font-weight: 600;
}
.createcampaignEditContainer .forumMainWrapper .donnersContainer .donnersList .donnerContainer h3 {
  color: #20a258;
  font-weight: 600;
  font-size: 22px;
}

.campaign_status .MuiSelect-select {
  margin-top: 2px !important;
}

.campaignTextArea {
  resize: none;
  font-family: "Poppins", sans-serif !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customProgress {
  margin-right: 15px;
  width: 100%;
}
.customProgress .circularprogedit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  text-align: center;
  margin-left: 70px;
  background-color: #f7f7f7;
  border-radius: 15px;
  padding-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .customProgress .circularprogedit {
    margin-right: 20px;
    margin-left: 0px;
  }
}
.customProgress .circularprogedit .circularprog {
  padding: 20px 0;
  font-size: 17px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  min-height: 0px;
  padding: 5px !important;
  margin-left: -150px;
}
.customProgress .circularprogedit .circularProgressedit {
  position: relative;
  display: flex;
  flex-direction: row;
}
.customProgress .circularprogedit .circularProgressedit p {
  position: absolute;
  padding: unset;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 32px;
  font-weight: 600;
  color: #394053;
  width: 40%;
  text-align: center;
}
.customProgress .circularprogedit h4 {
  padding: 20px 0;
  font-weight: 600;
  color: #394053;
}
.customProgress .circularprogedit .MuiCircularProgress-root {
  width: 130px !important;
  height: auto !important;
}
.customProgress .circularprogedit .MuiCircularProgress-colorPrimary {
  color: #20a258;
}

.customLinearProgressbar progress {
  border-radius: 7px;
  width: 100%;
  height: 7px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.customLinearProgressbar progress::-webkit-progress-bar {
  background-color: #c1c1c1;
  border-radius: 7px;
}
.customLinearProgressbar progress::-webkit-progress-value {
  background-color: #20a258;
  border-radius: 7px;
}
.customLinearProgressbar progress::-moz-progress-bar {
  /* style rules */
  background-color: #20a258;
  border-radius: 7px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.tableContainer .farmListsContainer {
  width: 100% !important;
  padding: 0 10px;
}
.tableContainer .farmListsContainer .farmContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 10px 20px;
}
.tableContainer .farmListsContainer .farmContainer h4 {
  font-size: 15px;
}
.tableContainer .farmListsContainer .farmContainer .option-dropDown {
  width: unset;
}
.tableContainer.discussionTableContainer {
  height: auto;
}
.tableContainer.discussionTableContainer .discussionTableHeader {
  width: 100%;
  padding: 40px 30px 20px 30px;
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
}
.tableContainer.discussionTableContainer .discussionTableHeader .discussionHeading {
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .discussionTableHeader {
    flex-direction: column;
    padding: 15px 14px;
    display: flex;
    align-items: flex-start;
  }
}
.tableContainer.discussionTableContainer .discussionTableHeader .outlineSelect {
  width: auto;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .discussionTableHeader .outlineSelect {
    width: 100%;
  }
}
.tableContainer.discussionTableContainer .discussionTableHeader .MuiSelect-select {
  width: 300px;
  min-width: auto !important;
  max-width: 250px !important;
}
.tableContainer.discussionTableContainer .discussionTableHeader .MuiSelect-select::after {
  top: 15px;
}
.tableContainer.discussionTableContainer .discussionTableHeader .MuiAccordionSummary-content {
  margin: 0;
  padding: 20px 10px;
}
.tableContainer.discussionTableContainer .discussionAccordian {
  box-shadow: none !important;
}
.tableContainer.discussionTableContainer .discussionAccordian::before {
  display: none;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiAccordionSummary-root {
  padding: 10px 30px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiAccordionSummary-content {
  margin: 0 !important;
  padding: 0;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiAccordionSummary-content p {
  font-size: 20px;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiAccordionSummary-content .MuiSvgIcon-root {
  fill: #394053;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiAccordionDetails-root p {
  width: 100%;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiList-root {
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .discussionAccordian .MuiList-root {
    margin-right: 0;
  }
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiList-root .MuiListItem-root {
  padding-bottom: 5px;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiList-root .MuiListItem-root, .tableContainer.discussionTableContainer .discussionAccordian .MuiList-root a {
  padding: 0;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiList-root .MuiListItem-root:hover, .tableContainer.discussionTableContainer .discussionAccordian .MuiList-root a:hover {
  background-color: transparent;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiList-root .MuiListItem-root .MuiTypography-root, .tableContainer.discussionTableContainer .discussionAccordian .MuiList-root a .MuiTypography-root {
  color: #394053;
  font-size: 15px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .discussionAccordian .MuiList-root .MuiListItem-root .MuiTypography-root, .tableContainer.discussionTableContainer .discussionAccordian .MuiList-root a .MuiTypography-root {
    font-size: 13px;
  }
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiList-root a:hover .MuiTypography-root, .tableContainer.discussionTableContainer .discussionAccordian .MuiList-root a.active .MuiTypography-root {
  color: #20a258;
}
.tableContainer.discussionTableContainer .discussionAccordian .MuiList-root .badge {
  background-color: #20a258;
  padding: 7px;
  color: #fff;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
.tableContainer.discussionTableContainer .discussionBody {
  overflow-y: scroll;
  height: 565px;
}
.tableContainer.discussionTableContainer .discussionBody::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer.discussionTableContainer .discussionBody::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer.discussionTableContainer .discussionBody::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
@media screen and (max-width: 768px) {
  .tableContainer.discussionTableContainer .discussionBody {
    height: 200px;
    border: 1px solid #4aa561;
    border-radius: 10px;
    margin: 30px 10px;
  }
}
.tableContainer.discussionTableContainer .chatNoDiscussionData {
  margin: 20px;
}
.tableContainer.discussionTableContainer .leftBox {
  border-right: 2px solid #ccc;
  margin-top: 8px;
  padding: 0;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody {
  padding: 30px 15px 0 15px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;
  max-height: 565px;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
@media screen and (max-width: 768px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatBody {
    border: 1px solid #b6bcc5;
    margin: 10px;
    border-radius: 10px;
    padding: 5px;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .receiveChatBodyBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .sentChatBodyBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px 0 10px 10px;
  margin-bottom: 20px;
  max-width: 700px;
  width: 700px;
}
@media screen and (max-width: 768px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox {
    width: 100%;
    padding: 8px;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox h3 {
  font-weight: 600;
  color: #0e0e0e;
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox h3 {
    font-size: 14px;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox p {
  font-size: 17px;
  margin: 15px 0;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox p {
    font-size: 13px;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox span {
  color: #909090;
  font-weight: 600;
  font-size: 15px;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox span {
    font-size: 14px;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatRow {
  display: flex;
  margin: 0;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow .customButton {
  margin-bottom: 0 !important;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow .customButton .MuiButton-root {
  padding: 0;
  max-width: 100px;
  height: 33px;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow .DelIcon {
  height: 30px;
  width: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow .DelButton {
  padding: 0;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow .DelButton:hover {
  background-color: transparent;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow .greenOutlineButton .MuiButton-root {
  border-radius: 30px;
  border-width: 2px;
  background-color: transparent;
  padding: 0;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow .greenOutlineButton .MuiButton-root .MuiButton-label {
  padding: 0;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox .chatBottomRow .greenOutlineButton .MuiButton-root .MuiButton-label span {
  color: #20a258;
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox.receivedChatBox {
  margin-left: 100px;
  background-color: #f5f5f5;
}
@media screen and (max-width: 768px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox.receivedChatBox {
    margin-left: 0;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox.sentChatBox {
  background-color: #edf4ed;
  margin-right: 100px;
}
@media screen and (max-width: 768px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox.sentChatBox {
    margin-right: 0;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatBody .chatBox.sentChatBox .chatBottomRow {
  justify-content: flex-start;
}
.tableContainer.discussionTableContainer .chatWrapper .chatFooter {
  display: flex;
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatFooter {
    flex-direction: column;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatFooter .greenContained {
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatFooter .greenContained {
    margin-top: 20px;
    margin-left: 0;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatFooter .greenContained .MuiButton-root {
  padding: 5px 20px;
  border-radius: 30px;
  border-color: #c3c3c3;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatFooter .greenContained .MuiButton-root {
    width: 100%;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatFooter .MuiFormControl-root {
  border-radius: 30px;
}
.tableContainer.discussionTableContainer .chatWrapper .chatEditForm {
  display: flex;
  padding: 5px;
  border: 1px solid #97b7ab;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatEditForm {
    flex-direction: column;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatEditForm .customButton {
  margin-bottom: 0 !important;
}
.tableContainer.discussionTableContainer .chatWrapper .chatEditForm .outlineTextfield {
  background-color: transparent !important;
}
.tableContainer.discussionTableContainer .chatWrapper .chatEditForm .MuiFormControl-root {
  border-radius: 5px;
}
.tableContainer.discussionTableContainer .chatWrapper .chatEditForm .greenContained {
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatEditForm .greenContained {
    margin-top: 20px;
    margin-left: 0;
  }
}
.tableContainer.discussionTableContainer .chatWrapper .chatEditForm .greenContained .MuiButton-root {
  padding: 5px 20px;
  border-radius: 5px;
}
.tableContainer.discussionTableContainer .chatWrapper .chatEditForm .greenContained .MuiButton-root span {
  color: #ffffff;
}
.tableContainer.discussionTableContainer .chatWrapper .chatEditForm .greenContained .MuiButton-root:hover span {
  color: #20a258;
}
@media screen and (max-width: 767px) {
  .tableContainer.discussionTableContainer .chatWrapper .chatEditForm .greenContained .MuiButton-root {
    width: 100%;
  }
}
.tableContainer.discussionTableContainer .discussionComment .MuiInputBase-root {
  width: 100%;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customTab .MuiTab-root {
  padding: 18px 12px !important;
}
.customTab .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
  color: #20a258;
}

.row {
  display: flex;
}

.hide {
  display: none;
}

.otpContainer.customOtpContainer .otpForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.otpContainer.customOtpContainer .otpForm .otpText {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
}
.otpContainer.customOtpContainer .otpForm input {
  background-color: transparent !important;
  border: 1px solid rgba(193, 193, 193, 0.368627451);
  border-radius: 8px;
  margin-right: 15px;
  outline: 0;
  height: 45px;
  width: 45px !important;
  color: #757575;
}
.otpContainer.customOtpContainer .otpForm input.disabledInput {
  cursor: not-allowed;
  opacity: 0.8;
}
.otpContainer.customOtpContainer .otpForm input.errorInput {
  border-color: red;
}

.customSwitch .switchLabel {
  white-space: nowrap;
  font-size: 15px;
}
.customSwitch .MuiSwitch-input {
  display: block;
}
.customSwitch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1;
}
.customSwitch .MuiSwitch-track {
  background-color: #20a258;
}
.customSwitch .MuiSwitch-track .Mui-checked {
  background-color: #20a258;
}
.customSwitch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #20a258;
}

.farmDetailsTabPanel {
  background-color: #ffffff;
}
.farmDetailsTabPanel .restContactNumber label {
  font-weight: 600;
}
.farmDetailsTabPanel .restContactNumber .customTextAreafield textarea {
  border: 1px solid rgba(193, 193, 193, 0.368627451);
  color: #394053;
  font-weight: 100;
  font-size: 14px;
}
.farmDetailsTabPanel .restContactNumber .customTextAreafield textarea::-moz-placeholder {
  color: #adb0b8;
  font-weight: 100;
}
.farmDetailsTabPanel .restContactNumber .customTextAreafield textarea:-ms-input-placeholder {
  color: #adb0b8;
  font-weight: 100;
}
.farmDetailsTabPanel .restContactNumber .customTextAreafield textarea::placeholder {
  color: #adb0b8;
  font-weight: 100;
}
.farmDetailsTabPanel .contactRestInput {
  position: relative;
}
.farmDetailsTabPanel .contactRestInput .errorMsg {
  position: absolute;
  margin-top: 3px;
}
.farmDetailsTabPanel .regInfoContainer {
  padding: 30px;
}
@media screen and (max-width: 767px) {
  .farmDetailsTabPanel .regInfoContainer {
    padding: 10px;
  }
}
@media screen and (max-width: 560px) {
  .farmDetailsTabPanel .regInfoContainer {
    padding: 0px 20px 20px 20px;
  }
}
.farmDetailsTabPanel .regInfoContainer .greenContained .Mui-disabled {
  cursor: not-allowed;
  pointer-events: auto;
  background-color: #20a258 !important;
  opacity: 0.7;
}
.farmDetailsTabPanel .regInfoContainer .greenContained .Mui-disabled span {
  color: #ffffff !important;
}
@media screen and (max-width: 470px) {
  .farmDetailsTabPanel .regInfoContainer .farmSaveButton .MuiButton-root {
    padding: 2px !important;
  }
}
@media screen and (max-width: 767px) {
  .farmDetailsTabPanel .regInfoContainer .ordinateHeader {
    border: 0 !important;
    line-height: initial !important;
  }
}
.farmDetailsTabPanel .regInfoContainer .ordinateHeader span {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 767px) {
  .farmDetailsTabPanel .regInfoContainer .ordinateHeader span {
    font-size: 15px;
  }
}
.farmDetailsTabPanel .regInfoContainer .MuiButton-root {
  padding: 8px 30px !important;
}
@media screen and (max-width: 767px) {
  .farmDetailsTabPanel .regInfoContainer .MuiButton-root {
    width: 100%;
  }
}
.farmDetailsTabPanel .MuiBox-root {
  padding: 0;
}
.farmDetailsTabPanel .restContactNumber h6 {
  color: #394053 !important;
}
.farmDetailsTabPanel .headerFarm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(193, 193, 193, 0.368627451);
  padding: 20px;
}
.farmDetailsTabPanel .headerFarm h4 {
  font-size: 22px;
}
.farmDetailsTabPanel .headerFarm span {
  font-size: 17px;
  padding-left: 0;
  color: #ec9e31;
}
@media screen and (max-width: 767px) {
  .farmDetailsTabPanel .headerFarm span {
    font-size: 14px;
  }
}
.farmDetailsTabPanel .restContactNumber p, .farmDetailsTabPanel .ownerDetailsContainer p {
  color: #464a53;
  font-family: "Poppins", sans-serif;
  font-size: 15px !important;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 600;
}
.farmDetailsTabPanel .phoneDetails {
  width: 100%;
  display: flex;
  align-items: center !important;
}
.farmDetailsTabPanel .phoneDetails .phoneDetailsRightBlock {
  align-items: center !important;
}
.farmDetailsTabPanel .phoneDetails .phoneDetailsRightBlock .otpContainer {
  width: auto !important;
}
.farmDetailsTabPanel .phoneDetails .phoneDetailsBlock {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 20px !important;
  grid-gap: 20px !important;
  gap: 20px !important;
}
.farmDetailsTabPanel .phoneDetails .phoneDetailsBlock .grayBackGroundButton {
  display: flex;
  align-items: center;
  width: 50%;
}
.farmDetailsTabPanel hr {
  background-color: #394053;
}
.farmDetailsTabPanel .bannerImgContainer {
  display: flex;
  flex-direction: column;
}
.farmDetailsTabPanel .bannerImgContainer .uploadSubtitle {
  font-family: "Poppins", sans-serif;
  color: #464a53;
  font-weight: 400;
}
.farmDetailsTabPanel .bannerImgContainer .fileUploadContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}
.farmDetailsTabPanel .bannerImgContainer .fileUploadContainer.pdfFileUploadContainer {
  margin-top: 10px;
}
.farmDetailsTabPanel .bannerImgContainer .btnContainer {
  margin-bottom: 0;
  padding: 0;
}
.farmDetailsTabPanel .bannerImgContainer h6 {
  color: #20a258;
  font-size: 22px;
  font-weight: bold;
}
.farmDetailsTabPanel .bannerImgContainer .customFileUpload {
  max-width: 150px;
  padding: 10px 20px;
  height: 125px;
}
.farmDetailsTabPanel .bannerImgContainer .fileUploadLabel span {
  white-space: nowrap;
}
.farmDetailsTabPanel .bannerImgContainer .FileUpload:nth-child(1), .farmDetailsTabPanel .bannerImgContainer .headerImageBlock:nth-child(1) {
  margin-left: 0;
}
.farmDetailsTabPanel .bannerImgContainer .headerImage, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  word-break: break-all;
}
@media screen and (max-width: 560px) {
  .farmDetailsTabPanel .bannerImgContainer .headerImage, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock {
    margin-top: 10px;
  }
}
.farmDetailsTabPanel .bannerImgContainer .headerImage.farmHeaderImage, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock.farmHeaderImage {
  margin-right: 10px;
}
.farmDetailsTabPanel .bannerImgContainer .headerImage .correctIconInput:checked ~ .correctIconLabel, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock .correctIconInput:checked ~ .correctIconLabel {
  opacity: 0;
}
.farmDetailsTabPanel .bannerImgContainer .headerImage .correctIconLabel, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock .correctIconLabel {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 15px;
}
.farmDetailsTabPanel .bannerImgContainer .headerImage .correct_icon, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock .correct_icon {
  width: 25px;
  height: 25px;
  -o-object-fit: contain;
     object-fit: contain;
}
.farmDetailsTabPanel .bannerImgContainer .headerImage h6, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock h6 {
  position: absolute;
  bottom: -25px;
  font-size: 13px;
  color: #394053;
  opacity: 0.8;
}
.farmDetailsTabPanel .bannerImgContainer .headerImage.headerPdfFileBlock h6, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock.headerPdfFileBlock h6 {
  bottom: 5px;
  left: 5px;
  font-weight: bold;
}
.farmDetailsTabPanel .bannerImgContainer .headerImage .newUploadFarmImage, .farmDetailsTabPanel .bannerImgContainer .headerImageBlock .newUploadFarmImage {
  justify-content: normal !important;
  align-items: normal !important;
  word-break: normal !important;
  overflow: unset !important;
  margin-top: 10px;
}
@media screen and (max-width: 560px) {
  .farmDetailsTabPanel .bannerImgContainer .newUploadFarm2 {
    margin-top: -9px;
  }
}
.farmDetailsTabPanel .bannerImgContainer .newUploadFarm {
  justify-content: normal;
  align-items: normal;
  word-break: normal;
  flex-wrap: wrap;
}
.farmDetailsTabPanel .bannerImgContainer .newUploadFarm::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.farmDetailsTabPanel .bannerImgContainer .newUploadFarm::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.farmDetailsTabPanel .bannerImgContainer .newUploadFarm::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
@media screen and (max-width: 400px) {
  .farmDetailsTabPanel .bannerImgContainer .newUploadFarm .farmImg {
    width: 120px;
    height: 110px;
  }
}
@media screen and (max-width: 350px) {
  .farmDetailsTabPanel .bannerImgContainer .newUploadFarm .farmImg {
    width: 100px;
    height: 100px;
  }
}
.farmDetailsTabPanel .bannerImgContainer .newUploadFarm .pdfFileBlock {
  justify-content: normal;
  align-items: normal;
  word-break: normal;
}
@media screen and (max-width: 400px) {
  .farmDetailsTabPanel .bannerImgContainer .newUploadFarmCustom .customFileUpload {
    width: 120px;
    height: 110px;
  }
}
@media screen and (max-width: 350px) {
  .farmDetailsTabPanel .bannerImgContainer .newUploadFarmCustom .customFileUpload {
    width: 100px;
    height: 100px;
  }
}
.farmDetailsTabPanel .bannerImgContainer .farmImg {
  width: 140px;
  height: 125px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock {
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .download_icon {
  position: absolute;
  top: 9px;
  right: 32px;
  border: 0;
  outline: 0;
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .download_icon img {
  height: 16px;
  width: 16px;
  -o-object-fit: contain;
     object-fit: contain;
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  z-index: 0 !important;
  cursor: pointer;
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .close_icon::before, .farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .close_icon::after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 22px;
  width: 4px;
  background-color: #ffffff;
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .close_icon::before {
  transform: rotate(45deg);
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .close_icon::after {
  transform: rotate(-45deg);
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .farmImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.farmDetailsTabPanel .bannerImgContainer .pdfFileBlock .pdf_icon {
  height: 50px;
}
.farmDetailsTabPanel .deliveryDetailsBlock h6 {
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}
.farmDetailsTabPanel .deliveryDetailsBlock p {
  color: #394053;
  font-size: 15px;
  padding: 20px 0;
}
.farmDetailsTabPanel .contactRow {
  margin-top: 40px;
  margin-bottom: -5px;
}
.farmDetailsTabPanel .restContactNumber .conactNumberForm {
  margin-top: 10px;
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px !important;
  gap: 20px !important;
}
.farmDetailsTabPanel .restContactNumber .conactNumberForm .greenContained {
  padding: 0px 0px;
}
.farmDetailsTabPanel .restContactNumber .conactNumberForm .greenContained .MuiButton-root {
  padding: 12px 20px !important;
}
.farmDetailsTabPanel .restContactNumber .customTextfield .countryCodeIcon {
  border-right: 1px solid #808080;
  width: 100%;
  padding-right: 20px;
}
.farmDetailsTabPanel .restContactNumber .customTextfield .countryCodeIcon p {
  font-size: 20px !important;
  font-weight: 700;
  color: #394053;
}
.farmDetailsTabPanel .ownerDetailsContainer .phoneDetails .customTextfield .countryCodeIcon {
  border-right: 1px solid #808080;
  width: 100%;
  padding-right: 20px;
}
.farmDetailsTabPanel .ownerDetailsContainer .phoneDetails .customTextfield .countryCodeIcon p {
  font-size: 20px !important;
  font-weight: 700;
  color: #394053;
}

.farmOwnerDetailsWrapper .countryCodeIcon {
  border-right: 1px solid #808080;
  width: 100%;
  padding-right: 20px;
}
.farmOwnerDetailsWrapper .countryCodeIcon p {
  font-size: 20px !important;
  font-weight: 700;
  color: #394053;
}

.customTextfield .textFieldMultiline {
  font-size: 14px !important;
}

.remove_icon::after,
.remove_icon::before {
  background-color: #000 !important;
  width: 2px !important;
}

.deliveryDetailBlocks {
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.deliveryDetailBlocks::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.deliveryDetailBlocks::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.deliveryDetailBlocks::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.deliveryDetailBlocks .deliveryDetailRow {
  display: flex;
  justify-content: flex-start;
  background-color: #f4f5f5;
  border-radius: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  padding: 0px 25px;
}
@media screen and (max-width: 1024px) {
  .deliveryDetailBlocks .deliveryDetailRow {
    padding: 10px 0;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlocks {
  padding: 10px;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlocks {
    padding: 0px 15px;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock1 {
  width: 42%;
}
@media screen and (max-width: 767px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlock1 {
    width: 100%;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock1 h6 {
  font-weight: 600;
  font-size: 17px;
  width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1024px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlock1 h6 {
    font-size: 14px;
    width: 100%;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock2 {
  width: 42%;
}
@media screen and (max-width: 767px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlock2 {
    width: 100%;
    padding: 10px 15px;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock2 h6 {
  font-weight: 400;
  font-size: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1024px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlock2 h6 {
    font-size: 14px;
    width: 100%;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock3 {
  width: 8%;
  border: none;
  background-color: transparent;
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock3:hover {
  background-color: transparent;
}
@media screen and (max-width: 767px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlock3 {
    width: 50%;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock3 a {
  color: red;
}
@media screen and (max-width: 768px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlock3 a {
    font-size: 15px;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock4 {
  width: 8%;
  border: none;
  background-color: transparent;
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock4:hover {
  background-color: transparent;
}
@media screen and (max-width: 767px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlock4 {
    width: 50%;
    text-align: right;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .deliveryBlock4 a {
  color: #20a258;
}
@media screen and (max-width: 768px) {
  .deliveryDetailBlocks .deliveryDetailRow .deliveryBlock4 a {
    font-size: 15px;
  }
}
.deliveryDetailBlocks .deliveryDetailRow .icons-btn {
  margin: 0 15px;
}
.deliveryDetailBlocks .deliveryDetailRow .icons-btn.MuiButton-root {
  padding: 0;
  min-width: 0;
}
.deliveryDetailBlocks .deliveryDetailRow .icons-btn img {
  height: 25px;
  width: 25px;
  -o-object-fit: contain;
     object-fit: contain;
}

.restaurant_edit_bank_select .MuiInputBase-root {
  width: 100%;
}
.restaurant_edit_bank_select .MuiInputBase-root .MuiSvgIcon-root {
  display: block;
}
.restaurant_edit_bank_select .MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  font-weight: 500;
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}
.restaurant_edit_bank_select .MuiInputBase-root svg {
  color: #000 !important;
}

.res_edit_green_contained .MuiButton-root .MuiButton-label span {
  font-size: 15px;
  font-weight: 800;
  padding: 5px 30px;
}
.res_edit_green_contained .MuiButton-root .plus_icon {
  display: none;
}

.res_edit_avail_wrapper {
  margin: 15px 0px 30px 0px;
}
.res_edit_avail_wrapper .MuiButton-root {
  border-radius: 30px;
}
.res_edit_avail_wrapper .MuiButton-root .MuiButton-label span {
  padding: 0px 30px;
  font-weight: 500 !important;
}

.farmImgHead {
  margin-bottom: 10px !important;
}

@media screen and (max-width: 560px) {
  .outletContainer {
    grid-column-gap: 40px;
    -moz-column-gap: 40px;
         column-gap: 40px;
    grid-row-gap: 10px;
    row-gap: 10px;
  }
}

.productVariety {
  margin-top: 25px;
}
@media screen and (min-width: 560px) {
  .productVariety .productVarietyGrid {
    margin-right: 25px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.custom_google_place_textfield {
  border: 1px solid rgba(193, 193, 193, 0.368627451) !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  padding: 16px !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  height: 50px !important;
}
.custom_google_place_textfield input {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.custom_google_place_textfield input::-moz-placeholder {
  color: #abafb3 !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
}
.custom_google_place_textfield input:-ms-input-placeholder {
  color: #abafb3 !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
}
.custom_google_place_textfield input::placeholder {
  color: #abafb3 !important;
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
}

.custom_google_place_detect {
  border: none;
  background-color: transparent;
  outline: none;
}
.custom_google_place_detect span {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.custom_google_place_detect span p {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #000;
}
.custom_google_place_detect span img {
  height: 20px;
  width: 20px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restDetailsContainer {
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
}
.restDetailsContainer h1,
.restDetailsContainer h2,
.restDetailsContainer h3,
.restDetailsContainer h4,
.restDetailsContainer h5,
.restDetailsContainer h6,
.restDetailsContainer p,
.restDetailsContainer label,
.restDetailsContainer legend {
  font-family: "Poppins", sans-serif;
}
.restDetailsContainer h6 {
  font-size: 20px;
  font-weight: 700;
  color: #808080;
}
.restDetailsContainer .restDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 35px;
  border-bottom: 0.5px solid #979797;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .restDetailsHeader {
    padding: 20px 10px;
  }
}
.restDetailsContainer .restDetailsHeader .saveBtn .plus_icon {
  display: none;
}
.restDetailsContainer .restDetailsHeader .saveBtn .MuiButton-label span {
  padding-left: 0;
}
.restDetailsContainer .restDetailsHeader .saveBtn .MuiButton-text:hover {
  background-color: white;
}
.restDetailsContainer .restDetailsHeader h4 {
  font-size: 24px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .restDetailsHeader h4 {
    font-size: 16px;
  }
}
.restDetailsContainer .restDetailsHeader .MuiButtonBase-root {
  background-color: #20a258;
  border-radius: 8px;
  padding: 10px 20px;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .restDetailsHeader .MuiButtonBase-root {
    padding: 5px 10px;
  }
}
.restDetailsContainer .restDetailsHeader .MuiButtonBase-root span {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .restDetailsHeader .MuiButtonBase-root span {
    font-size: 10px;
  }
}
.restDetailsContainer .restDetailsCardsContainer {
  margin-top: 30px;
  padding: 0 35px;
}
.restDetailsContainer .restDetailsCardsContainer .gridCells {
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .restDetailsCardsContainer {
    padding: 0px 10px;
  }
}
.restDetailsContainer .quoteTimeContainer {
  margin-top: 30px;
  padding: 0px 35px;
}
.restDetailsContainer .quoteTimeContainer h6 {
  color: #464a53;
  font-size: 22px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .quoteTimeContainer {
    padding: 0px 10px;
  }
}
.restDetailsContainer .quoteTimeContainer .quoteBorder {
  border-bottom: 0.5px solid #979797;
  padding-top: 5px;
}
.restDetailsContainer .quoteTimeContainer .quoteTime {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .quoteTimeContainer .quoteTime {
    flex-direction: column;
  }
}
.restDetailsContainer .quoteTimeContainer .quoteTime h6 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .quoteTimeContainer .quoteTime h6 {
    text-align: left;
    width: 100%;
  }
}
.restDetailsContainer .quoteTimeContainer .quoteTime .timingSelectContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .quoteTimeContainer .quoteTime .timingSelectContainer {
    margin: 10px 0px;
    flex-direction: column;
    width: 100%;
  }
}
.restDetailsContainer .quoteTimeContainer .quoteTime .timingSelectContainer .outlineSelect {
  width: 200px;
  margin: 0 10px;
}
.restDetailsContainer .quoteTimeContainer .quoteTime .timingSelectContainer .outlineSelect .customSelect .MuiSelect-select.MuiSelect-select {
  font-size: 22px;
  color: #000;
}
.restDetailsContainer .quoteTimeContainer .quoteTime .timingSelectContainer .outlineSelect .customSelect.dishListStatus.timeAlign {
  position: relative;
}
.restDetailsContainer .quoteTimeContainer .quoteTime .timingSelectContainer .outlineSelect .customSelect.dishListStatus.timeAlign .timeError {
  position: absolute;
  top: 55px;
  right: 0;
}
.restDetailsContainer .quoteTimeContainer .quoteTime .timingSelectContainer .outlineSelect .MuiTypography-colorTextSecondary {
  color: rgb(0, 0, 0);
  font-size: 22px;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .quoteTimeContainer .quoteTime .timingSelectContainer .outlineSelect {
    width: 100%;
    margin: 10px 0px;
  }
}
.restDetailsContainer .orderType {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0;
  padding: 0px 35px;
}
.restDetailsContainer .orderType h6 {
  font-size: 22px;
  font-weight: 600;
  color: #464a53;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderType {
    padding: 0px 10px;
  }
}
.restDetailsContainer .orderVolumeCOntainer {
  padding: 20px 35px;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderVolumeCOntainer {
    padding: 20px 10px;
  }
}
.restDetailsContainer .orderVolumeCOntainer h6 {
  color: #464a53;
  font-size: 22px;
  font-weight: 600;
}
.restDetailsContainer .orderVolumeCOntainer .header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #808080;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderVolumeCOntainer .header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.restDetailsContainer .orderVolumeCOntainer .header span:nth-child(1) {
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
.restDetailsContainer .orderVolumeCOntainer .header span:nth-child(2) {
  width: 78px;
  margin: 0 20px;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderVolumeCOntainer .header span:nth-child(2) {
    width: 100%;
    margin: 10px 0px;
  }
}
.restDetailsContainer .orderVolumeCOntainer .header span:nth-child(3) {
  color: #000;
  font-size: 22px;
  font-weight: 400;
}
.restDetailsContainer .orderVolumeCOntainer .header span:nth-child(4) {
  margin-left: 20px;
}
.restDetailsContainer .orderVolumeCOntainer .header span .MuiInputBase-input.MuiInput-input {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
}
.restDetailsContainer .orderDetailsContainer {
  padding: 20px 35px;
}
.restDetailsContainer .orderDetailsContainer h6 {
  font-size: 22px;
  font-weight: 600;
  color: #464a53;
}
.restDetailsContainer .orderDetailsContainer .greenOutlineButton label {
  font-size: 20px;
  font-weight: 600;
  color: #20a258;
}
.restDetailsContainer .orderDetailsContainer .orderPriceText {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #464a53;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderDetailsContainer {
    padding: 20px 10px;
  }
}
.restDetailsContainer .orderDetailsContainer p {
  font-size: 15px;
  margin: 10px 0;
  color: #000;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderDetailsContainer p {
    font-size: 18px;
  }
}
.restDetailsContainer .orderDetailsContainer .orderSlot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #808080;
  width: 100%;
}
.restDetailsContainer .orderDetailsContainer .orderSlot .orderText {
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100% !important;
}
.restDetailsContainer .orderDetailsContainer .orderSlot .MuiFormControl-root.MuiTextField-root {
  height: 53px;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span {
  margin: 0 10px;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderDetailsContainer .orderSlot span {
    margin: 10px 0;
  }
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(1) {
  color: #000;
  font-weight: 600;
  margin: 0;
  width: 33%;
  font-size: 18px;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(2) {
  width: 17%;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(2) .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(2) {
    width: 100%;
  }
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(3) {
  color: #000;
  font-size: 20px;
  font-weight: 400;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(4) {
  color: #000;
  width: 15%;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(4) .customSelect .MuiSelect-select.MuiSelect-select {
  font-size: 22px;
  color: #000;
  font-family: "Poppins", sans-serif;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(4) .MuiTypography-colorTextSecondary {
  margin-left: 20px;
  color: rgb(0, 0, 0);
  font-size: 22px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(4) {
    width: 100%;
  }
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(5) {
  color: #000;
  width: 15%;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(5) .customSelect .MuiSelect-select.MuiSelect-select {
  font-size: 22px;
  color: #000;
  font-family: "Poppins", sans-serif;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(5) .MuiTypography-colorTextSecondary {
  color: rgb(0, 0, 0);
  font-size: 22px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 768px) {
  .restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(5) {
    width: 100%;
  }
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(6) {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  width: 40%;
}
.restDetailsContainer .orderDetailsContainer .orderSlot span:nth-child(7) {
  margin-right: 10px;
}

.restAccount {
  font-family: "Poppins", sans-serif;
}
.restAccount h6 {
  font-size: 18px;
  color: #464a53;
}

.availSettingHeader {
  width: 120px;
  text-align: left;
  color: #949494;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  height: 100%;
}

.availSetting {
  width: 120px;
  text-align: left;
  color: #464a53;
  font-family: "Poppins", sans-serif;
}
.availSetting label {
  color: #949494 !important;
}

.customAvailCheckbox .MuiSvgIcon-root {
  height: 1.2em;
  width: 1.2em;
}
.customAvailCheckbox .Mui-checked .MuiSvgIcon-root {
  color: #20a258;
}
.customAvailCheckbox .MuiTypography-body1 {
  font-family: "Poppins", sans-serif;
  color: #949494;
}

.customTimeCheckbox .MuiSvgIcon-root {
  height: 1.2em;
  width: 1.2em;
}
.customTimeCheckbox .Mui-checked .MuiSvgIcon-root {
  color: #20a258;
}
.customTimeCheckbox .MuiTypography-body1 {
  color: #464a53;
  font-family: "Poppins", sans-serif;
}

.MuiTypography-root.availSetting.start.MuiTypography-body1 {
  margin-right: 15px;
}

.MuiTypography-root.availSetting.end.MuiTypography-body1 {
  margin-right: 5px;
}

.orderVol {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.availWrapperOne {
  border-bottom: 2px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.availWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.MuiGrid-root.availContainer.MuiGrid-container {
  width: 550px;
  margin: 30px 0 100px 40px;
  background-color: rgb(241, 241, 241);
}

.hyphen {
  margin: 0 0 25px 0;
  width: 10px;
  border-bottom: 2px solid #000;
}

.deliverySettIcon {
  width: 50px;
  height: 50px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@media screen and (max-width: 1024px) {
  .cardContainer.MuiGrid-spacing-xs-5 {
    margin: 0 -20px;
    padding-top: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer.MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 2px 22px;
  }
}
.cardContainer .card {
  width: 100%;
}
.cardContainer .card.MuiCard-root {
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 1600px) {
  .cardContainer .card.MuiCard-root {
    padding: 15px;
  }
}
.cardContainer .card.MuiCard-root .cardContent {
  padding: 0;
}
.cardContainer .card.MuiCard-root .cardContent .h6 {
  color: #c1c1c1;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
  padding-bottom: 5px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root .cardContent .h6 {
    font-size: 13px;
  }
}
.cardContainer .card.MuiCard-root .priceRow {
  display: flex;
  align-items: center;
}
.cardContainer .card.MuiCard-root .priceRow .h1 {
  color: #394053;
  font-size: 30px;
  font-weight: 600;
  padding-right: 10px;
}
@media screen and (max-width: 1600px) {
  .cardContainer .card.MuiCard-root .priceRow .h1 {
    font-size: 19px;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root .priceRow .h1 {
    font-size: 17px;
  }
}
.cardContainer .card.MuiCard-root .iconCircle {
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(103, 103, 177);
}
.cardContainer .card.restDetailsCard {
  cursor: pointer;
}
.cardContainer .card.restDetailsCard.MuiCard-root {
  background-color: #ffffff;
  padding: 15px 25px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  border: 1px solid #c1c1c1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-evenly;
}
.cardContainer .card.restDetailsCard.MuiCard-root.activeRestDetailCard {
  background-color: #edf8f2;
  border: 1px solid #59bd83;
}
.cardContainer .card.restDetailsCard .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cardContainer .card.restDetailsCard .header .MuiSvgIcon-root {
  color: #59bd83;
  font-size: 40px;
}
.cardContainer .card.restDetailsCard .header .h6 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-left: 20px;
  width: 100%;
  line-height: 1.1;
  text-align: unset;
}
.cardContainer .card.restDetailsCard .description {
  margin-top: 10px;
}
.cardContainer .card.restDetailsCard .description p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.cardContainer .card.choseCard {
  position: relative;
}
.cardContainer .card.choseCard.MuiCard-root {
  background-color: #ffffff;
  padding: 50px 0 0 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px #c1c1c1;
}
.cardContainer .card.courseCard {
  flex-direction: column;
  padding: 15px;
  height: 100%;
  box-shadow: 0px 16px 20px 0px rgba(204, 204, 204, 0.5803921569);
}
.cardContainer .card.courseCard .MuiCardMedia-img {
  border-radius: 15px;
}
.cardContainer .card.courseCard .cardContent {
  width: 100%;
  padding-top: 20px;
}
.cardContainer .card.courseCard .cardContent p {
  font-weight: 400;
  color: #c1c1c1;
  padding-top: 10px;
  word-spacing: 2px;
  line-height: 25px;
  font-size: 17px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.courseCard .cardContent p {
    font-size: 14px;
  }
}
.cardContainer .card.courseCard .CardActions {
  width: 100%;
  padding: 20px 0;
}
.cardContainer .card.purchaseCard {
  margin-bottom: 0;
  flex-direction: column !important;
  padding: 15px;
  box-shadow: 0px 16px 20px 0px rgba(204, 204, 204, 0.5803921569) !important;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.purchaseCard {
    margin-bottom: 30px;
  }
}
.cardContainer .card.purchaseCard .imgContainer {
  width: 100%;
  position: relative;
}
.cardContainer .card.purchaseCard .imgContainer .rating {
  position: absolute;
  top: 10px;
  left: 10px;
}
.cardContainer .card.purchaseCard .imgContainer .rating .MuiRating-iconEmpty {
  color: white;
}
.cardContainer .card.purchaseCard .courseImg {
  border-radius: 15px;
}
.cardContainer .card.purchaseCard .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.purchaseCard .MuiTypography-h5 {
  font-size: 20px;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .MuiTypography-h5 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard h4 {
    font-size: 16px;
  }
}
.cardContainer .card.purchaseCard .cardContent {
  padding: 20px 20px 0 20px !important;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .cardContent {
    padding: 5px 5px 0 5px !important;
  }
}
.cardContainer .card.purchaseCard .cardContent .MuiTypography-body2 {
  color: #c3c3c3;
  margin: 4px 0;
}
.cardContainer .card.purchaseCard .CardActions {
  justify-content: space-between;
  width: 100%;
  padding: 20px !important;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .CardActions {
    padding: 5px 5px 0 5px !important;
  }
}
.cardContainer .card.receiptCard {
  margin-bottom: 0;
  flex-direction: column !important;
  padding: 0px;
  box-shadow: 0px 0px 10px 0px #c3c3c3;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard {
    margin-bottom: 30px;
  }
}
.cardContainer .card.receiptCard .courseImg {
  border-radius: 15px 0 0 0;
}
.cardContainer .card.receiptCard .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.receiptCard .MuiTypography-h4 {
  font-size: 28px;
  color: #59bd83;
  font-weight: 600;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .MuiTypography-h4 {
    font-size: 16px;
  }
}
.cardContainer .card.receiptCard .cardContent {
  padding: 20px 20px 0 20px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .cardContent {
    padding: 20px !important;
  }
}
.cardContainer .card.receiptCard .cardContent .receiptDate {
  position: absolute;
  top: -40px;
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
  font-size: 18px;
  font-weight: 600;
}
.cardContainer .card.receiptCard .CardActions {
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 40px 20px !important;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions {
    padding: 20px !important;
  }
}
.cardContainer .card.receiptCard .CardActions .actionRow {
  display: flex;
  align-items: center;
  margin-left: 0;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions .actionRow {
    margin-bottom: 10px;
  }
}
.cardContainer .card.receiptCard .CardActions .actionRow h5 {
  font-weight: 600;
  padding: 0 5px;
}
.cardContainer .card.receiptCard .CardActions .actionRow .actionIcons {
  height: 30px;
  width: 30px;
}
.cardContainer .card.reviewCard {
  padding: 20px;
  border-radius: 15px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #e2dada;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .cardContainer .card.reviewCard {
    flex-direction: column;
    align-items: center;
  }
}
.cardContainer .card.reviewCard .profileImg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid #515156;
}
.cardContainer .card.reviewCard .cardContent {
  flex-direction: column;
  width: 100%;
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  .cardContainer .card.reviewCard .cardContent {
    padding-left: 0;
    padding-top: 20px;
  }
}
.cardContainer .card.reviewCard .cardContent .topRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cardContainer .card.reviewCard .cardContent .topRow h5 {
  font-weight: bold;
}
.cardContainer .card.reviewCard .cardContent .ratingRow {
  margin: 15px 0;
  display: flex;
}
.cardContainer .card.reviewCard .cardContent .ratingRow .MuiRating-icon {
  font-size: 30px;
}
.cardContainer .card.reviewCard .cardContent .ratingRow .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.reviewCard .cardContent p {
  font-size: 17px;
}
.cardContainer .card.studentCard {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 1px 1px 9px #c1c1c1;
  padding: 30px;
  margin-bottom: 0;
}
.cardContainer .card.studentCard .MuiCardHeader-root {
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span {
  font-family: "AllRoundGothicW01-Medium";
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span:nth-child(1) {
  color: #394053;
  font-weight: 600;
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span:nth-child(2) {
  color: #808080;
}
.cardContainer .card.studentCard .MuiCardContent-root {
  padding: 20px 0 0 0;
}
.cardContainer .card.studentCard .MuiCardContent-root p {
  color: #808080;
}
.cardContainer .card.coursePlayCard {
  box-shadow: 1px 1px 20px 0px rgba(204, 204, 204, 0.5803921569);
  padding: 15px 15px 150px 15px;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .cardContainer .card.coursePlayCard {
    border: 0;
    box-shadow: none;
    padding: 0;
  }
}

.educatorCard.MuiCard-root {
  width: 100%;
  border-radius: 8px 8px 0 0;
}
.educatorCard.MuiCard-root .MuiCardMedia-media {
  height: 300px;
}
.educatorCard.MuiCard-root .cardContent {
  background-color: #ffffff;
  margin-top: -20px;
  border-radius: 8px 8px 0 0;
}
.educatorCard.MuiCard-root .cardContent .MuiTypography-h5 {
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  word-spacing: 2px;
  letter-spacing: 2px;
}
.educatorCard.MuiCard-root .cardContent .MuiTypography-body2 {
  text-align: center;
  font-size: 13px;
}
.res_timing_wrapper {
  margin: 15px 15px 20px 15px !important;
  border-radius: 13px;
  background-color: #f1f1f1;
}

.availSettingHeaderWrapper {
  border-bottom: 2px solid #949494;
  display: flex;
  justify-content: space-between;
  padding: 20px !important;
}

.availSettingContentWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px !important;
}

.availSettingCustomTime .MuiFormControl-root {
  background-color: #fff;
  border-radius: 7px;
  border: solid 1px #abafb3;
}
@media screen and (max-width: 560px) {
  .availSettingCustomTime .MuiInputBase-input {
    font-size: 12px;
  }
}

.availSettingRemove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.availSettingRemove svg {
  color: #464a53;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.adminRegRestaurantWrapper .bannerCard .MuiCard-root {
  border-radius: 20px 20px 0 0;
}
.adminRegRestaurantWrapper .bannerCard .bannerHeadCard {
  background-image: url(/static/media/bannerImg.32cdbd54.jpg);
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 400px;
  padding: 70px;
}
@media screen and (max-width: 767px) {
  .adminRegRestaurantWrapper .bannerCard .bannerHeadCard {
    padding: 20px;
  }
}
.adminRegRestaurantWrapper .bannerCard .bannerHeadCard h1 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .adminRegRestaurantWrapper .bannerCard .bannerHeadCard h1 {
    font-size: 20px;
  }
}
.adminRegRestaurantWrapper .bannerCard .CardContent {
  text-align: center;
  padding: 70px 10px 50px 10px;
}
.adminRegRestaurantWrapper .bannerCard .CardContent p {
  font-size: 16px;
  color: #394053;
}
@media screen and (max-width: 767px) {
  .adminRegRestaurantWrapper .bannerCard .CardContent p {
    font-size: 14px;
  }
}
.adminRegRestaurantWrapper .bannerCard .CardContent h4 {
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .adminRegRestaurantWrapper .bannerCard .CardContent h4 {
    font-size: 22px;
  }
}
.adminRegRestaurantWrapper .workCard {
  position: relative;
}
.adminRegRestaurantWrapper .workCard .MuiCard-root {
  border-radius: 0 0 20px 20px;
}
.adminRegRestaurantWrapper .workCard .bannerHeadCard {
  background-image: url(/static/media/bannerImg.32cdbd54.jpg);
  background-size: cover;
  background-position: top center;
  width: 100%;
  height: 300px;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .adminRegRestaurantWrapper .workCard .bannerHeadCard {
    height: 100vh;
  }
}
.adminRegRestaurantWrapper .workCard .bannerHeadCard::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(173, 232, 241, 0.8);
  z-index: -1;
}
.adminRegRestaurantWrapper .workCard .bannerHeadCard .HeadCardContent {
  z-index: 3;
}
.adminRegRestaurantWrapper .workCard .bannerHeadCard .HeadCardContent h5 {
  color: #1e1e1e;
  font-weight: 600;
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
  margin: 0;
  font-size: 1.8rem;
}
.adminRegRestaurantWrapper .workCard .bannerHeadCard .MuiTypography-body2 {
  font-size: 17px;
}
.adminRegRestaurantWrapper .workCard .bannerBottomCard {
  padding: 100px;
  height: 300px;
  background-color: #ffffff;
}
@media screen and (max-width: 767px) {
  .adminRegRestaurantWrapper .workCard .bannerBottomCard {
    height: 90vh;
  }
}
.adminRegRestaurantWrapper .workCard .stepsMainCardContent {
  position: absolute;
  top: 150px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 4;
}
@media screen and (max-width: 767px) {
  .adminRegRestaurantWrapper .workCard .stepsMainCardContent {
    flex-direction: column;
    top: 100px;
  }
}
.adminRegRestaurantWrapper .workCard .stepsMainCardContent .stepsCard {
  margin: 0 20px 30px 20px;
  padding: 30px 20px;
  background-color: #ffffff;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .adminRegRestaurantWrapper .workCard .stepsMainCardContent .stepsCard {
    margin: 0 5px 30px 5px;
  }
}
.adminRegRestaurantWrapper .workCard .stepsMainCardContent .stepsCard .icons {
  margin-bottom: 30px;
}
.adminRegRestaurantWrapper .workCard .stepsMainCardContent .stepsCard h6 {
  color: #1e1e1e;
  font-weight: 600;
  font-size: 19px;
  margin: 0;
}
.adminRegRestaurantWrapper .workCard .stepsMainCardContent .stepsCard p {
  color: #394053;
  font-weight: 500;
  padding-top: 30px;
  margin: 0;
}
.adminRegRestaurantWrapper .workCard .MuiTypography-body2 {
  font-size: 17px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.orderDialogTitle {
  border-bottom: 1px solid #979797;
}
.orderDialogTitle h4 {
  text-align: center;
  color: #5a5a5a;
  font-weight: 600;
}

.subModalBlock {
  background-color: #ffffff;
  border-radius: 20px;
}
.subModalBlock .adminOrderModalContent {
  padding: 25px;
}
.subModalBlock .adminOrderModalContent .orderActionWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 10px;
}
.subModalBlock .adminOrderModalContent .orderStatusDropdown {
  width: 165px;
  height: 35px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #5a5a5a;
  font-weight: 500;
  background-color: #f0f0f0;
  border-radius: 4px !important;
}
.subModalBlock .adminOrderModalContent .orderStatusDropdown > div {
  border-radius: 4px;
}
.subModalBlock .adminOrderModalContent .orderStatusDropdown .MuiSelect-select:focus {
  min-height: auto;
}
.subModalBlock .adminOrderModalContent .orderStatusDropdown .MuiSelect-filled.MuiSelect-filled {
  padding: 8px 10px;
}
.subModalBlock .adminOrderModalContent .orderStatusDropdown svg {
  transition: transform 0.2s;
}
.subModalBlock .adminOrderModalContent .orderStatusButton {
  background-color: #e0e0e0;
  padding: 4px 10px;
  min-width: 130px;
  height: 35px;
  text-transform: none;
}
.subModalBlock .adminOrderModalContent .orderStatusButton span {
  color: #5a5a5a;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.subModalBlock .adminOrderModalContent .orderPrintLabel {
  height: 34px;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 4px 20px;
  border: 1px solid #359843;
  color: #359843;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.subModalBlock .adminOrderModalContent .trackingContainer h6 {
  font-size: 22px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #5a5a5a;
}
.subModalBlock .adminOrderModalContent .trackingContainer p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #5a5a5a;
}
.subModalBlock .adminOrderModalContent .farmOrderCard {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16);
}
.subModalBlock .adminOrderModalContent .farmOrderNotesWrapper h6 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
}
.subModalBlock .adminOrderModalContent .farmOrderNotesWrapper textarea {
  resize: none;
  height: 120px;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  background-color: #f0f0f0;
  font-size: 13px;
  border: 1px solid #d3d3d3;
  outline: none;
}
.subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderTrackingStatus {
  color: #d97e17;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderDeliverText {
  color: #5a5a5a;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}
.subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserDetails {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 10px;
}
.subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserDetails .orderUserAvatar {
  width: 65px;
  height: 65px;
}
.subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserInfoDetails p {
  color: #5a5a5a;
  font-family: "Poppins", sans-serif;
  word-wrap: break-word;
}
.subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserInfoDetails p:nth-child(1) {
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  margin-bottom: 2px;
}
.subModalBlock .adminOrderModalContent .farmOrderTrackingWrapper .orderUserInfoDetails p:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
}
.subModalBlock .adminOrderModalContent .priceSection {
  margin-top: -65px;
}
@media screen and (max-width: 768px) {
  .subModalBlock .adminOrderModalContent .priceSection {
    margin-top: 0;
  }
}
.subModalBlock .adminOrderModalContent h3 {
  padding-left: 20px;
  font-family: "AllRoundGothicW01-Medium";
  font-weight: 100;
}
.subModalBlock .adminOrderModalContent .contentHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.subModalBlock .adminOrderModalContent .contentHeader .farmProfile {
  height: 60px;
  width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.subModalBlock .adminOrderModalContent .farmCard {
  margin-bottom: 20px;
}
.subModalBlock .adminOrderModalContent .farmCard h3 {
  padding-left: 0;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}
.subModalBlock .adminOrderModalContent .farmCard .farmCardContent {
  display: flex;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  .subModalBlock .adminOrderModalContent .farmCard .farmCardContent {
    flex-direction: column;
  }
}
.subModalBlock .adminOrderModalContent .farmCard .farmCardContent .imgBlock {
  border-radius: 8px;
}
.subModalBlock .adminOrderModalContent .farmCard .farmCardContent .imgBlock .farmSubProfile {
  height: 180px;
  width: 180px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}
@media screen and (max-width: 768px) {
  .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .imgBlock {
    margin-bottom: 10px;
  }
}
.subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock {
  padding-left: 20px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock {
    padding-left: 0;
  }
}
.subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock p {
  width: 85%;
  font-size: 14px;
  line-height: 1.5;
  color: #5a5a5a;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 768px) {
  .subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock p {
    width: 100%;
  }
}
.subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock h5 {
  margin: 8px 0;
  color: #20a258;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 17px;
}
.subModalBlock .adminOrderModalContent .farmCard .farmCardContent .rightBlock h5 span {
  color: #20a258;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.subModalBlock .adminOrderModalContent .priceCard {
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 768px) {
  .subModalBlock .adminOrderModalContent .priceCard {
    margin-bottom: 20px;
  }
}
.subModalBlock .adminOrderModalContent .priceCard .cardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.subModalBlock .adminOrderModalContent .priceCard .cardRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.subModalBlock .adminOrderModalContent .priceCard .cardRow h6 {
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
.subModalBlock .adminOrderModalContent .priceCard .cardTotalRow h6 {
  font-weight: 600 !important;
  font-size: 18px;
  color: #000;
}
.subModalBlock .adminOrderModalContent .greenFilledButton .MuiButton-root {
  width: 100%;
}

.farm-order-menu {
  padding: 0 !important;
}
.farm-order-menu li {
  padding: 10px 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.farm-order-menu hr {
  margin: 0;
  color: #979797;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restOrderList {
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
}
.restOrderList .tableContainer {
  margin-bottom: 0;
}
.restOrderList.farmOrderList .outlineTextfield {
  width: 100% !important;
}
.restOrderList .tabContainer .customTab .tableContainer {
  margin-top: 20px;
}

.dishSorting {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.custom_center_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.toolBarContainer {
  border-radius: 10px;
  margin: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
}
.toolBarContainer span {
  color: unset;
  font-family: "Poppins", sans-serif;
}
.toolBarContainer .toolBarCOntainer {
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0 0;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(172, 168, 168);
  padding: 10px 10px;
  background-color: #f9f8fd;
  grid-gap: 10px;
  gap: 10px;
}
.toolBarContainer .toolBarCOntainer button {
  border: none;
  background-color: transparent;
}
.toolBarContainer .DraftEditor-editorContainer {
  min-height: 100px;
}
.toolBarContainer .DraftEditor-editorContainer .public-DraftEditor-content {
  min-height: 100px;
  overflow: auto;
  padding: 10px;
  display: flex;
}
.toolBarContainer .DraftEditor-editorContainer .public-DraftEditor-content div {
  display: flex;
}
.toolBarContainer .DraftEditor-editorContainer .public-DraftEditor-content div figure {
  margin: 0 10px;
  position: relative;
}
.toolBarContainer .DraftEditor-editorContainer .public-DraftEditor-content div figure img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.imgUploadContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
}
.imgUploadContainer .chipContainer {
  padding: 0;
  margin: 0;
}
.imgUploadContainer .MuiSvgIcon-root {
  transform: rotate(45deg);
}
.imgUploadContainer .imgContainer {
  position: relative;
  border-radius: 5px;
}
.imgUploadContainer .imgContainer img {
  width: 150px;
  height: 100px;
  padding: 5px;
  border-radius: 5px;
}
.imgUploadContainer .imgContainer .closeIcon {
  background-color: #dddddd;
  padding: 0;
  border-radius: 50%;
  transform: rotate(90deg);
  cursor: pointer;
}
.imgUploadContainer .imgContainer .closeIcon .MuiSvgIcon-root {
  transform: rotate(0deg);
}

.RichEditor-editor .public-DraftEditorPlaceholder-root {
  margin: left 10px;
  margin-top: 10px;
  padding: 15px;
  color: #e3e3e5;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.taskEditor .ql-toolbar {
  border: none !important;
  padding: 13px !important;
}
.taskEditor .quill {
  height: 150px;
}
.taskEditor .quill .ql-container {
  border: none;
  border-top: 1px solid #ccc;
}
.taskEditor .ql-blank::before {
  color: #e3e3e5 !important;
  font-style: normal !important;
  left: 15px !important;
  font-size: 16px !important;
  top: 19px;
  font-family: "Montserrat", sans-serif;
}
.taskEditor .ql-editor p {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  margin-top: 12px !important;
  line-height: 1.4 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.regStepperContainer {
  background-color: #ffffff;
  border-radius: 15px;
  font-family: "AllRoundGothicW01-Medium";
}
.regStepperContainer h1,
.regStepperContainer h2,
.regStepperContainer h3,
.regStepperContainer h4,
.regStepperContainer h5,
.regStepperContainer h6 {
  font-family: "Poppins", sans-serif;
}
.regStepperContainer h4 {
  font-weight: 700;
}
.regStepperContainer p {
  color: #bababa;
}
.regStepperContainer .regStepperHeader {
  display: flex;
  align-items: center;
  justify-self: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px 20px 20px 20px;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regStepperHeader {
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    grid-gap: 20px;
    gap: 20px;
  }
}
.regStepperContainer .regStepperHeader .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: flex-start;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regStepperHeader .header {
    justify-content: center;
  }
}
.regStepperContainer .regStepperHeader .header .MuiSvgIcon-root {
  font-size: 30px;
}
.regStepperContainer .regStepperHeader .header h4 {
  font-weight: 900;
  margin-left: 5px;
}
.regStepperContainer .regStepperHeader .stepperContainer {
  width: 100%;
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regStepperHeader .stepperContainer {
    padding-left: 0px;
    background-color: black;
  }
}
.regStepperContainer .regInfoContainer {
  padding: 0px 20px 20px 20px;
}
.regStepperContainer .regInfoContainer .verify-block {
  display: flex;
  align-items: center;
  height: 100%;
}
.regStepperContainer .regInfoContainer .verify-block.farm-verify-block {
  margin-bottom: 0;
}
.regStepperContainer .regInfoContainer .verify-block .verify-icon {
  background-color: transparent;
  border: 2px solid #20a258;
  border-radius: 50%;
  height: 21px;
  width: 21px;
  position: relative;
}
.regStepperContainer .regInfoContainer .verify-block .verify-icon::before {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 5px;
  width: 7px;
  height: 12px;
  border: solid #20a258;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.regStepperContainer .regInfoContainer .verify-block .text-verify {
  position: relative;
  font-weight: 600;
  font-size: 17px;
  color: #20a258;
  text-transform: capitalize;
  margin-left: 5px;
}
.regStepperContainer .regInfoContainer .verifyMaginBlock {
  margin-left: 55px;
}
@media screen and (min-width: 1560px) {
  .regStepperContainer .regInfoContainer .verifyMaginBlock {
    margin-left: 25px;
  }
}
@media screen and (max-width: 599px) {
  .regStepperContainer .regInfoContainer .verifyMaginBlock {
    margin-left: 0px;
  }
}
.regStepperContainer .regInfoContainer .regAdress h4 {
  font-weight: 700;
  margin: 30px 0;
  font-size: 22px;
}
.regStepperContainer .regInfoContainer .regAdress .regRestInfoForm {
  margin: 24px 0;
}
.regStepperContainer .regInfoContainer .regAdress .regRestInfoForm h6 {
  font-weight: 600;
  margin: 10px 0;
}
.regStepperContainer .regInfoContainer .regAdress .regRestInfoForm p {
  color: #808080;
  margin: 10px 0;
}
.regStepperContainer .regInfoContainer .regAdress .regRestInfoForm .detectIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  border-left: 1px solid #979797;
  padding-left: 30px;
}
.regStepperContainer .regInfoContainer .regAdress .regRestInfoForm .detectIcon img {
  height: 20px;
  width: 20px;
}
.regStepperContainer .regInfoContainer .regAdress .regRestInfoForm .detectIcon p {
  color: #394053;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .regStepperContainer .regInfoContainer .regAdress .regRestInfoForm .detectIcon {
    padding-left: 10px;
    grid-gap: 10px;
    gap: 10px;
  }
  .regStepperContainer .regInfoContainer .regAdress .regRestInfoForm .detectIcon p {
    font-size: 15px;
  }
}
.regStepperContainer .regInfoContainer .iframeContainer {
  margin: 40px 0;
}
.regStepperContainer .regInfoContainer .iframeContainer .coOrdinateContainer {
  margin: 20px 0;
}
.regStepperContainer .regInfoContainer .iframeContainer .coOrdinateContainer .ordinateHeader {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #c1c1c1;
  line-height: 0.1em;
  margin: 30px 0 30px;
}
.regStepperContainer .regInfoContainer .iframeContainer .coOrdinateContainer .ordinateHeader span {
  background: #fff;
  padding: 0 10px;
  color: #394053;
}
.regStepperContainer .regInfoContainer .iframeContainer .coOrdinateContainer .coOrdinate-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  grid-gap: 30px;
  gap: 30px;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regInfoContainer .iframeContainer .coOrdinateContainer .coOrdinate-form {
    flex-direction: column;
  }
}
.regStepperContainer .regInfoContainer .restContactNumber {
  margin: 20px 0;
}
.regStepperContainer .regInfoContainer .restContactNumber h6 {
  font-weight: 600;
  color: #808080;
  margin: 10px 0;
}
.regStepperContainer .regInfoContainer .restContactNumber p {
  font-size: 12px;
  margin: 5px 0;
}
.regStepperContainer .regInfoContainer .restContactNumber .contact {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regInfoContainer .restContactNumber .contact {
    flex-direction: column;
  }
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber {
  width: 100%;
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber h6 {
  font-size: 18px;
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber p {
  font-weight: 400;
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm {
    flex-direction: column;
  }
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm .numberField {
  width: 35%;
}
@media screen and (max-width: 768px) {
  .regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm .numberField {
    width: 100%;
  }
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm .greenContained {
  padding: 0px;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm .greenContained {
    width: 100%;
  }
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm .greenContained .MuiButton-root {
  padding: 8px 30px !important;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm .greenContained .MuiButton-root {
    width: 100%;
  }
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm .countryCodeIcon {
  border-right: 1px solid #808080;
}
.regStepperContainer .regInfoContainer .restContactNumber .contact .conactNumber .conactNumberForm .countryCodeIcon p {
  font-size: 20px;
  font-weight: 700;
  color: #394053;
  padding-right: 20px;
}
.regStepperContainer .regInfoContainer .restContactNumber .otpContainer {
  width: 100%;
}
.regStepperContainer .regInfoContainer .restContactNumber .otpContainer .otpForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 5px;
  grid-gap: 5px;
  gap: 5px;
}
.regStepperContainer .regInfoContainer .restContactNumber .otpContainer .otpForm p {
  font-family: "Poppins", sans-serif;
}
.regStepperContainer .regInfoContainer .restContactNumber .otpContainer .otpForm .outlineTextfield {
  width: 50px;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regInfoContainer .restContactNumber .otpContainer .otpForm .outlineTextfield {
    width: 50px;
  }
}
.regStepperContainer .regInfoContainer .restContactNumber .otpContainer .otpForm input {
  background-color: transparent !important;
  border: 1px solid rgba(193, 193, 193, 0.368627451);
  border-radius: 8px;
  margin-right: 15px;
  outline: 0;
  height: 45px;
  width: 45px !important;
  color: #757575;
}
.regStepperContainer .regInfoContainer .restContactNumber .otpContainer .otpForm input.disabledInput {
  cursor: not-allowed;
  opacity: 0.8;
}
.regStepperContainer .regInfoContainer .restContactNumber .otpContainer .otpForm input.errorInput {
  border-color: red;
}
.regStepperContainer .regInfoContainer .restContactNumber.restDetailsContact .conactNumber, .regStepperContainer .regInfoContainer .restContactNumber.restDetailsContact .otpContainer {
  width: 50% !important;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer {
  margin: 40px 0;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .detailsHeader {
  font-size: 22px !important;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer h4 {
  font-weight: 700;
  margin: 20px 0;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer p {
  font-size: 18px !important;
  margin: 10px 0;
  font-weight: 700;
  color: #5d5e6a;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .nameEmails {
  width: 75%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .regInfoContainer .ownerDetailsContainer .nameEmails {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .nameEmails .outlineTextfield {
  width: 100%;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .ownerDetails {
  width: 100%;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails {
  display: flex;
  align-items: flex-end !important;
  justify-content: flex-start;
  grid-gap: 10px;
  gap: 10px;
  margin: 30px 0;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .restPhone {
  margin-right: 30px;
  align-self: flex-end;
}
@media screen and (max-width: 560px) {
  .regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .restPhone {
    align-self: auto;
  }
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .grayBackGroundButton {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails {
    grid-gap: 20px;
    gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start !important;
    margin: 10px 0;
  }
  .regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails > div:nth-child(1) {
    width: 100%;
  }
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .customRadio {
  width: 100%;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .verifyForm {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .verifyForm.verifyRegForm {
  width: 100%;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .verifyForm .greenContained {
  padding: 0px;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .verifyForm .greenContained .MuiButton-root {
  padding: 8px 30px !important;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .verifyForm .restVerifyBtn {
  margin-right: 40px;
  align-self: flex-end;
}
@media screen and (max-width: 560px) {
  .regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .verifyForm .restVerifyBtn {
    align-self: flex-start;
  }
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .verifyForm .countryCodeIcon {
  border-right: 1px solid #808080;
}
.regStepperContainer .regInfoContainer .ownerDetailsContainer .phoneDetails .verifyForm .countryCodeIcon p {
  font-size: 20px;
  font-weight: 700;
  color: #394053;
  padding-right: 20px;
}
.regStepperContainer .restTypeTimingsContainer {
  padding: 40px 20px 30px 20px;
}
.regStepperContainer .restTypeTimingsContainer .restTypeCommonHeader h4 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #171725;
}
.regStepperContainer .restTypeTimingsContainer .restTypeCommonHeader p {
  margin: 10px 0;
  color: #464a53;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.regStepperContainer .restTypeTimingsContainer .radioBoxContainer {
  margin-left: -10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.regStepperContainer .restTypeTimingsContainer .radioBoxContainer .errorMsg {
  margin-left: 10px;
}
.regStepperContainer .restTypeTimingsContainer .radioBoxContainer .MuiFormGroup-root {
  flex-direction: row;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .restTypeTimingsContainer .radioBoxContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}
.regStepperContainer .restTypeTimingsContainer .outletType .block {
  margin: 30px 0;
}
.regStepperContainer .restTypeTimingsContainer .outletType .block h6 {
  color: #464a53;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0;
}
.regStepperContainer .restTypeTimingsContainer .restOperationTime {
  margin: 10px 0;
}
.regStepperContainer .restTypeTimingsContainer .restOperationTime .restOpenCLose {
  width: 70%;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .restTypeTimingsContainer .restOperationTime .restOpenCLose {
    width: 100%;
  }
}
.regStepperContainer .restTypeTimingsContainer .restOperationTime .restOpenCLose .timingsFrom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  grid-gap: 30px;
  gap: 30px;
}
.regStepperContainer .restTypeTimingsContainer .restOperationTime .restOpenCLose .timingsFrom span {
  color: #394053;
  font-weight: 700;
}
.regStepperContainer .restTypeTimingsContainer .restOperationTime .restOpenCLose .timingsFrom span:nth-child(2) {
  padding-top: 25px;
}
.regStepperContainer .restTypeTimingsContainer .restOperationTime .restOpenCLose .timingsFrom span h6 {
  color: #394053;
  font-weight: 700;
  margin: 5px 0;
}
.regStepperContainer .restTypeTimingsContainer .restOperationTime .restOpenCLose .openDaysCheckBox {
  margin: 20px 0;
}
.regStepperContainer .restTypeTimingsContainer .restOperationTime .restOpenCLose .openDaysCheckBox h6 {
  color: #394053;
  font-weight: 700;
}
.regStepperContainer .restTypeTimingsContainer .bannerImgContainer {
  margin: 30px 0;
}
.regStepperContainer .restTypeTimingsContainer .bannerImgContainer h6 {
  font-weight: 600;
  color: #20a258;
  font-size: 22px;
}
.regStepperContainer .restTypeTimingsContainer .bannerImgContainer .FileUpload .customFileUpload {
  max-width: 150px;
}
.regStepperContainer.restAddDetailsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .regStepperContainer.restAddDetailsWrapper {
    justify-content: flex-start;
    display: block;
  }
}
.regStepperContainer.restAddDetailsWrapper .restTypeTimingsContainer {
  padding: 20px 0 0 0;
}
.regStepperContainer.restAddDetailsWrapper .bankDetaisCOntainer {
  padding: 20px 0 20px 0;
}
.regStepperContainer.restAddDetailsWrapper .bankDetaisCOntainer h4 {
  font-size: 22px;
}
@media screen and (max-width: 767px) {
  .regStepperContainer.restAddDetailsWrapper .bankDetaisCOntainer {
    padding-bottom: 0;
  }
}
.regStepperContainer.restAddDetailsWrapper .bankDetaisCOntainer .bankDetalsFormContainer {
  margin: 20px 0 30px 0;
}
.regStepperContainer .bankDetaisCOntainer {
  padding: 40px 20px 30px 20px;
}
.regStepperContainer .bankDetaisCOntainer.adminFarmBankDetaisContainer {
  padding: 0;
}
.regStepperContainer .bankDetaisCOntainer .bankDetalsFormContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
  margin: 30px 0;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .bankDetaisCOntainer .bankDetalsFormContainer {
    flex-direction: column;
  }
}
.regStepperContainer .bankDetaisCOntainer .bankDetalsFormContainer .formContainer {
  width: 100%;
  height: 100%;
}
.regStepperContainer .bankDetaisCOntainer .bankDetalsFormContainer .formContainer h6 {
  font-weight: 700;
  color: #394053;
  margin: 10px 0;
  font-size: 18px;
}
.regStepperContainer .bankDetaisCOntainer .bankDetalsFormContainer .formContainer .outlineSelect {
  width: 100%;
}
.regStepperContainer .bankDetaisCOntainer .bankDetalsFormContainer .formContainer .outlineSelect .MuiSelect-select {
  padding: 12px 19px !important;
}
.regStepperContainer .bankDetaisCOntainer .bankDetalsFormContainer .formContainer .outlineSelect .MuiFormControl-root {
  width: 100%;
}
.regStepperContainer .bankDetaisCOntainer .uploadDetail {
  margin: 30px 0;
}
.regStepperContainer .bankDetaisCOntainer .uploadDetail .uploadSubtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #464a53;
  font-size: 15px;
  opacity: 0.9;
}
.regStepperContainer .bankDetaisCOntainer .checkBoxContainer {
  margin-top: 100px;
  margin-bottom: -50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.regStepperContainer .bankDetaisCOntainer .checkBoxContainer span {
  color: #5d5e6a;
}
@media screen and (max-width: 767px) {
  .regStepperContainer .bankDetaisCOntainer .checkBoxContainer span {
    font-size: 12px;
  }
}
.regStepperContainer .bankDetaisCOntainer .checkBoxContainer span a {
  font-weight: 700;
  color: #20a258;
  margin-left: 5px;
}
.regStepperContainer .bankDetaisCOntainer .checkBoxContainer span a:hover {
  text-decoration: underline;
}
.regStepperContainer .bankDetaisCOntainer .checkBoxContainer.checkBoxRegContainer {
  margin: 0;
}
.regStepperContainer .bankDetaisCOntainer .checkBoxContainer.checkBoxRegContainer label {
  font-family: "Poppins", sans-serif;
  color: #464a53;
}
.regStepperContainer .btnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 30px 0 10px;
  grid-gap: 10px;
  gap: 10px;
}
.regStepperContainer .btnContainer .greenContained .MuiButton-root .MuiButton-label span,
.regStepperContainer .btnContainer .grayButton .MuiButton-root .MuiButton-label span {
  font-size: 15px;
  font-weight: 800;
  padding: 5px 30px;
}
.regStepperContainer .btnContainer.regBtnContainer {
  margin: 0;
}
@media screen and (max-width: 560px) {
  .regStepperContainer .btnContainer {
    flex-wrap: nowrap;
    grid-gap: 0;
    gap: 0;
  }
}
@media screen and (max-width: 768px) {
  .regStepperContainer .btnContainer .greenContained,
.regStepperContainer .btnContainer .grayButton {
    width: 100%;
  }
  .regStepperContainer .btnContainer button:nth-child(2) {
    margin-left: 10px;
  }
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  box-shadow: 24;
  padding: 40px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;
}
@media screen and (max-width: 767px) {
  .modalBox {
    width: 300px;
    padding: 25px;
  }
}
.modalBox .modalImgContainer {
  width: 100%;
  height: 250px;
}
.modalBox .modalImgContainer > img {
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 767px) {
  .modalBox .modalImgContainer {
    height: 150px;
  }
}
.modalBox .modalHeading {
  text-align: center;
  width: 100%;
  font-weight: 700;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .modalBox .modalHeading {
    font-size: 15px;
  }
}
.modalBox .content {
  color: #5d5e6a;
  text-align: center;
  margin: 10px 0;
  width: 100%;
  font-size: 17px;
}
@media screen and (max-width: 767px) {
  .modalBox .content {
    font-size: 10px;
  }
}
.modalBox .greenContained .MuiButton-root {
  padding: 5px 40px;
}

.ownerDetailsContainer .countryCodeIcon {
  border-right: 1px solid #808080;
}
.ownerDetailsContainer .countryCodeIcon .inputAdornment {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #394053;
  padding-right: 20px !important;
}

.restOwnerCheckBox.customCheckbox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}
.restOwnerCheckBox.customCheckbox label {
  font-family: "Poppins", sans-serif;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-family: "Poppins", sans-serif;
  color: #464a53;
}

.custom_action_btn {
  width: 100% !important;
  min-height: 50px !important;
}

.resDesc {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}

.headingDetails {
  font-size: 18px !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customStepperContainer .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-active {
  color: #f8892b;
}
.customStepperContainer .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .MuiStepLabel-iconContainer .MuiStepIcon-root.MuiStepIcon-completed {
  color: #f8892b;
}
.customStepperContainer .MuiStepper-root .MuiStep-horizontal .MuiStepLabel-root .MuiStepLabel-labelContainer {
  display: none;
  visibility: hidden;
}

.stepIcon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-top: -8px;
  z-index: 2;
}
.stepIcon:hover {
  cursor: pointer;
}
.footer_container {
  background-color: #610808;
  padding: 40px;
  width: 100% !important;
}
@media screen and (max-width: 1024px) {
  .footer_container {
    padding: 30px 0;
  }
}

.footer_logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .footer_logo img {
    width: 150px;
  }
}
@media screen and (max-width: 1024px) {
  .footer_logo {
    justify-content: flex-start;
  }
}

.footer_wrapper {
  color: #ffffff;
}

.foot_list_wrapper {
  padding-bottom: 5px;
}

.foot_list_title {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px !important;
  text-transform: capitalize;
}
@media screen and (max-width: 1024px) {
  .foot_list_title {
    font-size: 14px;
    padding-top: 20px;
  }
}

.list-unstyled {
  padding: 3px 0px;
}

.foot_list_item {
  font-size: 16px;
  color: #c3c3c3 !important;
}
@media screen and (max-width: 1024px) {
  .foot_list_item {
    font-size: 13px;
  }
}

.foot_list_item:hover {
  color: #20a258 !important;
}

.subscribe_text:hover {
  color: #c3c3c3 !important;
}

.foot_input_field {
  border-radius: 50px !important;
  box-shadow: none !important;
  padding: 5px 5px 5px 20px !important;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.contact_us {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.local_store_link {
  font-size: 19px;
  color: #c3c3c3;
  text-decoration: underline;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.local_store_link:hover {
  color: #20a258;
}
@media screen and (max-width: 1024px) {
  .local_store_link {
    font-size: 13px;
  }
}

.contact_icons {
  border: 2px solid #c3c3c3;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_icons .MuiSvgIcon-root {
  font-size: 15px;
}

.contact-item {
  padding-left: 10px;
}

.contact-icon {
  color: #c3c3c3 !important;
}

.send_icon {
  background-color: #20a258 !important;
  color: #ffffff !important;
}

.social_icons_list {
  display: flex;
  align-items: center;
  margin-top: 10px;
  grid-gap: 5px;
  gap: 5px;
}
.social_icons_list button {
  border: none;
  background-color: transparent;
}
.social_icons_list button img {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.social_icon {
  color: #ffffff;
  font-size: 32px !important;
  cursor: pointer;
}

.copywrite {
  width: 100%;
  text-align: center;
  background-color: #222f26;
  padding: 15px;
}
.copywrite p {
  color: #b8b8b8 !important;
}

.email_error {
  color: red;
  margin-top: 3px;
  margin-left: 22px;
}

.contact_us_footer {
  outline: none;
  border: none;
  padding: 5px 40px;
  margin-top: 15px;
  border-radius: 20px;
  color: #494444;
}
@media screen and (max-width: 768px) {
  .contact_us_footer {
    padding: 3px 25px;
    font-size: 10px;
  }
}
.green_banner_new {
  background: url(/static/media/image-footer-calltoaction@2x.7c132915.png) no-repeat !important;
  background-size: cover;
  padding: 60px;
  display: flex;
  width: 100%;
  align-items: center;
}
.green_banner_new .banner_text {
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.56;
}
@media screen and (max-width: 1200px) {
  .green_banner_new .banner_text {
    font-size: 18px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 1024px) {
  .green_banner_new .banner_text {
    line-height: 1.2;
    font-size: 16px;
  }
}
@media screen and (max-width: 599px) {
  .green_banner_new .banner_text {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .green_banner_new {
    padding: 40px;
  }
}
@media screen and (max-width: 350px) {
  .green_banner_new {
    padding: 25px 15px;
  }
}

.btnBlock {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 600px) {
  .btnBlock {
    margin-top: 20px !important;
  }
}

.green_banner_btn {
  border: 2px solid #ffffff !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  padding: 3px 16px !important;
  font-size: 20px !important;
}
@media screen and (max-width: 1200px) {
  .green_banner_btn {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .green_banner_btn {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 768px) {
  .green_banner_btn {
    font-size: 13px !important;
  }
}

.btn-label {
  color: #ffffff !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.educationWapper {
  background-color: #ffffff;
  width: 100%;
}
.educationWapper .commonSection {
  margin-bottom: 100px;
}
.educationWapper .commonSection:last-child {
  margin-bottom: 0px;
}
.educationWapper .bannerSection {
  background-image: url(/static/media/banner1.5cea40be.png);
  background-size: cover;
  background-position: center center;
  height: 600px;
  width: 100%;
  position: relative;
  z-index: 1;
}
.educationWapper .bannerSection::after {
  content: " ";
  height: 100%;
  width: 100%;
  background-color: rgba(76, 175, 80, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.educationWapper .bannerSection .banner_content {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content {
    flex-direction: column;
    padding: 40px 0;
  }
}
.educationWapper .bannerSection .banner_content .bannerBlocks {
  width: 50%;
  margin: 0 10px;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content .bannerBlocks {
    width: 90%;
    margin: 0 10px;
  }
}
.educationWapper .bannerSection .banner_content .bannerBlocks:nth-child(2) {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content .bannerBlocks:nth-child(2) {
    margin: 0;
  }
}
.educationWapper .bannerSection .banner_content .bannerBlocks h1 {
  font-family: "AllRoundGothicW01-Medium";
  font-size: 40px;
  letter-spacing: 4px;
}
.educationWapper .bannerSection .banner_content .contentBlock {
  padding-left: 150px;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content .contentBlock {
    padding-left: 0;
  }
}
@media screen and (max-width: 1440px) {
  .educationWapper .bannerSection .banner_content .contentBlock {
    padding-left: 10px;
  }
}
.educationWapper .bannerSection .banner_content .contentBlock h1,
.educationWapper .bannerSection .banner_content .contentBlock p {
  color: #ffffff;
}
.educationWapper .bannerSection .banner_content .contentBlock h1 {
  font-family: "AllRoundGothicW01-Medium";
}
.educationWapper .bannerSection .banner_content .contentBlock p {
  color: #ffffff;
  font-weight: normal;
  margin: 40px 0;
}
.educationWapper .bannerSection .banner_content .searchbox {
  position: relative;
  width: 80%;
}
.educationWapper .bannerSection .banner_content .searchbox .plus_icon {
  display: none;
}
.educationWapper .bannerSection .banner_content .searchbox .MuiFormControl-root {
  padding: 3px 30px;
  border-radius: 30px;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content .searchbox .MuiFormControl-root {
    padding: 3px 10px;
  }
}
.educationWapper .bannerSection .banner_content .searchbox .MuiFormControl-root input {
  color: #1e1e1e !important;
  font-size: 16px;
  font-weight: 600;
}
.educationWapper .bannerSection .banner_content .searchbox .MuiFormControl-root input::-moz-placeholder {
  color: #1e1e1e !important;
  font-size: 16px;
  font-weight: 600;
}
.educationWapper .bannerSection .banner_content .searchbox .MuiFormControl-root input:-ms-input-placeholder {
  color: #1e1e1e !important;
  font-size: 16px;
  font-weight: 600;
}
.educationWapper .bannerSection .banner_content .searchbox .MuiFormControl-root input::placeholder {
  color: #1e1e1e !important;
  font-size: 16px;
  font-weight: 600;
}
.educationWapper .bannerSection .banner_content .searchbox .MuiInputBase-root input {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content .searchbox .MuiInputBase-root input {
    font-size: 14px;
  }
}
.educationWapper .bannerSection .banner_content .searchbox .MuiInputBase-root input::-moz-placeholder {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
}
.educationWapper .bannerSection .banner_content .searchbox .MuiInputBase-root input:-ms-input-placeholder {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
}
.educationWapper .bannerSection .banner_content .searchbox .MuiInputBase-root input::placeholder {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content .searchbox .MuiInputBase-root input::-moz-placeholder {
    font-size: 14px;
  }
  .educationWapper .bannerSection .banner_content .searchbox .MuiInputBase-root input:-ms-input-placeholder {
    font-size: 14px;
  }
  .educationWapper .bannerSection .banner_content .searchbox .MuiInputBase-root input::placeholder {
    font-size: 14px;
  }
}
.educationWapper .bannerSection .banner_content .searchbox .MuiButton-root {
  position: absolute;
  top: 0;
  right: -1px;
  padding: 15px 40px;
  height: 55px;
  border-radius: 30px;
  background-color: coral;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content .searchbox .MuiButton-root {
    padding: 17px 30px;
  }
}
.educationWapper .bannerSection .banner_content .searchbox .MuiButton-root .MuiButton-label span {
  padding-left: 0;
  font-size: 18px;
  color: #ffffff;
}
.educationWapper .bannerSection .banner_content .videoBlock {
  display: flex;
  align-items: center;
  position: relative;
}
.educationWapper .bannerSection .banner_content .videoBlock iframe {
  width: 100%;
  height: 500px;
  border-radius: 20px 0 0 20px;
  position: absolute;
}
@media screen and (max-width: 767px) {
  .educationWapper .bannerSection .banner_content .videoBlock iframe {
    height: 200px;
    position: unset;
  }
}
@media screen and (max-width: 768px) {
  .educationWapper .bannerSection .banner_content .videoBlock iframe {
    border-radius: 20px;
  }
}
@media screen and (max-width: 1440px) {
  .educationWapper .bannerSection .banner_content .videoBlock iframe {
    border-radius: 15px 0 0 15px;
  }
}
.educationWapper .subContentWrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 10px;
}
.educationWapper .subContentWrapper h2 {
  text-align: center;
  margin-bottom: 0px;
}
.educationWapper .subContentWrapper h6 {
  text-align: center;
  margin-bottom: 10px;
  color: #20a258;
}
@media screen and (max-width: 1440px) {
  .educationWapper .subContentWrapper {
    max-width: 1200px;
  }
}
.educationWapper .choseusSection .cardmb {
  margin-bottom: 30px;
}
.educationWapper .choseusSection p {
  padding: 50px 50px 50px 0;
  letter-spacing: 2px;
  color: #36433a;
}
.educationWapper .choseusSection h2 {
  font-size: 35px;
}
.educationWapper .choseusSection h4 {
  color: #394053;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 800;
}
@media screen and (max-width: 1024px) {
  .educationWapper .choseusSection h4 {
    font-size: 16px;
  }
}
.educationWapper .choseusSection .cardFooter {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.educationWapper .choseusSection .cardFooter .imgOverlay {
  z-index: 1;
}
.educationWapper .choseusSection .cardFooter .imgOverlay::after {
  content: " ";
  background-color: #d1e8d8;
  padding: 0 10px 10px 10px;
  position: absolute;
  right: 0;
  z-index: 1;
  height: 100%;
  bottom: -38px;
  width: 152px;
  z-index: -1;
  border-top-left-radius: 50%;
}
.educationWapper .choseusSection .cardFooter img {
  width: 180px;
  height: 120px;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 3;
}
.educationWapper .courseSection h6 {
  font-size: 20px;
}
.educationWapper .courseSection h2 {
  padding-bottom: 40px;
  font-size: 35px;
}
.educationWapper .courseSection .courseImg {
  width: 100%;
  border-radius: 20px;
  max-width: 550px;
}
.educationWapper .courseSection .joinCourseCard {
  background-color: transparent;
  max-width: 500px;
  box-shadow: none !important;
}
.educationWapper .courseSection .joinCourseCard img {
  height: 60px;
  width: 60px;
  -o-object-fit: contain;
     object-fit: contain;
}
.educationWapper .courseSection .joinCourseCard.active {
  box-shadow: 0px 16px 20px 0px rgba(204, 204, 204, 0.5803921569) !important;
}
.educationWapper .courseSection .joinCourseCard .cardContent {
  display: flex;
}
.educationWapper .courseSection .joinCourseCard .cardSubContent {
  padding-left: 20px;
}
.educationWapper .courseSection .joinCourseCard .cardSubContent h5 {
  font-weight: 600;
}
.educationWapper .courseSection .joinCourseCard .cardSubContent p {
  font-weight: 600;
  color: #c1c1c1;
  padding-top: 10px;
  word-spacing: 2px;
  line-height: 25px;
}
.educationWapper .courseSection .courseCard {
  border-radius: 15px;
}
.educationWapper .courseSection .courseCard .courseImg {
  border-radius: 15px;
}
.educationWapper .courseSection .courseCard .cardContent .MuiTypography-h5 {
  font-family: "AllRoundGothicW01-Medium";
}
.educationWapper .courseSection .courseCard .MuiButton-label span {
  text-transform: capitalize;
  font-size: 20px;
  margin-right: 10px;
  color: #394053;
  font-family: "AllRoundGothicW01-Medium";
}
.educationWapper .courseSection .courseCard .MuiButtonBase-root:hover {
  background-color: transparent;
}
.educationWapper .courseSection .circleBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.educationWapper .aboutCourses .leftContainer h4 {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.4;
  font-family: "AllRoundGothicW01-Medium";
}
.educationWapper .aboutCourses .leftContainer .content {
  color: #808080;
  line-height: 2;
  margin-top: 20px;
}
.educationWapper .aboutCourses .leftContainer .courseCountails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 30px;
  gap: 30px;
  margin-top: 50px;
}
.educationWapper .aboutCourses .leftContainer .courseCountails .block h3 {
  color: #20a258;
  font-size: 25px;
}
.educationWapper .aboutCourses .leftContainer .courseCountails .block p {
  font-size: 10px;
  color: #808080;
  font-family: "AllRoundGothicW01-Medium";
}
.educationWapper .aboutCourses .introVideo {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0px;
  z-index: 4;
}
.educationWapper .aboutCourses .introVideo .videoBlock {
  width: 100%;
  height: 100%;
  z-index: 4;
}
.educationWapper .aboutCourses .introVideo .videoBlock.bannerBlocks {
  width: 100%;
  height: 100%;
  z-index: 4;
}
.educationWapper .aboutCourses .introVideo .videoBlock iframe {
  width: 80%;
  height: 100%;
  border-radius: 20px;
  z-index: 4;
}
.educationWapper .aboutCourses .introVideo .dottedSideBar {
  position: absolute;
  top: 100px;
  right: 0px;
  z-index: 1;
  width: 100px;
}
@media screen and (max-width: 1024px) {
  .educationWapper .aboutCourses .introVideo .dottedSideBar {
    width: 80px;
    top: 180px;
  }
}
@media screen and (max-width: 834px) {
  .educationWapper .aboutCourses .introVideo .dottedSideBar {
    width: 70px;
    top: 180px;
  }
}
@media screen and (max-width: 767px) {
  .educationWapper .aboutCourses .introVideo .dottedSideBar {
    width: 60px;
    top: 240px;
  }
}
@media screen and (max-width: 599px) {
  .educationWapper .aboutCourses .introVideo .dottedSideBar {
    top: 40px;
  }
}
.educationWapper .educatorsContainer {
  margin-top: 150px;
}
.educationWapper .educatorsContainer h1 {
  font-family: unset;
  width: 100%;
  text-align: center;
  margin-bottom: 100px;
}
.educationWapper .educatorsContainer .swiperContainer .swiperAarrowRow {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: 100px auto 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .educationWapper .educatorsContainer .swiperContainer .swiperAarrowRow {
    max-width: 550px;
  }
}
.educationWapper .educatorsContainer .swiperContainer .swiperAarrowRow .prevNavigation,
.educationWapper .educatorsContainer .swiperContainer .swiperAarrowRow .nextNavigation {
  color: #ec9e31;
  cursor: pointer;
  z-index: 10;
}
.educationWapper .educatorsContainer .swiperContainer .swiperAarrowRow .prevNavigation .MuiSvgIcon-root,
.educationWapper .educatorsContainer .swiperContainer .swiperAarrowRow .nextNavigation .MuiSvgIcon-root {
  margin-top: -20px;
}
.educationWapper .educatorsContainer .swiperContainer .swiper-pagination {
  margin-top: 0px;
}
.educationWapper .educatorsContainer .swiperContainer .swiper-pagination .swiper-pagination-bullet {
  width: 40px;
  height: 7px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
  .educationWapper .educatorsContainer .swiperContainer .swiper-pagination .swiper-pagination-bullet {
    width: 30px;
  }
}
.educationWapper .educatorsContainer .swiperContainer .swiper-pagination .swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}
.web_restaurant_nav_bar {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.web_restaurant_nav_bar .toggle_menu_icon {
  margin-right: 10px;
}
.web_restaurant_nav_bar .toggle_menu_icon img {
  height: 25px;
}
@media (min-width: 1024px) {
  .web_restaurant_nav_bar .toggle_menu_icon {
    display: none;
  }
}
.web_restaurant_nav_bar img {
  width: 100%;
}
@media (max-width: 600px) {
  .web_restaurant_nav_bar img {
    width: 100px;
  }
}
.web_restaurant_nav_bar .web_restaurant_routing_tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 1320px) {
  .web_restaurant_nav_bar .web_restaurant_routing_tabs {
    display: none;
  }
}
.web_restaurant_nav_bar .web_restaurant_routing_tabs_none {
  display: none;
}
.web_restaurant_nav_bar a {
  text-transform: uppercase;
  color: #808080;
  font-weight: 500;
}
.web_restaurant_nav_bar .search_icon {
  background-color: #ec9e31;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.web_restaurant_nav_bar .web_header_profile {
  display: flex;
  justify-content: flex-end;
}
.web_restaurant_nav_bar .web_header_profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-left: 10px;
}
@media (max-width: 400px) {
  .web_restaurant_nav_bar .web_header_profile img {
    width: 40px;
    height: 40px;
  }
}
.web_restaurant_nav_bar .web_header_select :root {
  margin-right: 20px !important;
}
.web_restaurant_nav_bar .web_header_select svg {
  color: #ffffff;
  top: 5px;
  left: 3px;
}
.web_restaurant_nav_bar .web_header_select .MuiSelect-select.MuiSelect-select {
  padding-right: 0 !important;
  margin-right: 0px;
  background: green;
  border-radius: 50%;
  width: 30px;
  height: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #fff;
}
.web_restaurant_nav_bar .web_header_select .MuiInput-underline::before,
.web_restaurant_nav_bar .web_header_select .MuiInput-underline::after {
  border-bottom: none !important;
  outline: none !important;
}
.web_restaurant_nav_bar .restaurant_navbar_search_input_wrapper {
  background: #f0f0f0;
  border-radius: 50px;
  transition: all 1s;
}
.web_restaurant_nav_bar .restaurant_navbar_search_input_wrapper .MuiFilledInput-underline::before {
  border: none;
  outline: none;
}
.web_restaurant_nav_bar .restaurant_navbar_search_input_wrapper button {
  width: 9%;
  border-radius: 50px;
  background: #ec9e31;
}
.web_restaurant_nav_bar .restaurant_navbar_search_input_wrapper button span {
  color: #ffffff;
}
.web_restaurant_nav_bar .restaurant_navbar_search_input_wrapper_none {
  display: none;
}
.web_restaurant_nav_bar .restaurant_navbar_search_input {
  width: 90%;
  transition: all 1s;
  background-color: transparent !important;
}
.web_restaurant_nav_bar .restaurant_navbar_search_input input {
  padding: 15px 20px;
  background-color: transparent;
}
.web_restaurant_nav_bar .underline {
  background-image: linear-gradient(#5fca66, #5fca66);
  background-position: bottom center;
  /*Adjust the background-position to move the line*/
  background-size: 50% 3px;
  /*Adjust the background size to control length and height*/
  background-repeat: no-repeat;
  padding-bottom: 4px;
  /* this can also control the position */
}
.web_restaurant_nav_bar .restaurant_navbar_actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.web_restaurant_nav_bar .restaurant_navbar_actions button {
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 10px 20px;
}
.web_restaurant_nav_bar .restaurant_navbar_actions button span {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  text-transform: none;
}
.web_restaurant_nav_bar .restaurant_navbar_icon {
  display: block;
}
@media (max-width: 630px) {
  .web_restaurant_nav_bar .restaurant_navbar_icon_none {
    display: none;
  }
}
.container {
  flex: 1 1;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  background-color: white;
  border: 1px solid black;
  border-radius: 18px;
  align-items: middle;
  margin-top: 8%;
}

.form {
  margin-top: 20px;
}

.web_input_field .MuiFilledInput-underline:before,
.web_input_field .MuiFilledInput-underline:after {
  border-bottom: none !important;
}
.web_input_field .Mui-focused {
  color: #20a258;
}

.button {
  width: 100%;
  background: #5cb85c;
  color: white;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}

.signup_validations {
  color: red;
  font-size: 12px !important;
  padding: 10px 0px 0px 10px;
}

.login_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding: 0 12%;
  border-bottom: 5px solid #5cb85c;
}

.signup_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  border-bottom: 5px solid #e0e0e0;
  padding: 0 5%;
}

.login_btn_box {
  width: 50%;
}

.form_group {
  padding-bottom: 16px;
}

.label-title {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 12px;
  color: grey;
}

.form_control {
  width: 83%;
  border: none;
  height: 2rem;
  background: #d1d1d1;
  padding: 5px 25px;
  border-radius: 5px;
}

.forgotPassword_wrapper button {
  background-color: transparent !important;
  padding: 0px;
}
.forgotPassword_wrapper button :hover {
  background-color: transparent !important;
}
.forgotPassword_wrapper span {
  color: black !important;
  font-size: 15px;
  text-decoration: none;
  text-transform: none !important;
}

.forgotPassword {
  color: black;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 49%;
  z-index: -1;
}

.checkBoxContainerView {
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: -7px;
  z-index: -1;
}

.rememberMe {
  color: #6200ee;
  font-weight: bold;
  align-self: center;
  z-index: -1;
}

.orLabel {
  color: rgba(0, 0, 0, 0);
  font-weight: bold;
  align-self: center;
  margin: 20;
}

.login_signup_container {
  width: 100vh;
  height: 100%;
  display: flex;
  margin-top: 10%;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.layout_wrapper {
  background-color: #ffffff;
  border-radius: 35px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.form_wrapper {
  padding: 40px;
}

.logo_wrapper {
  padding: 20px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #20a258;
}

.error {
  color: rgb(243, 81, 81);
  font-size: 13px;
  font-weight: 500;
}

@media (max-width: 419px) {
  .sm_login_tab {
    padding-bottom: 30px;
  }
}

.forgot-password {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn {
  background-color: #20a258 !important;
  width: 100%;
  color: "white" !important;
  padding: 10px !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.btn_label {
  color: "white" !important;
}

.Login_Input {
  padding: 12px 10px !important;
}

.Login_Input_Field {
  border-radius: 8px !important;
  width: 100% !important;
  border: 2px solid transparent;
  color: #515156;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
}

.Login_Input_Field_Focus {
  border: 2px solid #20a258;
  color: #20a258;
  font-weight: 600;
}

.terms_of_condition {
  color: #394053;
  text-align: center;
  padding: 20px 0px;
  font-size: 15px !important;
}
.terms_of_condition span {
  color: #20a258;
}

.login_input_icons {
  color: #515156;
  font-size: 20px !important;
}

@media screen and (max-width: 415px) {
  .adjust_tab {
    font-weight: 500 !important;
    padding: 38px 10px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.container {
  flex: 1 1;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  background-color: white;
  border: 1px solid black;
  border-radius: 18px;
  align-items: middle;
  margin-top: 8%;
}

.form {
  margin-top: 20px;
}

.web_input_field .MuiFilledInput-underline:before,
.web_input_field .MuiFilledInput-underline:after {
  border-bottom: none !important;
}
.web_input_field .Mui-focused {
  color: #20a258;
}
.web_input_field p {
  color: #a1a4a9;
}
.web_input_field.endorementUser {
  display: flex;
  flex-wrap: wrap;
}
.web_input_field.endorementUser .urlClass {
  flex: 70% 1;
  border-right: 0;
  border-radius: 5px 0px 0px 5px !important;
}
.web_input_field.endorementUser .userNameClass {
  flex: 30% 1;
  border-radius: 0px 5px 5px 0px !important;
}
@media (max-width: 320px) {
  .web_input_field.endorementUser .urlClass {
    flex: 100% 1;
    border-bottom: 0;
    border-right: 1px solid #c3c3c3;
    border-radius: 5px 5px 0px 0px !important;
  }
  .web_input_field.endorementUser.userNameClass {
    flex: 100% 1;
    border-radius: 0px 0px 5px 5px !important;
  }
}
.web_input_field.endorment .MuiFilledInput-inputAdornedEnd {
  border-right: 1.5px #dcdddf solid;
  border-radius: 0;
}
.web_input_field.endorment .MuiInputAdornment-positionEnd {
  width: 221px;
  display: flex;
  justify-content: center;
}
.web_input_field.endorment p {
  font-family: "Poppins", sans-serif !important;
}

.contributeForms .web_input_field.endorementUser .urlClass {
  flex: 25% 1;
}
.contributeForms .web_input_field.endorementUser .userNameClass {
  flex: 75% 1;
}
@media (max-width: 420px) {
  .contributeForms .web_input_field.endorementUser .urlClass {
    flex: 50% 1;
    border-radius: 5px 5px 0px 0px !important;
  }
  .contributeForms .web_input_field.endorementUser.userNameClass {
    flex: 50% 1;
    border-radius: 0px 0px 5px 5px !important;
  }
}

.headerLearning {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid hsla(0deg, 0%, 75.7%, 0.368627451);
  padding: 20px;
}
.headerLearning h4 {
  font-family: "Poppins", sans-serif;
}

.Profileheader {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid hsla(0deg, 0%, 75.7%, 0.368627451);
  margin: 0 -30px;
  width: 75%;
  padding: 20px 30px;
}
.Profileheader h4 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

.helperText {
  font-weight: 500;
  margin-left: 24px;
  color: #a1a4a9;
  font-family: "Poppins", sans-serif;
}

.helperText2 {
  margin-left: 0;
  margin-top: 3px;
}

.mediaUploaderLearning {
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 200px;
  text-align: center;
  border: 1px solid #dcdddf;
  border-radius: 6px;
  color: #20a258;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mediaUploaderLearninghelper {
  width: 120px;
  height: 176px;
  margin-bottom: 9px;
  padding: 6px !important;
}
.mediaUploaderLearninghelper img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.textUpdateBlock {
  width: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textUpdateBlock .textUpdate {
  color: #20a258;
  font-size: 14px;
  font-weight: 600;
  margin-left: 3px;
  cursor: pointer;
}
.textUpdateBlock img {
  width: 20px;
  margin-right: -35px;
}

.button {
  width: 100%;
  background: #5cb85c;
  color: white;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}

.signup_validations {
  color: red;
  font-size: 12px !important;
  padding: 10px 0px 0px 10px;
}

.login_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding: 0 12%;
  border-bottom: 5px solid #5cb85c;
}

.signup_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  border-bottom: 5px solid #e0e0e0;
  padding: 0 5%;
}

.login_btn_box {
  width: 50%;
}

.form_group {
  padding-bottom: 16px;
}

.label-title {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 12px;
  color: grey;
}

.form_control {
  width: 83%;
  border: none;
  height: 2rem;
  background: #d1d1d1;
  padding: 5px 25px;
  border-radius: 5px;
}

.forgotPassword_wrapper button {
  background-color: transparent !important;
  padding: 0px;
}
.forgotPassword_wrapper button span {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.forgotPassword_wrapper button :hover {
  background-color: transparent !important;
}
.forgotPassword_wrapper span {
  color: black !important;
  font-size: 15px;
  text-decoration: none;
  text-transform: none !important;
}

.forgotPassword {
  color: black;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 49%;
  z-index: -1;
}

.checkBoxContainerView {
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: -7px;
  z-index: -1;
}

.rememberMe {
  color: #6200ee;
  font-weight: bold;
  align-self: center;
  z-index: -1;
}

.orLabel {
  color: rgba(0, 0, 0, 0);
  font-weight: bold;
  align-self: center;
  margin: 20;
}

.login_signup_container {
  width: 100vh;
  height: 100%;
  display: flex;
  margin-top: 10%;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.login_layout_wrapper {
  background-color: #ffffff;
  border-radius: 35px;
}

.form_wrapper {
  padding: 40px;
}

.logo_wrapper {
  padding: 25px 15px 15px !important;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #20a258;
}

.active_tab {
  border-bottom: 5px solid #20a258 !important;
  color: #03014c !important;
  text-align: center;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 10px;
  font-family: "AllRoundGothicW01-Medium";
}
@media screen and (max-width: 600px) {
  .active_tab {
    font-size: 16px !important;
    padding: 6px;
  }
}

.tab {
  width: 100%;
  text-align: center;
  padding: 10px;
  border-bottom: 5px solid #c3c3c3;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #8886a9;
  font-family: "AllRoundGothicW01-Medium";
}
@media screen and (max-width: 600px) {
  .tab {
    font-size: 16px !important;
    padding: 6px;
  }
}

.error {
  color: rgb(243, 81, 81);
  font-size: 13px;
  font-weight: 500px;
}

@media (max-width: 419px) {
  .sm_login_tab {
    padding-bottom: 30px;
  }
}

.forgot-password {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.btn {
  background-color: #20a258 !important;
  width: 100%;
  color: "white" !important;
  padding: 10px !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.login_signup_btn {
  background-color: #20a258 !important;
  width: 100%;
  color: "white" !important;
  padding: 10px !important;
  border-radius: 30px !important;
  text-transform: capitalize !important;
}
.login_signup_btn > span {
  color: #fff;
}

.login_signup_loader {
  color: #ffffff !important;
}

.btn_label {
  color: "white" !important;
}

.Login_Input {
  font-family: "Montserrat", sans-serif !important;
}

.Login_Input_Field {
  border-radius: 8px !important;
  width: 100% !important;
  color: #515156;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 10px !important;
  background-color: #f6f6f6 !important;
  border: 1px solid #f6f6f6 !important;
}

.Login_Input_Field_Focus {
  border: 1px solid #20a258 !important;
  color: #20a258;
  font-weight: 600;
}

.terms_of_condition {
  color: #394053;
  text-align: center;
  padding: 20px 0px;
  font-size: 15px !important;
}
.terms_of_condition span {
  color: #20a258;
}

.login_input_icons {
  color: #a2a2a2;
  font-size: 20px !important;
}

.password_visibility_icon {
  color: #515156;
  font-size: 20px !important;
  cursor: pointer;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.container {
  flex: 1 1;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  background-color: white;
  border: 1px solid black;
  border-radius: 18px;
  align-items: middle;
  margin-top: 8%;
}

.form {
  margin-top: 20px;
}

.login_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding: 0 12%;
  border-bottom: 5px solid #5cb85c;
}

.signup_btn {
  text-decoration: none;
  font-size: 17px;
  color: black;
  border-bottom: 5px solid #e0e0e0;
  padding: 0 5%;
}

.login_btn_box {
  width: 50%;
}

.form_group {
  padding-bottom: 16px;
}

.label-title {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 12px;
  color: grey;
}

.form_control {
  width: 83%;
  border: none;
  height: 2rem;
  background: #d1d1d1;
  padding: 5px 25px;
  border-radius: 5px;
}

.forgotPassword {
  color: black;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 10px;
  margin-left: 49%;
  z-index: -1;
}

.checkBoxContainerView {
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: -7px;
  z-index: -1;
}

.rememberMe {
  color: #6200ee;
  font-weight: bold;
  align-self: center;
  z-index: -1;
}

.orLabel {
  color: rgba(0, 0, 0, 0);
  font-weight: bold;
  align-self: center;
  margin: 20;
}

.login_signup_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout_wrapper {
  background-color: #ffffff;
  border-radius: 35px;
}

.forgot_password_header {
  position: relative;
}
.forgot_password_header img {
  width: 45%;
}
.forgot_password_header button {
  position: absolute;
  left: 25px;
  top: 60%;
  transform: translateY(-60%);
}
.forgot_password_header button svg {
  color: #515156;
}

.form_wrapper {
  padding: 40px;
}

.logo_wrapper {
  padding: 20px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #20a258;
}

.forgot_PW_tab_container {
  width: "100%";
  border-bottom: 5px solid #f0f0f0;
  display: flex;
  justify-content: center;
}

.forgot_PW_tab {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  border-bottom: 5px solid #20a258;
  margin-bottom: -5px !important;
  padding: 0px 20px 14px;
  font-weight: 500 !important;
  color: #000;
  line-height: normal !important;
  font-size: 22px !important;
  font-family: "AllRoundGothicW01-Book" !important;
}

.btn {
  background-color: #20a258 !important;
  width: 100%;
  color: #ffffff !important;
  padding: 10px !important;
  border-radius: 8px !important;
}
.btn span {
  color: #ffffff;
}

.btn_label {
  color: "white" !important;
}

.reset_pwd_text {
  color: #000000;
  text-align: center;
  padding-top: 21px;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif;
}
.reset_pwd_text span {
  color: #20a258;
}

.button {
  width: 100%;
  background: #5cb85c;
  color: white;
  padding: 10px 0px;
  border-radius: 5px;
  border: none;
}

.terms_of_condition {
  color: #394053;
  text-align: center;
  padding: 20px 0px !important;
  font-size: 15px !important;
}
.terms_of_condition span {
  color: #20a258;
}

.fp_content_wrapper {
  padding: 10px 50px 50px;
}
@media screen and (max-width: 600px) {
  .fp_content_wrapper {
    padding: 15px 40px 40px;
  }
}
.social_btn {
  box-shadow: none !important;
  font-size: 12.5px !important;
  padding: 8px !important;
  width: 100% !important;
  border-radius: 25px !important;
  text-transform: capitalize !important;
}
.social_btn:disabled {
  opacity: 0.6;
}
.social_btn .MuiButton-startIcon {
  margin-right: 4px;
}
.social_btn .google_btn_loader {
  color: #20a258;
}

.social_Img {
  width: 17px;
}

.btn_color_black {
  color: #000000 !important;
}

.social_btn_account {
  box-shadow: none !important;
  font-size: 17px !important;
  padding: 10px !important;
  width: 100% !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  background-color: #f6f6f6 !important;
  position: relative !important;
}
.social_btn_account .check_icon {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  color: #ffffff;
  background-color: #20a258;
  border-radius: 50%;
  padding: 2.3px;
  font-size: 17px !important;
}
.social_btn_account .MuiButton-startIcon {
  margin-right: 5px;
}
@media screen and (max-width: 1024px) {
  .social_btn_account {
    font-size: 15px !important;
  }
}

.btn_color_blue {
  color: #263c78 !important;
}

.social_Img_account {
  width: 22px;
  height: 22px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.web_header {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}
@media screen and (max-width: 1024px) {
  .web_header {
    padding: 15px;
    box-shadow: 0 4px 4px 0 rgba(21, 21, 21, 0.18);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
  }
}
.web_header .toggle_menu_icon {
  padding: 8px;
  margin-right: 8px;
}
.web_header .toggle_menu_icon img {
  height: 25px;
}
@media (min-width: 1024px) {
  .web_header .toggle_menu_icon {
    display: none;
  }
}
.web_header .web_header_logo_wrapper {
  width: 135px;
}
.web_header .web_header_logo_wrapper img {
  width: 100%;
}
@media (max-width: 600px) {
  .web_header .web_header_logo_wrapper {
    width: 120px;
    min-width: 120px;
  }
}
@media (max-width: 400px) {
  .web_header .web_header_logo_wrapper {
    width: 90px;
    min-width: 90px;
  }
}
.web_header .web_header_search_input_wrapper {
  width: 100%;
}
@media (max-width: 768px) {
  .web_header .web_header_search_input_wrapper {
    display: none;
  }
}
.web_header div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.web_header .web_header_action_icons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}
.web_header .web_header_action_icons_wrapper .web_header_icon_btn {
  padding: 6px !important;
}
.web_header .web_header_action_icons_wrapper .web_header_fav_fill {
  color: #ff0f0f;
  font-size: 28px;
}
.web_header .web_header_action_icons_wrapper .web_header_icon {
  color: #000;
  font-size: 28px;
}
@media (max-width: 600px) {
  .web_header .web_header_action_icons_wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px 5px;
  }
}
.web_header .navbar_login_btn_wrapper {
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 6px 12px;
  min-width: 75px;
  box-shadow: none;
}
.web_header .navbar_login_btn_wrapper:hover {
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: none;
}
.web_header .navbar_login_btn_wrapper span {
  color: #333;
  text-transform: capitalize;
  font-weight: 600;
}
.web_header .web_header_select :root {
  margin-right: 20px !important;
}
.web_header .web_header_select > button {
  background-color: #20a258;
  height: 15px;
  width: 15px;
  padding: 8px;
}
.web_header .web_header_select svg {
  color: #ffffff;
  transition: transform 0.3s;
}
.web_header .web_header_select .openExpandIcon {
  transform: rotate(180deg);
}
.web_header .web_header_select .MuiSelect-select.MuiSelect-select {
  padding-right: 0 !important;
  background: #20a258;
  border-radius: 50%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #fff;
}
.web_header .web_header_select .MuiInput-underline::before,
.web_header .web_header_select .MuiInput-underline::after {
  border-bottom: none !important;
  outline: none !important;
}
.web_header .web_header_profile {
  display: flex;
  justify-content: flex-end;
}
.web_header .web_header_profile img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .web_header .web_header_profile img {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 400px) {
  .web_header .web_header_profile img {
    width: 35px;
    height: 35px;
  }
}

.login_tab_container {
  cursor: pointer;
  height: 100%;
}
.login_tab_container h6 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_login_wrapper {
  padding: 5px 45px !important;
}
@media screen and (max-width: 600px) {
  .main_login_wrapper {
    padding: 15px 40px !important;
  }
}

.logo_wrapper_heading {
  padding: 0px 15px;
}
.logo_wrapper_heading img {
  width: 45%;
}

.login_sign_up_dialog {
  border-radius: 35px !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.login_sign_up_dialog::-webkit-scrollbar {
  display: none !important;
  /* Hide scrollbar for Safari and Chrome */
}

.shopping_cart_badge {
  background-color: #20a258;
  color: #fff;
  font-size: 11px !important;
  padding: 0px !important;
  height: 18px !important;
  min-width: 18px !important;
  right: 3px !important;
}

.web_header_menu_paper {
  padding: 14px;
}
.web_header_menu_paper a {
  color: #333;
}
.web_header_menu_paper li {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff !important;
  font-size: 16px;
  padding: 4px 16px;
  min-height: auto;
}
.web_header_menu_paper li:hover {
  color: #20a258;
}

.search_icon {
  width: 45px;
  height: 45px;
  background-color: #ffac0c;
  margin-left: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.search_icon svg {
  color: #ffffff;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.routesWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #f3f0f0;
}
@media (max-width: 1024px) {
  .routesWrapper {
    display: none;
  }
}
.routesWrapper a {
  width: 190px;
  padding: 15px 10px;
  -o-object-fit: contain;
     object-fit: contain;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600 !important;
  letter-spacing: 0.9px;
  color: #333;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
}

.toogle_button_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #f3f0f0;
  padding: 20px 30px;
}
.toogle_button_wrapper button {
  background-color: #20a258;
}
.toogle_button_wrapper button:hover {
  background-color: #20a258;
}

a.active_route {
  font-weight: 800;
  background-color: rgba(32, 162, 88, 0.09);
  color: #20a258 !important;
}

.web_routing_tabs_container {
  width: 90%;
}

@media (min-width: 960px) {
  .web_routing_tabs_container {
    display: flex;
    justify-content: center !important;
  }
}
@media screen and (max-width: 599px) {
  .web_routing_tab_wrapper {
    display: flex;
    justify-content: center;
  }
}
/*web routing tabs toggle sidebar*/
.web_routing_tabs_sidebar {
  padding: 20px 0;
  background-color: #ffffff;
  width: 280px;
}
.web_routing_tabs_sidebar a {
  width: 100%;
  padding: 12px 40px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #000000;
}
.web_routing_tabs_sidebar a:hover {
  background-color: rgba(32, 162, 88, 0.06);
  color: #20a258;
}

.web_routing_tabs_sidebar_toggleSidebar {
  left: 0;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.purchaseCourseWapper {
  background-color: #ffffff;
  width: 100%;
}
.purchaseCourseWapper h4 {
  margin-bottom: 15px;
}
.purchaseCourseWapper .customProgressbar {
  padding-top: 10px;
}
.purchaseCourseWapper .commonSection {
  margin-bottom: 80px;
}
.purchaseCourseWapper .purchaseCourseSection {
  padding: 30px 50px;
  box-shadow: 0px 0px 12px 0px #c1c1c1;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .purchaseCourseWapper .purchaseCourseSection {
    padding: 15px;
  }
}
.purchaseCourseWapper .subContentWrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 10px;
}
.purchaseCourseWapper .subCourseSection h2 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 45px;
}
.purchaseCourseWapper .subCourseSection h4 {
  margin-bottom: 0;
}
.purchaseCourseWapper .subCourseSection .MuiButton-label span {
  font-size: 14px;
  padding-left: 0;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.breadCrumbsrow {
  margin-bottom: 10px;
}
.breadCrumbsrow .MuiBreadcrumbs-li a {
  color: #394053;
}
.breadCrumbsrow .MuiBreadcrumbs-li .MuiTypography-body1 {
  color: #20a258;
  font-weight: 500;
}
.breadCrumbsrow .MuiBreadcrumbs-separator {
  font-size: 35px;
  margin-bottom: 5px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customChipArrayList {
  padding: 0 !important;
}
.customChipArrayList .MuiChip-root {
  width: 100%;
  justify-content: space-between;
  padding: 22px 15px;
  border-radius: 40px;
  background-color: transparent !important;
  border: 1px solid #c3c3c3;
  font-size: 17px;
  cursor: pointer;
}
.customChipArrayList .MuiChip-root:hover {
  border: 1px solid #20a258;
}
.customChipArrayList .MuiChip-root span {
  color: #5d5e6a;
  font-weight: 500;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.educationSidebar .MuiDrawer-paper {
  position: unset;
}
@media screen and (max-width: 959px) {
  .educationSidebar .drawerNav {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .educationSidebar .drawerNav {
    width: 240px;
  }
}
@media screen and (max-width: 767px) {
  .educationSidebar .drawerNav {
    flex-shrink: 0;
  }
}
@media screen and (min-width: 959px) {
  .educationSidebar .SidebarHeader {
    display: none;
  }
}
.educationSidebar .MuiDrawer-paperAnchorLeft {
  border-right: 0;
}
.educationSidebar .MuiListItem-gutters {
  padding: 0 0 10px 0 !important;
}
.educationSidebar .drawerIconXS {
  margin-right: 20px;
}
@media screen and (min-width: 1024px) {
  .educationSidebar .drawerIconXS {
    display: none;
  }
}
.educationSidebar .drawerContainer {
  background-color: red;
}

.EduCoursePlaySidebar {
  padding: 10px;
}
.EduCoursePlaySidebar .cardHeader {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .EduCoursePlaySidebar .cardHeader {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-bottom: 15px;
  }
}
.EduCoursePlaySidebar .cardHeader h6 {
  color: #59bd83;
  font-family: "Montserrat", sans-serif;
}
.EduCoursePlaySidebar .cardHeader hr {
  margin: 15px -40px 0 -40px;
}
@media screen and (max-width: 768px) {
  .EduCoursePlaySidebar .cardHeader hr {
    display: none;
  }
}
.EduCoursePlaySidebar .cardHeader .closeIcon {
  display: none;
}
@media screen and (max-width: 768px) {
  .EduCoursePlaySidebar .cardHeader .closeIcon {
    display: flex;
  }
}
.EduCoursePlaySidebar .customAccordian .MuiAccordion-root {
  padding: 0;
  border: 0;
  margin: 0;
}
.EduCoursePlaySidebar .customAccordian .MuiAccordionSummary-root {
  background-color: #f2f2f2;
  margin: 0 -15px;
  padding: 0 15px !important;
  border-bottom: 2px solid #c3c3c3;
}
@media screen and (max-width: 768px) {
  .EduCoursePlaySidebar .customAccordian .MuiAccordionSummary-root {
    margin: 0;
  }
}
.EduCoursePlaySidebar .customAccordian .topTitle {
  color: #394053;
  font-weight: 600;
  font-size: 0.95rem;
  font-family: "Montserrat", sans-serif;
}
.EduCoursePlaySidebar .customAccordian .MuiIconButton-edgeEnd {
  margin-right: auto;
}
.EduCoursePlaySidebar .customAccordian .MuiAccordionDetails-root {
  padding: 20px 0 0;
}
@media screen and (max-width: 768px) {
  .EduCoursePlaySidebar .customAccordian .navList {
    padding: 0 15px;
  }
}
.EduCoursePlaySidebar .customAccordian .navList li {
  display: flex;
  padding-bottom: 20px;
}
.EduCoursePlaySidebar .customAccordian .navList li .circleBg {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.EduCoursePlaySidebar .customAccordian .navList li .playCircle {
  background-color: #d8eee0;
}
.EduCoursePlaySidebar .customAccordian .navList li .pauseCircle {
  background-color: #ffefd3;
}
.EduCoursePlaySidebar .customAccordian .navList li h6 {
  font-weight: 500;
  padding-left: 10px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.educationFilterWapper {
  background-color: #ffffff;
  width: 100%;
  padding-top: 30px;
}
.educationFilterWapper .commonSection {
  margin-bottom: 50px;
}
.educationFilterWapper .subContentWrapper {
  max-width: 1450px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}
@media screen and (max-width: 1440px) {
  .educationFilterWapper .subContentWrapper {
    max-width: 1200px;
  }
}
@media screen and (min-width: 1025px) {
  .educationFilterWapper .filterCoursesWrapper {
    padding-left: 30px;
  }
}
.educationFilterWapper .filterCoursesWrapper .filterCoursesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .educationFilterWapper .filterCoursesWrapper .filterCoursesHeader {
    flex-direction: column;
  }
}
.educationFilterWapper .filterCoursesWrapper .filterCoursesHeader h4 {
  margin-right: 10px;
  text-align: left;
  width: 100%;
}
.educationFilterWapper .filterCoursesWrapper .filterCoursesHeader .courselist {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #c3c3c3;
  border-radius: 30px;
  padding: 5px 25px;
}
.educationFilterWapper .filterCoursesWrapper .filterCoursesHeader .courselist span {
  margin-right: 15px;
  white-space: nowrap;
}
.educationFilterWapper .filterCoursesWrapper .filterCoursesHeader .courselist .MuiSelect-select {
  padding-right: 0;
}
.educationFilterWapper .filterCoursesWrapper .filterCoursesHeader .courselist .MuiSvgIcon-root {
  display: none;
}
.educationFilterWapper .filterCoursesWrapper .filterBtnRow {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.educationFilterWapper .filterCoursesWrapper .paginationRow {
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.educationFilterWapper .farmBannerSection {
  background: url(/static/media/farm-banner.0bd93ac9.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  height: 550px;
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .educationFilterWapper .farmBannerSection {
    padding: 10px;
  }
}
.educationFilterWapper .farmBannerSection::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: -1;
}
.educationFilterWapper .farmBannerSection h1 {
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.educationFilterWapper .farmBannerSection h5 {
  margin: 30px 0;
}
.educationFilterWapper .learnSection h2 {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin-bottom: 50px;
}
.educationFilterWapper .reviewSection {
  flex-direction: row;
}
.educationFilterWapper .reviewSection .reviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.educationFilterWapper .reviewSection .reviewHeader a {
  font-weight: 500;
  font-size: 19px;
  color: #20a258;
  text-decoration: underline;
}
.educationFilterWapper .reviewSection .commentBlock h6 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
}
.educationFilterWapper .reviewSection .commentBlock .circleBtn .MuiButton-root {
  padding: 10px 60px;
}
.educationFilterWapper .filterRightBlock {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .educationFilterWapper .filterRightBlock {
    margin-top: 20px;
    width: 100%;
  }
}
.educationFilterWapper .greenOutlineGrayButton {
  display: none;
  justify-content: space-between;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .educationFilterWapper .greenOutlineGrayButton {
    display: block;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.educationCourseDetailWapper {
  background-color: #ffffff;
  width: 100%;
  padding-top: 30px;
}
.educationCourseDetailWapper .commonSection {
  margin-bottom: 70px;
}
.educationCourseDetailWapper .nav {
  padding: 20px 0 0 20px;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .nav {
    padding: 0;
  }
}
.educationCourseDetailWapper .nav li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .nav li {
    margin-bottom: 10px;
  }
}
.educationCourseDetailWapper .nav li img {
  height: 25px;
  width: 25px;
}
.educationCourseDetailWapper .nav li p {
  margin: 0;
  padding-left: 10px;
}
.educationCourseDetailWapper .subContentWrapper {
  max-width: 1450px;
  margin: 0 auto;
  padding: 10px;
}
.educationCourseDetailWapper .publicSpeakingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.educationCourseDetailWapper .publicSpeakingSection h1 {
  font-size: 40px;
  font-weight: 100;
  font-family: "AllRoundGothicW01-Medium";
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .publicSpeakingSection h1 {
    font-size: 30px;
  }
}
.educationCourseDetailWapper .publicSpeakingSection p {
  text-align: center;
  margin: 30px 0;
  font-weight: 600;
  font-size: 16px;
  opacity: 0.9;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .publicSpeakingSection p {
    font-size: 14px;
  }
}
.educationCourseDetailWapper .publicSpeakingSection .videoBlock iframe {
  width: 600px;
  height: 400px;
  border-radius: 20px;
}
@media screen and (max-width: 767px) {
  .educationCourseDetailWapper .publicSpeakingSection .videoBlock iframe {
    width: 100%;
  }
}
.educationCourseDetailWapper .enrollSection .courseCard {
  padding: 40px 25px;
}
@media screen and (max-width: 768px) {
  .educationCourseDetailWapper .enrollSection .courseCard {
    padding: 10px;
  }
}
.educationCourseDetailWapper .enrollSection .courseCard .cardContent {
  display: flex;
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  .educationCourseDetailWapper .enrollSection .courseCard .cardContent {
    flex-direction: column;
    align-items: center;
  }
}
.educationCourseDetailWapper .enrollSection .courseCard .cardContent img {
  height: 50px;
  width: 50px;
}
.educationCourseDetailWapper .enrollSection .courseCard .cardContent h3 {
  font-weight: 800;
  color: #394053;
}
@media screen and (max-width: 1440px) {
  .educationCourseDetailWapper .enrollSection .courseCard .cardContent h3 {
    font-size: 16px;
  }
}
.educationCourseDetailWapper .enrollSection .courseCard .cardContent p {
  padding-top: 0;
  font-size: 14px;
  color: #5d5e6a;
}
@media screen and (max-width: 768px) {
  .educationCourseDetailWapper .enrollSection .courseCard .cardContent p {
    font-size: 12px;
  }
}
.educationCourseDetailWapper .enrollSection .courseCard .cardContent .rightBlock {
  padding-left: 20px;
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  .educationCourseDetailWapper .enrollSection .courseCard .cardContent .rightBlock {
    padding-left: 0;
    padding-top: 20px;
    text-align: center;
  }
}
.educationCourseDetailWapper .enrollSection .greenOutlineButton {
  height: calc(100% - 30px);
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .enrollSection .greenOutlineButton {
    height: calc(100% - 10px);
  }
}
.educationCourseDetailWapper .enrollSection .greenOutlineButton .MuiButtonBase-root {
  height: 100%;
  width: 100%;
  background: transparent;
  background-image: url(/static/media/farm-banner.0bd93ac9.jpg);
  background-size: cover;
  position: relative;
  border: 0;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .enrollSection .greenOutlineButton .MuiButtonBase-root {
    padding: 50px;
  }
}
.educationCourseDetailWapper .enrollSection .greenOutlineButton .MuiButtonBase-root .MuiButton-label span {
  font-weight: 600;
  font-size: 30px;
  color: #ffffff;
  z-index: 2;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .enrollSection .greenOutlineButton .MuiButtonBase-root .MuiButton-label span {
    font-size: 18px;
  }
}
.educationCourseDetailWapper .enrollSection .greenOutlineButton .MuiButtonBase-root::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(89, 189, 131, 0.5);
  z-index: 1;
  border-radius: 8px;
}
.educationCourseDetailWapper .enrollSection .greenOutlineButton .customButton {
  height: 100%;
}
.educationCourseDetailWapper .trainerSection .trainerBlock {
  display: flex;
  justify-content: center;
}
.educationCourseDetailWapper .trainerSection .trainerImg {
  border-radius: 20px;
  border: 1px solid #757575;
  width: 400px;
  height: 600px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .trainerSection .trainerImg {
    width: 400px;
    height: 400px;
  }
}
.educationCourseDetailWapper .trainerSection .serviceImg {
  width: 500px;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 767px) {
  .educationCourseDetailWapper .trainerSection .serviceImg {
    width: 300px;
    height: 300px;
  }
}
.educationCourseDetailWapper .trainerSection .trainerRightBlock {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .educationCourseDetailWapper .trainerSection .trainerRightBlock {
    width: 100%;
  }
}
.educationCourseDetailWapper .trainerSection .trainerRightBlock h1 {
  font-family: "Montserrat", sans-serif;
  color: #0f3b18;
  font-size: 45px;
  font-weight: 100;
  line-height: 70px;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .trainerSection .trainerRightBlock h1 {
    font-size: 24px;
    line-height: 40px;
  }
}
.educationCourseDetailWapper .trainerSection .trainerRightBlock p {
  color: #0f3b18;
  font-size: 15px;
  font-weight: 100;
  margin: 20px 0;
  word-spacing: 5px;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .trainerSection .trainerRightBlock p {
    font-size: 14px;
    margin: 5px 0;
  }
}
.educationCourseDetailWapper .buyCourseSection .nav {
  display: flex;
  justify-content: center;
  padding: 0;
}
.educationCourseDetailWapper .buyCourseSection .nav li {
  width: 100%;
  padding: 10px 80px;
  margin: 10px 0;
  justify-content: flex-start;
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .buyCourseSection .nav li {
    padding: 10px;
  }
}
.educationCourseDetailWapper .buyCourseSection .nav li:nth-child(even) {
  background-color: #fff8e8;
}
.educationCourseDetailWapper .buyCourseSection .nav.priceNav li {
  justify-content: center;
}
.educationCourseDetailWapper .buyCourseSection .nav.priceNav .MuiSvgIcon-root {
  height: 0.9em;
  width: 0.9em;
}
.educationCourseDetailWapper .buyCourseSection .nav.priceNav .MuiSvgIcon-root.cancelIcon {
  color: #757575;
}
.educationCourseDetailWapper .buyCourseSection .classesCard .courseCard {
  justify-content: center;
  height: calc(100% - 156px);
}
@media screen and (max-width: 767px) {
  .educationCourseDetailWapper .buyCourseSection .classesCard .courseCard {
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .educationCourseDetailWapper .buyCourseSection .advancedCard {
    padding-left: 0px;
  }
}
.educationCourseDetailWapper .buyCourseSection .card .cardContent {
  max-height: 550px;
  border: 1px solid #c1c1c1;
}
.educationCourseDetailWapper .buyCourseSection .card .cardHeader {
  width: 100%;
  min-height: 100px;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard {
  box-shadow: none;
  height: 100%;
  padding: 0;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardHeader, .educationCourseDetailWapper .buyCourseSection .card.courseCard .cardFooter {
  width: 100%;
  text-align: center;
  border: 1px solid #c1c1c1;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardHeader h5, .educationCourseDetailWapper .buyCourseSection .card.courseCard .cardFooter h5 {
  color: #000000;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardHeader {
  padding: 50px 0;
  border-bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardHeader .priceCheckbox {
  position: absolute;
  top: 10px;
  right: 10px;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardHeader.active {
  background-color: #418c61;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardHeader.active h5 {
  color: #ffffff;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardFooter {
  padding: 20px 0;
  border-top: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardFooter .bottomContent {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 10px;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .cardFooter .bottomContent span {
  margin-right: 20px;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard.details {
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard.details .cardContent {
  margin-top: 125px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
}
@media screen and (max-width: 1024px) {
  .educationCourseDetailWapper .buyCourseSection .card.courseCard.details .cardContent {
    margin-top: 120px;
  }
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard.basicPrice {
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard.adVancePrice {
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .monthContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #418c61;
  border-radius: 10px;
  width: 80%;
  padding: 10px 20px;
  margin: 10px auto;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .monthContent .rightBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .monthContent h5, .educationCourseDetailWapper .buyCourseSection .card.courseCard .monthContent h6, .educationCourseDetailWapper .buyCourseSection .card.courseCard .monthContent span {
  color: #ffffff;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .monthContent h6 {
  font-size: 20px;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .monthContent span {
  font-size: 14px;
}
.educationCourseDetailWapper .buyCourseSection .card.courseCard .greenOutlineGrayButton .MuiButton-root {
  border: 1px solid #c3c3c3;
  background-color: transparent;
  width: 80%;
  padding: 15px 20px;
}
.educationCourseDetailWapper .studentCardSection {
  margin: 0;
  background-color: rgb(254, 250, 238);
  padding: 100px 0;
}
@media screen and (min-width: 768px) {
  .educationCourseDetailWapper .studentCardSection {
    padding: 100px 30px;
  }
}
.educationCourseDetailWapper .studentCardSection .studentCardHeader {
  width: 100%;
  margin-bottom: 50px;
}
.educationCourseDetailWapper .studentCardSection .studentCardHeader h3 {
  position: relative;
  z-index: 2;
  font-family: "AllRoundGothicW01-Medium";
  font-size: 26px;
}
.educationCourseDetailWapper .studentCardSection .studentCardHeader h3 span .MuiSvgIcon-root {
  font-size: 90px;
  color: #ec9e31;
  transform: rotate(180deg);
  position: absolute;
  top: -45px;
  left: -47px;
  z-index: -1;
}
.educationCourseDetailWapper .studentCardSection .cardContainer .MuiGrid-item {
  padding: 6px 30px;
}
@media screen and (min-width: 768px) {
  .educationCourseDetailWapper .studentCardSection .cardContainer .MuiGrid-item:nth-child(2) {
    margin-top: 10px;
  }
  .educationCourseDetailWapper .studentCardSection .cardContainer .MuiGrid-item:nth-child(5) {
    margin-top: 10px;
  }
}

#input-with-searchIcon-textfield::-moz-placeholder {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #9098b1;
}

#input-with-searchIcon-textfield:-ms-input-placeholder {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #9098b1;
}

#input-with-searchIcon-textfield::placeholder {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #9098b1;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customAccordian .MuiAccordion-root {
  margin-bottom: 20px;
  padding: 10px 20px;
  box-shadow: none;
  border: 1px solid #c1c1c1;
  border-radius: 10px !important;
}
@media screen and (max-width: 1024px) {
  .customAccordian .MuiAccordion-root {
    padding: 10px;
  }
}
.customAccordian .topTitle {
  color: #20a258;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1024px) {
  .customAccordian .topTitle {
    font-size: 1rem;
  }
}
.customAccordian .subTitle {
  padding-left: 30px;
  color: #000000;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 1024px) {
  .customAccordian .subTitle {
    font-size: 1rem;
  }
}
.customAccordian p {
  font-size: 16px;
}
@media screen and (max-width: 1024px) {
  .customAccordian p {
    font-size: 14px;
  }
}
.customAccordian .AccordionSummary .MuiSvgIcon-root {
  display: none;
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon {
  background-color: #ec9e31;
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon:hover {
  background-color: #ec9e31;
}
.customAccordian .AccordionSummary .MuiIconButton-label {
  position: relative;
}
.customAccordian .AccordionSummary .MuiIconButton-label::before, .customAccordian .AccordionSummary .MuiIconButton-label::after {
  content: " ";
  position: absolute;
  height: 2px;
  width: 12px;
  background-color: #ffffff;
}
.customAccordian .AccordionSummary .MuiIconButton-label::after {
  transform: rotate(90deg);
  background-color: #ffffff;
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg);
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon.Mui-expanded .MuiIconButton-label::after {
  display: none;
}
.customAccordian .AccordionDetails {
  display: flex;
  flex-direction: column;
}
.customAccordian .AccordionDetails h6 {
  margin: 10px 0;
  font-weight: 600;
  color: #000000;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.eduCoursePlayWapper {
  background-color: #ffffff;
  width: 100%;
  padding-top: 30px;
}
.eduCoursePlayWapper .commonSection {
  margin-bottom: 50px;
}
.eduCoursePlayWapper .subContentWrapper {
  max-width: 1450px;
  margin: 0 auto;
  padding: 10px;
}
.eduCoursePlayWapper .eduCourseContentWrapper {
  padding: 10px;
}
.eduCoursePlayWapper .eduCourseContentWrapper .filterBtn {
  display: none;
}
@media screen and (max-width: 768px) {
  .eduCoursePlayWapper .eduCourseContentWrapper .filterBtn {
    display: flex;
  }
}
.eduCoursePlayWapper .videoPlayerContainer {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.eduCoursePlayWapper .overViewConatiner {
  margin-top: 50px;
}
.eduCoursePlayWapper .overViewConatiner h3 {
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  text-align: left;
  padding-bottom: 30px;
  border-bottom: 1px solid #bababa;
}
.eduCoursePlayWapper .overViewConatiner .overViewDetails {
  width: 100%;
}
.eduCoursePlayWapper .overViewConatiner .overViewDetails p {
  width: 100%;
  text-align: justify;
  margin: 20px 0;
}
.controlContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 20px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
@media screen and (max-width: 768px) {
  .controlContainer {
    padding: 5px 20px;
  }
}
.controlContainer .playPauseContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 20%;
}
.controlContainer .playPauseContainer .MuiSvgIcon-root:hover {
  cursor: pointer;
}
.controlContainer .playPauseContainer .MuiSvgIcon-root:nth-child(2) {
  background-color: green;
  padding: 10px;
  border-radius: 50%;
}
@media screen and (max-width: 768px) {
  .controlContainer .playPauseContainer .MuiSvgIcon-root {
    font-size: 15px;
  }
}
.controlContainer .sliderContainer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .controlContainer .sliderContainer .MuiSvgIcon-root {
    font-size: 15px;
  }
}
.controlContainer .sliderContainer span {
  color: white;
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .controlContainer .sliderContainer span {
    font-size: 8px;
  }
}
.controlContainer .loopIconContainer {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.controlContainer .loopIconContainer .volumeContainer {
  position: relative;
}
.controlContainer .loopIconContainer .volumeContainer .sliderContainer {
  position: absolute;
  height: 100%;
  background-color: rgba(204, 195, 195, 0.4);
  top: -50px;
  left: 7px;
  padding: 10px 0px;
  display: none;
}
.controlContainer .loopIconContainer .volumeContainer:hover .sliderContainer {
  display: block;
}
@media screen and (max-width: 768px) {
  .controlContainer .loopIconContainer .MuiSvgIcon-root {
    font-size: 15px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.mainHeader {
  margin-bottom: 2px;
}
.mainHeader.farmHeader {
  box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.75);
}
.mainHeader .heading {
  border-left: 2px solid #20a258;
  padding-left: 20px;
}
.mainHeader .heading h5 {
  font-family: "AllRoundGothicW01-Medium";
  font-weight: 700;
}
.mainHeader .courseProgress {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}
.mainHeader .courseProgress p {
  font-weight: 600;
}
.mainHeader .courseProgress .progressBarContaine {
  position: relative;
}
.mainHeader .courseProgress .progressBarContaine .MuiCircularProgress-root {
  color: #20a258 !important;
}
.mainHeader .courseProgress .progressBarContaine .progresValue {
  position: absolute;
  top: 30%;
  left: 30%;
}
.mainHeader .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}
.mainHeader .rating p {
  font-weight: 600;
}
.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}

.Normal_User_Regitration_Form_Wrapper {
  box-shadow: 0px 0px 15px 0px #bababa;
  padding: 40px;
  border-radius: 20px;
}

.Normal_User_Regitration_Input_Field {
  width: 100%;
  border-radius: 8px !important;
  color: #515156 !important;
  font-size: 15px !important;
}

.Normal_User_Regitration_Input {
  padding: 15px !important;
}

.Normal_User_Regitration_Input_Focused {
  border-color: #20a258 !important;
}

.Normal_User_Regitration_Input_Label {
  color: #bababa;
  padding: 0px 0px 10px;
  font-size: 15px !important;
}

.create-account-heading {
  text-align: center;
  font-size: 30px !important;
  color: #394053;
  font-weight: 600 !important;
}

.create_account_btn-text {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize !important;
}

.create_account_btn {
  background-color: #20a258 !important;
  width: 100% !important;
  padding: 16px !important;
  margin-top: 20px !important;
}

.anable_create_account_arrows {
  background-color: #263c78;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.disable_create_account_arrows {
  background-color: #c3c3c3;
  border-radius: 5px;
  padding: 10px 12px;
  color: #ffffff !important;
}

.login_signup_tabs_wrapper {
  display: flex;
  align-items: flex-end;
}
.login_signup_tabs_wrapper a {
  width: 100%;
}
.topbar_container {
  background: #ffffff;
  border-bottom: 3px solid #f0f0f0;
}

.topbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
}
.topbar-wrapper > div:first-child {
  cursor: pointer;
  width: 150px;
}
@media (max-width: 600px) {
  .topbar-wrapper > div:first-child {
    width: 130px;
    min-width: 130px;
  }
}
@media (max-width: 400px) {
  .topbar-wrapper > div:first-child {
    width: 110px;
    min-width: 110px;
  }
}

.question_wrapper {
  width: 40px;
  height: 40px;
  background-color: #ec9e31;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
  cursor: pointer;
}
.all_white_background_wrapper {
  background-color: white !important;
  width: 100%;
  padding-top: 25px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.all_white_background_wrapper .create_profile_container {
  width: 100% !important;
}
@media screen and (max-width: 767px) {
  .all_white_background_wrapper .create_role_profile_form_container {
    padding: 10px;
  }
}
.all_white_background_wrapper .create_role_profile_form_container .main-header {
  padding: 20px;
}
.all_white_background_wrapper .create_role_profile_form_container .headerLearning {
  margin: 25px -28px;
  padding: 20px 30px;
}
@media screen and (max-width: 767px) {
  .all_white_background_wrapper .create_role_profile_form_container .headerLearning {
    margin: 10px -12px;
    padding: 15px;
  }
}
.all_white_background_wrapper .create_role_profile_form_container .web_input_field {
  margin: 5px 10px 10px 0;
}
@media screen and (max-width: 767px) {
  .all_white_background_wrapper .create_role_profile_form_container .web_input_field {
    margin: 0;
  }
}
.all_white_background_wrapper .create_role_profile_form_container .web_input_field input {
  color: rgba(0, 0, 0, 0.831372549);
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.Select_Role_Container {
  height: 100% !important;
  width: 100%;
  background: #ffffff !important;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 22px;
}

.select_role_loader_container {
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select_role_loader_container svg {
  color: #20a258;
}

.select_role_blank_container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select_your_role {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 70px 10px 60px;
  padding: 0px 20px;
}

.select_your_role_heading {
  padding-bottom: 10px !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #000 !important;
}

.select_your_role_text {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  text-align: center !important;
  color: #000 !important;
}

.role_container {
  height: 250px;
  text-align: center;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 20px;
  color: #20a258 !important;
  position: relative;
  cursor: pointer;
}
.role_container .selected_role_container {
  position: absolute;
  top: 15px;
  right: 15px;
}
.role_container .selected_role_container svg {
  color: #ffac0c;
}
.role_container .role_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.role_container .role_content img {
  height: 100px;
}

.selected_role {
  border: 1px solid #20a258;
  background-color: rgba(25, 160, 83, 0.04);
}

.role_card_title {
  font-size: 22.1px !important;
  font-weight: 700 !important;
  color: #19a053;
  text-align: center !important;
  font-family: "Poppins", sans-serif !important;
}

.select_role_move_button {
  background-color: #263c78 !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 6px 0px !important;
  min-width: 40px !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
}
.select_role_move_button span {
  color: #fff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 5px 15px !important;
}

.select_role_move_disabled {
  background-color: #c3c3c3 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.done_container {
  position: absolute;
  width: 100%;
  height: 110%;
  padding: 0 25px 4px 25px !important;
  background-color: #fff;
  text-align: center;
}

.done_container_wrapper {
  padding: 50px 30px;
  background: #fff;
  border-radius: 14px;
}

.Farmars-Profile-cre {
  font-family: "Poppins", sans-serif !important;
  font-size: 24px !important;
  font-weight: bold !important;
  margin: 14px 0 0 0 !important;
  color: #000;
}

.Now-you-can-go-to-da {
  font-family: "Poppins", sans-serif !important;
  font-size: 22px;
  color: #000;
  margin-bottom: 10px;
}

.Add-another-Role {
  border-radius: 9px !important;
  padding: 12px 25px !important;
  background-color: #20a258 !important;
  border: solid 1px #20a258 !important;
  width: 100% !important;
  text-transform: none !important;
}
.Add-another-Role span {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.Go-To-Dashboard {
  border-radius: 9px !important;
  padding: 12px 25px !important;
  border: solid 1px #20a258 !important;
  background-color: rgba(32, 162, 88, 0.04) !important;
  width: 100% !important;
  text-transform: none !important;
}
.Go-To-Dashboard span {
  color: #20a258;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.setting_layout_container {
  background-color: #ffffff;
}

.setting_layout_wrapper {
  border-radius: 10px;
  margin: 50px auto;
  background-color: #fff;
  box-shadow: 0 2px 18px 4px rgba(176, 176, 176, 0.22);
  -moz-box-shadow: 0 2px 18px 4px rgba(176, 176, 176, 0.22);
  -webkit-box-shadow: 0 2px 18px 4px rgba(176, 176, 176, 0.22);
  -o-box-shadow: 0 2px 18px 4px rgba(176, 176, 176, 0.22);
}
@media screen and (max-width: 960px) {
  .setting_layout_wrapper {
    margin: 10px 0px 30px;
  }
}

.web_sidenav {
  border-right: 2px solid #c3c3c3;
}
@media screen and (max-width: 960px) {
  .web_sidenav {
    border: none;
  }
}
.breadcrumb_component {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  font-weight: 600 !important;
}
@media screen and (max-width: 768px) {
  .breadcrumb_component {
    display: none;
  }
}

.breadcrumb__link {
  color: #000000;
}
.breadcrumb__link:hover {
  color: #000000;
}

.breadcrumb_active_link {
  color: #20a258;
}
.breadcrumb_active_link:hover {
  color: #20a258;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.web_sidebar {
  padding: 20px;
}
@media screen and (max-width: 960px) {
  .web_sidebar {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    overflow-x: auto;
    border-bottom: 2px solid #eee;
  }
  .web_sidebar::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  .web_sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  .web_sidebar::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 8px;
  }
  .web_sidebar::-webkit-scrollbar-thumb:hover {
    background: #d3d3d3;
  }
}

.web_sidebar_list_item {
  color: #000000;
  text-align: center;
  padding: 15px;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .web_sidebar_list_item {
    padding: 0;
  }
}

.account_setting_item {
  border: none !important;
}

.web_sidebar_link {
  color: #000000;
  width: 100% !important;
  font-family: "Montserrat", sans-serif;
}
.web_sidebar_link:hover {
  color: #20a258;
}
@media screen and (max-width: 960px) {
  .web_sidebar_link {
    padding: 15px 18px !important;
    min-width: 170px;
    font-weight: 500;
  }
}

.separate {
  border-bottom: solid 1px #dfe8e1 !important;
  padding-bottom: 15px !important;
}
@media screen and (max-width: 960px) {
  .separate {
    border: none !important;
    padding: 0 !important;
  }
}

.web_sidebar_active_link {
  display: block;
  background-color: #20a258;
  border-radius: 50px;
  padding: 15px 0px !important;
  color: #ffffff;
  width: 205px !important;
}
.web_sidebar_active_link:hover {
  color: #ffffff;
}
@media screen and (max-width: 960px) {
  .web_sidebar_active_link {
    border-radius: 0;
    color: #000000;
    background-color: #fff;
    padding: 15px 18px !important;
    min-width: 170px !important;
    border-bottom: 3px solid #20a258 !important;
    font-weight: 600 !important;
    z-index: 1;
  }
  .web_sidebar_active_link:hover {
    color: #000000;
  }
}

.web_sidebar_active_link .web_sidebar_list_item {
  border-bottom: none;
}

.logout_btn_wrapper {
  margin-top: 100px;
  padding: 20px;
}
@media screen and (max-width: 960px) {
  .logout_btn_wrapper {
    display: none;
  }
}

.logout_btn {
  background: #ed5959 !important;
  border-radius: 50px !important;
  color: white !important;
  text-transform: capitalize !important;
  padding: 15px 80px !important;
}
.logout_btn span {
  color: #fff;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.account_details_heading_wrapper {
  width: 100%;
  background-color: #f3f0f0;
  padding: 10px;
  border-radius: 6px;
}
.account_details_heading_wrapper h5 {
  color: black;
  font-weight: 600;
  font-size: 18px;
  margin-left: 15px;
}

.account_setting_save_btn {
  background: #20a258 !important;
  border-radius: 50px !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  padding: 12px 0px !important;
  box-shadow: none !important;
  width: 100%;
}
.account_setting_save_btn span {
  color: #fff;
}

.account_setting_save_btn_color {
  color: white !important;
}

.account_setting_changePWD_btn {
  background: white !important;
  border-radius: 24px !important;
  color: black !important;
  text-transform: capitalize !important;
  padding: 10px 0px !important;
  border: 1px solid #20a258 !important;
  font-weight: 900 !important;
  box-shadow: none !important;
  width: 100% !important;
}

.not_connected_s {
  color: black !important;
  font-size: 16px !important;
  line-height: normal !important;
}

.account_setting_role_card_grid {
  display: grid;
}

.account_setting_role_card_wrapper {
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 170px;
}
.account_setting_role_card_wrapper img {
  height: 100px !important;
}
.account_setting_role_card_wrapper p {
  color: #20a258;
  font-weight: bold;
}

.para {
  font-size: 16px;
}

.as_profile_pic_container {
  padding: 5px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.as_profile_heading {
  font-size: 16px !important;
  color: #3a3a3a !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  text-align: center;
  margin-bottom: 20px !important;
}

.as_profile_wrapper {
  position: relative;
}
.as_profile_wrapper img {
  border-radius: 50%;
  width: 120px !important;
  position: initial !important;
}
.as_profile_wrapper .as_profile_edit {
  position: absolute;
  right: 5px;
  top: 20px;
  background-color: #20a258 !important;
  transform: translateY(-50%);
  height: 30px !important;
  width: 30px !important;
}
.as_profile_wrapper .as_profile_edit:hover {
  background-color: #20a258;
}
.as_profile_wrapper .as_profile_edit svg {
  color: #ffffff;
  font-size: 18px !important;
}

.cp_field_container {
  padding: 10px 40px 30px 40px;
}

.update_password_header {
  position: relative;
}
.update_password_header img {
  width: 45%;
}
.update_password_header button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.update_password_header button svg {
  color: #515156;
}
.web_sidebar_link_view {
  padding: 20px 40px 40px;
}

.web_sidebar_link_view_header {
  border-bottom: 2px solid #dfe8e1;
  padding: 10px 0px 20px !important;
  font-size: 25px !important;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.web_sidebar_link_view_header h5 {
  font-size: 22px !important;
  font-weight: 500;
  text-transform: capitalize;
}

.wishlist_card_wrapper {
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
}

.product_name {
  padding: 5px 0px;
  font-size: 18px !important;
  font-weight: 600;
  text-transform: capitalize;
  color: #000000 !important;
}

.product_grey_text {
  padding: 5px 0px;
  font-size: 16px !important;
  font-weight: 500;
  color: #808080;
  text-transform: capitalize;
}
.product_grey_text span {
  color: #808080;
  text-transform: capitalize;
}

.view_addresses_icon_btn {
  background-color: #f0f0f0 !important;
  margin: 5px !important;
}

.product_price {
  color: #20a258;
  font-weight: 600;
  font-size: 20px;
  padding: 5px 0px;
}

.product-review-bar {
  display: flex;
}

.review-star {
  padding-right: 5px;
}

.btn-buy-now {
  width: 100%;
  background-color: #20a258 !important;
  color: #fff !important;
  border-radius: 20px !important;
  margin: 10px 0px !important;
  padding: 5px 20px !important;
  text-transform: capitalize !important;
}

.btn-ord-now {
  width: 100%;
  color: #20a258 !important;
  border: 1px solid #20a258 !important;
  border-radius: 20px !important;
  text-transform: capitalize !important;
  padding: 5px 20px !important;
}

.product_image_center {
  padding: 10px;
}

.product_image_wrapper {
  height: 100px;
}
.product_image_wrapper img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  -o-object-fit: fill;
     object-fit: fill;
}

@media (max-width: 599px) {
  .product_image_center {
    display: flex;
    justify-content: center;
  }
  .product_image_wrapper {
    display: flex;
    justify-content: center;
    height: 200px;
  }
  .product_image_wrapper img {
    -o-object-fit: fill;
       object-fit: fill;
  }
}
@media (max-width: 420px) {
  .web_sidebar_link_view_header {
    flex-direction: column;
  }
}
.cwp_courses_container {
  border-radius: 6px;
  border: solid 2px #f7f5f9;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  padding: 20px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.wcb_img_loader {
  color: #20a258 !important;
}

.wcb_item_details {
  display: flex;
  flex-direction: column;
  grid-gap: 35px;
  gap: 35px;
}
.wcb_item_details h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.wcb_item_details h3:hover {
  opacity: 0.8;
}
.wcb_item_details p {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: bold;
  color: #20a258;
}

.wcc_content_container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
}
.wcc_content_container .wcc_corse_details h3,
.wcc_content_container .wcc_corse_details p {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
}
.wcc_content_container .wcc_corse_details h3 {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 4px;
  cursor: pointer;
}
.wcc_content_container .wcc_corse_details h3:hover {
  opacity: 0.8;
}
.wcc_content_container .wcc_corse_details p {
  font-size: 14px;
  font-weight: 500;
  color: #5a5a5a;
}
.wcc_content_container > p {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: bold;
  color: #20a258;
}

.wcc_action_container {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  height: 100%;
}

.wcb_actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  grid-gap: 15px;
  gap: 15px;
  height: 100%;
}
@media screen and (max-width: 599px) {
  .wcb_actions {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
  }
}
.wcb_actions .wcb_quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f5f5;
  border-radius: 26px;
  grid-gap: 15px;
  gap: 15px;
}
.wcb_actions .wcb_quantity button {
  border: none;
  background-color: transparent;
  height: 34px;
  width: 34px;
  font-size: 18px;
  font-weight: bold;
  color: #264653;
  border-radius: 50%;
}
.wcb_actions .wcb_quantity button:disabled {
  cursor: default;
}
.wcb_actions .wcb_quantity span {
  font-size: 15px;
  font-weight: bold;
  color: #264653;
}
.wcb_actions .wcb_quantity button:last-child {
  height: 35px;
  width: 35px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
}
.wcb_actions .wcb_actions_bottom {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
@media screen and (max-width: 599px) {
  .wcb_actions .wcb_actions_bottom {
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
  }
}
.wcb_actions .wcb_actions_bottom .wcb_fav_icon {
  background-color: #f7f7f7;
  padding: 6px;
}
.wcb_actions .wcb_actions_bottom .wcb_fav_icon svg {
  font-size: 20px;
  color: #ec5959;
}
.wcb_actions .wcb_actions_bottom .wcb_cart_btn {
  padding: 6px 25px;
  border-radius: 24px;
  background-color: #20a258;
  text-transform: none;
}
.wcb_actions .wcb_actions_bottom .wcb_cart_btn span {
  color: #fff;
}

.wcb_quantity_container {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 960px) {
  .wcb_quantity_container {
    justify-content: flex-start;
  }
}
.wcb_quantity_container .wcb_quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f5f5;
  border-radius: 26px;
  grid-gap: 15px;
  gap: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.wcb_quantity_container .wcb_quantity button {
  border: none;
  background-color: transparent;
  height: 34px;
  width: 34px;
  font-size: 18px;
  font-weight: bold;
  color: #264653;
  border-radius: 50%;
}
.wcb_quantity_container .wcb_quantity button:disabled {
  cursor: default;
}
.wcb_quantity_container .wcb_quantity span {
  font-size: 15px;
  font-weight: bold;
  color: #264653;
}
.wcb_quantity_container .wcb_quantity button:last-child {
  height: 35px;
  width: 35px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
}

.wcb_fav_icon {
  background-color: #f7f7f7 !important;
  padding: 8px !important;
}
.wcb_fav_icon svg {
  font-size: 20px;
  color: #ec5959;
}

.wcb_cart_btn {
  padding: 6px 20px !important;
  border-radius: 24px !important;
  background-color: #20a258 !important;
  text-transform: none;
  width: 100%;
}
.wcb_cart_btn span {
  color: #fff;
}

.wcb_add_cart_loader {
  margin: 2px 15px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.web_sidebar_link_view_header h5 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  color: #000 !important;
}

.track_order_btn {
  background-color: #ffa700 !important;
  border-radius: 50px !important;
  padding: 8px 18px !important;
  font-weight: bolder !important;
}

.history_invoice_btn {
  background-color: #f3f0f0 !important;
  border-radius: 50px !important;
  padding: 8px 18px !important;
  font-weight: bolder !important;
}
.history_invoice_btn span {
  color: #000 !important;
}

.od_course_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.wishlist_card_wrapper {
  margin-bottom: 5% !important;
}

.product_name {
  font-weight: 900 !important;
  font-size: 18px !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order_history_product_desc {
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #5a5a5a;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.order_history_product_desc span {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #5a5a5a;
}

.order_history_product_price {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #179d4e;
  margin-top: 15px !important;
}

.btn_tab_ordrhstry {
  text-transform: capitalize !important;
  background-color: #f3f0f0 !important;
  margin: 10px !important;
  border-radius: 20px !important;
}
.btn_tab_ordrhstry span {
  color: #000 !important;
  font-weight: 500 !important;
  padding: 0 10px !important;
}

.track_order_details_link {
  margin: 0px 0px !important;
}
.track_order_details_link .store_name {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 900 !important;
  color: #000;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.track_order_details_link .order_number {
  font-size: 14px;
  color: #000;
  font-weight: lighter !important;
  word-break: break-word;
}
.track_order_details_link .order_link {
  color: #20a258;
  text-decoration: underline;
  border: none;
  background-color: transparent;
}
.track_order_details_link .order_link_wrapper {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  width: 100%;
}

.order_history_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
}
.order_history_loader_container .order_history_loader {
  color: #20a258;
}

.order_history_blank_container {
  height: 25vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 20px !important;
  padding: 10px 15px;
}
.order_history_blank_container p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.course_od_blank_container {
  border: none !important;
  margin-bottom: 0px !important;
}

.od_action_btn {
  background-color: #f3f3f3 !important;
}
.od_action_btn span {
  color: #3a3a3a !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.od_dialog_main_wrapper {
  padding: 0px 50px 50px !important;
  position: relative;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
@media screen and (max-width: 600px) {
  .od_dialog_main_wrapper {
    padding: 0px 30px 30px !important;
  }
}
@media screen and (max-width: 300px) {
  .od_dialog_main_wrapper {
    padding: 0px 20px 20px !important;
  }
}

.od_dialog_main_wrapper::-webkit-scrollbar {
  display: none !important;
}

.od_dialog_print_wrapper {
  padding: 20px 50px 50px !important;
}

.od_loader_container {
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.od_loader_container .od_loader {
  color: #20a258;
}

.od_cancel_order_wrapper {
  display: flex;
  justify-content: flex-end;
}
.od_cancel_order_wrapper button {
  color: #e74747;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 17px;
  font-weight: 600;
}

.od_status {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
  margin-bottom: 2px;
}

.od_main_heading {
  font-family: "Poppins", sans-serif !important;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #3a3a3a;
  text-align: center;
  border-bottom: 5px solid #20a258;
  margin-bottom: -5px !important;
  padding: 0px 30px;
}
@media screen and (max-width: 600px) {
  .od_main_heading {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 300px) {
  .od_main_heading {
    font-size: 18px !important;
  }
}

.od_subheading {
  width: 100%;
}
.od_subheading p {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #3a3a3a;
  margin: 10px 0px;
}
@media screen and (max-width: 600px) {
  .od_subheading p {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 300px) {
  .od_subheading p {
    font-size: 14px !important;
  }
}

.od_container {
  border: 2px solid #d3c8cc;
  border-radius: 20px;
  padding: 0px !important;
}

.od_details_container {
  padding: 20px !important;
}
@media screen and (max-width: 600px) {
  .od_details_container {
    padding: 14px !important;
  }
}
@media screen and (max-width: 300px) {
  .od_details_container {
    padding: 10px !important;
  }
}

.od_light_paragraph {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  font-weight: 500;
  color: #8b8b8b;
}
@media screen and (max-width: 600px) {
  .od_light_paragraph {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 300px) {
  .od_light_paragraph {
    font-size: 12px !important;
  }
}

.od_light_dark_paragraph {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  font-weight: 500;
  color: #484848;
}
@media screen and (max-width: 600px) {
  .od_light_dark_paragraph {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 300px) {
  .od_light_dark_paragraph {
    font-size: 12px !important;
  }
}

.od_dark_paragraph {
  font-family: "Poppins", sans-serif !important;
  font-size: 20px !important;
  font-weight: 500;
  color: #000;
}
@media screen and (max-width: 600px) {
  .od_dark_paragraph {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 300px) {
  .od_dark_paragraph {
    font-size: 14px !important;
  }
}

.od_total_paragraph {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000;
}
@media screen and (max-width: 600px) {
  .od_total_paragraph {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 300px) {
  .od_total_paragraph {
    font-size: 12px !important;
  }
}

.od_download_invoice {
  border-top: 2px solid #d3c8cc !important;
  border-radius: 0px !important;
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  grid-gap: 5px !important;
  gap: 5px !important;
  text-transform: none !important;
  padding: 8px 20px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.od_download_invoice:hover {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.od_download_invoice p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.od_download_invoice img {
  height: 22px;
  width: 22px;
}

.od_border_top {
  border-top: 2px solid #d3c8cc !important;
}

.od_dialog_close {
  position: absolute !important;
  top: 12px;
  right: 12px;
}
.od_dialog_close svg {
  color: #000;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.ot_step_img_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ot_step_img_wrapper .ot_step_img {
  height: 40px;
  width: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

.ot_main_button {
  width: 100% !important;
  border-radius: 10px !important;
  background-color: #20a258 !important;
  padding: 8px 0px !important;
}
.ot_main_button span {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.sr {
  padding: 0px !important;
  padding-bottom: 25px !important;
}

.phone_number_input {
  padding: 12px 10px 12px 0px !important;
}

.address_phone_number_prefix {
  color: rgba(0, 0, 0, 0.35) !important;
  font-weight: 600 !important;
  padding-right: 5px !important;
  border-right: 2px solid rgba(0, 0, 0, 0.35) !important;
}

.value_contain_phone_prefix {
  color: #000 !important;
}

.Login_Input_Field_Focus .address_phone_number_prefix {
  color: #20a258 !important;
  border-right: 2px solid #20a258 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.view_addresses_loader {
  display: flex;
  width: 100%;
  min-height: 400px;
  justify-content: center;
  align-items: center;
}
.view_addresses_loader svg {
  color: #20a258;
}

.product_grey_text {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
}

.add_address {
  background-color: #20a258 !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  padding: 8px 20px !important;
}

.address_action_container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.addresses_blank_container {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;
}
.addresses_blank_container p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.add_address_form_wrapper {
  padding: 5px 60px 35px;
}

.add_address_modal_container .modal_box {
  width: 100%;
  height: 100%;
}
.grass_banner {
  background: url(/static/media/page-header-bg@2x.7f0820d1.png) no-repeat;
  background-size: cover;
  padding: 60px;
  height: 260px;
  display: flex;
  align-items: center;
}

.green_top_banner_text_wrapper {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.green_top_banner_text_wrapper h3 {
  font-size: 40px;
  font-weight: 800;
  color: white;
  text-transform: uppercase;
  margin-top: 10px !important;
  margin-bottom: 6px !important;
}
.green_top_banner_text_wrapper span {
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width: 648px) {
  .breadFont h3 {
    font-size: 30px;
  }
  .breadFont span {
    font-size: 12px;
  }
}
@media screen and (max-width: 520px) {
  .breadFont h3 {
    font-size: 20px;
  }
  .breadFont span {
    font-size: 10px;
  }
}
@media screen and (max-width: 390px) {
  .breadFont h3 {
    font-size: 15px;
  }
  .breadFont span {
    font-size: 8px;
  }
}
@media screen and (max-width: 320px) {
  .breadFont h3 {
    font-size: 11px;
  }
  .breadFont span {
    font-size: 8px;
  }
}

.Leave_icon {
  color: white;
  font-size: 25px !important;
}

.bullet {
  color: white;
  font-size: 11px !important;
  margin-left: 8px;
  margin-right: 8px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.about_section_wrapper {
  background-color: white !important;
  height: auto;
  padding-top: 20px !important;
}

.aboutUsHeading h3 {
  font-size: 38px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .aboutUsHeading h3 {
    font-size: 18px;
  }
}

.team_section_wrapper {
  padding-top: 20px;
  padding-bottom: 60px;
}

.aboutus_ourteam_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.green_small_heading {
  color: #20a258;
  font-weight: bold;
  font-size: 16px;
}

.aboutus_tabs {
  width: 65%;
  display: flex;
  border-bottom: 3px solid #20a258;
}
.aboutus_tabs span {
  width: 33.33%;
  color: black;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
}

.first_vegi_img_wrapper {
  width: 100%;
}
.first_vegi_img_wrapper img {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.vision_wrapper {
  width: 90%;
}
@media screen and (max-width: 600px) {
  .vision_wrapper {
    width: 100%;
  }
}

.heading_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heading_wrapper h3 {
  color: #333;
  line-height: 1.68;
  font-size: 30px;
  text-align: center;
}

.about_us_our_team_cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about_us_our_team_cards .formerImg {
  width: 300px;
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
}

.team_card_content {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.team_card_content p {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .team_card_content p {
    font-size: 12px;
  }
}
.team_card_content h3 {
  color: #20a258;
  font-size: 21px;
  font-weight: 800;
}
@media screen and (max-width: 768px) {
  .team_card_content h3 {
    font-size: 15px;
  }
}

.service_section_wrapper {
  background-color: #ec9e31;
  padding-top: 40px;
  padding-bottom: 40px;
}

.service_section_card_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.service_section_card_wrapper h1,
.service_section_card_wrapper p {
  color: white;
}
.service_section_card_wrapper h1 {
  font-weight: 600;
}
.service_section_card_wrapper p {
  margin-left: 20px;
  text-transform: uppercase;
}

.achivement_batch_section_wrapper {
  padding-bottom: 70px;
  padding-top: 70px;
  background-color: #ffffff;
}

.batch_img_wrapper img {
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (max-width: 767px) {
  .batch_img_wrapper img {
    width: 45px;
    height: 45px;
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.aboutus_testimonial_section {
  background: url(/static/media/group-10@2x.234b2feb.png) no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;
  min-width: none;
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (min-width: 1125px) {
  .aboutus_testimonial_section {
    background-position: 100% 100%;
    position: relative;
  }
  .aboutus_testimonial_section .testimonial_old_home {
    position: absolute;
    width: 194.2px;
    height: 181px;
    -o-object-fit: contain;
       object-fit: contain;
    left: 0;
    bottom: 0;
  }
}
.aboutus_testimonial_section .aboutus_testimonial_card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  background-color: white;
  width: 90%;
  padding: 50px 40px 25px 45px;
  border-top-left-radius: 80px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .aboutus_testimonial_section .aboutus_testimonial_card {
    width: 100%;
  }
}
.aboutus_testimonial_section .testimonial_content {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  padding: 10px 0px;
  align-items: center;
}
.aboutus_testimonial_section .testimonial_content img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.aboutus_testimonial_section .testimonial_content div {
  width: 100%;
  padding-right: 35px;
}
.aboutus_testimonial_section .testimonial_content div h6 {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 4px;
  color: #333;
  font-family: "Montserrat", sans-serif;
}
.aboutus_testimonial_section .testimonial_content div p {
  font-size: 15px;
  color: #333;
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  word-wrap: break-word;
}
.aboutus_testimonial_section .comma_icon_wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -30px;
}
@media screen and (max-width: 1024px) {
  .aboutus_testimonial_section .comma_icon_wrapper {
    margin-top: 4px;
  }
}
@media screen and (max-width: 600px) {
  .aboutus_testimonial_section .comma_icon_wrapper {
    margin-top: -30px;
  }
}
.aboutus_testimonial_section .comma_icon_wrapper img {
  width: 50px;
}
.aboutus_testimonial_section .testimonial_text_wrapper {
  max-height: 140px;
  overflow-y: scroll;
  padding-right: 2px;
}
.aboutus_testimonial_section .testimonial_text_wrapper::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.aboutus_testimonial_section .testimonial_text_wrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.aboutus_testimonial_section .testimonial_text_wrapper::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.aboutus_testimonial_section .testimonial_text_wrapper .testimonial_text {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.72;
  color: #333232;
}

.aboutHeading {
  font-family: "Montserrat", sans-serif;
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  color: #61bd88;
}
@media screen and (max-width: 768px) {
  .aboutHeading {
    font-size: 25px;
  }
}

.farm_love {
  font-family: "AllRoundGothicW01-Book";
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: normal;
  color: #333;
}
@media screen and (max-width: 768px) {
  .farm_love {
    font-size: 28px;
  }
}

.visionPara {
  width: 90% !important;
}
@media screen and (max-width: 768px) {
  .visionPara {
    width: 100% !important;
  }
}

.aboutmargin {
  margin-bottom: 40px;
}

.missionPara {
  width: 100% !important;
}

.missionHeading {
  text-align: center;
}
.green_tabs {
  width: 100%;
  height: auto;
  color: black;
}

ul.green_tabs_menu {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #20a258;
}
@media screen and (max-width: 768px) {
  ul.green_tabs_menu {
    width: 100%;
  }
}

ul.green_tabs_menu li {
  width: 33.33%;
  padding: 10px;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}
@media screen and (max-width: 768px) {
  ul.green_tabs_menu li {
    font-size: 12px;
    white-space: nowrap;
  }
}

ul.green_tabs_menu li.active {
  background: #20a258;
  color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.green_tab_contents_wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

.green_tab_contents {
  width: 80%;
  text-align: justify;
}
@media screen and (max-width: 599px) {
  .green_tab_contents {
    width: 100%;
  }
}
.green_tab_contents p {
  font-family: Montserrat;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #333232;
}
@media screen and (max-width: 1000px) {
  .green_tab_contents p {
    font-size: 14px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.faq_container {
  margin: 100px 0px;
}

.faqside_image {
  border-radius: 10px;
  width: 100%;
  min-height: 450px;
  background: url(/static/media/faqsideimg.fc77582a.png) no-repeat;
  background-size: cover;
  background-position: center;
}

.rootAccordian {
  box-shadow: none !important;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-top: 20px;
}
.rootAccordian .MuiAccordionSummary-root {
  padding: 0 !important;
}
.rootAccordian .MuiAccordionSummary-root .MuiAccordionSummary-content p {
  padding-left: 0 !important;
}
@media screen and (min-width: 321px) {
  .rootAccordian .MuiAccordionSummary-root .MuiAccordionSummary-content {
    margin-left: 0 !important;
  }
}

.faq_heading {
  font-size: 35px !important;
  text-transform: uppercase;
  font-family: "AllRoundGothicW01-Book" !important;
}
@media screen and (max-width: 480px) {
  .faq_heading {
    font-size: 25px !important;
  }
}

.faqSpacing {
  margin-top: -34px !important;
}
@media screen and (max-width: 767px) {
  .faqSpacing {
    margin-top: -100px !important;
  }
}

.question {
  width: 100%;
  word-wrap: break-word;
  font-size: 18px;
  flex-shrink: 0;
  margin-right: 20px;
}

.answer {
  font-size: 16px;
  color: #797979;
}

.removeIcon {
  color: #dac4b5;
}

.addIcon {
  color: #797979;
}
.policy_wrapper {
  padding: "30px 40px 0px 30px";
}
@media screen and (max-width: 768px) {
  .policy_wrapper {
    padding: "30px 40px 0px 30px";
  }
}

.policy_container {
  height: 60vh;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  margin-top: 50px;
}
@media screen and (max-width: 768px) {
  .policy_container {
    margin-top: 25px;
    max-height: 55vh;
  }
}

.policy_tab_box {
  padding: 42px;
}
.policy_tab_box .MuiTabs-root {
  border-bottom: 1px #dcd6d6 solid;
}
.policy_tab_box .MuiTabs-root .MuiButtonBase-root:before {
  background-color: #20a258;
}
.policy_tab_box .MuiTabs-root .MuiTab-wrapper {
  color: #2a2525;
}
.policy_tab_box .PrivateTabIndicator-colorSecondary-25 {
  background-color: #20a258;
}
.policy_tab_box .MuiTabs-indicator {
  background-color: #20a258;
}
@media screen and (max-width: 768px) {
  .policy_tab_box {
    padding: 20px;
  }
  .policy_tab_box .MuiTab-wrapper {
    font-size: 11px;
  }
}
.policy_tab_box .tabPanel .MuiBox-root {
  overflow-y: scroll;
}
.policy_tab_box .tabPanel .MuiBox-root::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.policy_tab_box .tabPanel .MuiBox-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.policy_tab_box .tabPanel .MuiBox-root::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
@media screen and (max-width: 1920px) {
  .policy_tab_box .tabPanel .MuiBox-root {
    max-height: 500px;
  }
}
@media screen and (max-width: 1024px) {
  .policy_tab_box .tabPanel .MuiBox-root {
    max-height: 450px;
  }
}
@media screen and (max-width: 768px) {
  .policy_tab_box .tabPanel .MuiBox-root {
    max-height: 350px;
  }
}
@media screen and (max-width: 375px) {
  .policy_tab_box .tabPanel .MuiBox-root {
    max-height: 250px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.common_heading {
  font-family: "AllRoundGothicW01-Medium" !important;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (max-width: 480px) {
  .common_heading {
    font-size: 23px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.contact_container_wrapper {
  padding: 80px 0 100px 0;
}
@media screen and (max-width: 480px) {
  .contact_container_wrapper {
    padding-top: 40px;
  }
}

.contact_right_container .green_small_heading {
  font-family: "AllRoundGothicW01-Book" !important;
  font-size: 25px;
}
.contact_right_container h3 {
  font-family: "AllRoundGothicW01-Book" !important;
  letter-spacing: 1px;
  font-size: 1cm;
}

.contact_container .MuiFilledInput-underline:hover::before, .contact_container .MuiFilledInput-underline:hover::after {
  border-bottom: none;
  border-bottom: none;
}
.contact_container .MuiFilledInput-underline::before, .contact_container .MuiFilledInput-underline::after {
  border-bottom: none;
  border-bottom: none;
}

.contact_us_text_area {
  width: 100% !important;
  border: none !important;
  outline: none;
  background-color: #f6f6f6 !important;
  resize: none;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
  font-weight: 600 !important;
  color: #515156;
}
.contact_us_text_area::-moz-placeholder {
  color: #adb0b8;
}
.contact_us_text_area:-ms-input-placeholder {
  color: #adb0b8;
}
.contact_us_text_area::placeholder {
  color: #adb0b8;
}

.contact_us_heading {
  color: #20a258;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin: 15px 0px !important;
}

.contact_us_title {
  font-size: 30px !important;
  margin: 15px 0px !important;
}

.contact_us_text {
  margin: 15px 0px 0px !important;
}

.map_image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  min-height: 450px;
  background: url(/static/media/map.d90c9714.png) no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 768px) {
  .map_image {
    min-height: 320px;
  }
}

.contact_us_btn {
  background-color: #20a258 !important;
  padding: 10px 20px !important;
  border-radius: 50px !important;
  padding: 15px 20px !important;
  font-weight: 900 !important;
}

.btn-label {
  text-transform: capitalize;
}
.filter_input_root {
  border-radius: 30px !important;
}
.filter_input_root fieldset {
  top: 0px;
}

.filter_input_root:focus {
  border: "none";
}

.filter_input_input {
  padding: 14px 12px 14px 12px !important;
}

.filter_input_icon {
  width: 16px;
  -o-object-fit: contain;
     object-fit: contain;
}

.filter_advance_search_btn {
  background-color: #20a258 !important;
  width: 100%;
  color: "white" !important;
  padding: 11px 12px !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
}
.filter_advance_search_btn > span {
  color: #fff;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #20a258 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.farm_sidebar_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.farm_sidebar_common_box {
  width: 100%;
  border-radius: 12px;
  border: solid 1px #e6e6e6;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
}

.farm_sidebar_heading {
  display: flex;
  align-items: center;
}

.farm_sidebar_box_heading {
  font-size: 15px;
  font-weight: bold !important;
  color: #5d5e6a;
  text-transform: capitalize;
}

.farm_sidebar_range .MuiSlider-track {
  color: #20a258;
  height: 3px;
}
.farm_sidebar_range .MuiSlider-thumb {
  color: #20a258;
}
.farm_sidebar_range .MuiSlider-mark {
  color: #20a258;
}
.farm_sidebar_range .MuiSlider-marked {
  color: #20a258;
}

.price_container {
  font-family: "Montserrat", sans-serif;
  color: #818181 !important;
  font-size: 15px !important;
}
.price_container span {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.related_category_contents p {
  font-size: 15px;
  color: #818181;
  font-weight: 500;
  margin-bottom: 5px;
  cursor: pointer;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.related_category_contents .selected {
  font-weight: bold !important;
}

.sidebar_text_and_content_wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 25px;
}
.sidebar_text_and_content_wrapper > div {
  overflow-x: hidden;
}

.farm_side_bar_icons {
  font-size: 13px !important;
  color: #818181;
}

.refine_by_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0;
  padding: 8px 15px 8px 20px;
  border-radius: 25px;
  border: solid 1px #979797;
}
.refine_by_content p {
  color: #818181;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.refine_by_content_active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0;
  padding: 8px 15px 8px 20px;
  border-radius: 25px;
  border: solid 1px #20a258;
  background-color: #20a258;
}
.refine_by_content_active p {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.side_bar_close_icon {
  color: #818181;
}

.side_bar_close_icon_active {
  color: white;
}

.filter_items p {
  color: #818181;
  font-size: 15px;
  padding-top: 5px;
}

.active_filter {
  color: #20a258 !important;
}

.product_tag_content_wrapper {
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  padding: 20px;
}
.product_tag_content_wrapper .product_tag_header {
  color: #333;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px;
}
.product_tag_content_wrapper .selected {
  color: white;
  font-weight: bold;
  background-color: #20a258;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #20a258;
}

.product_tag_content p {
  font-size: 13px;
  padding: 6px 12px;
  border-radius: 24px;
  border: solid 0.5px #979797;
  color: #818181;
  cursor: pointer;
  text-align: center;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.farm_card {
  width: 100%;
}

.farm_card_box_shadow {
  width: 100%;
  box-shadow: 0px 0px 2px 2px #c3c3c3;
  border-radius: 20px;
}
.farm_card_box_shadow .farm_card_content {
  padding: 20px;
}
.farm_card_box_shadow .farm_card_content h6 {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.farm_card_box_shadow .farm_card_content p {
  color: #5a5a5a;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  min-height: 21px;
}
.farm_card_box_shadow .farm_card_content .duration {
  color: #000000;
  margin: 30px 0px;
}

.farm_img_wrapper {
  border-radius: 15px;
}
.farm_img_wrapper img {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 15px;
}

.farm_card_content {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
}
.farm_card_content h6 {
  font-weight: 900 !important;
  font-size: 18px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.farm_card_content p {
  color: #5a5a5a;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 21px;
}
.farm_card_content .duration {
  color: #000000;
  margin: 30px 0px;
}

.read_more_btn {
  padding: 9px 16px !important;
  border-radius: 29.5px !important;
  background-color: #f3f0f0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-transform: capitalize !important;
  transition: 0.5s !important;
}
.read_more_btn img {
  transition: transform 0.25s;
}
.read_more_btn:hover {
  background-color: #e1dfdf !important;
}
.read_more_btn:hover img {
  transform: translateX(3px);
}
@media screen and (max-width: 400px) {
  .read_more_btn {
    padding: 7px 11px !important;
  }
  .read_more_btn span {
    font-size: 13px;
  }
  .read_more_btn img {
    height: 15px;
  }
}

.read_more_btn_color {
  color: black;
}

.iconFilled {
  color: #ffb400;
}

.iconHover {
  color: #ffb400;
}

.card_price_wrapper .product_price_wrapper {
  display: flex !important;
  align-items: center;
}
.card_price_wrapper h5 {
  color: #20a258;
  font-size: 22px;
  font-weight: 800 !important;
}

.image_loader {
  color: #20a258 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.farms_page_content_wrapper {
  background-color: #ffffff;
  width: 100vw;
  height: auto;
  padding-top: 30px;
  padding-bottom: 100px;
}
.farms_page_content_wrapper h3 {
  font-family: "AllRoundGothicW01-Book" !important;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .farms_page_content_wrapper {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .farms_page_content_wrapper {
    padding-top: 15px;
  }
}

.custom_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
.custom_loader_container svg {
  color: #20a258;
}

.outline_input_icons {
  color: #20a258;
  font-size: 14px;
}

.btn_view_all {
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
}

.pagination_grid {
  margin-top: 30px;
}
.pagination_grid div {
  margin-left: 0px !important;
}

.advance_search_btn {
  background-color: #20a258 !important;
  width: 100%;
  color: "white" !important;
  padding: 12px 10px !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-weight: 600;
}

.farms_page_main_section {
  margin-top: 50px;
  width: 100%;
}
@media (max-width: 768px) {
  .farms_page_main_section {
    margin-top: 0px;
  }
}

.heading_and_filter_input_wrapper {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter_heading_wrapper {
  width: 100%;
}
.filter_heading_wrapper h3 {
  font-family: "AllRoundGothicW01-Medium" !important;
}

.filter_formControl_root {
  width: 100% !important;
}

.OutlinedInput-root {
  border-radius: 50px !important;
}

.select_icon {
  color: black !important;
  top: 2px !important;
}

.show_more_btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show_more_btn_wrapper_pagination {
  text-align: center;
  margin: 0px 75px;
}

.filter_input_wrapper_new {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #c3c3c3;
  border-radius: 50px;
  padding: 4px 2px 4px 14px;
  margin-bottom: 15px;
}
.filter_input_wrapper_new p {
  color: #c3c3c3;
}
.filter_input_wrapper_new .MuiInputBase-root {
  background: none !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  margin-top: 3px !important;
  padding-left: 6px;
}
.filter_input_wrapper_new .MuiInput-underline::before,
.filter_input_wrapper_new .MuiInput-underline::after {
  border-bottom: none !important;
  outline: none !important;
}
.filter_input_wrapper_new select,
.filter_input_wrapper_new .MuiSelect-select:focus {
  background-color: transparent !important;
}
.filter_input_wrapper_new .MuiMenuItem-root {
  font-family: "Montserrat", sans-serif !important;
}

.select_menus {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.leave_img_with_text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
}
.leave_img_with_text h3 {
  font-family: "AllRoundGothicW01-Book" !important;
  font-size: 30px;
  color: #333;
  text-align: center;
}
.leave_img_with_text p {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #5a5a5a;
}
.leave_img_with_text p span {
  color: #20a258;
  font-weight: bold;
  cursor: pointer;
}
.leave_img_with_text p a {
  color: #20a258;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
.web_pagination_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.web_pagination_container .Mui-selected {
  background-color: #20a258 !important;
  color: #ffffff !important;
  border: 1px solid #20a258;
}
@media screen and (max-width: 400px) {
  .web_pagination_container .MuiPaginationItem-root {
    min-width: 26px !important;
  }
}

.cmsPagination {
  justify-content: flex-start;
}

.left_align_pagination_container {
  justify-content: flex-start !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.custom_green_round_btn {
  background-color: #20a258 !important;
  color: #ffffff !important;
  padding: 10px 30px !important;
  border-radius: 24px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
}

.green_btn_label {
  color: #ffffff;
}

.green_btn_root {
  border-radius: 24px !important;
  background-color: #20a258 !important;
}
.render_img_main_wrapper {
  width: 100%;
  border-radius: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.render_img_main_wrapper img {
  width: 100%;
  height: 400px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  -o-object-fit: cover;
     object-fit: cover;
}

.farm_small_img_main_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
}

.farm_small_img_wrapper {
  width: 100px;
}
.farm_small_img_wrapper img {
  width: 100%;
  height: auto;
  cursor: pointer;
  -o-object-fit: cover;
     object-fit: cover;
}

.image-gallery-icon:hover {
  color: #20a258 !important;
}

.image-gallery-thumbnail {
  border-radius: 3px !important;
}
.image-gallery-thumbnail img {
  border-radius: 2px !important;
  -o-object-fit: fill !important;
     object-fit: fill !important;
}
.image-gallery-thumbnail.active {
  border-color: #20a258 !important;
}
.image-gallery-thumbnail:hover {
  border-color: #20a258 !important;
}

.image-gallery-slide .image-gallery-image {
  border-radius: 10px !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.review_card_wrapper {
  border: 1px solid #c3c3c3;
  padding: 20px;
  border-radius: 20px;
}

.reviewer_img {
  display: flex;
  align-items: center;
}
.reviewer_img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #979797;
}

.review_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reviewer_details {
  display: flex;
  justify-content: space-between;
}

.reviewer_name {
  color: #000000;
  font-weight: 600 !important;
  font-size: 18px !important;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
}

.reviewed_date {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #5a5a5a;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.farm_details_top_section_wrappper {
  margin-top: 20px;
}
.farm_details_top_section_wrappper .farm_details_btn_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.farm_details_top_section_wrappper .farm_details_btn_container .farm_view_product_btn {
  background-color: #20a258;
  padding: 7px 30px;
  border-radius: 24px;
  text-transform: none;
}
.farm_details_top_section_wrappper .farm_details_btn_container .farm_view_product_btn span {
  font-weight: 600;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}

.Farm_Img_render_Wrapper {
  width: 100%;
}
.Farm_Img_render_Wrapper img {
  width: 100%;
  height: 500px;
}

.farm_details_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}
.farm_details_heading .farm_name_fac_wrapper {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  overflow-x: hidden;
}
.farm_details_heading .farm_name_fac_wrapper h3 {
  font-family: "AllRoundGothicW01-Book" !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.farm_details_heading .farm_name_fac_wrapper button {
  padding: 6px;
}
.farm_details_heading .farm_social_img_wrapper {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.farm_details_heading .farm_social_img_wrapper .farm_social_img {
  height: 25px !important;
  width: 25px !important;
}

.farm_certified_tags_container {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
  padding: 5px 0px 10px;
  min-height: 25px;
}
.farm_certified_tags_container img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.farm_details_review {
  margin-top: 70px !important;
}

.farm_details_context {
  color: #5a5a5a;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.89;
  overflow-y: auto;
  max-height: 250px;
  margin-bottom: 10px;
  padding-right: 20px;
  word-wrap: break-word;
}
.farm_details_context::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.farm_details_context::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3;
  border-radius: 10px !important;
}
.farm_details_context::-webkit-scrollbar-thumb {
  background-color: #20a258;
  border-radius: 10px;
  opacity: 0.5;
}
.farm_details_context::-webkit-scrollbar-thumb:hover {
  background-color: #20a258;
  opacity: 1;
}

.farm_details_review_heading h3 {
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 2px;
  margin-right: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
@media (max-width: 600px) {
  .farm_details_review_heading h3 {
    font-size: 18px;
  }
}
@media (max-width: 400px) {
  .farm_details_review_heading h3 {
    font-size: 16px;
  }
}

.write_review_with_underline {
  color: #179d4e;
  font-size: 22px;
  text-decoration: underline;
  font-weight: 600;
  text-align: right;
}
@media screen and (max-width: 600px) {
  .write_review_with_underline {
    font-size: 16px;
  }
}

.review_submit_btn {
  background: #20a258 !important;
  border-radius: 30px !important;
  padding: 6px 50px !important;
}
.review_submit_btn span {
  color: #ffffff;
  text-transform: none;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 600px) {
  .review_submit_btn {
    padding: 6px 40px !important;
  }
  .review_submit_btn span {
    font-size: 15px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;
  font-weight: 600;
}

.swiper-button-prev,
.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.swiper-button-next:after {
  position: relative;
  left: 1px;
}

.swiper-button-prev:after {
  position: relative;
  left: -1px;
}

.popular_view_btn_container {
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
}

.farm_detail_review_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taskDescrip {
  margin: 10px 5px 18px 0;
  padding: 10px;
  border: #e1e1e1 solid 1px;
  border-radius: 10px;
  word-wrap: break-word;
}
.taskDescrip p {
  color: rgba(0, 0, 0, 0.87) !important;
  line-height: 1.4 !important;
}
.farm_popular_products_slider .slick-arrow.slick-prev,
.farm_popular_products_slider .slick-arrow.slick-next {
  background: rgba(0, 0, 0, 0.7);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 50%;
}
.farm_popular_products_slider .slick-arrow.slick-prev svg,
.farm_popular_products_slider .slick-arrow.slick-next svg {
  color: #fff;
  font-size: 20px;
}
.farm_popular_products_slider .slick-prev {
  padding-right: 2px;
}
@media screen and (max-width: 1300px) {
  .farm_popular_products_slider .slick-prev {
    left: -16px;
  }
}
@media screen and (max-width: 800px) {
  .farm_popular_products_slider .slick-prev {
    left: -10px;
  }
}
.farm_popular_products_slider .slick-next {
  padding-left: 2px;
}
@media screen and (max-width: 1300px) {
  .farm_popular_products_slider .slick-next {
    right: -16px;
  }
}
@media screen and (max-width: 800px) {
  .farm_popular_products_slider .slick-next {
    right: -10px;
  }
}
.farm_popular_products_slider .slick-prev::before,
.farm_popular_products_slider .slick-next::before {
  display: none;
}
.farm_popular_products_slider .custom-indicator {
  bottom: -65px;
}
.farm_popular_products_slider .custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}
.farm_popular_products_slider .custom-indicator li.slick-active {
  filter: grayscale(0%);
}
.farm_popular_products_slider .multi__image:hover {
  transform: scale(1.1);
  transition: 500ms;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.section {
  padding: 60px;
}

.product_details {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.product_details .prd_h3 {
  font-family: "AllRoundGothicW01-Book" !important;
}

@media screen and (max-width: 428px) {
  .product_details_tabs {
    margin-bottom: 20px;
  }
}
.product_price {
  color: #20a258;
  font-size: 22px !important;
  font-weight: 600 !important;
}

.product_compare_at_price {
  color: #77808a !important;
  margin-left: 10px !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  text-decoration: line-through;
}

.product_price_tags_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  padding-bottom: 5px;
}
.product_price_tags_wrapper .product_price_wrapper {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.farm_product_price {
  display: inline-block;
}

.product_details_label {
  color: #000000;
  text-transform: uppercase;
  padding-right: 10px;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}

.product_details_user_review {
  text-transform: none !important;
  font-size: 18px !important;
  display: inline-block !important;
}

.product_details_review_box {
  display: flex;
  align-items: center;
}

.product_details_review_box > span {
  margin-left: 15px;
}

.product_details_children_wrapper {
  border: 1px solid #c1c1c1;
  padding: 20px 30px;
  border-radius: 20px;
}

.product_details_customer_review_count {
  color: #808080;
  border-bottom: 1px solid #c3c3c3;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 15px !important;
}

.product_details_top_right_heading {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}
.product_details_top_right_heading p {
  float: right;
  color: #20a258;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}

@media screen and (max-width: 428px) {
  .product_details_top_right_heading {
    justify-content: flex-end;
  }
  .product_details_top_right_heading p {
    float: left;
    position: relative;
    top: -35px;
    left: 10px;
  }
}
.review_label {
  color: #000000;
  font-weight: 600 !important;
  font-size: 18px;
  margin-left: 5px;
}

.write_review_text_area {
  width: 100%;
  resize: none;
  border: 1px solid #c1c1c1;
  border-radius: 20px !important;
  outline: none;
  padding: 15px;
  font-family: "Montserrat", sans-serif !important;
}

.product_fav {
  background-color: #fff;
  padding: 6px !important;
}
.product_fav:hover {
  background-color: #fff !important;
}
.product_fav svg {
  color: #394053;
}
.product_fav .fav_fill_icon {
  color: red !important;
}

.product_category {
  color: #20a258;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.product_tags {
  background-color: #9be5bb;
  border-radius: 15px;
  padding: 2px 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
}

.product_details_tabs {
  margin-top: 30px;
  display: flex;
}

.product_details_tab {
  background-color: #20a258;
  color: #ffffff !important;
  border-radius: 12px;
  padding: 8px 24px;
  font-size: 18px;
}

.product_details_tab:hover {
  opacity: 0.9;
}

.product_details_review_heading {
  display: flex;
  align-items: center;
}

.cart_action_container {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 5px;
}
.cart_action_container .product_variant_wrapper {
  flex-grow: 1;
}
.cart_action_container .product_variant_wrapper .product_variant_select {
  width: 100%;
  border-radius: 8px;
  background-color: #f4f5f5;
}
.cart_action_container .product_variant_wrapper .product_variant_select .MuiSelect-filled {
  border-radius: 8px;
  padding: 10px 15px;
}
.cart_action_container .product_variant_wrapper .product_variant_select.Mui-focused {
  background-color: #f4f5f5;
  border-radius: 8px !important;
}

.cart_btn_container {
  width: 100%;
  margin-top: 15px;
}
.cart_btn_container button {
  width: 100%;
}

.product_stock_info,
.product_less_stock_info {
  color: red;
  font-family: "Open Sans", sans-serif !important;
  font-size: 15px !important;
  margin-top: 3px !important;
  padding-left: 2px;
}

.product_less_stock_info {
  color: #F3666A;
}

.product_details_info {
  display: flex;
  align-items: center;
}

.product_add_cart {
  padding: 7px 25px !important;
  border-radius: 24px !important;
  background-color: #20a258 !important;
  min-width: 120px !important;
}
.product_add_cart:disabled {
  opacity: 0.7;
}

.add_cart_loader {
  color: #ffffff !important;
}

.product_details_grey_txt {
  color: #5a5a5a !important;
  font-weight: 500 !important;
  padding-top: 5px;
  font-family: "Montserrat", sans-serif !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.custom_quantity_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f5f5;
  border-radius: 26px;
  grid-gap: 8px;
  gap: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.custom_quantity_container button {
  border: none;
  background-color: transparent;
  height: 33px;
  width: 33px;
  font-size: 18px;
  font-weight: bold;
  color: #264653;
  border-radius: 50%;
}
.custom_quantity_container span {
  font-size: 14px;
  font-weight: bold;
  color: #264653;
  margin-right: 7px;
  font-family: "PT Sans", sans-serif;
}
.custom_quantity_container button:last-child {
  height: 34px;
  width: 34px;
  background-color: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
}

.custom_quantity_container_disabled {
  opacity: 0.7;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.farm_catelog_receipt_card {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 17px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.farm_catelog_img_wrapper {
  width: 100%;
  position: relative;
  height: 270px;
  text-align: center;
}
.farm_catelog_img_wrapper img {
  width: 100%;
  height: 270px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.farm_Catelog_receipt_date {
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 20px;
  padding: 6px 20px 4px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.farm_Catelog_receipt_date p {
  font-size: 12px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.farm_catelog_receipt_contents {
  padding: 30px 25px;
}
.farm_catelog_receipt_contents h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #4cb479;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.farm_catelog_likes_and_comments_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.farm_catelogs_likes_comment_div {
  display: flex;
  align-items: center;
  width: 100%;
}
.farm_catelogs_likes_comment_div .forum_like_wrapper {
  display: flex;
}
.farm_catelogs_likes_comment_div .forum_like_wrapper:hover {
  transform: scale(1.1);
}

.farm_catelogs_likes_comment_div:nth-child(2) {
  justify-content: flex-end;
}

.farm_catelog_like_icon {
  color: #008eea;
}

.farm_catelog_comment_icon {
  color: #ffa700;
}

.like_count {
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  margin-right: 3px;
  color: #000;
  padding-top: 2px;
}

.like_comment_text {
  font-size: 13px;
  font-weight: 500 !important;
  padding-top: 2px;
}

.farm_forum_dialog {
  border-radius: 20px !important;
  overflow-y: scroll !important;
  scrollbar-color: #20a258 transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.farm_forum_dialog ::-webkit-scrollbar-track {
  background: transparent !important;
}
.farm_forum_dialog ::-webkit-scrollbar-thumb {
  background-color: #20a258;
  border-radius: 20px;
  border: 3px solid transparent !important;
}
@media screen and (max-width: 560px) {
  .farm_forum_dialog {
    border-radius: 10px !important;
  }
}

.farm_forum_dialog::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.custom_comment_wrapper {
  border-radius: 26px;
  background-color: #f0f0f0;
  padding: 25px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .custom_comment_wrapper {
    padding: 20px;
  }
}
.custom_comment_wrapper .para {
  color: #92929d;
  font-family: "Montserrat", sans-serif;
}
.custom_comment_wrapper .comment_image_wrapper {
  padding: 12px 0px;
}
.custom_comment_wrapper .comment_profile_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #979797;
}
@media screen and (max-width: 600px) {
  .custom_comment_wrapper .comment_profile_img {
    width: 45px;
    height: 45px;
  }
}
.custom_comment_wrapper .comment_main_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .custom_comment_wrapper .comment_main_container {
    grid-gap: 10px;
    gap: 10px;
  }
}
@media screen and (max-width: 450px) {
  .custom_comment_wrapper .comment_main_container {
    flex-wrap: wrap;
  }
}
.custom_comment_wrapper .comment_main_comment {
  padding: 12px 0px;
}
.custom_comment_wrapper .comment_info_container {
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;
  gap: 8px;
  flex-wrap: wrap;
}
.custom_comment_wrapper .comment_date {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: right;
  color: #5a5a5a !important;
}
@media screen and (max-width: 600px) {
  .custom_comment_wrapper .comment_date {
    font-size: 12px;
  }
}
.custom_comment_wrapper h5 {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif !important;
  color: #000 !important;
}
@media screen and (max-width: 600px) {
  .custom_comment_wrapper h5 {
    font-size: 15px;
  }
}
.custom_comment_wrapper .reply_heading {
  margin-right: 0 !important;
}
.custom_comment_wrapper .comment {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #5a5a5a !important;
  margin: 12px 0px;
  word-break: break-word;
}
@media screen and (max-width: 600px) {
  .custom_comment_wrapper .comment {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 560px) {
  .custom_comment_wrapper .comment {
    padding: 2px;
    margin: 0;
    color: #7d7979 !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px !important;
  }
}
@media screen and (max-width: 400px) {
  .custom_comment_wrapper .comment {
    font-size: 12px !important;
  }
}
.custom_comment_wrapper .comment_actions_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
}
@media screen and (max-width: 450px) {
  .custom_comment_wrapper .comment_actions_container {
    justify-content: flex-end;
    margin-top: 5px;
  }
  .custom_comment_wrapper .comment_actions_container button {
    width: 100%;
  }
}
.custom_comment_wrapper .comment_reply_container {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.custom_comment_wrapper .review_reply_btn {
  background-color: #3bad6c !important;
  padding: 7px 20px;
  border-radius: 25px;
}
.custom_comment_wrapper .review_reply_btn span {
  color: #ffffff;
  text-transform: capitalize;
}
@media screen and (max-width: 450px) {
  .custom_comment_wrapper .review_reply_btn span {
    font-size: 12px;
  }
}
.custom_comment_wrapper .review_reply_btn p {
  color: #ffffff;
  padding-left: 10px;
}
.custom_comment_wrapper .report_btn {
  background-color: transparent;
  padding: 6px 10px;
  border-radius: 25px;
  border: 1px solid #fd5656;
  text-transform: none;
  min-width: 110px;
}
.custom_comment_wrapper .report_btn span {
  font-weight: 600;
  color: #fd5656;
}
@media screen and (max-width: 450px) {
  .custom_comment_wrapper .report_btn span {
    font-size: 12px;
  }
}
.custom_comment_wrapper .report_btn:disabled {
  background-color: transparent;
  cursor: not-allowed !important;
  border: 1px solid #9098b1;
  pointer-events: inherit;
}
.custom_comment_wrapper .report_btn:disabled span {
  font-weight: 600;
  color: #9098b1;
}
.custom_comment_wrapper ul {
  display: flex;
}
.custom_comment_wrapper ul li {
  padding: 0px 3px;
}
.custom_comment_wrapper ul li svg {
  color: #ec9e31;
}
@media (max-width: 515px) {
  .custom_comment_wrapper {
    flex-direction: column;
  }
  .custom_comment_wrapper img {
    margin-bottom: 10px;
  }
}

.forum_reply_container {
  padding: 20px 0px;
  border-top: 1px solid #979797;
}
@media screen and (max-width: 450px) {
  .forum_reply_container .reply_image_wrapper {
    display: flex;
    justify-content: center;
  }
}

.forum_show_replies_container {
  display: flex !important;
  justify-content: flex-end !important;
}
.forum_show_replies_container button {
  background: transparent;
  text-transform: none;
  border-radius: 20px;
  padding: 6px 12px;
}
.forum_show_replies_container button span {
  color: #20a258;
  font-family: "Poppins", sans-serif;
}

.review_action_container {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  justify-content: flex-end;
}
@media screen and (max-width: 450px) {
  .review_action_container {
    grid-gap: 8px;
    gap: 8px;
  }
}
.review_action_container .add_review_cancel {
  background: #fff;
  border: 1px solid #c3c3c3 !important;
  line-height: normal !important;
  width: 37px !important;
  height: 37px !important;
}
@media screen and (max-width: 450px) {
  .review_action_container .add_review_cancel {
    width: 32px !important;
    height: 32px !important;
  }
}
.review_action_container .add_review_cancel svg {
  color: #000;
}
@media screen and (max-width: 450px) {
  .review_action_container .add_review_cancel svg {
    font-size: 16px;
  }
}
.review_action_container button {
  padding: 5px;
}

.reply_save_btn {
  border-radius: 25px !important;
  background-color: #3bad6c !important;
}
.reply_save_btn span {
  color: #ffffff !important;
  padding: 0px 30px;
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 450px) {
  .reply_save_btn span {
    font-size: 12px;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 600px) {
  .review_reply_container {
    margin-top: 5px;
  }
}

.add_reply_loader {
  margin: 3px 0px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.reply_header {
  display: flex;
  justify-content: space-between;
}
.reply_header h5 {
  color: #000000;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}
.reply_header p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #5a5a5a;
}

.reply_edit_textfield {
  margin-top: 10px !important;
}
.reply_edit_textfield input {
  color: #5a5a5a !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  border-radius: 20px;
  padding: 10px 12px;
  border: 1px solid #c3c3c3 !important;
  background-color: #fff;
}
@media screen and (max-width: 450px) {
  .reply_edit_textfield input {
    font-size: 14px !important;
  }
}

.reply_edit_delete_wrapper {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 15px;
}
@media screen and (max-width: 450px) {
  .reply_edit_delete_wrapper {
    grid-gap: 10px;
    gap: 10px;
  }
}

.delete_reply {
  border: 1px solid red !important;
  border-radius: 50% !important;
  min-width: 0 !important;
  line-height: normal !important;
  width: 36px !important;
}
.delete_reply img {
  height: 20px !important;
  width: 20px !important;
  margin: 0px !important;
}
@media screen and (max-width: 450px) {
  .delete_reply img {
    height: 18px !important;
    width: 20px !important;
  }
}
.delete_reply svg {
  color: red !important;
}
@media (max-width: 413px) {
  .delete_reply {
    width: 100%;
  }
}

.edit_reply {
  border: 1px solid #3bad6c !important;
  border-radius: 25px !important;
}
.edit_reply span {
  color: #3bad6c !important;
  padding: 0px 30px;
  text-transform: none;
  font-weight: 600;
}
@media screen and (max-width: 450px) {
  .edit_reply span {
    padding: 0px 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .edit_reply span {
    padding: 0px 10px;
    font-size: 12px;
    border-radius: 5px;
  }
}

.cancel_reply {
  border-radius: 25px !important;
  background-color: #fff !important;
  border: 1px solid #c3c3c3 !important;
}
@media screen and (max-width: 560px) {
  .cancel_reply {
    padding: 0px !important;
    height: 35px;
    margin: 10px 10px 0 0 !important;
  }
}
.cancel_reply span {
  color: #000000 !important;
  padding: 0px 25px;
  text-transform: none;
  font-weight: 500;
}
@media screen and (max-width: 450px) {
  .cancel_reply span {
    font-size: 12px !important;
    padding: 0px 10px !important;
  }
}

.save_reply {
  border-radius: 25px !important;
  background-color: #3bad6c !important;
}
@media screen and (max-width: 560px) {
  .save_reply {
    height: 35px;
    padding: 0 !important;
    margin: 10px 10px 0 0 !important;
  }
}
.save_reply span {
  color: #ffffff !important;
  padding: 0px 30px;
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 450px) {
  .save_reply span {
    font-size: 12px !important;
    padding: 0px 10px !important;
  }
}

.reply_profile_img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 1px solid #979797;
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  .reply_profile_img {
    width: 38px;
    height: 38px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.farm_forum_modal_container {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.farm_forum_modal_container ::-webkit-scrollbar {
  width: 5px !important;
  display: none !important;
}
.farm_forum_modal_container ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px !important;
}
.farm_forum_modal_container ::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
.farm_forum_modal_container ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.forum_content_wrapper {
  padding: 30px;
}
@media screen and (max-width: 600px) {
  .forum_content_wrapper {
    padding: 20px;
  }
}
@media screen and (max-width: 400px) {
  .forum_content_wrapper {
    padding: 20px 10px;
  }
}

.carrot_banner {
  height: 60vh;
  background-size: 100% 100%;
  background-position: center;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  position: relative;
}
@media screen and (max-width: 600px) {
  .carrot_banner {
    height: 40vh;
  }
}
.carrot_banner button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.farm_forum_comments_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #979797;
  padding: 20px 0px;
  grid-gap: 8px;
  gap: 8px;
}
.farm_forum_comments_header h5 {
  font-weight: 600;
}
.farm_forum_comments_header span {
  color: #20a258;
  font-weight: 600;
  border-bottom: 1px solid #20a258;
}

.farm_forum_comment_textarea_wrapper {
  border: 1px solid #c3c3c3;
  border-radius: 20px;
  padding: 20px;
}

.farm_forum_likes_comments_wrapper {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 10px;
}
.farm_forum_likes_comments_wrapper span {
  margin-top: 6px;
  color: #000000;
}
.farm_forum_likes_comments_wrapper span span {
  font-weight: 600;
  color: #000000;
  padding: 0px 5px;
}
.farm_forum_likes_comments_wrapper p {
  color: #000000;
  display: flex;
  align-items: flex-end;
  grid-gap: 2px;
  gap: 2px;
}
.farm_forum_likes_comments_wrapper p img {
  width: 22px !important;
}
.farm_forum_likes_comments_wrapper .forum_like_wrapper {
  cursor: pointer;
}
.farm_forum_likes_comments_wrapper .forum_like_wrapper:hover {
  transform: scale(1.1);
}
@media (max-width: 300px) {
  .farm_forum_likes_comments_wrapper {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
}

.farm_forum_scroll_textarea {
  width: 100%;
  resize: none;
  border: 1px solid #c3c3c3;
  border-radius: 20px;
  padding: 20px;
  overflow-y: auto;
}
.farm_forum_scroll_textarea:focus {
  outline: none;
}

.farm_forum_comment_textarea {
  width: 100%;
  resize: none;
  border: none;
}
.farm_forum_comment_textarea:focus {
  border: none !important;
  outline: none !important;
}

.farm_forum_comment_textarea_btn_wrapper {
  display: flex;
  justify-content: flex-end;
}
.farm_forum_comment_textarea_btn_wrapper button {
  border-radius: 25px;
  background-color: #ffac0c;
}
.farm_forum_comment_textarea_btn_wrapper button:hover {
  border: 1px solid #ffac0c;
}
.farm_forum_comment_textarea_btn_wrapper button:hover span {
  color: #ffac0c;
}
.farm_forum_comment_textarea_btn_wrapper button span {
  color: #ffffff;
  padding: 3px 12px;
  text-transform: none;
}
.farm_forum_comment_textarea_btn_wrapper .reply_btn_disabled {
  background-color: #f3f3f3;
}
.farm_forum_comment_textarea_btn_wrapper .reply_btn_disabled span {
  color: #3a3a3a;
}

.forum_loader_container {
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c3c3c3;
  border-radius: 20px;
}
.forum_loader_container svg {
  color: #20a258;
}
.farm_catelog_top_banner {
  background: url(/static/media/grass-banner-2.1e8a75e2.jpg) no-repeat;
  background-size: cover;
  padding: 60px;
  height: 100%;
  width: 100%;
  margin-bottom: 15px;
}
.farm_catelog_top_banner h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #ffffff;
  font-weight: 600;
}

.farm_catelog_banner_input {
  padding: 14.5px 18px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
}

.farm_catelog_search_btn {
  width: 149px !important;
  height: 61px !important;
  padding: 20px 20px;
  background-color: #ffac0c !important;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  position: absolute;
  right: 0;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.res_heading_wrapper p {
  font-family: "AllRoundGothicW01-Medium";
  font-size: 36px;
  color: #000;
  font-weight: normal;
  margin-bottom: 20px;
}

.farm_catelogs_tabs_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.farm_catelog_tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  border-radius: 8px;
  background-color: #f3f3f3;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}
.farm_catelog_tab img {
  width: 25px;
  margin-right: 10px;
}
.farm_catelog_tab p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.farm_catelog_tab_active {
  background-color: #3bad6c;
}
.farm_catelog_tab_active p {
  color: white;
}

.farm_catelog_middle_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding: 80px 40px;
  width: 100%;
  background-color: rgba(12, 12, 12, 0.22);
  background-blend-mode: multiply;
  display: flex;
  justify-content: center;
}
@media (max-width: 600px) {
  .farm_catelog_middle_banner {
    padding: 40px;
  }
}

.farm_details_review_heading {
  display: flex;
  width: 100%;
  align-items: center;
}
.farm_details_review_heading p {
  color: #000000;
  font-weight: 600 !important;
  font-size: 18px;
  padding: 15px 0px;
  padding-right: 10px;
}

.farm_details_review_block {
  margin-top: 45px !important;
}

.farm_details_write_review_heading {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.write_review_with_underline {
  color: #20a258;
  font-size: 18px;
  text-decoration: underline;
  font-weight: bold;
  padding: 15px 0px;
}

.Learn_some_new_heading_wrapper {
  margin-top: 35px;
  margin-bottom: 15px;
}
.Learn_some_new_heading_wrapper h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: #333;
  text-align: center;
}

.farm_catelog_middle_banner_contents_wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
@media (max-width: 500px) {
  .farm_catelog_middle_banner_contents_wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
  }
}

.middle_banner_left_content h1 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 56px !important;
  font-weight: 800 !important;
  color: #000 !important;
}
.middle_banner_left_content h5 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  color: #000;
  margin: 15px 0px 25px;
  font-size: 20px !important;
  line-height: 1.23;
}
@media (max-width: 600px) {
  .middle_banner_left_content h1 {
    font-size: 38px !important;
  }
  .middle_banner_left_content h5 {
    font-size: 18px !important;
  }
}
@media (max-width: 300px) {
  .middle_banner_left_content h1 {
    font-size: 28px !important;
  }
  .middle_banner_left_content h5 {
    font-size: 16px !important;
  }
}
@media (max-width: 400px) {
  .middle_banner_left_content {
    font-size: 35px;
  }
}

.farm_donate_btn_product {
  background-color: #ffa700 !important;
  padding: 12px 30px !important;
  margin-top: 10px !important;
  border-radius: 45px !important;
  font-size: 18px !important;
}
@media (max-width: 300px) {
  .farm_donate_btn_product {
    font-size: 14px !important;
    padding: 8px 20px !important;
  }
}

.farm_donate_btn_label {
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif !important;
  color: #fff;
}

.orange_circle_wrapper {
  display: inline-flex;
  justify-content: center;
}

.orange_circle {
  width: 240px;
  height: 240px;
  padding: 52px 40px 72px 45px;
  border: solid 18px #ffa700;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.orange_circle p,
.orange_circle h5 {
  font-family: "Montserrat", sans-serif;
  color: #000;
}
.orange_circle p {
  font-size: 15px;
  font-weight: 500;
}
.orange_circle h5 {
  font-size: 28px !important;
  font-weight: 800;
  margin-bottom: 15px;
}

.restaurant_catelog_select_icon {
  color: #000000 !important;
  top: 2px !important;
}

.farm_donate_generate {
  font-size: 20px;
  color: #000000;
}
@media (max-width: 300px) {
  .farm_donate_generate {
    font-size: 17px;
  }
}

.farm_donate_gain_amount {
  color: #000000;
  font-size: 45px;
  text-shadow: 1.5px 0px #000000;
  letter-spacing: 1px;
  font-weight: 700;
}
@media (max-width: 300px) {
  .farm_donate_gain_amount {
    font-size: 25px;
  }
}

.farm_donate_raise_amount {
  color: #000000;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
  text-shadow: 0.8px 0px #000000;
  letter-spacing: 1px;
}
@media (max-width: 300px) {
  .farm_donate_raise_amount {
    font-size: 13px;
    margin-top: 5px;
  }
}

.restaurant_main_wrapper {
  margin-top: 50px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .restaurant_main_wrapper {
    margin-top: 15px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.donate_banner {
  background: url(/static/media/group-20@3x.a45764dc.png) no-repeat;
  background-size: cover;
  padding: 90px 140px 200px;
  background-color: rgba(12, 12, 12, 0.22);
  background-blend-mode: multiply;
}
.donate_banner .donate_container_header {
  color: #ffac0c;
  padding: 10px 0px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 80px;
  font-weight: 900 !important;
  line-height: 1;
  letter-spacing: 0.1px;
  height: 100%;
}
@media screen and (max-width: 1000px) {
  .donate_banner {
    padding: 80px 100px 160px;
  }
  .donate_banner .donate_container_header {
    font-size: 62px;
  }
}
@media screen and (max-width: 600px) {
  .donate_banner {
    padding: 60px 50px 120px;
  }
  .donate_banner .donate_container_header {
    font-size: 38px;
  }
}

.donate_green_container {
  height: 45px;
  width: 100%;
  background-color: #20a258;
}

.web_input_field .MuiFilledInput-underline:before,
.web_input_field .MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.donate_container {
  margin-top: -120px;
}
.donate_container h1 {
  color: #ffac0c;
  padding: 10px 0px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 80px;
  font-weight: 900 !important;
  line-height: 1;
  letter-spacing: 0.1px;
  width: 80%;
}
.donate_container .donate_description {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.71;
}
@media screen and (max-width: 600px) {
  .donate_container {
    margin-top: -70px;
  }
}

.featured_donate_wrapper {
  padding: 40px 50px;
  background-color: #ffffff;
  box-shadow: 4px 6.9px 17px 0 rgba(0, 0, 0, 0.2);
  border: 10px solid #fafafa;
  border-radius: 8px;
}
.featured_donate_wrapper h4 {
  color: #ff9800;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.33;
}
.featured_donate_wrapper h2 {
  font-weight: 600;
  margin-top: 10px;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.14;
  color: #424242;
}
@media screen and (max-width: 800px) {
  .featured_donate_wrapper {
    padding: 30px;
  }
  .featured_donate_wrapper h4 {
    font-size: 20px;
  }
  .featured_donate_wrapper h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 600px) {
  .featured_donate_wrapper {
    padding: 30px;
  }
  .featured_donate_wrapper h4 {
    font-size: 18px;
  }
  .featured_donate_wrapper h2 {
    font-size: 25px;
  }
}

.amount_donate p {
  text-transform: capitalize;
  margin: 0px 0px 10px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.31;
  color: #616161;
}

.radio-label {
  color: #616161 !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px !important;
}
.radio-label svg {
  color: #616161 !important;
}

.donate_leave_reply {
  border-top: 2px solid #f3f3f3;
  margin-top: 50px;
  padding: 50px 0px;
}
.donate_leave_reply h4 {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  line-height: 0.7;
  color: #424242;
}
.donate_leave_reply p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #616161;
}

.donate_input_root {
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  color: #515156 !important;
  padding: 4px 8px;
}

.donate_input {
  width: 100%;
  outline: none;
  border: none;
  padding: 13px 10px !important;
}

.donate_reply_input_root {
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 6px !important;
  border: 1px solid #e9e9e9 !important;
  padding: 4px 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 2.29 !important;
  color: #616161 !important;
  font-family: "Montserrat", sans-serif !important;
}
.donate_reply_input_root input::-moz-placeholder {
  opacity: 1 !important;
}
.donate_reply_input_root input:-ms-input-placeholder {
  opacity: 1 !important;
}
.donate_reply_input_root input::placeholder {
  opacity: 1 !important;
}

.donate_input_focused {
  border: 1px solid #ec9e31 !important;
  outline: none !important;
}

.donate_text_area {
  width: 100%;
  resize: none;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none !important;
  border: 1px solid #e9e9e9 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 2.29 !important;
  color: #616161 !important;
  font-family: "Montserrat", sans-serif !important;
}
.donate_text_area:focus {
  border: 1px solid #ec9e31 !important;
}
.donate_text_area::-moz-placeholder {
  color: #616161;
}
.donate_text_area:-ms-input-placeholder {
  color: #616161;
}
.donate_text_area::placeholder {
  color: #616161;
}

.donate_btn {
  background-color: #ff9800 !important;
  padding: 10px 24px !important;
  margin-top: 20px !important;
  border-radius: 20px !important;
}

.donate_btn_2 {
  background-color: #ff9800 !important;
  padding: 10px 20px !important;
  border-radius: 20px !important;
  width: 100% !important;
}

.donate_btn_label {
  color: #ffffff !important;
  font-size: 12px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
}

.donate_btn_disabled {
  background-color: #f3f3f3 !important;
}
.donate_btn_disabled span {
  color: #333 !important;
}

@media screen and (max-width: 428px) {
  .donate_container h1 {
    color: #ec9e31 !important;
    font-weight: 900 !important;
    font-size: 60px;
    text-align: left;
    padding: 30px 0px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restaurant_landing_page_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}
.restaurant_landing_page_loader svg {
  color: #20a258;
}

.restaurant_landing_page_banner {
  background: url(/static/media/polygon-polygon-mask.1bdfc759.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding: 120px 100px;
}
@media screen and (max-width: 1200px) {
  .restaurant_landing_page_banner {
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
  .restaurant_landing_page_banner {
    padding: 45px;
  }
}
@media screen and (max-width: 600px) {
  .restaurant_landing_page_banner {
    background: url(/static/media/banner1-mobile.4e719e75.png) no-repeat;
    background-size: 100% 100%;
    padding: 25px;
    margin: 15px;
  }
}

.restaurant_landing_page_banner_content {
  width: 100%;
  padding: 10px 0px;
}

.restaurant_landing_page_banner_two {
  margin-bottom: 50px;
  font-family: "AllRoundGothicW01-Book";
  font-weight: 500;
  color: #fff;
  font-size: 60px;
  line-height: 1.11;
  width: 80%;
}
@media screen and (max-width: 1500px) {
  .restaurant_landing_page_banner_two {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .restaurant_landing_page_banner_two {
    font-size: 48px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 959px) {
  .restaurant_landing_page_banner_two {
    font-size: 40px;
    margin-bottom: 25px;
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  .restaurant_landing_page_banner_two {
    font-size: 34px;
    margin-bottom: 30px;
    width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .restaurant_landing_page_banner_two {
    font-size: 26px;
    margin-bottom: 20px;
    width: 55%;
  }
}

.Food_resto_input_btn_wrappper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 2px;
  border-radius: 24px;
  width: 90%;
}
@media screen and (max-width: 1200px) {
  .Food_resto_input_btn_wrappper {
    width: 95%;
  }
}
@media screen and (max-width: 959px) {
  .Food_resto_input_btn_wrappper {
    width: 55%;
  }
}
@media screen and (max-width: 600px) {
  .Food_resto_input_btn_wrappper {
    width: 100%;
    margin-top: 30px;
  }
}

.Food_resto_input {
  padding: 6px 15px;
  background-color: #fff;
  border-radius: 24px !important;
  border: none;
}
.Food_resto_input input {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
  color: rgba(51, 51, 51, 0.5);
}
@media screen and (max-width: 600px) {
  .Food_resto_input {
    padding: 4px 14px;
  }
  .Food_resto_input input {
    font-size: 14px;
  }
}
@media screen and (max-width: 300px) {
  .Food_resto_input {
    padding: 2px 12px;
  }
  .Food_resto_input input {
    font-size: 12px;
  }
}

.Food_resto_btn {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: bold !important;
  background-color: #feab0b !important;
  padding: 0px 30px !important;
  text-transform: capitalize !important;
  border-radius: 24px !important;
  cursor: pointer !important;
  height: 100% !important;
}
@media screen and (max-width: 600px) {
  .Food_resto_btn {
    padding: 0px 24px !important;
  }
  .Food_resto_btn span {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 300px) {
  .Food_resto_btn {
    padding: 0px 20px !important;
  }
  .Food_resto_btn span {
    font-size: 12px !important;
  }
}

.restaurant_popular_categories_section {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  background-color: rgba(244, 237, 226, 0.65);
}

.submit_restaurant_bubble {
  position: absolute;
  right: -30px;
  bottom: 240px;
  width: 236px;
  height: 234px;
  z-index: -1;
}

.submit_restaurant_rings {
  position: absolute;
  left: -289px;
  bottom: -289px;
  width: 436px;
  height: 436px;
  z-index: -1;
}

.restaurant_category_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.restaurant_green_background_heading_new {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  padding: 2px 10px;
  border-radius: 8px;
  background-color: #5eae53;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  line-height: 2;
  text-align: center;
}
@media screen and (max-width: 300px) {
  .restaurant_green_background_heading_new {
    font-size: 12px;
  }
}

.popular_categories_dish_card {
  border-radius: 15px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
  cursor: pointer;
  outline: none;
}

.popular_categories_dish_card_selected {
  background-color: #feab0b;
  box-shadow: 10px 20px 40px 0 rgba(97, 49, 0, 0.2);
}
.popular_categories_dish_card_selected .dish_count {
  background-color: #5eae53;
}
.popular_categories_dish_card_selected .dish_name_price_wrapper h6 {
  color: #ffffff;
}
.popular_categories_dish_card_selected .dish_name_price_wrapper p {
  color: rgba(255, 255, 255, 0.5);
}

.heading_wrapper h4 {
  margin: 30px 0px;
  font-weight: 500;
  font-family: "AllRoundGothicW01-Book";
  font-size: 38px;
  color: #333333;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .heading_wrapper h4 {
    font-size: 30px;
    margin: 20px 0px;
  }
}
@media screen and (max-width: 300px) {
  .heading_wrapper h4 {
    font-size: 22px;
    margin: 15px 0px;
  }
}

.heading_wrapper_res .restaurant_big_heading {
  font-family: "AllRoundGothicW01-Book";
}

.dish_categories_content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 12px;
}

.dish_img_wrapper {
  height: 120px;
  width: 120px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.dish_img_wrapper img {
  width: 40px;
  height: 40px;
}
@media screen and (max-width: 500px) {
  .dish_img_wrapper {
    height: 90px;
    width: 90px;
  }
  .dish_img_wrapper img {
    height: 30px;
    width: 30px;
  }
}

.dish_count {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #afd3aa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 500px) {
  .dish_count {
    height: 30px;
    width: 30px;
  }
}

.dish_name_price_wrapper {
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.dish_name_price_wrapper h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  padding-bottom: 5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dish_name_price_wrapper p {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.3);
  text-align: center;
  line-height: 1.71;
}
@media screen and (max-width: 500px) {
  .dish_name_price_wrapper h6 {
    font-size: 17px;
  }
  .dish_name_price_wrapper p {
    font-size: 13px;
  }
}

.restaurant_middle_content_wrapper {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media screen and (max-width: 600px) {
  .restaurant_middle_content_wrapper {
    margin-top: 25px;
  }
}
@media screen and (max-width: 300px) {
  .restaurant_middle_content_wrapper {
    margin-top: 10px;
  }
}

.restaurant_big_heading {
  font-family: "AllRoundGothicW01-Book";
  font-size: 52px;
  line-height: 1.14;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 500;
}
@media screen and (max-width: 600px) {
  .restaurant_big_heading {
    font-size: 38px;
  }
}
@media screen and (max-width: 300px) {
  .restaurant_big_heading {
    font-size: 34px;
    margin: 15px 0px;
  }
}

.restaurant_middle_text {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.5);
}

.restaurant_view_all_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 8px;
  gap: 8px;
}
.restaurant_view_all_content .restaurant_view_all_btn {
  text-transform: none;
  border-radius: 20px;
  padding: 5px 20px;
}
.restaurant_view_all_content .restaurant_view_all_btn span {
  font-size: 17px;
  color: #333;
}

.restaurants_arrow_icons_wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #feab0b !important;
}
@media screen and (max-width: 300px) {
  .restaurants_arrow_icons_wrapper {
    height: 30px !important;
    width: 30px !important;
  }
}

.restaurants_arrow_icons_disable {
  opacity: 0.9;
}

.restaurants_arrow_icons {
  color: #ffffff;
  font-size: 19px;
}
@media screen and (max-width: 300px) {
  .restaurants_arrow_icons {
    font-size: 16px !important;
  }
}

.restaurant_famous_dish_card {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: relative;
  cursor: pointer;
}

.famous_dish_img_wrapper {
  width: 100%;
}
.famous_dish_img_wrapper img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  height: 100%;
}

.restaurant_star_rating_and_text_wrapper {
  padding: 10px;
  border-radius: 8px;
  background-color: #feab0b;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  width: 100px;
  position: absolute;
  top: 20px;
  left: 20px;
}
.restaurant_star_rating_and_text_wrapper p {
  color: white;
  font-size: 14px;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .restaurant_star_rating_and_text_wrapper {
    padding: 6px;
    width: 70px;
    top: 15px;
    left: 15px;
  }
  .restaurant_star_rating_and_text_wrapper h6 {
    font-size: 8px;
  }
}
@media screen and (max-width: 300px) {
  .restaurant_star_rating_and_text_wrapper {
    padding: 6px;
    width: 60px;
  }
  .restaurant_star_rating_and_text_wrapper h6 {
    font-size: 10px;
  }
}

.star_icon_resto {
  font-size: 15px;
  font-weight: bold;
}

.famous_dish_contents {
  padding: 20px;
}
.famous_dish_contents h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 600px) {
  .famous_dish_contents {
    padding: 12px 14px;
  }
  .famous_dish_contents h6 {
    font-size: 19px;
  }
}
@media screen and (max-width: 300px) {
  .famous_dish_contents {
    padding: 15px;
  }
  .famous_dish_contents h6 {
    font-size: 18px;
  }
}

.famous_dish_timing_and_name_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 14px;
}
.famous_dish_timing_and_name_wrapper p {
  font-family: "Montserrat", sans-serif;
  color: #333333;
  font-size: 17px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.famous_dish_timing_and_name_wrapper span {
  font-family: "Montserrat", sans-serif;
  color: rgba(51, 51, 51, 0.5);
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media screen and (max-width: 768px) {
  .famous_dish_timing_and_name_wrapper {
    margin-top: 5px;
  }
  .famous_dish_timing_and_name_wrapper p {
    font-size: 15px;
  }
  .famous_dish_timing_and_name_wrapper span {
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .famous_dish_timing_and_name_wrapper {
    margin-top: 8px;
    margin-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
  }
  .famous_dish_timing_and_name_wrapper p {
    font-size: 14px;
  }
  .famous_dish_timing_and_name_wrapper span {
    font-size: 15px;
    text-align: end;
    width: 100%;
  }
}

.woman_chef_img_wrapper {
  width: 100%;
}
.woman_chef_img_wrapper img {
  width: 100%;
  height: auto;
}

.restaurant_list_content_wrapper {
  margin-top: 70px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.res_bottom_text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
  color: rgba(51, 51, 51, 0.5);
  margin-bottom: 10px;
}

.restaurant_submit_green_btn {
  background-color: #5eae53 !important;
  padding: 4px 9px !important;
  border-radius: 8px !important;
}

.restaurant_submit_green_btn_color {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.submit_restaurant_heading {
  font-family: "Montserrat", sans-serif;
  font-size: 52px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: normal;
  color: #333;
  margin-top: 20px;
  margin-bottom: 15px;
}
@media screen and (max-width: 600px) {
  .submit_restaurant_heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 300px) {
  .submit_restaurant_heading {
    font-size: 22px;
  }
}

.rest_submit_orange_btn {
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 24px !important;
  background-color: #feab0b !important;
  padding: 10px 25px !important;
  text-transform: capitalize !important;
  width: 150px !important;
}
@media screen and (max-width: 300px) {
  .rest_submit_orange_btn {
    font-size: 10px;
    padding: 6px 18px !important;
  }
}

.restaurant_list_heading_wrapper {
  margin-bottom: 35px !important;
}
@media screen and (max-width: 600px) {
  .restaurant_list_heading_wrapper {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 300px) {
  .restaurant_list_heading_wrapper {
    margin-bottom: 10px !important;
  }
}
.restaurant_categories_slider .slick-arrow.slick-prev,
.restaurant_categories_slider .slick-arrow.slick-next {
  background: #000;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 50%;
  opacity: 0.8;
}
.restaurant_categories_slider .slick-arrow.slick-prev svg,
.restaurant_categories_slider .slick-arrow.slick-next svg {
  color: #fff;
  font-size: 20px;
}
.restaurant_categories_slider .slick-prev {
  left: -22px;
  padding-right: 2px;
}
@media screen and (max-width: 800px) {
  .restaurant_categories_slider .slick-prev {
    left: -15px;
  }
}
.restaurant_categories_slider .slick-next {
  right: -22px;
  padding-left: 2px;
}
@media screen and (max-width: 800px) {
  .restaurant_categories_slider .slick-next {
    right: -15px;
  }
}
.restaurant_categories_slider .slick-prev::before,
.restaurant_categories_slider .slick-next::before {
  display: none;
}
.restaurant_categories_slider .custom-indicator {
  bottom: -65px;
}
.restaurant_categories_slider .custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}
.restaurant_categories_slider .custom-indicator li.slick-active {
  filter: grayscale(0%);
}
.restaurant_categories_slider .multi__image:hover {
  transform: scale(1.1);
  transition: 500ms;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customDropdown .userAvtar {
  height: 40px;
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
}
.customDropdown .btnGroup {
  position: relative;
  cursor: pointer;
}
.customDropdown .btnGroup::before {
  content: " ";
  position: absolute;
  right: -5px;
  top: 20px;
  border: 0px;
  border-left: 3px solid #bababa;
  border-bottom: 3px solid #bababa;
  border-top: 0px solid transparent;
  height: 10px;
  width: 10px;
  transform: rotate(-45deg);
  transition: transform 0.3s;
}
.customDropdown .btnGroupOpenMenu::before {
  transform: rotate(135deg);
}
.customDropdown .MuiButton-root:hover {
  background-color: transparent;
}

.menu-customize .MuiPaper-rounded {
  border-radius: 10px;
}
.menu-customize .MuiMenuItem-root:hover {
  background-color: #fff;
  color: #20a258;
}
.menu-customize .MuiMenuItem-root {
  text-transform: capitalize !important;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.11px;
  font-weight: 500;
  color: #000;
}

.option-dropDown {
  width: 100%;
}
.option-dropDown .customDropdown .userAvtar {
  display: none;
}
.option-dropDown .customDropdown .MuiMenuItem-root {
  text-transform: capitalize;
}
.option-dropDown .customDropdown .btnGroup {
  position: relative;
  height: 30px;
}
.option-dropDown .customDropdown .btnGroup::before {
  content: "...";
  position: absolute;
  text-align: center;
  left: 0px;
  top: -5px;
  border: 0px;
  overflow: hidden;
  color: #bababa;
  font-size: 20px;
  font-weight: 900;
  height: 100%;
  width: 100%;
  transform: rotate(0deg);
}
.option-dropDown .customDropdown .MuiButton-root:hover {
  background-color: transparent;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

@media screen and (max-width: 1024px) {
  .cardContainer.MuiGrid-spacing-xs-5 {
    margin: 0 -20px;
    padding-top: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer.MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 2px 22px;
  }
}
.cardContainer .card {
  width: 100%;
}
.cardContainer .card.MuiCard-root {
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 1600px) {
  .cardContainer .card.MuiCard-root {
    padding: 15px;
  }
}
.cardContainer .card.MuiCard-root .cardContent {
  padding: 0;
}
.cardContainer .card.MuiCard-root .cardContent .h6 {
  color: #c1c1c1;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
  padding-bottom: 5px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root .cardContent .h6 {
    font-size: 13px;
  }
}
.cardContainer .card.MuiCard-root .priceRow {
  display: flex;
  align-items: center;
}
.cardContainer .card.MuiCard-root .priceRow .h1 {
  color: #394053;
  font-size: 30px;
  font-weight: 600;
  padding-right: 10px;
}
@media screen and (max-width: 1600px) {
  .cardContainer .card.MuiCard-root .priceRow .h1 {
    font-size: 19px;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.MuiCard-root .priceRow .h1 {
    font-size: 17px;
  }
}
.cardContainer .card.MuiCard-root .iconCircle {
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(103, 103, 177);
}
.cardContainer .card.restDetailsCard {
  cursor: pointer;
}
.cardContainer .card.restDetailsCard.MuiCard-root {
  background-color: #ffffff;
  padding: 15px 25px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  border: 1px solid #c1c1c1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-evenly;
}
.cardContainer .card.restDetailsCard.MuiCard-root.activeRestDetailCard {
  background-color: #edf8f2;
  border: 1px solid #59bd83;
}
.cardContainer .card.restDetailsCard .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cardContainer .card.restDetailsCard .header .MuiSvgIcon-root {
  color: #59bd83;
  font-size: 40px;
}
.cardContainer .card.restDetailsCard .header .h6 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-left: 20px;
  width: 100%;
  line-height: 1.1;
  text-align: unset;
}
.cardContainer .card.restDetailsCard .description {
  margin-top: 10px;
}
.cardContainer .card.restDetailsCard .description p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.cardContainer .card.choseCard {
  position: relative;
}
.cardContainer .card.choseCard.MuiCard-root {
  background-color: #ffffff;
  padding: 50px 0 0 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px 0px #c1c1c1;
}
.cardContainer .card.courseCard {
  flex-direction: column;
  padding: 15px;
  height: 100%;
  box-shadow: 0px 16px 20px 0px rgba(204, 204, 204, 0.5803921569);
}
.cardContainer .card.courseCard .MuiCardMedia-img {
  border-radius: 15px;
}
.cardContainer .card.courseCard .cardContent {
  width: 100%;
  padding-top: 20px;
}
.cardContainer .card.courseCard .cardContent p {
  font-weight: 400;
  color: #c1c1c1;
  padding-top: 10px;
  word-spacing: 2px;
  line-height: 25px;
  font-size: 17px;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.courseCard .cardContent p {
    font-size: 14px;
  }
}
.cardContainer .card.courseCard .CardActions {
  width: 100%;
  padding: 20px 0;
}
.cardContainer .card.purchaseCard {
  margin-bottom: 0;
  flex-direction: column !important;
  padding: 15px;
  box-shadow: 0px 16px 20px 0px rgba(204, 204, 204, 0.5803921569) !important;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.purchaseCard {
    margin-bottom: 30px;
  }
}
.cardContainer .card.purchaseCard .imgContainer {
  width: 100%;
  position: relative;
}
.cardContainer .card.purchaseCard .imgContainer .rating {
  position: absolute;
  top: 10px;
  left: 10px;
}
.cardContainer .card.purchaseCard .imgContainer .rating .MuiRating-iconEmpty {
  color: white;
}
.cardContainer .card.purchaseCard .courseImg {
  border-radius: 15px;
}
.cardContainer .card.purchaseCard .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.purchaseCard .MuiTypography-h5 {
  font-size: 20px;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .MuiTypography-h5 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard h4 {
    font-size: 16px;
  }
}
.cardContainer .card.purchaseCard .cardContent {
  padding: 20px 20px 0 20px !important;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .cardContent {
    padding: 5px 5px 0 5px !important;
  }
}
.cardContainer .card.purchaseCard .cardContent .MuiTypography-body2 {
  color: #c3c3c3;
  margin: 4px 0;
}
.cardContainer .card.purchaseCard .CardActions {
  justify-content: space-between;
  width: 100%;
  padding: 20px !important;
}
@media screen and (max-width: 1440px) {
  .cardContainer .card.purchaseCard .CardActions {
    padding: 5px 5px 0 5px !important;
  }
}
.cardContainer .card.receiptCard {
  margin-bottom: 0;
  flex-direction: column !important;
  padding: 0px;
  box-shadow: 0px 0px 10px 0px #c3c3c3;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard {
    margin-bottom: 30px;
  }
}
.cardContainer .card.receiptCard .courseImg {
  border-radius: 15px 0 0 0;
}
.cardContainer .card.receiptCard .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.receiptCard .MuiTypography-h4 {
  font-size: 28px;
  color: #59bd83;
  font-weight: 600;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .MuiTypography-h4 {
    font-size: 16px;
  }
}
.cardContainer .card.receiptCard .cardContent {
  padding: 20px 20px 0 20px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .cardContent {
    padding: 20px !important;
  }
}
.cardContainer .card.receiptCard .cardContent .receiptDate {
  position: absolute;
  top: -40px;
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
  font-size: 18px;
  font-weight: 600;
}
.cardContainer .card.receiptCard .CardActions {
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 40px 20px !important;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions {
    padding: 20px !important;
  }
}
.cardContainer .card.receiptCard .CardActions .actionRow {
  display: flex;
  align-items: center;
  margin-left: 0;
}
@media screen and (max-width: 1024px) {
  .cardContainer .card.receiptCard .CardActions .actionRow {
    margin-bottom: 10px;
  }
}
.cardContainer .card.receiptCard .CardActions .actionRow h5 {
  font-weight: 600;
  padding: 0 5px;
}
.cardContainer .card.receiptCard .CardActions .actionRow .actionIcons {
  height: 30px;
  width: 30px;
}
.cardContainer .card.reviewCard {
  padding: 20px;
  border-radius: 15px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #e2dada;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .cardContainer .card.reviewCard {
    flex-direction: column;
    align-items: center;
  }
}
.cardContainer .card.reviewCard .profileImg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid #515156;
}
.cardContainer .card.reviewCard .cardContent {
  flex-direction: column;
  width: 100%;
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  .cardContainer .card.reviewCard .cardContent {
    padding-left: 0;
    padding-top: 20px;
  }
}
.cardContainer .card.reviewCard .cardContent .topRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cardContainer .card.reviewCard .cardContent .topRow h5 {
  font-weight: bold;
}
.cardContainer .card.reviewCard .cardContent .ratingRow {
  margin: 15px 0;
  display: flex;
}
.cardContainer .card.reviewCard .cardContent .ratingRow .MuiRating-icon {
  font-size: 30px;
}
.cardContainer .card.reviewCard .cardContent .ratingRow .MuiRating-iconFilled {
  color: #ec9e31;
}
.cardContainer .card.reviewCard .cardContent p {
  font-size: 17px;
}
.cardContainer .card.studentCard {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 1px 1px 9px #c1c1c1;
  padding: 30px;
  margin-bottom: 0;
}
.cardContainer .card.studentCard .MuiCardHeader-root {
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span {
  font-family: "AllRoundGothicW01-Medium";
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span:nth-child(1) {
  color: #394053;
  font-weight: 600;
}
.cardContainer .card.studentCard .MuiCardHeader-root .MuiCardHeader-content span:nth-child(2) {
  color: #808080;
}
.cardContainer .card.studentCard .MuiCardContent-root {
  padding: 20px 0 0 0;
}
.cardContainer .card.studentCard .MuiCardContent-root p {
  color: #808080;
}
.cardContainer .card.coursePlayCard {
  box-shadow: 1px 1px 20px 0px rgba(204, 204, 204, 0.5803921569);
  padding: 15px 15px 150px 15px;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .cardContainer .card.coursePlayCard {
    border: 0;
    box-shadow: none;
    padding: 0;
  }
}

.educatorCard.MuiCard-root {
  width: 100%;
  border-radius: 8px 8px 0 0;
}
.educatorCard.MuiCard-root .MuiCardMedia-media {
  height: 300px;
}
.educatorCard.MuiCard-root .cardContent {
  background-color: #ffffff;
  margin-top: -20px;
  border-radius: 8px 8px 0 0;
}
.educatorCard.MuiCard-root .cardContent .MuiTypography-h5 {
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  word-spacing: 2px;
  letter-spacing: 2px;
}
.educatorCard.MuiCard-root .cardContent .MuiTypography-body2 {
  text-align: center;
  font-size: 13px;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.tableContainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #ffffff;
  padding-right: 0;
}
.tableContainer::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer td {
  font-family: "Montserrat", sans-serif;
  font-size: 14px !important;
}
.tableContainer td h5 {
  font-size: 14px !important;
}
.tableContainer .MuiTableCell-stickyHeader {
  position: unset;
}
.tableContainer .tableHeaderContainer.MuiTableContainer-root {
  height: 500px !important;
  min-height: 500px !important;
}
.tableContainer .tableBodyContainer.MuiTableContainer-root {
  height: 500px !important;
  min-height: 500px !important;
}
.tableContainer .headerListContainer {
  padding: 20px;
}
.tableContainer .headerListContainer .prodListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 1299px) {
  .tableContainer .headerListContainer .prodListHeader {
    flex-wrap: wrap;
  }
}
.tableContainer .headerListContainer .prodListHeader .link {
  font-weight: 600;
  color: #394053;
}
.tableContainer .headerListContainer .prodListHeader .link:hover {
  color: #20a258;
  text-decoration: none;
}
.tableContainer .headerListContainer .prodListHeader h4,
.tableContainer .headerListContainer .prodListHeader h6 {
  font-weight: bold;
  white-space: nowrap;
}
.tableContainer .headerListContainer .prodListHeader .blocks {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .blocks {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
.tableContainer .headerListContainer .prodListHeader .orderblocks {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .orderblocks {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .orderblocks {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.tableContainer .headerListContainer .prodListHeader .block1 .btnContainer .MuiButton-label {
  color: #fff;
  text-transform: capitalize;
  padding: 5px 0px;
}
@media screen and (max-width: 1299px) {
  .tableContainer .headerListContainer .prodListHeader .block1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    margin: 10px 0;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0px !important;
    margin: 10px 0;
    align-items: flex-start;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .addButton {
  padding-left: 10px;
}
@media screen and (max-width: 767px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root {
    width: 100%;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label .plus_icon::after {
    position: relative;
    left: -10px;
  }
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton .customButton .MuiButton-root .MuiButton-label .plus_icon::before {
    position: relative;
    left: -10px;
    top: 4px;
  }
}
@media screen and (max-width: 600px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .addButton {
    width: 100%;
    padding-left: 0px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .rightOrders {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .rightOrders {
    margin-top: 10px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .text {
  padding-right: 10px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 20px;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .text {
    width: 100px;
  }
}
@media screen and (max-width: 560px) {
  .tableContainer .headerListContainer .prodListHeader .block1 .text {
    margin-right: 24px;
    font-size: 14px;
    width: 100px;
  }
}
.tableContainer .headerListContainer .prodListHeader .block1 .customSelect .MuiFormControl-root {
  width: 100%;
}
.tableContainer .headerListContainer .prodListBottomRow {
  margin-top: 20px;
  display: flex;
}
.tableContainer .headerListContainer .prodListBottomRow .MuiInput-formControl {
  margin-top: 0;
}
.tableContainer .headerListContainer .prodListBottomRow .MuiInputLabel-root {
  left: 13px;
  color: #ccc;
  font-weight: 500;
  transform: translate(0, 16.5px) !important;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .block1 {
  padding-left: 20px;
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect .customSelect {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .block1 .outlineSelect .customSelect .MuiFormControl-root {
    width: 100%;
  }
}
.tableContainer .headerListContainer .prodListBottomRow .outlineTextfield {
  width: 83%;
}
@media screen and (max-width: 1024px) {
  .tableContainer .headerListContainer .prodListBottomRow .outlineTextfield {
    width: 100%;
  }
}
.tableContainer.MuiTableContainer-root {
  box-shadow: 0px 0px 4px 0px #ffffff;
  overflow: inherit;
  overflow-x: auto;
}
.tableContainer.MuiTableContainer-root .tableHeader {
  padding: 0 5px;
}
.tableContainer.MuiTableContainer-root .tableHeader h6, .tableContainer.MuiTableContainer-root .tableHeader a {
  font-weight: 600;
  color: #394053;
}
.tableContainer.MuiTableContainer-root .tableHeader h6:hover, .tableContainer.MuiTableContainer-root .tableHeader a:hover {
  color: #20a258;
  text-decoration: none;
}
.tableContainer.MuiTableContainer-root .tableHead {
  z-index: 5;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -1px !important;
  font-size: 11px;
  color: #44444f;
}
.tableContainer.MuiTableContainer-root .tableHead .tableHead-menu-column {
  width: 50px !important;
}
.tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
  padding: 5px 10px;
  background-color: #e3eaf7;
  color: #808080;
  font-weight: 600;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  border-top: 1px solid rgb(224, 224, 224);
}
@media screen and (max-width: 768px) {
  .tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
    padding: 5px 20px;
  }
}
.tableContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:first-child {
  width: 200px;
  padding-left: 20px;
}
.tableContainer.MuiTableContainer-root .tableHead.dashBoadTableHead {
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.tableContainer.MuiTableContainer-root .tableHead.dashBoadTableHead .MuiTableCell-head {
  text-transform: none;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body {
  color: #515156;
  font-size: 17px;
  white-space: nowrap;
  padding: 20px 10px;
  cursor: pointer;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body:first-child {
  padding: 16px 20px;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body.BoldCell {
  font-weight: 600;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body.paymentCell {
  text-transform: uppercase;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body .productCell {
  display: flex;
  align-items: center;
}
.tableContainer.MuiTableContainer-root .MuiTableCell-body .productCell h5 {
  padding-left: 30px;
  font-weight: bold;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.tableContainer .productHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0px;
  margin-left: -5px;
}
.tableContainer .productHead span {
  padding-left: 24px;
}
.tableContainer .productHead span:nth-child(2) {
  font-family: "Poppins", sans-serif !important;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: #44444f;
}
.tableContainer .checkboxnameCell {
  display: flex;
  align-items: center;
}
.tableContainer .checkboxnameCell .customCheckbox {
  margin-right: 20px;
}
.tableContainer .checkboxnameCell span:nth-child(2) {
  color: #515156;
}
.tableContainer .MuiTableCell-body {
  color: #515156;
  font-size: 17px;
  white-space: nowrap;
}
.tableContainer .MuiTableCell-body.BoldCell {
  font-weight: 600;
}
.tableContainer .MuiTableCell-body.paymentCell {
  text-transform: uppercase;
}
.tableContainer .MuiTableCell-body .productCell {
  display: flex;
  align-items: center;
  width: 100%;
}
.tableContainer .MuiTableCell-body .productCell h5 {
  padding-left: 30px;
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  letter-spacing: 0.23px;
  color: #171725;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.tableContainer .MuiTableCell-body .productCell p {
  padding-left: 20px;
  font-weight: bold;
  font-size: 15px;
  width: 200px;
  word-break: break-word;
  word-wrap: wrap;
  white-space: pre-wrap;
}
.tableContainer .outlineChip .customChips.MuiChip-root {
  background-color: transparent;
  border: 1px solid #6ddfba;
}
.tableContainer .outlineChip .customChips.MuiChip-root span {
  color: #6ddfba;
  text-transform: uppercase;
}
.tableContainer .prodImage {
  height: 80px;
  width: 80px;
  object-fit: fill;
  border-radius: 8px;
}
.tableContainer .table-block .tableHead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}
.tableContainer .table-block .tableHead .MuiTableCell-head {
  padding: 5px 0;
  background-color: #e3eaf7;
  color: #44444f;
  font-weight: 600;
  width: 100px;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif !important;
  font-size: 11px;
  letter-spacing: 0.8px;
}
.tableContainer .table-block .tableHead .MuiTableCell-head:first-child {
  width: 200px;
  padding-left: 20px;
}
.tableContainer .table-block .tableHead .MuiTableCell-head:nth-child(4) {
  text-align: center;
}
.tableContainer .table-block .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body .productCell {
  width: 100%;
}
.tableContainer .table-block .MuiTableContainer-root {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 4px 0px #ffffff;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer .table-block .MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.tableContainer.analyticsTable {
  width: 100%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
  background-color: #ffffff;
  color: #394053;
  font-size: 12px;
  letter-spacing: 2px;
  padding-left: 0;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .tableContainer.analyticsTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
    padding: 5px 15px;
  }
}
.tableContainer.analyticsTable.MuiTableContainer-root .MuiTableCell-body {
  padding: 8px 0;
  border: 0;
}
.tableContainer.analyticsTable.MuiTableContainer-root .MuiTypography-body1 {
  color: #394053;
  font-size: 15px;
  white-space: nowrap;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell:first-child {
  grid-gap: 20px;
  gap: 20px;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell p {
  padding: 0;
  font-weight: normal;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentSuccessIcon {
  background-color: #6ddfba;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentFailIcon {
  background-color: red;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentRefundIcon {
  background-color: #ff8d8d;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer.analyticsTable.MuiTableContainer-root .productCell .paymentProcessIcon {
  background-color: #5babfb;
  color: #ffffff;
  padding: 5px;
  font-size: 30px;
  border-radius: 50%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 40%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 10%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 10%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .restforums .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(6) {
  width: 5%;
}
.tableContainer .restforums .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
  font-weight: normal;
}
.tableContainer .restforums .prodImage {
  margin-left: 20px;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(2) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(3) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body:nth-child(4) {
  font-weight: normal;
}
.tableContainer .prodListTabel .MuiTableContainer-root .prodImage {
  margin-left: 20px;
}
.tableContainer .restDishesTable {
  border-radius: 0px !important;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 40%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 10%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 10%;
}
.tableContainer .restDishesTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .restDishesTable .prodImage {
  margin-left: 20px;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 20%;
}
.tableContainer .adminLearningCourseTable .MuiTableContainer-root h5 {
  font-size: 17px;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 35%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 20%;
}
.tableContainer .custListTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 5%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(1) {
  width: 30%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(2) {
  width: 8%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(3) {
  width: 20%;
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
  width: 39%;
}
@media screen and (max-width: 768px) {
  .tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(4) {
    padding-left: 20px;
  }
}
.tableContainer .campTable .MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  width: 3%;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar {
  width: 100%;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale span {
  color: #394053;
  font-size: 12px;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .fundScale span:nth-child(2) {
  margin: 0 10px;
}
.tableContainer .campTable .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-body .fundProgressBar .customLinearProgressbar {
  width: 100%;
}
.tableContainer .campTable .prodImage {
  margin-left: 20px;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .tableHead .MuiTableCell-head {
  width: 100px;
  text-align: left;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .tableHead .MuiTableCell-head:nth-child(5) {
  text-align: center;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .MuiTableCell-body {
  text-align: left;
  font-size: 15px;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root .MuiTableCell-body.accordianCell {
  padding: 0;
  border: 0;
}
.tableContainer.restOrderListContainer.MuiTableContainer-root h5 {
  font-size: 15px;
  font-weight: 700;
}
.tableContainer.purchaseTable.MuiTableContainer-root .tableHead .MuiTableCell-head {
  padding-right: 50px;
}
.tableContainer.purchaseTable.MuiTableContainer-root .MuiTableCell-body {
  padding-right: 50px;
}
.tableContainer.learningPurchasesContainer .tableHead {
  z-index: 5;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -1px !important;
  font-size: 11px;
  color: #44444f;
}
.tableContainer.learningPurchasesContainer .tableHead .MuiTableCell-head {
  text-align: left !important;
}
.tableContainer.learningPurchasesContainer .dropDownCell {
  text-align: right !important;
}
.tableContainer.learningPurchasesContainer .course-purchase-row {
  display: flex;
  align-items: center;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .VideoInput_video, .tableContainer.learningPurchasesContainer .course-purchase-row .techPurchase {
  height: 50px;
  width: 80px;
  object-fit: cover;
  border-radius: 15px;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .customerPurchaseDefaultCourse {
  height: 50px;
  width: 80px;
  object-fit: cover;
  border-radius: 15px;
}
.tableContainer.learningPurchasesContainer .course-purchase-row .customerPurchase {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.tableContainer.learningPurchasesContainer .course-purchase-row h5 {
  margin-left: 10px;
  width: 150px;
  white-space: normal;
  line-height: 25px;
}

.blocks h6 {
  color: #333;
}

.course-purchase-dialog-row {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.course-purchase-dialog-row .VideoInput_video, .course-purchase-dialog-row .techPurchase {
  width: 240px;
  object-fit: cover;
  border-radius: 15px;
}
.course-purchase-dialog-row .customerPurchaseDefaultCourse {
  width: 240px;
  object-fit: cover;
  border-radius: 15px;
}
.course-purchase-dialog-row .customerPurchase {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.course-purchase-dialog-row h5 {
  margin-left: 10px;
  width: 150px;
  white-space: normal;
  line-height: 25px;
}

.viewLink {
  color: #20a258;
  text-decoration: underline;
}
.viewLink:hover {
  color: #5babfb;
}

@media screen and (max-width: 772px) {
  .subModalBlockPurchase {
    padding-bottom: 20px;
  }
}

.learningCourseTitle {
  width: 200px !important;
  word-break: break-word !important;
  word-wrap: wrap !important;
  white-space: pre-wrap !important;
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.chartContainer {
  margin-bottom: 25px;
  width: 100%;
  background-color: white;
  border-radius: 20px;
}
.chartContainer .card.MuiCard-root {
  padding: 15px 20px 0px 20px;
}
.chartContainer.cardContainer {
  padding-top: 0;
}
@media screen and (max-width: 1024px) {
  .chartContainer {
    margin: 10px 0 25px 0;
  }
}
.chartContainer h5 {
  font-size: 18px;
  font-weight: bolder;
  white-space: nowrap;
}
.chartContainer .chartCard {
  width: 100%;
}
.chartContainer .chartCard .charthead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .chartContainer .chartCard .charthead {
    flex-direction: column;
  }
}
.chartContainer .chartCard .listMenu {
  display: flex;
}
@media screen and (max-width: 767px) {
  .chartContainer .chartCard .listMenu {
    margin-top: 10px;
  }
}
.chartContainer .chartCard .listMenu .listItems {
  margin: 0 15px;
  position: relative;
}
.chartContainer .chartCard .listMenu .listItems span {
  margin-left: 5px;
  color: #5d5e6a;
  font-size: 12px;
}
.chartContainer .chartCard .listMenu .listItems::before {
  content: " ";
  position: absolute;
  width: 13px;
  height: 15px;
  left: -15px;
  top: 5px;
  border-radius: 50%;
}
.chartContainer .chartCard .listMenu .listItems.primary::before {
  background-color: #5babfb;
}
.chartContainer .chartCard .listMenu .listItems.success::before {
  background-color: #20a258;
}
.chartContainer .header {
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
@media screen and (max-width: 580px) {
  .chartContainer .header {
    flex-direction: column;
    padding: 20px 20px;
  }
}
.chartContainer .header h3 {
  width: 100%;
  text-align: left;
  font-weight: 900;
}
.chartContainer .header .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 580px) {
  .chartContainer .header .button-container {
    margin-top: 14px;
  }
}
.chartContainer .header .button-container .graph-btn {
  background-color: #ffffff;
  color: #c1c1c1;
  font-weight: 500;
  padding: 5px 15px;
  border-radius: 8px;
  outline: none;
  border: none;
  margin: 0 2px;
}
.chartContainer .header .button-container .graph-btn.active {
  color: #ffffff;
  background-color: #5babfb;
}
.chartContainer .dashboardChart {
  width: 100%;
  height: 350px;
}
@media screen and (max-width: 580px) {
  .chartContainer .dashboardChart {
    height: 250px;
  }
}
.chartContainer .dashboardChart #lineChart {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 580px) {
  .chartContainer .dashboardChart #lineChart {
    padding: 20px 20px;
  }
}

@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

body,
html {
  background-color: #ffffff !important;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
}
body::-webkit-scrollbar,
html::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
body::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

input[type=radio],
input[type=checkbox] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: #394053;
  font-family: "Montserrat", sans-serif;
}

.h1 {
  color: #394053;
  font-size: 30px;
  font-weight: 600;
  padding-right: 10px;
}
@media screen and (max-width: 1024px) {
  .h1 {
    font-size: 20px;
  }
}

h2 {
  color: #394053;
  font-size: 26px;
  font-weight: 100;
  font-family: "AllRoundGothicW01-Medium";
}
@media screen and (max-width: 1024px) {
  h2 {
    font-size: 18px;
  }
}

h4 {
  color: #394053;
  font-size: 22px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1024px) {
  h4 {
    font-size: 19px;
  }
}

.h6 {
  color: #c1c1c1;
  font-size: 15px;
  font-weight: 400;
  line-height: 12px;
}

span {
  color: #c3c3c3;
  font-family: "Montserrat", sans-serif;
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
@media screen and (max-width: 1024px) {
  p {
    font-size: 13px;
  }
}

a {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}

button,
a,
label {
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

.mainWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #e3e9f6;
  overflow: hidden;
}

.contentWrapper {
  width: 100%;
  overflow-y: auto;
  flex: 1 1;
  display: flex;
  padding: 20px 0;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .contentWrapper {
    padding: 8px 0;
  }
}
.contentWrapper .subContentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  background-color: #e3e9f6;
  border-radius: 20px;
}
.contentWrapper .subContentWrapper::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.contentWrapper .subContentWrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.contentWrapper .subContentWrapper::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
@media screen and (max-width: 1024px) {
  .contentWrapper .subContentWrapper {
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .contentWrapper .subContentWrapper {
    border-radius: 12px;
  }
}

.btn:focus {
  border: 0;
  box-shadow: none;
}
.btn.dropdown-toggle {
  padding: 0;
  position: relative;
}
.btn.dropdown-toggle::after {
  border: 0;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-color: #808080;
}

.searchIcon {
  color: #808080;
}

.form-group {
  position: relative;
  width: 100%;
}
.form-group .form-control {
  background-color: #f0f0f0;
  border: 0;
  padding: 12px;
  padding-left: 45px;
  border-radius: 10px;
  color: #c1c1c1;
}
.form-group .form-control::-webkit-input-placeholder {
  color: #c1c1c1;
}
.form-group .form-control::placeholder {
  color: #c1c1c1;
}
.form-group .form-control:focus {
  background-color: #f0f0f0;
  box-shadow: none;
}

.form-select {
  background-color: #20a258;
  color: #ffffff;
  background-image: none;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-menu {
  border-radius: 10px;
}
.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover {
  background-color: rgba(109, 223, 186, 0.2117647059);
}

.chartContainer {
  margin-bottom: 25px;
}
@media screen and (max-width: 1024px) {
  .chartContainer {
    margin: 10px 0 25px 0;
  }
}
.chartContainer h5 {
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}
.chartContainer .chartCard {
  width: 100%;
}
.chartContainer .chartCard .charthead {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .chartContainer .chartCard .charthead {
    flex-direction: column;
  }
}
.chartContainer .chartCard .listMenu {
  display: flex;
}
@media screen and (max-width: 767px) {
  .chartContainer .chartCard .listMenu {
    margin-top: 10px;
  }
}
.chartContainer .chartCard .listMenu .listItems {
  margin: 0 15px;
  position: relative;
}
.chartContainer .chartCard .listMenu .listItems span {
  margin-left: 5px;
  color: #5d5e6a;
  font-size: 12px;
}
.chartContainer .chartCard .listMenu .listItems::before {
  content: " ";
  position: absolute;
  width: 13px;
  height: 15px;
  left: -15px;
  top: 5px;
  border-radius: 50%;
}
.chartContainer .chartCard .listMenu .listItems.primary::before {
  background-color: #5babfb;
}
.chartContainer .chartCard .listMenu .listItems.success::before {
  background-color: #20a258;
}

.farms_all_pages_container {
  width: 100vw;
  height: auto;
  background-color: #ffffff;
  padding-top: 30px !important;
  padding-bottom: 70px !important;
}
@media only screen and (max-width: 600px) {
  .farms_all_pages_container {
    padding-bottom: 30px !important;
    padding-top: 10px !important;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.shopping_cart_header {
  margin-top: 30px;
}
.shopping_cart_header h3 {
  font-family: "AllRoundGothicW01-Book";
  font-size: 26px;
  color: #05172c;
}

.empty_cart_container {
  min-height: 55vh;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  margin-top: 15px !important;
}
.empty_cart_container img {
  height: 180px;
}
@media screen and (max-width: 600px) {
  .empty_cart_container img {
    height: 110px;
  }
}
@media screen and (max-width: 300px) {
  .empty_cart_container img {
    height: 90px;
  }
}
.empty_cart_container div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px 25px;
}
.empty_cart_container p {
  font-size: 24px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #20a258;
  text-align: center;
}
.empty_cart_container h6 {
  width: 70%;
  text-align: center;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: rgba(51, 51, 51, 0.5);
}
@media screen and (max-width: 600px) {
  .empty_cart_container {
    min-height: 40vh;
  }
}

.shopping_cart_separator {
  border-bottom: 1px solid #d2d2d2;
  margin: 50px 0px;
}

.shopping_cart_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
.shopping_cart_loader_container svg {
  color: #20a258;
}

.all_cart_detail_container {
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  padding: 40px 30px;
  border-radius: 15px;
}
.all_cart_detail_container .all_cart_detail_heading {
  font-weight: 500 !important;
  padding-bottom: 8px;
  color: #000000;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}
.all_cart_detail_container .all_cart_detail_content {
  color: #000000;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.all_cart_detail_container .all_cart_detail_grid_heading {
  padding: 5px 0px !important;
}
.all_cart_detail_container .all_cart_detail_grid {
  padding: 0px !important;
}

.coupon_code_container {
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  padding: 35px 20px;
  border-radius: 15px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.cart_payment_method_title {
  font-weight: 600 !important;
  padding-bottom: 10px;
  color: #000000;
  font-size: 18px;
  text-transform: capitalize;
}

.cart_payment_details {
  display: flex;
  justify-content: space-between;
}
.cart_payment_details p {
  padding: 3px 0px;
  color: #000000;
}

.cart_total_payment_wrapper {
  border-top: 1px solid #000000;
  padding: 10px 0px 0px !important;
  margin: 15px 0px 0px !important;
}
.cart_total_payment_wrapper p {
  font-weight: 600;
}

.radio_label {
  color: #000000 !important;
  font-family: "Montserrat", sans-serif !important;
}

.radio_checked {
  color: #20a258 !important;
}
.radio_checked span {
  color: #20a258 !important;
}
.radio_checked.radio_label {
  font-weight: 800 !important;
}

.radio_disabled {
  color: rgba(0, 0, 0, 0.38);
}

.payment_option_checkbox .Mui-checked .Mui-disabled {
  color: #20a258 !important;
}
.payment_option_checkbox .Mui-checked .MuiIconButton-label, .payment_option_checkbox .Mui-checked svg {
  color: #20a258;
}

.checked_radio_label {
  color: #000000 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
}

.radio_checked .radio_label {
  font-weight: 800 !important;
  color: black;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.shopping_cart_wrapper_box {
  box-shadow: 0 2px 12px 0 rgba(183, 189, 196, 0.5);
  padding: 20px;
  border-radius: 15px;
}

.web_input_field .MuiFilledInput-underline:before,
.web_input_field .MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.green_line {
  border-bottom: 3px solid #20a258 !important;
  margin: 10px 0px 20px;
  width: 100px;
}

.shopping_cart_details {
  display: flex;
  justify-content: space-between;
  font-family: "AllRoundGothicW01-Medium" !important;
  margin-bottom: 30px;
}
.shopping_cart_details h3 {
  font-family: "AllRoundGothicW01-Book";
  font-size: 28px;
  color: #333;
}
@media screen and (max-width: 1024px) {
  .shopping_cart_details h3 {
    font-size: 24px;
  }
}
@media screen and (max-width: 400px) {
  .shopping_cart_details h3 {
    font-size: 20px;
  }
}
.shopping_cart_details div {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.shopping_cart_details img {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}
@media screen and (max-width: 400px) {
  .shopping_cart_details img {
    height: 40px;
    width: 40px;
  }
}

.shopping_cart_select_root {
  width: 100% !important;
  border: none !important;
}
.shopping_cart_select_root:focus {
  border: none !important;
}
.shopping_cart_select_root .shopping_cart_select {
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #000;
}
.shopping_cart_select_root .shopping_cart_select::before {
  content: "Delivery Address: " !important;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #000;
}

.shopping_cart_select_focused {
  border: none !important;
}

.cart_total_price_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart_total_price_wrapper h3 {
  color: #05172c;
  font-size: 23px;
}
.cart_total_price_wrapper h4 {
  font-size: 22px;
  font-weight: bold;
  color: #05172c;
  font-family: "PT Sans", sans-serif;
}

.apply_coupon_wrapper p {
  color: #000000;
  text-transform: capitalize;
  padding: "0px 8px";
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.add_coupon_input_root {
  width: 100% !important;
  border-radius: 50px !important;
  margin-top: 15px !important;
  background-color: #ffffff !important;
  border: 1px solid #c3c3c3;
}

.add_coupon_applied {
  border: 1px dashed #808080 !important;
  background-color: #f0f0f0 !important;
}
.add_coupon_applied input {
  color: #808080 !important;
  font-weight: 500;
}

.add_coupon_input {
  width: 100% !important;
  border-radius: 50px !important;
  padding: 12px 20px !important;
}
.add_coupon_input::-moz-placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #5a5a5a;
}
.add_coupon_input:-ms-input-placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #5a5a5a;
}
.add_coupon_input::placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #5a5a5a;
}

.apply_coupon_btn {
  background-color: #20a258 !important;
  border-radius: 24px !important;
  padding: 13px 32px !important;
  min-width: 120px !important;
  line-height: normal !important;
  width: 100% !important;
}
.apply_coupon_btn span {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
.apply_coupon_btn.Mui-disabled {
  background-color: #ebe8e8 !important;
}
.apply_coupon_btn.Mui-disabled span {
  color: #000 !important;
}

.remove_coupon_btn {
  background-color: #fc5558 !important;
}

.note_to_order_label {
  padding: 0px 5px 15px;
  font-size: 16px;
  color: #05172c;
}

.note_to_order_textarea {
  width: 100%;
  resize: none;
  border-radius: 12px;
  border: 1px solid #c3c3c3;
  padding: 25px 40px;
}
.note_to_order_textarea::-moz-placeholder {
  line-height: normal;
  color: #77808a;
}
.note_to_order_textarea:-ms-input-placeholder {
  line-height: normal;
  color: #77808a;
}
.note_to_order_textarea::placeholder {
  line-height: normal;
  color: #77808a;
}

.shopping_cart_address_button {
  padding: 8px 24px !important;
  background-color: #20a258 !important;
  border-radius: 50px !important;
}
.shopping_cart_address_button:hover {
  background-color: #20a258;
}
.shopping_cart_address_button span {
  color: #ffffff;
}

.shopping_cart_address_dialog {
  border-radius: 37px !important;
  padding: 12px 0px;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.shopping_cart_address_dialog::-webkit-scrollbar {
  display: none !important;
  /* Hide scrollbar for Safari and Chrome */
}

.cart_restaurant_time_details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 2px;
  gap: 2px;
}
.cart_restaurant_time_details p {
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  color: #333;
  letter-spacing: normal;
}
@media screen and (max-width: 1100px) {
  .cart_restaurant_time_details p {
    font-size: 19px;
  }
}
@media screen and (max-width: 400px) {
  .cart_restaurant_time_details p {
    font-size: 17px;
  }
}
.cart_restaurant_time_details .cart_restaurant_delivery_time {
  color: #20a258;
  display: inline-block;
  font-weight: 600;
  margin-left: 5px;
}

.restaurant_close_desc {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  padding: 3px 8px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: red;
  opacity: 0.8;
  text-transform: none;
}
.restaurant_close_desc svg {
  margin-right: 5px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.shopping_cart_card_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 5px;
  gap: 5px;
}
.shopping_cart_card_header h4 {
  font-family: "AllRoundGothicW01-Book";
  font-size: 24px;
  font-weight: normal;
  color: #05172c;
}
.shopping_cart_card_header .order_summary_quantity {
  color: #333;
  font-size: 17px;
}
.shopping_cart_card_header button {
  margin-top: -11px;
}
.shopping_cart_card_header button img {
  cursor: pointer;
  height: 45px;
  border-radius: 50%;
}

.cart_product_image {
  height: 200px;
  width: 96%;
  -o-object-fit: fill;
     object-fit: fill;
  border-radius: 12px;
}

.shopping_cart_product_name {
  font-family: "AllRoundGothicW01-Book";
  font-size: 24px;
  color: #05172c;
  font-weight: normal;
  line-height: normal;
  word-break: break-word;
}

.shopping_cart_details_wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

.shopping_cart_price_wrapper {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.shopping_cart_price_wrapper .cart_variant_wrapper {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  margin-top: 5px;
}
.shopping_cart_price_wrapper .cart_variant_wrapper > h6 {
  font-size: 17px;
  color: #bbbbbb;
  margin-top: 7px;
}
.shopping_cart_price_wrapper .cart_variant_wrapper > div {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  flex-wrap: wrap;
}
.shopping_cart_price_wrapper .order_summary_variant {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.shopping_cart_price_wrapper .order_summary_variant h6,
.shopping_cart_price_wrapper .order_summary_variant p {
  font-size: 17px;
  color: #333;
  font-weight: 600;
}

.cart_variant_btn {
  color: #bbb;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 24px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 12px;
  min-height: 34px;
}

.cart_selected_variant {
  color: #20a258;
  border: 1px solid #20a258;
}

.shopping_cart_card_instruction {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin-top: 2px;
}

.shopping_cart_card_paragraph {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.cart_price span {
  font-family: "Montserrat", sans-serif;
  color: #fc4b4e;
  margin-right: 10px;
  font-size: 18px;
  font-weight: 800;
}
.cart_price del {
  font-family: "Montserrat", sans-serif;
  color: #818992;
  font-size: 18px;
  font-weight: bold;
}

.cart_buy_now_btn {
  background-color: #ffac0c !important;
  border-radius: 25px !important;
  padding: 8px 20px !important;
  min-width: 90px !important;
}

.cart_btn_label {
  color: #ffffff !important;
}

.cart_buy_disabled {
  background-color: #ebe8e8 !important;
}
.cart_buy_disabled span {
  color: #000000 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restaurant_card {
  width: 100%;
  height: 100%;
  box-shadow: 10px 20px 40px 0 rgba(97, 49, 0, 0.2);
  border-radius: 20px;
}

.restaurant_card_img_wrapper {
  border-radius: 15px;
}
.restaurant_card_img_wrapper .restaurant_card_image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.restaurant_card_content {
  padding: 15px;
}
.restaurant_card_content h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 900 !important;
  font-size: 18px !important;
}
.restaurant_card_content p {
  color: #5a5a5a;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  margin-top: 8px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.restaurant_card_content .duration {
  margin: 30px 0px 10px 0px;
  padding-left: 5px;
  height: 28px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
}
@media screen and (max-width: 480px) {
  .restaurant_card_content {
    padding: 8px 13px 13px;
  }
  .restaurant_card_content p {
    margin-top: 3px;
  }
  .restaurant_card_content .duration {
    margin: 7px 0px 5px;
    padding-left: 0px;
    font-size: 14px;
  }
}

.check_menu_btn {
  padding: 9px 16px 9px 20px !important;
  border-radius: 29.5px !important;
  background-color: #f3f0f0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-transform: capitalize !important;
  transition: 0.5s !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}
.check_menu_btn img {
  transition: transform 0.25s;
}
.check_menu_btn:hover {
  background-color: #e1dfdf !important;
}
.check_menu_btn:hover img {
  transform: translateX(3px);
}
@media screen and (max-width: 480px) {
  .check_menu_btn {
    padding: 8px 10px !important;
    font-size: 14px !important;
  }
  .check_menu_btn img {
    height: 14px;
    width: 15px;
  }
}

.check_menu_btn_color {
  color: black;
}

.image_loader {
  color: #20a258 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.menu_item_title {
  font-family: "Open Sans", sans-serif !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #000 !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu_item_modal_wrapper h5,
.menu_item_modal_wrapper p {
  color: #000000;
}
.menu_item_modal_wrapper h5 {
  font-weight: 600;
}
.menu_item_modal_wrapper p {
  font-size: 15px;
}
.menu_item_modal_wrapper span {
  color: #808080;
  font-size: 13px;
  padding: 10px 0px;
}
.menu_item_modal_wrapper .menu_item_description {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #000;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 8px;
}
.menu_item_modal_wrapper textarea {
  width: 100%;
  resize: none;
  border-radius: 10px;
  border: 1px solid #c3c3c3;
  padding: 10px 30px;
}
.menu_item_modal_wrapper textarea:focus {
  outline: none;
}

.menu_item_description_wrapper {
  padding-bottom: 8px;
  border-bottom: 1px solid #c1c1c1;
  color: #000000;
}

.choose_item_check_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.choose_item_check_wrapper div {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.choose_item_check_wrapper p {
  color: #000000;
  font-weight: 500;
  text-transform: capitalize;
}
.choose_item_check_wrapper span {
  padding: 0px;
}

.choose_number_of_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #20a258;
  padding: 6px 8px;
  border-radius: 24px;
}
.choose_number_of_item span {
  background-color: #20a258;
  border-radius: 50%;
  padding: 3px;
}
.choose_number_of_item span svg {
  color: #ffffff;
}

.add_to_order_btn {
  background-color: #20a258 !important;
  border-radius: 24px !important;
  padding: 8px !important;
  width: 100%;
  height: 100%;
}
.add_to_order_btn span {
  color: #ffffff;
  text-transform: none;
  font-size: 16px;
}

.instruction_title {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #05172c;
}

.instruction_description {
  margin-bottom: 16px;
  font-size: 14px !important;
}

.show_menu_close_icon {
  width: 40px;
  height: 40px;
}
.show_menu_close_icon svg {
  color: #000000;
  width: 25px;
  height: 25px;
}

.quantity_change_icon {
  cursor: pointer;
}

.quantity_change_icon:disabled {
  cursor: default;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restaurant_details_wrapper_details h1 {
  font-family: "AllRoundGothicW01-Medium" !important;
  font-size: 72px;
  font-weight: 500;
  color: #333333;
}

.get_direction {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  color: #20a258;
}

.h3 {
  font-family: "Montserrat", sans-serif;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restaurant_details_images {
  margin-top: 20px;
}
.restaurant_details_images img {
  border-radius: 12px;
  height: 448px;
  -o-object-fit: fill;
     object-fit: fill;
  cursor: pointer !important;
}
.restaurant_details_images .other_restaurant_images_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer !important;
}
.restaurant_details_images .hover_effect_container {
  height: 208px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  cursor: pointer;
}
.restaurant_details_images .hover_effect_container .last_image_hover {
  height: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 40px;
  font-weight: bold;
}
.restaurant_details_images .hover_effect_container .last_image_hover span {
  color: #ffffff;
}
.restaurant_details_images .other_restaurant_images {
  height: 208px !important;
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.restaurant_details_wrapper h1 {
  color: #000000;
  font-weight: 600;
  font-size: 50px;
}
@media screen and (max-width: 600px) {
  .restaurant_details_wrapper h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 300px) {
  .restaurant_details_wrapper h1 {
    font-size: 26px;
  }
}
.restaurant_details_wrapper p {
  color: #808080;
}
.restaurant_details_wrapper .tag {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 11px 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.restaurant_details_wrapper .restaurant_details_wrapper_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.restaurant_details_wrapper .restaurant_details_wrapper_header .res_details_info_box {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
}
.restaurant_details_wrapper .restaurant_details_wrapper_header .rating_number_box {
  background-color: #20a258;
  border-radius: 10px;
  padding: 7px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}
.restaurant_details_wrapper .restaurant_details_wrapper_header .rating_number_box svg {
  color: #ffffff;
  font-size: 30px;
}
.restaurant_details_wrapper .restaurant_details_wrapper_header .rating_number_box p {
  padding-left: 10px;
  color: #ffffff;
  font-size: 15px;
}
.restaurant_details_wrapper .restaurant_details_wrapper_header h6 {
  color: #515156;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.restaurant_details_wrapper .restaurant_details_wrapper_header p {
  color: #20a258;
  font-weight: 600;
  border-bottom: 1px solid #20a258;
}

.restaurant_details_wrapper_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.learn_something_new_card {
  box-shadow: 0px 0px 2px 2px #c3c3c3;
  border-radius: 20px;
}
.learn_something_new_card .learn_something_new_card_img_wrapper {
  position: relative;
}
.learn_something_new_card .learn_something_new_card_img_wrapper div {
  position: absolute;
  bottom: 0;
  left: 20px;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 20px;
  color: #000000;
}
.learn_something_new_card img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.learn_something_new_card .learn_something_new_card_content {
  padding: 20px 20px 30px;
}
.learn_something_new_card h5 {
  color: #20a258;
  font-weight: 600;
  padding: 10px 0px 30px;
}

.restaurant_details_menu_header {
  margin-top: 20px;
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  flex-wrap: wrap;
}
.restaurant_details_menu_header p {
  text-transform: capitalize;
  font-weight: 500;
  color: #515156;
  font-size: 20px;
}
.restaurant_details_menu_header .restaurant_close_desc {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  padding: 5px 8px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: red;
  opacity: 0.8;
  text-transform: none;
}
.restaurant_details_menu_header .restaurant_close_desc svg {
  margin-right: 5px;
}

.restaurant_details_menu_heading p {
  text-transform: uppercase;
  color: #515156;
  font-size: 15px;
}

.restaurant_details_menu_item {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 15px;
}
.restaurant_details_menu_item .restaurant_details_menu_item_description div {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.restaurant_details_menu_item .restaurant_details_menu_item_description div h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: #333;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.restaurant_details_menu_item .restaurant_details_menu_item_description h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: #333;
}
.restaurant_details_menu_item .restaurant_details_menu_item_description .res_details_desc_container {
  display: flex;
  align-items: center;
}
.restaurant_details_menu_item .restaurant_details_menu_item_description .res_details_desc_container p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgba(51, 51, 51, 0.5);
}
.restaurant_details_menu_item .restaurant_details_menu_item_description .res_details_desc_container button {
  font-weight: bold;
  color: #20a258;
  border: none;
  font-size: 15px;
  outline: none;
}
.restaurant_details_menu_item .restaurant_details_menu_item_details {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 10px;
}
@media screen and (max-width: 400px) {
  .restaurant_details_menu_item .restaurant_details_menu_item_details {
    flex-direction: column;
  }
}
.restaurant_details_menu_item .restaurant_details_menu_item_details svg {
  color: #20a258;
  font-size: 55px;
  cursor: pointer;
}
.restaurant_details_menu_item .restaurant_details_menu_item_details p {
  font-size: 18px;
  color: #333;
}
.restaurant_details_menu_item .restaurant_details_menu_item_details del {
  color: #818992;
  font-size: 18px;
}
.restaurant_details_menu_item img {
  width: 100%;
  height: 80px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 599px) {
  .restaurant_details_menu_item img {
    height: 240px;
  }
}

.restaurant_details_place_order_wrapper_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 35px 30px;
  border-radius: 15px;
}
.restaurant_details_place_order_wrapper_box .restaurant_order_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.restaurant_details_place_order_wrapper_box .place_order_flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.restaurant_details_place_order_wrapper_box .place_order_flex h5 {
  color: #515156;
}
.restaurant_details_place_order_wrapper_box .place_order_flex h2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.restaurant_details_place_order_wrapper_box .place_order_flex span {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 51, 51, 0.5);
}
.restaurant_details_place_order_wrapper_box .deliver {
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 20px;
}
.restaurant_details_place_order_wrapper_box h6,
.restaurant_details_place_order_wrapper_box h5,
.restaurant_details_place_order_wrapper_box h4,
.restaurant_details_place_order_wrapper_box p {
  color: #000000;
}
.restaurant_details_place_order_wrapper_box h6 {
  text-transform: uppercase;
  font-size: 12px;
  color: #333333 !important;
}
.restaurant_details_place_order_wrapper_box h4 {
  font-size: 24px;
  color: #333333;
}

.restaurant_details_place_order_address_textfield {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px;
}
.restaurant_details_place_order_address_textfield input {
  width: 90%;
  border: none;
  outline: none;
}

.restaurant_details_place_order_address_textfield_root {
  border: none;
  outline: none;
  width: 100%;
  background-color: #ffffff !important;
}

.restaurant_details_place_order_address_textfield_input {
  background-color: #ffffff !important;
  width: 100%;
  padding: 10px !important;
}

.restaurant_details_order .remove_btn_container {
  display: flex;
  justify-content: center;
}
.restaurant_details_order .restaurant_details_dish_details {
  display: flex;
  grid-gap: 2px;
  gap: 2px;
}
.restaurant_details_order .restaurant_details_dish_details p {
  margin-left: 5px;
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.restaurant_details_order img {
  min-width: 60px;
  height: 50px;
  border-radius: 8px;
}
.restaurant_details_order span,
.restaurant_details_order del {
  color: #808080;
  font-size: 15px;
}
.restaurant_details_order .remove_order {
  width: 30px !important;
  height: 30px !important;
}
.restaurant_details_order .remove_order svg {
  border-radius: 50% !important;
  background-color: #20a258 !important;
  color: #ffffff;
}

.restaurant_details_quantity_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d2d2d2;
  border-radius: 26px;
  max-height: 25px;
  width: 64px;
}
.restaurant_details_quantity_box button {
  border: none;
  background-color: transparent;
  height: 22px;
  width: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #264653;
}
.restaurant_details_quantity_box span {
  font-size: 14px;
  font-weight: bold;
  color: #264653;
  padding-right: 5px;
}
.restaurant_details_quantity_box button:last-child {
  height: 26px;
  width: 26px;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 16px;
}

.res_details_price {
  display: block;
  font-family: "Inter", sans-serif;
}

.restaurant_details_order_addons {
  margin-top: 5px;
}
.restaurant_details_order_addons p {
  font-size: 9px;
  font-weight: 600;
  color: #000000;
  margin-top: 3px;
}
.restaurant_details_order_addons span {
  font-size: 11px;
  font-weight: 600;
  color: #000000;
}

.place_order_btn {
  background-color: #20a258 !important;
  width: 100%;
  border-radius: 50px !important;
  padding: 10px !important;
}

.restaurant_details_middle_banner {
  padding: 130px;
  background: url(/static/media/green_overlayed_restaurants_banner.fc9cb945.jpg) no-repeat;
  background-size: cover;
  margin: 30px 0px 20px;
}
.restaurant_details_middle_banner h1,
.restaurant_details_middle_banner h5,
.restaurant_details_middle_banner p {
  color: #ffffff;
}
.restaurant_details_middle_banner h1 {
  font-weight: 800;
  font-size: 50px;
}

.restaurant_add_on_dialog_paper {
  width: 80vw !important;
  max-width: 840px !important;
  border-radius: 35px !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.restaurant_add_on_dialog_paper::-webkit-scrollbar {
  display: none !important;
  /* Hide scrollbar for Safari and Chrome */
}

.restaurant_lsn_wrapper {
  margin-top: 60px;
  margin-bottom: 100px;
}
@media screen and (max-width: 600px) {
  .restaurant_lsn_wrapper {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 300px) {
  .restaurant_lsn_wrapper {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

.restaurant_details_lsn_heading {
  font-family: "Montserrat", sans-serif !important;
  font-size: 38px !important;
  font-weight: 500;
  color: #333333;
  margin-bottom: 30px;
  text-align: center;
}

.restaurant_donate_generate {
  font-size: 20px;
}

.restaurant_donate_gain_amount {
  font-size: 45px;
  text-shadow: 1.5px 0px #ffffff;
  letter-spacing: 1px;
  font-weight: 700;
}

.restaurant_donate_raise_amount {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  text-shadow: 0.8px 0px #ffffff;
  letter-spacing: 1px;
}

.restaurant_donate_btn {
  border-radius: 45px !important;
  background-color: #ffa700 !important;
  padding: 12px 25px !important;
  margin-top: 10px !important;
}

.restaurant_donate_btn_label {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #ffffff !important;
}

.restaurant_details_address_root {
  border-radius: 12px !important;
  background-color: #ffffff !important;
  padding: 0 !important;
  border: none !important;
  outline: none !important;
}
.restaurant_details_address_root fieldset {
  top: 0px !important;
}

.address_loader_box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 10px 0px;
  background-color: #fff;
}
.address_loader_box .address_circular_loader {
  color: #20a258;
}

.address_list_container {
  border-radius: 8px;
}

.restaurant_details_address_input {
  padding: 12px 14px !important;
  height: 100% !important;
}

.menu_item_disable_btn svg {
  color: #c3c3c3 !important;
}

.res_details_more_btn_container {
  display: flex;
  justify-content: center;
}
.res_details_more_btn_container button {
  padding: 8px 30px !important;
  border-radius: 12px !important;
  border: solid 1px rgba(32, 162, 88, 0.3) !important;
}
.res_details_more_btn_container button span {
  text-transform: none;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #20a258;
}
.fav_fill_icon {
  color: #ec5959 !important;
}

.fav_icon {
  color: rgba(0, 0, 0, 0.4);
}
.restaurant_search_map_view_container {
  padding: 0px 0px 0px 20px;
  background-color: #ffffff;
}

.search_restaurant {
  background-color: #f0f0f0;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
}
.search_restaurant div {
  width: 80%;
  background-color: #ffffff;
  border: 1px solid #bababa;
  border-radius: 10px;
}
.search_restaurant div::before,
.search_restaurant div::after {
  border-bottom: none !important;
}
.search_restaurant select {
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 10px !important;
  padding: 15px 10px;
}

.restaurant_search_card {
  background: linear-gradient(to bottom, transparent, rgba(185, 181, 181, 0.3), rgba(20, 0, 10, 0.5), rgba(0, 0, 0, 0.7)), url(/static/media/restaurantCardimg.945e6957.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;
}
.restaurant_search_card p,
.restaurant_search_card span {
  color: #ffffff;
}
.restaurant_search_card .restaurant_search_card_content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.restaurant_search_card .restaurant_rating {
  display: flex;
  align-items: center;
  background-color: #ea8e77;
  padding: 5px 8px 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
}
.restaurant_search_card .restaurant_rating p {
  padding: 5px;
}
.restaurant_search_card .restaurant_details h5,
.restaurant_search_card .restaurant_details h6 {
  color: #ffffff;
  text-transform: capitalize;
}
.restaurant_search_card .restaurant_details h6 {
  font-weight: 400;
  padding-bottom: 10px;
}

.restaurant_search_map_container {
  background: url(/static/media/mapView.e11dcfce.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-left: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.restaurant_card_in_map {
  background-color: #ffffff;
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.restaurant_card_img_in_map {
  position: relative;
}
.restaurant_card_img_in_map img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 200px;
}
.restaurant_card_img_in_map .restaurant_card_header_img_in_map {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.restaurant_card_img_in_map .restaurant_card_header_img_in_map div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ec9e31;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
}
.restaurant_card_img_in_map .restaurant_card_header_img_in_map div p,
.restaurant_card_img_in_map .restaurant_card_header_img_in_map div span {
  color: #ffffff;
}
.restaurant_card_img_in_map .restaurant_card_header_img_in_map div p {
  padding: 0px 5px;
}
.restaurant_card_img_in_map .restaurant_card_header_img_in_map div:nth-child(2) {
  border-radius: 50%;
}

.restaurant_card_details_in_map {
  padding: 20px 20px 30px;
  width: 100%;
}
.restaurant_card_details_in_map div {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px 20px;
}
.restaurant_card_details_in_map span {
  color: #bababa;
  font-weight: 500;
}
.restaurant_card_details_in_map h6,
.restaurant_card_details_in_map h5 {
  padding: 10px 0px;
}
.restaurant_card_details_in_map button {
  width: 100%;
  background-color: #20a258 !important;
  border-radius: 15px !important;
}
.restaurant_card_details_in_map button span {
  color: #ffffff;
  text-transform: none;
  padding: 5px;
  font-size: 18px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.create_role_main_wrapper {
  position: relative !important;
}

.address_button_container {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.address_button_container span {
  padding: 0 !important;
}

.create_profile_container {
  width: 80vw !important;
  padding: 30px;
}
@media screen and (max-width: 1024px) {
  .create_profile_container {
    width: 100% !important;
    padding: 10px;
  }
}
.create_profile_container .headerLearning {
  margin: 0 -30px;
  padding: 20px 30px;
}
.create_profile_container .MuiTabs-root {
  border-bottom: 2px solid #bbbbbb;
  margin: 0 -30px;
}
@media screen and (max-width: 767px) {
  .create_profile_container .MuiTabs-root {
    margin: 0;
  }
}
.create_profile_container .MuiTabs-root .MuiButtonBase-root {
  opacity: 1;
  margin: 0;
  padding: 0;
}
.create_profile_container .MuiTabs-root .MuiButtonBase-root .MuiTab-wrapper {
  color: #20a258;
  margin-left: -30px;
}
@media screen and (max-width: 767px) {
  .create_profile_container .MuiTabs-root .MuiButtonBase-root .MuiTab-wrapper {
    margin-left: 0;
  }
}
.create_profile_container .MuiTabs-root .MuiButtonBase-root::before {
  content: " ";
  position: absolute;
  height: 3px;
  width: 200px;
  background-color: #20a258;
  top: 45px;
  z-index: 9;
}

.create_role_profile_form_container {
  box-shadow: 0px 0px 12px 0px #c3c3c3;
  padding: 40px 30px;
  border-radius: 20px;
  margin: 40px 0px 100px;
}
.create_role_profile_form_container h3 {
  text-align: center;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
}
@media screen and (max-width: 560px) {
  .create_role_profile_form_container h3 {
    font-size: 22px;
  }
}
.create_role_profile_form_container h3,
.create_role_profile_form_container h4 {
  color: #000000;
  text-transform: capitalize;
}

.create_profile_location_message {
  width: 100%;
  text-align: center;
}

.gps_location {
  color: #20a258;
}

.create_role_profile_otp_input_container {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.create_role_profile_input_root {
  width: 100%;
  padding: 0px !important;
  border-radius: 5px;
  background: none !important;
  border: 1px solid #c3c3c3;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.create_role_profile_input {
  padding: 16px !important;
  border-radius: 15px;
}

.create_role_profile_input::-moz-placeholder {
  color: #394053 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.create_role_profile_input:-ms-input-placeholder {
  color: #394053 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.create_role_profile_input::placeholder {
  color: #394053 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.create_profile_label {
  padding: 5px 0px !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  color: #464a53 !important;
}

.create_profile_label_text {
  padding: 5px 0px;
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: "Poppins", sans-serif !important;
  color: #464a53 !important;
}

.create_role_profile_desc {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  color: #000 !important;
}

.create_profile_owner_heading {
  font-family: "Poppins", sans-serif !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  letter-spacing: 0.1px !important;
  color: #171725 !important;
}

.create_role_profile_btn {
  background: #20a258 !important;
  padding: 11px 12px !important;
  width: 100%;
  text-transform: capitalize !important;
}
.create_role_profile_btn span {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif;
}

.create_role_profile_email {
  margin-top: 17px !important;
}

.create_role_otp_container {
  padding-top: 0 !important;
}

.create_role_btn_farm {
  margin-top: 17px !important;
}

.create_role_profile_btn_disabled {
  background: #58bc81 !important;
  padding: 11px 12px !important;
  width: 100%;
}
.create_role_profile_btn_disabled span {
  color: #ffffff !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif;
}

.OTP_header {
  font-weight: bold;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  padding-bottom: 5px;
  color: #464a53;
}

.OTP_header1 {
  padding-bottom: 5px;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  color: #464a53;
}

.create_profile_radio {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #464a53 !important;
}

.OTP_Error {
  color: red;
  padding-left: 100px;
  padding-top: 25px;
}

.otp_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp_loader_container svg {
  color: #20a258;
}

.OTP_Success_Msg {
  color: #20a258 !important;
  font-weight: bold !important;
  font-size: 18px !important;
  padding-top: 45px !important;
}

.OTP_Success_Msg1 {
  color: #20a258 !important;
  font-weight: bold;
  font-size: 18px;
  padding-top: 35px;
}

.gps_input_field {
  width: 100%;
}

.create_role_profile_detect_location_input_wrapper {
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  padding: 0px;
  display: flex;
  align-items: center;
}
.create_role_profile_detect_location_input_wrapper div {
  display: flex;
  align-items: center;
  border-left: 1px solid #c3c3c3;
  padding: 0px 15px 0px 10px;
}
.create_role_profile_detect_location_input_wrapper div p {
  padding-left: 10px;
  color: #000000;
  font-weight: 600;
}

.create_role_profile_detect_location_input_root {
  width: 100%;
  padding: 0px !important;
  border-radius: 5px;
  background: none !important;
  border: none !important;
}

.create_role_profile_mobile_number_input_wrapper {
  border: 1px solid rgba(193, 193, 193, 0.368627451);
  border-radius: 5px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 5px 10px 10px 0px;
}
.create_role_profile_mobile_number_input_wrapper div:nth-child(1) {
  display: flex;
  align-items: center !important;
  border-right: 1px solid #c3c3c3;
  padding: 0px 15px 0px 10px !important;
}
.create_role_profile_mobile_number_input_wrapper div:nth-child(1) p {
  padding: 0px 10px !important;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
}
.create_role_profile_mobile_number_input_wrapper input {
  padding: 15px 16px !important;
}
.create_role_profile_mobile_number_input_wrapper div:nth-child(2) {
  width: 100%;
}

.custom_dialog_paper {
  border-radius: 20px !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.custom_dialog_paper::-webkit-scrollbar {
  display: none !important;
}

.custom_roundish_dialog_paper {
  border-radius: 35px !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.custom_roundish_dialog_paper::-webkit-scrollbar {
  display: none !important;
}

.create_profile_nav_btn {
  background-color: #263c78 !important;
  padding: 15px 0px !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  min-width: 50px !important;
  z-index: 1 !important;
}
@media screen and (max-width: 550px) {
  .create_profile_nav_btn {
    min-width: 40px !important;
    padding: 10px 0px !important;
  }
}
@media screen and (max-width: 340px) {
  .create_profile_nav_btn {
    min-width: 35px !important;
    padding: 8px 0px !important;
  }
}
.create_profile_nav_btn span {
  color: #fff !important;
  font-size: 16px !important;
  max-width: 20px !important;
}

.select_role_move_disabled {
  background-color: #c3c3c3 !important;
}

.create_profile_back {
  left: 2vw !important;
}
@media screen and (max-width: 550px) {
  .create_profile_back {
    left: 1.2vw !important;
  }
}

.create_profile_right {
  right: 2vw !important;
}
@media screen and (max-width: 550px) {
  .create_profile_right {
    right: 1.2vw !important;
  }
}

.mediaUploaderLearning .no-profile {
  border-radius: 50%;
  -o-object-fit: contain;
     object-fit: contain;
  height: 140px;
  width: 140px;
}

.educationalCartUpload {
  width: 100%;
  margin-bottom: 25px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  background-color: #fff;
  font-size: 1em;
  font-weight: bold;
  color: #394053;
  display: flex;
  align-items: center;
}
.educationalCartUpload .uploadTitle {
  width: 100%;
  font-family: "Poppins", sans-serif;
  padding: 16px 54px 15px 21px;
}
.educationalCartUpload .inputfile + label {
  font-weight: bold;
  color: #394053;
  display: block;
  border-left: solid 1px #abafb3;
  padding: 16px 20px 15px 20px;
  font-family: "Poppins", sans-serif;
}

.farm_otp_input .MuiFilledInput-input {
  padding: 16px 12px !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.customAccordian {
  padding-top: 30px;
  align-items: center;
}
.customAccordian .MuiAccordion-root {
  margin-bottom: 20px;
  padding: 10px 20px;
  box-shadow: none;
  border: none !important;
  border-radius: 5px !important;
}
.customAccordian .topTitle {
  color: #000000;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
}
.customAccordian .topTitle span {
  display: flex;
  flex-direction: column;
  color: #333;
  align-items: center;
  font-size: 25px;
}
@media screen and (max-width: 768px) {
  .customAccordian .topTitle span {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian .topTitle span {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 768px) {
  .customAccordian .topTitle {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian .topTitle {
    font-size: 24px;
  }
}
.customAccordian .subTitle {
  padding-left: 30px;
  color: #000000;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  display: flex;
  margin-left: 30px;
  align-items: center;
}
.customAccordian p {
  font-size: 16px;
}
@media screen and (max-width: 1024px) {
  .customAccordian p {
    font-size: 14px;
  }
}
.customAccordian .AccordionSummary .MuiSvgIcon-root {
  display: none;
}
.customAccordian .AccordionSummary .subTitle {
  font-size: 30px;
}
@media screen and (max-width: 560px) {
  .customAccordian .AccordionSummary .subTitle {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon {
  background-color: #ec9e31;
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon:hover {
  background-color: #ec9e31;
}
.customAccordian .AccordionSummary .MuiIconButton-label {
  position: relative;
}
.customAccordian .AccordionSummary .MuiIconButton-label::before, .customAccordian .AccordionSummary .MuiIconButton-label::after {
  content: " ";
  position: absolute;
  height: 2px;
  width: 12px;
  background-color: #ffffff;
}
.customAccordian .AccordionSummary .MuiIconButton-label::after {
  transform: rotate(90deg);
  background-color: #ffffff;
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(0deg);
}
.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon.Mui-expanded .MuiIconButton-label::after {
  display: none;
}
.customAccordian .AccordionDetails {
  display: flex;
  flex-direction: column;
}
.customAccordian .AccordionDetails h6 {
  margin: 10px 0;
  font-weight: 600;
  color: #000000;
}
.customAccordian .AccordionDetails .subTitle {
  font-family: "Montserrat", sans-serif;
  margin: -10px 0 20px 135px;
}
@media screen and (max-width: 560px) {
  .customAccordian .AccordionDetails .subTitle {
    margin: 24px 0 10px 50px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }
}
.customAccordian .AccordionDetails .MuiTypography-h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.customAccordian .AccordionDetails .para {
  margin: -10px 0 20px 165px;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .customAccordian .AccordionDetails .para {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian .AccordionDetails .para {
    margin: 0 0 10px 80px;
    font-weight: normal;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    text-align: justify;
    color: #919191;
  }
}
.customAccordian .overview-subTitle {
  display: flex;
  align-items: center;
  margin-left: 60px;
  font-size: 30px;
  font-weight: 500;
}
@media screen and (max-width: 560px) {
  .customAccordian .overview-subTitle {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }
}
.customAccordian .overview-text {
  margin: 0 0 20px 150px;
  font-size: 30px;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .customAccordian .overview-text {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian .overview-text {
    font-size: 14px !important;
    margin: 0 0 20px 70px;
  }
}
.customAccordian .MuiAccordionSummary-root {
  flex-direction: row-reverse;
}
@media screen and (max-width: 560px) {
  .customAccordian .MuiAccordionSummary-root {
    padding: 0;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian {
    padding: 0;
  }
}

@media screen and (max-width: 560px) {
  .accordionContainer {
    padding: 0 20px;
  }
}

@media screen and (max-width: 560px) {
  .customAccordian6 .MuiAccordionSummary-root {
    padding: 12px;
  }
}
.customAccordian6 .topTitle6 {
  color: #000;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .customAccordian6 .topTitle6 {
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .topTitle6 {
    font-size: 12px;
  }
}
.customAccordian6 p {
  font-size: 16px;
}
@media screen and (max-width: 560px) {
  .customAccordian6 p {
    font-size: 12px !important;
  }
}
.customAccordian6 .AccordionSection .accordionWrapper {
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
  border-radius: 0;
}
@media screen and (max-width: 560px) {
  .customAccordian6 .AccordionSection .accordionWrapper {
    margin: 0;
  }
}
.customAccordian6 .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg);
}
@media screen and (max-width: 560px) {
  .customAccordian6 .MuiAccordionSummary-expandIcon.Mui-expanded {
    padding: 0;
  }
}
.customAccordian6 .MuiAccordionSummary-content {
  justify-content: space-between;
}
@media screen and (max-width: 560px) {
  .customAccordian6 .MuiAccordionSummary-content {
    margin: 0 !important;
  }
}
.customAccordian6 .detailWrapper {
  display: flex;
  margin: 1rem 0 0 2rem;
  align-items: center;
}
.customAccordian6 .detailWrapper video {
  border-radius: 15px;
  -o-object-fit: fill;
     object-fit: fill;
  width: 125px;
  height: 75px;
}
@media screen and (max-width: 560px) {
  .customAccordian6 .detailWrapper video {
    width: 73px !important;
    height: 44px !important;
    border-radius: 5px;
  }
}
.customAccordian6 .detailWrapper h6 {
  font-weight: bold;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .customAccordian6 .detailWrapper h6 {
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .detailWrapper h6 {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
}
.customAccordian6 .detailWrapper .time {
  margin-top: 8px;
  color: #92929d;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .customAccordian6 .detailWrapper .time {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .detailWrapper .time {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .detailWrapper {
    margin: 0;
  }
}
.customAccordian6 .completedStat {
  color: #20a258;
  font-weight: bold;
  font-size: 20px;
}
@media screen and (max-width: 560px) {
  .customAccordian6 .completedStat {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
}
.customAccordian6 .MuiAccordion-root:before {
  background-color: transparent !important;
}
.customAccordian6 .MuiAccordionDetails-root .buttonWraper {
  cursor: pointer;
  margin: 10px 0 0 40px;
}
@media screen and (max-width: 560px) {
  .customAccordian6 .MuiAccordionDetails-root .buttonWraper {
    margin: 10px 0 12px 40px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .MuiAccordionDetails-root {
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .customAccordian6 .MuiAccordionDetails-root .para {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .MuiAccordionDetails-root .para {
    margin-left: 0 !important;
    text-align: justify;
    font-family: "Poppins", sans-serif !important;
    padding: 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .customAccordian6 .paper {
    margin: 1rem 0 !important;
  }
}
.customAccordian6 .document {
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .customAccordian6 .document {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .document {
    font-size: 12px;
  }
}
.customAccordian6 .pdfIcon {
  margin-left: 18px;
  width: 20px;
  height: 25px;
}
@media screen and (max-width: 560px) {
  .customAccordian6 .pdfIcon {
    width: 12px;
    height: 14px;
  }
}
.customAccordian6 .playIcon {
  margin-left: 18px;
  width: 25px;
  height: 25px;
}
@media screen and (max-width: 560px) {
  .customAccordian6 .playIcon {
    width: 15px;
    height: 15px;
  }
}
.customAccordian6 .videoName {
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .customAccordian6 .videoName {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .videoName {
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 {
    padding: 0;
  }
}

.modalVideo {
  width: 800px;
  height: 400px;
}
@media screen and (max-width: 768px) {
  .modalVideo {
    width: 90vw;
  }
}

.customAccordian6-active {
  background-color: #f4f5f9 !important;
}

.AccordionSummary-active {
  background-color: #f4f5f9;
}

.AccordionSummary {
  align-items: center;
}
.AccordionSummary .MuiTypography-h5 {
  font-family: "Poppins", sans-serif;
}
.AccordionSummary span {
  font-family: "Poppins", sans-serif;
}
.AccordionSummary p {
  font-size: 12px;
  color: #92929d;
}

.taskAccordion {
  align-items: center;
}
.taskAccordion .MuiTypography-h5 {
  font-family: "Poppins", sans-serif;
}
.taskAccordion span {
  font-family: "Poppins", sans-serif;
}
.taskAccordion p {
  font-size: 25px;
  color: #9098b1;
}
@media screen and (max-width: 560px) {
  .taskAccordion p {
    font-size: 12px !important;
  }
}

.commonAccordian {
  margin: 40px 0;
}
.commonAccordian .MuiPaper-root {
  box-shadow: none;
}
.commonAccordian .MuiAccordionDetails-root {
  padding: 0 15px 0 0;
}
.commonAccordian .MuiAccordionSummary-root {
  margin: 0;
  box-shadow: none !important;
  background-color: #f4f5f5;
  border: 1px solid #f4f5f5;
  border-radius: 10px;
  min-height: 64px;
  padding: 12px;
}
.commonAccordian .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 0px;
}
.commonAccordian .MuiAccordionSummary-root.Mui-expanded {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.commonAccordian .MuiAccordionSummary-expandIcon svg {
  color: #394053;
}
.commonAccordian .MuiCollapse-entered {
  border: 1px solid #f4f5f5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.commonAccordian .MuiCollapse-wrapper {
  margin-top: 40px;
}
.commonAccordian h6 {
  font-size: 18px;
  font-weight: bold;
}
.commonAccordian .greenContained {
  padding-bottom: 30px;
}
.commonAccordian.courseAccordian {
  margin: 20px 0;
}
.commonAccordian.courseAccordian .MuiCollapse-wrapper {
  margin-top: 10px;
}
.commonAccordian.courseAccordian .MuiAccordionDetails-root {
  flex-direction: column;
  padding: 0;
}
.commonAccordian.courseAccordian .education-row {
  padding: 20px 0 0 0;
}

.exercise-title {
  margin-top: 2.5rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: black;
  font-size: 36px;
  font-weight: 500;
}
@media screen and (max-width: 1160px) {
  .exercise-title {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 960px) {
  .exercise-title {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 768px) {
  .exercise-title {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 640px) {
  .exercise-title {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 560px) {
  .exercise-title {
    font-size: 14px !important;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .task-wrapper .MuiAccordionSummary-root {
    padding: 0;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.check_out_delivery_cards_container {
  box-shadow: 0px 0px 12px 0px #c3c3c3;
  padding: 40px 30px;
  border-radius: 20px;
}
.check_out_delivery_cards_container button {
  width: 100%;
  padding: 8px 10px !important;
  border-radius: 50px !important;
}
.check_out_delivery_cards_container button span {
  color: #ffffff;
}

.check_out_left_container {
  height: 100%;
  padding: 40px 30px 60px;
}

.check_out_delivery_card {
  border: 2px solid #f0f0f0;
  padding: 20px;
  border-radius: 5px;
}
.check_out_delivery_card h6 {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  padding: 2px 0px;
}
.check_out_delivery_card p {
  color: #515156 !important;
  padding: 2px 0px;
  font-size: 16px;
}

.check_out_delivery_card_actions_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}
.check_out_delivery_card_actions_container button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px !important;
}
.check_out_delivery_card_actions_container img {
  cursor: pointer;
}

.non_editable_action_container {
  justify-content: flex-end !important;
}

.check_out_heading {
  font-family: "AllRoundGothicW01-Book";
  font-size: 28px;
  color: #333;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.checkout_address_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 45vh;
}
.checkout_address_loader svg {
  color: #20a258;
}

.pickup_address_hint {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #333 !important;
  margin-left: 12px !important;
}

.no_address_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 45vh;
}
.no_address_container h2 {
  color: #20a258;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}
.no_address_container h5 {
  color: rgba(51, 51, 51, 0.5);
  font-size: 18px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.order_summary_total_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}
.order_summary_total_price h3 {
  color: #05172c;
  font-size: 22px;
}
.order_summary_total_price h4 {
  font-size: 22px;
  font-weight: bold;
  color: #05172c;
  font-family: "PT Sans", sans-serif;
}

.check_out_delivery_card_btn_root {
  background-color: #20a258 !important;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.check_out_bitcoin_cointainer {
  box-shadow: 0px 0px 12px 0px #c3c3c3;
  padding: 40px 30px;
  border-radius: 20px;
  margin: 40px 0px 0px;
}
.check_out_bitcoin_cointainer p {
  color: #000000;
  font-size: 15px;
  font-weight: 100 !important;
}
.check_out_bitcoin_cointainer h4 {
  color: #000000;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 500;
}

.bitcoin_copy_address_container div {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 10px 30px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bitcoin_copy_address_container h6 {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.checkout_btn {
  width: 100%;
  margin-top: 30px;
}
.checkout_btn button {
  width: 100%;
  background-color: #20a258 !important;
  border-radius: 50px !important;
  padding: 10px !important;
}
.checkout_btn button span {
  color: #ffffff;
  text-transform: none;
}
.checkout_btn .Mui-disabled {
  background-color: #ebe8e8 !important;
}
.checkout_btn .Mui-disabled span {
  color: #000;
}

/*bitcoin modal*/
.bitcoin_payment_form_wrapper {
  padding: 30px 35px;
}
.bitcoin_payment_form_wrapper .bitcoin_payment_form_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  text-align: center;
}
.bitcoin_payment_form_wrapper .bitcoin_payment_form_content img {
  padding-left: 30px;
  height: 80%;
  width: 80%;
}
.bitcoin_payment_form_wrapper h5,
.bitcoin_payment_form_wrapper h6,
.bitcoin_payment_form_wrapper p {
  padding: 5px;
}
.bitcoin_payment_form_wrapper h5 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-top: 10px;
}
.bitcoin_payment_form_wrapper h6 {
  font-family: "Poppins", sans-serif;
  font-size: 18px !important;
  text-align: center;
  font-weight: 300 !important;
  color: #000;
}
.bitcoin_payment_form_wrapper p {
  color: #20a258;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}
.bitcoin_payment_form_wrapper button {
  width: 100%;
  background-color: #20a258 !important;
  border-radius: 30px !important;
  padding: 15px !important;
}
.bitcoin_payment_form_wrapper button span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.add_card_form_wrapper {
  padding: 20px 50px 35px;
}
@media screen and (max-width: 600px) {
  .add_card_form_wrapper {
    padding: 10px 25px 20px;
  }
}
@media screen and (max-width: 300px) {
  .add_card_form_wrapper {
    padding: 10px 20px 20px;
  }
}

.add_card_heading {
  text-align: center;
  border-bottom: 5px solid #20a258;
  margin-bottom: -5px !important;
  padding: 2px 30px;
  font-weight: 500 !important;
  color: #000;
  letter-spacing: 0.2px !important;
  word-spacing: 0.8px !important;
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif;
}

.check_out_ccard_add_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}
.check_out_ccard_add_card_header p {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.check_out_ccard_add_card_header button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 50px !important;
  background-color: #20a258 !important;
  padding: 5px 20px !important;
}
.check_out_ccard_add_card_header button span {
  text-transform: capitalize;
  color: #fff;
}

.check_out_ccard_add_card_row_item {
  border-radius: 10px;
  background-color: #f3f0f0;
  padding: 10px;
  margin: 10px 0px;
  cursor: pointer;
}
.check_out_ccard_add_card_row_item h6 {
  color: #000000;
  font-family: "Open Sans", sans-serif;
}
.check_out_ccard_add_card_row_item .ccard_card_row_action_icon {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 9px !important;
}
.check_out_ccard_add_card_row_item .ccard_card_row_action_icon img {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.checkout_card_selected {
  background-color: #20a258;
}
.checkout_card_selected h6 {
  color: #fff;
}

.check_wrapper .check_root {
  padding: 0px !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 5px !important;
}

.custom_card_select_checkbox {
  color: #333;
  border-radius: 5px;
  font-size: 20px;
  background-color: #f3f0f0;
}

.flex_item {
  justify-content: space-between;
  align-items: flex-end;
}
.flex_item h6 {
  color: #000000;
  font-weight: 600;
}

.ccard_radio {
  display: flex;
  align-items: flex-end !important;
}
.ccard_radio span {
  padding-bottom: 0px;
}

.cvv_form_wrapper {
  padding: 20px 40px 40px;
}
.cvv_form_wrapper .cvv_heading {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  border-bottom: 5px solid #20a258;
  margin-bottom: -5px !important;
  padding: 5px 20px;
}
.cvv_form_wrapper .cvv_otp_fields {
  width: 60px !important;
  text-align: center;
  height: 60px;
  padding: 15px;
  border: none;
  margin: 0px 10px;
  outline: none;
  border-radius: 8.8px;
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.19);
  background-color: #f6f6f6;
  color: #20a258;
  font-weight: 500;
  font-size: 20px;
}
.cvv_form_wrapper .cvv_error {
  margin-top: 15px;
  margin-left: 15px;
  color: #ff0000;
}
.cvv_form_wrapper button {
  width: 100%;
  background-color: #20a258;
  padding: 12px !important;
  border-radius: 30px !important;
  margin-top: 30px;
}
.cvv_form_wrapper button span {
  color: #ffffff;
  text-transform: capitalize;
}
.cvv_form_wrapper button:hover {
  background-color: #20a258;
}
.cvv_form_wrapper button:hover span {
  color: #ffffff;
}

.cvv_check_disabled {
  background-color: #c3c3c3 !important;
}
.cvv_check_disabled span {
  color: #000000;
}

.cvv_statement {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.cvv_input_wrapper input {
  width: 30px;
  height: 30px;
  padding: 15px;
  text-align: center;
}
.cvv_input_wrapper input::after, .cvv_input_wrapper input::before {
  border: none !important;
  outline: none !important;
}

.ccard_detail_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.ccard_detail_wrapper h6 {
  margin-left: 5px;
}

.add_card_fields {
  margin: 20px 0px !important;
}

.custom_masked_field {
  font-family: "Montserrat", sans-serif;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.09);
  padding: 15px 20px;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 15px;
  margin: 5px 0px;
}
.custom_masked_field:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.custom_masked_field:focus {
  outline: none;
  color: #20a258;
}
.custom_masked_field::-moz-placeholder {
  color: rgba(0, 0, 0, 0.35);
  font-weight: 600;
}
.custom_masked_field:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.35);
  font-weight: 600;
}
.custom_masked_field::placeholder {
  color: rgba(0, 0, 0, 0.35);
  font-weight: 600;
}
.custom_masked_field:focus::-moz-placeholder {
  color: #20a258;
}
.custom_masked_field:focus:-ms-input-placeholder {
  color: #20a258;
}
.custom_masked_field:focus::placeholder {
  color: #20a258;
}

.custom_save_radio {
  border: 2px solid #20a258 !important;
  height: 8px;
  width: 8px;
  padding: 8px !important;
  margin-right: 10px !important;
}
.custom_save_radio svg {
  color: #fff;
  font-size: 16px;
}

.custom_check_save_radio {
  background-color: #20a258 !important;
}

.save_card_header {
  font-family: "Montserrat", sans-serif !important;
}

@media (max-width: 800px) {
  .cvv_form_wrapper {
    padding: 0px 40px 20px;
  }
}
@media (max-width: 599px) {
  .ccard_card_row_action_icon {
    text-align: end;
  }
  .cvv_input_wrapper {
    padding: 1px;
  }
}
@media (max-width: 300px) {
  .flex_item {
    flex-direction: column;
    align-items: flex-start;
    text-align: end !important;
  }
  .ccard_price_wrapper {
    width: 100%;
  }
  .ccard_radio {
    display: flex;
    align-items: center !important;
  }
  .ccard_radio span {
    padding-bottom: 10px;
  }
}
.card_form_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.landing_page_reorder {
  display: flex;
  justify-content: flex-end;
  padding: 20px 10px;
}
@media screen and (max-width: 768px) {
  .landing_page_reorder {
    padding: 10px 0px;
  }
  .landing_page_reorder button {
    font-size: 13px;
    padding: 7px 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .landing_page_reorder button {
    width: 100%;
  }
}

.llp_loader_container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.llp_loader_container svg {
  color: #20a258;
}

.landing_page_container {
  background-color: rgba(244, 237, 226, 0.65);
}

.landing_page_color_container {
  background-color: #f3f3f3;
}

.landing_page_section {
  padding: 60px 0px;
}
@media screen and (max-width: 768px) {
  .landing_page_section {
    padding: 10px 0px 40px;
  }
}

.user_details_section {
  padding: 20px 10px;
}
.user_details_section .user_name {
  font-size: 30px;
  color: #20a258;
}
.user_details_section .user_name .user_name_value {
  text-transform: capitalize;
  color: #20a258;
}
@media screen and (max-width: 768px) {
  .user_details_section .user_name {
    font-size: 24px;
  }
}

.landing_page_section_details {
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing_page_section_details .h1 {
  font-family: "AllRoundGothicW01-Book" !important;
  font-weight: lighter !important;
  font-size: 34px !important;
  color: #333 !important;
}
@media screen and (max-width: 768px) {
  .landing_page_section_details .h1 {
    font-size: 26px !important;
  }
}

.green_small_heading {
  font-size: 20px;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  color: #3bad6c !important;
}
@media screen and (max-width: 768px) {
  .green_small_heading {
    font-size: 15px;
  }
}

.landing_page_courses_container {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3bad6c;
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #d3d3d3;
  border-radius: 20px;
  padding: 15px;
  text-align: center;
}

.green_mid_banner {
  background: url(/static/media/grass-banner.daca0b27.png);
  background-size: cover;
  width: 100%;
  padding: 60px;
  text-align: center;
  margin-bottom: 10px;
}
.green_mid_banner p {
  color: #ffffff;
  font-size: 38px;
  text-transform: capitalize;
  font-family: "AllRoundGothicW01-Book";
}
@media screen and (max-width: 768px) {
  .green_mid_banner {
    padding: 20px 10px;
    margin-bottom: 20px;
  }
  .green_mid_banner p {
    font-size: 26px;
  }
}

.latest_updates_card_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 50vh;
  cursor: pointer;
}
.latest_updates_card_wrapper img {
  position: relative;
  width: 100%;
  border-radius: 20px;
  -o-object-fit: cover;
     object-fit: cover;
}

.latest_updates_card_ad {
  height: 47.2vh;
}

.latest_updates_card_name_wrapper {
  width: 90%;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px 20px;
  margin: 0px 15px 0px;
  opacity: 0.87;
  background-color: #fff;
}
@media (max-width: 540px) {
  .latest_updates_card_name_wrapper {
    padding: 10px;
    word-wrap: break-word;
  }
}

.latest_updates_card_name {
  font-size: 20px !important;
  text-align: center;
  color: #20a258;
  font-weight: 600 !important;
  padding: 0px 30px;
  font-family: "Montserrat", sans-serif !important;
}
@media (max-width: 540px) {
  .latest_updates_card_name {
    font-size: 15px !important;
  }
}

.farms_liked_card_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 40vh;
}
.farms_liked_card_wrapper img {
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-radius: 20px;
}
.farms_liked_card_wrapper:hover .card_hidden {
  display: block;
  transition: all 0.8s ease-in-out;
}
.farms_liked_card_wrapper:hover .farms_liked_card_name_wrapper {
  display: none;
}

.empty_star_rating {
  color: #fff;
}

.farms_liked_card_name_wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px 40px;
  margin: 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 6%, rgba(0, 0, 0, 0.7));
}

.farms_liked_card_name {
  font-size: 20px !important;
  text-align: center;
  color: #ffffff;
  font-weight: 600 !important;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif !important;
}

.favourate_icon_wrapper {
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  z-index: 99;
  right: 12px;
  top: 12px;
  cursor: pointer;
  box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
}
.favourate_icon_wrapper button {
  background-color: #ffffff;
  padding: 8px;
}
.favourate_icon_wrapper button svg {
  color: #20a258;
}

.farms_liked_rating_wrapper {
  display: flex;
  justify-content: center;
}

.rated_star {
  color: #ec9e31;
}

.un_rated_star {
  color: #ffffff;
}

.card_hidden {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 1;
  width: 100%;
  background-color: rgba(25, 161, 79, 0.82);
  border-radius: 20px;
  text-align: center;
  transition: all 1s ease-in;
}
.card_hidden .farms_liked_card_desc {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.69;
  text-align: center;
  margin-top: 20px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.card_hidden_wrapper {
  height: 85%;
  width: 90%;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  margin: 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease-in;
}

.view_card_details_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view_card_details_wrapper p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}
.view_card_details_wrapper button {
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  padding: 6px;
}
.view_card_details_wrapper button svg {
  color: #ffffff;
}

.green-btn {
  background-color: #20a258 !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  width: 180px;
}

.grey_text {
  color: #5a5a5a !important;
  font-weight: 500 !important;
  padding-top: 5px;
  font-family: "Montserrat", sans-serif !important;
  word-break: break-word;
}

.landing_page_EHF {
  background-color: #feefda;
}

.fav_courses_card_wrapper {
  position: relative;
  border-radius: 20px;
}
.fav_courses_card_wrapper img {
  height: 260px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
}

.fav_course_item {
  background-color: rgba(12, 12, 12, 0.42);
  border-radius: 20px;
  position: absolute;
  top: 0%;
  left: 0%;
  padding: 25px 30px;
  color: #ffffff !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fav_course_item .fav_course_item_text {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.fav_course_name {
  text-transform: capitalize;
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.fav_course_author {
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
}

.fav_courses_rating_wrapper {
  display: flex;
  align-items: center;
}

.fav_courses_rating {
  position: relative;
}

.favourate_icon {
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 10px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
}
.favourate_icon button {
  background-color: #ffffff;
  padding: 8px;
  box-shadow: 0 2px 15px 0 rgba(183, 189, 196, 0.57);
}
.favourate_icon button svg {
  color: #20a258;
}

/*bottom section*/
.bottom_section_card_wrapper {
  background-color: #ffffff;
  border-radius: 20px;
  height: 100%;
}
.bottom_section_card_wrapper img {
  width: 100%;
  height: 220px;
  -o-object-fit: cover;
     object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.bottom_section_card_img_wrapper {
  position: relative;
}
.bottom_section_card_img_wrapper p {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 6px;
  margin: 0px 15px;
  padding: 8px 20px 2px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 14px;
  font-weight: 600;
}

.bottom_section_card_details {
  padding: 15px 25px 25px;
}
.bottom_section_card_details p {
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #000;
  margin-bottom: 15px;
}

.green_question_text {
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
  color: #4cb479 !important;
}

.arrow {
  text-align: right !important;
}

.landing_page_btn_container_arrow {
  text-align: right;
}
.landing_page_btn_container_arrow .arrow-btn {
  background-color: none !important;
  text-align: right !important;
  text-decoration: none !important;
}
.landing_page_btn_container_arrow .arrow-btn .arrow-btn-label {
  background-color: none !important;
}
.landing_page_btn_container_arrow .arrow-btn span {
  padding: 0 !important;
  margin: 0 !important;
}

.arrow-btn-label-dark {
  color: #1e1e1e !important;
}

.landing_page_btn_container_center {
  text-align: center;
}

.arrow-btn-label-grey {
  background-color: none !important;
  text-decoration: none !important;
  color: #c3c3c3 !important;
}

@media (max-width: 599px) {
  .landing_page_btn_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.guest_landing_banner {
  background: url(/static/media/PlantHand.47ce911b.png) no-repeat;
  background-size: 100% 100%;
  padding: 20px 0px;
}

.guest_landing_slider_indicator svg {
  width: 18px;
  height: 18px;
  color: #ffffff;
}

.guest_landing_active_indicator svg {
  fill: #7dc642;
}

.center_box {
  background-color: rgba(244, 237, 226, 0.7);
}

.landing_info_first {
  margin-top: 50px;
  padding: 80px 0px;
  position: relative;
}

.guest_landing_flower {
  position: absolute;
  height: 538px;
  width: 111px;
  right: 0px;
  top: 0px;
}

.landing_question_heading {
  font-family: "AllRoundGothicW01-Book" !important;
  font-size: 38px !important;
  color: #333333 !important;
  margin-bottom: 20px !important;
}

.landing_info_boxes {
  padding: 50px 30px;
  border-radius: 10px;
  background-color: rgba(42, 166, 95, 0.12);
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  height: 100%;
}
.landing_info_boxes h5 {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  color: #000000;
}
.landing_info_boxes p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.72;
  color: #333232;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 5px;
  word-break: break-word;
}
.landing_info_boxes p::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.landing_info_boxes p::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.landing_info_boxes p::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.landing_info_boxes p::-webkit-scrollbar-thumb {
  background-color: #20a258;
}
.landing_info_boxes .landing_info_boxes_image {
  text-align: center;
}
.landing_info_boxes .landing_info_boxes_image img {
  height: 95px;
  width: 95px;
}

.landing_info_boxes_center {
  background-color: #3bad6c;
  position: relative;
  overflow: hidden;
}
.landing_info_boxes_center h5 {
  color: #ffffff;
}
.landing_info_boxes_center p {
  color: #ffffff;
}
.landing_info_boxes_center p::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.landing_info_boxes_center .landing_box_bubble {
  background-color: rgba(255, 255, 255, 0.23);
  top: 5px;
  right: -14px;
  border-radius: 50%;
  position: absolute;
  height: 320px;
  width: 320px;
  transform: translate(40%, -40%);
}

.landing_info_second {
  margin: 180px 0 100px 0;
}

.landing_info_second_box {
  background-color: #e5f4ec;
  width: 95%;
  border-bottom-right-radius: 130px;
  border-top-right-radius: 25px;
}

.landing_info_images_container {
  display: flex;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
  margin-top: -50px;
}

.landing_info_sunset {
  height: 448px;
}

.landing_info_grasscut {
  height: 96px;
  display: block;
  margin-bottom: 30px;
}

.landing_info_faces {
  height: 416px;
}

.landing_info_about {
  display: flex;
  flex-direction: column;
  margin: 70px 0px 30px 50px;
  padding: 30px;
  cursor: pointer;
}
.landing_info_about:hover {
  border-radius: 20px;
  box-shadow: 0 2px 27px 6px rgba(0, 0, 0, 0.07);
  transition: all 0.5s;
}
.landing_info_about:hover .about_us_arrow {
  opacity: 1;
}
.landing_info_about .about_us_arrow {
  color: #333;
  font-size: 30px;
  rotate: 180deg;
  opacity: 0;
  transition: all 0.5s;
}
.landing_info_about h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: #3bad6c;
}

.about_subheading p {
  font-family: "AllRoundGothicW01-Book";
  font-size: 38px;
  line-height: 1.68;
  color: #333333;
  margin-top: 18px;
  word-break: break-word;
}

.about_description p {
  margin-top: 18px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.72;
  color: #333232;
  word-break: break-word;
}

.landing_info_third {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
}
@media screen and (max-width: 959px) {
  .landing_info_third {
    flex-direction: column;
  }
}

.farming_left_portion {
  padding: 0px 90px 0px 130px;
}
.farming_left_portion p:nth-child(1) {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: normal;
  color: #3bad6c;
}
.farming_left_portion p:nth-child(2) {
  font-family: "AllRoundGothicW01-Book";
  font-size: 38px;
  line-height: 1.5;
  color: #333333;
  width: 85%;
  word-break: break-word;
}
.farming_left_portion p:nth-child(3) {
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.72;
  color: #333232;
  word-break: break-word;
}

.farming_carousel {
  width: 40vw;
}
@media screen and (max-width: 959px) {
  .farming_carousel {
    width: 100%;
    padding: 0px 60px;
  }
}
.farming_carousel .farm-slick .slick-slide {
  padding: 0px 10px;
}

.view_farm_button {
  padding: 4px 23px !important;
  border-radius: 25px !important;
  border: 1px solid #3bad6c !important;
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  margin-top: 40px !important;
}
.view_farm_button span {
  color: #000000 !important;
}

.farming_images_container {
  display: flex;
  justify-content: flex-end;
}

.farming_images_arrows {
  display: flex;
  justify-content: flex-end;
  margin-top: 72px;
}
.farming_images_arrows img {
  cursor: pointer;
}
.farming_images_arrows img:last-child {
  margin-right: 40px;
  margin-left: 20px;
}

.farming_images {
  height: 415px !important;
  padding: 0px !important;
  border-radius: 15px !important;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 475px) and (max-width: 599px) {
  .farming_images {
    height: 325px !important;
  }
}
@media (min-width: 380px) and (max-width: 474px) {
  .farming_images {
    height: 250px !important;
  }
}

.farming_images:not(:last-child) {
  margin-right: 10px;
}

.landing_info_fourth {
  margin-top: 80px;
  background-color: #e5f4ec;
  padding: 30px 0;
}

.landing_info_offer_heading {
  margin-bottom: 50px;
}
.landing_info_offer_heading div:nth-last-child(2) p {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: #3bad6c;
}
.landing_info_offer_heading div:last-child p {
  font-family: "AllRoundGothicW01-Book";
  font-size: 38px;
  color: #333333;
}

.landing_info_offer_boxes {
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 40px 30px;
  box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.08);
}
.landing_info_offer_boxes p:nth-last-child(2) {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  word-break: break-word;
}
.landing_info_offer_boxes p:last-child {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.72;
  color: #333232;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 5px;
  word-break: break-word;
}
.landing_info_offer_boxes p:last-child::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.landing_info_offer_boxes p:last-child::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.landing_info_offer_boxes p:last-child::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

.landing_info_fifth {
  padding: 80px 0px;
  position: relative;
}

.guest_landing_old_home {
  position: absolute;
  height: 181px;
  width: 192px;
  left: 0px;
  bottom: 0;
}

.landing_info_testimonial_boxes {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 40px 45px;
  box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.08);
  border-radius: 25px;
  grid-gap: 30px;
  gap: 30px;
  border-top-left-radius: 100px;
  position: relative;
  z-index: 9;
}
.landing_info_testimonial_boxes p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.72;
  color: #333232;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 5px;
  word-break: break-word;
}
.landing_info_testimonial_boxes p::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  border-radius: 8px;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.landing_info_testimonial_boxes p::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c3c3c3;
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}
.landing_info_testimonial_boxes p::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  scrollbar-face-color: #f7f7f7;
  scrollbar-track-color: #f7f7f7;
}

.testimonial_quote {
  position: absolute;
  bottom: 35px;
  right: 43px;
  height: 39px;
  width: 55px;
  z-index: -1;
}
@media (min-width: 600px) and (max-width: 959px) {
  .testimonial_quote {
    bottom: 20px;
    right: 28px;
    height: 28px;
    width: 42px;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .testimonial_quote {
    bottom: 30px;
    right: 35px;
    height: 30px;
    width: 45px;
  }
}

.testimonial_user_box {
  display: flex;
  align-items: center;
}
.testimonial_user_box div:nth-last-child(2) {
  height: 64px;
  width: 64px;
  box-shadow: 0 16px 24px 0 rgba(0, 24, 52, 0.08);
}
.testimonial_user_box div:last-child {
  margin-left: 20px;
}
.testimonial_user_box div:last-child h6:nth-last-child(2) {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
  color: #333333;
}
.testimonial_user_box div:last-child h6:last-child {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 2;
  color: #333333;
}

@media (min-width: 280px) and (max-width: 474px) {
  .landing_question_heading {
    font-size: 25px !important;
  }
  .about_subheading p {
    font-size: 30px;
    line-height: 1.24;
  }
  .farming_left_portion {
    padding: 0px 40px;
  }
  .farming_left_portion p:nth-child(2) {
    font-size: 28px;
    line-height: 1.2;
    width: 100%;
  }
  .landing_info_offer_heading {
    margin-bottom: 15px;
  }
  .landing_info_offer_heading div:last-child p {
    font-size: 28px;
  }
  .landing_info_second {
    margin: 120px 0 80px;
  }
  .landing_info_about {
    margin: 0px;
  }
  .farming_carousel {
    padding: 0px 40px;
  }
  .landing_info_fourth {
    margin-top: 30px;
    padding: 30px 0;
    background-color: #e5f4ec;
  }
  .landing_info_fifth {
    padding: 30px 0;
  }
  .landing_info_sunset {
    height: 240px;
  }
  .landing_info_grasscut {
    height: 50px;
  }
  .landing_info_faces {
    height: 230px;
  }
}
@media (min-width: 475px) and (max-width: 599px) {
  .landing_question_heading {
    font-size: 28px !important;
  }
  .about_subheading p {
    font-size: 36px;
    line-height: 1.32;
  }
  .farming_left_portion {
    padding: 0px 40px;
  }
  .farming_left_portion p:nth-child(2) {
    font-size: 36px;
    line-height: 1.3;
    width: 100%;
  }
  .landing_info_offer_heading {
    margin-bottom: 20px;
  }
  .landing_info_offer_heading div:last-child p {
    font-size: 36px;
  }
  .landing_info_second {
    margin: 130px 0 90px;
  }
  .landing_info_about {
    margin: 0px;
  }
  .farming_carousel {
    padding: 0px 40px;
  }
  .landing_info_fourth {
    margin-top: 30px;
    padding: 30px 0;
    background-color: #e5f4ec;
  }
  .landing_info_fifth {
    padding: 30px 0;
  }
  .landing_info_sunset {
    height: 325px;
  }
  .landing_info_grasscut {
    height: 70px;
  }
  .landing_info_faces {
    height: 310px;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  .landing_info_sunset {
    height: 380px;
  }
  .landing_info_grasscut {
    height: 80px;
  }
  .landing_info_faces {
    height: 352px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.guest_landing_banner_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 75px 20px;
  grid-gap: 20px;
  gap: 20px;
}
@media screen and (max-width: 500px) {
  .guest_landing_banner_content {
    padding: 35px 15px;
  }
}
.guest_landing_banner_content img {
  height: 67.5px;
}
@media screen and (max-width: 500px) {
  .guest_landing_banner_content img {
    height: 40px;
  }
}
.guest_landing_banner_content h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 75px;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .guest_landing_banner_content h3 {
    font-size: 52px;
  }
}
@media screen and (max-width: 500px) {
  .guest_landing_banner_content h3 {
    font-size: 34px;
  }
}
.guest_landing_banner_content button {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  padding: 13px 65px;
  text-transform: none;
  border-radius: 36px;
}
@media screen and (max-width: 800px) {
  .guest_landing_banner_content button {
    padding: 10px 50px;
  }
}
@media screen and (max-width: 500px) {
  .guest_landing_banner_content button {
    padding: 8px 40px;
  }
}
.guest_landing_banner_content button:hover {
  background-color: #ffffff;
  opacity: 0.95;
}
.guest_landing_banner_content button span {
  color: #16a252;
  font-size: 18px;
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .guest_landing_banner_content button span {
    font-size: 15px;
  }
}
@media screen and (max-width: 500px) {
  .guest_landing_banner_content button span {
    font-size: 13px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.purchases_course_heading {
  font-family: "AllRoundGothicW01-Book" !important;
  font-size: 30px !important;
  color: #333 !important;
  margin-top: 30px !important;
}

.cc_container {
  cursor: pointer;
}

.MuiTypography-root.cc_course_name.MuiTypography-h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-top: 1rem;
}

.MuiTypography-root.cc_author_name.MuiTypography-h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #746f6f;
  margin-bottom: 2rem;
  font-size: 15px;
  margin-top: 2px;
}

.default_course_container {
  background-color: #FBAB7E;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  width: 100%;
  height: 200px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.default_course_container p {
  font-weight: 600;
  font-size: 2.3rem;
  color: #fff;
  letter-spacing: 5px;
}

.fav_page_empty_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  padding: 30px;
  height: 40vh;
  border: 2px solid #f0f0f0;
  border-radius: 15px;
  margin-top: 10px;
}
.fav_page_empty_box p {
  font-weight: 600;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  color: #1b1c1e;
  text-align: center;
}
.fav_page_empty_box .MuiButton-root:hover {
  background-color: #20a258;
  opacity: 0.9;
}
.fav_page_empty_box button {
  background-color: #20a258;
  padding: 8px 30px;
  border-radius: 20px;
  text-transform: none;
}
.fav_page_empty_box button span {
  color: #fff;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.clf_main_wrapper {
  padding: 50px 0px 80px 0px;
}
.clf_main_wrapper .clf_main_heading {
  font-family: "AllRoundGothicW01-Book";
  font-size: 50px;
  color: #333;
}
.clf_main_wrapper .clf_description {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
  color: rgba(51, 51, 51, 0.5);
}
@media screen and (max-width: 480px) {
  .clf_main_wrapper {
    padding: 10px 0px 40px 0px;
  }
  .clf_main_wrapper .clf_main_heading {
    font-size: 42px;
  }
  .clf_main_wrapper .clf_description {
    font-size: 15px;
  }
}

.view_all_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.view_all_btn_container .orange_btn {
  padding: 10px 50px;
  border-radius: 24px;
  background-color: #feab0b;
  text-transform: none;
}
.view_all_btn_container .orange_btn:hover span {
  color: #000;
}
.view_all_btn_container .orange_btn span {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.clf_empty_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  height: 40vh;
  grid-gap: 15px;
  gap: 15px;
  border: 2px solid #d3d3d3;
  border-radius: 20px;
}
.clf_empty_container .clf_loader {
  color: #20a258;
}
.clf_empty_container h4 {
  color: #333;
  font-weight: 600;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
}
.clf_empty_container button {
  background-color: #20a258;
  text-transform: none;
  padding: 8px 20px;
  border-radius: 8px;
}
.clf_empty_container button:hover span {
  color: #333;
}
.clf_empty_container button span {
  color: #ffffff;
  font-size: 16px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.ndd_user_details {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.ndd_user_details div {
  border-radius: 50%;
  padding: 4px;
  border: 2px solid #20a258;
}
.ndd_user_details div img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.ndd_user_details p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.ndd_content_wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.ndd_heading_container {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  background-color: #f6faf7;
  padding: 12px 30px;
  border-radius: 24px;
}
.ndd_heading_container .ndd_topic_select {
  border: 2px dashed #35a042 !important;
  border-radius: 8px;
  color: #35a042;
  font-size: 14px;
  width: 120px;
}
.ndd_heading_container .ndd_topic_select .MuiSelect-select.MuiSelect-select {
  padding: 6px 15px;
}
.ndd_heading_container .ndd_topic_select .MuiSelect-select.MuiSelect-select:focus {
  background-color: transparent;
}
.ndd_heading_container .ndd_heading {
  padding: 0px 10px;
  font-family: "Montserrat", sans-serif;
  color: #333;
}
.ndd_heading_container .ndd_heading input::-moz-placeholder {
  color: #abafb3;
  opacity: 1;
}
.ndd_heading_container .ndd_heading input:-ms-input-placeholder {
  color: #abafb3;
  opacity: 1;
}
.ndd_heading_container .ndd_heading input::placeholder {
  color: #abafb3;
  opacity: 1;
}
@media screen and (max-width: 600px) {
  .ndd_heading_container {
    grid-gap: 5px;
    gap: 5px;
    padding: 10px 15px;
  }
  .ndd_heading_container .ndd_topic_select .MuiSelect-select.MuiSelect-select {
    padding: 4px 8px;
  }
}

.ndd_description {
  border: none;
  border-radius: 14px;
  padding: 15px 30px;
  width: 100%;
  background-color: #f6faf7;
  resize: none;
  outline: none;
  color: #333;
  font-family: "Montserrat", sans-serif;
}
.ndd_description::-moz-placeholder {
  color: #abafb3;
}
.ndd_description:-ms-input-placeholder {
  color: #abafb3;
}
.ndd_description::placeholder {
  color: #abafb3;
}

.ndd_submit_btn_container {
  justify-content: flex-start !important;
  padding: 20px 24px 24px !important;
}
.ndd_submit_btn_container button {
  border-radius: 25px;
  text-transform: none;
  padding: 8px 30px;
  background-image: linear-gradient(104deg, #2dae43 -27%, #147620 151%);
}
.ndd_submit_btn_container button span {
  color: #ffffff;
}
.ndd_submit_btn_container .ndd_submit_disabled {
  background-image: none;
  background-color: #f7f7f7;
}
.ndd_submit_btn_container .ndd_submit_disabled span {
  color: #000;
}

.ndd_topic_menu_loader {
  background-color: #ffffff !important;
}
.ndd_topic_menu_loader div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.ndd_topic_menu_loader svg {
  color: #20a258 !important;
}

.sdd_dialog_title {
  padding: 40px 40px 10px !important;
}
@media screen and (max-width: 480px) {
  .sdd_dialog_title {
    padding: 25px 20px 10px !important;
  }
}

.sdd_dialog_content {
  padding: 0px 40px 40px !important;
}
@media screen and (max-width: 480px) {
  .sdd_dialog_content {
    padding: 0px 20px 20px !important;
  }
}

.sdd_search_field {
  padding: 8px 15px !important;
  border-radius: 12px;
  background-color: #f6faf7;
}
.sdd_search_field input {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #818181;
}
.sdd_search_field input::-moz-placeholder {
  color: #818181 !important;
}
.sdd_search_field input:-ms-input-placeholder {
  color: #818181 !important;
}
.sdd_search_field input::placeholder {
  color: #818181 !important;
}

.sdd_search_icon {
  color: #0fa64f;
}

.sdd_topic_heading {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-bottom: 10px;
  z-index: 1;
}

.sdd_empty_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
}
.sdd_empty_container svg {
  color: #20a258;
}
.sdd_empty_container p {
  opacity: 0.5;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.sdd_topics_list {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  margin-top: 15px;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.ce_main_wrapper {
  padding: 80px 0 50px;
  background-color: rgba(20, 118, 32, 0.06);
}
.ce_main_wrapper .ce_main_heading {
  font-family: "AllRoundGothicW01-Book";
  font-size: 50px;
  line-height: 1.14;
  color: #333;
}
.ce_main_wrapper .ce_description {
  margin-top: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
  color: rgba(51, 51, 51, 0.5);
}
.ce_main_wrapper .ce_carousel_wrapper {
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  min-height: 200px !important;
}
.ce_main_wrapper .ce_carousel_wrapper .ce_carousel {
  width: 100vw;
}
.ce_main_wrapper .ce_empty_container {
  height: 40vh;
  border: 2px solid #d3d3d3;
  border-radius: 20px;
  padding: 15px;
  color: #3bad6c;
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  .ce_main_wrapper {
    padding: 30px 0px 0px;
  }
  .ce_main_wrapper .ce_main_heading {
    font-size: 45px;
  }
  .ce_main_wrapper .ce_description {
    font-size: 15px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.experts_loader {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.experts_loader svg {
  color: #20a258;
}

.experts_upper_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}
.experts_upper_wrapper .experts_filter_icon {
  border: 1px solid #d3d3d3;
  padding: 8px;
  min-width: 40px !important;
}
.experts_upper_wrapper .experts_filter_icon:hover img {
  filter: invert(0%) sepia(4%) saturate(0%) hue-rotate(324deg) brightness(95%) contrast(105%);
}
.experts_upper_wrapper .experts_filter_icon img {
  height: 20px;
  width: 20px;
}
.experts_upper_wrapper .isFilterEnabled {
  background-color: #20a258;
}
.experts_upper_wrapper .isFilterEnabled img {
  filter: invert(100%) sepia(0%) saturate(7477%) hue-rotate(107deg) brightness(102%) contrast(105%);
}

.experts_filter_menu_wrapper {
  padding: 10px 0px;
}
.experts_filter_menu_wrapper div {
  padding: 6px 12px;
  cursor: pointer;
}
.experts_filter_menu_wrapper div:hover, .experts_filter_menu_wrapper div:focus {
  background-color: rgba(0, 0, 0, 0.04);
}
.experts_filter_menu_wrapper .isExpertFilterSelected {
  background-color: rgba(0, 0, 0, 0.08);
}
.experts_filter_menu_wrapper .isExpertFilterSelected:hover, .experts_filter_menu_wrapper .isExpertFilterSelected:focus {
  background-color: rgba(0, 0, 0, 0.08);
}

.edd_main_wrapper .edd_image_loader {
  color: #20a258;
}
.edd_main_wrapper .edd_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}
.edd_main_wrapper .edd_loader_container svg {
  color: #20a258;
}
.edd_main_wrapper .edd_main_heading {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #333;
}
.edd_main_wrapper .edd_secondary_heading {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.19px;
  color: #394052;
}
.edd_main_wrapper .edd_name_heading {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.48px;
  color: #1e8d2f;
}
.edd_main_wrapper .edd_education_details,
.edd_main_wrapper .edd_experience_details {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  letter-spacing: 1.11px;
  color: #394052;
  margin-top: 4px;
}
.edd_main_wrapper .edd_experience_details {
  font-weight: 500;
  margin-top: 16px;
}
.edd_main_wrapper .edd_website {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.53;
  letter-spacing: 1.11px;
  color: #5580ea;
  text-decoration: underline;
}
.edd_main_wrapper .edd_social_container {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding-top: 8px;
}
.edd_main_wrapper .edd_expert_description {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  opacity: 0.57;
  margin-top: 15px;
  word-wrap: break-word;
}
.chp_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
.chp_loader_container svg {
  color: #20a258;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.checkout_current_step_box {
  display: flex;
  justify-content: center;
  min-height: 50vh;
}

.checkout_login_container {
  text-align: center;
}
.checkout_login_container p {
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}
.checkout_login_container .checkout_login_button {
  padding: 10px 25px !important;
  background-color: #20a258 !important;
}
.checkout_login_container .checkout_login_button span {
  text-transform: capitalize;
  color: #ffffff;
}

.checkout_order_dialog_paper {
  border-radius: 35px !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.checkout_order_dialog_paper::-webkit-scrollbar {
  display: none !important; /* Hide scrollbar for Safari and Chrome */
}

.checkout_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
}
.checkout_loader_container svg {
  color: #20a258 !important;
}

.no_card_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20vh;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  margin-bottom: 10px;
}
.no_card_container h2 {
  color: #20a258;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}
.no_card_container h5 {
  color: rgba(51, 51, 51, 0.5);
  font-size: 18px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.reward_radio_label {
  width: 100% !important;
}

.reward_payment_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.reward_payment_header .reward_heading {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 100%;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #000000;
}
.reward_payment_header .selected_reward_heading {
  font-weight: 600;
}
.reward_payment_header .reward_price {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.loyalty_point_wrapper {
  margin-top: 5px;
}
.loyalty_point_wrapper h6 {
  color: #333;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}
.loyalty_point_wrapper .MuiFilledInput-input {
  padding: 15px;
}
.loyalty_point_wrapper .applicable_point_message {
  font-size: 13px;
  color: #FC4B4E;
  font-family: "Montserrat", sans-serif;
  text-align: end;
  margin-top: 2px;
}

.applicable_points_loader_wrapper {
  display: flex;
  justify-content: flex-end;
}
.applicable_points_loader_wrapper > span {
  width: 50%;
}

.checkout_payment_loader {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout_payment_loader svg {
  color: #20a258;
}
@charset "UTF-8";
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.pd_main_wrapper {
  padding: 50px 45px;
}
.pd_main_wrapper .pd_content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  text-align: center;
}
.pd_main_wrapper .pd_content_wrapper img {
  height: 60%;
  width: 60%;
}
.pd_main_wrapper h5,
.pd_main_wrapper h6,
.pd_main_wrapper p {
  padding: 5px;
}
.pd_main_wrapper h5 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #000;
  margin-top: 20px;
}
.pd_main_wrapper h6 {
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  text-align: center;
  font-weight: 300 !important;
  color: #000;
}
.pd_main_wrapper p {
  color: #20a258;
  font-size: 18px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
  word-break: break-word;
}
.pd_main_wrapper .pd_multi_id_wrapper {
  list-style: none;
}
.pd_main_wrapper .pd_multi_id_wrapper li {
  display: flex;
  align-items: center;
}
.pd_main_wrapper .pd_multi_id_wrapper li::before {
  color: #20a258;
  content: "•";
  font-weight: bold;
}
.pd_main_wrapper .pd_multi_id_wrapper p {
  margin-top: 0px;
  padding: 3px;
  text-align: left;
}
.pd_main_wrapper button {
  width: 100%;
  background-color: #1ea157 !important;
  border-radius: 30px !important;
  padding: 10px !important;
}
.pd_main_wrapper button span {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.ch_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  padding: 30px;
  width: 100%;
  background-color: #fff;
}
@media screen and (max-width: 1024px) {
  .ch_container {
    padding: 15px;
  }
}
.ch_container .ch_left_container {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.ch_container .ch_left_container .toggle_menu_icon {
  padding: 8px;
  margin-right: 8px;
}
.ch_container .ch_left_container .toggle_menu_icon img {
  height: 25px;
}
@media (min-width: 1024px) {
  .ch_container .ch_left_container .toggle_menu_icon {
    display: none;
  }
}
.ch_container .ch_logo_wrapper {
  width: 135px;
}
.ch_container .ch_logo_wrapper img {
  width: 100%;
}
@media (max-width: 600px) {
  .ch_container .ch_logo_wrapper {
    width: 120px;
    min-width: 120px;
  }
}
@media (max-width: 400px) {
  .ch_container .ch_logo_wrapper {
    width: 90px;
    min-width: 90px;
  }
}
.ch_container .ch_btn_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 15px;
  gap: 15px;
}
.ch_container .ch_btn_container .ch_btn {
  border-radius: 22px;
  border: 1px solid #147620;
  background-color: #fff;
  padding: 6px 17px;
  text-transform: none;
}
.ch_container .ch_btn_container .ch_btn span {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.ch_container .ch_btn_container .ch_btn .ch_btn_image {
  border-radius: 50%;
  height: 23px;
  width: 23px;
}
@media screen and (max-width: 768px) {
  .ch_container .ch_btn_container .ch_btn {
    padding: 6px !important;
    border-radius: 50%;
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
  }
  .ch_container .ch_btn_container .ch_btn .ch_btn_image {
    height: 26px;
    width: 26px;
  }
}

.ch_account_menu_wrapper {
  padding: 15px 20px;
}
.ch_account_menu_wrapper div {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  padding: 4px 0px;
  color: #333;
}
.ch_account_menu_wrapper div:hover {
  font-weight: 600;
  color: #20a258;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.sdc_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  border-radius: 21px;
  background-color: #f7f7f7;
  padding: 20px 30px;
  width: 100%;
  cursor: pointer;
}
.sdc_container > div {
  overflow: hidden;
}
.sdc_container .sdc_description span,
.sdc_container .sdc_description mark {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 0.5);
}
.sdc_container .sdc_description mark {
  background-color: rgba(28, 137, 44, 0.2);
  padding: 0px 2px;
}
.sdc_container .sdc_heading span,
.sdc_container .sdc_heading mark {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sdc_container .sdc_heading mark {
  background-color: rgba(28, 137, 44, 0.2);
  padding: 0px 2px;
}
.sdc_container .sdc_user_info_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 4px;
  gap: 4px;
  margin-top: 8px;
}
.sdc_container .sdc_user_info_container p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  opacity: 0.5;
}
.sdc_container .sdc_user_info_container svg {
  font-size: 8px;
  color: #000;
  opacity: 0.5;
}
.sdc_container .sdc_follow_btn {
  background-color: #ffffff;
  padding: 6px 25px;
  border-radius: 25px;
  text-transform: none;
  min-width: 125px;
}
.sdc_container .sdc_follow_btn span {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1c892c;
}
.sdc_container .sdc_follow_icon {
  height: 20px;
}
@media screen and (max-width: 480px) {
  .sdc_container {
    padding: 20px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.cd_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.cd_loader_container svg {
  color: #20a258;
}

.cd_topics_main_wrapper {
  overflow: hidden;
  position: relative;
}

.cd_topics_container {
  display: flex;
  align-items: flex-end;
  grid-gap: 5px;
  gap: 5px;
  padding: 0px 15px;
  cursor: pointer;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 7px;
}
.cd_topics_container::-webkit-scrollbar {
  height: 9px;
}
.cd_topics_container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}
.cd_topics_container::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 8px;
}
.cd_topics_container::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}
.cd_topics_container .cd_topic {
  padding: 0px 10px;
}
.cd_topics_container .cd_topic > p {
  padding-bottom: 10px;
  opacity: 0.5;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: center;
  min-width: 110px;
}
.cd_topics_container .cd_topic > div {
  margin-bottom: -5px;
  height: 5px;
  border-radius: 3px;
}
.cd_topics_container .cd_topic_active > p {
  opacity: 1;
}
.cd_topics_container .cd_topic_active > div {
  background-image: linear-gradient(to right, #7fb886 -19%, #35a042 109%);
}

.cd_topics_line {
  position: absolute;
  left: 0;
  bottom: 11px;
  width: 100%;
  height: 5px;
  border-radius: 4px;
  background-color: #f9f8fd;
  z-index: -1;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

mark {
  border-radius: 6px;
  background-color: rgba(28, 136, 43, 0.18);
  padding: 0px 5px;
}

.cdc_main_container {
  padding: 20px 0px;
}
.cdc_main_container .cdc_redirect_container {
  cursor: pointer;
}
.cdc_main_container .cdc_user_info {
  opacity: 0.5;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}
.cdc_main_container .cdc_heading span,
.cdc_main_container .cdc_heading mark {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #000;
}
.cdc_main_container .cdc_description_wrapper {
  margin-top: 5px;
}
.cdc_main_container .cdc_description span,
.cdc_main_container .cdc_description mark {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #b2b2b2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cdc_main_container .cdc_tags_highlight span,
.cdc_main_container .cdc_tags_highlight mark {
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  color: #1c892c;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cdc_main_container .cdc_separator {
  height: 5px;
  border-radius: 4px;
  background-color: #f9f8fd;
}
.cdc_main_container .cdc_comments_container {
  padding: 0px 0px 5px 20px;
}
.cdc_main_container .cdc_comments_container .cdc_comment_heading {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.cdc_main_container .cdc_comments_container .cdc_not_first {
  border-top: solid 1px #979797;
}
.cdc_main_container .cdc_comments_container .cdc_comment {
  padding: 15px 0px;
}
.cdc_main_container .cdc_no_comments_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 8px;
}
.cdc_main_container .cdc_comment_filter_btn {
  background-color: none;
  text-transform: none;
  border-radius: 18px;
  padding: 4px 10px;
}
.cdc_main_container .cdc_comment_filter_btn span {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1c892c;
}
.cdc_main_container .cdc_bottom_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
  border-radius: 32px;
  background-color: #f6faf7;
  padding: 8px 12px;
}
.cdc_main_container .cdc_bottom_container .cdc_follow_btn {
  background-color: #ffffff;
  padding: 6px 25px;
  border-radius: 25px;
  text-transform: none;
  height: 40px;
  min-width: 100px;
}
.cdc_main_container .cdc_bottom_container .cdc_follow_btn span {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1c892c;
}
.cdc_main_container .cdc_bottom_container .cdc_follow_icon {
  height: 20px;
}
.cdc_main_container .cdc_bottom_container .cdc_comment_count_container {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding-right: 10px;
}
.cdc_main_container .cdc_bottom_container .cdc_comment_count_container img {
  height: 22px;
  width: 22px;
}
.cdc_main_container .cdc_bottom_container .cdc_comment_count_container p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1c892c;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.cdl_loader_container {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cdl_loader_container svg {
  color: #20a258;
}

.cdl_main_container {
  padding: 20px 0px;
}
.cdl_main_container .cdl_lod_more_btn {
  padding: 8px 55px;
  height: 50px;
  border-radius: 49px;
  background-image: linear-gradient(103deg, #2dae43 -27%, #147620 151%);
  text-transform: none;
  font-weight: normal !important;
}
.cdl_main_container .cdl_lod_more_btn span {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: normal !important;
  font-size: 22px;
}
@media screen and (max-width: 450px) {
  .cdl_main_container .cdl_lod_more_btn {
    padding: 6px 35px;
    height: auto;
  }
  .cdl_main_container .cdl_lod_more_btn span {
    font-size: 16px;
  }
}

.cdl_empty_list_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 40vh;
}
.cdl_empty_list_container p {
  opacity: 0.5;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.cdts_main_container {
  padding-bottom: 15px;
}
.cdts_main_container .cdts_search_field {
  padding: 6px 10px;
  border-radius: 10px;
  border: solid 1.3px #d3d3d3;
  background-color: #fff;
  font-size: 16px;
  color: #000;
}
.cdts_main_container .cdts_search_field:hover, .cdts_main_container .cdts_search_field:focus-within {
  border: solid 1.3px #2ea763;
}
.cdts_main_container .cdts_search_field:hover .cdts_hashtag, .cdts_main_container .cdts_search_field:focus-within .cdts_hashtag {
  border-left: 2px solid #2ea763 !important;
}
.cdts_main_container .cdts_search_field .MuiInputAdornment-positionStart {
  margin-right: 1px;
}
.cdts_main_container .cdts_search_field .cdts_filed_icons_wrapper {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.cdts_main_container .cdts_search_field .cdts_filed_icons_wrapper .cdts_hashtag {
  color: #333;
  font-size: 1.2rem;
  border-left: 2px solid #d3d3d3;
  padding-left: 10px;
}
.cdts_main_container .cdts_searching_loader {
  color: #2ea763;
}
.cdts_main_container .cdts_search_icon {
  color: #2ea763;
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.cdd_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  border-top: 3px solid #f9f8fd;
}
.cdd_loader_container svg {
  color: #20a258;
}

.cdd_content_container {
  padding: 20px 60px 30px;
}
.cdd_content_container > p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(51, 51, 51, 0.5);
}
.cdd_content_container .cdd_separator {
  height: 5px;
  margin: 25px 0 15px 0;
  border-radius: 4px;
  background-color: #f9f8fd;
}
.cdd_content_container .cdd_comments_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 15px 0px;
}
.cdd_content_container .cdd_add_comment_wrapper {
  border: 1px solid #c3c3c3;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  margin: 20px 0px 15px;
}
.cdd_content_container .cdd_add_comment_wrapper textarea {
  width: 100%;
  resize: none;
  border: none;
}
.cdd_content_container .cdd_add_comment_wrapper textarea:focus {
  border: none !important;
  outline: none !important;
}
@media screen and (max-width: 480px) {
  .cdd_content_container {
    padding: 20px 20px 30px;
  }
  .cdd_content_container > p {
    font-size: 15px;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.restaurant_map_left_container {
  padding: 30px 20px;
  min-height: 100vh;
  max-height: 100vh;
  width: 320px;
  display: inline-block;
  overflow-y: auto;
}
.restaurant_map_left_container::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.restaurant_map_left_container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}
.restaurant_map_left_container::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 8px;
}
.restaurant_map_left_container::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}
.restaurant_map_left_container .restaurant_map_total_heading p {
  height: 32px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  color: #333333;
}
.restaurant_map_left_container .restaurant_map_all_restaurants {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  grid-gap: 20px;
  gap: 20px;
}
.restaurant_map_left_container .restaurant_map_all_restaurants::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.restaurant_map_left_container .restaurant_map_all_restaurants::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}
.restaurant_map_left_container .restaurant_map_all_restaurants::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 8px;
}
.restaurant_map_left_container .restaurant_map_all_restaurants::-webkit-scrollbar-thumb:hover {
  background: #d3d3d3;
}
@media screen and (max-width: 1000px) {
  .restaurant_map_left_container .restaurant_map_all_restaurants {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 0px;
  }
}
@media screen and (max-width: 1000px) {
  .restaurant_map_left_container {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    max-height: auto;
    min-height: auto;
    overflow-x: hidden;
  }
  .restaurant_map_left_container .restaurant_map_total_heading p {
    margin-left: 15px;
  }
}

.restaurant_map_loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.restaurant_map_loader_wrapper svg {
  color: #20a258;
}

@media screen and (max-width: 1000px) {
  .restaurant_map_dish_card_wrapper {
    display: inline-block;
    margin: 0 10px;
  }
}

.restaurant_map_dish_card {
  width: 270px;
  height: 208px;
  padding: 15px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  border: 6px solid #fff;
}
@media screen and (max-width: 300px) {
  .restaurant_map_dish_card {
    width: 220px;
    height: 160px;
  }
}
.restaurant_map_dish_card img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 6px;
  -o-object-fit: fill;
     object-fit: fill;
}
.restaurant_map_dish_card .dish_card_rating {
  width: 75px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  background-color: #eb8e78;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}
.restaurant_map_dish_card .dish_card_rating p {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  line-height: 1.6;
}
@media screen and (max-width: 300px) {
  .restaurant_map_dish_card .dish_card_rating {
    width: 65px;
    height: 40px;
  }
  .restaurant_map_dish_card .dish_card_rating p {
    font-size: 14px;
  }
}
.restaurant_map_dish_card .dish_card_details {
  margin-left: 4px;
}
.restaurant_map_dish_card .dish_card_details p {
  color: #ffffff;
  font-family: "Montserrat", sans-serif !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.restaurant_map_dish_card .dish_card_details p:nth-child(1) {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.8;
}
.restaurant_map_dish_card .dish_card_details p:nth-child(2) {
  font-size: 24px;
  line-height: 1;
}

.active_restaurant_map_dish_card {
  border: 6px solid #f49b3f;
}

.restaurant_google_map {
  height: 100vh;
  width: calc(100vw - 338px);
  display: inline-block;
}
@media screen and (max-width: 1000px) {
  .restaurant_google_map {
    display: block;
    width: 95vw;
    margin: 0px auto;
  }
}

.restaurant_map_selected_card {
  width: 280px;
  border-radius: 12px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transform: translate(-50%, -100%);
  left: 12px;
  bottom: 48px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ffffff;
}
.restaurant_map_selected_card .restaurant_map_upper_wrapper {
  height: 200px;
  position: relative;
  padding: 16px;
}
.restaurant_map_selected_card .restaurant_map_upper_wrapper img {
  width: 100%;
  height: 200px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -o-object-fit: fill;
     object-fit: fill;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.restaurant_map_selected_card .restaurant_map_upper_wrapper .selected_restaurant_card_rating_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.restaurant_map_selected_card .restaurant_map_upper_wrapper .selected_restaurant_card_rating {
  padding: 5px 14px 5px 12px;
  border-radius: 8px;
  background-color: #ffac0c;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5px;
  gap: 5px;
}
.restaurant_map_selected_card .restaurant_map_upper_wrapper .selected_restaurant_card_rating p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #ffffff;
}
.restaurant_map_selected_card .restaurant_map_upper_wrapper .selected_restaurant_card_close {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffac0c;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.restaurant_map_selected_card .restaurant_map_upper_wrapper .selected_restaurant_card_close svg {
  color: #ffffff;
}
.restaurant_map_selected_card .selected_restaurant_card_details {
  padding: 20px;
}
.restaurant_map_selected_card .selected_restaurant_card_details p {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.restaurant_map_selected_card .selected_restaurant_card_details .selected_card_dish {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
}
.restaurant_map_selected_card .selected_restaurant_card_details .selected_card_restaurant {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #333;
}
.restaurant_map_selected_card .selected_restaurant_card_details .selected_card_address {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgba(51, 51, 51, 0.5);
}
.restaurant_map_selected_card .selected_restaurant_card_details .selected_card_time_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 15px;
}
.restaurant_map_selected_card .selected_restaurant_card_details .selected_card_time_container .selected_card_time {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
}
.restaurant_map_selected_card .selected_restaurant_card_details .selected_card_time_container .selected_card_price {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: rgba(51, 51, 51, 0.5);
}
.restaurant_map_selected_card .selected_restaurant_card_details .selected_card_order_button {
  width: 100%;
  height: 48px;
  margin-top: 12px;
  border-radius: 12px;
  text-transform: none;
  background-color: #1ca055;
  text-align: center;
}
.restaurant_map_selected_card .selected_restaurant_card_details .selected_card_order_button span {
  color: #ffffff;
  font-size: 16px;
}
@media screen and (max-width: 300px) {
  .restaurant_map_selected_card {
    width: 220px;
  }
}
@media screen and (max-width: 500px) {
  .restaurant_map_selected_card {
    width: 240px;
  }
  .restaurant_map_selected_card .restaurant_map_upper_wrapper {
    height: 190px;
  }
  .restaurant_map_selected_card .restaurant_map_upper_wrapper .selected_restaurant_card_rating {
    padding: 3px 10px;
  }
  .restaurant_map_selected_card .restaurant_map_upper_wrapper .selected_restaurant_card_rating p {
    font-size: 16px;
  }
  .restaurant_map_selected_card .restaurant_map_upper_wrapper .selected_restaurant_card_close {
    height: 35px;
    width: 35px;
  }
  .restaurant_map_selected_card .selected_restaurant_card_details .selected_card_dish {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .restaurant_map_selected_card .selected_restaurant_card_details .selected_card_restaurant {
    font-size: 20px;
  }
  .restaurant_map_selected_card .selected_restaurant_card_details .selected_card_address {
    font-size: 14px;
  }
  .restaurant_map_selected_card .selected_restaurant_card_details .selected_card_time_container p {
    font-size: 14px !important;
  }
  .restaurant_map_selected_card .selected_restaurant_card_details .selected_card_order_button {
    height: 40px;
  }
  .restaurant_map_selected_card .selected_restaurant_card_details .selected_card_order_button span {
    font-size: 14px;
  }
}

.restaurant_map_selected_card::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -14px;
  border-width: 14px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.restaurant_map_pin {
  height: 100%;
  width: 100%;
}
.restaurant_map_pin svg {
  color: #ea4335;
  font-size: 50px;
}

.restaurant_map_pin_hover svg {
  opacity: 0.9;
}
@charset "UTF-8";
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.container-img {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.backIcon {
  height: 45px;
  width: 45px;
  margin-left: 10px;
  padding: 6px;
  border-radius: 50%;
}
.backIcon:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media screen and (max-width: 400px) {
  .backIcon {
    height: 25px;
    width: 25px;
  }
}

.heartIcon {
  height: 30px;
  width: 30px;
}
@media screen and (max-width: 400px) {
  .heartIcon {
    height: 20px;
    width: 20px;
  }
}

.courseHeader {
  color: #169d4e;
  padding-left: 15px;
  font-weight: 700;
  word-spacing: 4px;
  margin-right: 75px;
}
@media screen and (max-width: 560px) {
  .courseHeader {
    font-size: 16px;
    margin-right: 0;
  }
}
@media screen and (max-width: 400px) {
  .courseHeader {
    font-size: 14px;
    margin-right: 0;
  }
}

.courseHeaderBox {
  padding: 20px;
  background-color: #f4f9f5;
}
@media screen and (max-width: 560px) {
  .courseHeaderBox {
    box-shadow: 0 4px 4px 0 rgba(21, 21, 21, 0.18);
    border: none !important;
  }
}

.rightBox {
  margin-left: 80px;
  margin-top: 27px;
  width: 100%;
  text-align: right;
  box-sizing: content-box;
}
@media screen and (max-width: 400px) {
  .rightBox {
    margin-left: 50px;
  }
}

.container-img-2 {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  height: 380px !important;
}
.container-img-2 .image {
  position: absolute;
  display: block;
  width: 100%;
  height: 380px !important;
  border-radius: 15px;
}
@media screen and (max-width: 768px) {
  .container-img-2 .image {
    position: relative;
    padding: 20px;
    height: 340px !important;
  }
}
@media screen and (max-width: 560px) {
  .container-img-2 .image {
    position: relative;
    padding: 20px;
    border-radius: 5px;
    height: 278px !important;
  }
}
@media screen and (max-width: 768px) {
  .container-img-2 {
    position: relative;
    height: 340px !important;
  }
}
@media screen and (max-width: 560px) {
  .container-img-2 {
    position: relative;
    height: 278px !important;
  }
}

.container-educator-img {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
}
.container-educator-img .image {
  height: 450px !important;
  margin-top: 30px;
  margin-left: 0px;
  position: absolute;
  display: block;
  width: 100%;
  border-radius: 8px;
}
@media screen and (max-width: 768px) {
  .container-educator-img .image {
    height: 300px !important;
  }
}
@media screen and (max-width: 600px) {
  .container-educator-img .image {
    height: 270px !important;
  }
}
@media screen and (max-width: 520px) {
  .container-educator-img .image {
    height: 220px !important;
  }
}
@media screen and (max-width: 400px) {
  .container-educator-img .image {
    height: 177px !important;
  }
}

.rightEducatorContainer {
  text-align: justify;
  margin-left: -5px;
  margin-top: 20px;
  height: 430px;
  box-shadow: 0 3px 36px 13px rgba(145, 167, 208, 0.05);
  padding: 10px 40px;
  border-radius: 10px;
}
.rightEducatorContainer .h3 {
  margin-top: 20px;
  font-size: 27px;
  font-weight: bold;
  text-transform: capitalize;
  color: #333;
}
@media screen and (max-width: 768px) {
  .rightEducatorContainer .h3 {
    font-size: 22px;
  }
}
@media screen and (max-width: 640px) {
  .rightEducatorContainer .h3 {
    font-size: 18px;
  }
}
@media screen and (max-width: 560px) {
  .rightEducatorContainer .h3 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
}
@media screen and (max-width: 768px) {
  .rightEducatorContainer {
    width: 100%;
    margin-top: 0;
    padding: 10px 0 0 20px !important;
    height: 100%;
  }
}

.discussionData {
  margin-top: 20px;
  margin-left: 1.8rem;
}

.discContainer {
  background-color: rgba(20, 118, 32, 0.08);
  padding: 22px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}
.discContainer h5 {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 560px) {
  .discContainer h5 {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif;
  }
}
.discContainer p {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 560px) {
  .discContainer p {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif;
  }
}
.discContainer .text .MuiInputBase-input {
  margin-top: 1rem;
  font-size: 16px;
  text-align: justify;
}
@media screen and (max-width: 560px) {
  .discContainer .text .MuiInputBase-input {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 560px) {
  .discContainer {
    padding: 16px;
  }
}

.margin-2 {
  margin: 2rem;
  flex: 1 1;
}
@media screen and (max-width: 560px) {
  .margin-2 {
    margin: 10px 20px;
  }
}

.greenTick {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
@media screen and (max-width: 1160px) {
  .greenTick {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width: 960px) {
  .greenTick {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 768px) {
  .greenTick {
    width: 25px;
    height: 25px;
  }
}
@media screen and (max-width: 560px) {
  .greenTick {
    width: 20px;
    height: 20px;
  }
}

.taskTitleWrapper {
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .taskTitleWrapper {
    padding: 0;
  }
}

.ques {
  padding: 0 60px 0 82px;
}
@media screen and (max-width: 960px) {
  .ques {
    padding: 0 0 0 20px;
  }
}

.lessonTabSection {
  padding-left: 40px;
  border-bottom: 4px;
  border-color: divider;
  display: flex;
  justify-content: space-between;
}
.lessonTabSection span.MuiTab-wrapper {
  text-transform: capitalize;
}
@media screen and (max-width: 1160px) {
  .lessonTabSection span.MuiTab-wrapper {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 960px) {
  .lessonTabSection span.MuiTab-wrapper {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 768px) {
  .lessonTabSection span.MuiTab-wrapper {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 640px) {
  .lessonTabSection span.MuiTab-wrapper {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 560px) {
  .lessonTabSection span.MuiTab-wrapper {
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
  }
}
@media screen and (max-width: 750px) {
  .lessonTabSection {
    padding-left: 0;
    flex-direction: column-reverse;
  }
}

.searchWrapper {
  width: 304px;
}
.searchWrapper .searchBox {
  background-color: #fff;
  border-radius: 24px;
  border: solid 1px #979797;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 750px) {
  .searchWrapper .searchBox {
    font-family: "Poppins", sans-serif !important;
    border-radius: 5px;
    background-color: rgba(20, 118, 32, 0.08);
    border: none;
  }
}
@media screen and (max-width: 880px) {
  .searchWrapper {
    width: 250px;
    margin-right: 50px !important;
  }
}
@media screen and (max-width: 750px) {
  .searchWrapper {
    width: 100%;
    padding: 0 2rem;
  }
}
@media screen and (max-width: 560px) {
  .searchWrapper {
    margin-top: 12px;
    width: 100%;
    padding: 0 1rem;
  }
}

.discussionWrapper {
  margin: 0 auto;
  width: 90%;
}
.discussionWrapper .partName {
  margin-left: 20px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 560px) {
  .discussionWrapper .partName {
    font-size: 12px;
    color: #000;
    font-weight: 500;
  }
}
.discussionWrapper .lessonName {
  margin-left: 10px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 560px) {
  .discussionWrapper .lessonName {
    font-size: 12px;
    color: #000;
    font-weight: 500;
  }
}
@media screen and (max-width: 560px) {
  .discussionWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 560px) {
  .DiscussionSection .MuiBox-root {
    padding: 0;
  }
}

.margin3 {
  margin-top: 3rem;
}

.margin4 {
  margin-bottom: 4rem;
}

.container-img-3 {
  position: relative;
  width: 100%;
  max-width: 250px;
}

.overlay {
  width: 200px;
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  transition: 0.5s ease;
  opacity: 0;
  font-size: 20px;
  padding: 13px;
  text-align: center;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.replyDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.replyDetail h5 {
  font-weight: 600;
  color: #000;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1024px) {
  .replyDetail h5 {
    font-size: 16px;
  }
}

.container-img .overlay {
  opacity: 0.69;
}

.container-img-2 .overlay {
  opacity: 0.05;
}

.flex {
  display: flex;
  padding: 1rem;
  justify-content: center;
}
.flex .para {
  color: #92929d;
  font-weight: 500;
  font-size: 16px;
}

.shareMenu {
  position: absolute !important;
  top: 11% !important;
  padding: 20px;
}

.learningHeaderContainer {
  justify-content: unset;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.flexOne {
  display: flex;
  padding: 0.5rem;
  justify-content: unset;
  text-align: justify;
  align-items: center;
  margin: 0 0 12px 1rem;
}
.flexOne .rightArrowIcon {
  height: 25px;
  width: 25px;
  margin-right: 1rem;
}
@media screen and (max-width: 560px) {
  .flexOne .rightArrowIcon {
    width: 20px;
    height: 20px;
  }
}
.flexOne .para {
  color: #92929d;
  font-weight: 500;
  font-size: 16px;
}
@media screen and (max-width: 1160px) {
  .flexOne .para {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .flexOne .para {
    font-size: 12px;
    font-family: "Poppins", sans-serif !important;
  }
}
@media screen and (max-width: 560px) {
  .flexOne {
    padding: 0;
    margin: 0 0 12px 0;
  }
}

.reviewGrid {
  margin: 2.5rem 0 2.5rem;
  justify-content: space-between !important;
}

.reviewText {
  font-weight: bolder;
  font-size: 18px;
}

.reviewImageContainer {
  position: relative;
  margin-right: 20px;
  width: 100px;
  height: 100px;
  padding-top: 0;
}
.reviewImageContainer .image {
  position: absolute;
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}

@media screen and (max-width: 900px) {
  .course-con-1 img {
    width: 70%;
  }
}
@media screen and (max-width: 1340px) {
  .course-con-1 img {
    width: 90%;
  }
}
.MuiChip-root {
  border-radius: 10px;
  padding: 4px;
  min-width: 150px;
}

.intrest {
  min-width: 150px;
  list-style: none;
  border: 1px solid;
  border-radius: 10px;
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.intrest:hover {
  color: #20a258;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
}

.chip {
  font-size: large !important;
  font-weight: bold !important;
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.4509803922) !important;
  border-radius: 18px !important;
  cursor: pointer !important;
  padding: 1.2rem !important;
}

.MuiChip-label {
  color: #8c8c8c !important;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 960px) {
  .MuiChip-label {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .MuiChip-label {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .MuiChip-label {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
}

.MuiChip-label:hover {
  color: #3fa74a !important;
}

.chip:hover {
  border: 1px solid #3fa74a !important;
}

.chip .MuiChip-clickableColorPrimary {
  color: rgb(17, 19, 19);
}

.black-font button {
  color: black !important;
}

.nav-link {
  min-width: 200;
}

.nav-link::after {
  content: "";
  border-bottom: 2px solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.avtar-grp {
  position: absolute;
  max-width: 250px;
  padding: 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  bottom: -4rem;
  background: white;
  border-radius: 10px;
  left: -6rem;
  box-shadow: 0px 5px 10px #ccc;
}
.avtar-grp p {
  color: #92929d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.avtar-grp h6 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #000;
}
@media screen and (max-width: 560px) {
  .avtar-grp h6 {
    font-size: 11px;
    font-family: "Poppins", sans-serif;
  }
}
@media screen and (max-width: 960px) {
  .avtar-grp {
    height: 160px;
    top: 93%;
    left: 65% !important;
  }
}
@media screen and (max-width: 600px) {
  .avtar-grp {
    height: 125px;
    padding: 20px;
    width: 160px;
    align-items: center;
    justify-content: center;
    grid-gap: 0;
    grid-gap: 0;
    gap: 0;
    top: 93%;
    left: 65% !important;
  }
}
@media screen and (max-width: 560px) {
  .avtar-grp {
    height: 100px;
    padding: 20px;
    width: 145px;
    align-items: center;
    justify-content: center;
    grid-gap: 0;
    grid-gap: 0;
    gap: 0;
    top: 93%;
    left: 65% !important;
  }
}
@media screen and (max-width: 400px) {
  .avtar-grp {
    height: 100px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    grid-gap: 0;
    grid-gap: 0;
    gap: 0;
    top: 93%;
    left: 55% !important;
  }
}

.one {
  position: absolute;
  max-width: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  top: 18rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #ccc;
  padding: 1rem 0.5rem;
  width: 200px;
  align-items: center;
  left: 59%;
}
.one .avatarImgWrapper {
  width: 62px;
  height: 62px;
}
.one p {
  color: #92929d;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.one h6 {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
}
@media screen and (max-width: 768px) {
  .one {
    position: absolute;
    max-width: 170px;
    top: 16rem;
    left: 56%;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 560px) {
  .one {
    position: absolute;
    max-width: 145px;
    top: 14rem;
    left: 56%;
    margin-bottom: 20px;
  }
}

.avatarImg {
  width: 62px;
  height: 62px;
}
.avatarImg .MuiAvatarGroup-root {
  padding: 12px;
}
.avatarImg .MuiAvatarGroup-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.delDisIcon {
  border: 1px solid red;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  width: 32px;
  height: 35px;
}
@media screen and (max-width: 560px) {
  .delDisIcon {
    width: 29px;
    height: 28px;
  }
}

.edit_Disc {
  border: 1px solid #3bad6c !important;
  margin-left: 10px;
  padding: 4px 30px;
  border-radius: 25px;
  font-size: 16px;
  color: #3bad6c;
}
.edit_Disc span {
  color: #3bad6c !important;
  padding: 0px 30px;
  text-transform: none;
  font-weight: 600;
}
@media screen and (max-width: 560px) {
  .edit_Disc {
    border-radius: 5px !important;
    padding: 4px 20px;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
}

.padding {
  padding: 0 5rem;
}
@media screen and (max-width: 960px) {
  .padding {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 560px) {
  .padding {
    padding: 0;
    margin-top: 1.2rem;
    font-family: "Poppins", sans-serif;
  }
}

.padding2 {
  padding: 0 5rem;
  margin-top: 2.5rem;
}
@media screen and (max-width: 960px) {
  .padding2 {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 560px) {
  .padding2 {
    padding: 0;
    margin-top: 1.2rem;
    font-family: "Poppins", sans-serif;
  }
}

.margin1 {
  margin-top: 4.5rem;
}

.courseInfoContainer {
  padding: 30px;
  border: none;
  box-shadow: 0px 5px 10px #ccc;
}
@media screen and (max-width: 960px) {
  .courseInfoContainer {
    padding: 16px 10px;
  }
}
@media screen and (max-width: 560px) {
  .courseInfoContainer {
    padding: 0 20 20px;
    box-shadow: none;
  }
}

.courseDetail {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.learningPadding {
  padding: 0 0 0 3rem;
}
.learningPadding .searchbox {
  padding-left: 1rem;
}
@media screen and (max-width: 1160px) {
  .learningPadding .searchbox {
    margin-left: -60px;
  }
}
@media screen and (max-width: 768px) {
  .learningPadding .searchbox {
    margin-left: 0;
    margin-top: -55px;
    flex-direction: row;
    padding: 0;
  }
}
.learningPadding .searchbox .customTextfield {
  margin-left: 32px;
}
@media screen and (max-width: 768px) {
  .learningPadding .searchbox .customTextfield {
    margin-left: -32px;
  }
}
@media screen and (max-width: 600px) {
  .learningPadding .searchbox .customTextfield {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (max-width: 1160px) {
  .learningPadding {
    padding: 0 0 0 1rem;
  }
}
@media screen and (max-width: 960px) {
  .learningPadding {
    padding: 0 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .learningPadding {
    padding: 0 1.2rem;
  }
}

.listSearchbox {
  display: flex;
  margin: 0 auto;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .listSearchbox {
    padding: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .listSearchbox .customTextfield {
    width: 100%;
  }
}

.left-img {
  width: 450px;
  height: 500px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  .left-img {
    width: 100%;
    height: 400px !important;
  }
}
@media screen and (max-width: 768px) {
  .left-img {
    width: 100%;
    height: 300px !important;
  }
}
@media screen and (max-width: 560px) {
  .left-img {
    width: 100%;
    height: 210px !important;
  }
}

.heading {
  margin: 1.5rem 4.5rem;
  color: black;
  font-family: "AllRoundGothicW01-Medium" !important;
}

.sub-heading {
  margin: 0.5rem 4.7rem;
  color: black;
  font-family: "AllRoundGothicW01-Medium" !important;
}

.course-con {
  margin: 2rem 0rem 2.5rem 5rem !important;
}

.container {
  display: flex !important;
  justify-content: center !important;
}

.replyBox {
  width: 100%;
  height: 240px;
  text-align: right;
  box-sizing: content-box;
}

.saveBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
}

.editDelContainer {
  display: flex;
  justify-content: flex-end;
}
.editDelContainer .replyBtnWrapper {
  display: flex;
  margin-top: 1rem;
  margin-left: 83%;
  cursor: pointer;
}
.editDelContainer .replyBtnWrapper .delIcon {
  border: 1px solid red;
  margin-right: 10px;
  border-radius: 50%;
  padding: 6px;
  width: 32px;
  height: 35px;
}
.editDelContainer .replyBtnWrapper .edit_reply {
  border: 1px solid #3bad6c !important;
  border-radius: 25px !important;
  cursor: pointer;
  padding: 4px 30px;
  font-size: 16px;
  color: #3bad6c;
}
.editDelContainer .replyBtnWrapper .edit_reply span {
  color: #3bad6c !important;
  padding: 0px 30px;
  text-transform: none;
  font-weight: 600;
}

.center-sec {
  margin: 3rem;
  display: flex;
  justify-content: center;
}
.center-sec .sec-1 {
  height: 350px;
  display: flex;
  justify-content: center;
}

.enrolledContainer {
  margin-top: 3rem;
}
@media screen and (max-width: 768px) {
  .enrolledContainer .margin3 {
    padding: 0 20px;
    margin: 24px 0 50px;
  }
}
@media screen and (max-width: 560px) {
  .enrolledContainer .margin3 {
    padding: 0 20px;
    margin-top: 24px 0;
  }
}
@media screen and (max-width: 960px) {
  .enrolledContainer {
    margin-top: 21px;
    flex-direction: column-reverse;
  }
}

.enrolledTabContainer {
  padding: 0px 40px 0;
  width: 100%;
  margin-top: 20px;
}
@media screen and (max-width: 560px) {
  .enrolledTabContainer .MuiBox-root {
    padding: 0;
    margin-top: 6px;
  }
}
@media screen and (max-width: 960px) {
  .enrolledTabContainer {
    padding: 70px 0px;
  }
}
@media screen and (max-width: 768px) {
  .enrolledTabContainer {
    padding: 32px 0px;
  }
}
@media screen and (max-width: 768px) {
  .enrolledTabContainer {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 560px) {
  .enrolledTabContainer {
    padding: 35px 20px;
  }
}

.tabBox {
  padding-left: 40px;
  border-bottom: 4px;
  border-color: divider;
}
@media screen and (max-width: 768px) {
  .tabBox .MuiTab-wrapper {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 560px) {
  .tabBox .MuiTab-wrapper {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif !important;
  }
}
@media screen and (max-width: 768px) {
  .tabBox {
    padding-left: 0;
  }
}

@media screen and (max-width: 480px) {
  .avtar-grp {
    left: 1rem;
  }
  .heading {
    margin: 0.5rem !important;
  }
  .sub-heading {
    margin: 0.5rem !important;
  }
  .course-con {
    margin: 2rem 0rem 0rem 0.75rem !important;
  }
  .center-sec {
    margin: 0rem;
    display: block;
  }
  .center-sec .sec-1 {
    display: block;
  }
  .center-sec .sec-1 img {
    width: 100% !important;
  }
}
.slick-slide {
  width: 100%;
  padding: 20px;
}
@media screen and (max-width: 600px) {
  .slick-slide {
    padding: 10px;
  }
}
.slick-slide .sliderWrapper {
  position: relative;
}
.slick-slide img {
  position: relative;
}

.slick-slide.slick-active.slick-center .popTeachCardContainer {
  height: 420px;
}
.slick-slide.slick-active.slick-center .slider-title {
  font-size: 26px;
}
.slick-slide.slick-active.slick-center .popTeachName {
  font-size: 40px;
}

.swiperWrapper {
  width: 100vw;
}
.swiperWrapper .swiper-slide {
  padding: 20px;
  transition: 0.5s;
}
.swiperWrapper .swiper-slide-active {
  padding: 0;
  border: 5px solid #000;
}

.swiperContainer .swiper-pagination {
  display: none;
  margin-top: 0px;
}
.swiperContainer .swiper-pagination .swiper-pagination-bullet {
  width: 40px;
  height: 7px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 767px) {
  .swiperContainer .swiper-pagination .swiper-pagination-bullet {
    width: 30px;
  }
}
.swiperContainer .swiper-pagination .swiper-slide {
  margin-top: 20px;
  transition: 0.5s;
}
.swiperContainer .swiper-pagination .swiper-slide-active {
  transition: 0.5s;
  margin-top: 0;
  zoom: 120%;
}
.swiperContainer .swiper-pagination .swiperAarrowRow {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: 100px auto 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .swiperContainer .swiper-pagination .swiperAarrowRow {
    max-width: 550px;
  }
}
.swiperContainer .swiper-pagination .swiperAarrowRow .prevNavigation,
.swiperContainer .swiper-pagination .swiperAarrowRow .nextNavigation {
  cursor: pointer;
  z-index: 10;
}
.swiperContainer .swiper-pagination .swiperAarrowRow .prevNavigation .MuiSvgIcon-root,
.swiperContainer .swiper-pagination .swiperAarrowRow .nextNavigation .MuiSvgIcon-root {
  margin-top: -20px;
}

.swiper-slide {
  width: 200px !important;
}

@media screen and (max-width: 960px) {
  .avtar-grp {
    left: 1rem;
  }
}
@media screen and (max-width: 370px) {
  .avtar-grp img {
    height: 3rem !important;
  }
}
@media screen and (max-width: 960px) {
  .page1-img {
    height: 500px;
  }
}
@media screen and (max-width: 430px) {
  .h1 {
    font-size: 49px;
  }
}
@media screen and (max-width: 370px) {
  .h10 {
    font-size: 47px !important;
  }
}
.page1-img {
  margin-top: 2rem;
  width: 100%;
  height: auto;
}

.customAccordian6 .MuiAccordion-root .MuiAccordionSummary-root {
  flex-direction: row !important;
}

.MuiAccordionSummary-content {
  margin-left: 1rem !important;
}

.customAccordian .AccordionSummary .MuiAccordionSummary-expandIcon {
  color: black;
  border: 1px solid;
  background: transparent !important;
}

.customAccordian .AccordionSummary .MuiIconButton-label::before,
.customAccordian .AccordionSummary .MuiIconButton-label::after {
  content: " ";
  position: absolute;
  height: 2px;
  width: 12px;
  background-color: #000 !important;
}

.flex-2 {
  display: flex;
  grid-gap: 3.5rem;
  gap: 3.5rem;
  margin: 0 5rem 1rem 5rem;
  flex-wrap: wrap;
  justify-content: center;
}
.flex-2 h3 {
  color: #20a258;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 34px;
}
.flex-2 h5,
.flex-2 span {
  color: #394053;
  font-weight: bold;
}
.flex-2 h6 {
  color: #92929d;
  font-weight: bold;
  font-size: 15px;
}
.flex-2 .chip-active {
  border: 1px solid #3fa74a !important;
  color: #3fa74a !important;
}
.flex-2 .chip-active .MuiChip-label {
  color: #3fa74a !important;
}
@media screen and (max-width: 960px) {
  .flex-2 {
    justify-content: center;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    margin: 30px 0;
  }
}
@media screen and (max-width: 768px) {
  .flex-2 {
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 30px 0;
  }
}
@media screen and (max-width: 560) {
  .flex-2 {
    justify-content: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    margin: 30px 0;
  }
}

.timeWrapper {
  text-align: center;
  display: flex;
}

.clockImg {
  margin-right: 20px;
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 960px) {
  .clockImg {
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 640px) {
  .clockImg {
    margin-right: 5px;
    width: 26px;
    height: 26px;
  }
}

.lessonLength {
  text-align: center;
  padding-right: 40px;
  border-right: 2px solid #92929d;
}
.lessonLength h5 {
  font-weight: 500px;
  font-size: 28px;
}
@media screen and (max-width: 960px) {
  .lessonLength h5 {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .lessonLength h5 {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .lessonLength h5 {
    font-family: "Poppins", sans-serif;
    width: 80px;
    color: #000;
    font-weight: normal;
    font-size: 12px;
  }
}
@media screen and (max-width: 640px) {
  .lessonLength {
    border-right: none;
    padding-right: 0;
  }
}

.enrollStud {
  text-align: center;
}
.enrollStud h5 {
  font-weight: 500;
  font-size: 28px;
}
@media screen and (max-width: 960px) {
  .enrollStud h5 {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .enrollStud h5 {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .enrollStud h5 {
    font-family: "Poppins", sans-serif;
    color: #000;
    font-weight: normal;
    font-size: 12px;
  }
}

.progressBox {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin: 20px 0 16px;
}
.progressBox .width {
  width: 230px;
}
@media screen and (max-width: 560px) {
  .progressBox .width {
    width: 180px;
  }
}
@media screen and (max-width: 768px) {
  .progressBox {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 560px) {
  .progressBox {
    margin: 11px 0;
  }
}

.priceContainer {
  text-align: center;
}
.priceContainer .coursePrice {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 31px;
  letter-spacing: 1;
  color: #000;
}
@media screen and (max-width: 960px) {
  .priceContainer .coursePrice {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  .priceContainer .coursePrice {
    font-size: 18px;
  }
}
@media screen and (max-width: 560px) {
  .priceContainer .coursePrice {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 12px;
  }
}
.priceContainer .coursePrice span {
  font-size: 12px;
}
@media screen and (max-width: 640px) {
  .priceContainer .coursePrice span {
    font-family: "Poppins", sans-serif;
    font-size: 9px;
  }
}
@media screen and (max-width: 640px) {
  .priceContainer {
    text-align: center;
  }
}

.enrollBtnWrapper {
  display: flex;
}
@media screen and (max-width: 768px) {
  .enrollBtnWrapper {
    display: none;
  }
}

.flex-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.flex-1 h3 {
  color: #20a258;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 34px;
}
.flex-1 .chip-active {
  border: 1px solid #3fa74a !important;
  color: #3fa74a !important;
}
.flex-1 .chip-active .MuiChip-label {
  color: #3fa74a !important;
}

.overlay2 {
  width: 100%;
  border-radius: 37px;
  align-items: center;
}

.btn-1 .MuiButton-label span {
  color: #20a258 !important;
}

.AccordionDetails {
  flex-direction: column;
  margin-left: 182px;
}
.AccordionDetails .para {
  margin-top: -10px;
  font-size: 21px;
  word-spacing: 4;
  color: #92929d;
}

.MuiAccordionDetails-root {
  margin-left: 1rem;
}

.paper {
  margin: 1rem 2rem !important;
}

.MuiGrid-spacing-xs-1 {
  width: calc(100% - 168px) !important;
  margin-left: 94px !important;
  margin-right: 94px !important;
}

.outletType .MuiGrid-spacing-xs-1,
.openDaysCheckBox .MuiGrid-spacing-xs-1 {
  margin-left: 0 !important;
}

.MuiGrid-spacing-xs-10 {
  margin: 0 !important;
}

.MuiFormControl-root {
  width: 100%;
}
.MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.textFieldMultiline .MuiInputBase-input::-moz-placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #abafb3;
  font-weight: normal;
}

.textFieldMultiline .MuiInputBase-input:-ms-input-placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #abafb3;
  font-weight: normal;
}

.textFieldMultiline .MuiInputBase-input::placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #abafb3;
  font-weight: normal;
}
.textFieldMultiline .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(221, 208, 208, 0.368627451) !important;
  border-radius: 8px !important;
}

.bottom-line {
  border-bottom: 2px solid #3fa74a;
}

@media screen and (max-width: 1260px) {
  .image-1 img {
    width: 1100px !important;
    height: 420px;
  }
}
@media screen and (max-width: 1160px) {
  .image-1 img {
    width: 1000px !important;
    height: 400px;
  }
}
@media screen and (max-width: 1060px) {
  .image-1 img {
    width: 900px !important;
    height: 380px;
  }
}
@media screen and (max-width: 960px) {
  .image-1 img {
    width: 800px !important;
    height: 360;
  }
}
@media screen and (max-width: 860px) {
  .image-1 img {
    width: 700px !important;
    height: 340px;
  }
}
@media screen and (max-width: 760px) {
  .image-1 img {
    width: 600px !important;
    height: 330px;
  }
}
@media screen and (max-width: 660px) {
  .image-1 img {
    width: 450px !important;
    height: 320px;
  }
}
@media screen and (max-width: 560px) {
  .image-1 img {
    width: 350px !important;
    height: 300px;
  }
}
@media screen and (max-width: 460px) {
  .image-1 img {
    width: 350px !important;
    height: 280px !important;
  }
}
@media screen and (max-width: 400px) {
  .image-1 img {
    width: 290px !important;
    height: 260px !important;
  }
}
@media screen and (max-width: 328px) {
  .image-1 img {
    width: 265px !important;
    height: 260px !important;
  }
}
.regular-checkbox {
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.circleBtn-1 .customButton .MuiButton-root:hover .MuiButton-label span {
  color: white !important;
}

.regular-checkbox:active,
.regular-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.regular-checkbox:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.regular-checkbox:checked:after {
  content: "✔";
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #99a1a7;
}

.btn-2 {
  min-width: 16.5rem !important;
}

.btn-start {
  width: 355px;
}
@media screen and (max-width: 560px) {
  .btn-start {
    width: 100%;
  }
}

.contentBlock .search {
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: rgba(193, 193, 193, 0.368627451);
}
@media screen and (min-width: 1160px) {
  .contentBlock {
    padding-left: 0 !important;
  }
}
@media screen and (min-width: 600px) {
  .contentBlock {
    padding-left: 32px !important;
  }
}

.paginationContainer .MuiPagination-ul li .Mui-selected {
  background-color: #169d4e;
  color: #fff;
  border-color: #169d4e;
}

.course-overview .h3 {
  font-family: "AllRoundGothicW01-Book";
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  color: #333;
  margin-top: 10px;
  text-transform: capitalize;
}
@media screen and (max-width: 960px) {
  .course-overview .h3 {
    font-size: 36px;
  }
}
@media screen and (max-width: 768px) {
  .course-overview .h3 {
    font-size: 32px;
  }
}
@media screen and (max-width: 560px) {
  .course-overview .h3 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
}
.course-overview h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  color: #333;
  font-weight: 600;
  margin-top: 3px;
  text-transform: capitalize;
}
@media screen and (max-width: 960px) {
  .course-overview h5 {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  .course-overview h5 {
    font-size: 18px;
  }
}
@media screen and (max-width: 560px) {
  .course-overview h5 {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #000;
    font-weight: normal !important;
  }
}
.course-overview .para {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #333;
  margin-top: 18px;
  word-spacing: 4px;
  text-align: justify;
  word-break: break-word;
}
@media screen and (max-width: 960px) {
  .course-overview .para {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  .course-overview .para {
    font-size: 18px;
  }
}
@media screen and (max-width: 560px) {
  .course-overview .para {
    font-family: "Poppins", sans-serif;
    color: #919191;
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .course-overview {
    padding: 32px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 560px) {
  .course-overview {
    padding: 20px;
    margin-top: 20px;
  }
}

.timeCal {
  font-family: "Montserrat", sans-serif;
}
.timeCal h3 {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.91px;
  color: #20a258;
}
@media screen and (max-width: 960px) {
  .timeCal h3 {
    font-size: 22px;
  }
}
@media screen and (max-width: 768px) {
  .timeCal h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 560px) {
  .timeCal h3 {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }
}
.timeCal h6 {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.41px;
  text-align: justify;
  color: #333;
}
@media screen and (max-width: 960px) {
  .timeCal h6 {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .timeCal h6 {
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .timeCal h6 {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
  }
}
@media screen and (max-width: 560px) {
  .timeCal {
    width: 80px;
    text-align: left;
  }
}

.courseFlexContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .courseFlexContainer {
    justify-content: space-evenly;
  }
}

.enrollBtnContainer {
  display: none;
}
.enrollBtnContainer .customButton {
  display: none;
}
@media screen and (max-width: 768px) {
  .enrollBtnContainer .customButton {
    display: block;
    padding: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 560px) {
  .enrollBtnContainer .customButton {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .enrollBtnContainer .MuiGrid-grid-xs-12 {
    display: block;
  }
}
@media screen and (max-width: 560px) {
  .enrollBtnContainer .circleBtn .MuiButton-root {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .enrollBtnContainer {
    padding: 0 20px;
  }
}

.enrolled-tabs span.MuiTab-wrapper {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
}

.reply-wrapper {
  font-family: "Montserrat", sans-serif;
  margin: 2rem 0 0 5rem;
  background-color: #f0f0f0;
  padding: 36px 39.8px 22px 38px;
  border-radius: 26px;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  margin-bottom: 0px;
}

.reply_btn {
  background-color: #20a258 !important;
  padding: 10px 20px;
  border-radius: 24px;
  border: none;
  color: #ffffff;
  display: flex;
}
.reply_btn span {
  color: #ffffff;
  text-transform: capitalize;
}
.reply_btn p {
  display: flex;
  color: #ffffff;
  padding-left: 10px;
}

.description {
  margin-top: 1rem;
  color: #92929d;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.7rem;
}

.knowlink {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
  color: #3bae6d;
  font-weight: bold;
  cursor: pointer;
}

.knowlinkNew {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
  color: #3bae6d;
  font-weight: bold;
}

.send-btn {
  border-radius: 34px !important;
  margin: 0.5rem;
  text-transform: capitalize !important;
  background-color: #ffc107 !important;
}
@media screen and (max-width: 560px) {
  .send-btn {
    padding: 6px !important;
    font-size: 12px;
  }
}

.replyContainer {
  margin-right: 20px;
}

.replyDesc {
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  font-size: 16px;
  text-align: justify;
  color: #5a5a5a;
  font-weight: 500;
}

.replyText {
  margin-left: 80px;
  margin-top: 27px;
  width: 100%;
  text-align: right;
  box-sizing: content-box;
}

.editText {
  margin-top: 1rem;
  font-size: 16px;
  text-align: justify;
}

.saveButton {
  border-radius: 34px;
  margin: 0.5rem;
}

.textContainer {
  margin: 1.5rem 0 4rem auto;
  display: flex;
}
@media screen and (max-width: 560px) {
  .textContainer {
    position: relative;
    font-size: 12px !important;
  }
}

.postBtn {
  padding: 10px 30px;
  display: flex;
  margin-left: 2rem;
}
@media screen and (max-width: 560px) {
  .postBtn {
    position: absolute;
    font-size: 12px;
    top: 13%;
    left: 75%;
    padding: 4px 12px;
    border-radius: 3px !important;
  }
}

.textBox {
  border: 1px solid lightgray;
  border-radius: 15px;
  width: 82%;
  margin: 0;
}
@media screen and (max-width: 560px) {
  .textBox .MuiInputBase-input {
    font-size: 12px;
    font-family: "Poppins", sans-serif !important;
  }
}
@media screen and (max-width: 560px) {
  .textBox {
    border-radius: 5px;
    width: 100%;
  }
}

.author {
  color: #92929d;
  font-size: 26px;
  font-weight: 500;
  margin-top: 1.5rem;
}
@media screen and (max-width: 768px) {
  .author {
    font-size: 20px;
  }
}
@media screen and (max-width: 560px) {
  .author {
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 600;
    margin-top: 11px;
  }
}

.status-num {
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #92929d;
}
@media screen and (max-width: 768px) {
  .status-num {
    margin-left: 20px;
  }
}
@media screen and (max-width: 560px) {
  .status-num {
    margin-right: 85px;
    font-size: 12px;
    font-family: "Poppins", sans-serif !important;
  }
}

.day-status {
  font-size: 20px;
  font-weight: 500;
  color: #92929d;
  margin-bottom: 8px;
}

.enrolledLessonHeading {
  font-size: 20px;
  font-weight: 600;
}

.circleBtn .customButton {
  width: 100%;
}
.circleBtn .customButton .comm-btn {
  color: black !important;
}

.MuiTab-wrapper {
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  font-size: 22px;
}

.imageHeaderContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 560px) {
  .imageHeaderContainer {
    margin-top: 20px;
  }
}
.imageHeaderContainer .image-1 h1 {
  font-size: 38px;
  text-align: center;
  margin-bottom: 1.5rem;
  color: black;
  font-weight: 500;
}
@media screen and (max-width: 1160px) {
  .imageHeaderContainer .image-1 h1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 960px) {
  .imageHeaderContainer .image-1 h1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .imageHeaderContainer .image-1 h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 640px) {
  .imageHeaderContainer .image-1 h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 560px) {
  .imageHeaderContainer .image-1 h1 {
    font-size: 18px;
    font-family: "Poppins", sans-serif !important;
  }
}

.section-title {
  margin-top: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  text-align: center;
  color: black;
  font-size: 40px;
}
@media screen and (max-width: 1160px) {
  .section-title {
    font-size: 32px;
  }
}
@media screen and (max-width: 960px) {
  .section-title {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .section-title {
    font-size: 24px;
  }
}
@media screen and (max-width: 640px) {
  .section-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 560px) {
  .section-title {
    font-size: 18px;
    margin: 0 0 32px;
  }
}

.MuiAccordionDetails-root.AccordionSection {
  display: flex;
  padding: 0px;
  margin-left: 0;
  border-bottom: none;
}

.MuiPaper-elevation1 {
  box-shadow: 0 2px 6px -1px rgba(20, 118, 32, 0.11);
}

.video-player {
  position: relative;
  width: 750px;
  height: 500px;
}
.video-player video {
  width: 750px;
  height: 500px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.video-player .player-controls {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 750px;
  height: 80px;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
}
.video-player .player-controls img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  box-shadow: rgba(0, 24, 52, 0.08) 0px 24px 48px 0px;
}

.business_banner {
  background: url(/static/media/business-teaching-rhpe-8-jc.9a45891e.png) no-repeat;
  background-size: cover;
  padding: 60px;
  display: flex;
  width: 100%;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .business_banner {
    padding: 60px 30px;
  }
}

@media screen and (max-width: 960px) {
  .businessTeachContainer {
    padding: 0 !important;
  }
}

.banner_text {
  color: #ffffff;
  font-weight: 600;
  align-items: center !important;
}

.banner_btn {
  border: 2px solid #ffffff !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  padding: 3px 16px !important;
  font-size: 20px !important;
  align-items: center !important;
}

.btn-label {
  color: #ffffff !important;
  align-items: center !important;
}

.video-player1 {
  margin-left: 20px;
  height: 560px;
  max-width: 1291px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.video-player1 .video {
  width: 100%;
  border-radius: 20px;
}
.video-player1 .player-controls {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media screen and (max-width: 560px) {
  .video-player1 .player-controls {
    height: 50px;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
.video-player1 .player-controls img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  box-shadow: rgba(0, 24, 52, 0.08) 0px 24px 48px 0px;
}
@media screen and (max-width: 560px) {
  .video-player1 .player-controls img {
    width: 30px !important;
    height: 30px !important;
  }
}
@media screen and (max-width: 560px) {
  .video-player1 {
    width: 100%;
    height: 238px;
    border-radius: 5px !important;
  }
}

video {
  border-radius: 20px;
}
@media screen and (max-width: 560px) {
  video {
    border-radius: 5px !important;
  }
}

.lessonInfoContainer {
  margin-top: 4rem;
  box-shadow: 0 0 10px #ccc;
}
.lessonInfoContainer .marginTwo {
  margin: 2rem 2rem;
}
@media screen and (max-width: 560px) {
  .lessonInfoContainer .marginTwo {
    margin: 2rem 0 !important;
  }
}
@media screen and (max-width: 560px) {
  .lessonInfoContainer {
    box-shadow: none;
    margin-top: 35px;
  }
}

.course-intro {
  font-family: "Montserrat", sans-serif;
}

.MuiTypography-root.course-name.MuiTypography-h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin-top: 0.5rem;
  padding-left: 20px;
  text-transform: capitalize;
}
@media screen and (max-width: 600px) {
  .MuiTypography-root.course-name.MuiTypography-h5 {
    margin: 0;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
  }
}

.MuiTypography-root.author-name.MuiTypography-h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #746f6f;
  margin-bottom: 2.5rem;
  padding-left: 20px;
  font-size: 17px;
  text-transform: capitalize;
}
@media screen and (max-width: 600px) {
  .MuiTypography-root.author-name.MuiTypography-h6 {
    margin: 5px 0 20px !important;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
}

p.MuiTypography-root.overview-box.MuiTypography-body1 {
  font-size: 42px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 960px) {
  p.MuiTypography-root.overview-box.MuiTypography-body1 {
    font-size: 32px;
  }
}
@media screen and (max-width: 768px) {
  p.MuiTypography-root.overview-box.MuiTypography-body1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 640px) {
  p.MuiTypography-root.overview-box.MuiTypography-body1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 560px) {
  p.MuiTypography-root.overview-box.MuiTypography-body1 {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
  }
}

.overviewBoxWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 100px;
}
@media screen and (max-width: 768px) {
  .overviewBoxWrapper {
    margin-top: 12px;
    padding: 20px;
  }
}

.customAccordian .AccordionDetails .MuiTypography-h5 {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
}
.customAccordian .AccordionDetails.overviewDetails {
  font-family: "Poppins", sans-serif;
  margin-top: -108px;
}
@media screen and (max-width: 560px) {
  .customAccordian .AccordionDetails.overviewDetails {
    margin-top: -70px;
  }
}
.customAccordian .AccordionDetails .paragraph {
  text-align: justify;
  margin-right: 35px;
}
@media screen and (max-width: 560px) {
  .customAccordian .AccordionDetails .paragraph {
    margin-right: 0;
  }
}
.customAccordian .AccordionDetails .paragraph .para {
  font-family: "Poppins", sans-serif;
  color: #9098b1;
  margin: 0 0 20px 150px;
  font-size: 21px;
  font-weight: normal;
}
@media screen and (max-width: 560px) {
  .customAccordian .AccordionDetails .paragraph .para {
    margin: 0 0 20px 70px;
    font-size: 12px;
  }
}

.customAccordian6 .AccordionSummary .MuiTypography-body1.resource-title {
  font-family: "Poppins", sans-serif;
}
.customAccordian6 .AccordionSummary .lesson {
  font-weight: bold;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .customAccordian6 .AccordionSummary .lesson {
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  .customAccordian6 .AccordionSummary .lesson {
    font-size: 12px;
  }
}

.customAccordian6 .MuiPaper-elevation1 {
  box-shadow: none;
}

.leftDiscussion {
  width: 85%;
  margin-left: 3.7rem;
}
.leftDiscussion .reply {
  background-color: #f0f0f0;
}
.leftDiscussion .reply h5 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 560px) {
  .leftDiscussion .reply h5 {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif;
  }
}
.leftDiscussion .reply p {
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 560px) {
  .leftDiscussion .reply p {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif;
  }
}
.leftDiscussion .reply .MuiInputBase-input {
  margin-top: 1rem;
  font-size: 16px;
  text-align: justify;
}
@media screen and (max-width: 560px) {
  .leftDiscussion .reply .MuiInputBase-input {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 560px) {
  .leftDiscussion .reply {
    padding: 16px;
  }
}
@media screen and (max-width: 560px) {
  .leftDiscussion {
    margin-left: 0 !important;
  }
}

input[type=checkbox] {
  display: none;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #9098b1;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  height: 25px;
  width: 25px;
  border-radius: 15px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #3fa74a;
  margin-left: -50px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.circleBtn:hover {
  color: #3fa74a;
}

.circleBtn.searchButton {
  margin-left: -4.4rem;
  margin-top: 1.45rem;
}
@media screen and (max-width: 600px) {
  .circleBtn.searchButton {
    margin-left: -5.4rem;
  }
}
@media screen and (max-width: 560px) {
  .circleBtn.searchButton {
    margin-left: -4.4rem;
  }
}
.circleBtn.searchButton .courseSearch {
  margin-top: 0;
  background-color: #ffc107;
  height: 47px;
  width: 125px;
  border: none;
}
@media screen and (max-width: 600px) {
  .circleBtn.searchButton .courseSearch {
    border-radius: 0;
    height: 32px;
    width: 0;
  }
}

.complete-btn {
  font-size: 20px;
  padding: 12px 24px;
  background-color: rgb(42, 172, 79);
  color: white;
  border-radius: 50px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.complete-btn:hover {
  background-color: white;
  color: #3fa74a;
  border: 1px solid #3fa74a;
}

.react-pdf__Page {
  text-align: center;
}

.react-pdf__Page__canvas {
  width: 270px !important;
  height: 320px !important;
  overflow: auto;
}

.react-pdf__Page__textContent {
  display: none;
}

span.MuiTab-wrapper {
  color: #000;
  font-size: 22px;
}

.comment_wrapper {
  margin: 0;
  padding: 20px;
  border-radius: 26px;
  display: flex;
  justify-content: flex-start;
  background-color: #f0f0f0;
}

.MuiBox-root.MuiBox-root-16.discussion {
  margin: 0;
}

.search-discussion {
  position: absolute;
  top: 27%;
  right: 10%;
  border-radius: 20px;
  height: 38px;
  width: 350px;
}

.task-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 837px;
  height: 530px;
  padding: 20px;
  background-color: white;
  border-radius: 27px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .task-popup {
    height: 90% !important;
    width: 85%;
    padding: 10px 5px;
  }
}
@media screen and (max-width: 560px) {
  .task-popup {
    height: 90% !important;
    width: 85%;
    padding: 0;
  }
}
.task-popup .shortAns {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  height: 122px;
}

@media screen and (max-width: 560px) {
  .boxAns p {
    font-size: 12px;
  }
}

.boxAns::-webkit-scrollbar {
  display: none;
}

p.MuiTypography-root.answeredText.MuiTypography-body1 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  height: 122px;
}

p.MuiTypography-root.select-text.MuiTypography-body1 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin: 0 0 20px;
}
@media screen and (max-width: 1160px) {
  p.MuiTypography-root.select-text.MuiTypography-body1 {
    font-size: 22px;
  }
}
@media screen and (max-width: 960px) {
  p.MuiTypography-root.select-text.MuiTypography-body1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 768px) {
  p.MuiTypography-root.select-text.MuiTypography-body1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 560px) {
  p.MuiTypography-root.select-text.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    margin: 0 5px 5px;
  }
}

.horizontal-line {
  margin: 0 -20px;
  border-bottom: 2px solid #ccc;
}

p.MuiTypography-root.task-title.MuiTypography-body1 {
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin-top: 15px;
}
@media screen and (max-width: 1160px) {
  p.MuiTypography-root.task-title.MuiTypography-body1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 560px) {
  p.MuiTypography-root.task-title.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
}

.task-question {
  margin-top: 12px;
  width: 768px;
  background-color: #f5f5f5;
}
@media screen and (max-width: 560px) {
  .task-question {
    width: 100%;
  }
}

.question {
  font-family: "Montserrat", sans-serif;
  padding: 20px 20px 20px 17px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #000;
}
@media screen and (max-width: 1160px) {
  .question {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .question {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #4c4b4b;
  }
}

p.MuiTypography-root.task-option.MuiTypography-body1 {
  font-family: "Montserrat", sans-serif;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin: 15px 0px;
}
@media screen and (max-width: 1160px) {
  p.MuiTypography-root.task-option.MuiTypography-body1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  p.MuiTypography-root.task-option.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }
}

.ansWrapper {
  margin-top: 24px;
  width: 768px;
}
@media screen and (max-width: 560px) {
  .ansWrapper {
    width: 0;
  }
}

.option-A {
  width: 56px;
  height: 56px;
  font-family: "Open Sans", sans-serif;
  font-size: 27px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin: 0 6px 0 0;
  padding: 8px 15.8px 8px 16px;
  border-radius: 8px;
  background-color: #8db1f6;
  border: none;
}
@media screen and (max-width: 1160px) {
  .option-A {
    width: 40px;
    height: 40px;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    padding: 0;
  }
}

.option-container {
  width: 373px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  background-color: #fff;
  border: solid 1px #ccc;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .option-container {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 400px) {
  .option-container {
    font-family: "Poppins", sans-serif;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.options-popup {
  width: 300px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  background-color: #fff;
  border: solid 1px #ccc;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .options-popup {
    width: 280px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 960px) {
  .options-popup {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 400px) {
  .options-popup {
    font-family: "Poppins", sans-serif;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.select-popup {
  width: 300px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  cursor: pointer;
  text-align: left;
  background-color: #147620;
  color: #fff;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .select-popup {
    width: 280px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 960px) {
  .select-popup {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 400px) {
  .select-popup {
    font-family: "Poppins", sans-serif;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.actOptions {
  width: 280px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  background-color: #fff;
  border: solid 1px #ccc;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .actOptions {
    font-size: 16px !important;
    padding: 10px 12px;
    margin: 12px 15px 12px 0;
  }
}
@media screen and (max-width: 560px) {
  .actOptions {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif;
    padding: 10px 20px;
    margin: 5px 0;
    width: 150px;
  }
}

.actOptions-popup {
  width: 240px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  background-color: #fff;
  border: solid 1px #ccc;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .actOptions-popup {
    font-size: 14px;
    padding: 10px 20px;
    margin: 10px 10px 10px 0;
  }
}
@media screen and (max-width: 560px) {
  .actOptions-popup {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    padding: 10px 20px;
    margin: 5px 0;
    width: 150px;
  }
}

.selectAction {
  width: 280px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #ccc;
  cursor: pointer;
  text-align: center;
  background-color: #147620;
  color: #fff;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .selectAction {
    font-size: 16px !important;
    padding: 10px 12px;
    margin: 12px 15px 12px 0;
  }
}
@media screen and (max-width: 560px) {
  .selectAction {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif;
    padding: 10px 20px;
    margin: 5px 0;
    width: 150px;
  }
}

.actionResult {
  display: flex;
  margin-left: 30px;
}
@media screen and (max-width: 1160px) {
  .actionResult {
    margin-left: 0 !important;
  }
}

.actionResultPopup {
  display: flex;
  margin-right: 20px;
}

.selectAction-popup {
  width: 240px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #ccc;
  cursor: pointer;
  text-align: center;
  background-color: #147620;
  color: #fff;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .selectAction-popup {
    font-size: 14px;
    padding: 10px 20px;
    margin: 10px 10px 10px 0;
  }
}
@media screen and (max-width: 560px) {
  .selectAction-popup {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    padding: 10px 20px;
    margin: 5px 0;
    width: 150px;
  }
}

.wrongAct {
  background-color: #d16565;
  width: 280px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #ccc;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
}

.fullBtnContainer {
  margin: 0 25px 0 0;
  padding: 16px 56px 16.2px;
  border-radius: 37px;
  border: solid 1px #147620;
  background-color: #fff;
  color: "#000";
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  align-items: flex-end;
}
@media screen and (max-width: 1160px) {
  .fullBtnContainer {
    padding: 14px 20px !important;
  }
}
@media screen and (max-width: 960px) {
  .fullBtnContainer {
    padding: 12px 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .fullBtnContainer {
    padding: 10px 8px !important;
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .fullBtnContainer {
    padding: 6px 3px !important;
    border-radius: 5px !important;
    font-size: 12px;
  }
}

.fullScreenIcon {
  width: 19px;
  height: 19px;
  margin-right: 20px;
}
@media screen and (max-width: 960px) {
  .fullScreenIcon {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }
}
@media screen and (max-width: 768px) {
  .fullScreenIcon {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 560px) {
  .fullScreenIcon {
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }
}

.ansContainer {
  border: 1px solid #979797;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
}
@media screen and (max-width: 560px) {
  .ansContainer p {
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .ansContainer {
    width: 285px;
  }
}

.evenOption {
  display: flex;
  margin-left: 30px;
}
@media screen and (max-width: 1160px) {
  .evenOption {
    margin-left: 20px;
    align-items: center;
  }
}
@media screen and (max-width: 710px) {
  .evenOption {
    margin-left: 0;
    align-items: center;
  }
}

.textImage {
  margin-top: 20px;
}
@media screen and (max-width: 1160px) {
  .textImage {
    margin-top: 0px !important;
  }
}

p.MuiTypography-root.Options.MuiTypography-body1 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}

.cancelBtn {
  margin: 0 25px 0 0;
  padding: 16px 56px 16.2px;
  border-radius: 37px;
  border: solid 1px #147620;
  background-color: #fff;
  color: "#000";
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .cancelBtn {
    font-size: 14px;
    padding: 10px 28px !important;
  }
}
@media screen and (max-width: 768px) {
  .cancelBtn {
    font-size: 14px;
    padding: 8px 20px !important;
  }
}
@media screen and (max-width: 560px) {
  .cancelBtn {
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 10px !important;
    margin: 0 10px 0 0;
  }
}

.submitBtn {
  padding: 16px 56px 16.2px;
  border-radius: 37px;
  background-image: linear-gradient(106deg, #2dae43 -27%, #147620 151%);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
}
@media screen and (max-width: 960px) {
  .submitBtn {
    font-size: 14px;
    padding: 10px 28px !important;
  }
}
@media screen and (max-width: 768px) {
  .submitBtn {
    font-size: 14px;
    padding: 10px 20px !important;
  }
}
@media screen and (max-width: 560px) {
  .submitBtn {
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 10px !important;
    margin: 0 10px 0 0;
  }
}

.taskBtnContainer {
  display: flex;
  margin: 45px 0 20px;
  justify-content: space-between;
}
@media screen and (max-width: 960px) {
  .taskBtnContainer {
    margin: 0 !important;
  }
}

.imageOpt-Container {
  display: flex;
  margin-left: 80px;
}
@media screen and (max-width: 1160px) {
  .imageOpt-Container {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .imageOpt-Container {
    margin-left: 0 !important;
    margin-top: 5px !important;
  }
}

.textOptContainer {
  display: flex;
}
@media screen and (max-width: 560px) {
  .textOptContainer {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 560px) {
  .optionPopup {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .optionPopup {
    margin-left: 0 !important;
  }
}

.nextLine {
  margin-top: 20px;
}
@media screen and (max-width: 1160px) {
  .nextLine {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 560px) {
  .nextLine {
    margin-top: 0px !important;
  }
}

.nextLineSingle {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
@media screen and (max-width: 1160px) {
  .nextLineSingle {
    margin-top: 10px !important;
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 724px) {
  .nextLineSingle {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-left: 0px !important;
  }
}

.twoOptions {
  margin-top: 20px;
}
@media screen and (max-width: 1160px) {
  .twoOptions {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 560px) {
  .twoOptions {
    margin-top: 5px !important;
  }
}

.select {
  width: 373px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  cursor: pointer;
  text-align: left;
  background-color: #147620;
  color: #fff;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .select {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 400px) {
  .select {
    font-family: "Poppins", sans-serif;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.selectImage {
  width: 240px;
  height: 160px;
  background-color: #147620;
  cursor: pointer;
  padding: 10px;
  border: solid 1px #abafb3;
  border-radius: 7px;
  margin-left: 14px;
}
@media screen and (max-width: 1160px) {
  .selectImage {
    width: 220px;
  }
}
@media screen and (max-width: 400px) {
  .selectImage {
    width: 160px;
  }
}

.optionImage {
  width: 240px;
  height: 160px;
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
  border: solid 1px #abafb3;
  border-radius: 7px;
  margin-left: 14px;
}
@media screen and (max-width: 1160px) {
  .optionImage {
    width: 220px;
  }
}
@media screen and (max-width: 400px) {
  .optionImage {
    width: 160px;
  }
}

.wrongImage {
  width: 240px;
  height: 160px;
  background-color: #d16565;
  cursor: pointer;
  padding: 10px;
  border: solid 1px #abafb3;
  border-radius: 7px;
  margin-left: 14px;
}
@media screen and (max-width: 1160px) {
  .wrongImage {
    width: 220px !important;
  }
}
@media screen and (max-width: 400px) {
  .wrongImage {
    width: 160px !important;
  }
}

.imageSize {
  width: 100%;
  height: 100%;
}

.wrong {
  background-color: #d16565;
  width: 373px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .wrong {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 400px) {
  .wrong {
    font-family: "Poppins", sans-serif;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.wrong-popup {
  background-color: #d16565;
  width: 300px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #abafb3;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 1160px) {
  .wrong-popup {
    width: 280px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 960px) {
  .wrong-popup {
    width: 220px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 400px) {
  .wrong-popup {
    font-family: "Poppins", sans-serif;
    width: 160px;
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
  }
}

.wrongAct-popup {
  background-color: #d16565;
  width: 240px;
  margin: 0 0 0 6px;
  padding: 15px 61px 16px 37px;
  border-radius: 7px;
  border: solid 1px #ccc;
  cursor: pointer;
  text-align: left;
  color: #fff;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
}

.optionColor {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.selectOptImage {
  width: 145px;
  border: 2px solid #ccc;
  padding: 20px 0px;
  margin-left: 10px;
  background-color: #147620;
}

.wrongOptImage {
  width: 145px;
  border: 2px solid #ccc;
  padding: 20px 0px;
  margin-left: 10px;
  background-color: #d16565;
}

.popTeacher {
  text-align: center;
  padding: 1rem;
  font-family: "AllRoundGothicW01-Book";
  font-size: 38px;
  font-weight: 400;
  color: #333;
}
@media screen and (max-width: 600px) {
  .popTeacher {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
  }
}

.MuiTypography-root.popCourse.MuiTypography-h2 {
  color: #333;
  font-family: "AllRoundGothicW01-Book";
  font-size: 56px;
  font-weight: 400;
}
@media screen and (max-width: 600px) {
  .MuiTypography-root.popCourse.MuiTypography-h2 {
    font-size: 20px;
    text-align: left;
    font-family: "Poppins", sans-serif;
  }
}

.MuiTypography-root.popText.MuiTypography-h5 {
  margin: 1rem 0;
  color: rgba(51, 51, 51, 0.5);
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 960px) {
  .MuiTypography-root.popText.MuiTypography-h5 {
    margin: 1rem 1rem 1rem 0;
  }
}
@media screen and (max-width: 560px) {
  .MuiTypography-root.popText.MuiTypography-h5 {
    font-size: 12px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    margin: 1rem 0;
  }
}

p.MuiTypography-root.taskPoints.MuiTypography-body1 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding: 16px 25px 16.2px;
  border: 1px solid #000;
  border-radius: 37px;
  margin: 0;
}
@media screen and (max-width: 1160px) {
  p.MuiTypography-root.taskPoints.MuiTypography-body1 {
    padding: 14px 20px !important;
  }
}
@media screen and (max-width: 960px) {
  p.MuiTypography-root.taskPoints.MuiTypography-body1 {
    padding: 12px 15px !important;
  }
}
@media screen and (max-width: 768px) {
  p.MuiTypography-root.taskPoints.MuiTypography-body1 {
    padding: 10px 8px !important;
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  p.MuiTypography-root.taskPoints.MuiTypography-body1 {
    padding: 6px 3px !important;
    border-radius: 5px !important;
    font-size: 12px;
  }
}

.pointContainer {
  display: flex;
  align-items: center;
  margin: 30px 0 20px;
}

.paraText {
  margin-top: 1rem;
  font-size: 16px;
  margin-left: -50px;
  font-family: "Helvetica", sans-serif;
  color: #000;
  width: 485px;
  text-align: justify;
  letter-spacing: 0.23px;
}
@media screen and (max-width: 1160px) {
  .paraText {
    margin-left: 0px;
  }
}
@media screen and (max-width: 960px) {
  .paraText {
    margin-left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .paraText {
    margin-left: -30px;
    font-size: 14px;
    width: 180px;
  }
}
@media screen and (max-width: 560px) {
  .paraText {
    font-size: 12px;
    width: 145px;
    font-family: "Poppins", sans-serif;
    color: #5a5959;
  }
}

.slide {
  position: relative;
  width: 600px;
  text-align: center;
}
.slide .slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  align-items: center;
}
.slide .slider-overlay .slider-title {
  color: #fff;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 600;
}
.slide .slider-overlay .slider-desc {
  color: #fff;
  margin-bottom: 30px;
  font-size: 18px;
}

.slide img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.slick-center {
  transform: scale(1.08);
  z-index: 1;
}

p.MuiTypography-root.select-option.MuiTypography-body1 {
  color: #9098b1;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
@media screen and (max-width: 1160px) {
  p.MuiTypography-root.select-option.MuiTypography-body1 {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 724px) {
  p.MuiTypography-root.select-option.MuiTypography-body1 {
    font-size: 14px !important;
    margin-left: 0px;
  }
}
@media screen and (max-width: 560px) {
  p.MuiTypography-root.select-option.MuiTypography-body1 {
    font-size: 12px !important;
    margin-left: 0px;
  }
}

h4.MuiTypography-root.taskCount.MuiTypography-h4 {
  font-family: "Poppins", sans-serif;
  color: #000;
  font-weight: 500;
}
@media screen and (max-width: 1160px) {
  h4.MuiTypography-root.taskCount.MuiTypography-h4 {
    font-size: 28px;
  }
}
@media screen and (max-width: 960px) {
  h4.MuiTypography-root.taskCount.MuiTypography-h4 {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  h4.MuiTypography-root.taskCount.MuiTypography-h4 {
    font-size: 20px;
  }
}
@media screen and (max-width: 640px) {
  h4.MuiTypography-root.taskCount.MuiTypography-h4 {
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  h4.MuiTypography-root.taskCount.MuiTypography-h4 {
    font-size: 12px;
    font-weight: 400;
  }
}

textarea.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline.MuiInput-inputMultiline {
  font-family: "Poppins", sans-serif;
}

.taskTitle p {
  font-family: "Poppins", sans-serif !important;
  font-size: 25px;
  font-weight: 500;
  color: #9098b1 !important;
}
@media screen and (max-width: 960px) {
  .taskTitle p {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .taskTitle p {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 560px) {
  .taskTitle p {
    font-size: 12px !important;
  }
}

.expandedTitle p {
  font-family: "Poppins", sans-serif !important;
  font-size: 25px;
  font-weight: 500;
  color: #000 !important;
}
@media screen and (max-width: 960px) {
  .expandedTitle p {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .expandedTitle p {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 560px) {
  .expandedTitle p {
    font-size: 12px !important;
  }
}

.taskAccordion .MuiAccordionSummary-content.Mui-expanded {
  margin: 8px;
}

.react-player {
  width: 1170px;
  height: 550px;
}

.player-controls {
  position: absolute;
  left: 48px;
  bottom: -235px;
  width: 1168px;
  height: 70px;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.player-controls img {
  width: 50px !important;
  height: 50px !important;
}

.MuiGrid-root.video-player1.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12 {
  width: 1291px;
}

.sliderSlickContainer {
  position: relative;
  height: 380px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sliderImage {
  position: relative;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.sliderDetailContainer {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column-i;
  align-items: center;
  justify-content: flex-end;
  border-radius: 20px;
  padding: 0px 20px 20px;
  grid-gap: 10px;
  gap: 10px;
}

.sliderName {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.sliderSkill {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.ce_carousel_wrapper {
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  min-height: 200px !important;
}
@media screen and (max-width: 600px) {
  .ce_carousel_wrapper {
    padding: 0;
  }
}
.ce_carousel_wrapper .ce_carousel {
  width: 100vw;
}
@media screen and (max-width: 960px) {
  .ce_carousel_wrapper .ce_carousel .popTeachCardContainer {
    height: 300px;
  }
  .ce_carousel_wrapper .ce_carousel .slick-slide.slick-active.slick-center .popTeachName {
    font-family: "Poppins", sans-serif;
    font-size: 28px;
  }
  .ce_carousel_wrapper .ce_carousel .slick-slide.slick-active.slick-center .popTeachCardContainer {
    height: 340px;
  }
}
@media screen and (max-width: 600px) {
  .ce_carousel_wrapper .ce_carousel .popTeachCardContainer {
    height: 185px;
  }
  .ce_carousel_wrapper .ce_carousel .slick-slide.slick-active.slick-center .popTeachName {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
  }
  .ce_carousel_wrapper .ce_carousel .slick-slide.slick-active.slick-center .popTeachCardContainer {
    height: 200px;
  }
}

.slick-track {
  margin: auto;
}

.shareModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 180px;
  background-color: #fff;
  box-shadow: 24px;
  padding: 20px;
  border-radius: 12px;
}
@media screen and (max-width: 600px) {
  .shareModal {
    width: 450px;
  }
}
@media screen and (max-width: 500px) {
  .shareModal {
    width: 360px;
  }
}
@media screen and (max-width: 400px) {
  .shareModal {
    width: 300px;
  }
}

p.MuiTypography-root.shareCourseText.MuiTypography-body1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
}

.copyBtn {
  padding: 0 20px;
  border: none;
  background-color: #20a258;
  color: #fff;
  display: flex;
  align-items: center;
}

.copiedTxt {
  position: absolute;
  top: 85px;
  right: 20px;
  color: #20a258;
}
@media screen and (max-width: 600px) {
  .copiedTxt {
    top: 75%;
    left: 35%;
  }
}

svg.MuiSvgIcon-root.threeDots {
  position: relative;
  font-size: 40px;
  border-radius: 50%;
  cursor: pointer;
  background-color: white;
  margin-left: auto;
  border: 1px solid green;
}
@media screen and (max-width: 560px) {
  svg.MuiSvgIcon-root.threeDots {
    font-size: 25px;
  }
}

.MuiGrid-root.shareContainer.MuiGrid-container {
  width: 400px;
  display: flex;
  border: 1px solid rgb(32, 162, 88);
  justify-content: space-between;
}
@media screen and (max-width: 600px) {
  .MuiGrid-root.shareContainer.MuiGrid-container {
    width: 400px;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 500px) {
  .MuiGrid-root.shareContainer.MuiGrid-container {
    width: 320px;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 400px) {
  .MuiGrid-root.shareContainer.MuiGrid-container {
    width: 260px;
    flex-wrap: nowrap;
  }
}

.sharelink .Mui-disabled {
  -webkit-text-fill-color: #000 !important;
}

.completedBtn h5 {
  color: #59bd83;
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
}

.lessonBtn {
  border-radius: 40px;
  padding: 10px 20px;
  border: 1px solid #59bd83;
  background-color: #20a258;
}
.lessonBtn .MuiButton-label {
  text-transform: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (max-width: 560px) {
  .lessonBtn .MuiButton-label {
    font-size: 12px;
  }
}
@media screen and (max-width: 560px) {
  .lessonBtn {
    border-radius: 5px !important;
    padding: 10px 12px;
  }
}

.overviewLesson {
  margin: 0 auto;
  width: 90%;
}
@media screen and (max-width: 768px) {
  .overviewLesson {
    width: 100%;
  }
}

.react-player1 {
  position: relative;
  overflow: hidden;
}
.react-player1 .video {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.react-player1 .player-controls1 {
  position: absolute;
  left: 0;
  bottom: 7px;
  width: 100%;
  height: 70px;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.react-player1 .player-controls1 img {
  width: 50px !important;
  height: 50px !important;
}

.popTeachCardContainer {
  position: relative;
  height: 380px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.popTeachCardImage {
  position: relative;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.popCardDetailContainer {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 20px;
  padding: 0px 20px 20px;
  grid-gap: 10px;
  gap: 10px;
}
@media screen and (max-width: 600px) {
  .popCardDetailContainer {
    border-radius: 5px;
    padding: 0px 15px 15px;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px;
  }
}
.popCardDetailContainer .popTeachName {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .popCardDetailContainer .popTeachName {
    font-size: 24px;
    font-weight: 500;
  }
}
@media screen and (max-width: 600px) {
  .popCardDetailContainer .popTeachName {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
}
.popCardDetailContainer .popTeachSkill {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .popCardDetailContainer .popTeachSkill {
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .popCardDetailContainer .popTeachSkill {
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
  }
}

.courses_img_wrapper {
  width: 100%;
  cursor: pointer;
  height: 250px;
}
.courses_img_wrapper img {
  padding: 20px;
  border-radius: 30px;
  width: 100%;
  height: 100%;
}

.learningHeading {
  font-family: "AllRoundGothicW01-Medium";
  font-weight: 500;
  font-size: 62px;
  padding-left: 2.5rem;
}
@media screen and (max-width: 1160px) {
  .learningHeading {
    padding-left: 0;
    font-size: 52px;
  }
}
@media screen and (max-width: 960px) {
  .learningHeading {
    font-size: 48px;
  }
}
@media screen and (max-width: 768px) {
  .learningHeading {
    font-size: 32px;
    padding-left: 0;
    margin-bottom: 50px;
    margin-left: -32px;
  }
}
@media screen and (max-width: 600px) {
  .learningHeading {
    padding: 0;
    position: absolute;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 500;
    width: 170px;
    top: 15%;
    left: 5%;
    margin-left: 0;
  }
}

.totalStudents {
  font-weight: 500;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
}

.educatorDescription {
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .educatorDescription {
    font-size: 14px;
  }
}
@media screen and (max-width: 560px) {
  .educatorDescription {
    margin-top: 12px;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    color: #919191;
  }
}

.task_result_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 100vw;
}
.task_result_loader svg {
  color: #20a258;
}

.courseEnrolledText h6 {
  color: #92929d;
  font-weight: 500 !important;
}

.taskImageContainer {
  width: 100%;
}
.taskImageContainer div {
  padding-top: 0;
  max-height: 250px;
  max-width: 700px;
}
.taskImageContainer img {
  width: 100%;
}
@media screen and (max-width: 1160px) {
  .taskImageContainer img {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .taskImageContainer img .taskImage {
    width: 350px !important;
    -o-object-fit: fill;
       object-fit: fill;
  }
}
@media screen and (max-width: 560px) {
  .taskImageContainer img .taskImage {
    width: 285px !important;
    -o-object-fit: fill;
       object-fit: fill;
  }
}

.imageWrapper {
  padding-top: 0;
  height: 138px;
}

.reactPlayerWrapper video {
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (max-width: 560px) {
  .reactPlayerWrapper video {
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.videoThumbnail video {
  border-radius: 8px !important;
  -o-object-fit: fill !important;
     object-fit: fill !important;
}
@media screen and (max-width: 560px) {
  .videoThumbnail video {
    width: 102px !important;
    height: 57px !important;
    border-radius: 5px !important;
  }
}
.videoThumbnail .video {
  height: 84px;
  width: 150px;
  -o-object-fit: fill;
     object-fit: fill;
  border-radius: 8px !important;
  border: 1px solid #ccc;
}
@media screen and (max-width: 560px) {
  .videoThumbnail .video {
    width: 102px !important;
    height: 57px !important;
    border-radius: 5px !important;
  }
}
.videoThumbnail .video1 {
  height: 75px;
  width: 125px;
  -o-object-fit: fill;
     object-fit: fill;
  border-radius: 8px !important;
  border: 1px solid #ccc;
}
@media screen and (max-width: 560px) {
  .videoThumbnail .video1 {
    width: 73px !important;
    height: 44px !important;
    border-radius: 5px !important;
  }
}

.playerBox {
  margin-bottom: 150px;
}

.playerPadding {
  padding-left: 10px;
}
@media screen and (max-width: 960px) {
  .playerPadding {
    padding: 0 20px;
    margin-bottom: 2rem !important;
  }
}

.popularCoursesContainer {
  margin: 4rem 3rem 1rem 3rem;
  margin-left: 120px;
}
@media screen and (max-width: 1160px) {
  .popularCoursesContainer {
    margin-left: 38px;
  }
}
@media screen and (max-width: 960px) {
  .popularCoursesContainer {
    margin: 43px 17px 5px 38px;
    font-weight: normal;
  }
  .popularCoursesContainer div {
    margin-right: 40px;
  }
}
@media screen and (max-width: 600px) {
  .popularCoursesContainer {
    margin: 43px 17px 5px 19px;
    font-weight: normal;
  }
  .popularCoursesContainer div {
    margin-right: 40px;
  }
}

.popCourseWrapper {
  margin: 2rem 3rem 1rem 3rem;
}
@media screen and (max-width: 1160px) {
  .popCourseWrapper {
    margin: 0 0 12px 0 !important;
    width: 100% !important;
    font-weight: normal;
    padding: 20 0px;
  }
}

.viewBtnWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem !important;
}

.videoRightBox {
  margin-left: 20px;
}

.popTeachContainer {
  margin-top: 2rem;
  background-color: #e9eee9;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .popTeachContainer {
    margin-top: 0;
    background-color: #fff;
  }
}

.popTeachPadding {
  padding: 2rem;
}
@media screen and (max-width: 600px) {
  .popTeachPadding {
    padding: 1rem;
  }
}

.enrollImgContainer {
  display: flex;
  justify-content: center;
}

.learningEnrollSection {
  margin-top: 8rem;
  margin-bottom: 4rem;
}
@media screen and (max-width: 960px) {
  .learningEnrollSection {
    margin-top: 11rem;
  }
}
@media screen and (max-width: 600px) {
  .learningEnrollSection {
    margin-top: 14rem;
  }
}
@media screen and (max-width: 560px) {
  .learningEnrollSection {
    margin-top: 180px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 400px) {
  .learningEnrollSection {
    margin-top: 200px;
    margin-bottom: 0px;
  }
}

.enrollContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 60px;
}
@media screen and (max-width: 768px) {
  .enrollContentWrapper {
    width: 100%;
    padding-left: 40px;
  }
}

.enrollHeading {
  width: 563px;
  letter-spacing: 4.36px;
  font-size: 46px;
  margin-left: -50px;
  font-weight: 500;
}
@media screen and (max-width: 1160px) {
  .enrollHeading {
    width: auto;
    margin-left: 0;
  }
}
@media screen and (max-width: 960px) {
  .enrollHeading {
    width: auto;
    margin: 0;
    font-size: 32px;
  }
}
@media screen and (max-width: 768px) {
  .enrollHeading {
    margin-left: -30px;
    box-sizing: content-box;
    width: 180px;
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #000;
  }
}
@media screen and (max-width: 560px) {
  .enrollHeading {
    box-sizing: content-box;
    width: auto !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #000;
  }
}

.landingEnroll {
  margin-top: 2rem;
  margin-left: -50px;
}
@media screen and (max-width: 1160px) {
  .landingEnroll {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .landingEnroll {
    margin-left: -30px;
  }
}

.headerImg {
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  margin-top: 25px;
  border-radius: 15px;
  width: 750px;
  height: 510px;
}
@media screen and (max-width: 960px) {
  .headerImg {
    width: 100%;
    height: 380px;
  }
}
@media screen and (max-width: 600px) {
  .headerImg {
    width: 100%;
    height: 320px;
    margin-top: 0;
  }
}
@media screen and (max-width: 560px) {
  .headerImg {
    width: 100%;
    height: 230px;
    margin-top: 0;
  }
}

.groupImg {
  height: 4rem;
  width: 150px;
}
@media screen and (max-width: 600px) {
  .groupImg {
    width: 120px;
    margin: 0;
  }
}
@media screen and (max-width: 560px) {
  .groupImg {
    width: 100px;
    margin: 10px 0;
  }
}

.MuiFormControl-root.MuiTextField-root.searchContainer {
  padding-left: 20px;
  min-width: 350px;
  background-color: white;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #ccc;
  margin-top: 1.5rem;
  color: darkgray;
}
@media screen and (max-width: 600px) {
  .MuiFormControl-root.MuiTextField-root.searchContainer {
    min-width: 0;
    border-radius: 0;
  }
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.landingSearch {
  margin-top: -6px;
  background-color: #ffc107;
  color: #fff;
  height: 45px;
  width: 125px;
  border: none;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.landingSearch:hover {
  opacity: 0.92;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.landingSearch:hover span {
  color: #fff;
}
@media screen and (max-width: 600px) {
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.landingSearch {
    margin-top: -0.7rem;
    width: 60px;
    height: 30px;
    border-radius: 0px;
  }
}

.circleBtn.searchBoxContainer {
  margin-left: -3.5rem;
  margin-top: 30px;
}
@media screen and (max-width: 600px) {
  .circleBtn.searchBoxContainer {
    margin-top: 40px;
    margin-left: -5.5rem;
  }
}
@media screen and (max-width: 560px) {
  .circleBtn.searchBoxContainer {
    margin-top: 40px;
    margin-left: -4.5rem;
  }
}

.selectText {
  text-align: center;
  font-size: 56px;
  font-family: "AllRoundGothicW01-Medium";
  margin: 2rem 0rem;
  font-weight: lighter;
  color: #333;
}
@media screen and (max-width: 960px) {
  .selectText {
    font-size: 48px;
  }
}
@media screen and (max-width: 768px) {
  .selectText {
    font-size: 32px;
    color: #000;
    font-weight: 500px;
    margin: 0;
  }
}
@media screen and (max-width: 560px) {
  .selectText {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    color: #000;
    font-weight: 500px;
    margin: 0;
  }
}

.selectCourseContainer {
  margin-top: 2rem;
}

.MuiTypography-root.courseText.MuiTypography-h5 {
  margin: 3.5rem 0 0.1rem 7.2rem;
  color: #333;
  font-size: 56px;
  font-family: "AllRoundGothicW01-Medium";
}
@media screen and (max-width: 960px) {
  .MuiTypography-root.courseText.MuiTypography-h5 {
    font-size: 40px;
    padding: 0 2.5rem;
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .MuiTypography-root.courseText.MuiTypography-h5 {
    font-size: 32px;
    padding: 0 2rem;
    margin: 0;
  }
}
@media screen and (max-width: 560px) {
  .MuiTypography-root.courseText.MuiTypography-h5 {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    padding: 0 1.35rem;
    margin: 0;
  }
}

p.MuiTypography-root.courselistText.MuiTypography-body1 {
  font-size: 16px;
  margin: 0.5rem 0 2rem 7.2rem;
  color: rgba(51, 51, 51, 0.5);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
@media screen and (max-width: 960px) {
  p.MuiTypography-root.courselistText.MuiTypography-body1 {
    font-size: 14px;
    padding: 0 2.5rem;
    margin: 5px 0 0;
  }
}
@media screen and (max-width: 768px) {
  p.MuiTypography-root.courselistText.MuiTypography-body1 {
    font-size: 12px;
    padding: 0 2rem;
    margin: 5px 0 0;
  }
}
@media screen and (max-width: 560px) {
  p.MuiTypography-root.courselistText.MuiTypography-body1 {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    padding: 0 1.35rem;
    margin: 5px 0 0;
  }
}

.educatorHeadline {
  color: #9098b1;
  font-weight: 500;
  font-size: 12px;
}

.lessonWrapper {
  padding: 0 1rem;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .lessonWrapper {
    padding: 0 20px;
  }
}

.courseTitle {
  font-size: 48px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .courseTitle {
    font-size: 28px;
  }
}
@media screen and (max-width: 560px) {
  .courseTitle {
    font-size: 18px;
    font-family: "Poppins", sans-serif !important;
    color: #000;
    width: 126px;
  }
}

.reviewPadding .comment {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.reviewPadding .comment_date {
  display: flex;
  justify-content: flex-end;
}
.reviewPadding h5 {
  font-weight: 600;
  color: #000;
}

@media screen and (max-width: 560px) {
  body,
html {
    font-family: "Poppins", sans-serif !important;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.landingSearch {
    margin-top: -0.7rem;
    width: 60px;
    height: 30px;
    border-radius: 0px;
  }
  .avtar-grp h6 {
    font-size: 11px;
    font-family: "Poppins", sans-serif;
  }
  .avtar-grp p {
    font-size: 11px;
  }
  .avtar-grp .totalStudents {
    margin-top: 0;
  }
  .enrollImgContainer {
    height: 100%;
  }
  .enrollHeading {
    box-sizing: content-box;
    width: 145px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: justify;
    color: #000;
  }
  .landingEnroll {
    margin-top: 0;
  }
  .MuiGrid-spacing-xs-1 {
    width: 100% !important;
    margin: 0 !important;
  }
  .courses_img_wrapper {
    height: 150px !important;
  }
  .courses_img_wrapper div {
    height: 150px !important;
  }
  .courses_img_wrapper img {
    width: 100%;
    height: 150px !important;
  }
  .circleBtn .MuiButton-root {
    padding: 7px 11px 8.1px;
    border-radius: 5px;
    width: 100% !important;
  }
  .circleBtn .MuiButton-root .MuiButton-label span {
    font-size: 12px;
  }
  .selectCourseContainer {
    margin-top: 28px;
    padding-right: 0;
  }
  .chip {
    border-radius: 3px !important;
    padding: 5px 10px !important;
  }
  .MuiChip-root {
    min-width: 0px !important;
  }
  .chip-active {
    border: 1px solid #3fa74a !important;
    color: #3fa74a !important;
  }
  .chip-active .MuiChip-label {
    color: #3fa74a !important;
  }
  .courseDetail {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .container-img-2 {
    position: relative;
    height: 278px !important;
  }
  .container-img-2 .image {
    position: relative;
    padding: 20px;
    border-radius: 5px;
    height: 278px !important;
  }
  .one {
    position: absolute;
    max-width: 145px;
    top: 14rem;
    left: 56%;
    margin-bottom: 20px;
  }
  .one h6,
.one p {
    font-size: 12px;
  }
  .one .avatarImgWrapper {
    width: 50px;
    height: 50px;
  }
  .margin1 {
    margin-top: 0;
  }
  .margin1 .MuiPaper-outlined {
    border: none;
  }
  .customAccordian {
    padding: 0 20px;
    border-top: #fff !important;
    border-bottom: #fff !important;
  }
  .customAccordian .MuiAccordion-root {
    background-color: #f2f7fe !important;
  }
  .margin3 {
    margin-top: 0;
    margin-bottom: 0 !important;
  }
  .rightEducatorContainer {
    width: 100%;
    margin-top: 0;
    padding: 10px 0 0 20px !important;
    height: 100%;
  }
  .margin4 {
    padding: 0 20px;
    margin-bottom: 0;
  }
  .knowlink {
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  .comment_wrapper {
    flex-direction: row;
    border-radius: 5px !important;
  }
  .comment_wrapper h5,
.comment_wrapper p {
    font-size: 12px;
    font-weight: normal;
    font-family: "Poppins", sans-serif !important;
  }
  .comment_wrapper p {
    color: #7d7979;
  }
  .comment_wrapper .StyledRating {
    font-size: 16px;
  }
  .comment_wrapper .reviewPadding {
    padding: 2px !important;
  }
  .comment_wrapper .rightReviewContainer {
    align-items: center;
    justify-content: space-between;
  }
  .comment_wrapper .reply_btn {
    padding: 5px 10px;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    border-radius: 3px;
  }
  .comment_wrapper .reply_btn .replyIcon {
    padding-left: 4px;
    color: #fff;
  }
  .comment_wrapper .reply_btn .replyIcon .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
  .reviewWrapper {
    padding: 20px;
  }
  .reviewText {
    font-weight: 500;
    font-size: 16px;
  }
  .reviewImageContainer {
    width: 35px;
    height: 35px;
  }
  .reviewImageContainer .image {
    width: 35px !important;
    height: 35px !important;
  }
  .replyBox {
    height: 100%;
  }
  .reply-wrapper {
    padding: 14px;
    border-radius: 5px;
  }
  .reply-wrapper .MuiInputBase-input {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
  .reply-wrapper .send-btn {
    padding: 6px;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    text-transform: capitalize;
    border-radius: 5px !important;
  }
  .reply-wrapper .saveBtnWrapper {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .reply-wrapper .MuiFormControl-root {
    margin-top: 0 !important;
  }
  .reply-wrapper .MuiOutlinedInput-root {
    padding: 0;
  }
  .discussion {
    height: auto !important;
  }
  .discussion pre {
    font-size: 12px !important;
    color: #5e5e5e !important;
  }
  .discussion .replyDetail {
    font-family: "Poppins", sans-serif !important;
  }
  .discussion .replyDetail h5,
.discussion .replyDetail p {
    font-size: 12px;
  }
  .editDelContainer {
    justify-content: left;
  }
  .editDelContainer .replyBtnWrapper {
    margin-left: 0;
    margin-top: 0;
  }
  .editDelContainer .replyBtnWrapper .edit_reply {
    border-radius: 5px !important;
    padding: 4px 20px;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
  .review_label {
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
  }
  .StyledRating {
    font-size: 16px !important;
  }
  .write_review_text_area {
    border-radius: 5px;
    height: 130px !important;
    font-size: 14px;
  }
  .green_round_btn {
    border-radius: 5px !important;
    padding: 4px 12px !important;
    font-size: 12px !important;
  }
  .rightBox {
    height: 57px;
  }
  .day-status {
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
  }
  .enrolledLessonHeading {
    font-size: 12px;
    font-family: "Poppins", sans-serif !important;
  }
  .tabBox {
    padding-left: 0;
  }
  .tabBox .MuiTab-wrapper {
    font-size: 12px !important;
    font-family: "Poppins", sans-serif !important;
  }
}
@media screen and (max-width: 960px) {
  .video-player {
    width: 100%;
    height: 320px;
  }
  .video-player div {
    width: 100% !important;
    height: 0 !important;
  }
  .video-player video {
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 5px;
    height: 320px !important;
  }
  .video-player .player-controls {
    bottom: 1px;
    height: 60px !important;
    border-radius: 0;
  }
  .video-player .player-controls img {
    width: 40px !important;
    height: 40px !important;
  }
}
@media screen and (max-width: 480px) {
  .video-player {
    width: 100%;
    height: 238px;
  }
  .video-player div {
    width: 100% !important;
    height: 0 !important;
  }
  .video-player video {
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 5px;
    height: 238px !important;
  }
  .video-player .player-controls {
    bottom: 1px;
    height: 50px !important;
    border-radius: 0;
  }
  .video-player .player-controls img {
    width: 30px !important;
    height: 30px !important;
  }
}
@font-face {
  font-family: "AllRoundGothicW01-Bold";
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot");
  src: url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.woff") format("woff"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/8df141f447c5686cf9dbae8dabb1e71a.svg#AllRoundGothicW01-Bold") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Medium";
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot");
  src: url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.woff") format("woff"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/ff9f9b187dc224f3f985a8fadbd88d45.svg#AllRoundGothicW01-Medium") format("svg");
}
@font-face {
  font-family: "AllRoundGothicW01-Book";
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot");
  src: url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.woff") format("woff"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dd266e8a31d9cbae26395f9d100e4e4b.svg#AllRoundGothicW01-Book") format("svg");
}
.errorMsg {
  color: red !important;
  font-size: 13px;
  font-weight: 500 !important;
}

.Block2 {
  width: 60px;
}

.flex {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  padding: unset;
  justify-content: unset;
  align-items: center;
}

.Course {
  font-size: medium;
  font-weight: bolder;
  -moz-columns: silver;
       columns: silver;
  display: flex;
}

.text {
  color: green;
  font-size: 22px;
  font-weight: bold;
}

.text-course {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.box {
  margin: 2.5rem 0 0 2rem;
  border-radius: 10px;
}

.TextField {
  margin-top: 0.3rem;
}

.Box3 {
  display: flex;
  flex-direction: column;
  margin: 0.7rem 1.5rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.footer-button {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.footer-btn01 {
  padding: 1.5rem;
}

.footer-btn02 {
  padding: 1.5rem;
}

.h5 {
  font-weight: bolder;
  color: #484242;
}

.delete {
  display: flex;
  justify-content: right;
  align-self: flex-end;
}

.MuiGrid-root.Block2.MuiGrid-item.MuiGrid-grid-xs-6 {
  max-width: 100%;
  flex-grow: 0;
  flex-basis: 70%;
}

.MuiGrid-root.MuiGrid-grid-xs-3 {
  max-width: 70%;
  flex-grow: 0;
  flex-basis: 0%;
}

.MuiBox-root.MuiBox-root-9.flex {
  justify-content: left;
  padding: 1.5rem 0 0 0;
  margin: 1rem;
}

p.MuiTypography-root.create-course.MuiTypography-body1 {
  font-family: "Poppins", sans-serif;
}

span.MuiTab-wrapper {
  font-size: 18px;
  color: #bbbbbb;
  font-weight: 600;
}
