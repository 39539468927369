@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';

.customStepperContainer {
  .MuiStepper-root {
    // background-color: brown;
    // padding: 30px;
    .MuiStep-horizontal {
      // background-color: brown;
      .MuiStepLabel-root {
        .MuiStepLabel-iconContainer {
          .MuiStepIcon-root {
            &.MuiStepIcon-active {
              color: #f8892b;
            }
            &.MuiStepIcon-completed {
              color: #f8892b;
            }
          }
        }
        & .MuiStepLabel-labelContainer {
          display: none;
          visibility: hidden;
        }
      }
    }
  }
}

// step icon

.stepIcon {
  border-radius: 50%;
  // padding: 0 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-top: -8px;
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
  span {
  }
}
