@import "../../../../web/src/assets/css/constants/base/colors";
@import "../../../../web/src/assets/css/constants/base/global";

//images
// @import { Banner_Img } from "./assets";

.adminRegRestaurantWrapper {
  & .bannerCard {
    & .MuiCard-root {
      border-radius: 20px 20px 0 0;
    }
    & .bannerHeadCard {
      background-image: url("../images/bannerImg.jpg");
      background-size: cover;
      background-position: top center;
      // background-position: 100% 100%;
      width: 100%;
      height: 400px;
      padding: 70px;
      @media screen and (max-width: 767px) {
        padding: 20px;
      }
      & h1 {
        color: #fff;
        font-weight: 500;
        margin-bottom: 15px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
    & .CardContent {
      text-align: center;
      padding: 70px 10px 50px 10px;
      & p {
        font-size: 16px;
        color: $DARK;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      & h4 {
        font-weight: 500;
        @media screen and (max-width: 767px) {
          font-size: 22px;
        }
      }
    }
    // & .MuiTypography-body2 {
    //     font-size: 17px;
    // }
  }
  & .workCard {
    position: relative;
    & .MuiCard-root {
      border-radius: 0 0 20px 20px;
    }
    & .bannerHeadCard {
      background-image: url("../images/bannerImg.jpg");
      background-size: cover;
      background-position: top center;
      // background-position: 100% 100%;
      width: 100%;
      height: 300px;
      position: relative;
      z-index: 1;
      @media screen and (max-width: 767px) {
        height: 100vh;
      }
      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(173, 232, 241, 0.8);
        z-index: -1;
      }

      & .HeadCardContent {
        z-index: 3;
        & h5 {
          color: $DARK_SHADE2;
          font-weight: 600;
          padding-top: 50px;
          padding-bottom: 20px;
          text-align: center;
          margin: 0;
          font-size: 1.8rem;
        }
      }
      & .MuiTypography-body2 {
        font-size: 17px;
      }
    }
    & .bannerBottomCard {
      padding: 100px;
      height: 300px;
      background-color: $WHITE;
      @media screen and (max-width: 767px) {
        height: 90vh;
      }
    }
    & .stepsMainCardContent {
      position: absolute;
      top: 150px;
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 4;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        top: 100px;
      }
      & .stepsCard {
        margin: 0 20px 30px 20px;
        padding: 30px 20px;
        background-color: $WHITE;
        border: 1px solid $GRAY_SHADE1;
        border-radius: 10px;
        box-shadow: $BOX_SHADOW;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        @media screen and (max-width: 768px) {
          margin: 0 5px 30px 5px;
        }
        & .icons {
          margin-bottom: 30px;
        }
        & h6 {
          color: $DARK_SHADE2;
          font-weight: 600;
          font-size: 19px;
          margin: 0;
        }
        & p {
          color: $DARK;
          font-weight: 500;
          padding-top: 30px;
          margin: 0;
        }
      }
    }
    & .MuiTypography-body2 {
      font-size: 17px;
    }
  }
}
