// -------------colors ------------//
$DARK: #394053;
$DARK_SHADE1: #5d5e6a;
$DARK_SHADE2: #1e1e1e;
$BLACK: #000;
$WHITE: #ffffff;
$GRAY: #394053;
$GRAY_SHADE1: #c3c3c3;
$GRAY_SHADE2: #f0f0f0;
$GRAY_SHADE3: #c1c1c1;
$GRAY_SHADE4: #808080;
$GRAY_SHADE5: #bababa;
$GRAY_SHADE6: #515156;
$GRAY_SHADE7: #c1c1c15e;
$GRAY_SHADE8: #f7f7f7;
$GRAY_SHADE9: #e2dada;
$GRAY_SHADE10: #757575;
$GRAY_SHADE11: #f3f3f3;
$GRAY_SHADE12: #cccccc94;
$GRAY_SHADE13: #f2f2f2;
$GRAY_SHADE14: #f4f5f5;
$GRAY_SHADE15: #92929d;
$GRAY_SHADE16: #44444f;
$GRAY_SHADE17: #8994a6;
$GRAY_SHADE18: #464a53;
$GRAY_SHADE19 : #9098b1;

$GREEN: #20a258;
$GREEN_SHADE1: #6ddfba;
$GREEN_SHADE2: #6ddfba36;
$GREEN_SHADE3: #e7ffed;
$GREEN_SHADE4: #6a6b77;
$GREEN_SHADE5: #edf8f2;
$GREEN_SHADE6: #59bd83;
$GREEN_SHADE7: #0f3b18;
$GREEN_SHADE8: #418c61;
$GREEN_SHADE9: #d8eee0;
$GREEN_SHADE10: #3bad6c;
$GREEN_SHADE11: #b6e0c8;

$RED: red;
$RED_SHADE1:#F3666A;
$DANGER: #ff8d8d;
$DANGER1: coral;
$DANGER2: #fdeeee;
$DANGER3: #f59495;
$DANGER4: #fff8e8;
$DANGER5: #dd92933d;

$SKYBLUE: #e3e9f6;
$SKYBLUE_SHADE1: #f7f8fa;
$SKYBLUE_SHADE2: #dbdcdf;
$SKYBLUE_SHADE3: #5babfb;
$SKYBLUE_SHADE4: #e3eaf7;
$Black: #000000;
$ORANGE: #ec9e31;
$ORANGE2: #ffac0c;
$ORANGE3: #ff9800;
$ORANGE_SHADE1: #ffefd3;
// #ffb400
$GREEN_BLACK: #36433a;
$DARK_BLUE: #263c78;
$BLUE_SHADE1: #edf7fe;
$BLUE_SHADE2: #76c4fc;

