@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.faq_container {
  margin: 100px 0px;
}

.faqside_image {
  border-radius: 10px;
  width: 100%;
  min-height: 450px;
  background: url(../faqsideimg.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.rootAccordian {
  box-shadow: none !important;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-top: 20px;
  & .MuiAccordionSummary-root {
    padding: 0 !important;
    & .MuiAccordionSummary-content {
      & p {
        padding-left: 0 !important;
      }
      @media screen and (min-width: 321px) {
        margin-left: 0 !important;
      }
    }
  }
}
.faq_heading {
  font-size: 35px !important;
  text-transform: uppercase;
  font-family: $ROUNDGOTHIC_BOOK !important;
  @media screen and (max-width: 480px) {
    font-size: 25px !important;
  }
}

.faqSpacing {
  margin-top: -34px !important;
  @media screen and (max-width: 767px) {
    margin-top: -100px !important;
  }
}
.question {
  width: 100%;
  word-wrap: break-word;
  font-size: 18px;
  flex-shrink: 0;
  margin-right: 20px;
}
.answer {
  font-size: 16px;
  color: #797979;
}
.removeIcon {
  color: #dac4b5;
}
.addIcon {
  color: #797979;
}
