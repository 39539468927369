@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.add_card_form_wrapper {
  padding: 20px 50px 35px;

  @media screen and (max-width: 600px) {
    padding: 10px 25px 20px;
  }

  @media screen and (max-width: 300px) {
    padding: 10px 20px 20px;
  }
}

.add_card_heading {
  text-align: center;
  border-bottom: 5px solid $GREEN;
  margin-bottom: -5px !important;
  padding: 2px 30px;
  font-weight: 500 !important;
  color: #000;
  letter-spacing: 0.2px !important;
  word-spacing: 0.8px !important;
  font-size: 18px !important;
  font-family: $MONTSERRAT;
}

.check_out_ccard_add_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;

  p {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  button {
    width: fit-content;
    border-radius: 50px !important;
    background-color: $GREEN !important;
    padding: 5px 20px !important;

    span {
      text-transform: capitalize;
      color: #fff;
    }
  }
}

.check_out_ccard_add_card_row_item {
  border-radius: 10px;
  background-color: #f3f0f0;
  padding: 10px;
  margin: 10px 0px;
  cursor: pointer;

  h6 {
    color: $Black;
    font-family: $OPEN_SANS;
  }

  .ccard_card_row_action_icon {
    width: fit-content !important;
    padding: 9px !important;

    img {
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }
}

.checkout_card_selected {
  background-color: $GREEN;

  h6 {
    color: #fff;
  }
}

.check_wrapper {
  .check_root {
    padding: 0px !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 5px !important;
  }
}

.custom_card_select_checkbox {
  color: #333;
  border-radius: 5px;
  font-size: 20px;
  background-color: #f3f0f0;
}

.flex_item {
  justify-content: space-between;
  align-items: flex-end;

  h6 {
    color: $Black;
    font-weight: 600;
  }
}

.ccard_radio {
  display: flex;
  align-items: flex-end !important;

  span {
    padding-bottom: 0px;
  }
}

.cvv_form_wrapper {
  padding: 20px 40px 40px;

  .cvv_heading {
    font-family: $MONTSERRAT;
    font-size: 20px;
    font-weight: 500;
    color: #000;
    width: fit-content;
    text-align: center;
    border-bottom: 5px solid $GREEN;
    margin-bottom: -5px !important;
    padding: 5px 20px;
  }

  .cvv_otp_fields {
    width: 60px !important;
    text-align: center;
    height: 60px;
    padding: 15px;
    border: none;
    margin: 0px 10px;
    outline: none;
    border-radius: 8.8px;
    box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.19);
    background-color: #f6f6f6;
    color: #20a258;
    font-weight: 500;
    font-size: 20px;
  }

  .cvv_error {
    margin-top: 15px;
    margin-left: 15px;
    color: #ff0000;
  }

  button {
    width: 100%;
    background-color: $GREEN;
    padding: 12px !important;
    border-radius: 30px !important;
    margin-top: 30px;

    span {
      color: $WHITE;
      text-transform: capitalize;
    }

    &:hover {
      background-color: $GREEN;

      span {
        color: $WHITE;
      }
    }
  }
}

.cvv_check_disabled {
  background-color: $GRAY_SHADE1 !important;

  span {
    color: $Black;
  }
}

.cvv_statement {
  color: $Black;
  font-size: 14px;
  font-weight: 500;
}

.cvv_input_wrapper {
  input {
    width: 30px;
    height: 30px;
    padding: 15px;
    text-align: center;

    &::after,
    &::before {
      border: none !important;
      outline: none !important;
    }
  }
}

.ccard_detail_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  h6 {
    margin-left: 5px;
  }
}


.add_card_fields {
  margin: 20px 0px !important;
}

.custom_masked_field {
  font-family: $MONTSERRAT;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.09);
  padding: 15px 20px;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.10);
  font-weight: 600;
  font-size: 15px;
  margin: 5px 0px;

  &:disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  &:focus {
    outline: none;
    color: $GREEN;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.35);
    font-weight: 600;
  }

  &:focus::placeholder {
    color: $GREEN;
  }
}

.custom_save_radio {
  border: 2px solid $GREEN !important;
  height: 8px;
  width: 8px;
  padding: 8px !important;
  margin-right: 10px !important;

  svg {
    color: #fff;
    font-size: 16px;
  }

}

.custom_check_save_radio {
  background-color: $GREEN !important;
}

.save_card_header {
  font-family: $MONTSERRAT !important;
}

@media (max-width: 800px) {
  .cvv_form_wrapper {
    padding: 0px 40px 20px;
  }
}

@media (max-width: 599px) {
  .ccard_card_row_action_icon {
    text-align: end;
  }

  .cvv_input_wrapper {
    padding: 1px;
  }
}

@media (max-width: 300px) {
  .flex_item {
    flex-direction: column;
    align-items: flex-start;
    text-align: end !important;
  }

  .ccard_price_wrapper {
    width: 100%;
  }

  .ccard_radio {
    display: flex;
    align-items: center !important;

    span {
      padding-bottom: 10px;
    }
  }
}

.card_form_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}