@import '../constants/base/';
.pickerContainer {
  margin-left: 10px;
  width: 100%;
  .MuiButton-root {
    font-size: 0.6rem;
    &.MuiButton-outlined {
      background-color: $GRAY_SHADE3;
      width: 100%;
      border: none;
      span {
        font-weight: 600;
        color: $WHITE;
      }
      .MuiSvgIcon-root {
        color: orange;
        font-size: 20px;
        padding: 0;
        border: none;
        margin: 0;
      }
    }
  }
  & .MuiPaper-root {
    background-color: blue;
  }

  .grayButton {
    height: 100%;
    & .MuiButton-root {
      border-radius: $FORM_BORDER_RADIUS;
      border: 1px solid transparent;
      background-color: #f3f3f3;
      white-space: nowrap;
      & .MuiButton-label {
        color: black;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 600;
        & span {
          padding: 0 10px;
        }
      }
      & .plus_icon {
        display: none;
      }
      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
}

.dateRangePicker {
  overflow: hidden;
  width: 100%;
  padding: 10px 20px;

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    font-size: 10px;

    span {
      font-weight: 700;
      font-size: 14px;
      color: $DARK;
    }
  }

  & .DateRangePicker {
    width: 100%;
    margin-top: 10px;

    & .DateRangePicker__Weekdays {
      display: none;
    }
    & .DateRangePicker__MonthDates {
      border: none !important;
      margin-top: 10px;
    }
    & .DateRangePicker__Month {
      margin: unset !important;
      width: 100%;
    }
    & .DateRangePicker__Date {
      border: 0 solid white !important;
    }

    & .DateRangePicker__MonthHeader {
      border-bottom: 1px solid $GRAY_SHADE3;
      line-height: 40px;
      padding-bottom: 45px !important;
      .DateRangePicker__MonthHeaderLabel {
        color: $GRAY_SHADE17;
      }
    }

    & .DateRangePicker__CalendarHighlight {
      display: none;
    }

    & .DateRangePicker__PaginationArrow {
      border-radius: 50%;
      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.06);
      }
    }

    & .DateRangePicker__PaginationArrow--next {
      right: 0px;
      .DateRangePicker__PaginationArrowIcon--next {
        border: solid $GRAY_SHADE17;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-45deg);
        left: 9px;
        top: 11px;
      }
    }
    & .DateRangePicker__PaginationArrow--previous {
      left: 0px;
      .DateRangePicker__PaginationArrowIcon--previous {
        border: solid $GRAY_SHADE17;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(135deg);
        right: 9px;
        top: 11px;
      }
    }
    
    & .DateRangePicker__CalendarSelection {
      background-color: $GREEN_SHADE3;
      border: 1px solid $GREEN_SHADE3;
    }
    & .DateRangePicker__DateLabel {
      font-size: 14px !important;
      color: $GRAY_SHADE17;
    }

    & .DateRangePicker__CalendarSelection--start,
    .DateRangePicker__CalendarSelection--end {
      background-color: $GREEN;
      border: 1px solid $GREEN;
      border-radius: 5px;
      & .DateRangePicker__DateLabel {
        color: white !important;
      }

      &.DateRangePicker__DateLabel {
        color: white;
        font-size: 16px !important;
      }
    }

    & .DateRangePicker__CalendarSelection--start {
      left: 0;
    }
    & .DateRangePicker__CalendarSelection--end {
      right: 0;
    }

    & .DateRangePicker__Date--weekend {
      background-color: white;
    }
  }
}
