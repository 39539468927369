@import "../constants/base/colors.scss";
@import "../constants/base/mixins.scss";
@import "../constants/base/global.scss";

.customAccordian {
  & .MuiAccordion-root {
    margin-bottom: 20px;
    padding: 10px 20px;
    box-shadow: none;
    border: 1px solid $GRAY_SHADE3;
    border-radius: 10px !important;
    @media screen and (max-width: 1024px) {
      padding: 10px;
    }
  }
  & .topTitle {
    color: $GREEN;
    font-weight: 600;
    font-family: $POPPINS;
    @media screen and (max-width: 1024px) {
      font-size: 1rem;
    }
  }
  & .subTitle {
    padding-left: 30px;
    color: $Black;
    font-weight: 600;
    font-family: $POPPINS;
    @media screen and (max-width: 1024px) {
      font-size: 1rem;
    }
  }
  & p {
    font-size: 16px;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }
  & .AccordionSummary {
    & .MuiSvgIcon-root {
      display: none;
    }
    & .MuiAccordionSummary-expandIcon {
      background-color: $ORANGE;
      &:hover {
        background-color: $ORANGE;
      }
    }
    & .MuiIconButton-label {
      position: relative;
      &::before,
      &::after {
        content: " ";
        position: absolute;
        // left: -7px;
        height: 2px;
        width: 12px;
        background-color: $WHITE;
      }
      &::after {
        transform: rotate(90deg);
        background-color: $WHITE;
      }
    }
    & .MuiAccordionSummary-expandIcon.Mui-expanded {
      transform: rotate(0deg);
      & .MuiIconButton-label {
        &::after {
          display: none;
        }
      }
    }
  }
  & .AccordionDetails {
    display: flex;
    flex-direction: column;
    & h6 {
      margin: 10px 0;
      font-weight: 600;
      color: $Black;
    }
  }
}
