@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.ch_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 30px;
    width: 100%;
    background-color: #fff;

    @media screen and (max-width: 1024px) {
      padding: 15px;
    }

    .ch_left_container {
      display: flex;
      align-items: center;
      gap: 5px;

      .toggle_menu_icon {
        padding: 8px;
        margin-right: 8px;
    
        img {
          height: 25px;
        }
    
        @media (min-width: 1024px) {
          display: none;
        }
      }
    }
    
    .ch_logo_wrapper {
      width: 135px;

      img {
        width: 100%;
      }
  
      @media (max-width: 600px) {
        width: 120px;
        min-width: 120px;
      }
  
      @media (max-width: 400px) {
        width: 90px;
        min-width: 90px;
      }
    }

    .ch_btn_container {
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        .ch_btn {
            border-radius: 22px;
            border: 1px solid #147620;
            background-color: #fff;
            padding: 6px 17px;
            text-transform: none;

            span {
                font-family: $POPPINS;
                font-size: 16px;
                font-weight: 500;
                color: #000;
            }

            .ch_btn_image {
                border-radius: 50%;
                height: 23px;
                width: 23px;
            }

            @media screen and (max-width: 768px) {
                padding: 6px !important;
                border-radius: 50%;
                min-width: fit-content !important;

                .ch_btn_image {
                    height: 26px;
                    width: 26px;
                }
            }
        }
    }
}

.ch_account_menu_wrapper {
    padding: 15px 20px;

    div {
        cursor: pointer;
        font-family: $MONTSERRAT;
        font-size: 17px;
        padding: 4px 0px;
        color: #333;
        &:hover {
            font-weight: 600;
            color: $GREEN;
        }
    }
}