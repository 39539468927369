@import '../constants/base/colors.scss';
@import '../constants/base/global.scss';

.customPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 480px) {
    gap: 10px;
    width: 100%;
    justify-content: space-between;
  }

  & .MuiPagination-ul {
    gap: 5px;

    & .MuiPaginationItem-page {
      &:hover {
        background-color: $GREEN;
        color: $WHITE;
        border: 1px solid $GREEN;
      }

      &.Mui-selected {
        background-color: transparent;
        color: $GREEN;
        border: none;

        &.Mui-focusVisible {
          background-color: $GREEN;
        }
      }
    }

    & li {
      border-radius: 8px;
      height: 32px;
      width: 32px;

      button {
        margin: 0;
        padding: 0;
        border-radius: 8px;
      }

      &:first-child,
      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;

        button {
          border: 1px solid #e2e2ea;
          background-color: $WHITE;

          svg {
            font-size: 22px;
            color: #92929d;
          }

          &:hover {
            background-color: $GREEN;
            border: 1px solid $GREEN;

            svg {
              color: #fff;
            }
          }

          &.Mui-disabled {
            background-color: #fff;
          }
        }

        & .MuiPaginationItem-page {
          &:hover {
            color: unset !important;
            border: unset;
          }
        }
      }
    }
  }

  & .pagePerViewContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e2e2ea;
    border-radius: 8px;
    height: 32px;

    & p {
      padding: 0px 12px;
      font-size: 14px;
      letter-spacing: 0.1px;
      font-family: 'Roboto', sans-serif;
      color: #92929d;
    }

    & .btnContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      flex-direction: column;
      border-left: 1px solid #e2e2ea;
      padding: 0px 8px;
      height: 100%;

      .icon {
        width: 0;
        height: 0;
        border-radius: 5px;
        color: #92929d;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #92929d;
        cursor: pointer;
        padding: 0px;

        &:hover {
          border-left-color: $GREEN;
        }

        &:disabled {
          border-left-color: #92929d;
          cursor: default;
        }

        &:nth-child(1) {
          transform: rotate(270deg);
        }

        &:nth-child(2) {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.restOrderPagination {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: -1;
  background-color: #fff;
  z-index: 1;
  border-top: 1px solid rgba(193, 193, 193, 0.4);

  @media screen and (max-width: 480px) {
    div:nth-child(1) {
      display: none;
    }
  }

  div {
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #44444f;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}