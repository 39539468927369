@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.menu_item_title {
  font-family: $OPEN_SANS !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #000 !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu_item_modal_wrapper {

  h5,
  p {
    color: $Black;
  }

  h5 {
    font-weight: 600;
  }

  p {
    font-size: 15px;
  }

  span {
    color: $GRAY_SHADE4;
    font-size: 13px;
    padding: 10px 0px;
  }

  .menu_item_description {
    font-family: $OPEN_SANS;
    font-size: 14px;
    color: #000;
    border-bottom: 1px solid $GRAY_SHADE3;
    padding-bottom: 8px;
  }

  textarea {
    width: 100%;
    resize: none;
    border-radius: 10px;
    border: 1px solid $GRAY_SHADE1;
    padding: 10px 30px;

    &:focus {
      outline: none;
    }
  }
}

.menu_item_description_wrapper {
  padding-bottom: 8px;
  border-bottom: 1px solid $GRAY_SHADE3;
  color: #000000;
}

.choose_item_check_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  p {
    color: $Black;
    font-weight: 500;
    text-transform: capitalize;
  }

  span {
    padding: 0px;
  }
}

.choose_number_of_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid $GREEN;
  padding: 6px 8px;
  border-radius: 24px;

  span {
    background-color: $GREEN;
    border-radius: 50%;
    padding: 3px;

    svg {
      color: $WHITE;
    }
  }
}

.add_to_order_btn {
  background-color: $GREEN !important;
  border-radius: 24px !important;
  padding: 8px !important;
  width: 100%;
  height: 100%;

  span {
    color: $WHITE;
    text-transform: none;
    font-size: 16px;
  }
}

.instruction_title {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #05172c;
}

.instruction_description {
  margin-bottom: 16px;
  font-size: 14px !important;
}

.show_menu_close_icon {
  width: 40px;
  height: 40px;

  svg {
    color: #000000;
    width: 25px;
    height: 25px;
  }
}

.quantity_change_icon {
  cursor: pointer;
}

.quantity_change_icon:disabled {
  cursor: default;
}