@import "../../../../web/src/assets/css/constants/base/colors";
@import "../../../../web/src/assets/css/constants/base/global";

.headerContainer {
  border-bottom: 1px solid $GRAY_SHADE3;
}

.headingReport {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 40px;
    height: 30px;
  }

  h3 {
    font-family: $MONTSERRAT;
    font-weight: 600;
    font-size: 22px;
  }
}

.reportCardWrapper {
  padding: 40px;
}

.reportCard {
  width: 290px;
  height: 275px;
  padding: 20px 0px 30px;
  border-radius: 12px;
  box-shadow: 0 0 6px 4px rgb(216, 214, 214);
  background-color: #fff;
  font-family: $MONTSERRAT;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  h4 {
    margin: 0 30px 7.3px 35px;
    font-size: 20px;
    font-weight: 500;
    color: #000;

    span {
      color: #000;

      @media screen and (max-width: 560px) {
        font-size: 14px;
      }
    }
  }

  h3 {
    margin: 32px 30px 7.3px 35px;
    font-size: 32px;
    font-weight: 600;
    color: #01c9ff;
  }

  h5 {
    width: 83px;
    margin-top: 26px;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-right: 25px;
  }

  .reportOrders {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .reportOrdersWeek {
    display: flex;
    align-items: center;

    h3 {
      margin: 10px 30px 7.3px 35px;
      font-size: 32px;
      font-weight: 600;
      color: #01c9ff;
    }

    h5 {
      width: 83px;
      margin-top: 6px;
      font-size: 18px;
      font-weight: 500;
      color: #000;
    }
  }

  .reportsCurve {
    width: 285px;
    height: 114px;
    margin: 22px 0 0;
    padding: 14.3px 50px 64.8px 127px;
    background-image: linear-gradient(
      184deg,
      #90e7ff 92%,
      rgba(190, 241, 255, 0) 40%
    );
  }
}
