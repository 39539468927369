@import "../../../../web/src/assets/css/constants/base/colors.scss";

.web_restaurant_nav_bar {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toggle_menu_icon {
    margin-right: 10px;

    img {
      height: 25px;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  img {
    width: 100%;

    @media (max-width: 600px) {
      width: 100px;
    }
  }

  .web_restaurant_routing_tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 1320px) {
      display: none;
    }
  }

  .web_restaurant_routing_tabs_none {
    display: none;
  }

  a {
    text-transform: uppercase;
    color: $GRAY_SHADE4;
    font-weight: 500;
  }

  .search_icon {
    background-color: $ORANGE;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $WHITE;
  }

  .web_header_profile {
    display: flex;
    justify-content: flex-end;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
      margin-left: 10px;

      @media (max-width: 400px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  .web_header_select {
    :root {
      margin-right: 20px !important;
    }

    svg {
      color: $WHITE;
      top: 5px;
      left: 3px;
    }

    .MuiSelect-select.MuiSelect-select {
      padding-right: 0 !important;
      margin-right: 0px;
      background: green;
      border-radius: 50%;
      width: 30px;
      height: 1;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: #fff;
    }

    .MuiInput-underline::before,
    .MuiInput-underline::after {
      border-bottom: none !important;
      outline: none !important;
    }
  }

  .restaurant_navbar_search_input_wrapper {
    background: $GRAY_SHADE2;
    border-radius: 50px;
    transition: all 1s;

    .MuiFilledInput-underline::before {
      border: none;
      outline: none;
    }

    button {
      width: 9%;
      border-radius: 50px;
      background: $ORANGE;

      span {
        color: $WHITE;
      }
    }
  }

  .restaurant_navbar_search_input_wrapper_none {
    display: none;
  }

  .restaurant_navbar_search_input {
    width: 90%;
    transition: all 1s;

    background-color: transparent !important;

    input {
      padding: 15px 20px;
      background-color: transparent;
    }
  }

  .underline {
    background-image: linear-gradient(#5fca66, #5fca66);
    background-position: bottom center;
    /*Adjust the background-position to move the line*/
    background-size: 50% 3px;
    /*Adjust the background size to control length and height*/
    background-repeat: no-repeat;
    padding-bottom: 4px;
    /* this can also control the position */
  }

  .restaurant_navbar_actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    button {
      background-color: $GRAY_SHADE2;
      border-radius: 50px;
      padding: 10px 20px;

      span {
        color: $Black;
        font-weight: 600;
        font-size: 18px;
        text-transform: none;
      }
    }
  }

  .restaurant_navbar_icon {
    display: block;
  }

  .restaurant_navbar_icon_none {
    @media (max-width: 630px) {
      display: none;
    }
  }
}