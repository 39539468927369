@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/mixins.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.customAccordian {
  padding-top: 30px;
  align-items: center;

  & .MuiAccordion-root {
    margin-bottom: 20px;
    padding: 10px 20px;
    box-shadow: none;
    border: none !important;
    border-radius: 5px !important;
  }

  & .topTitle {
    color: $Black;
    font-weight: 500;
    font-family: $POPPINS;
    font-size: 40px;

    span {
      display: flex;
      flex-direction: column;
      color: #333;
      align-items: center;
      font-size: 25px;

      @media screen and (max-width: 768px) {
        font-size: 16px !important;
      }
      @media screen and (max-width: 560px) {
        font-size: 12px !important;
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 28px !important;
    }

    @media screen and (max-width: 560px) {
      font-size: 24px;
    }
  }

  & .subTitle {
    padding-left: 30px;
    color: $Black;
    font-weight: 500;
    font-family: $MONTSERRAT;
    display: flex;
    margin-left: 30px;
    align-items: center;
  }

  & p {
    font-size: 16px;

    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }

  & .AccordionSummary {
    & .MuiSvgIcon-root {
      display: none;
    }

    & .subTitle{
      font-size: 30px;

      @media screen and (max-width: 560px) {
        font-size: 14px;
        font-family: $POPPINS;
      }
    }

    & .MuiAccordionSummary-expandIcon {
      background-color: $ORANGE;

      &:hover {
        background-color: $ORANGE;
      }
    }

    & .MuiIconButton-label {
      position: relative;

      &::before,
      &::after {
        content: " ";
        position: absolute;
        height: 2px;
        width: 12px;
        background-color: $WHITE;
      }

      &::after {
        transform: rotate(90deg);
        background-color: $WHITE;
      }
    }

    & .MuiAccordionSummary-expandIcon.Mui-expanded {
      transform: rotate(0deg);

      & .MuiIconButton-label {
        &::after {
          display: none;
        }
      }
    }
  }

  & .AccordionDetails {
    display: flex;
    flex-direction: column;

    & h6 {
      margin: 10px 0;
      font-weight: 600;
      color: $Black;
    }

    & .subTitle {
      font-family: $MONTSERRAT;
      margin: -10px 0 20px 135px;

      @media screen and (max-width: 560px) {
        margin: 24px 0 10px 50px;
        font-size: 14px;
        font-family: $POPPINS;
      }
    }

    .MuiTypography-h5{
      font-family: $POPPINS;
      font-weight: 500;
    }

    .para {
      margin: -10px 0 20px 165px;
      text-align: justify;
      @media screen and (max-width: 768px) {
        font-size: 14px !important;
      }
      @media screen and (max-width: 560px) {
        margin: 0 0 10px 80px;
        font-weight: normal;
        font-size: 12px;
        font-family: $POPPINS;
        text-align: justify;
        color: #919191;
      }
    }
  }

  & .overview-subTitle {
    display: flex;
    align-items: center; 
    margin-left: 60px; 
    font-size: 30px; 
    font-weight: 500;
    @media screen and (max-width: 560px) {
      font-size: 14px;
      font-family: $POPPINS;
    }
  }

  & .overview-text {
    margin: 0 0 20px 150px;
    font-size: 30px; 
    font-weight: 500;
    @media screen and (max-width: 768px) {
      font-size: 22px !important;
    }
    @media screen and (max-width: 560px) {
      font-size: 14px !important;
      margin : 0 0 20px 70px;
    }
  }

  .MuiAccordionSummary-root {
    flex-direction: row-reverse;

    @media screen and (max-width : 560px) {
      padding: 0;
    }
  }

  @media screen and (max-width: 560px) {
    padding: 0;
  }
}

.accordionContainer {
  @media screen and (max-width: 560px) {
    padding: 0 20px;
  }
}

.customAccordian6 {

  .MuiAccordionSummary-root {
    @media screen and (max-width: 560px) {
      padding: 12px;
    }
  }

  & .topTitle6 {
    color: #000;
    font-weight: 500;
    font-family: $POPPINS;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }

  & p {
    font-size: 16px;
    @media screen and (max-width: 560px) {
      font-size: 12px !important;
    }
  }

  .AccordionSection {
    .accordionWrapper {
      width: 100%; 
      border-bottom: 1px solid #ccc; 
      margin: 0 10px; 
      border-radius: 0; 
      @media screen and (max-width: 560px) {
        margin: 0;
      }
    }
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(90deg);
    @media screen and (max-width: 560px) {
      padding: 0;
    }
  }

  .MuiAccordionSummary-content {
    justify-content: space-between;
    @media screen and (max-width : 560px) {
      margin: 0 !important;
    }
  }

  & .detailWrapper {
    display: flex; 
    margin: 1rem 0 0 2rem; 
    align-items: center;

    video {
      border-radius: 15px; 
      object-fit: fill;
      width: 125px; 
      height: 75px;
      @media screen and (max-width : 560px) {
        width: 73px !important; 
        height: 44px !important;
        border-radius: 5px;
      }
    }

    h6 {
      font-weight: bold; 
      font-size: 20px;
      @media screen and (max-width : 768px) {
        font-size: 16px;
      }
      @media screen and (max-width : 560px) {
        font-size: 12px;
        font-family: $POPPINS;
      }
    }

    .time {
      margin-top: 8px;
      color: $GRAY_SHADE15;
      font-size: 20px;
      @media screen and (max-width : 768px) {
        font-size: 14px;
      }
      @media screen and (max-width : 560px) {
        font-size: 12px;
        font-family: $POPPINS;
      }
    }

    @media screen and (max-width : 560px) {
      margin: 0;
    }
  }

  .completedStat {
    color: #20a258; 
    font-weight: bold; 
    font-size: 20px; 
    @media screen and (max-width : 560px) {
      font-size: 12px;
      font-family: $POPPINS;
    }
  }

  .MuiAccordion-root:before {
    background-color: transparent !important;
  }

  .MuiAccordionDetails-root {
    .buttonWraper {
      cursor: pointer; 
      margin: 10px 0 0 40px;
      @media screen and (max-width : 560px) {
        margin: 10px 0 12px 40px;
      }
    } 
    @media screen and (max-width : 560px) {
      padding: 0;
    }
    .para {
      @media screen and (max-width : 768px) {
        font-size: 14px;
      }
      @media screen and (max-width : 560px) {
        margin-left: 0 !important;
        text-align: justify;
        font-family: $POPPINS !important;
        padding: 0 20px;
      }
    }
  }

  .paper {
    @media screen and (max-width : 768px) {
      margin: 1rem 0 !important;
    }
  }

  .document {
    padding: 10px; 
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }
 
  .pdfIcon {
    margin-left: 18px;
    width: 20px;
    height: 25px;
    @media screen and (max-width: 560px) {
      width: 12px;
      height: 14px;
    }
  }

  .playIcon {
    margin-left: 18px;
    width: 25px;
    height: 25px;
    @media screen and (max-width: 560px) {
      width: 15px;
      height: 15px;
    }
  }

  .videoName {
    cursor: pointer;
    padding: 10px;
    font-size: 20px; 
    font-weight: bold;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media screen and (max-width: 560px) {
      font-size: 12px; 
    }
  }

  @media screen and (max-width : 560px) {
    padding: 0;
  }
}

.modalVideo {
  width: 800px;
  height: 400px;
  @media screen and (max-width: 768px) {
    width: 90vw;
  }
}

.customAccordian6-active {
  background-color: #f4f5f9 !important;
}

.AccordionSummary-active {
  background-color: #f4f5f9;
}

.AccordionSummary {
  .MuiTypography-h5 {
    font-family: $POPPINS;
  }

  align-items: center;
  span {
    font-family: $POPPINS;
  }
  p {
    font-size: 12px;
    color: $GRAY_SHADE15;
  }
}

.taskAccordion {
  .MuiTypography-h5 {
    font-family: $POPPINS;
  }

  align-items: center;
  span {
    font-family: $POPPINS;
  }
  p {
    font-size: 25px;
    color: #9098b1;
    @media screen and (max-width: 560px) {
      font-size: 12px !important;
    }
  }
}

.commonAccordian {
  margin: 40px 0;

  .MuiPaper-root {
    box-shadow: none;
  }

  & .MuiAccordionDetails-root {
    padding: 0 15px 0 0;
  }
  & .MuiAccordionSummary-root {
    margin: 0;
    box-shadow: none !important;
    background-color: #f4f5f5;
    border: 1px solid #f4f5f5;
    border-radius: 10px;
    min-height: 64px;
    padding: 12px;

    .MuiAccordionSummary-content {
      margin: 0px;
    }
  }

  & .MuiAccordionSummary-root.Mui-expanded {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .MuiAccordionSummary-expandIcon {
    svg {
      color: #394053;
    }
  }

  & .MuiCollapse-entered {
    border: 1px solid #f4f5f5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  & .MuiCollapse-wrapper {
    margin-top: 40px;
  }
  & h6 {
    font-size: 18px;
    font-weight: bold;
  }
  & .greenContained {
    padding-bottom: 30px;
  }
  &.courseAccordian {
    margin: 20px 0;
  
    & .MuiCollapse-wrapper {
      margin-top: 10px;
    }
    & .MuiAccordionDetails-root {
      flex-direction: column;
      padding: 0;
    }
    & .education-row {
      padding: 20px 0 0 0;
    }
  }
}

.exercise-title {
  margin-top: 2.5rem;
  font-family: $POPPINS;
  text-align: center;
  color: black;
  font-size: 36px; 
  font-weight: 500;
  @media screen and (max-width: 1160px) {
    font-size: 32px !important;
  }
  @media screen and (max-width: 960px) {
    font-size: 28px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px !important;
  }
  @media screen and (max-width: 640px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 560px) {
    font-size: 14px !important;
    margin-top: 1rem
  }
}

.task-wrapper {
  .MuiAccordionSummary-root  {
    @media screen and (max-width: 640px){
      padding: 0;
    }
  }
}


