@import "../../../../web/src/assets/css/constants/base/colors";
@import "../../../../web/src/assets/css/constants/base/global";

.restDetailsContainer {
  width: 100%;
  background-color: $WHITE;
  border-radius: $BORDER_RADIUS;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  label,
  legend {
    font-family: $POPPINS;
  }

  h6 {
    font-size: 20px;
    font-weight: 700;
    color: $GRAY_SHADE4;
  }

  .restDetailsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 35px;
    border-bottom: 0.5px solid #979797;

    @media screen and (max-width: 768px) {
      padding: 20px 10px;
    }

    & .saveBtn {
      & .plus_icon {
        display: none;
      }

      & .MuiButton-label {
        & span {
          padding-left: 0;
        }
      }

      .MuiButton-text:hover {
        background-color: white;
      }
    }

    h4 {
      font-size: 24px;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .MuiButtonBase-root {
      background-color: $GREEN;
      border-radius: $FORM_BORDER_RADIUS;
      padding: 10px 20px;

      @media screen and (max-width: 768px) {
        padding: 5px 10px;
      }

      span {
        color: $WHITE;

        @media screen and (max-width: 768px) {
          font-size: 10px;
        }
      }
    }
  }

  .restDetailsCardsContainer {
    & .gridCells {
      margin-bottom: 10px;
    }

    margin-top: 30px;
    padding: 0 35px;

    @media screen and (max-width: 768px) {
      padding: 0px 10px;
    }
  }

  .quoteTimeContainer {
    margin-top: 30px;
    padding: 0px 35px;

    h6 {
      color: #464a53;
      font-size: 22px;
      font-weight: 600;
    }

    @media screen and (max-width: 768px) {
      padding: 0px 10px;
    }
    .quoteBorder {
      border-bottom: 0.5px solid #979797;
      padding-top: 5px;
    }

    .quoteTime {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      padding: 20px 0;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      h6 {
        color: #000;
        font-size: 20px;
        font-weight: 600;

        @media screen and (max-width: 768px) {
          text-align: left;
          width: 100%;
        }
      }

      .timingSelectContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 30px;

        @media screen and (max-width: 768px) {
          margin: 10px 0px;
          flex-direction: column;
          width: 100%;
        }

        .outlineSelect {
          width: 200px;
          margin: 0 10px;

          .customSelect .MuiSelect-select.MuiSelect-select {
            font-size: 22px;
            color: #000;
          }

          .customSelect.dishListStatus.timeAlign {
            position: relative;
            .timeError {
              position: absolute;
              top: 55px;
              right: 0;
            }
          }

          .MuiTypography-colorTextSecondary {
            color: rgb(0, 0, 0);
            font-size: 22px;
            font-weight: 400;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            margin: 10px 0px;
          }
        }
      }
    }
  }

  .orderType {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 30px 0;
    padding: 0px 35px;

    h6 {
      font-size: 22px;
      font-weight: 600;
      color: #464a53;
    }

    @media screen and (max-width: 768px) {
      padding: 0px 10px;
    }
  }

  .orderVolumeCOntainer {
    padding: 20px 35px;

    @media screen and (max-width: 768px) {
      padding: 20px 10px;
    }

    h6 {
      color: #464a53;
      font-size: 22px;
      font-weight: 600;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 0;

      border-bottom: 1px solid $GRAY_SHADE4;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      span {
        &:nth-child(1) {
          color: #000;
          font-weight: 600;
          font-size: 20px;
        }

        &:nth-child(2) {
          width: 78px;
          margin: 0 20px;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin: 10px 0px;
          }
        }

        &:nth-child(3) {
          color: #000;
          font-size: 22px;
          font-weight: 400;
        }

        &:nth-child(4) {
          margin-left: 20px;
        }

        .MuiInputBase-input.MuiInput-input {
          font-family: $POPPINS;
          font-size: 22px;
        }
      }
    }
  }

  .orderDetailsContainer {
    padding: 20px 35px;

    h6 {
      font-size: 22px;
      font-weight: 600;
      color: #464a53;
    }

    .greenOutlineButton {
      label {
        font-size: 20px;
        font-weight: 600;
        color: #20a258;
      }
    }

    .orderPriceText {
      font-family: $POPPINS;
      font-size: 20px;
      font-weight: 400;
      color: #464a53;
    }

    @media screen and (max-width: 768px) {
      padding: 20px 10px;
    }

    p {
      font-size: 15px;
      margin: 10px 0;
      color: #000;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    .orderSlot {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      padding: 20px 0;
      border-bottom: 1px solid $GRAY_SHADE4;
      width: 100%;

      .orderText {
        font-weight: 600;
        color: #000;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100% !important;
      }

      .MuiFormControl-root.MuiTextField-root {
        height: 53px;
      }

      span {
        margin: 0 10px;

        @media screen and (max-width: 768px) {
          margin: 10px 0;
        }

        &:nth-child(1) {
          color: #000;
          font-weight: 600;
          margin: 0;
          width: 33%;
          font-size: 18px;
        }

        &:nth-child(2) {
          width: 17%;

          .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart {
            font-family: $POPPINS;
            font-size: 22px;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        &:nth-child(3) {
          color: #000;
          font-size: 20px;
          font-weight: 400;
        }

        &:nth-child(4) {
          color: #000;
          width: 15%;

          .customSelect .MuiSelect-select.MuiSelect-select {
            font-size: 22px;
            color: #000;
            font-family: $POPPINS;
          }

          .MuiTypography-colorTextSecondary {
            margin-left: 20px;
            color: rgb(0, 0, 0);
            font-size: 22px;
            font-weight: 400;
            font-family: $POPPINS;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        &:nth-child(5) {
          color: #000;
          width: 15%;

          .customSelect .MuiSelect-select.MuiSelect-select {
            font-size: 22px;
            color: #000;
            font-family: $POPPINS;
          }

          .MuiTypography-colorTextSecondary {
            color: rgb(0, 0, 0);
            font-size: 22px;
            font-weight: 400;
            font-family: $POPPINS;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        &:nth-child(6) {
          color: #000;
          font-size: 20px;
          font-weight: 400;
          width: 40%;
        }

        &:nth-child(7) {
          margin-right: 10px;
        }
      }
    }
  }
}

.restAccount {
  font-family: $POPPINS;

  h6 {
    font-size: 18px;
    color: #464a53;
  }
}

.availSettingHeader {
  width: 120px;
  text-align: left;
  color: #949494;
  font-family: $POPPINS;
  display: flex;
  align-items: center;
  height: 100%;
}

.availSetting {
  width: 120px;
  text-align: left;
  color: #464a53;
  font-family: $POPPINS;

  & label {
    color: #949494 !important;
  }
}

.customAvailCheckbox {
  & .MuiSvgIcon-root {
    height: 1.2em;
    width: 1.2em;
  }
  & .Mui-checked {
    & .MuiSvgIcon-root {
      color: $GREEN;
    }
  }
  & .MuiTypography-body1 {
    font-family: $POPPINS;
    color: #949494;
  }
}

.customTimeCheckbox {
  & .MuiSvgIcon-root {
    height: 1.2em;
    width: 1.2em;
  }
  & .Mui-checked {
    & .MuiSvgIcon-root {
      color: $GREEN;
    }
  }
  & .MuiTypography-body1 {
    color: #464a53;
    font-family: $POPPINS;
  }
}

.MuiTypography-root.availSetting.start.MuiTypography-body1 {
  margin-right: 15px;
}

.MuiTypography-root.availSetting.end.MuiTypography-body1 {
  margin-right: 5px;
}

.orderVol {
  font-size: 20px;
  font-family: $POPPINS;
  font-weight: 600;
}

.availWrapperOne {
  border-bottom: 2px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.availWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.MuiGrid-root.availContainer.MuiGrid-container {
  width: 550px;
  margin: 30px 0 100px 40px;
  background-color: rgb(241, 241, 241);
}

.hyphen {
  margin: 0 0 25px 0;
  width: 10px;
  border-bottom: 2px solid #000;
}

.deliverySettIcon {
  width: 50px;
  height: 50px;
}
