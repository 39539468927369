@import "../../../../../web/src/assets/css/constants/base/global";
.toolBarContainer {
  // border: 1px solid rgb(172, 168, 168);
  border-radius: 10px;
  margin: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);

  & span {
    color: unset;
    font-family: $POPPINS;
  }
  & .toolBarCOntainer {
    display: flex;
    align-items: center;
    border-radius: 10px 10px 0 0;
    justify-content: flex-start;
    border-bottom: 1px solid rgb(172, 168, 168);
    padding: 10px 10px;
    background-color: #f9f8fd;
    gap: 10px;
    button {
      border: none;
      background-color: transparent;
    }
  }
  & .DraftEditor-editorContainer {
    min-height: 100px;
    & .public-DraftEditor-content {
      min-height: 100px;
      overflow: auto;
      padding: 10px;
      display: flex;

      & div {
        display: flex;
        & figure {
          margin: 0 10px;
          position: relative;
          img {
            height: 80px;
            width: 80px;
            object-fit: cover;
            border-radius: 10px;
            border: 1px solid #ccc;
          }
          // &::before {
          //   content: "";
          //   background-color: #edf8f2;
          //   border: 1px solid #000;
          //   padding: 0;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   position: absolute;
          //   right: -10px;
          //   top: -10px;
          //   height: 22px;
          //   width: 22px;
          //   cursor: pointer;
          //   border-radius: 50%;

          // }
          // &::after {
          //   content: "";
          //   position: absolute;
          //   // border-radius: 50%;
          //   color: #20a258;
          //   font-size: 24px;
          //   font-weight: 600;
          //   display: inline-block;
          //   transform: rotate(45deg);
          // }
        }
      }
    }
  }
}

.imgUploadContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  & .chipContainer {
    padding: 0;
    margin: 0;
  }
  & .MuiSvgIcon-root {
    transform: rotate(45deg);
  }
  .imgContainer {
    position: relative;
    // border: 1px solid gray;

    border-radius: 5px;

    img {
      width: 150px;
      height: 100px;
      padding: 5px;
      border-radius: 5px;
    }
    .closeIcon {
      background-color: #dddddd;
      padding: 0;
      border-radius: 50%;
      transform: rotate(90deg);
      cursor: pointer;
      & .MuiSvgIcon-root {
        transform: rotate(0deg);
      }
    }
  }
}

.RichEditor-editor {
  & .public-DraftEditorPlaceholder-root {
    margin: left 10px;
    margin-top: 10px;
    padding: 15px;
    color: #e3e3e5;
  }
}

.RichEditor-hidePlaceholder {
  & .public-DraftEditorPlaceholder-root {
    display: none;
  }
}

.taskEditor{
  & .ql-toolbar{
    border: none !important;
    padding: 13px !important;
  }
  & .quill{
    height: 150px;
    & .ql-container{
      border: none;
      border-top: 1px solid #ccc;
    }
  }
  & .ql-blank{
    &::before{
      color: #e3e3e5 !important;
      font-style: normal !important;
      left: 15px !important;
      font-size: 16px !important;
      top: 19px;
      font-family: $MONTSERRAT;
    }
  }
  & .ql-editor{
    & p{
      font-size: 16px !important;
      color: rgba(0, 0, 0, 0.87) !important;
      margin-top: 12px !important;
      line-height: 1.4 !important;
    }
  }
}
