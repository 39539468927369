@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
.purchaseCourseWapper {
  background-color: $WHITE;
  width: 100%;
  & h4 {
    margin-bottom: 15px;
  }

  & .customProgressbar {
    padding-top: 10px;
  }
  
  & .commonSection {
    margin-bottom: 80px;
  }
  & .purchaseCourseSection {
    padding: 30px 50px;
    box-shadow: 0px 0px 12px 0px $GRAY_SHADE3;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
      padding: 15px;
    }
  }
  & .subContentWrapper {
    max-width: 1600px;
    margin: 0 auto;
    padding: 10px;
  }

  & .subCourseSection {
    & h2 {
      margin-bottom: 30px;
      text-align: center;
      font-size: 45px;
    }
    & h4 {
      margin-bottom: 0;
    }

    & .MuiButton-label {
      & span {
        font-size: 14px;
        padding-left: 0;
      }
    }
  }
}
