@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.ot_step_img_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .ot_step_img {
    height: 40px;
    width: 100%;
    object-fit: fill;
  }
}

.ot_main_button {
  width: 100% !important;
  border-radius: 10px !important;
  background-color: #20a258 !important;
  padding: 8px 0px !important;

  span {
    font-family: $POPPINS;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
  }
}