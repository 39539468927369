@import "../../../../web/src/assets/css/constants/base/colors";
@import "../../../../web/src/assets/css/constants/base/global";

.createcampaignEditContainer {
  width: 100%;

  background-color: $WHITE;
  border-radius: $BORDER_RADIUS;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $GRAY_SHADE3;
    padding: 40px 20px 30px 20px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .leftSideHeader {
      width: 100%;
      display: flex;
      align-items: center;

      justify-content: flex-start;
      .MuiSvgIcon-root {
        font-size: 30px;
      }
      h4 {
        font-weight: 900;
        // font-size: 35px;
        margin-left: 5px;
      }
    }
    .rightSideHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;

      .campaign_delete_icon {
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
        margin-top: 20px;
        justify-content: space-between;
      }
     

      // & .MuiSvgIcon-root {
      //   font-size: 40px;
      //   color: rgb(223, 37, 37);
      // }
    }
  }
  .forumMainWrapper {
    padding: 40px 20px 30px 20px;
    // background-color: green;
    margin-bottom: 30px;
    width: 100%;

    overflow: hidden;
    & .imageContainer {
      position: relative;
      //   background-color: red;
      .image {
        width: 100%;
        height: 250px;
        img {
          border-radius: $FORM_BORDER_RADIUS;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .editBannerBtn {
        position: absolute;
        top: 10px;
        right: 20px;
        background-color: $WHITE;
        border-radius: $FORM_BORDER_RADIUS;
        padding: 5px 20px;
        span {
          color: black;
        }
      }
    }
    & .forumResponseContainer {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: space-between;
      }
      & .likesCommentsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        & .likeComments {
          .MuiSvgIcon-root {
            color: blue;
            font-size: 30px;
          }
          .count {
            color: black;
            margin: 0 5px;
            font-size: 20px;
            font-weight: 700;
          }
          & span {
            color: $GRAY_SHADE6;
          }
        }
      }
      & .buttonContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media screen and (max-width: 768px) {
          margin-top: 20px;
          justify-content: center;
          width: 100%;
        }
        .MuiButtonBase-root {
          background-color: $GREEN;
          border-radius: $FORM_BORDER_RADIUS;
          padding: 10px 30px;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          span {
            color: $WHITE;
          }
        }
      }
    }
    & .forumHeaderDescription {
      width: 100%;
      margin: 20px 0;
      h4 {
        margin: 10px 0;
        font-size: 17px;
        color: grey;
      }
      textarea {
        width: 100%;
        border-radius: $FORM_BORDER_RADIUS;
        padding: 10px;
        border-color: $GRAY_SHADE3;
        color: $GRAY_SHADE4;
      }
    }
    .Donation {
      color: green;
      margin-bottom: 15px;
    }
    .Raise {
      margin-left: -950px;
      text-align: left;
    }
    .h5 {
      color: green;
    }
    .ReqAmount {
      display: flex;
      flex-direction: row;
    }
    .DonaRaisedt {
      margin-left: 70px;
    }
    .edit {
      // background-color: black;
      // margin-left: 1050px;
      // color: grey;
      // margin-bottom: -60px;
      position: absolute;
      top: 20px;
      right: 10px;
    }
    .editCamp {
      background-color: white;
      text-decoration-color: black;
      color: black;
    }
    .donnersContainer {
      margin-top: 20px;
      width: 100%;
      h3 {
        color: $GRAY_SHADE6;
        font-size: 17px;
        font-weight: 600;
      }
      .donnersList {
        margin-top: 20px;
        width: 100%;

        & .donnerContainer {
          border: 1px solid $GRAY_SHADE1;
          border-radius: 7px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 30px;

          margin: 20px 0;

          .profile {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }

            p {
              color: $BLACK;
              margin-left: 20px;
              font-size: 16px;
              font-weight: 700;
              text-transform: capitalize;
            }
          }

          p {
            color: $GRAY_SHADE1;
              font-weight: 600;
          }

          h3 {
            color: $GREEN;
            font-weight: 600;
            font-size: 22px;
          }
        }
      }
    }
  }
}

.campaign_status {
  .MuiSelect-select {
    margin-top: 2px !important;
  }
}

.campaignTextArea {
  resize : none;
  font-family: $POPPINS !important;
}