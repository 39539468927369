@import "../constants/base/";

.customTextfield {
  & .MuiInputLabel-root {
    position: absolute;
    top: -25px;
    font-size: 21px;
    font-weight: bold;
    color: $DARK;
  }
  & .MuiFormControl-root {
    background-color: $GRAY_SHADE2;
    border: 0;
    padding: 3px 10px;
    margin-top: 0px;
    border-radius: 10px;
    color: $DARK;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    & .MuiInputBase-input {
      &::placeholder {
        color: $DARK;
        font-size: 14px;
        font-family: $POPPINS;
      }
    }
    & .MuiInput-underline {
      &::before {
        border: 0;
      }
    }
    & .MuiInput-formControl {
      margin: 0;
      width: 100%;
      font-size: 14px;
    }
    & .MuiInput-underline {
      &::after {
        border: 0;
      }
      &:hover {
        &:not(.Mui-disabled) {
          &::before {
            border: 0;
          }
        }
      }
      &:focus {
        &:not(.Mui-disabled) {
          &::before {
            border: 0;
          }
        }
      }
    }
    & .customLabel {
      margin-bottom: 10px;
    }
  }
}
.outlineTextfield {
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 10px;
  & .errorMsg {
    margin: 0;
  }
  & .MuiFormControl-root {
    background-color: transparent !important;
    border: 1px solid $GRAY_SHADE7 !important;
    padding: 0px 10px;
  }
  & .MuiInputBase-root {
    & input {
      &::placeholder {
        color: red;
      }
    }
  }
  &.readOnlyField {
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 10px 5px !important;
        text-align: center;
      }
    }
  }
  &.boxRadiusBorder {
    & .MuiFormControl-root {
      border-radius: $FORM_BORDER_RADIUS;
    }
    .MuiInputBase-root {
      .MuiInputBase-input {
        padding: 10px 5px !important;
        font-family: $POPPINS !important;
      }
    }
  }
  &.inventoryTextbox {
    & .MuiFormControl-root {
      height: 54px;
    }
  }
}

.sideLine {
  border-left: 1px solid gray;       /* add the border             */
  padding: 0 7px;                     /* left and right padding     */
}

.customTextAreafield {
  textarea {
    resize: none;
    padding: 3px 10px;
    border-radius: 10px;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    color: $DARK;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    font-family: $MONTSERRAT;
    &:focus {
      outline: none !important;
      border-color: $GRAY_SHADE3;
    }
  }
}

.normalTextArea {
  textarea {
    resize: none;
    padding: 3px 10px;
    border-radius: 10px;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 15px;
    font-size: 16px;
    font-family: $MONTSERRAT;
    &:focus {
      outline: none !important;
      border-color: $GRAY_SHADE3;
    }
  }   
}

.ownerEmailField {
  width: 31.5% !important;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
.handleOpacity{
   .Mui-disabled{
     opacity: .6;
   }
}

.textFieldMultiline {
  .MuiInputBase-input {
    font-size: 14px;
  }
}