@import "../../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../../web/src/assets/css/constants/base/global.scss";

.pd_main_wrapper {
  padding: 50px 45px;

  .pd_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    text-align: center;

    img {
      height: 60%;
      width: 60%;
    }
  }

  h5,
  h6,
  p {
    padding: 5px;
  }

  h5 {
    font-family: $POPPINS;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #000;
    margin-top: 20px;
  }

  h6 {
    font-family: $POPPINS;
    font-size: 16px !important;
    text-align: center;
    font-weight: 300 !important;
    color: #000;
  }

  p {
    color: $GREEN;
    font-size: 18px !important;
    font-family: $POPPINS;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    word-break: break-word;
  }

  .pd_multi_id_wrapper {
    list-style: none;

    li {
      display: flex;
      align-items: center;
    }

    li::before {
      color: $GREEN;
      content: "\2022";
      font-weight: bold;
    }

    p {
      margin-top: 0px;
      padding: 3px;
      text-align: left;
    }
  }

  button {
    width: 100%;
    background-color: #1ea157 !important;
    border-radius: 30px !important;
    padding: 10px !important;

    span {
      color: $WHITE;
      font-size: 14px;
      font-weight: 600;
    }
  }
}