@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";
.customFileUpload {
  & .fileUploadInput {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $GRAY_SHADE3;
  border-radius: 10px;
  padding: 20px;
  max-width: 370px;
  margin: 10px 0;
  & .fileUploadLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & img {
      height: 50px;
      width: 50px;
      object-fit: contain;
    }
    & span {
      color: $GREEN;
      font-weight: 600;
      padding-top: 10px;
    }  
  }
  & .fileUploadIcon{
    & img {
      width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 23px;
    }
  }
}
