@import "../../../../web/src/assets/css/constants/base/index.scss";
.educationSidebar {
  & .MuiDrawer-paper {
    position: unset;
  }
  & .drawerNav {
    @media screen and (max-width: 959px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      width: 240px;
    }
    @media screen and (max-width: 767px) {
      flex-shrink: 0;
    }
  }
  & .SidebarHeader {
    @media screen and (min-width: 959px) {
      display: none;
    }
  }
  & .MuiDrawer-paperAnchorLeft {
    border-right: 0;
  }
  & .MuiListItem-gutters {
    padding: 0 0 10px 0 !important;
  }
  & .drawerSM {
    // width: calc(100% - 240px);
    // margin-left: 240px;
    // background-color: red;
  }
  & .drawerIconXS {
    margin-right: 20px;
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
  & .drawerContainer {
    background-color: red;
  }
}
.EduCoursePlaySidebar {
  padding: 10px;
  & .cardHeader {
    width: 100%;
    @media screen and (max-width: 768px) {
      justify-content: space-between;
      align-items: center;
      display: flex;
      padding-bottom: 15px;
    }
    & h6 {
      color: $GREEN_SHADE6;
      font-family: $MONTSERRAT;
    }
    & hr {
      margin: 15px -40px 0 -40px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    & .closeIcon {
      display: none;
      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }
  & .customAccordian {
    & .MuiAccordion-root {
      padding: 0;
      border: 0;
      margin: 0;
    }
    & .MuiAccordionSummary-root {
      background-color: $GRAY_SHADE13;
      margin: 0 -15px;
      padding: 0 15px !important;
      border-bottom: 2px solid $GRAY_SHADE1;
      @media screen and (max-width: 768px) {
        margin: 0;
      }
    }
    & .topTitle {
      color: $DARK;
      font-weight: 600;
      font-size: 0.95rem;
      font-family: $MONTSERRAT;
      @media screen and (max-width: 1024px) {
        // font-size: 1rem;
      }
    }
    & .MuiIconButton-edgeEnd {
      margin-right: auto;
    }
    & .MuiAccordionDetails-root {
      padding: 20px 0 0;
    }
    .navList {
      @media screen and (max-width: 768px) {
        padding: 0 15px;
      }
      & li {
        display: flex;
        padding-bottom: 20px;
        & .circleBg {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
        & .playCircle {
          background-color: $GREEN_SHADE9;
        }
        & .pauseCircle {
          background-color: $ORANGE_SHADE1;
        }
        & h6 {
          font-weight: 500;
          padding-left: 10px;
        }
      }
    }
  }
}
