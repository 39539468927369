@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.sr {
  padding: 0px !important;
  padding-bottom: 25px !important;
}

.phone_number_input {
  padding: 12px 10px 12px 0px !important;
}

.address_phone_number_prefix {
  color: rgba(0, 0, 0, 0.35) !important;
  font-weight: 600 !important;
  padding-right: 5px !important;
  border-right: 2px solid rgba(0, 0, 0, 0.35) !important;
}

.value_contain_phone_prefix {
  color: #000 !important;
}

.Login_Input_Field_Focus {
  .address_phone_number_prefix {
    color: $GREEN !important;
    border-right: 2px solid $GREEN !important;
  }
}
