@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";
.eduCoursePlayWapper {
  // height: 100%;
  background-color: $WHITE;
  width: 100%;
  padding-top: 30px;
  & .commonSection {
    margin-bottom: 50px;
  }
  & .subContentWrapper {
    max-width: 1450px;
    margin: 0 auto;
    padding: 10px;
  }
  & .eduCourseContentWrapper {
    padding: 10px;
    & .filterBtn {
      display: none;
      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }

  .videoPlayerContainer {
    position: relative;
    overflow: hidden;
    border-radius: $BORDER_RADIUS;
  }
  .overViewConatiner {
    margin-top: 50px;
    h3 {
      font-weight: 600;
      font-size: 20px;
      width: 100%;
      text-align: left;
      padding-bottom: 30px;
      border-bottom: 1px solid $GRAY_SHADE5;
    }

    .overViewDetails {
      width: 100%;

      p {
        width: 100%;
        text-align: justify;
        margin: 20px 0;
      }
    }
  }
}
