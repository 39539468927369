@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.review_card_wrapper {
  border: 1px solid $GRAY_SHADE1;
  padding: 20px;
  border-radius: 20px;
}
.reviewer_img {
  display: flex;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #979797;
  }
}

.review_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.reviewer_details {
  display: flex;
  justify-content: space-between;
}
.reviewer_name {
  color: $Black;
  font-weight: 600 !important;
  font-size: 18px !important;
  text-transform: capitalize;
  font-family: $MONTSERRAT;
}
.reviewed_date {
  font-family: $MONTSERRAT;
  font-size: 16px;
  font-weight: 500;
  color: #5a5a5a;
}
