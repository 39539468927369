@import "../../../../../web/src//assets//css/constants/base/colors.scss";
@import "../../../../../web/src//assets//css/constants/base/global.scss";

.restaurant_card {
  width: 100%;
  height: 100%;
  box-shadow: 10px 20px 40px 0 rgba(97, 49, 0, 0.2);
  border-radius: 20px;
}

.restaurant_card_img_wrapper {
  border-radius: 15px;

  .restaurant_card_image {
    object-fit: cover;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

.restaurant_card_content {
  padding: 15px;

  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 900 !important;
    font-size: 18px !important;
  }

  p {
    color: #5a5a5a;
    font-size: 14px;
    font-family: $MONTSERRAT;
    text-align: justify;
    margin-top: 8px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .duration {
    margin: 30px 0px 10px 0px;
    padding-left: 5px;
    height: 28px;
    font-family: $MONTSERRAT;
    font-size: 15px;
    font-weight: 600;
    color: #333333;
  }

  @media screen and (max-width: 480px) {
    padding: 8px 13px 13px;

    p {
      margin-top:3px;
    }

    .duration {
      margin: 7px 0px 5px;
      padding-left: 0px;
      font-size: 14px;
    }
  }
}

.check_menu_btn {
  padding: 9px 16px 9px 20px !important;
  border-radius: 29.5px !important;
  background-color: #f3f0f0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-transform: capitalize !important;
  transition: 0.5s !important;
  font-family: $MONTSERRAT !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000000 !important;

  img {
    transition: transform 0.25s;
  }

  &:hover {
    background-color: #e1dfdf !important;

    img {
      transform: translateX(3px)
    }
  }

  @media screen and (max-width: 480px) {
    padding: 8px 10px !important;
    font-size: 14px !important;

    img {
      height: 14px;
      width: 15px;
    }
  }
}

.check_menu_btn_color {
  color: black;
}

.image_loader {
  color: #20a258 !important;
}