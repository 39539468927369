@import "../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../web/src/assets/css/constants/base/global.scss";

.web_sidebar {
  padding: 20px;

  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    overflow-x: auto;
    border-bottom: 2px solid #eee;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #d3d3d3;
    }
  }
}

.web_sidebar_list_item {
  color: $Black;
  text-align: center;
  padding: 15px;
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    padding: 0;
  }
}

.account_setting_item {
  border: none !important;
}

.web_sidebar_link {
  color: $Black;
  width: 100% !important;
  font-family: $MONTSERRAT;

  &:hover {
    color: $GREEN;
  }

  @media screen and (max-width: 960px) {
    padding: 15px 18px !important;
    min-width: 170px;
    font-weight: 500;
  }
}

.separate {
  border-bottom: solid 1px #dfe8e1 !important;
  padding-bottom: 15px !important;

  @media screen and (max-width: 960px) {
    border: none !important;
    padding: 0 !important;
  }
}


.web_sidebar_active_link {
  display: block;
  background-color: $GREEN;
  border-radius: 50px;
  padding: 15px 0px !important;
  color: $WHITE;
  width: 205px !important;

  &:hover {
    color: $WHITE;
  }

  @media screen and (max-width: 960px) {
    border-radius: 0;
    color: $Black;
    background-color: #fff;
    padding: 15px 18px !important;
    min-width: 170px !important;
    border-bottom: 3px solid $GREEN !important;
    font-weight: 600 !important;
    z-index: 1;

    &:hover {
      color: $Black;
    }
  }
}

.web_sidebar_active_link .web_sidebar_list_item {
  border-bottom: none;
}

.logout_btn_wrapper {
  margin-top: 100px;
  padding: 20px;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.logout_btn {
  background: #ed5959 !important;
  border-radius: 50px !important;
  color: white !important;
  text-transform: capitalize !important;
  padding: 15px 80px !important;

  span {
    color: #fff;
  }
}