@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.custom_comment_wrapper {
  border-radius: 26px;
  background-color: #f0f0f0;
  padding: 25px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    padding: 20px;
  }

  .para {
    color: $GRAY_SHADE15;
    font-family: $MONTSERRAT;
  }

  .comment_image_wrapper {
    padding: 12px 0px;
  }

  .comment_profile_img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #979797;

    @media screen and (max-width: 600px) {
      width: 45px;
      height: 45px;
    }
  }

  .comment_main_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    width: 100%;

    @media screen and (max-width: 600px) {
      gap: 10px;
    }

    @media screen and (max-width: 450px) {
      flex-wrap: wrap;
    }
  }

  .comment_main_comment {
    padding: 12px 0px;
  }

  .comment_info_container {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    flex-wrap: wrap;
  }

  .comment_date {
    font-family: $MONTSERRAT;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: right;
    color: #5a5a5a !important;

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    font-family: $MONTSERRAT !important;
    color: #000 !important;

    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
  }

  .reply_heading {
    margin-right: 0 !important;
  }

  .comment {
    font-family: $MONTSERRAT !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #5a5a5a !important;
    margin: 12px 0px;
    word-break: break-word;

    @media screen and (max-width: 600px) {
      font-size: 14px !important;
    }

    @media screen and (max-width: 560px) {
      padding: 2px;
      margin: 0;
      color: #7d7979 !important;
      font-family: $POPPINS !important;
      font-size: 12px !important;
    }

    @media screen and (max-width: 400px) {
      font-size: 12px !important;
    }
  }

  .comment_actions_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    flex-wrap: wrap;

    @media screen and (max-width: 450px) {
      justify-content: flex-end;
      margin-top: 5px;

      button {
        width: 100%;
      }
    }
  }

  .comment_reply_container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  .review_reply_btn {
    background-color: #3bad6c !important;
    padding: 7px 20px;
    border-radius: 25px;

    span {
      color: $WHITE;
      text-transform: capitalize;

      @media screen and (max-width: 450px) {
        font-size: 12px;
      }
    }

    p {
      color: $WHITE;
      padding-left: 10px;
    }
  }

  .report_btn {
    background-color: transparent;
    padding: 6px 10px;
    border-radius: 25px;
    border: 1px solid #fd5656;
    text-transform: none;
    min-width: 110px;

    span {
      font-weight: 600;
      color: #fd5656;

      @media screen and (max-width: 450px) {
        font-size: 12px;
      }
    }
  }

  .report_btn:disabled {
    background-color: transparent;
    cursor: not-allowed !important;
    border: 1px solid #9098b1;
    pointer-events: inherit;

    span {
      font-weight: 600;
      color: #9098b1;
    }
  }

  ul {
    display: flex;

    li {
      padding: 0px 3px;

      svg {
        color: $ORANGE;
      }
    }
  }

  @media (max-width: 515px) {
    flex-direction: column;

    img {
      margin-bottom: 10px;
    }
  }
}

.forum_reply_container {
  padding: 20px 0px;
  border-top: 1px solid #979797;

  .reply_image_wrapper {
    @media screen and (max-width: 450px) {
      display: flex;
      justify-content: center;
    }
  }
}

.forum_show_replies_container {
  display: flex !important;
  justify-content: flex-end !important;

  button {
    background: transparent;
    text-transform: none;
    border-radius: 20px;
    padding: 6px 12px;

    span {
      color: $GREEN;
      font-family: $POPPINS;
    }
  }
}

.review_action_container {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;

  @media screen and (max-width: 450px) {
    gap: 8px;
  }

  .add_review_cancel {
    background: #fff;
    border: 1px solid #c3c3c3 !important;
    line-height: normal !important;
    width: 37px !important;
    height: 37px !important;

    @media screen and (max-width: 450px) {
      width: 32px !important;
      height: 32px !important;
    }

    svg {
      color: #000;

      @media screen and (max-width: 450px) {
        font-size: 16px;
      }
    }
  }

  button {
    padding: 5px;
  }
}

.reply_save_btn {
  border-radius: 25px !important;
  background-color: #3bad6c !important;

  span {
    color: $WHITE !important;
    padding: 0px 30px;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 450px) {
      font-size: 12px;
      padding: 0px 10px;
    }
  }

}

.review_reply_container {
  @media screen and (max-width: 600px) {
    margin-top: 5px;
  }
}

.add_reply_loader {
  margin: 3px 0px;
}