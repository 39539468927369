@import "../../../../../web/src/assets/css/constants/base/colors.scss";
@import "../../../../../web/src/assets/css/constants/base/global.scss";

.farms_page_content_wrapper {
  background-color: $WHITE;
  width: 100vw;
  height: auto;
  padding-top: 30px;
  padding-bottom: 100px;
  h3 {
    font-family: $ROUNDGOTHIC_BOOK !important;
    font-weight: 600;
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 15px;
  }
}

.custom_loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;

  svg {
    color: $GREEN;
  }
}

.outline_input_icons {
  color: $GREEN;
  font-size: 14px;
}
.btn_view_all {
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
}
.pagination_grid {
  margin-top: 30px;
  div {
    margin-left: 0px !important;
  }
}

.advance_search_btn {
  background-color: $GREEN !important;
  width: 100%;
  color: "white" !important;
  padding: 12px 10px !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-weight: 600;
}

.farms_page_main_section {
  margin-top: 50px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
}
.heading_and_filter_input_wrapper {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}
.filter_heading_wrapper {
  width: 100%;
  h3 {
    font-family: $ROUNDGOTHIC_MEDIUM !important;
  }
}

.filter_formControl_root {
  width: 100% !important;
}
.OutlinedInput-root {
  border-radius: 50px !important;
}
.select_icon {
  color: black !important;
  top: 2px !important;
}
.show_more_btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.show_more_btn_wrapper_pagination {
  text-align: center;
  margin: 0px 75px;
}

.filter_input_wrapper_new {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid $GRAY_SHADE1;
  border-radius: 50px;
  padding: 4px 2px 4px 14px;
  margin-bottom: 15px;
  p {
    color: $GRAY_SHADE1;
  }
  .MuiInputBase-root {
    background: none !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    font-family: $MONTSERRAT !important;
    margin-top: 3px !important;
    padding-left: 6px;
  }
  .MuiInput-underline::before,
  .MuiInput-underline::after {
    border-bottom: none !important;
    outline: none !important;
  }
  select,
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }

  .MuiMenuItem-root {
    font-family: $MONTSERRAT !important;
  }
}

.select_menus {
  font-family: $MONTSERRAT !important;
  font-size: 16px !important;
}
