@import "../constants/base/colors.scss";
@import "../constants/base/global.scss";
.customCheckbox {
  & input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    & + .is_same_number {
      &::before {
        border-radius: 50%;
      }
    }
    &:checked {
      & + label {
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 5px;
          left: 5px;
          width: 8px;
          height: 14px;
          border: solid $GREEN;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
        &::before {
          background-color: rgba(32, 162, 88, 0.08);
        }
      }
      & + .is_same_number {
        &::after {
          border-radius: 50%;
          top: 7.2px;
          left: 2.75px;
          width: 11px;
          height: 11px;
          background-color: $GREEN;
          transform: rotate(0) !important;
          border-width: 0;
        }
      }
    }
  }

  & label {
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      appearance: none;
      -webkit-appearance: none;
      background-color: rgba(32, 162, 88, 0.08);
      border: 1px solid rgba(0,0,0,0.26);
      border-radius: 4px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
      margin-bottom: -1px !important;
    }
    &.product_checkbox
    {
      &::before {
        margin-bottom: 5px;
      }
    }
  }
  
}

.circleCheckbox {
  & input {
    &:checked {
      & + label {
        &::after {
          border: solid $ORANGE;
          border-width: 0 4px 4px 0;
        }
        &::before {
          background-color: $WHITE;
        }
      }
    }
  }
  & label {
    &::before {
      border-radius: 50%;
      border-color: $WHITE;
    }
  }
}

.customLabelCheckbox {
  & .MuiSvgIcon-root {
    height: 1.2em;
    width: 1.2em;
  }
  & .Mui-checked {
    & .MuiSvgIcon-root {
      color: $GREEN;
    }
  }
  & .MuiTypography-body1 {
    color: $DARK;
  }
}

.customToggleButton {
  .switch {
    cursor: pointer;
    width: 80px;
    height: 36px;
    background: #fff;
    position: relative;
    transition: background-color 0.2s;
    border: 1px solid $GRAY_SHADE4;
    border-radius: 30px;
    margin-left: 30px;
    margin-top: 20px;
    @media screen and (max-width: 560px) {
      width: 75px;
      height: 30px;
    }
    .switchCheckbox {
      height: 0;
      width: 0;
      position: absolute;
      visibility: hidden;
      &:checked {
        + .redSwitch {
          left: calc(100%);

          transform: translateX(-100%);
        }
        + .greenSwitch {
          left: calc(100%);

          transform: translateX(-100%);
        }
        + .switchButton {
          left: calc(97%);

          transform: translateX(-100%);
        }
      }
    }
    .switchButton {
      position: absolute;
      z-index: 1;
      top: 2px;
      left: 2px;
      bottom: 2px;
      width: 30px;
      height: 30px;
      transition: 0.3s;
      background-color: #20a258;
      border-radius: 50%;

      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }

    .redSwitch {
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      width: 35px;
      height: 35px;
      transition: 0.3s;
      background: red;
      border-radius: 50%;
      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

      @media screen and (max-width: 560px) {
        width: 30px;
        height: 30px;
      }
    }

    .greenSwitch {
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0px;
      width: 35px;
      height: 35px;
      transition: 0.3s;
      background: $GREEN;
      border-radius: 50%;
      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

      @media screen and (max-width: 560px) {
        width: 30px;
        height: 30px;
      }
    }

    
    .switchLabels {
      display: relative;
      z-index: 0;
      height: 100%;
      font: 14pt "Segoe UI", Verdana, sans-serif;
      span {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
       width: 50%; 
        height: 100%;
        font-size: 14px;
        color: $GRAY_SHADE6;
        font-weight: 600;
        &:first-child {
          left: 0;
        }
        &:last-child {
          right: 0;
        }
        @media screen and (max-width: 560px) {
          font-size: 12px;
          width: 48%;
        }
      }
    }
  }
}

.dangerCheckbox {
  & input {
    &:checked {
      & + label {
        &::before {
          background-color: $ORANGE;
          border: 1px solid $ORANGE;
          margin-right: 0;
          padding: 9px;
        }
        &::after {
          top: 4px;
          left: 6px;
          height: 13px;
          border-color: $WHITE;
          border-radius: 3px;
        }
      }
    }
  }
}

.grayBackGroundButton {
  .switchLabel {
    font-size: 10px;
    margin-right: 10px;
    color: $GRAY_SHADE6;
    font-weight: bold;
  }
  .customToggleButton {
    .switch {
      border: unset;
      background-color: $GRAY_SHADE2;
      margin: unset;
    }
  }
  .acceptOrderBtn{
    display:flex;
    justify-content: center;
  }
}

.mcqCheckWidth{
  & .MuiFormControlLabel-root{
    width:28px !important
  }
}